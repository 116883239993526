import { Box, useTheme } from "@mui/material";
import React from "react";

const GrowthProgressbar = ({ step }) => {
  const theme = useTheme();
  return (
    <Box display="flex" position="abolute">
      <Box
        width={`${(step - 1) * 20}%`}
        height="5px"
        position="abolute"
        sx={{
          // background: " rgb(42,128,244)",
          transition: "all ease .3s",
          background:
            " linear-gradient(90deg, rgba(42,128,244,1) 16%, rgba(48,96,176,1) 36%, rgba(56,56,92,1) 99%)",
        }}
      >
        <Box position="relative">
          <Box
            width="13px"
            height="13px"
            borderRadius="50%"
            position="absolute"
            mt="-5px"
            left="0"
            sx={{ backgroundColor: theme.palette.others.first }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GrowthProgressbar;
