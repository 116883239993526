import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMemo, useState } from 'react';
import { MapContainer, Marker, Polygon, Popup, TileLayer, useMap } from 'react-leaflet';
import MarkerIcon from '../../assets/redMarker.png';
import MarkerIcon2 from '../../assets/yellowMarker.png';

import MarkerIcon4 from '../../assets/greenMarker.png';

import { Close, FullscreenOutlined } from '@mui/icons-material';
import { Box, IconButton, Modal, styled, Typography, useTheme } from '@mui/material';
import FlexBetween from 'components/FlexBetween/FlexBetween';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSensorIndex } from 'States';
import { store } from 'States/rootReducer';
import { setId } from 'States/sensors';
import { getCenterOfSensors } from 'Utils/mapUtils';

const CSSPOP = styled(Popup)(({ mode }) => ({
    '& .leaflet-popup-content-wrapper, .leaflet-popup-tip': {
        background: mode === 'dark' ? ' rgba(29,29,65,0.7) !important' : 'rgba(248,248,252,0.7)  !important',
        backdropFilter: 'blur(7.5px) !important',
        width: '135px',
    },
    '& .leaflet-popup-content-wrapper, .leaflet-popup-content': {
        margin: '10px !important',
    },
    '& .leaflet-popup-tip-container .leaflet-popup-tip': {
        background: mode == 'dark' ? 'rgba(29,29,65,0.6)!important' : 'rgba(248,248,252,0.7)  !important',
        backdropFilter: 'blur(7.5px) !important',
    },
    '& .leaflet-popup-close-button': {
        display: 'none !important',
    },
}));

var myIcon = L.icon({
    iconUrl: MarkerIcon,
    iconSize: [19.19, 21.58],
    iconAnchor: [10, 21.58],
    popupAnchor: [2, -10],
});
var myIcon2 = L.icon({
    iconUrl: MarkerIcon2,
    iconSize: [19.19, 21.58],
    iconAnchor: [10, 21.58],
    popupAnchor: [2, -30],
});
var myIcon3 = L.icon({
    iconUrl: MarkerIcon4,
    iconSize: [19.19, 21.58],
    iconAnchor: [10, 21.58],
    popupAnchor: [2, -30],
});
const MapBox = styled(Box)(({ theme, mode }) => ({
    position: 'relative',
    width: '100% !important',
    padding: '0px',
    maxHeight: '100%',
    '& .leaflet-left': {
        left: '94%',
    },
    '& .leaflet-bar': {
        border: 'none',
    },
    '& .leaflet-control-zoom-in': {
        width: '24px !important',
        height: '24px !important',
        backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7) !important' : 'rgba(250, 250, 252, 0.7) !important',
        color: theme.palette.fontColor.alt,
        borderRadius: '8px 8px 0 0 !important',
    },

    '& .leaflet-touch .leaflet-bar a': {
        lineHeight: '24px !important',
    },
    '& .leaflet-control-zoom-out': {
        width: '24px !important',
        height: '24px !important',
        backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7 )!important' : 'rgba(250, 250, 252, 0.7) !important',
        color: theme.palette.fontColor.alt,
        borderRadius: '0 0 8px 8px !important',
    },
}));

function CustomMarker({ location, lastData, type, id, title }) {
    const [t, setT] = useState(type);
    const [idSelected, setIdSelected] = useState(id);
    const navigate = useNavigate();
    const getIcon = (type) => {
        switch (type) {
            case 'SOIL':
                return myIcon;
            case 'WEATHER':
                return myIcon2;
            case 'WATER':
                return myIcon3;
            case 'PLANT':
                return myIcon3;

            default:
                break;
        }
    };

    const theme = useTheme();
    const mode = useSelector((state) => state.global.mode);

    if (location)
        return (
            <Marker position={location} icon={getIcon(type)}>
                <CSSPOP mode={mode} sx={{ zIndex: 1000 }}>
                    <FlexBetween flexDirection='column' sx={{ alignItems: 'flex-start', mb: '0.5rem' }}>
                        <Typography variant='smallestTextPrimary' color={theme.palette.fontColor.main}>
                            سنسور هوشمند
                        </Typography>
                        <Typography variant='smallestTextSecondary' color={theme.palette.fontColor.main}>
                            {title}
                        </Typography>
                    </FlexBetween>
                    <FlexBetween sx={{ mb: '0.2rem' }}>
                        <Typography variant='smallestTextSecondary' color={theme.palette.fontColor.main}>
                            دمای هوا:
                        </Typography>
                        <Typography variant='h4' color={theme.palette.others.third}>
                            {lastData?.temperature ? `${lastData.temperature.v}°` : '-'}
                        </Typography>
                    </FlexBetween>

                    <FlexBetween sx={{ mb: '0.2rem' }}>
                        <Typography variant='smallestTextSecondary' color={theme.palette.fontColor.main}>
                            رطوبت هوا:
                        </Typography>
                        <Typography variant='h4' color={theme.palette.others.second}>
                            {lastData?.humidity
                                ? `${lastData.humidity.v}%`
                                : lastData?.humidity_6
                                ? `${lastData.humidity_6.v}%`
                                : '-'}
                        </Typography>
                    </FlexBetween>

                    <FlexBetween sx={{ mb: '0.2rem' }}>
                        <Typography variant='smallestTextSecondary' color={theme.palette.fontColor.main}>
                            دمای خاک:
                        </Typography>
                        <Typography variant='h4' color={theme.palette.others.third}>
                            -
                        </Typography>
                    </FlexBetween>

                    <FlexBetween sx={{ mb: '0.2rem' }}>
                        <Typography variant='smallestTextSecondary' color={theme.palette.fontColor.main}>
                            رطوبت خاک:
                        </Typography>
                        <Typography variant='h4' color={theme.palette.others.third}>
                            -
                        </Typography>
                    </FlexBetween>
                    <Box
                        display='flex'
                        justifyContent='flex-start'
                        // onClick={(type) => console.log("ttttt==========>", t)}
                    >
                        <Box
                            // to={"/sensormanagement"}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (t === 'WEATHER') store.dispatch(setSensorIndex({ sensorIndex: 1 }));
                                if (t === 'SOIL') store.dispatch(setSensorIndex({ sensorIndex: 2 }));
                                if (t === 'PLANT') store.dispatch(setSensorIndex({ sensorIndex: 3 }));
                                store.dispatch(setId({ id: id }));
                                navigate('/sensormanagement');
                            }}
                        >
                            <Typography color={theme.palette.others.first} variant='smallestTextSecondary'>
                                <span
                                    style={{
                                        width: '40px',
                                        borderBottom: `1px solid ${theme.palette.others.first}`,
                                    }}
                                >
                                    جزییات بیشتر
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                </CSSPOP>
            </Marker>
        );
}

const MapComponent = ({ check }) => {
    const theme = useTheme();
    const mode = useSelector((state) => state.global.mode);
    const idd = useSelector((state) => state.sensors.id);
    const d = useSelector((state) => state.dashboard.remote);
    const [mapModal, setMapModal] = useState(false);
    const polygon = useMemo(() => d.property.boundary.coordinates[0]);

    const [position, setPosition] = useState(getCenterOfSensors(d));

    useEffect(() => {}, [mode]);
    function CalculatePolygonCenter(d) {
        const { devices } = d;
        let sumX = 0;
        let sumY = 0;

        for (let i = 0; i < devices.length; i++) {
            sumX += devices[i].location.x;
            sumY += devices[i].location.y;
        }

        let centerX = sumX / devices.length;
        let centerY = sumY / devices.length;

        return [centerX, centerY];
    }

    const center = CalculatePolygonCenter(d);

    function MapView() {
        const map = useMap();
        map.fitBounds(polygon);
        return null;
    }
    return (
        <MapBox theme={theme} mode={mode}>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '10px',
                    width: '24px',
                    height: '24px',
                    backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                    backdropFilter: 'blur(7.5px)',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1000,
                }}
            >
                <IconButton>
                    <FullscreenOutlined
                        onClick={() => setMapModal(true)}
                        sx={{
                            width: '22px',
                            height: '22px',
                        }}
                    />
                </IconButton>
            </Box>
            <Modal
                open={mapModal}
                onClose={() => setMapModal(false)}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                sx={{
                    '& .leaflet-left': {
                        left: '96.5%',
                    },
                    '& .leaflet-bar': {
                        border: 'none',
                    },
                    '& .leaflet-control-zoom-in': {
                        width: '24px !important',
                        height: '24px !important',
                        backgroundColor:
                            mode === 'dark'
                                ? 'rgba(29, 29, 65, 0.7) !important'
                                : 'rgba(250, 250, 252, 0.7) !important',
                        color: theme.palette.fontColor.alt,
                        borderRadius: '8px 8px 0 0 !important',
                    },

                    '& .leaflet-touch .leaflet-bar a': {
                        lineHeight: '24px !important',
                    },
                    '& .leaflet-control-zoom-out': {
                        width: '24px !important',
                        height: '24px !important',
                        backgroundColor:
                            mode === 'dark'
                                ? 'rgba(29, 29, 65, 0.7 )!important'
                                : 'rgba(250, 250, 252, 0.7) !important',
                        color: theme.palette.fontColor.alt,
                        borderRadius: '0 0 8px 8px !important',
                    },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',

                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme.palette.background.second,
                        '& .leaflet-container': {
                            height: '100%',
                        },
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50px',
                            left: '3rem',
                            width: '24px',
                            height: '24px',
                            backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                            backdropFilter: 'blur(7.5px)',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1000,
                        }}
                    >
                        <IconButton>
                            <Close
                                onClick={() => setMapModal(false)}
                                sx={{
                                    width: '22px',
                                    height: '22px',
                                }}
                            />
                        </IconButton>
                    </Box>
                    <MapContainer
                        center={position}
                        // zoom={15}
                        style={{ borderRadius: '8px', height: '100% !important' }}
                    >
                        <TileLayer
                            attribution='PTR'
                            url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            maxZoom={22}
                            maxNativeZoom={18}
                        />
                        <Polygon positions={polygon} />
                        {check
                            ? d.devices.map((item) => (
                                  <CustomMarker
                                      lastData={item.lastData}
                                      location={[item.location.x, item.location.y]}
                                      type={item.type}
                                      id={item.deviceId}
                                      title={item.deviceTitle}
                                  />
                              ))
                            : null}
                        {d.property.zones.map((item) => (
                            <Polygon positions={item.boundary.coordinates[0]} />
                        ))}
                        <MapView center={center} />
                    </MapContainer>
                </Box>
            </Modal>
            <MapContainer
                center={position}
                // zoom={15}
                style={{ borderRadius: '12px', height: '38vh' }}
            >
                <TileLayer
                    attribution='PTR'
                    url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    maxZoom={22}
                    maxNativeZoom={18}
                />
                <Polygon positions={polygon} />
                {check
                    ? d.devices.map((item) => (
                          <CustomMarker
                              lastData={item.lastData}
                              location={[item.location.x, item.location.y]}
                              type={item.type}
                              id={item.deviceId}
                              title={item.deviceTitle}
                          />
                      ))
                    : null}
                {d.property.zones.map((item) => (
                    <Polygon positions={item.boundary.coordinates[0]} />
                ))}
                <MapView center={center} />
            </MapContainer>
        </MapBox>
    );
};

export default MapComponent;
