import { Box, Rating, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useState } from "react";

const ExpertItemMob = ({ data, active, setExpertId }) => {
  const theme = useTheme();

  const [value, setValue] = useState(data.rate);

  return (
    <Box
      onClick={() => setExpertId(data.id)}
      width="218px"
      height="191px"
      borderRadius="4px"
      position="relative"
    >
      {active && (
        <Box
          width="60px"
          height="23px"
          borderRadius="4px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: "12px",
            right: "12px",
            backgroundColor: theme.palette.others.third,
          }}
        >
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.background.third}
          >
            فعال
          </Typography>
        </Box>
      )}
      <Box
        width="218px"
        height="100%"
        component="img"
        src={data.pic}
        borderRadius="4px"
      />
      <Box
        position="absolute"
        width="194px"
        // height="80px"
        borderRadius="4px"
        sx={{
          bottom: "0.5rem",
          left: "50%",
          transform: "translateX(-50%)",
          transition: "all ease 0.3s",
          background: "rgba(29, 29, 65, 0.7)",
          backdropDrop: "blur(7.5px)",
          padding: "12px",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "center",
        }}
      >
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <Typography variant="h5">{data.name}</Typography>
          <FlexBetween gap="0.5rem">
            <Rating
              size="small"
              readOnly
              name="size-small"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              {data.loc}
            </Typography>
          </FlexBetween>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpertItemMob;
