import {
  Box,
  Divider,
  Rating,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import WalletFeatureItem from "./WalletFeatureItem";
import E1 from "../../assets/experts/1.png";
import E2 from "../../assets/experts/2.png";
import E3 from "../../assets/experts/3.png";
import E4 from "../../assets/experts/4.png";
import E5 from "../../assets/experts/5.png";
import E6 from "../../assets/experts/6.png";
import ExpertItem from "./ExpertItem";
import FlexBetween from "components/FlexBetween/FlexBetween";
const Experts = () => {
  const theme = useTheme();

  const [value, setValue] = React.useState(10);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box width="100%">
      <Typography variant="h3">کارشناس ها</Typography>
      <Tabs
        //   ref={scrollRef}
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { transition: "none" },
        }}
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          direction: "ltr",
          height: "194px",
          width: "100%",
          mt: "1rem",
          //   backgroundColor: "red",
          display: "flex",
          alignItems: "center",

          "& .MuiTabs-scrollButtons": {
            width: "40px",
            height: "100px",
            borderRadius: "8px",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        <Box position="relative">
          <ExpertItem name="امیر ناصری" pic={E1} rate={4} />
        </Box>
        <Box position="relative">
          <ExpertItem name="علی مرادی" pic={E2} rate={4} />
        </Box>
        <Box position="relative">
          <ExpertItem name="هانیه رضایی" pic={E3} rate={4} />
        </Box>
        <Box position="relative">
          <ExpertItem name="حسین محمدی" pic={E4} rate={4} />
        </Box>
        <Box position="relative">
          <ExpertItem name="سینا هوشمند" pic={E5} rate={4} />
        </Box>
        <Box position="relative">
          <ExpertItem name="امیر ناصری" pic={E6} rate={4} />
        </Box>
      </Tabs>
    </Box>
  );
};

export default Experts;
