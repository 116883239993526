import { CloseOutlined, DangerousOutlined } from "@mui/icons-material";
import {
  Box,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Form from "components/Form/Form";
import React, { useEffect } from "react";
import LoginPic from "../../assets/Register.png";
import InstagramIcon from "../../assets/instagram.svg";
import YoutubeIcon from "../../assets/youtube.svg";
import LinkdinIcon from "../../assets/linkedin.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginMob from "mob/scenesMob/LoginMob/LoginMob";
import AnimatedProgressBar from "../../components/AnimatedProgressBar/AnimatedProgressBar";
const Login = () => {
  const theme = useTheme();
  const token = useSelector((state) => state.global.token);
  const [open, setOpen] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  const mode = useSelector((state) => state.global.mode);
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);
  return (
    <>
      {isMobileScreens ? (
        <>
          <LoginMob />
        </>
      ) : (
        <>
          <FlexBetween
            sx={{
              width: "100vw",
              height: "100vh",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ position: "absolute", width: "100%", height: "100%" }}
              component="img"
              src={LoginPic}
            />
            <Box sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
              {mode === "dark" ? (
                <img
                  alt="img1"
                  src={require("../../assets/LogoColor.png")}
                  style={{
                    width: "52px",
                    height: "40px",
                    objectFit: "cover",
                  }}
                ></img>
              ) : (
                <img
                  alt="img1"
                  src={require("../../assets/LogoColor.png")}
                  style={{
                    width: "52px",
                    height: "40px",
                    objectFit: "cover",
                  }}
                ></img>
              )}
            </Box>
            <Box
              sx={{
                width: "1400px",
                height: "600px",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box
                sx={{
                  width: "36.375rem",
                  height: "32.5rem",

                  backgroundColor: "rgba(248, 248, 252, 0.3)",
                  backdropFilter: "blur(7.5px)",
                  borderRadius: "24px",
                  zIndex: 1000,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Form setOpen={setOpen} />
              </Box>
              <Box
                sx={{
                  width: "38.375rem",
                  height: "32.5rem",

                  zIndex: 1000,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography fontSize="40px" color="#F7F7F7" fontWeight="500">
                  سامانه مدیریت هوشمند رهبان
                </Typography>
                <Typography fontSize="16px" color="#F7F7F7" mt="8px">
                  بهتر است نبض مزرعه تان در دست خودتان باشد
                </Typography>
                <Box display="flex" gap="10px" mt="37px">
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#697c63",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      onClick={() =>
                        (window.location.href =
                          "https://www.linkedin.com/company/partotaprayan/")
                      }
                      sx={{ width: "32px", height: "32px" }}
                      component="img"
                      src={LinkdinIcon}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#697c63",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      onClick={() =>
                        (window.location.href =
                          "https://youtube.com/@partotaprayan4313")
                      }
                      sx={{ width: "32px", height: "32px" }}
                      component="img"
                      src={YoutubeIcon}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "40px",
                      height: "40px",
                      backgroundColor: "#697c63",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      onClick={() =>
                        (window.location.href =
                          "https://www.instagram.com/partotaprayan/?igshid=YmMyMTA2M2Y=")
                      }
                      sx={{ width: "32px", height: "32px" }}
                      component="img"
                      src={InstagramIcon}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box position="absolute" zIndex="1000" sx={{ bottom: "30px" }}>
              <Typography color="#FAFAFC" fontSize="14px" mt="5rem" ml="2.5rem">
                کلیه حقوق این سامانه متعلق به شرکت پرتوتاپ رایان است
              </Typography>
            </Box>

            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleClose}
              anchorOrigin={{ vertical, horizontal }}
              dir="rtl"
            >
              <FlexBetween
                gap="1rem"
                sx={{
                  backgroundColor: theme.palette.others.second,
                  p: "0.5rem 0.5rem",
                  borderRadius: "8px",
                }}
              >
                <FlexBetween gap="0.5rem">
                  <DangerousOutlined sx={{ width: "24px", height: "24px" }} />
                  <Typography>ورود ناموفق بود لطفا مجددا تلاش کنید</Typography>
                </FlexBetween>
                <CloseOutlined
                  onClick={handleClose}
                  sx={{ width: "24px", height: "24px" }}
                />
              </FlexBetween>
            </Snackbar>
          </FlexBetween>
        </>
      )}
    </>
  );
};

export default Login;
