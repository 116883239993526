import { Box } from '@mui/material'
import Step1 from '../assets/growthStep/1.png'
import Step2 from '../assets/growthStep/2.png'
import Step3 from '../assets/growthStep/3.png'
import Step4 from '../assets/growthStep/4.png'
import Step5 from '../assets/growthStep/5.png'
import Step6 from '../assets/growthStep/6.png'
export const getSVG = (step,size) => {
  if (size === 0){
    switch (step) {
      case 1:
        return (
         <Box component="img" src={Step1}/>
        );
      case 2:
        return (
          <Box component="img" src={Step2}/>
        );
      case 3:
        return (
          <Box component="img" src={Step3}/>
        );
      case 4:
        return (
          <Box component="img" src={Step4}/>
        );
      case 5:
        return (
          <Box component="img" src={Step5}/>
        );
      case 6:
        return (
          <Box component="img" src={Step6}/>
        );

      default:
        break;
    }
  }else
  switch (step) {
    case 1:
      return (
       <Box width="60px" height="56px" component="img" src={Step1}/>
      );
    case 2:
      return (
        <Box width="60px" height="56px" component="img" src={Step2}/>
      );
    case 3:
      return (
        <Box width="60px" height="56px" component="img" src={Step3}/>
      );
    case 4:
      return (
        <Box width="60px" height="56px" component="img" src={Step4}/>
      );
    case 5:
      return (
        <Box width="60px" height="56px" component="img" src={Step5}/>
      );
    case 6:
      return (
        <Box width="60px" height="56px" component="img" src={Step6}/>
      );

    default:
      break;
  }
  };