export const PickingCowHoofsData = {
    type: "سم چینی",
    performance: [
      ["05/12", 850],
      ["05/13", 820],
      ["05/14", 810],
      ["05/15", 780],
      ["05/16", 606],
      ["05/17", 710],
      ["05/18", 850],
      ["05/19", 770],
    ],
    status: [[770, 750], "سم چینی شده ها"],
  
    livestock: [
      {
        code: 900,
        date: "1402/03/10",
        rightHand: "درماتیت بین انگشتی",
        leftHand: "دولایه",
        rightLeg: "ساییدگی پاشنه",
        leftLeg: "ساییدگی پاشنه",
        nextTime: "1402/06/17",
        milk: 9,
      },
      {
        code: 899,
        date: "1402/02/22",
        rightHand: "درماتیت بین انگشتی",
        leftHand: "دولایه",
        rightLeg: "ساییدگی پاشنه",
        leftLeg: "ساییدگی پاشنه",
        nextTime: "1402/06/17",
        milk: 8,
      },
      {
        code: 898,
        date: "1402/02/20",
        rightHand: "درماتیت بین انگشتی",
        leftHand: "دولایه",
        rightLeg: "ساییدگی پاشنه",
        leftLeg: "ساییدگی پاشنه",
        nextTime: "1402/06/15",
        milk: 9,
      },
      {
        code: 897,
        date: "1402/02/15",
        rightHand: "درماتیت بین انگشتی",
        leftHand: "دولایه",
        rightLeg: "ساییدگی پاشنه",
        leftLeg: "ساییدگی پاشنه",
        nextTime: "1402/06/10",
        milk: 8,
      },
    ],
  };