import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Box, FormControl, MenuItem, Select, styled, Typography, useTheme } from '@mui/material';
import { getLayerData } from 'api/remoteSensing';
import { useFetchFarmData } from 'hooks/useFetchData';
import { useEffect, useMemo, useRef, useState } from 'react';
import { WMSTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { strQueryParams } from 'Utils/stringUtils';
import { getDateShamsiAirSensors } from 'Utils/Utils';
import L from 'leaflet';
import DatePicker from 'react-multi-date-picker';
import calendar from '../../assets/calendar.svg';
import calendarLight from '../../assets/calendar-light.svg';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import moment from 'moment';

const StyledMenuList = styled(MenuItem)(({ theme }) => ({
    /* Use the prop `theme` to apply dynamic styles */
    backgroundColor: theme.palette.background.third,
    boxShadow: 'none !important',
    '& .MuiPaper-root': { background: 'red' },
}));
const MapCompareSide = ({ direction, nameIndex }) => {
    const datePickerRef = useRef();
    const mode = useSelector((state) => state.global.mode);
    const theme = useTheme();
    const indexes = useSelector((state) => state.remoteSensing.indexes);
    const activeIndex = useSelector((state) => state.remoteSensing.activeIndex);
    const idFarm = useSelector((state) => state.remoteSensing.idFarm);
    const firstDate = useSelector((state) => state.remoteSensing.firstDate);
    const secondDate = useSelector((state) => state.remoteSensing.secondDate);
    const boundary = useSelector((state) => state.remoteSensing.boundary);
    const clip = useMemo(() => {
        return 'POLYGON((' + boundary?.map((p) => p[1] + ' ' + p[0]).reduce((s, p) => (s ? s + ',' : '') + p) + '))';
    }, [boundary]);
    const [selectedLayer, setSelectedLayer] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(nameIndex);
    const [selectedIndexIndex, setSelectedIndexIndex] = useState(activeIndex);
    const layersData = useFetchFarmData(
        getLayerData,
        idFarm,
        selectedIndex,
        strQueryParams({ start: firstDate, end: secondDate }),
    );
    const layers = layersData?.response?.data?.content;
    const sortedLayers = layers?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    useEffect(() => {
        if (sortedLayers) {
            setSelectedLayer(sortedLayers[0]);
        }
    }, [sortedLayers]);
    const handleChangeIndex = (e) => {
        setSelectedIndexIndex(e.target.value);
        setSelectedIndex(indexes[e.target.value].index);
    };
    const handleDateChange = (value) => {
        const formattedValue = moment(new Date(value)).format('YYYY-MM-DD');
        const layerIndex = layers.findIndex((layer) => layer.date === formattedValue);
        setSelectedLayer(layers[layerIndex]);
    };
    const url = selectedLayer?.wmsUrl;
    const layer = selectedLayer?.wmsLayer;
    return (
        <>
            {url && layer && clip && (
                <WMSTileLayer
                    // make layer refresh on URL change
                    pane={direction}
                    key={url}
                    url={url}
                    params={{
                        version: '1.3.0',
                        layers: layer,
                        clip: clip,
                        transparent: true,
                    }}
                    format='image/png'
                    opacity={1}
                    crs={L.CRS.EPSG4326}
                />
            )}
            <Box
                sx={{
                    position: 'absolute',
                    [direction]: direction === 'left' ? 50 : 10,
                    top: 20,
                    width: '163px',
                    height: '41px',
                    backgroundColor: theme.palette.background.second,
                    zIndex: 1000,
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                }}
            >
                <FormControl
                    variant='standard'
                    sx={{ m: 1, mt: '0.75rem', minWidth: '90%', background: theme.palette.background.second }}
                >
                    <Select
                        IconComponent={() => <KeyboardArrowDownOutlined />}
                        disableUnderline
                        value={selectedIndexIndex}
                        onChange={handleChangeIndex}
                        sx={{
                            ml: '0.5rem',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#40405e',
                            },
                        }}
                    >
                        {indexes?.map((item, idx) => (
                            <StyledMenuList
                                value={idx}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    m: '0 5px',

                                    backgroundColor: `${theme.palette.background.third}!important`,
                                    ':hover': {
                                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                                        borderRadius: '4px',
                                    },
                                    '& .muirtl-6hp17o-MuiList-root-MuiMenu-list ': {
                                        background: 'red !important',
                                    },
                                }}
                            >
                                <Typography color={theme.palette.fontColor.main} fontSize={theme.typography.body}>
                                    {item.name}
                                </Typography>
                            </StyledMenuList>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    [direction]: 10,
                    padding: '0 1rem',
                    bottom: 20,
                    width: '163px',
                    height: '41px',
                    background: theme.palette.background.second,
                    zIndex: 1000,
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ddd',
                    },
                    '& .rmdp-wrapper': {
                        backgroundColor: theme.palette.background.third,
                        width: '250px',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    },
                    '& .rmdp-container': {
                        width: '100%',
                        display: 'block',
                    },
                    '& .rmdp-day': {
                        color: theme.palette.fontColor.main,
                    },
                    '& .rmdp-arrow': {
                        borderColor: theme.palette.fontColor.main,
                    },
                    '& .rmdp-week-day': {
                        color: theme.palette.fontColor.alt,
                    },
                    '& .rmdp-header-values span': {
                        color: theme.palette.fontColor.main,
                    },
                    '& .rmdp-panel-header': {
                        color: theme.palette.fontColor.main,
                    },
                    '& .rmdp-ep-arrow': {
                        borderBottom: 'none',
                        borderTop: 'none',
                    },
                    '& .ep-arrow::after': {
                        backgroundColor: theme.palette.background.third,
                    },
                    '& .rmdp-ep-shadow:after': {
                        boxShadow: 'none',
                    },
                    '& .rmdp-month-picker': {
                        backgroundColor: theme.palette.background.third,
                    },
                    '& .rmdp-year-picker': {
                        backgroundColor: theme.palette.background.third,
                    },
                    '& .rmdp-input': {
                        color: 'white !important',
                        fontFamily: 'YekanBakhFaNum-Regular',
                    },
                    '& .rmdp-top-class': {
                        backgroundColor: theme.palette.background.third,
                        color: '#fff',
                    },
                    '& .rmdp-day.rmdp-today span': {
                        backgroundColor: 'rgba(42,128,244,0.5)',
                    },
                    '& .rmdp-day.rmdp-selected span:not(.highlight)': {
                        backgroundColor: theme.palette.primary.alt,
                    },
                    '& .rmdp-day .sd': {
                        color: theme.palette.fontColor.main,
                    },
                    '& .rmdp-week .rmdp-week-day': {
                        color: theme.palette.fontColor.alt,
                    },
                    '& .rmdp-header-values': {
                        color: theme.palette.fontColor.main,
                    },
                    '& .rmdp-shadow': {
                        boxShadow: 'none',
                    },
                    '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
                        backgroundColor: theme.palette.primary.alt,
                    },
                }}
            >
                {selectedLayer && (
                    <DatePicker
                        render={
                            <div
                                onClick={() => datePickerRef.current.openCalendar()}
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    src={mode === 'dark' ? calendar : calendarLight}
                                    alt='calendar icon'
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                    }}
                                />
                                <div>{getDateShamsiAirSensors(selectedLayer.date)}</div>
                            </div>
                        }
                        calendarPosition={`top-${direction}`}
                        className='bg-dark rmdp-prime'
                        calendar={persian}
                        locale={persian_fa}
                        weekDays={['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']}
                        value={getDateShamsiAirSensors(selectedLayer.date)}
                        onChange={handleDateChange}
                        ref={datePickerRef}
                        mapDays={({ date }) => {
                            const formatedDate = moment(new Date(date)).format('YYYY-MM-DD');
                            const isValid = layers?.map((layer) => layer.date).includes(formatedDate);
                            if (!isValid)
                                return {
                                    disabled: true,
                                    style: { color: theme.palette.fontColor.alt },
                                };
                            return {
                                style: { fontWeight: 'bold' },
                            };
                        }}
                    />
                )}
            </Box>
        </>
    );
};

export default MapCompareSide;
