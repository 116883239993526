import React, { useState } from "react";
import L, { map } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import {
  Box,
  IconButton,
  Modal,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import ButtonsSensorsManagementAll from "scenes/SensorsManagementAll/ButtonsSensorsManagementAll";

import {
  Close,
  DownloadOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";
import ButtonMap from "./ButtonMap";
import { store } from "States/rootReducer";
import { setId } from "States/sensors";
import FlexBetween from "components/FlexBetween/FlexBetween";
import MarkerIcon from "../../assets/redMarker.png";
import MarkerIcon2 from "../../assets/yellowMarker.png";
import MarkerIcon4 from "../../assets/greenMarker.png";

const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "relative",
  width: "100%",
  height: "95%",
  padding: "0px",
  maxHeight: "80%",
  "& .leaflet-left": {
    left: "1.7%",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));

const CSSPOP = styled(Popup)(({ mode }) => ({
  "& .leaflet-popup-content-wrapper, .leaflet-popup-tip": {
    background:
      mode === "dark"
        ? " rgba(29,29,65,0.7) !important"
        : "rgba(248,248,252,0.7)  !important",
    backdropFilter: "blur(7.5px) !important",
    width: "135px",
  },
  "& .leaflet-popup-content-wrapper, .leaflet-popup-content": {
    margin: "10px !important",
  },
  "& .leaflet-popup-tip-container .leaflet-popup-tip": {
    background:
      mode == "dark"
        ? "rgba(29,29,65,0.6)!important"
        : "rgba(248,248,252,0.7)  !important",
    backdropFilter: "blur(7.5px) !important",
  },
  "& .leaflet-popup-close-button": {
    display: "none !important",
  },
}));
var myIcon = L.icon({
  iconUrl: MarkerIcon,
  iconSize: [19.19, 21.58],
  iconAnchor: [10, 21.58],
  popupAnchor: [2, -10],
});
var myIcon2 = L.icon({
  iconUrl: MarkerIcon2,
  iconSize: [19.19, 21.58],
  iconAnchor: [10, 21.58],
  popupAnchor: [2, -30],
});
var myIcon3 = L.icon({
  iconUrl: MarkerIcon4,
  iconSize: [19.19, 21.58],
  iconAnchor: [10, 21.58],
  popupAnchor: [2, -30],
});

function CustomMarker({ location, setNeighbor }) {
  // const [t, setT] = useState(type);
  // const [idSelected, setIdSelected] = useState(id);
  // const navigate = useNavigate();
  const getIcon = (type) => {
    switch (type) {
      case "SOIL":
        return myIcon;
      case "WEATHER":
        return myIcon2;
      case "WATER":
        return myIcon3;
      case "PLANT":
        return myIcon3;

      default:
        break;
    }
  };

  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);

  if (location)
    return (
      <Marker position={location} icon={myIcon}>
        <CSSPOP mode={mode} sx={{ zIndex: 1000 }}>
          <FlexBetween
            flexDirection="column"
            sx={{ alignItems: "flex-start", mb: "0.5rem" }}
          >
            <Typography variant="h5" color={theme.palette.fontColor.main}>
              آقای احمدی
            </Typography>
            <Typography
              variant="smallestTextSecondary"
              color={theme.palette.fontColor.main}
            ></Typography>
          </FlexBetween>
          <FlexBetween sx={{ mb: "0.2rem" }}>
            <Typography
              variant="smallestTextSecondary"
              color={theme.palette.fontColor.main}
            >
              باغ سیب
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.others.third}
            ></Typography>
          </FlexBetween>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="111px"
            height="27px"
            borderRadius="8px"
            mt="16px"
            sx={{
              cursor: "pointer",
              backgroundColor: theme.palette.others.first,
            }}
            onClick={() => setNeighbor((prev) => !prev)}
          >
            <Typography variant="smallestTextSecondary" color="fontColor.main">
              خرید داده
            </Typography>
          </Box>
        </CSSPOP>
      </Marker>
    );
}
const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);

  return null;
};

const WalletMap = ({ setNeighbor }) => {
  const mode = useSelector((state) => state.global.mode);
  useEffect(() => {}, [mode]);
  const position = [35.643029, 52.224928];
  const [mapModal, setMapModal] = useState(false);
  const theme = useTheme();
  return (
    <Box height="100%">
      <Typography variant="h3" color={theme.palette.fontColor.main} mb="2rem">
        خرید داده از همسایه
      </Typography>
      <MapBox mode={mode}>
        <ButtonMap individual={false} setMapModal={setMapModal} />
        <Modal
          open={mapModal}
          onClose={() => setMapModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            zIndex: 200000,
            "& .leaflet-left": {
              left: "14px",
            },
            "& .leaflet-bar": {
              border: "none",
            },
            "& .leaflet-control-zoom-in": {
              width: "24px !important",
              height: "24px !important",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7) !important"
                  : "rgba(250, 250, 252, 0.7) !important",
              color: theme.palette.fontColor.alt,
              borderRadius: "8px 8px 0 0 !important",
            },

            "& .leaflet-touch .leaflet-bar a": {
              lineHeight: "24px !important",
            },
            "& .leaflet-control-zoom-out": {
              width: "24px !important",
              height: "24px !important",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7 )!important"
                  : "rgba(250, 250, 252, 0.7) !important",
              color: theme.palette.fontColor.alt,
              borderRadius: "0 0 8px 8px !important",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.background.second,
              "& .leaflet-container": {
                height: "100% !important",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "120px",
                left: "3.5rem",
                width: "24px",
                height: "24px",
                backgroundColor:
                  mode === "dark"
                    ? "rgba(29, 29, 65, 0.7)"
                    : "rgba(250, 250, 252, 0.7)",
                backdropFilter: "blur(7.5px)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
              }}
            >
              <IconButton>
                <Close
                  onClick={() => setMapModal(false)}
                  sx={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              </IconButton>
            </Box>

            <MapContainer
              center={position}
              zoom={15.5}
              style={{ borderRadius: "8px", height: "241px", width: "100%" }}
            >
              {/* <ChangeView center={position} zoom={15.5} /> */}
              <TileLayer
                attribution="PTR"
                url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                maxZoom={22}
                maxNativeZoom={18}
              />
            </MapContainer>
          </Box>
        </Modal>

        <MapContainer
          center={position}
          zoom={15.5}
          style={{ borderRadius: "8px", height: "100%", width: "100%" }}
        >
          {/* <ChangeView center={position} zoom={15.5} /> */}
          <TileLayer
            attribution="PTR"
            url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
            maxZoom={22}
            maxNativeZoom={18}
          />
          <CustomMarker
            // lastData={item.lastData}
            location={[35.643529, 52.228258]}
            setNeighbor={setNeighbor}
          />
        </MapContainer>
      </MapBox>
    </Box>
  );
};

export default WalletMap;
