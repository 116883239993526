import {
  AddBoxOutlined,
  DangerousOutlined,
  MoreHoriz,
  PrecisionManufacturingOutlined,
} from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import WaterIcon from "../assets/water1.png";
import SoilIcon from "../assets/soil.png";
import DangerIcon from "../assets/dang.png";
import RobotIcon from "../assets/robot.png";
import AvatarImage from "../assets/profile.jpg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Rec1Icon from "../assets/rec1.png";
import Rec2Icon from "../assets/rec2.png";
import Rec3Icon from "../assets/rec3.png";
const Recommends = () => {
  const theme = useTheme();
  const recData = useSelector((state) => state.dashboard.recommendations);
  const navigate = useNavigate();
  return (
    <>
      {/* {!recData.enabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="88px"
            height="27px"
            sx={{
              backgroundColor: theme.palette.others.first,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            // onClick={() => navigate("/wallet")}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              فعال سازی
            </Typography>
          </Box>
        </Box>
      )} */}
      <Box sx={{ opacity: recData.enabled ? 1 : 1 }}>
        <FlexBetween>
          <Typography variant="h5" mb="0.15rem">
            توصیه ها
          </Typography>
          <MoreHoriz
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/recommends")}
          />
        </FlexBetween>
        <FlexBetween mb="0.25rem">
          <FlexBetween gap="0.5rem">
            <Box component="img" src={Rec1Icon} />
            <Typography variant="body" color="fontColor.main">
              غذای بیشتر به دام
            </Typography>
          </FlexBetween>
          <PrecisionManufacturingOutlined
            sx={{ width: "24px", height: "24px" }}
          />
        </FlexBetween>
        <FlexBetween mb="0.25rem">
          <FlexBetween gap="0.5rem">
            <Box component="img" src={Rec2Icon} />
            <Typography variant="body" color="fontColor.main">
              سرمای بیشتر بهاربند 1
            </Typography>
          </FlexBetween>
          <Avatar src={AvatarImage} sx={{ width: "24px", height: "24px" }} />
        </FlexBetween>

        <FlexBetween>
          <FlexBetween gap="0.5rem">
            <Box
              component="img"
              src={Rec3Icon}
              sx={{ width: "32px", height: "32px" }}
            />
            <Typography variant="body" color="fontColor.main">
              افزایش آسایش بهاربند 3
            </Typography>
          </FlexBetween>
          <IconButton sx={{ width: "24px", height: "24px" }}>
            <AddBoxOutlined sx={{ width: "24px", height: "24px" }} />
          </IconButton>
        </FlexBetween>
      </Box>
    </>
  );
};

export default Recommends;
