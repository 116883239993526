const FahlData = [
    {
      type: "w",
      code: 22,
      date: "1402/02/12",
      time: "12:30",
      status: "قابل تلقیح",
      fahlTime: 18,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "p",
      code: 420,
      date: "1402/02/16",
      time: "22:30",
      res: "",
      status: "تلیسه",
      fahlTime: 30,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "s",
      code: 56,
      date: "1402/02/15",
      time: "21:30",
      res: "",
      status: "تلیسه",
      fahlTime: 22,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "s",
      code: 16,
      date: "1402/05/19",
      time: "22:30",
      res: "",
      status: "تلیسه",

      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
  ];


  export default FahlData