export const AnimalComfortData = [
    {
      title: "بهاربند 1",
      baharband: [
        ["05/12", 910],
        ["05/13", 820],
        ["05/14", 900],
        ["05/15", 672],
        ["05/16", 650],
        ["05/17", 723],
        ["05/18", 831],
        ["05/19", 850],
      ],
      all: [
        ["05/12", 810],
        ["05/13", 820],
        ["05/14", 750],
        ["05/15", 672],
        ["05/16", 620],
        ["05/17", 623],
        ["05/18", 731],
        ["05/19", 550],
      ],
      mean: 82,
      history: [
        {
          title: "بهاربند 1",
          date: "1402/04/12",
          comfort: 50,
        },
        {
          title: "بهاربند 1",
          date: "1402/04/13",
          comfort: 56,
        },
        {
          title: "بهاربند 1",
          date: "1402/04/15",
          comfort: 65,
        },
        {
          title: "بهاربند 1",
          date: "1402/04/18",
          comfort: 80,
        },
        {
          title: "بهاربند 1",
          date: "1402/05/01",
          comfort: 85,
        },
        {
          title: "بهاربند 1",
          date: "1402/05/03",
          comfort: 88,
        },
      ],
    },
    {
      title: "بهاربند 2",
      baharband: [
        ["05/12", 970],
        ["05/13", 720],
        ["05/14", 800],
        ["05/15", 572],
        ["05/16", 590],
        ["05/17", 323],
        ["05/18", 231],
        ["05/19", 450],
      ],
      all: [
        ["05/12", 810],
        ["05/13", 820],
        ["05/14", 750],
        ["05/15", 672],
        ["05/16", 620],
        ["05/17", 623],
        ["05/18", 731],
        ["05/19", 550],
      ],
      mean: 68,
      history: [
        {
          title: "بهاربند 2",
          date: "1402/04/12",
          comfort: 50,
        },
        {
          title: "بهاربند 2",
          date: "1402/04/13",
          comfort: 56,
        },
        {
          title: "بهاربند 2",
          date: "1402/04/15",
          comfort: 65,
        },
        {
          title: "بهاربند 2",
          date: "1402/04/18",
          comfort: 80,
        },
        {
          title: "بهاربند 2",
          date: "1402/05/01",
          comfort: 85,
        },
        {
          title: "بهاربند 2",
          date: "1402/05/03",
          comfort: 88,
        },
      ],
    },
    {
      title: "بهاربند 3",
      baharband: [
        ["05/12", 940],
        ["05/13", 810],
        ["05/14", 750],
        ["05/15", 562],
        ["05/16", 650],
        ["05/17", 623],
        ["05/18", 621],
        ["05/19", 510],
      ],
      all: [
        ["05/12", 810],
        ["05/13", 820],
        ["05/14", 750],
        ["05/15", 672],
        ["05/16", 620],
        ["05/17", 623],
        ["05/18", 731],
        ["05/19", 550],
      ],
      mean: 45,
      history: [
        {
          title: "بهاربند 3",
          date: "1402/04/12",
          comfort: 50,
        },
        {
          title: "بهاربند 3",
          date: "1402/04/13",
          comfort: 56,
        },
        {
          title: "بهاربند 3",
          date: "1402/04/15",
          comfort: 65,
        },
        {
          title: "بهاربند 3",
          date: "1402/04/18",
          comfort: 80,
        },
        {
          title: "بهاربند 3",
          date: "1402/05/01",
          comfort: 85,
        },
        {
          title: "بهاربند 3",
          date: "1402/05/03",
          comfort: 88,
        },
      ],
    },
    {
      title: "بهاربند 4",
      baharband: [
        ["05/12", 740],
        ["05/13", 710],
        ["05/14", 650],
        ["05/15", 462],
        ["05/16", 550],
        ["05/17", 723],
        ["05/18", 521],
        ["05/19", 610],
      ],
      all: [
        ["05/12", 610],
        ["05/13", 520],
        ["05/14", 450],
        ["05/15", 672],
        ["05/16", 720],
        ["05/17", 423],
        ["05/18", 531],
        ["05/19", 50],
      ],
      mean: 93,
      history: [
        {
          title: "بهاربند 4",
          date: "1402/04/12",
          comfort: 50,
        },
        {
          title: "بهاربند 4",
          date: "1402/04/13",
          comfort: 56,
        },
        {
          title: "بهاربند 4",
          date: "1402/04/15",
          comfort: 65,
        },
        {
          title: "بهاربند 4",
          date: "1402/04/18",
          comfort: 80,
        },
        {
          title: "بهاربند 4",
          date: "1402/05/01",
          comfort: 85,
        },
        {
          title: "بهاربند 4",
          date: "1402/05/03",
          comfort: 88,
        },
      ],
    },
  ];

 export const AnimalComfortHistoryData = [
    {
      title: "بهاربند 1",
      date: "1402/05/05",
      comfort: 50,
    },
    {
      title: "بهاربند 2",
      date: "1402/05/05",
      comfort: 87,
    },
    {
      title: "بهاربند 3",
      date: "1402/05/05",
      comfort: 56,
    },
    {
      title: "بهاربند 4",
      date: "1402/05/05",
      comfort: 67,
    },
    {
      title: "بهاربند 5",
      date: "1402/05/05",
      comfort: 87,
    },
    {
      title: "بهاربند 6",
      date: "1402/05/05",
      comfort: 89,
    },
    {
      title: "بهاربند 1",
      date: "1402/05/09",
      comfort: 85,
    },
    {
      title: "بهاربند 2",
      date: "1402/05/08",
      comfort: 76,
    },
    {
      title: "بهاربند 3",
      date: "1402/05/06",
      comfort: 83,
    },
    {
      title: "بهاربند 1",
      date: "1402/05/08",
      comfort: 78,
    },
  ];