import { Instagram, Telegram, YouTube } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Form from "components/Form/Form";
import React, { useEffect } from "react";
import LoginPic from "../../../assets/register-background.png";
import Logo from "../../../assets/LogoLogin.png";
import InstagramIcon from "../../../assets/instagram.svg";
import YoutubeIcon from "../../../assets/youtube.svg";
import TelegramIcon from "../../../assets/ph_telegram-logo-light.svg";
import FormRegisterFarmer from "components/Form/FormRegisterFarmer";
import FormRegisterMob from "./RegisterFormMob";
import { useSelector } from "react-redux";
const RegisterMob = () => {
  const mode = useSelector((state) => state.global.mode);

  return (
    <FlexBetween
      sx={{
        width: "100vw",
        height: "790px",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overFlowY: "scroll",
        flexGrow: 1,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{ position: "absolute", width: "100%" }}
        component="img"
        src={LoginPic}
      />
      <Box
        sx={{
          overFlowY: "scroll",
          flexGrow: 1,
          overflowY: "auto",
          display: "flex",
          alignItems: "center",

          justifyContent: "center",
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
          {mode === "dark" ? (
            <img
              alt="img1"
              src={require("../../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
              }}
            ></img>
          ) : (
            <img
              alt="img1"
              src={require("../../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
              }}
            ></img>
          )}
        </Box>
        <Box
          sx={{
            width: "336px",
            height: "696px",
            zIndex: 1000,
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
            mt: "5rem",
          }}
        >
          <Box
            sx={{
              width: "336px",
              height: "696px",
              backgroundColor: "rgba(248, 248, 252, 0.3)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "24px",
              zIndex: 1000,
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormRegisterMob />
          </Box>
        </Box>
      </Box>
    </FlexBetween>
  );
};

export default RegisterMob;
