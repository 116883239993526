import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useState } from "react";
import styled from "styled-components";
import { HoverIconButton } from "components/styledComponents/common";
const Accordion = styled.div((props) => ({
  maxHeight: props.open ? "50vh" : 0,
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  overflow: "hidden",
  marginTop: "0.8rem",
  transition: "max-height 0.3s",
}));

const TransactionItem = ({ item }) => {
  const [is_open, setOpen] = useState(false);
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const dividerColor = theme.palette.background.forth;
  return (
    <Box
      p="0.7rem"
      sx={{
        "&:not(:last-child)": {
          borderBottom: `1px solid ${dividerColor}`,
        },
      }}
    >
      <FlexBetween
        alignItems="center"
        justifyContent="space-between"
        mb="0.5rem"
      >
        <Typography variant="smallestTextPrimary" color={main}>
          {item.service}
        </Typography>
        <HoverIconButton onClick={() => setOpen((prev) => !prev)}>
          <KeyboardArrowDownOutlined
            sx={{
              rotate: is_open ? "180deg" : "0deg",
              transition: "rotate 0.3s",
            }}
          />
        </HoverIconButton>
      </FlexBetween>
      <FlexBetween>
        <Typography variant="smallestTextPrimary" color={alt}>
          {item.time}
        </Typography>
        <Box
          sx={{
            width: "92px",
            height: "23px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor:
              item.status === "successful"
                ? theme.palette.others.third
                : item.status === "pending"
                ? theme.palette.others.first
                : theme.palette.others.second,
          }}
        >
          <Typography
            color={"#000"}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {item.status === "successful"
              ? "موفق"
              : item.status === "pending"
              ? "در انتظار پرداخت"
              : "ناموفق"}
          </Typography>
        </Box>
      </FlexBetween>
      <Accordion open={is_open}>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={alt}>
            مبلغ
          </Typography>
          <Typography variant="subHeading" color={main}>
            {item.price}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={alt}>
            تاریح پرداحت
          </Typography>
          <Typography variant="subHeading" color={main}>
            {item.date}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="smallestTextPrimary" color={alt}>
            کد پیگیری
          </Typography>
          <Typography variant="subHeading" color={main}>
            {item.id}
          </Typography>
        </FlexBetween>
      </Accordion>
    </Box>
  );
};

export default TransactionItem;
