import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { MOBILE_WIDTH } from "constants/constant";
import SeenVector from "assets/icon/seen_vector.svg";
const TicketMessage = ({ text, time, seen, me = false }) => {
  const theme = useTheme();
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;

  return (
    <Box
      width={"auto"}
      maxWidth={isMobileScreens ? "95%" : "459px"}
      p="0.5rem 0.75rem"
      borderRadius={me ? "14px 14px 14px 4px" : "14px 14px 4px 14px"}
      sx={{
        backgroundColor: me
          ? theme.palette.others.first
          : theme.palette.background.third,
      }}
      position="relative"
    >
      <Stack
        direction="row"
        gap="0.5rem"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body" color={main}>
          {text}
        </Typography>
        <Typography
          variant="smallestTextSecondary"
          color={alt}
          alignSelf="flex-end"
          sx={{ direction: "ltr", whiteSpace: "nowrap" }}
        >
          {me && seen && <img src={SeenVector} />}
          {time}
        </Typography>
      </Stack>
    </Box>
  );
};

export default TicketMessage;
