import { Box, Rating, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import UserImage from "../../assets/StaffImg/4.jpg";

const CommentItem = () => {
  const theme = useTheme();
  const alt = theme.palette.fontColor.alt;
  return (
    <Box width="100%" m="2rem 0">
      <FlexBetween>
        <Stack direction="row" alignItems="center" gap="4px">
          <Box
            component="img"
            width="24px"
            height="24px"
            borderRadius="99px"
            sx={{ objectFit: "cover" }}
            src={UserImage}
          />
          <Typography variant="smallestTextSecondary" color="fontColor.main">
            آقای احمدی - 28 فروردین 1402
          </Typography>
        </Stack>
        <Rating
          size="medium"
          name="read-only"
          value={4}
          readOnly
          sx={{ direction: "ltr" }}
        />
      </FlexBetween>
      <FlexBetween mt="1rem">
        <Typography variant="body" color="fontColor.main">
          برای صرفه جویی تو هزینه از همسایمون داده هواشناسی رو خریدم بدون اینکه
          لازم باشه سنسور رو تو مزرعه خودم داشته باشم
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          gap="4px"
          borderBottom={`1px solid ${alt}`}
        >
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.54181 7.70993L5.60301 10.7556C5.77938 10.931 5.77938 11.2155 5.60301 11.3909C5.44624 11.5469 5.20289 11.5642 5.02688 11.4429L4.96434 11.3909L1.13227 7.57893C0.975505 7.42298 0.958086 7.1809 1.08002 7.00581L1.13227 6.94359L4.96434 3.13158C5.1407 2.95614 5.42665 2.95614 5.60301 3.13158C5.75978 3.28753 5.7772 3.52961 5.65527 3.7047L5.60301 3.76692L2.54181 6.81143L8.22581 6.81201C11.8937 6.81201 14.8808 9.71189 14.9965 13.3327L15 13.5507C15 13.7989 14.7978 14 14.5484 14C14.299 14 14.0968 13.7989 14.0968 13.5507C14.0968 10.3954 11.5813 7.82448 8.43638 7.71419L8.22581 7.71051L2.54181 7.70993L5.60301 10.7556L2.54181 7.70993Z"
              fill="#C4C3D9"
            />
          </svg>
          <Typography variant="smallestTextPrimary" color="fontColor.alt">
            پاسخ
          </Typography>
        </Stack>
      </FlexBetween>
    </Box>
  );
};

export default CommentItem;
