import { useTheme } from "@mui/material";

export const useGetColor = (type, value) => {
    const theme = useTheme()
    const temperatureThreshold1 = 10;
    const temperatureThreshold2 = 30;
    const humidityThreshold1 = 30;
    const humidityThreshold2 = 60;
    switch (type) {
        case 'temperature':
            if (value > temperatureThreshold1 && value < temperatureThreshold2) return theme.palette.others.third;
            if (value > temperatureThreshold2) return theme.palette.others.second;
            if (value < temperatureThreshold1) return theme.palette.others.forth;
            break;
        case 'humidity':
            if (value > humidityThreshold1 && value < humidityThreshold2) return theme.palette.others.third;
            if (value > humidityThreshold2) return theme.palette.others.forth;
            if (value < humidityThreshold1) return theme.palette.others.second;
            break;
        case 'precipitation_rain':
            return theme.palette.others.third;
        case 'wind_speed':
            return theme.palette.others.third;
        case 'solar_radiation':
            return theme.palette.others.third;
        case 'atmospheric_pressure':
            return theme.palette.others.third;
        default:
            break;
    }
};