import { setWeather } from "States/weather";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WithSpinner from "components/With-Spinner/With-Spinner.component";
import Weather from "./Weather";
import { store } from "States/rootReducer";
import { setLogin } from "States";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const WeatherIndex = () => {
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.global.token);
  const dispatch = useDispatch();
  const WeatherWithSpinner = WithSpinner(Weather);
  const refreshToken = useSelector((state) => state.global.refreshToken);

  const navigate = useNavigate();

  const checkStatusApi = async (error, refreshToken) => {
    if (error.response && error.response.status === 401) {
      try {
        console.log("checkStatusApi ===========>", error.response.status);
        getNewAccessToken(refreshToken);
      } catch (error) {
        console.log("n===>", error);
      }
    } else {
    }
  };

  const getNewAccessToken = async (refreshToken) => {
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", "user-panel");
    formData.append("client_secret", "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV");
    formData.append("grant_type", "refresh_token");
    await fetch("https://api.rahbaan.ir/v1/authentication/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxBodyLength: Infinity,

      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("🚀 ~ file: WeatherIndex.jsx:53 ~ .then ~ data:", data);

        store.dispatch(
          setLogin({
            user: data,
            token: data.access_token,
            refreshToken: data.refresh_token,
          })
        );
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: WeatherIndex.jsx:64 ~ getNewAccessToken ~ error:",
          error
        );

        store.dispatch(
          setLogin({
            user: null,
            token: null,
            refreshToken: null,
          })
        );
        navigate("/login");
      });
  };
  const getWeather = async () => {
    axios
      .get(`https://api.rahbaan.ir/v1/weather/overview`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(setWeather({ weather: response.data.data }));
        console.log(
          "🚀 ~ file: WeatherIndex.jsx:86 ~ .then ~  response.data.data:",
          response.data.data
        );

        if (response.status === 200) {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SensorManagement.jsx:299 ~ useEffect ~ error:",
          error
        );
        checkStatusApi(error, refreshToken);
      });
  };
  useEffect(() => {
    // getWeather();
  }, [token]);

  return <WeatherWithSpinner isLoading={false} />;
};

export default WeatherIndex;
