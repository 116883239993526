import { KeyboardArrowDownOutlined, Search } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  MenuList,
  Select,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { CustomInputBase } from "components/styledComponents/common";
import { useState } from "react";
import ShoppingCardDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCardLight from "../../assets/icon/shopping-cart-light.svg";
import { useSelector } from "react-redux";
import WidgetWrapper from "components/WidgetWrapper";
import TransactionItem from "./TransactionItem";
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
}));

const mockData = [
  {
    service: "سنجش از دور",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 تومان",
    date: "1401/02/03  - 20:30",
    id: "6384683235",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "successful",
    price: "200,000 تومان",
    date: "1401/02/03  - 20:30",
    id: "23452621",
  },
  {
    service: "سنجش از دور",
    time: "۶ ماهه",
    status: "pending",
    price: "200,000 تومان",
    date: "1401/02/03  - 20:30",
    id: "67456342",
  },
  {
    service: "بسته برنزی",
    time: "۶ ماهه",
    status: "failed",
    price: "200,000 تومان",
    date: "1401/02/03  - 20:30",
    id: "7234523426",
  },
];
const Transactions = ({ setTransactionView }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;

  const mode = useSelector((state) => state.global.mode);
  const [sortedData, setSortedData] = useState(mockData);
  const [selectedFilter, setSelectedFilter] = useState("همه");
  const [searchValue, setSearchValue] = useState("");
  const [side, setSide] = useState("buy");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    const tmp = mockData.filter((item) => item.service.includes(value));
    setSortedData(tmp);
  };
  return (
    <Box
      sx={{
        p: "12px",
        overflow: "auto",
        height: "calc(100dvh - 112px)",
      }}
    >
      <FlexBetween
        sx={{
          gap: "12px",
        }}
      >
        <FlexBetween
          sx={{
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            borderRadius: "8px",
            flex: "1 1 40%",
            height: "36px",
          }}
        >
          <Search sx={{ mr: "0.3rem" }} />
          <CustomInputBase
            placeholder="جست و جو"
            id="inputSearchRemote"
            value={searchValue}
            onChange={handleSearch}
          />
        </FlexBetween>
        <Box
          //   width="48%"
          height="36px"
          borderRadius="8px"
          sx={{ flex: "1 1 40%" }}
        >
          <Select
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
            IconComponent={() => <KeyboardArrowDownOutlined />}
            MenuProps={{
              // Apply the custom styled MenuList component
              MenuListProps: { component: StyledMenuList },
            }}
            sx={{
              width: "100%",
              height: "36px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",

              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
          >
            <MenuItem
              value={"همه"}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "0 5px",
                borderRadius: "4px",
                backgroundColor: `${theme.palette.background.third}!important`,
                ":hover": {
                  backgroundColor: `${theme.palette.fontColor.third} !important`,
                },
              }}
            >
              <Typography
                variant="smallestTextSecondary"
                fontColor={theme.palette.fontColor.main}
              >
                همه
              </Typography>
            </MenuItem>
          </Select>
        </Box>
      </FlexBetween>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          my: "22px",
          gap: "20px",
        }}
      >
        <Box
          onClick={() => setSide("buy")}
          sx={{
            borderBottom:
              side === "buy"
                ? `2px solid ${theme.palette.primary.main}`
                : "none",
          }}
        >
          <Typography>خرید</Typography>
        </Box>
        <Box
          onClick={() => setSide("sell")}
          sx={{
            borderBottom:
              side === "sell"
                ? `2px solid ${theme.palette.primary.main}`
                : "none",
          }}
        >
          <Typography>فروش</Typography>
        </Box>
      </Box>
      <WidgetWrapper mt="12px">
        <FlexBetween>
          <Typography variant="h4" color={main}>
            تراکنش ها
          </Typography>
          <Box
            component="img"
            src={mode === "dark" ? ShoppingCardDark : ShoppingCardLight}
            width="24px"
            height="24px"
          />
        </FlexBetween>
        {sortedData.map((item) => (
          <TransactionItem item={item} />
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default Transactions;
