import { AddOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import CenterStack from "components/styledComponents/CenterStack";
import React, { useState } from "react";
import DownloadIcon from "../../assets/Icons/download.png";
import DeleteIcon from "../../assets/Icons/delete.png";
import { EstrusData } from "Mock/EstrusData";
import LiveDualLineChart from "scenes/LivestockManagement/LiveDualLineChart";
import PointerIcon from "../../assets/pointer.png";
import EstrusFormMob from "./EstrusFormMob";

const EstrusMob = () => {
  const theme = useTheme();
  const [isAddItem, setIsAddItem] = useState(false);
  const main = theme.palette.fontColor.main;
  const colors = ["#C3FF79", "#72EB13", "#72EB13", "#FFEE00", "#FC4949"];
  const boxWidth = 107;
  const colorWidth = boxWidth / colors.length;

  const gradientBackground = `linear-gradient(to right, ${colors
    .map(
      (color, index) =>
        `${color} ${colorWidth * index}px, ${color} ${
          colorWidth * (index + 1)
        }px`
    )
    .join(", ")})`;
  return isAddItem ? (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <EstrusFormMob setIsAddItem={setIsAddItem} />
    </Box>
  ) : (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper height="248px">
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="h5" color="fontColor.Main">
            فحلی ها
          </Typography>
          <Typography variant="smallestTextSecondary" color="fontColor.alt">
            1 ماه گذشته
          </Typography>
        </Stack>
        <Box mt="1rem">
          <AnimatedProgressBar
            number={75}
            width={157}
            height={163}
            title={EstrusData.status[0][0]}
            subTitle={EstrusData.status[0][1]}
            color={theme.palette.primary.third}
            dot={false}
          />
        </Box>
      </WidgetWrapper>
      <WidgetWrapper height="257px" mt="12px">
        <Typography variant="h4" color="fontColor.main">
          فحلی ها
        </Typography>
        <Stack direction="row" alignItems="center" gap="16px">
          <Box display="flex" gap="8px" mt="0.5rem">
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "others.second",
                },
              }}
              checked={true}
            />
            <Typography variant="smallestTextPrimary" color="fontColor.alt">
              فحلی با تلقیح
            </Typography>
          </Box>
          <Box display="flex" gap="8px" mt="0.5rem">
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "others.sixth",
                },
              }}
              checked={true}
            />
            <Typography variant="smallestTextPrimary" color="fontColor.alt">
              فحلی بدون تلقیح
            </Typography>
          </Box>
        </Stack>
        <LiveDualLineChart
          data1={EstrusData.success}
          data2={EstrusData.failure}
          color1={theme.palette.others.sixth}
          color2={theme.palette.others.second}
          r1={58}
          g1={198}
          b1={173}
          r2={252}
          g2={73}
          b2={73}
        />
      </WidgetWrapper>
      <WidgetWrapper mt="12px" mb="70px">
        <FlexBetween>
          <Typography variant="h4" color="fontColor.main">
            فحلی ها
          </Typography>
          <Stack direction="row" gap="16px">
            <IconButton>
              <Box component="img" src={DownloadIcon} />
            </IconButton>
            <IconButton onClick={() => setIsAddItem(true)}>
              <CenterStack
                sx={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                }}
              >
                <AddOutlined />
              </CenterStack>
            </IconButton>
          </Stack>
        </FlexBetween>
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            // ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            m: "0.75rem 0 1rem 0",
          }}
        >
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "27px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
          <Search sx={{ ml: "0.2rem" }} />
        </FlexBetween>
        {EstrusData.livestock.map((item, index) => (
          <>
            <Box
              width="100%"
              height="69px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <FlexBetween>
                <Typography variant="body" color="fontColor.main">
                  دام {item.code}
                </Typography>
                <Box component="img" src={DeleteIcon} />
              </FlexBetween>
              <FlexBetween>
                <Typography variant="subHeading" color="fontColor.alt">
                  {item.status}
                </Typography>

                <Stack direction="column" sx={{}}>
                  <Box
                    sx={{
                      width: "107px",
                      height: "6px",
                      backgroundImage: gradientBackground,
                      borderRadius: "30px",
                      position: "relative",
                    }}
                  >
                    <Box
                      component="img"
                      src={PointerIcon}
                      position="absolute"
                      sx={{ top: -6, left: (item.estrusTime / 32) * 100 }}
                    />
                  </Box>
                  <FlexBetween
                    sx={{
                      width: "107px",
                      height: "6px",
                      mt: "0.5rem",
                    }}
                  >
                    <Typography variant="extraSmall" color="fontColor.main">
                      32
                    </Typography>
                    <Typography variant="extraSmall" color="fontColor.main">
                      0
                    </Typography>
                  </FlexBetween>
                </Stack>
              </FlexBetween>
            </Box>

            {index !== EstrusData.livestock.length - 1 && <Divider />}
          </>
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default EstrusMob;
