import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import FarmIcon from "../../assets/Modules/weather1.png";

const ServiceItem = ({ title }) => {
  const theme = useTheme();
  const blue = theme.palette.others.first;
  return (
    <Stack direction="row" alignItems="center" gap="16px" m="2rem 0">
      <Box
        component="img"
        width="56px"
        hieght="56px"
        borderRadius="8px"
        src={FarmIcon}
      />
      <Stack direcion="column" justifyContent="space-between" height="56px">
        <Typography variant="h5" color="fontColor.main">
          {title}
        </Typography>
        <Typography variant="subHeading" color="others.first">
          <span style={{ borderBottom: `1px solid ${blue}` }}>مشاهده</span>
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ServiceItem;
