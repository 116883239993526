import { Box, Stack, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const PackageItem = ({ title, price, unit, enable = false }) => {
  return (
    <Box
      width="160px"
      height="142px"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
    >
      <FlexBetween>
        <Typography variant="h5" color="fontColor.main">
          {title}
        </Typography>
        {enable && (
          <Box
            width="60px"
            height="23px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
            sx={{ backgroundColor: "others.third" }}
          >
            <Typography>فعال</Typography>
          </Box>
        )}
      </FlexBetween>
      <Stack direction="row" alignItems="center">
        <Typography variant="h2" color="fontColor.main">
          {price}
        </Typography>
        <Typography variant="subHeading" color="fontColor.main">
          {unit}
        </Typography>
      </Stack>
      <Box
        width="160px"
        height="32px"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "others.first",
          cursor: "pointer",
        }}
      >
        <Typography>تمدید سرویس</Typography>
      </Box>
    </Box>
  );
};

export default PackageItem;
