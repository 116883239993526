import { Box, Collapse, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
import { useState } from "react";
import ArrowIcon from "../../assets/arrow.png";

const BottomDrawer = ({ selectedTitle, setSelectedTitle }) => {
  const theme = useTheme();
  const [is_open, setOpen] = useState(false);
  const [groupLivestock, setGroupLivestock] = useState(false);
  const [actions, setActions] = useState(false);
  const LiveItem = ({ id, title }) => {
    return (
      <Box
        width="100%"
        height="40px"
        display="flex"
        alignItems="center"
        mb="0.2rem"
        p="0.5rem"
        borderRadius="4px"
        sx={{
          backgroundColor:
            selectedTitle === title
              ? theme.palette.background.third
              : "transparent",
        }}
        onClick={() => {
          setSelectedTitle(title);
          setOpen(false);
        }}
      >
        <Typography variant="subHeading" color="fontColor.main">
          {title}
        </Typography>
      </Box>
    );
  };

  return (
    <MobileBottomSidebar height={"80vh"} is_open={is_open} setOpen={setOpen}>
      {is_open ? (
        <Box
          sx={{
            height: "85%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <FlexBetween>
            <Typography variant="h4" color="white">
              دسته بندی دام ها
            </Typography>
            <IconButton onClick={() => setGroupLivestock(!groupLivestock)}>
              <Box
                component="img"
                src={ArrowIcon}
                sx={{ rotate: groupLivestock ? "0" : "180deg" }}
              />
            </IconButton>
          </FlexBetween>
          <Collapse in={groupLivestock} timeout={500}>
            <LiveItem id={1} title="خشکی" />
            <LiveItem id={2} title="فحلی" />
            <LiveItem id={3} title="کل دام" />
          </Collapse>
          <FlexBetween mt="1rem">
            <Typography variant="h4" color="fontColor.main">
              اقدامات
            </Typography>
            <IconButton onClick={() => setActions(!actions)}>
              <Box
                component="img"
                src={ArrowIcon}
                sx={{ rotate: actions ? "0" : "180deg" }}
              />
            </IconButton>
          </FlexBetween>
          <Collapse in={actions} timeout={500}>
            <LiveItem id={4} title="زایش" />
            <LiveItem id={5} title="تلقیح" />
            <LiveItem id={6} title="واکسن" />
            <LiveItem id={7} title="سم چینی" />
            <LiveItem id={8} title="درمان" />
            <LiveItem id={9} title="دارو" />
            <LiveItem id={10} title="وضعیت سلامت" />
            <LiveItem id={11} title="آسایش دام" />
          </Collapse>
        </Box>
      ) : (
        <>
          <FlexBetween onClick={() => setOpen(true)}>
            <Typography variant="h4" color="white">
              {selectedTitle}
            </Typography>
          </FlexBetween>
        </>
      )}
    </MobileBottomSidebar>
  );
};

export default BottomDrawer;
