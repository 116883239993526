import { Box, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import PowerIcon from "../../assets/Actuator/Power.png";
const ActuatorItem = ({
  icon,
  title,
  zone,
  status,
  selected,
  index,
  setSelected,
}) => {
  const theme = useTheme();
  const [togglePower, setTogglePower] = useState(status);
  return (
    <Box
      width="95%"
      height="101px"
      borderRadius="8px"
      p="1rem 0.5rem"
      backgroundColor={
        selected ? theme.palette.background.third : "transparent"
      }
      sx={{
        cursor: selected ? "auto" : "pointer",
      }}
      onClick={() => setSelected(index)}
    >
      <Box display="flex" alignItems="center" gap="0.2rem">
        <Box width="24px" height="24px" component="img" src={icon} />
        <Typography variant="h5" color={theme.palette.fontColor.main}>
          {title}
        </Typography>
        <Typography
          variant="smallestTextPrimary"
          color={theme.palette.fontColor.alt}
        >
          {zone}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt="0.75rem"
        sx={{ cursor: "pointer" }}
      >
        <Typography borderBottom={`1px solid ${theme.palette.fontColor.main}`}>
          ویرایش
        </Typography>
        <Box
          width="24px"
          height="24px"
          backgroundColor={
            togglePower
              ? theme.palette.others.third
              : theme.palette.background.third
          }
          borderRadius="8px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setTogglePower(!togglePower)}
        >
          <Box component="img" src={PowerIcon} />
        </Box>
      </Box>
    </Box>
  );
};

export default ActuatorItem;
