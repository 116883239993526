import {
  Box,
  IconButton,
  Modal,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import StepSvgTitle from "./StepSvgTitle";
import GrowthProgressbar from "./GrowthProgressbar";
import { getSVG } from "Utils/GrowthStep";
import CopyRight from "widgets/CopyRight";
import { MapContainer, TileLayer } from "react-leaflet";
import {
  Close,
  DownloadOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import ButtonsMap from "./ButtonsMap";
import SmartIndexGrowth from "./SmartIndexGrowth";

const growthData = [
  { step: 1, title: "دانه" },
  { step: 2, title: "جوانه" },
  { step: 3, title: "رشد ده سانتی" },
  { step: 4, title: "نهال" },
  { step: 5, title: "شکوفه" },
  { step: 6, title: "میوه" },
];
function getTitleByStep(step) {
  const foundItem = growthData.find((item) => item.step === step);
  return foundItem ? foundItem.title : null;
}
const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  padding: "0px",
  maxHeight: "100%",
  "& .leaflet-left": {
    left: "3.7%",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));
const GrowthStages = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);

  useEffect(() => {}, [mode]);
  const position = [35.643029, 52.224928];
  const [mapModal, setMapModal] = useState(false);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [step, setStep] = useState(1);
  useEffect(() => {}, [step]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1235px"
      width="calc(100% - 2rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" height="89vh" gap="16px">
        <WidgetWrapper width="61.5vw" height="83vh">
          <FlexBetween>
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography variant="h5" color={main}>
                محصول:
              </Typography>
              <Typography variant="h2" color={main}>
                سیب قرمز
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="0.5rem">
              <Typography variant="h5" color={main}>
                زمان برداشت محصول:
              </Typography>
              <Typography variant="h3" color={main}>
                30 خرداد
              </Typography>
            </Box>
          </FlexBetween>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <FlexBetween
              sx={{
                width: "98%",
                alignItems: "flex-end",
                p: "0 1rem",
                mt: "2rem",
              }}
            >
              <StepSvgTitle step={1} title={getTitleByStep(1)} />
              <StepSvgTitle step={2} title={getTitleByStep(2)} />
              <StepSvgTitle step={3} title={getTitleByStep(3)} />
              <StepSvgTitle step={4} title={getTitleByStep(4)} />
              <StepSvgTitle step={5} title={getTitleByStep(5)} />
              <StepSvgTitle step={6} title={getTitleByStep(6)} />
            </FlexBetween>
            <Box
              width="92%"
              height="3px"
              position="relative"
              mr="-3%"
              sx={{ backgroundColor: theme.palette.background.third }}
            >
              <GrowthProgressbar step={step} />
            </Box>
          </Box>
          <Box
            width="100%"
            height="32px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="3rem"
          >
            <Box
              width="169px"
              height="32px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                cursor: "pointer",
                gap: "0.5rem",
              }}
              onClick={() => {
                if (step < 6) setStep(step + 1);
              }}
            >
              <Typography>رفتن به مرحله بعد</Typography>

              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.88065 3.95334L2.83398 8.00001L6.88065 12.0467"
                  stroke="#F7F7F7"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.1673 8H2.94727"
                  stroke="#F7F7F7"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
          </Box>
          <Box mt="2rem">
            <Box display="flex" alignItems="center" gap="1rem">
              <Box
                width="80px"
                height="80px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                sx={{
                  backgroundColor: theme.palette.background.third,
                }}
              >
                {getSVG(step, 1)}
              </Box>
              <Box display="flex" flexDirection="column" gap="1rem">
                <Typography
                  variant="h5"
                  color={main}
                >{`مرحله ${step}:`}</Typography>
                <Typography variant="h3" color={main}>
                  {getTitleByStep(step)}
                </Typography>
              </Box>
            </Box>
            <Box mt="1rem" display="flex" flexDirection="column">
              <Typography variant="body" color={main}>
                درخت سیب کود ارگانیک و ازت بالا را ترجیح می دهد.
              </Typography>
              <Typography variant="body" color={main}>
                کنجاله خون ( گوشت بدون آب)، کنجاله سویا، کود مرغ کمپوست شده و
                کنجاله پر همه از منابع خوب وعالی نیتروژن هستند. کودهای مخصوص
                درخت سیب نیز وجود دارد.
              </Typography>
              <Typography variant="body" color={main}>
                علاوه بر نیتروژن درخت شما به عناصر کلان و ریز نیز نیاز دارد.
                افزودن کمپوست هنگام کوددهی روش خوبی برای تامین مواد عالی و مواد
                معدنی است. ارومیت یا Cascade Remineralizing Soil Boost منابع
                خوبی از مواد معدنی کمیاب هستند.
              </Typography>
            </Box>
          </Box>
        </WidgetWrapper>
        <Box
          width="calc(100% - 61.5vw)"
          height="83vh"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <Box width="100%" height="49%">
            <MapBox mode={mode}>
              <ButtonsMap individual={false} setMapModal={setMapModal} />
              <Modal
                open={mapModal}
                onClose={() => setMapModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  zIndex: 200000,
                  "& .leaflet-left": {
                    left: "14px",
                  },
                  "& .leaflet-bar": {
                    border: "none",
                  },
                  "& .leaflet-control-zoom-in": {
                    width: "24px !important",
                    height: "24px !important",
                    backgroundColor:
                      mode === "dark"
                        ? "rgba(29, 29, 65, 0.7) !important"
                        : "rgba(250, 250, 252, 0.7) !important",
                    color: theme.palette.fontColor.alt,
                    borderRadius: "8px 8px 0 0 !important",
                  },

                  "& .leaflet-touch .leaflet-bar a": {
                    lineHeight: "24px !important",
                  },
                  "& .leaflet-control-zoom-out": {
                    width: "24px !important",
                    height: "24px !important",
                    backgroundColor:
                      mode === "dark"
                        ? "rgba(29, 29, 65, 0.7 )!important"
                        : "rgba(250, 250, 252, 0.7) !important",
                    color: theme.palette.fontColor.alt,
                    borderRadius: "0 0 8px 8px !important",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.second,
                    "& .leaflet-container": {
                      height: "100% !important",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "120px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <Close
                        onClick={() => setMapModal(false)}
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "170px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <PriorityHighOutlined
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "200px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <DownloadOutlined
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <MapContainer
                    center={position}
                    zoom={15.5}
                    style={{
                      borderRadius: "8px",
                      height: "241px",
                      width: "100%",
                    }}
                  >
                    {/* <ChangeView center={position} zoom={15.5} /> */}
                    <TileLayer
                      attribution="PTR"
                      url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                      maxZoom={22}
                      maxNativeZoom={18}
                    />
                  </MapContainer>
                </Box>
              </Modal>

              <MapContainer
                center={position}
                zoom={16}
                style={{ borderRadius: "8px", height: "100%", width: "100%" }}
              >
                {/* <ChangeView center={position} zoom={15.5} /> */}
                <TileLayer
                  attribution="PTR"
                  url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  subdomains={["mt0", "mt1", "mt2", "mt3"]}
                  maxZoom={22}
                  maxNativeZoom={18}
                />
              </MapContainer>
            </MapBox>
          </Box>
          <Box width="100%" height="49%">
            <SmartIndexGrowth />
          </Box>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default GrowthStages;
