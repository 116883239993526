export const InoculatedData = {
    type: "تلقیح",
    performance: [
      ["05/12", 670],
      ["05/13", 635],
      ["05/14", 710],
      ["05/15", 860],
      ["05/16", 856],
      ["05/17", 880],
      ["05/18", 910],
      ["05/19", 750],
    ],
    status: [[750, 1750], "تلقیح انجام شده"],
  
    livestock: [
      {
        code: 900,
        inDate: "20:30 - 1402/06/10",
        inType: "مصنوعی",
        order: "12",
        noSperm: "2255588251",
        milk: "9",
      },
      {
        code: 899,
        inDate: "10:30 - 1402/06/9",
        inType: "مصنوعی",
        order: "8",
        noSperm: "2555444610",
        milk: "8",
      },
      {
        code: 898,
        inDate: "09:10 - 1402/06/6",
        inType: "مصنوهی",
        order: "5",
        noSperm: "4567545829",
        milk: "9",
      },
      {
        code: 897,
        inDate: "23:30 - 1402/06/03",
        inType: "مصنوعی",
        order: "5",
        noSperm: "2553563491",
        milk: "6",
      },
    ],
  };