import { Box, styled } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

const ContainerDiv = styled(Box)(({ theme, mode }) => ({
  position: "fixed",
  backdropFilter: "blur(6px)",
  width: "100%",
  zIndex: 100,
  bottom: "44px",
  left: 0,
  color: theme.palette.fontColor.alt,
  background:
    mode === "dark"
      ? "rgba(29, 29, 65, 0.7) !important"
      : "rgba(250, 250, 252, 0.7) !important",
  overflow: "hidden",
  borderRadius: "8px 8px 0px 0px",
  transition: "0.6s",
  padding: "0 1rem",
}));

const SeparatorDiv = styled(Box)(({ theme, mode }) => ({
  width: "32px",
  height: "3px",
  margin: "0 auto",
  background: theme.palette.fontColor.alt,
  borderRadius: "15px",
}));
const MobileBottomSidebar = ({
  children,
  height,
  is_open,
  setOpen,
  initial_height = "90px",
}) => {
  const mode = useSelector((state) => state.global.mode);
  const wrapper_ref = useRef(null);
  const [touch_start, setTouchStart] = useState(null);
  const [touch_end, setTouchEnd] = useState(null);

  const min_swipe_distance = 20;

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const onTouchEnd = () => {
    if (!touch_start || !touch_end) return;
    const distance = touch_start - touch_end;
    let isDownSwipe;
    isDownSwipe = distance > min_swipe_distance;
    if (isDownSwipe) setOpen(true);
    else setOpen(false);
  };

  return (
    <ContainerDiv
      mode={mode}
      style={{ height: is_open ? height : initial_height }}
      ref={wrapper_ref}
    >
      <div
        style={{ padding: "8.5px 0 1rem" }}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        <SeparatorDiv mode={mode} />
      </div>
      {children}
    </ContainerDiv>
  );
};

export default MobileBottomSidebar;
