import { Box, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Pic1 from "../../assets/StaffImg/1.jpg";
import SendLight from "../../assets/icon/send-light.svg";
import SendDark from "../../assets/icon/send-dark.svg";
import { useSelector } from "react-redux";
const TaskItem = ({ item, handleClickTask }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const sendIcon = mode === "dark" ? SendDark : SendLight;

  return (
    <Box
      onClick={handleClickTask}
      p="0.7rem"
      sx={{
        "&:not(:last-child)": {
          borderBottom: `1px solid ${alt}`,
        },
      }}
    >
      <FlexBetween
        alignItems="center"
        justifyContent="space-between"
        mb="0.5rem"
      >
        <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
          <Box
            width="24px"
            height="24px"
            component="img"
            src={item.pic}
            borderRadius="99px"
          />
          <Typography variant="smallestTextPrimary" color={main}>
            {item.tasks.title}
          </Typography>
        </Stack>
        <Box
          width="20px"
          height="20px"
          component="img"
          src={sendIcon}
          borderRadius="99px"
        />
      </FlexBetween>
      <FlexBetween>
        <Typography variant="smallestTextPrimary" color={alt}>
          {item.tasks.priority}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="4.8124rem"
          height="1.4375rem"
          borderRadius="4px"
          sx={{
            backgroundColor:
              item?.tasks.status === "در حال انجام"
                ? theme.palette.others.forth
                : item?.tasks.status === "انجام شده"
                ? theme.palette.others.third
                : theme.palette.others.second,
          }}
        >
          <Typography
            color={theme.palette.background.second}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {item.tasks.status}
          </Typography>
        </Box>
      </FlexBetween>
    </Box>
  );
};

export default TaskItem;
