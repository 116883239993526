import {
  Badge,
  Box,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AccountBalance,
  AccountBalanceOutlined,
  AirplaneTicket,
  AirplaneTicketOutlined,
  Calculate,
  CalculateOutlined,
  Cloud,
  CloudOutlined,
  Dashboard,
  DashboardOutlined,
  Forum,
  ForumOutlined,
  HeadsetMic,
  HeadsetMicOutlined,
  Inventory2,
  Inventory2Outlined,
  KeyboardArrowDownOutlined,
  Leaderboard,
  LeaderboardOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  Recommend,
  RecommendOutlined,
  ReportProblem,
  ReportProblemOutlined,
  SatelliteAlt,
  SatelliteAltOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
  ToggleOff,
  ToggleOffOutlined,
} from "@mui/icons-material";
import {
  setFarmName,
  setFarmNameMob,
  setLogin,
  setPropertyId,
  setSelectedItemMob,
} from "States";
import { store } from "States/rootReducer";
import axios from "axios";
import { setProperty } from "States/dashboard";
import { useFetchFarmData } from "hooks/useFetchData";
import { getFarmData } from "api/remoteSensing";
import CompareOutlinedIcon from "../../assets/Icons/Comparison.png";
import CompareIcon from "../../assets/Icons/check.png";
import HealtMgmtOutLinedIcon from "../../assets/Icons/healthicons_animal-cow-outline.png";
import HealthMgmtIcon from "../../assets/Icons/healthicons_animal-cow.png";
const firstItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
    id: 1,
  },

  {
    text: "مدیریت دام",
    t: "livestockmanagement",
    icon: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={HealtMgmtOutLinedIcon}
      />
    ),
    iconFilled: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={HealthMgmtIcon}
      />
    ),
    id: 2,
  },
];
const secondItems = [
  {
    text: "توصیه ها",
    t: "recommends",
    id: 3,
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: (
      <Badge
        color="secondary"
        variant="dot"
        invisible={false}
        badgeContent=""
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& 	.MuiBadge-colorSecondary": {
            backgroundColor: "#FC4949",
          },
        }}
      >
        <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
      </Badge>
    ),
    iconFilled: (
      <Badge
        color="secondary"
        variant="dot"
        invisible={false}
        badgeContent=""
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& 	.MuiBadge-colorSecondary": {
            backgroundColor: "#FC4949",
          },
        }}
      >
        <Recommend sx={{ width: "24px", height: "24px" }} />
      </Badge>
    ),
  },
  {
    text: "مقایسه",
    t: "compare",
    id: 4,

    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={CompareOutlinedIcon}
      />
    ),
    iconFilled: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={CompareIcon}
      />
    ),
  },

  {
    text: "پیام رسان",
    t: "messenger",
    id: 5,
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "گزارشات",
    t: "report",
    id: 6,
    icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "عملگر ها",
    t: "actuator",
    id: 7,
    icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مدیریت کارکنان",
    t: "staffmgmt",
    id: 8,
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "کیف پول",
    t: "wallet",
    id: 9,
    icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هشدارها",
    t: "alarms",
    id: 10,
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: (
      <Badge
        color="secondary"
        variant="dot"
        invisible={false}
        badgeContent=""
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& 	.MuiBadge-colorSecondary": {
            backgroundColor: "#FC4949",
          },
        }}
      >
        <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
      </Badge>
    ),
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تیکت",
    t: "ticket",
    id: 11,
    icon: <AirplaneTicketOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <AirplaneTicket sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هواشناسی",
    t: "weather",
    id: 12,
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },
];
const thirdItems = [
  {
    text: "مشاوره آنلاین",
    t: "help",
    id: 13,
    icon: <HeadsetMicOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <HeadsetMic sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "فروشگاه",
    t: "shopping",
    id: 14,
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "حسابداری",
    t: "shoppingg",
    id: 15,
    icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "انبارداری",
    t: "shoppinggg",
    id: 16,
    icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  },
];

const ListSidebarMob = ({ open }) => {
  const refreshToken = useSelector((state) => state.global.refreshToken);
  const token = useSelector((state) => state.global.token);
  const getNewAccessToken = async (refreshToken) => {
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", "user-panel");
    formData.append("client_secret", "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV");
    formData.append("grant_type", "refresh_token");
    await fetch("https://api.rahbaan.ir/v1/authentication/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxBodyLength: Infinity,

      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("🚀 ~ file: WeatherIndex.jsx:53 ~ .then ~ data:", data);

        store.dispatch(
          setLogin({
            user: data,
            token: data.access_token,
            refreshToken: data.refresh_token,
          })
        );
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: WeatherIndex.jsx:64 ~ getNewAccessToken ~ error:",
          error
        );

        store.dispatch(
          setLogin({
            user: null,
            token: null,
            refreshToken: null,
          })
        );
        navigate("/login");
      });
  };
  const setFarm = async (id, title) => {
    getNewAccessToken(refreshToken);

    axios
      .put(`https://api.rahbaan.ir/v1/properties/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(setPropertyId({ propertyId: id }));
        getNewAccessToken(refreshToken);
        dispatch(setFarmNameMob({ farmNameMob: title }));
      });
    console.catch((error) => {
      console.log("🚀 ~ file: Navbar.jsx:247 ~ setFarm ~ error:", error);
    });
  };
  const selectedItem = useSelector((state) => state.global.selectedItemMob);

  const mode = useSelector((state) => state.global.mode);
  const { response: farms } = useFetchFarmData(getFarmData);
  const farmData = farms?.data;
  useEffect(() => {
    if (farmData) {
      const active = farmData.find((farm) => farm.active);
      dispatch(
        setProperty({
          property: farmData.data,
        })
      );
      active &&
        dispatch(
          setFarmNameMob({
            farmNameMob: active.title,
          })
        );
    }
  }, [farmData]);
  const farmNameMob = useSelector((state) => state.global.farmNameMob);

  const navigate = useNavigate();
  const theme = useTheme();
  const boxRefs = useRef([]);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (boxRefs.current[selectedItem]) {
      boxRefs.current[selectedItem].scrollIntoView({ behavior: "smooth" });
      console.log(
        "🚀 ~ file: ListSidebarMob.jsx:243 ~ useEffect ~     boxRefs.current[selectedItem - 1]:",
        boxRefs.current[selectedItem]
      );
    }
  }, [farmNameMob]);
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          width: 265,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        role="presentation"
      >
        <Box mt="0.75rem">
          {mode === "dark" ? (
            <img
              alt="img1"
              src={require("../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
                marginBottom: "1rem",
              }}
            ></img>
          ) : (
            <img
              alt="img1"
              src={require("../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
              }}
            ></img>
          )}
        </Box>
        <Box
          sx={{
            width: "241px",
            height: "40px !important",
            borderRadius: "16px",
            backgroundColor: theme.palette.background.third,
            border: "none",
            borderColor: "transparent !important",
            outline: "none",
            display: "flex",
            alignItems: "center",
            pr: "1rem",
            position: "relative",
          }}
          onClick={handleClick}
        >
          <Typography
            color={theme.palette.fontColor.main}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {farmNameMob}
          </Typography>
          <Box
            position="absolute"
            width="12px"
            height="12px"
            sx={{
              top: "10px",
              left: "20px",
            }}
          >
            <KeyboardArrowDownOutlined />
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          zIndex: 10000000,
          mt: "0.25rem",
          width: "265px !important",
          height: "240px !important",
          "& .MuiPaper-elevation": {
            boxShadow: "none !important",
          },
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#40405e !important",
          },
          "& .MuiList-root": {
            background: theme.palette.background.third,
          },
        }}
      >
        {farmData?.map((item) => (
          <MenuItem
            onClick={() => {
              setFarm(item.id, item.title);
              handleClose();
            }}
            sx={{
              zIndex: 10000000,
              width: "241px !important",
              backgroundColor: `${theme.palette.background.third} !important`,
              ":hover": {
                backgroundColor: `${theme.palette.fontColor.third} !important`,
              },
            }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <WidgetWrapper
        width="241px"
        height="106px"
        sx={{
          backgroundColor: theme.palette.background.third,
          m: "1rem 0.75rem !important",
          p: "1rem 0.5rem",
        }}
      >
        {firstItems.map((item, index) => (
          <Box
            ref={(ref) => (boxRefs.current[item.id] = ref)}
            width="225px"
            height="40px"
            borderRadius="12px"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="0.5rem"
            p="0 0.5rem"
            sx={{
              backgroundColor:
                item.id === selectedItem ? theme.palette.others.first : "none",

              "scroll-snap-align": "start",
              "scroll-snap-stop": "normal",
            }}
            onClick={() => {
              dispatch(
                setSelectedItemMob({
                  selectedItemMob: index + 1,
                })
              );
              navigate(`/${item.t}`);
            }}
            tabIndex={index === selectedItem - 1 ? 0 : -1}
          >
            {item.id === selectedItem ? item.iconFilled : item.icon}
            <Typography>{item.text}</Typography>
          </Box>
        ))}
      </WidgetWrapper>
      <WidgetWrapper
        width="241px"
        height="475px"
        sx={{
          backgroundColor: theme.palette.background.third,
          m: "1rem 0.75rem !important",
          p: "1rem 0.5rem",
        }}
      >
        {secondItems.map((item, index) => (
          <Box
            ref={(ref) => (boxRefs.current[item.id] = ref)}
            width="225px"
            height="40px"
            mt="0.25rem"
            borderRadius="12px"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="0.5rem"
            p="0 0.5rem"
            sx={{
              backgroundColor:
                item.id === selectedItem ? theme.palette.others.first : "none",
            }}
            onClick={() => {
              dispatch(
                setSelectedItemMob({
                  selectedItemMob: index + 3,
                })
              );
              navigate(`/${item.t}`);
            }}
            tabIndex={index + 3 === selectedItem - 1 ? 0 : -1}
          >
            {item.id === selectedItem ? item.iconFilled : item.icon}
            <Typography>{item.text}</Typography>
          </Box>
        ))}
      </WidgetWrapper>
      <WidgetWrapper
        width="241px"
        height="189px"
        sx={{
          backgroundColor: theme.palette.background.third,
          m: "1rem 0.75rem !important",
          p: "1rem 0.5rem",
        }}
      >
        {thirdItems.map((item, index) => (
          <Box
            ref={(ref) => (boxRefs.current[item.id] = ref)}
            width="225px"
            height="40px"
            mt="0.25rem"
            borderRadius="12px"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gap="0.5rem"
            p="0 0.5rem"
            sx={{
              backgroundColor:
                item.id === selectedItem ? theme.palette.others.first : "none",
            }}
            onClick={() => {
              dispatch(
                setSelectedItemMob({
                  selectedItemMob: index + 12,
                })
              );
              navigate(`/${item.t}`);
            }}
            tabIndex={index + 12 === selectedItem - 1 ? 0 : -1}
          >
            {item.id === selectedItem ? item.iconFilled : item.icon}
            <Typography>{item.text}</Typography>
          </Box>
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default ListSidebarMob;
