import { CacheProvider } from "@emotion/react";
import {
  AttachFileOutlined,
  CloseOutlined,
  EmojiEmotionsOutlined,
  KeyboardArrowDownOutlined,
  MicNoneOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
import WidgetWrapper from "components/WidgetWrapper";
import {
  CssTextField,
  CustomButton,
  cacheRtl,
} from "components/styledComponents/common";
import { useState } from "react";
import { StyledTextarea } from "scenes/Ticket/Ticket";
import TicketItemMob from "./TicketItemMob";
import { v4 as uuidv4 } from "uuid";
import TicketMessage from "scenes/Ticket/TicketMessage";

import SendMessageDark from "assets/icon/send-message-dark.svg";
import SendMessageLight from "assets/icon/send-message-light.svg";
import { useSelector } from "react-redux";
import ArrowRightDark from "assets/icon/arrow-right-dark.svg";
import ArrowRightLight from "assets/icon/arrow-right-light.svg";
const mockData = [
  {
    id: uuidv4(),
    title: "شارژ نشدن کیف پول",
    subject: "مالی",
    service: "کیف پول",
    priority: "middle",
    date: "1401/02/03  - 20:30",
    status: "pending",
    messages: [],
  },
  {
    id: uuidv4(),
    imageUrl: "https://s28.picofile.com/file/8464512900/5.jpg",
    title: "خرابی دستگاه",
    subject: "فنی",
    service: "سنسور گیاه",
    priority: "high",
    date: "1401/02/03  - 20:30",
    status: "answered",
    messages: [
      {
        me: true,
        text: "سلام خسته نباشید از امروز صبح دستگاه شماره 1 از کار افتاده دیتایی ارسال نمی کنه",
        date: "1402/02/01",
        time: "7:18 PM",
        seen: true,
      },
      {
        me: false,
        text: "اینترنت مزرعه وصله؟!",
        date: "1402/02/01",
        time: "7:19 PM",
        seen: true,
      },
      {
        me: true,
        text: "بله وصله بقیه دستگاه ها دارم کار می کنن فقط همین از کار افتاده",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: true,
      },
      {
        me: false,
        text: "باهاتون تماس گرفته میشه برای بررسی بیشتر اگر لازم باشه کارشناسمون برای بررسی و رفع خرابی میان مزرعه",
        date: "1402/02/01",
        time: "7:21 PM",
        seen: true,
      },
      {
        me: true,
        text: "ممنون منتظر تماستون هستم",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: false,
      },
    ],
  },
  {
    id: uuidv4(),
    imageUrl: "https://s28.picofile.com/file/8464512918/6.jpg",
    title: "از کار افتادن عملگر",
    subject: "فنی",
    service: "عملگر آبیاری",
    priority: "middle",
    date: "1401/02/03  - 20:30",
    status: "answered",
    messages: [
      {
        me: true,
        text: "سلام خسته نباشید از امروز صبح دستگاه شماره 1 از کار افتاده دیتایی ارسال نمی کنه",
        date: "1402/02/01",
        time: "7:18 PM",
        seen: true,
      },
      {
        me: false,
        text: "اینترنت مزرعه وصله؟!",
        date: "1402/02/01",
        time: "7:19 PM",
        seen: true,
      },
      {
        me: true,
        text: "بله وصله بقیه دستگاه ها دارم کار می کنن فقط همین از کار افتاده",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: true,
      },
      {
        me: false,
        text: "باهاتون تماس گرفته میشه برای بررسی بیشتر اگر لازم باشه کارشناسمون برای بررسی و رفع خرابی میان مزرعه",
        date: "1402/02/01",
        time: "7:21 PM",
        seen: false,
      },
    ],
  },
  {
    id: uuidv4(),
    title: "خرابی دستگاه",
    subject: "فنی",
    service: "سنسور هوا",
    priority: "low",
    date: "1401/02/03  - 20:30",
    status: "pending",
    messages: [],
  },
];
const TicketMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const mode = useSelector((state) => state.global.mode);
  const [selectedTicket, setSelectedTicket] = useState("");
  const [message, setMessage] = useState("");
  const [open_sidebar, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [service, setService] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  const selectedTicketData = mockData.find(
    (item) => item.id === selectedTicket
  );
  const handleClickEdit = (item) => {
    setTitle(item.title);
    setSubject(item.subject);
    setService(item.service);
    setPriority(item.priority);
    setOpen(true);
  };
  const handleClose = () => {
    setTitle("");
    setSubject("");
    setService("");
    setPriority("");
    setOpen(false);
  };
  return (
    <>
      {!!selectedTicket ? (
        <Box sx={{ p: "12px", height: "calc(100dvh - 110px)" }}>
          <WidgetWrapper
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{ overflow: "auto" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => setSelectedTicket("")}>
                <Box
                  component="img"
                  width="32px"
                  height="32px"
                  borderRadius="50%"
                  src={mode === "dark" ? ArrowRightDark : ArrowRightLight}
                />
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "baseline", gap: "4px" }}>
                <Typography variant="h5">
                  {selectedTicketData?.title}
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  {selectedTicketData?.service}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                flex: 1,
                m: "0 auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                overflowY: "auto",
                gap: "4px",
              }}
            >
              <Box
                width="100%"
                height="100%"
                position={"relative"}
                sx={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  display: "flex",
                  gap: "12px",
                  flex: 1,
                  flexDirection: "column",
                  "&::-webkit-scrollbar": {
                    width: 5,
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "1px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#5A596F",
                    outline: `0px solid slategrey`,
                    borderRadius: "5px",
                  },
                }}
              >
                {selectedTicketData?.messages.map((item) => (
                  <Box
                    display="flex"
                    flexDirection={item.me ? "row-reverse" : "row"}
                    gap="12px"
                  >
                    <TicketMessage
                      seen={item.seen}
                      me={item.me}
                      text={item.text}
                      time={item.time}
                    />
                  </Box>
                ))}
              </Box>
              <Box width="100%" display="flex" alignItems="flex-end">
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  sx={{
                    backgroundColor: theme.palette.background.third,
                    borderRadius: "8px",
                    p: "0.25rem 0.5rem",
                  }}
                  alignItems="flex-end"
                  gap="0.3rem"
                >
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    gap="8px"
                    flexGrow={1}
                  >
                    <IconButton>
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          position: "relative",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            mode === "dark" ? SendMessageDark : SendMessageLight
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: message ? 20 : 0,
                            height: message ? 20 : 0,
                            transition: "all 0.3s",
                          }}
                        />
                        <AttachFileOutlined
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: message ? 0 : 20,
                            height: message ? 0 : 20,
                            transition: "all 0.1s",
                            transform: "rotate(45deg)",
                          }}
                        />
                      </div>
                    </IconButton>
                    <FormControl
                      sx={{
                        width: "95%",
                        py: "8px",
                        "& textarea::placeholder": {
                          color: theme.palette.fontColor.alt,
                        },
                      }}
                    >
                      <TextareaAutosize
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="پیام جدید"
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          color: theme.palette.fontColor.main,
                          resize: "none",
                          border: "none",
                          outline: "none",
                          fontFamily: "YekanBakhFaNum-Regular",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    gap="0.5rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <IconButton sx={{ px: 0 }}>
                      <EmojiEmotionsOutlined />
                    </IconButton>
                    <IconButton sx={{ px: 0 }}>
                      <MicNoneOutlined />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </WidgetWrapper>
        </Box>
      ) : (
        <>
          <MobileBottomSidebar
            height="80vh"
            is_open={open_sidebar}
            setOpen={setOpen}
            initial_height="0px"
            handleClose={handleClose}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                alignItems: "stretch",
                height: "100%",
              }}
            >
              <FlexBetween>
                <Typography color="fontColor.main" variant="h4">
                  تیکت جدید
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseOutlined />
                </IconButton>
              </FlexBetween>
              <CacheProvider value={cacheRtl}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    width: "100%",
                    flex: 1,
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      "& .MuiInputBase-root": {
                        height: "45px",
                      },
                    }}
                  >
                    <CssTextField
                      label="عنوان"
                      variant="outlined"
                      style={{ width: "100%" }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </Box>
                  <FormControl
                    sx={{
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-root": {
                        height: "45px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px 14px",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {!subject && (
                      <InputLabel shrink={false} id="item_type_label">
                        موضوع
                      </InputLabel>
                    )}

                    <Select
                      IconComponent={KeyboardArrowDownOutlined}
                      id="item_type"
                      labelId="item_type_label"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <MenuItem value={"مالی"}>مالی</MenuItem>
                      <MenuItem value={"فنی"}>فنی</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-root": {
                        height: "45px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px 14px",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {!service && (
                      <InputLabel shrink={false} id="item_type_label">
                        سرویس مربوطه
                      </InputLabel>
                    )}

                    <Select
                      IconComponent={KeyboardArrowDownOutlined}
                      id="item_type"
                      labelId="item_type_label"
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                    >
                      <MenuItem value={"کیف پول"}>کیف پول</MenuItem>
                      <MenuItem value={"سنسور هوا"}>سنسور هوا</MenuItem>
                      <MenuItem value={"سنسور خاک"}>سنسور خاک</MenuItem>
                      <MenuItem value={"سنسور گیاه"}>سنسور گیاه</MenuItem>
                      <MenuItem value={"عملگر آبپاش"}>عملگر آبپاش</MenuItem>
                      <MenuItem value={"عملگر مه پاش"}>عملگر مه پاش</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-root": {
                        height: "45px",
                      },
                      "& .MuiInputBase-input": {
                        padding: "8px 14px",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {!priority && (
                      <InputLabel shrink={false} id="item_type_label">
                        اولویت
                      </InputLabel>
                    )}

                    <Select
                      IconComponent={KeyboardArrowDownOutlined}
                      id="item_type"
                      labelId="item_type_label"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                    >
                      <MenuItem value={"high"}>بالا</MenuItem>
                      <MenuItem value={"middle"}>متوسط</MenuItem>
                      <MenuItem value={"low"}>پایین</MenuItem>
                    </Select>
                  </FormControl>

                  <StyledTextarea
                    maxRows={3}
                    placeholder="توضیحات"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Box>
              </CacheProvider>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "1rem",
                  width: "100%",
                  height: "96px",
                }}
              >
                <CustomButton sx={{ flex: "1 1" }}>ثبت</CustomButton>
                <CustomButton
                  onClick={handleClose}
                  secondary
                  sx={{ flex: "1 1" }}
                >
                  انصراف
                </CustomButton>
              </Box>
            </Box>
          </MobileBottomSidebar>
          <Box
            p="1rem 0.5rem"
            sx={{
              overflowY: "auto",
              maxHeight: "calc(100dvh - 112px)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                transition: "all 0.2s ease",
                alignItems: "center",
                border: `1px solid ${theme.palette.fontColor.alt}`,
                borderRadius: "8px",
                height: "36px",
                mb: "1rem",
                width: "auto",
                pr: "8px",
              }}
            >
              <Search color="fontColor.alt" sx={{ ml: "0.2rem" }} />
              <InputBase
                disableUnderline
                placeholder="جست و جو"
                value={search}
                onChange={handleSearch}
                id="inputSearchRemote"
                sx={{
                  paddingRight: "5px",
                  backgroundColor: "inherit",
                  "& .MuiInputBase-input": {
                    "::placeholder": {
                      color: `${main} !important`,
                    },
                  },
                  height: "36px",
                  width: "100%",
                  borderRadius: "8px",
                  color: theme.palette.fontColor.alt,
                  outline: "none",
                  borderBottom: "none",
                }}
              />
            </Box>
            <WidgetWrapper sx={{ overflowY: "auto" }}>
              <FlexBetween>
                <Typography variant="h4">تیکت ها</Typography>
                <CustomButton onClick={() => setOpen(true)}>
                  تیکت جدید
                </CustomButton>
              </FlexBetween>

              {mockData
                .filter((item) => item.title.includes(search))
                .map((item) => (
                  <TicketItemMob
                    setSelectedTicket={setSelectedTicket}
                    item={item}
                    handleClickEdit={handleClickEdit}
                  />
                ))}
            </WidgetWrapper>
          </Box>
        </>
      )}
    </>
  );
};

export default TicketMob;
