import { CloseOutlined, Done } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

const PlanItem = ({ exist, title }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  return (
    <Box display="flex" alignItems="center" gap="0.5rem">
      {exist ? (
        <Done
          sx={{
            color: theme.palette.others.third,
            width: "15px",
            height: "15px",
          }}
        />
      ) : (
        <CloseOutlined
          sx={{
            color: theme.palette.others.second,
            width: "15px",
            height: "15px",
          }}
        />
      )}
      <Typography variant="subHeading" color={main}>
        {title}
      </Typography>
    </Box>
  );
};
const Plan = ({ plan }) => {
  console.log("🚀 ~ file: Plan.jsx:34 ~ Plan ~ plan:", plan);
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box
      p="1.313rem 0.813rem"
      width="100%"
      height="544px"
      mt="16px"
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        background:
          plan.title === "بسته کامل"
            ? "linear-gradient(193.94deg, #D4AF37 0%, rgba(212, 175, 55, 0) 100%)"
            : "linear-gradient(193.94deg, #2A80F4 0%, rgba(42, 128, 244, 0) 100%)",
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          gap="0.25rem"
        >
          <Typography variant="h4" color={main}>
            {plan.title}
          </Typography>
          <Typography variant="subHeading" color={main}>
            ({plan.period})
          </Typography>
        </Box>
        {plan.active && (
          <Box
            width="60px"
            height="23px"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: theme.palette.others.third }}
          >
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.background.second}
            >
              فعال
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" gap="1rem" alignItems="flex-end">
        <Typography variant="h1" color={main} lineHeight="2rem">
          {plan.price}
        </Typography>
        <Typography variant="body" color={main}>
          {plan.unit}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <PlanItem exist={plan.livestockManagement} title="مدیریت  دام" />
        <PlanItem exist={plan.smartIndex} title="شاخص هوشمند" />
        <PlanItem exist={plan.weather} title="هواشناسی" />
        <PlanItem exist={plan.staffManagement} title="مدیریت کارکنان" />
        <PlanItem exist={plan.alarms} title="هشدارها" />
        <PlanItem exist={plan.recommends} title="توصیه ها" />
        <PlanItem exist={plan.actuaturs} title="عملگرها" />
        <PlanItem exist={plan.inventory} title="انبارداری" />
        <PlanItem exist={plan.accountant} title="حسابداری" />
      </Box>
      <Box
        width="235px"
        height="32px"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: theme.palette.others.first, cursor: "pointer" }}
      >
        <Typography variant="subHeading" color={main}>
          {plan.active ? "تمدید بسته" : "خرید بسته"}
        </Typography>
      </Box>
    </Box>
  );
};

export default Plan;
