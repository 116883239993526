import { Box, useTheme } from "@mui/material";
import BoxSensorsManagement from "components/BoxSensorsManagement/BoxSensorsManagement";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const SensorCards = ({ cards, toggle }) => {
  const theme = useTheme();
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [cards]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "27rem",
        zIndex: "1000",
        width: "99%",
        height: "163px",
        p: "0 2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      {cards
        ? cards.map((item, index) => (
            <motion.div
              key={`${item.key}-${animationKey}`} // Use a unique key for each BoxSensorsManagement component
              initial={{ scale: 0.7 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.25 * (index + 1) }}
            >
              <BoxSensorsManagement
                type={item.key}
                data={item.data}
                color={theme.palette.others.third}
                toggle={toggle}
                max={125}
              />
            </motion.div>
          ))
        : null}
    </Box>
  );
};

export default SensorCards;
