import Pic1 from '../assets/StaffImg/1.jpg'
import Pic2 from '../assets/StaffImg/2.jpg'
import Pic3 from '../assets/StaffImg/3.jpg'
import Pic4 from '../assets/StaffImg/4.jpg'
import Pic5 from '../assets/StaffImg/5.jpg'
import Pic6 from '../assets/StaffImg/6.jpg'
export const AlarmsData = [
    {
      title: "بهاربند 1",
      alarm: "لنگش گاو 212",
      priority: "High",
      sendDate: "1402/02/03 - 10:30",
      dueTo: "امیر محمدی",
      description:
        "با توجه به افزایش بارندگی در روزهای آینده خطر آب گرتگی در تمام ناحیه ها وجود دارد.",
      notification: "پیامک به حسین کاظمی",
      pic:Pic1 ,
      alt: "",
    },
    {
      title: "بهاربند 2",
      alarm: "دمای پایین محیط",
      priority: "Highest",
      sendDate: "1402/02/03 - 20:30",
      dueTo: "Machine",
      description:
        "با توجه به افزایش بارندگی در روزهای آینده خطر آب گرفتگی در تمام ناحیه ها وجود دارد.",
      notification: "پیامک به حسین کاظمی",
      pic:Pic2 ,
      alt: "",
    },
    {
      title: "بهاربند 3",
      alarm: "گاو 5 مشکوک به سقط",
      priority: "Highest",
      sendDate: "1402/02/13 - 20:30",
      dueTo: "امیر رضایی",
      description:
        "با توجه به افزایش بارندگی در روزهای آینده خطر آب گرتگی در تمام ناحیه ها وجود دارد.",
      notification: "پیامک به حسین کاظمی",
      pic:Pic3 ,
      alt: "",
    },
  ];
  export const CollarData = [
    {
      title: "قلاده 1",
      status: true,
      battery: "HIGH",
      signal: "LOWEST",
    },
    {
      title: "قلاده 2",
      status: false,
      battery: "LOW",
      signal: "LOW",
    },
    {
      title: "قلاده 3",
      status: true,
      battery: "LOWEST",
      signal: "HIGH",
    },
  ];