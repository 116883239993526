import { Box, Typography, useTheme } from "@mui/material";

import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";

import { useSelector } from "react-redux";

import CopyRight from "widgets/CopyRight";
import RecordSensorsForm from "./RecordSensorsForm";
import RecordSensorsTable from "./RecordSensorsTable";

const RecordSensors = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  //   const alt = theme.palette.fontColor.alt;
  const mode = useSelector((state) => state.global.mode);
  const idd = useSelector((state) => state.sensors.id);
  console.log("🚀 ~ file: Map.component.jsx:220 ~ MapComponent ~ idd:", idd);
  const d = useSelector((state) => state.dashboard.remote);
  console.log("🚀 ~ file: Map.component.jsx:571 ~ MapComponent ~ d:", d);

  useEffect(() => {}, [mode]);

  return (
    <Box
      width="97.8%"
      height="92vh"
      m="16px 16px 0 16px"
      sx={{
        // backgroundColor: "red",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 15,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper
        width="100%"
        height="50vh"
        sx={{ backgroundColor: theme.palette.background.second }}
      >
        <Typography variant="h3" color={main}>
          ثبت سنسور
        </Typography>
        <RecordSensorsForm />
      </WidgetWrapper>
      <WidgetWrapper
        mt="16px"
        width="100%"
        height="424px"
        sx={{
          backgroundColor: theme.palette.background.second,
          overflow: "hidden",
        }}
      >
        <RecordSensorsTable />
      </WidgetWrapper>
      <Box sx={{ m: "0 0 2rem 0.2rem" }}>
        <CopyRight />
      </Box>
    </Box>
  );
};

export default RecordSensors;
