import { Box, Divider, Fade, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import AnimatedProgressBarWallet from "scenes/Wallet/AnimatedProgressbarWallet";
import WalletChart from "scenes/Wallet/WalletChart";
import WalletSelectBox from "scenes/Wallet/WalletSelectedBox";
import { useInView } from "react-intersection-observer";
import { motion, useAnimation } from "framer-motion";
import Plan from "scenes/Wallet/Plan";
import planData from "scenes/Wallet/plandata";
import ServiceItemMob from "./ServiceItemMob";
import { ServicesData, expertData, expertFields } from "./data";
import ExpertItemMob from "./ExpertItemMob";
import WalletMapMob from "./WalletMapMob";
import WeatherModuleMob from "./WeatherModuleMob";
import Transactions from "./Transactions";
import WalletFeatureItemMob from "./WalletFeatureItemMob";
import ExpertModuleMob from "./ExpertModuleMob";

const expertFieldTitle = (field) => {
  switch (field) {
    case "watering":
      return "کارشناس آبیاری";
    case "soil":
      return "کارشناس خاک و حاصلخیزی";
    case "poison":
      return "کارشناس سم و آفات";
    case "plant":
      return "کارشناس گیاه";
    default:
      break;
  }
};
const WalletMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const [show, setShow] = useState(false);
  const { ref, inView } = useInView();
  const [neighbor, setNeighbor] = useState(false);
  const [WeatherModule, setWeatherModule] = useState(false);
  const [transactionView, setTransactionView] = useState(false);
  const Box1Animation = useAnimation();
  const Box2Animation = useAnimation();
  const Box3Animation = useAnimation();
  const Box4Animation = useAnimation();
  const Box5Animation = useAnimation();
  const [expertId, setExpertId] = useState(null);
  useEffect(() => {
    if (inView) {
      if (!show) {
        Box1Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box2Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box3Animation.start({
          opacity: 1,
          transition: {
            type: "spring",
            duration: 200,
            bounce: 0.1,
          },
        });
        Box4Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box5Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        setShow(true);
      }
    }
    if (!inView) {
      if (!show) {
        Box1Animation.start({
          x: "60vw",
        });
        Box2Animation.start({
          x: "30vw",
        });
        Box3Animation.start({
          opacity: 0,
        });
        Box4Animation.start({
          x: "-30vw",
        });
        Box5Animation.start({
          x: "-60vw",
        });
      }
    }
  }, [inView]);
  return (
    <>
      {WeatherModule ? (
        <WeatherModuleMob setWeatherModule={setWeatherModule} />
      ) : transactionView ? (
        <Transactions setTransactionView={setTransactionView} />
      ) : !!expertId ? (
        <ExpertModuleMob setExpertId={setExpertId} expertId={expertId} />
      ) : (
        <Box
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            maxHeight: "calc(100dvh - 112px)",
            padding: "12px",
            "&::-webkit-scrollbar": {
              width: 0,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <Fade in={true} timeout={4000}>
            <Box
              height="210px"
              position="relative"
              sx={{
                background: "url(/walletBanner.png)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "8px",
              }}
            >
              <Box
                position="absolute"
                top="50%"
                left="50%"
                sx={{ transform: "translate(-50%,-50%)", width: "90%" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="12px"
              >
                <Box textAlign={"center"}>
                  <Typography color={main} variant="h3" fontWeight={600}>
                    پیش بینی خطرات احتمالی برای
                  </Typography>
                  <Typography
                    color={main}
                    variant="h2"
                    fontWeight={400}
                    mt="0.5rem"
                  >
                    سلامت دام
                  </Typography>
                </Box>
                <Box
                  width="228px"
                  height="32px"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.first,
                    cursor: "pointer",
                  }}
                  onClick={() => setWeatherModule((prev) => !prev)}
                >
                  <Typography>بیشتر</Typography>
                </Box>
              </Box>
            </Box>
          </Fade>
          <Fade in={true} timeout={6000}>
            <WidgetWrapper mt="12px">
              <FlexBetween>
                <Stack direction={"row"} gap="0.5rem">
                  <Typography>واریزی</Typography>
                  <Typography>(تومان)</Typography>
                </Stack>
                <WalletSelectBox items={["ماهانه"]} />
              </FlexBetween>

              <WalletChart
                min={0}
                max={11000000}
                stepSize={5000000}
                h={"85%"}
              />
              <Box
                height="40px"
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="12px"
                sx={{
                  backgroundColor: theme.palette.others.first,
                  cursor: "pointer",
                }}
                onClick={() => setTransactionView(true)}
              >
                <Typography>تراکنش ها</Typography>
              </Box>
            </WidgetWrapper>
          </Fade>
          <Fade in={true} timeout={6000}>
            <WidgetWrapper mt="12px">
              <Typography variant="h4" color={main}>
                کیف پول
              </Typography>
              <Box
                width="100%"
                height="90%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AnimatedProgressBarWallet
                  number={75}
                  width={157}
                  height={163}
                  title="200"
                  subTitle="350 هزار تومان"
                  color={theme.palette.others.first}
                  dot={false}
                />
              </Box>
              <Box
                height="40px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="8px"
                sx={{
                  backgroundColor: theme.palette.others.first,
                  cursor: "pointer",
                  mt: "12px",
                }}
              >
                <Typography variant="subHeading" color={main}>
                  شارژ کیف پول
                </Typography>
              </Box>
            </WidgetWrapper>
          </Fade>
          <Fade in={true} timeout={6000}>
            <WidgetWrapper mt="12px" direction="ltr">
              <WalletFeatureItemMob
                title={"عملگرها"}
                totalDays="180"
                route={"/actuator"}
                remainedDays={(61 / 180) * 100}
                titleRemained={61}
                color={theme.palette.others.forth}
              />
              <Divider orientation="horizontal" flexItem />
              <WalletFeatureItemMob
                title={"بسته اقتصادی 1"}
                totalDays="365"
                remainedDays={Math.round((90 / 365) * 100)}
                titleRemained={90}
                color={theme.palette.others.second}
              />
              <Divider orientation="horizontal" flexItem />
              <WalletFeatureItemMob
                title={"مدیریت کارکنان"}
                totalDays={180}
                remainedDays={Math.round((88 / 180) * 100)}
                titleRemained={88}
                color={theme.palette.others.forth}
                route={"/staffmgmt"}
              />
              <Divider orientation="horizontal" flexItem />
              <WalletFeatureItemMob
                title={"هواشناسی"}
                totalDays={90}
                remainedDays={Math.round((68 / 90) * 100)}
                titleRemained={68}
                color={theme.palette.others.third}
                route={"/weather"}
              />
            </WidgetWrapper>
          </Fade>
          <Box ref={ref}>
            <motion.div animate={Box1Animation}>
              <Plan plan={planData.economic1} />
            </motion.div>
            <motion.div animate={Box2Animation}>
              <Plan plan={planData.economic2} />
            </motion.div>
            <motion.div animate={Box3Animation}>
              <Plan plan={planData.economic3} />
            </motion.div>
            <motion.div animate={Box4Animation}>
              <Plan plan={planData.economic4} />
            </motion.div>
            <motion.div animate={Box5Animation}>
              <Plan plan={planData.fullPack} />
            </motion.div>
          </Box>
          <WidgetWrapper mt="12px">
            <Typography variant="h4" color={main}>
              سرویس ها
            </Typography>
            {ServicesData.map((item) => (
              <ServiceItemMob data={item} />
            ))}
          </WidgetWrapper>
          <WidgetWrapper mt="12px">
            <Typography variant="h4" color={main} mb="24px">
              کارشناس ها
            </Typography>
            {expertFields.map((field) => (
              <>
                <Typography variant="h4" color={main} my="12px">
                  {expertFieldTitle(field)}
                </Typography>
                <Stack
                  direction="row"
                  gap="1.5rem"
                  alignItems="center"
                  sx={{
                    overflowY: "hidden !important",
                    overflowX: "auto",
                    overflow: "-moz-scrollbars-none",
                    "-ms-scrollbar-style": "none",
                    scrollbarWidth: "none",
                    mb: "1rem",
                    px: "0.5rem",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {expertData
                    .filter((item) => item.fields.includes(field))
                    .sort((a, b) => {
                      let indexA = a.active.includes(field) ? 1 : -1;
                      let indexB = b.active.includes(field) ? 1 : -1;
                      return indexB - indexA;
                    })
                    .map((item) => (
                      <ExpertItemMob
                        setExpertId={setExpertId}
                        data={item}
                        active={item.active.includes(field)}
                      />
                    ))}
                </Stack>
              </>
            ))}
          </WidgetWrapper>
          <Box
            sx={{
              backgroundColor: theme.palette.background.second,
              borderRadius: "0.75rem",
              mt: "12px",
            }}
          >
            <Typography variant="h4" color={main} p="12px 12px 24px">
              رسم همسایگی
            </Typography>
            <WalletMapMob setNeighbor={setNeighbor} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default WalletMob;
