import { Box } from "@mui/material";
import { useState } from "react";
import DrawMapMob from "./DrawMapMob";
import CultivationTypeMob from "./CultivationTypeMob";
import WidgetWrapper from "components/WidgetWrapper";
import WateringMob from "./WateringMob";
import WaterRequirementMob from "./WaterRequirementMob";
import FertilizerPoisonMob from "./FertilizerPoisonMob";
import StepsBar from "mob/componentsMob/StepsBar/StepsBar";

const RecordFarmMob = () => {
  const [steps, setSteps] = useState([
    "ACTIVE",
    "INACTIVE",
    "INACTIVE",
    "INACTIVE",
    "INACTIVE",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [polygonArea, setPolygonArea] = useState(0);

  const handleStep = (direction, status) => {
    let tmp = [...steps];
    switch (direction) {
      case "NEXT":
        if (activeStep < 2) {
          tmp[activeStep] = status;
          tmp[activeStep + 1] = "ACTIVE";
          setActiveStep((prev) => prev + 1);
        }
        break;
      case "BACK":
        if (activeStep > 0) {
          tmp[activeStep] = "INACTIVE";
          tmp[activeStep - 1] = "ACTIVE";
          setActiveStep((prev) => prev - 1);
        }
        break;
      default:
        break;
    }
    setSteps(tmp);
  };
  return (
    <Box sx={{ overflow: "auto", height: "calc(100dvh - 2px)" }}>
      {activeStep === 0 && (
        <DrawMapMob
          polygonArea={polygonArea}
          setPolygonArea={setPolygonArea}
          handleStep={handleStep}
        />
      )}
      <Box>
        {activeStep > 0 && (
          <WidgetWrapper mx="12px" mt="12px">
            {activeStep === 1 && (
              <CultivationTypeMob
                polygonArea={polygonArea}
                handleStep={handleStep}
              />
            )}
          </WidgetWrapper>
        )}
      </Box>
    </Box>
  );
};

export default RecordFarmMob;
