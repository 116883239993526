import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useRef, useState } from "react";
import CopyRight from "widgets/CopyRight";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
import CustomSelectBoxComfort from "scenes/LivestockManagement/CustomSelectBoxComfort";
import ReportSensorDataMap from "./ReportSensorDataMap";
import DatePicker from "react-multi-date-picker";
import { getDateShamsiAirSensors } from "Utils/Utils";
import { useSelector } from "react-redux";
import calendar from "../../assets/calendar.svg";
import calendarLight from "../../assets/calendar-light.svg";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";

const ReportData = {
  birth: {},
};
const Report = () => {
  const theme = useTheme();
  const [liveGroup, setLiveGroup] = useState(false);
  const [actions, setActions] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("کل دام");
  const datePickerRefFrom = useRef();
  const datePickerRefTo = useRef();
  const mode = useSelector((state) => state.global.mode);

  const LiveItem = ({ id, title }) => {
    return (
      <Box
        width="95%"
        height="32px"
        display="flex"
        alignItems="center"
        mb="0.2rem"
        p="0.5rem"
        borderRadius="4px"
        sx={{
          backgroundColor:
            selectedTitle === title
              ? theme.palette.background.third
              : "transparent",
          ":hover": {
            backgroundColor: "background.third",
            cursor: "pointer",
          },
        }}
        onClick={() => setSelectedTitle(title)}
      >
        <Typography variant="subHeading" color="fontColor.main">
          {title}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="89vh">
        <WidgetWrapper
          flex={1}
          height="98%"
          maxHeight="86vh"
          sx={{ padding: "1rem 1rem 1.1rem 0.2rem" }}
        >
          <Box
            width="100%"
            height="100%"
            sx={{
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            <FlexBetween>
              <Typography variant="h5" color="fontColor.main">
                دسته بندی دام
              </Typography>
              <IconButton onClick={() => setLiveGroup(!liveGroup)}>
                <KeyboardArrowDownOutlined
                  sx={{ rotate: !liveGroup ? "-180deg" : "" }}
                />
              </IconButton>
            </FlexBetween>
            <Collapse in={!liveGroup} timeout={500}>
              <Box overflow="hidden">
                <LiveItem id={1} title="خشکی" />
                <LiveItem id={2} title="فحلی" />
                <LiveItem id={3} title="کل دام" />
              </Box>
            </Collapse>
            <FlexBetween mt="1rem">
              <Typography variant="h5" color="fontColor.main">
                اقدامات
              </Typography>
              <IconButton onClick={() => setActions(!actions)}>
                <KeyboardArrowDownOutlined
                  sx={{ rotate: !actions ? "-180deg" : "" }}
                />
              </IconButton>
            </FlexBetween>
            <Collapse in={!actions} timeout={500}>
              <Box overflow="hidden">
                <LiveItem id={4} title="زایش" />
                <LiveItem id={5} title="تلقیح" />
                <LiveItem id={6} title="واکسن" />
                <LiveItem id={7} title="سم چینی" />
                <LiveItem id={8} title="درمان" />
                <LiveItem id={9} title="دارو" />
                <LiveItem id={10} title="وضعیت سلامت" />
                <LiveItem id={11} title="آسایش دام" />
              </Box>
            </Collapse>
            <Box
              mt="1rem"
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => setSelectedTitle("شجره دام")}
            >
              <Typography variant="h5" color="fontColor.main">
                شجره دام
              </Typography>
            </Box>
            <Box
              mt="1rem"
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => setSelectedTitle("تراکنش ها")}
            >
              <Typography variant="h5" color="fontColor.main">
                تراکنش ها
              </Typography>
            </Box>
            <Box
              mt="1rem"
              sx={{
                ":hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => setSelectedTitle("سنسورها")}
            >
              <Typography variant="h5" color="fontColor.main">
                سنسورها
              </Typography>
            </Box>
            <Divider variant="fullWidth" sx={{ m: "12px 0" }} />
            {/* width, height, items, selectedItem, setSelectedItem, all = false,
            title, */}
            <Box width="100%" height="27px" mb="0.5rem">
              <CustomSelectBoxComfort
                minWidth="95%"
                height="27px"
                all={true}
                title="فواصل زایش"
                //   selectedItem={}
                // setSelectedItem={}
                // items={}
              />
            </Box>
            <Box width="100%" height="27px" mb="0.5rem">
              <CustomSelectBoxComfort
                minWidth="95%"
                height="27px"
                all={true}
                title="فواصل زایش"
                //   selectedItem={}
                // setSelectedItem={}
                // items={}
              />
            </Box>
            <Box width="100%" height="27px" mb="0.5rem">
              <CustomSelectBoxComfort
                minWidth="95%"
                height="27px"
                all={true}
                title="فواصل زایش"
                //   selectedItem={}
                // setSelectedItem={}
                // items={}
              />
            </Box>
            <ReportSensorDataMap
            // data={sensorsData[sensorType]}
            // selectedSensor={selectedSensor}
            // setSelectedSensor={setSelectedSensor}
            />

            <Box
              sx={{
                mt: "1rem",
                //   position: "absolute",
                //   [direction]: 10,
                padding: "0 12px",
                //   bottom: 20,
                width: "95%",
                height: "27px",
                background: "transparent",
                border: `1px solid ${theme.palette.fontColor.alt}`,
                zIndex: 1000,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1rem",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                },
                "& .rmdp-wrapper": {
                  backgroundColor: theme.palette.background.third,
                  width: "95%",
                  borderRadius: "8px",
                  overflow: "hidden",
                },
                "& .rmdp-container": {
                  width: "100%",
                  display: "block",
                  fontSize: "12px",
                },
                "& .rmdp-day": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-arrow": {
                  borderColor: theme.palette.fontColor.main,
                },
                "& .rmdp-week": {
                  width: "202px",
                },
                "& .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values span": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-panel-header": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-ep-arrow": {
                  borderBottom: "none",
                  borderTop: "none",
                },
                "& .ep-arrow::after": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-ep-shadow:after": {
                  boxShadow: "none",
                },
                "& .rmdp-month-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-year-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-input": {
                  color: "white !important",
                  fontFamily: "YekanBakhFaNum-Regular",
                },
                "& .rmdp-top-class": {
                  backgroundColor: theme.palette.background.third,
                  color: "#fff",
                },
                "& .rmdp-day.rmdp-today span": {
                  backgroundColor: "rgba(42,128,244,0.5)",
                },
                "& .rmdp-day.rmdp-selected span:not(.highlight)": {
                  backgroundColor: theme.palette.primary.alt,
                },
                "& .rmdp-day .sd": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-week .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-shadow": {
                  boxShadow: "none",
                },
                "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover":
                  {
                    backgroundColor: theme.palette.primary.alt,
                  },
              }}
            >
              <DatePicker
                render={
                  <div
                    onClick={() => datePickerRefFrom.current.openCalendar()}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      mode,
                    }}
                  >
                    <div style={{ display: "flex", gap: "8px" }}>
                      <div>از</div>
                      <div>
                        {/* {getDateShamsiAirSensors(
                        sensorsData.WEATHER[0].data[0].data[0].ts
                      )} */}
                      </div>
                    </div>
                    <img
                      src={mode === "dark" ? calendar : calendarLight}
                      alt="calendar icon"
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </div>
                }
                calendarPosition="top-center"
                className="bg-dark rmdp-prime"
                calendar={persian}
                locale={persian_fa}
                weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
                // value={getDateShamsiAirSensors(selectedLayer.date)}
                // onChange={handleDateChange}
                ref={datePickerRefFrom}
              />
            </Box>
            <Box
              sx={{
                mt: "1rem",
                //   position: "absolute",
                //   [direction]: 10,
                padding: "0 12px",
                //   bottom: 20,
                width: "95%",
                height: "27px",
                background: "transparent",
                border: `1px solid ${theme.palette.fontColor.alt}`,
                zIndex: 1000,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "1rem",
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                },
                "& .rmdp-wrapper": {
                  backgroundColor: theme.palette.background.third,
                  width: "95%",
                  borderRadius: "8px",
                  overflow: "hidden",
                },
                "& .rmdp-container": {
                  width: "100%",
                  display: "block",
                  fontSize: "12px",
                },
                "& .rmdp-day": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-arrow": {
                  borderColor: theme.palette.fontColor.main,
                },
                "& .rmdp-week": {
                  width: "202px",
                },
                "& .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values span": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-panel-header": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-ep-arrow": {
                  borderBottom: "none",
                  borderTop: "none",
                },
                "& .ep-arrow::after": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-ep-shadow:after": {
                  boxShadow: "none",
                },
                "& .rmdp-month-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-year-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-input": {
                  color: "white !important",
                  fontFamily: "YekanBakhFaNum-Regular",
                },
                "& .rmdp-top-class": {
                  backgroundColor: theme.palette.background.third,
                  color: "#fff",
                },
                "& .rmdp-day.rmdp-today span": {
                  backgroundColor: "rgba(42,128,244,0.5)",
                },
                "& .rmdp-day.rmdp-selected span:not(.highlight)": {
                  backgroundColor: theme.palette.primary.alt,
                },
                "& .rmdp-day .sd": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-week .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-shadow": {
                  boxShadow: "none",
                },
                "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover":
                  {
                    backgroundColor: theme.palette.primary.alt,
                  },
              }}
            >
              <DatePicker
                render={
                  <div
                    onClick={() => datePickerRefFrom.current.openCalendar()}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      mode,
                    }}
                  >
                    <div style={{ display: "flex", gap: "8px" }}>
                      <div>از</div>
                      <div>
                        {/* {getDateShamsiAirSensors(
                        sensorsData.WEATHER[0].data[0].data[0].ts
                      )} */}
                      </div>
                    </div>
                    <img
                      src={mode === "dark" ? calendar : calendarLight}
                      alt="calendar icon"
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </div>
                }
                calendarPosition="top-center"
                className="bg-dark rmdp-prime"
                calendar={persian}
                locale={persian_fa}
                weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
                // value={getDateShamsiAirSensors(selectedLayer.date)}
                // onChange={handleDateChange}
                ref={datePickerRefFrom}
              />
            </Box>
            <Box
              width="95%"
              height="27px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
              sx={{ backgroundColor: theme.palette.others.first }}
            >
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                گرفتن گزارش
              </Typography>
            </Box>
          </Box>
        </WidgetWrapper>

        <Box flex={6.2} height="98%">
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {/* {selectedTitle === "آسایش دام" && (
              <AnimalComfort selectedTitle={selectedTitle} />
            )} */}
          </Box>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default Report;
