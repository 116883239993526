import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const DailyWeatherFluctuations = ({
  minMinTemperature,
  maxMaxTemperature,
  min,
  max,
}) => {
  const theme = useTheme();
  const diff = maxMaxTemperature - minMinTemperature;
  console.log("🚀 ~ file: DailyWeatherFluctuations.jsx:12 ~ diff:", max - min);
  return (
    <Stack direction="row" alignItems="center">
      <Typography variant="subHeading" color={theme.palette.fontColor.alt}>
        {Math.round(maxMaxTemperature)}°
      </Typography>
      <Box
        sx={{
          width: "46px",
          height: "2px",
          backgroundColor: theme.palette.background.third,
          m: "0 5px",
          position: "relative",
        }}
      >
        <Box
          position="absolute"
          sx={{
            top: 0,
            left: `${((min - minMinTemperature) / diff) * 100}%`,
            width: `${((max - min) / diff) * 100}%`,
            height: "2px",
            background: "linear-gradient(90deg, #FFEE00 0%, #FC4949 97.88%)",
          }}
        ></Box>
      </Box>
      <Typography variant="h5" color={theme.palette.fontColor.main}>
        {Math.round(minMinTemperature)}°
      </Typography>
    </Stack>
  );
};

export default DailyWeatherFluctuations;
