import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  MenuList,
  Rating,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import FarmIcon from "../../assets/Modules/weather1.png";
import FarmIcon2 from "../../assets/Modules/Weather2.png";
import FarmIcon3 from "../../assets/Modules/Weather3.png";
import FarmIcon4 from "../../assets/Modules/Weather4.png";
import PackageItemMob from "./PackageItemMob";
import { useSelector } from "react-redux";
import ArrowRightDark from "../../assets/icon/arrow-right-dark.svg";
import ArrowRightLight from "../../assets/icon/arrow-right-light.svg";
import ShoppingCardDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCardLight from "../../assets/icon/shopping-cart-light.svg";
import MessageDark from "../../assets/icon/message-dark.svg";
import MessageLight from "../../assets/icon/message-light.svg";
import StarDark from "../../assets/icon/star-dark.svg";
import StarLight from "../../assets/icon/star-light.svg";
import CommentItem from "modules/WeatherModule/commentItem";
import { expertData } from "./data";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { KeyboardArrowDownOutlined } from "@mui/icons-material";
const CommentsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.fontColor.main,
  backgroundColor: theme.palette.background.third,
  boxShadow: "none",
  borderRadius: "8px",
  width: "100%",
  height: "36px",
  "&:focus": {
    backgroundColor: theme.palette.background.third,
  },
}));

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
}));
const ExpertModuleMob = ({ setExpertId, expertId }) => {
  const expert = expertData.find((item) => item.id === expertId);
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [recommendationFilter, setRecommendationFilter] =
    useState("تعداد توصیه ها");
  const [selectedPackageFilter, setSelectedPackageFilter] =
    useState("همه بسته ها");
  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "calc(100dvh - 112px)",
          padding: "12px",
        }}
      >
        <WidgetWrapper mt="12px">
          <IconButton sx={{ padding: "8px 0" }}>
            <Box
              component="img"
              width="32px"
              height="32px"
              borderRadius="50%"
              src={mode === "dark" ? ArrowRightDark : ArrowRightLight}
              onClick={() => setExpertId(null)}
            />
          </IconButton>
          <Stack
            direction="row"
            alignItems="stretch"
            width="100%"
            gap="16px"
            mb="12px"
          >
            <Box component="img" src={expert.pic} width="64px" height="64px" />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              width="90%"
            >
              <Typography variant="h4" color="fontColor.main">
                {expert.name}
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                کارشناس کشاورزی - تهران
              </Typography>
            </Box>
            <Box
              component="img"
              src={mode === "dark" ? ShoppingCardDark : ShoppingCardLight}
              width="24px"
              height="24px"
            />
          </Stack>

          <Typography sx={{ my: "1rem" }}>شماره پروانه - ۳۵۸</Typography>
          <Stack
            direction="row"
            alignItems="center"
            gap="24px"
            justifyContent="flex-start"
            mb="16px"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Rating
                size="medium"
                name="read-only"
                value={expert.rate}
                readOnly
                sx={{ direction: "ltr" }}
              />
              <Typography variant="subHeading" color="fontColor.alt">
                83 نفر
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Typography variant="h5" color="fontColor.main">
                150
              </Typography>
              <Typography variant="subHeading" color="fontColor.alt">
                خرید
              </Typography>
            </Box>
          </Stack>
          <Divider />
          <Typography sx={{ my: "1rem" }}>بسته های درخواست کارشناس</Typography>
          <FlexBetween
            sx={{
              gap: "12px",
            }}
          >
            <Box
              //   width="48%"
              height="36px"
              borderRadius="8px"
              sx={{ flex: "1 1 40%" }}
            >
              <Select
                value={selectedPackageFilter}
                onChange={(e) => setSelectedPackageFilter(e.target.value)}
                IconComponent={() => <KeyboardArrowDownOutlined />}
                MenuProps={{
                  // Apply the custom styled MenuList component
                  MenuListProps: { component: StyledMenuList },
                }}
                sx={{
                  width: "100%",
                  height: "36px",
                  border: `1px solid ${theme.palette.fontColor.alt}`,
                  borderRadius: "8px",

                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#40405e",
                  },
                }}
              >
                <MenuItem
                  value={"همه بسته ها"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    m: "0 5px",
                    borderRadius: "4px",
                    backgroundColor: `${theme.palette.background.third}!important`,
                    ":hover": {
                      backgroundColor: `${theme.palette.fontColor.third} !important`,
                    },
                  }}
                >
                  <Typography
                    variant="smallestTextSecondary"
                    fontColor={theme.palette.fontColor.main}
                  >
                    همه بسته ها
                  </Typography>
                </MenuItem>
              </Select>
            </Box>
            <Box
              //   width="48%"
              height="36px"
              borderRadius="8px"
              sx={{ flex: "1 1 40%" }}
            >
              <Select
                value={recommendationFilter}
                onChange={(e) => setRecommendationFilter(e.target.value)}
                IconComponent={() => <KeyboardArrowDownOutlined />}
                MenuProps={{
                  // Apply the custom styled MenuList component
                  MenuListProps: { component: StyledMenuList },
                }}
                sx={{
                  width: "100%",
                  height: "36px",
                  border: `1px solid ${theme.palette.fontColor.alt}`,
                  borderRadius: "8px",

                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#40405e",
                  },
                }}
              >
                <MenuItem
                  value={"تعداد توصیه ها"}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    m: "0 5px",
                    borderRadius: "4px",
                    backgroundColor: `${theme.palette.background.third}!important`,
                    ":hover": {
                      backgroundColor: `${theme.palette.fontColor.third} !important`,
                    },
                  }}
                >
                  <Typography
                    variant="smallestTextSecondary"
                    fontColor={theme.palette.fontColor.main}
                  >
                    تعداد توصیه ها
                  </Typography>
                </MenuItem>
              </Select>
            </Box>
          </FlexBetween>
          <Box
            my="2rem"
            width="100%"
            //   height="50%"
            display="flex"
            alignItems="stretch"
            // p="0 1rem"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap", gap: "12px" }}
          >
            <PackageItemMob
              count={20}
              title={"یک ماهه"}
              price={"750"}
              unit={"هزار تومان"}
            />
            <PackageItemMob
              count={40}
              title={"یک ماهه"}
              price={"1.3"}
              unit={"میلیون تومان"}
              enable={true}
            />
            <PackageItemMob
              count={80}
              title={"یک ماهه"}
              price={"2.2"}
              unit={"میلیون تومان"}
            />
            <PackageItemMob
              count={160}
              title={"یک ماهه"}
              price={"4"}
              unit={"میلیون تومان"}
            />
          </Box>
          <Divider />
          <Box
            my="2rem"
            width="100%"
            //   height="50%"
            display="flex"
            alignItems="stretch"
            // p="0 1rem"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap", gap: "12px" }}
          >
            <PackageItemMob
              count={20}
              title={"سه ماهه"}
              price={"750"}
              unit={"هزار تومان"}
            />
            <PackageItemMob
              count={40}
              title={"سه ماهه"}
              price={"1.3"}
              unit={"میلیون تومان"}
              enable={true}
            />
            <PackageItemMob
              count={80}
              title={"سه ماهه"}
              price={"2.2"}
              unit={"میلیون تومان"}
            />
            <PackageItemMob
              count={160}
              title={"سه ماهه"}
              price={"4"}
              unit={"میلیون تومان"}
            />
          </Box>
          <Divider />

          <Box
            my="2rem"
            width="100%"
            //   height="50%"
            display="flex"
            alignItems="stretch"
            // p="0 1rem"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap", gap: "12px" }}
          >
            <PackageItemMob
              count={20}
              title={"شش ماهه"}
              price={"750"}
              unit={"هزار تومان"}
            />
            <PackageItemMob
              count={40}
              title={"شش ماهه"}
              price={"1.3"}
              unit={"میلیون تومان"}
              enable={true}
            />
            <PackageItemMob
              count={80}
              title={"شش ماهه"}
              price={"2.2"}
              unit={"میلیون تومان"}
            />
            <PackageItemMob
              count={160}
              title={"شش ماهه"}
              price={"4"}
              unit={"میلیون تومان"}
            />
          </Box>
          <Divider />

          <Box
            my="2rem"
            width="100%"
            //   height="50%"
            display="flex"
            alignItems="stretch"
            // p="0 1rem"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap", gap: "12px" }}
          >
            <PackageItemMob
              count={20}
              title={"یک ساله"}
              price={"750"}
              unit={"هزار تومان"}
            />
            <PackageItemMob
              count={40}
              title={"یک ساله"}
              price={"1.3"}
              unit={"میلیون تومان"}
              enable={true}
            />
            <PackageItemMob
              count={80}
              title={"یک ساله"}
              price={"2.2"}
              unit={"میلیون تومان"}
            />
            <PackageItemMob
              count={160}
              title={"یک ساله"}
              price={"4"}
              unit={"میلیون تومان"}
            />
          </Box>
          <Divider />
        </WidgetWrapper>
        <WidgetWrapper mt="12px">
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography variant="h4" color="fontColor.main">
              نظرات شما
            </Typography>
            <Typography variant="subHeading" color="fontColor.alt">
              یک دیدگاه
            </Typography>
          </Stack>

          <CommentItem />
          <Divider />
          <CommentItem />
          <Divider />
          <CommentItem />

          <Box
            sx={{
              width: "136px",
              m: "1rem auto",
            }}
          >
            <CommentsButton>همه نظرات</CommentsButton>
          </Box>
          <Typography
            variant="smallestTextSecondary"
            color="fontColor.alt"
            sx={{
              borderBottom: `1px solid ${theme.palette.fontColor.alt}`,
              display: "flex",
              alignItems: "center",
              gap: "4px",
              width: "fit-content",
            }}
          >
            <Box
              component="img"
              src={mode === "dark" ? MessageDark : MessageLight}
              width="16px"
              height="16px"
            />
            نظر خود را ثبت کنید
          </Typography>
        </WidgetWrapper>
        <WidgetWrapper mt="12px">
          <Typography variant="h4">کارشناس های پیشنهادی</Typography>
          <Box
            width="100%"
            display="flex"
            alignItems="stretch"
            justifyContent="flex-start"
            mt="1rem"
            gap="12px"
            sx={{
              overflowY: "hidden !important",
              overflowX: "auto",
              overflow: "-moz-scrollbars-none",
              "-ms-scrollbar-style": "none",
              scrollbarWidth: "none",
              mb: "1rem",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {expertData.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  component="img"
                  src={item.pic}
                  width="82px"
                  height="82px"
                />
                <Typography
                  variant="smallestTextSecondary"
                  sx={{ alignSelf: "flex-start" }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    width: "fit-content",
                  }}
                  variant="smallestTextSecondary"
                >
                  <Box
                    component="img"
                    src={mode === "dark" ? StarDark : StarLight}
                    width="6px"
                    height="6px"
                  />
                  4.5
                </Typography>
              </Box>
            ))}
          </Box>
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default ExpertModuleMob;
