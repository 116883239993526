import moment from 'jalali-moment';
import jalaali from 'jalaali-js';
import momentjs from 'moment';

export const getMonthDay = (dd) => {
    const date = momentjs(dd);
    const d = new Date(date);
    return new Intl.DateTimeFormat('fa-IR', {
        month: '2-digit',
        day: '2-digit',
    }).format(d);
};

export const getMonthDayP1 = (dd) => {
    const date = momentjs(dd);
    momentjs.locale('fa');
    const d = new Date(date);
    return new Intl.DateTimeFormat('fa-IR', {
        month: '2-digit',
        day: '2-digit',
    }).format(d);
};
export const getDayName = (dd) => {
    const date1 = momentjs(dd);
    momentjs.locale('fa'); // set the locale to Persian (Farsi)
    return date1.format('dddd');
};

export const getMonthDayT = (dd) => {
    const myDate = new Date(dd);
    const myDateString = myDate.toISOString();
    const date = moment(myDateString);
    const year = date.jYear();
    const month = date.jMonth();
    const day = date.jDate();
    const d = new Date(year, month, day);
    return new Intl.DateTimeFormat('fa-IR', {
        month: '2-digit',
        day: '2-digit',
    }).format(d);
};

export const getTimeT = (dd) => {
    const date = moment(dd);

    return date.format('HH:mm');
};

export const getDateShamsi = (date1) => {
    const date = momentjs(date1);
    const d = new Date(date);
    return new Intl.DateTimeFormat('fa-IR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(d);
};

export const getDateShamsiAirSensors = (date1) => {
    const myDate = new Date(date1);
    const myDateString = myDate.toISOString();
    const date = moment(myDateString);
    return new Intl.DateTimeFormat('fa-IR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }).format(date);
};

export const getTimeShamsiAirSensors = (date1) => {
    const myDate = new Date(date1);
    const myDateString = myDate.toISOString();
    const date = moment(myDateString);
    const year = date.jYear();
    const month = date.jMonth();
    const day = date.jDate();
    const d = new Date(year, month, day);
    return new Intl.DateTimeFormat('fa-IR', {
        hour: '2-digit',
        minute: '2-digit',
    }).format(d);
};

export const shamsiToGregorian = (shamsiDate) => {
    const [shamsiYear, shamsiMonth, shamsiDay] = shamsiDate.split('-').map(Number);
    const { gy, gm, gd } = jalaali.toGregorian(shamsiYear, shamsiMonth, shamsiDay);
    return `${gy}-${gm.toString().padStart(2, '0')}-${gd.toString().padStart(2, '0')}`;
};

export const debounce = (fn, time = 100) => {
    let timer = null;
    return (evt) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(evt), time);
    };
};

export const throttle = (cb, delay = 1000) => {
    let shouldWait = false;

    return (...args) => {
        if (shouldWait) return;

        cb(...args);
        shouldWait = true;
        setTimeout(() => {
            shouldWait = false;
        }, delay);
    };
};
