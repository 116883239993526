import { createSlice } from "@reduxjs/toolkit";

    const initialState ={
        growth: {},
        recommendations: {},
        remote: {},
        staffs: {},
        weather: {},
        index: {},
        actuators: {},
        alerts: {},
        sensors: {},
        cultivation: {},
        property:{}
    };

    export const dashboardSlice = createSlice({
        name:"dashboard",
        initialState,
        reducers:{ 
            setData:(state,action)=>{
                state.growth = action.payload.growth;
                state.recommendations = action.payload.recommendations;
                state.remote = action.payload.remote;
                state.staffs = action.payload.staffs;
                state.weather = action.payload.weather;
                state.index = action.payload.index;
                state.actuators = action.payload.actuators;
                state.alerts = action.payload.alerts;
                state.sensors = action.payload.sensors;
                state.cultivation = action.payload.cultivation;
            },
            setProperty:(state,action)=>{
                state.property= action.payload.property
            }
           
        }
    });

    export const {setData,setProperty}= dashboardSlice.actions;
    export default dashboardSlice.reducer;