import React, { useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { AddOutlined, ArrowUpwardOutlined } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import ArrowIcon from "../../assets/arrow.png";
import TaskIcon from "../../assets/task.png";
import MachineIcon from "../../assets/machine.png";
import WaterIcon from "../../assets/waterRec.png";
import PoisonIcon from "../../assets/poisonRec.png";
import SoilIcon from "../../assets/soilRec.png";
import DetailRecommends from "scenes/Recommends/DetailRecommends";
import DetailsStaffs from "./DetailsStaffs";

const StaffTable = ({ data }) => {
  // const data1 = data.map((item) => {
  //   const t = item.title.split("/");
  //   return {
  //     name: t[1],
  //     title: t[0],
  //     priority: t[2],
  //   };
  // });

  const [toggleDate, setToggleDate] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [toggles, setToggles] = useState(data ? data.map(() => false) : false);

  const handleToggle = (index) => {
    setToggles((prevToggles) => {
      const newToggles = [...prevToggles];
      newToggles[index] = !newToggles[index];
      return newToggles;
    });
  };
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box style={{ height: "90%", width: "100%" }} mt="1rem">
      <Box display="flex">
        <Box
          width="15%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
            mr="10%"
          >
            اسم کارگر
          </Typography>
        </Box>
        <Box
          width="10%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            وظیفه
          </Typography>
        </Box>
        <Box
          width="10%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FlexBetween>
            <Typography
              fontSize={theme.typography.subHeading}
              color={theme.palette.fontColor.alt}
            >
              اولویت
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleDate ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleDate(!toggleDate);
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </FlexBetween>
        </Box>
        <Box
          width="15%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FlexBetween>
            <Typography
              fontSize={theme.typography.subHeading}
              color={theme.palette.fontColor.alt}
            >
              زمان ارسال
            </Typography>
          </FlexBetween>
        </Box>
        <Box
          width="15%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            زمان مشاهده
          </Typography>
        </Box>
        <Box
          width="10%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            زمان انجام
          </Typography>
        </Box>
        <Box
          width="10%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            وضعیت
          </Typography>
        </Box>
        <Box
          width="5%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            ارسال
          </Typography>
        </Box>
        <Box
          width="10%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            ویرایش
          </Typography>
        </Box>
        <Box
          width="5%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
      </Box>

      <Divider sx={{ marginTop: "10px" }} />
      <Box
        sx={{
          height: "90%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        {data
          ? data.map((item, index) => (
              <>
                <Box display="flex" m="1rem 0">
                  <Box
                    width="14.3%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap="0.25rem"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap="1rem"
                    >
                      <Box
                        width="1.5rem"
                        height="1.5rem"
                        component="img"
                        src={item.pic}
                        borderRadius="99px"
                        sx={{ objectFit: "cover" }}
                      />
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextSecondary}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width="9.6%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.title}
                    </Typography>
                  </Box>
                  <Box
                    width="9.6%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      width="90%"
                      height="23px"
                      borderRadius="8px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        direction: "ltr",
                      }}
                    >
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.tasks.priority}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width="14.3%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.sendDate}
                    </Typography>
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.sendTime}
                    </Typography>
                  </Box>
                  <Box
                    width="14.3%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.seenDate}
                    </Typography>
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.seenTime}
                    </Typography>
                  </Box>
                  <Box
                    width="9.6%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.tasks.doTime}
                    </Typography>
                  </Box>

                  <Box
                    width="9.3%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      width="4.8124rem"
                      height="1.4375rem"
                      borderRadius="4px"
                      sx={{
                        backgroundColor:
                          item?.tasks?.status === "در حال انجام"
                            ? theme.palette.others.forth
                            : item?.tasks?.status === "انجام شده"
                            ? theme.palette.others.third
                            : theme.palette.others.second,
                      }}
                    >
                      <Typography
                        color={theme.palette.background.second}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.tasks.status}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.93313 4.21331L10.5931 2.32665C13.1331 1.47998 14.5131 2.86665 13.6731 5.40665L11.7865 11.0666C10.5198 14.8733 8.43979 14.8733 7.17313 11.0666L6.61312 9.38665L4.93313 8.82665C1.12646 7.55998 1.12646 5.48665 4.93313 4.21331Z"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.74023 9.1L9.1269 6.70667"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.83958 2.4L3.36624 8.19333C3.15958 8.41333 2.95958 8.84667 2.91958 9.14667L2.67291 11.3067C2.58624 12.0867 3.14624 12.62 3.91958 12.4867L6.06624 12.12C6.36624 12.0667 6.78624 11.8467 6.99291 11.62L12.4662 5.82667C13.4129 4.82667 13.8396 3.68667 12.3662 2.29334C10.8996 0.913336 9.78624 1.4 8.83958 2.4Z"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.92676 3.36664C8.21342 5.20664 9.70676 6.6133 11.5601 6.79997"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2 14.6667H14"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </IconButton>
                  </Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton
                      sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                      onClick={() => handleToggle(index)}
                    >
                      <Box
                        component="img"
                        src={ArrowIcon}
                        sx={{ rotate: toggle ? 0 : "180deg" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Collapse in={toggles[index]} timeout={500}>
                  <DetailsStaffs
                    taskDescription={item.tasks.taskDescription}
                    comment={item.tasks.comment}
                    backupAction={item.tasks.backupAction}
                    startDate={item.tasks.startDate}
                    endDate={item.tasks.endDate}
                  />
                </Collapse>

                <Divider />
              </>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default StaffTable;
