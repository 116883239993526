import { Box, useMediaQuery } from "@mui/material";
import LivestockManagementMob from "mob/LivestockManagementMob/LivestockManagementMob";
import React from "react";
import LivestockManagement from "./LivestockManagement";

const LivestockManagementIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      {isMobileScreens ? <LivestockManagementMob /> : <LivestockManagement />}
    </Box>
  );
};

export default LivestockManagementIndex;
