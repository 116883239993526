import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import SunriseIcon from "../../assets/sunrise.png";
import SunsetIcon from "../../assets/sunset.png";

const Sunset = ({ sunset, sunrise }) => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  const theme = useTheme();
  //   const boxRef = useRef(null);
  //   const [right, setRight] = useState(null);
  //   const [top, setTop] = useState(null);
  //   useEffect(() => {
  //     const { right, top } = boxRef.current.getBoundingClientRect();
  //     console.log("🚀 ~ file: Sunset.jsx:13 ~ useEffect ~ top:", top);
  //     console.log("🚀 ~ file: Sunset.jsx:13 ~ useEffect ~ right:", right);
  //     setTop(top);
  //     setRight(right);
  //   }, []);

  const time1 = sunrise;
  const time2 = sunset;
  const intervals = 5;

  const date1 = new Date(`2000-01-01T${time1}`);
  const date2 = new Date(`2000-01-01T${time2}`);

  const diffInMilliseconds = date2 - date1;
  const intervalDuration = diffInMilliseconds / intervals;

  const timePoints = [];
  for (let i = 0; i < intervals; i++) {
    const intervalTime = new Date(date1.getTime() + i * intervalDuration);
    const formattedTime = intervalTime.toTimeString().slice(0, 8);
    timePoints.push(formattedTime);
  }
  console.log("🚀 ~ file: Sunset.jsx:31 ~ Sunset ~ timePoints:", timePoints);

  // Get the current date and time
  const now = new Date();

  // Adjust the time to the Persian time zone (UTC+3:30)
  now.setMinutes(now.getMinutes()); // 210 minutes = 3 hours and 30 minutes

  // Extract the time components
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");

  // Format the time as "HH:mm:ss"
  const persianTime = `${hours}:${minutes}:${seconds}`;

  const getSvgSun = () => {
    if (persianTime > timePoints[4]) {
      return (
        <svg
          width="262"
          height="180"
          viewBox="0 0 262 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            opacity="0.5"
            x1="4.37114e-08"
            y1="100.905"
            x2="262"
            y2="100.905"
            stroke="#38385C"
          />
          <path
            d="M32.125 99.9049C32.125 78.5972 42.4819 58.1557 60.9291 43.0796C79.3769 28.0029 104.402 19.5299 130.5 19.5299C156.598 19.5299 181.623 28.0029 200.071 43.0796C218.518 58.1557 228.875 78.5972 228.875 99.9049"
            stroke="#FF7410"
            stroke-width="0.25"
            stroke-dasharray="4 4"
          />
          <path
            d="M73 101.405L32 101.405C32 36.9051 104 13.4052 143.789 20.0986C182.41 24.2438 227.448 50.0536 229 101.405L73 101.405Z"
            fill="url(#paint0_linear_2082_37624)"
          />
          <g opacity="0.5" filter="url(#filter0_f_2082_37624)">
            <circle cx="32" cy="94.4049" r="9" fill="#FF7410" />
          </g>
          <circle cx="31.75" cy="94.1549" r="4.75" fill="#FF7410" />
          <defs>
            <filter
              id="filter0_f_2082_37624"
              x="12"
              y="74.4049"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5.5"
                result="effect1_foregroundBlur_2082_37624"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2082_37624"
              x1="127.915"
              y1="16.2474"
              x2="127.915"
              y2="101.405"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF7410" stop-opacity="0.5" />
              <stop offset="1" stop-color="#FF7410" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else if (persianTime > timePoints[3]) {
      return (
        <svg
          width="262"
          height="180"
          viewBox="0 0 262 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            opacity="0.5"
            x1="4.37114e-08"
            y1="100.5"
            x2="262"
            y2="100.5"
            stroke="#38385C"
          />
          <path
            d="M32.125 99.5C32.125 78.1923 42.4819 57.7508 60.9291 42.6747C79.3769 27.598 104.402 19.125 130.5 19.125C156.598 19.125 181.623 27.598 200.071 42.6747C218.518 57.7508 228.875 78.1923 228.875 99.5"
            stroke="#FF7410"
            stroke-width="0.25"
            stroke-dasharray="4 4"
          />
          <path
            d="M73 101V34.2432C88.4448 25.6846 112.705 16.3575 143.789 19.6936C182.41 23.8388 227.448 49.6486 229 101L73 101Z"
            fill="url(#paint0_linear_2082_37625)"
          />
          <g opacity="0.5" filter="url(#filter0_f_2082_37625)">
            <circle cx="73" cy="35" r="9" fill="#FF7410" />
          </g>
          <circle cx="72.75" cy="34.75" r="4.75" fill="#FF7410" />
          <defs>
            <filter
              id="filter0_f_2082_37625"
              x="53"
              y="15"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5.5"
                result="effect1_foregroundBlur_2082_37625"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2082_37625"
              x1="127.915"
              y1="15.8423"
              x2="127.915"
              y2="101"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF7410" stop-opacity="0.5" />
              <stop offset="1" stop-color="#FF7410" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else if (persianTime > timePoints[2]) {
      return (
        <svg
          width="262"
          height="181"
          viewBox="0 0 262 181"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M131 19.5C217.5 24.5 232.5 88.5 228.836 102H131V19.5Z"
            fill="url(#paint0_linear_2082_37626)"
          />
          <line
            opacity="0.5"
            x1="4.37114e-08"
            y1="101.5"
            x2="262"
            y2="101.5"
            stroke="#38385C"
          />
          <path
            d="M32.125 100.5C32.125 79.1923 42.4819 58.7508 60.9291 43.6747C79.3769 28.598 104.402 20.125 130.5 20.125C156.598 20.125 181.623 28.598 200.071 43.6747C218.518 58.7508 228.875 79.1923 228.875 100.5"
            stroke="#FFEE00"
            stroke-width="0.25"
            stroke-dasharray="4 4"
          />
          <g opacity="0.5" filter="url(#filter0_f_2082_37626)">
            <circle cx="131" cy="20" r="9" fill="#FFAA00" />
          </g>
          <circle cx="130.75" cy="19.75" r="4.75" fill="#FFAA00" />
          <defs>
            <filter
              id="filter0_f_2082_37626"
              x="111"
              y="0"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5.5"
                result="effect1_foregroundBlur_2082_37626"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2082_37626"
              x1="201.5"
              y1="14.2262"
              x2="201.5"
              y2="102"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFAA00" stop-opacity="0.5" />
              <stop offset="1" stop-color="#FFAA00" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else if (persianTime > timePoints[1]) {
      return (
        <svg
          width="262"
          height="180"
          viewBox="0 0 262 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M174 28C220 45.5 230.388 85.2123 228.836 101H174V28Z"
            fill="url(#paint0_linear_2082_37627)"
          />
          <line
            opacity="0.5"
            x1="4.37114e-08"
            y1="100.5"
            x2="262"
            y2="100.5"
            stroke="#38385C"
          />
          <path
            d="M32.125 99.5C32.125 78.1923 42.4819 57.7508 60.9291 42.6747C79.3769 27.598 104.402 19.125 130.5 19.125C156.598 19.125 181.623 27.598 200.071 42.6747C218.518 57.7508 228.875 78.1923 228.875 99.5"
            stroke="#FFEE00"
            stroke-width="0.25"
            stroke-dasharray="4 4"
          />
          <g opacity="0.5" filter="url(#filter0_f_2082_37627)">
            <circle cx="174.231" cy="28" r="9" fill="#FFEE00" />
          </g>
          <circle cx="173.981" cy="27.75" r="4.75" fill="#FFEE00" />
          <defs>
            <filter
              id="filter0_f_2082_37627"
              x="154.231"
              y="8"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5.5"
                result="effect1_foregroundBlur_2082_37627"
              />
            </filter>
            <linearGradient
              id="paint0_linear_2082_37627"
              x1="201.5"
              y1="13.2262"
              x2="201.5"
              y2="101"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FECE2F" stop-opacity="0.5" />
              <stop offset="1" stop-color="#FECE2F" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      );
    } else {
      return (
        <svg
          width="262"
          height="180"
          viewBox="0 0 262 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            opacity="0.5"
            x1="4.37114e-08"
            y1="100.5"
            x2="262"
            y2="100.5"
            stroke="#38385C"
          />
          <path
            d="M32.125 99.5C32.125 78.1923 42.4819 57.7508 60.9291 42.6747C79.3769 27.598 104.402 19.125 130.5 19.125C156.598 19.125 181.623 27.598 200.071 42.6747C218.518 57.7508 228.875 78.1923 228.875 99.5"
            stroke="#FFEE00"
            stroke-width="0.25"
            stroke-dasharray="4 4"
          />
          <g opacity="0.5" filter="url(#filter0_f_2082_37628)">
            <circle cx="229" cy="94" r="9" fill="#FFEE00" />
          </g>
          <circle cx="228.75" cy="93.75" r="4.75" fill="#FFEE00" />
          <defs>
            <filter
              id="filter0_f_2082_37628"
              x="209"
              y="74"
              width="40"
              height="40"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="5.5"
                result="effect1_foregroundBlur_2082_37628"
              />
            </filter>
          </defs>
        </svg>
      );
    }
  };

  console.log("persianTime ====>", persianTime);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box mt="-1rem"> {getSvgSun()}</Box>
      <FlexBetween width={isMobileScreens ? "100%" : "80%"} mt="-5rem">
        <FlexBetween gap="0.25rem">
          <Box component="img" src={SunriseIcon} />
          <Typography
            fontSize={theme.typography.smallestTextPrimary}
            color="#FFEE00"
          >
            طلوع آفتاب
          </Typography>
          <Typography variant="h5" color="#FFEE00">
            04:52{/* {sunrise.split(":").slice(0, 2).join(":")} */}
          </Typography>
        </FlexBetween>
        <FlexBetween gap="0.25rem">
          <Box component="img" src={SunsetIcon} />
          <Typography
            fontSize={theme.typography.smallestTextPrimary}
            color="#FF7410"
          >
            غروب آفتاب
          </Typography>
          <Typography variant="h5" color="#FF7410">
            19:24 {/* {sunset.split(":").slice(0, 2).join(":")} */}
          </Typography>
        </FlexBetween>
      </FlexBetween>
    </Box>
  );
};

export default Sunset;
