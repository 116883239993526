import {
  ArrowUpwardOutlined,
  EditOutlined,
  LocationOnOutlined,
  Refresh,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import RefreshIcon from "../../assets/Refresh.png";
const mockData = [
  {
    name: "آقای احمدی",
    taskType: "داشت",
    doneAction: "آبیاری ناحیه 1",
    doneDate: "1402/02/03",
    doneTime: "20:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "کاشت",
    doneAction: "شخم زدن ناحیه 1 تا 5",
    doneDate: "1402/02/02",
    doneTime: "15:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "سایر",
    doneAction: "تعمیر لوله آب ناحیه 3",
    doneDate: "1402/02/01",
    doneTime: "11:00",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "برداشت",
    doneAction: "هرس درختان",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "برداشت",
    doneAction: "هرس درختان",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "برداشت",
    doneAction: "هرس درختان",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "برداشت",
    doneAction: "هرس درختان",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
  {
    name: "آقای احمدی",
    taskType: "برداشت",
    doneAction: "هرس درختان",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: "پیشتاز الوند",
  },
];
const RecordEventsTable = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [searchValue, setSearchValue] = useState("");
  const [toggleName, setToggleName] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [toggles, setToggles] = useState(
    mockData ? mockData.map(() => false) : false
  );
  const sortedData =
    searchType === "name"
      ? toggleName
        ? mockData.sort((a, b) => a.name.localeCompare(b.name))
        : mockData.sort((a, b) => b.name.localeCompare(a.name))
      : toggleDate
      ? mockData.sort((a, b) => a.doneDate.localeCompare(b.doneDate))
      : mockData.sort((a, b) => b.doneDate.localeCompare(a.doneDate));

  const Data = sortedData.filter((item) =>
    searchValue !== "" ? item.taskType.includes(searchValue) : item
  );
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <FlexBetween
        sx={{
          // display: open ? "inline-flex" : "none",
          transition: "all 0.2s ease",
          ml: "1.5rem",
          alignItems: "center",
          border: `1px solid ${theme.palette.fontColor.alt}`,
          // border: "1px solid black",
          borderRadius: "8px",
          width: "200px",
        }}
      >
        <Search sx={{ mr: "0.2rem" }} />
        <InputBase
          disableUnderline
          placeholder="جست و جو"
          id="inputSearchRemote"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          sx={{
            paddingRight: "5px",
            backgroundColor: "inherit",
            "& .MuiInputBase-input": {
              "::placeholder": {
                color: `${theme.palette.fontColor.main} !important`,
              },
            },
            height: "27px",
            width: "200px",
            borderRadius: "8px",
            color: theme.palette.fontColor.main,
            outline: "none",
            borderBottom: "none",
          }}
        />
      </FlexBetween>
      <Box width="100%" mt="1rem" height="90%">
        <Box display="flex" alignItems="center">
          <Box width="12%" mr="1.75rem">
            <Typography variant="subHeading" color={alt}>
              اسم کارگر
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleName ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleName(!toggleName);
                setSearchType("name");
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </Box>
          <Box
            width="7%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              نوع کار
            </Typography>
          </Box>
          <Box
            width="15%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              اقدامات انجام شده
            </Typography>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              تاریخ انجام کار
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleDate ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleDate(!toggleDate);
                setSearchType("date");
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              ساعت انجام کار
            </Typography>
          </Box>
          <Box
            width="20%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              توضیحات
            </Typography>
          </Box>
          <Box
            width="7%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              تصویر
            </Typography>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              تبدیل به تسک
            </Typography>
          </Box>
          <Box
            width="5%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              ویرایش
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "10px" }} />
        <Box
          sx={{
            width: "100%",
            height: "97%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {Data.map((item, index) => (
            <Box m="1rem 0">
              <Box display="flex" alignItems="center">
                <Box width="12.5%" mr="1.5rem">
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Typography variant="subHeading" color={main}>
                      {item.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width="7%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="subHeading" color={main}>
                    {item.taskType}
                  </Typography>
                </Box>
                <Box
                  width="15%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="subHeading" color={main}>
                    {item.doneAction}
                  </Typography>
                </Box>
                <Box
                  width="10%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="subHeading" color={main}>
                    {item.doneDate}
                  </Typography>
                </Box>
                <Box
                  width="10%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="subHeading" color={main}>
                    {item.doneTime}
                  </Typography>
                </Box>
                <Box
                  width="20%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="subHeading"
                    color={main}
                    textAlign="center"
                  >
                    {item.description}
                  </Typography>
                </Box>
                <Box
                  width="7%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.5">
                      <path
                        d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z"
                        stroke="#C4C3D9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V10"
                        stroke="#C4C3D9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15.75 5H21.25"
                        stroke="#C4C3D9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M18.5 7.75V2.25"
                        stroke="#C4C3D9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M2.66992 18.95L7.59992 15.64C8.38992 15.11 9.52992 15.17 10.2399 15.78L10.5699 16.07C11.3499 16.74 12.6099 16.74 13.3899 16.07L17.5499 12.5C18.3299 11.83 19.5899 11.83 20.3699 12.5L21.9999 13.9"
                        stroke="#C4C3D9"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                  </svg>
                </Box>

                <Box
                  width="10%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box component="img" src={RefreshIcon} />
                </Box>
                <Box
                  width="5%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <EditOutlined
                    sx={{ width: "16px", height: "16px", mr: "0.5rem" }}
                  />
                </Box>
              </Box>
              <Divider sx={{ marginTop: "10px" }} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RecordEventsTable;
