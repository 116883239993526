import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";

import { useRef } from "react";
import { useEffect } from "react";
import CircularProgressBar from "components/circularProgressBar/CircularProgressBar";

import "swiper/css";
import "swiper/css/navigation";

const SpeedometerMob = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to the first box on mount
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [value, setValue] = React.useState(6);
  console.log("🚀 ~ file: Weather.jsx:66 ~ Weather ~ value:", value);

  return (
    <WidgetWrapper
      //   opc={weatherData.enabled ? 1 : 0.1}
      mt="12px"
      sx={{ padding: "0.5rem 0 0.5rem 0" }}
      maxHeight="56px"
    >
      <Box
        display="flex"
        mt="-0.5rem"
        p="0 1rem"
        sx={{
          overflowY: "hidden",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: 0, // Change this to adjust the width of the horizontal scrollbar
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        <Stack direction="row" alignItems="center" gap="0.5rem" height="50px">
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            تعداد کل
          </Typography>
          <CircularProgressBar
            value={100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              1750
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            دوشا پرشیر
          </Typography>
          <CircularProgressBar
            value={(200 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              200
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            دوشا کم شیر
          </Typography>
          <CircularProgressBar
            value={(857 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              857
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="1rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            باردار
          </Typography>
          <CircularProgressBar
            value={(350 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              350
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            دام خشک
          </Typography>
          <CircularProgressBar
            value={(70 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              70
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            تلیسه آبستن
          </Typography>
          <CircularProgressBar
            value={(57 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              57
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 1rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          height="50px"
        >
          <Typography
            variant="smallestTextSecondary"
            color={alt}
            mt="1rem"
            sx={{ whiteSpace: "nowrap" }}
          >
            تلیسه غیرآبستن
          </Typography>
          <CircularProgressBar
            value={(187 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              187
            </Typography>
          </CircularProgressBar>
        </Stack>
      </Box>
    </WidgetWrapper>
  );
};

export default SpeedometerMob;
