import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import ArrowIcon from "../../../assets/arrow.png";
import Full3Icon from "../../../assets/battery/3full.png";
import FullIcon from "../../../assets/battery/battery-full.png";
import EmptyIcon from "../../../assets/battery/battery-empty.png";
import WifiIcon from "../../../assets/wifi/wifi.png";
import Wifi1Icon from "../../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../../assets/wifi/wifi-3.png";
import AirMap from "components/IndividualAirSensors/AirMap";
import AirMapMob from "mob/componentsMob/AirMapMob/AirMapMob";
import AlarmIcon from "../../../assets/AlarmDark.png";
import {
  getDateShamsi,
  getDateShamsiAirSensors,
  getTimeShamsiAirSensors,
} from "Utils/Utils";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import TempAir from "components/IndividualAirSensors/TempAir";
import WindAir from "components/IndividualAirSensors/WindAir";
import RainAir from "components/IndividualAirSensors/RainAir";
import RadiationIntensityAir from "components/IndividualAirSensors/RadiationIntensityAir";
import HumidityAir from "components/IndividualAirSensors/HumidityAir";
import DewPointAir from "components/IndividualAirSensors/DewPointAir";
import PressureAir from "components/IndividualAirSensors/PressureAir";
import WeatherMob from "mob/componentsMob/WeatherMob/WeatherMob";
import WeatherWidgetMob from "mob/componentsMob/WeatherWidgetMob/WeatherWidgetMob";
import AlarmNoSensor from "components/AlarmNoSensor/AlarmNoSensor";
const AirSensorMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const sts = theme.typography.smallestTextSecondary;
  const [filterSensor, setFilterSensor] = useState(1);
  const [filterAlarm, setFilterAlarm] = useState(1);
  useEffect(() => {
    console.log("mmmmmmm");
  }, [filterSensor]);
  console.log(
    "🚀 ~ file: AirSensorMob.jsx:49 ~ AirSensorMob ~ filterSensor:",
    filterSensor
  );
  const data = useSelector((state) => state.sensors.sensorsData.devices);
  const selectedSensorDashboard = useSelector((state) => state.sensors.id);

  const allWeatherSensors = data.filter((item) => item.type === "WEATHER");
  console.log(
    "🚀 ~ file: AirSensorMob.jsx:33 ~ AirSensorMob ~ allWeatherSensors:",
    allWeatherSensors
  );

  var employer = ["wipro", "yohima", "accentur", "capgemine", "infosyss"];
  employer.sort(function (x, y) {
    if (x > y) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  });
  console.log("Here are the employers in descending order", employer);
  const sortedDataTitle = [...allWeatherSensors].sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  console.log(
    "🚀 ~ file: AirSensorMob.jsx:67 ~ sortedDataTitle ~ sortedDataTitle:",
    sortedDataTitle
  );

  const sortedDataBattery = allWeatherSensors.sort((a, b) => {
    const statusOrder = { LOWEST: 0, LOW: 1, HIGH: 2 };
    return statusOrder[a.batteryStatus] - statusOrder[b.batteryStatus];
  });

  const sortedDataStatusInActive = allWeatherSensors.sort((a, b) => {
    const statusOrder = { INACTIVE: 0, ACTIVE: 1 };
    return statusOrder[a.batteryStatus] - statusOrder[b.batteryStatus];
  });

  const sortedDataStatusActive = allWeatherSensors.sort((a, b) => {
    const statusOrder = { ACTIVE: 0, INACTIVE: 1 };
    return statusOrder[a.batteryStatus] - statusOrder[b.batteryStatus];
  });

  const sensors =
    filterSensor === 1
      ? sortedDataTitle
      : filterSensor === 2
      ? sortedDataBattery
      : filterSensor === 3
      ? sortedDataStatusInActive
      : sortedDataStatusActive;
  console.log(
    "🚀 ~ file: AirSensorMob.jsx:100 ~ AirSensorMob ~ sensors:",
    sensors
  );

  const idExists = allWeatherSensors.some(
    (item) => item.id === selectedSensorDashboard
  );
  const sen = useSelector((state) => state.sensors.sensorsData);
  console.log("🚀 ~ file: AirSensorMob.jsx:117 ~ AirSensorMob ~ sen:", sen);
  const alarms = useSelector(
    (state) => state.sensors.sensorsData.alarms.WEATHER
  );
  console.log(
    "🚀 ~ file: AirSensorMob.jsx:47 ~ AirSensorMob ~ alarms:",
    alarms
  );
  const [selectedSensor, setSelectedSensor] = useState(
    idExists && selectedSensorDashboard
      ? selectedSensorDashboard
      : allWeatherSensors[0].id
  );
  const getBatteryStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return EmptyIcon;
      case "LOW":
        return Full3Icon;
      case "HIGH":
        return FullIcon;
      default:
        break;
    }
  };
  const getSignalStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return WifiIcon;
      case "LOW":
        return Wifi1Icon;
      case "HIGH":
        return Wifi3Icon;
      default:
        break;
    }
  };
  useEffect(() => {
    console.log("Sensor Changed!");
  }, [selectedSensor, selectedSensorDashboard]);
  const selectedData = allWeatherSensors.filter(
    (item) => item.id === selectedSensor
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "70vh",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          position: "relative",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {allWeatherSensors.length > 0 ? (
          <>
            <Box>
              <WeatherWidgetMob />
              <WidgetWrapper m="0.5rem 0">
                <FlexBetween>
                  <Typography variant="h4" mb="0.1rem" color={main}>
                    سنسورهای هوا
                  </Typography>
                  <MoreHoriz />
                </FlexBetween>
                <Typography fontSize={theme.typography.subHeading} color={alt}>
                  زمانبندی ارسال داده: 6 ساعته
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mt: "1rem",
                    gap: "0.5rem",
                  }}
                >
                  <Box
                    width="39px"
                    height="32px"
                    borderRadius="8px"
                    textAlign="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        filterSensor === 1
                          ? theme.palette.others.first
                          : theme.palette.background.third,
                      cursor: "pointer",
                    }}
                    onClick={() => setFilterSensor(1)}
                  >
                    <Typography color={main} fontSize={sts}>
                      اسم
                    </Typography>
                  </Box>
                  <Box
                    width="112px"
                    height="32px"
                    borderRadius="8px"
                    textAlign="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        filterSensor === 2
                          ? theme.palette.others.first
                          : theme.palette.background.third,
                      cursor: "pointer",
                    }}
                    onClick={() => setFilterSensor(2)}
                  >
                    <Typography color={main} fontSize={sts}>
                      کمترین میزان باتری
                    </Typography>
                  </Box>
                  <Box
                    width="77px"
                    height="32px"
                    borderRadius="8px"
                    textAlign="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        filterSensor === 3
                          ? theme.palette.others.first
                          : theme.palette.background.third,
                      cursor: "pointer",
                    }}
                    onClick={() => setFilterSensor(3)}
                  >
                    <Typography color={main} fontSize={sts}>
                      غیر فعال ها
                    </Typography>
                  </Box>
                  <Box
                    width="58px"
                    height="32px"
                    borderRadius="8px"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        filterSensor === 4
                          ? theme.palette.others.first
                          : theme.palette.background.third,
                      cursor: "pointer",
                    }}
                    onClick={() => setFilterSensor(4)}
                  >
                    <Typography color={main} fontSize={sts}>
                      فعال ها
                    </Typography>
                  </Box>
                </Box>
                {sensors.map((item, index) => (
                  <Box mt="1rem">
                    <FlexBetween>
                      <Typography>{item.title}</Typography>
                      <IconButton
                      // sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                      // onClick={() => handleToggle(index)}
                      >
                        <Box
                          component="img"
                          src={ArrowIcon}
                          sx={{ rotate: "180deg" }}
                        />
                      </IconButton>
                    </FlexBetween>
                    <Box
                      mt="0.5rem"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: "1rem",
                      }}
                    >
                      <Box gap="0.5rem" sx={{ display: "flex" }}>
                        <Box
                          component={"img"}
                          src={getBatteryStatus(item.batteryStatus)}
                        />
                        <Box
                          component={"img"}
                          src={getSignalStatus(item.signalStatus)}
                        />
                      </Box>
                      <Box
                        width="62px"
                        height="23px"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",

                          borderRadius: "4px",
                          backgroundColor:
                            item.status === "ACTIVE"
                              ? theme.palette.others.third
                              : theme.palette.others.second,
                        }}
                      >
                        <Typography
                          fontSize={theme.typography.smallestTextPrimary}
                          color={theme.palette.background.second}
                        >
                          {item.status === "ACTIVE" ? "فعال" : "غیرفعال"}
                        </Typography>
                      </Box>
                    </Box>
                    {index !== allWeatherSensors.length - 1 ? (
                      <Divider />
                    ) : null}
                  </Box>
                ))}
              </WidgetWrapper>
              <AirMapMob
                data={allWeatherSensors}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
              />
            </Box>
            <WidgetWrapper m="0.5rem 0">
              <FlexBetween>
                <Stack direction="row" gap="0.5rem">
                  <Box component={"img"} src={AlarmIcon} />
                  <Typography>هشدار</Typography>
                </Stack>
                <MoreHoriz />
              </FlexBetween>
              <Box
                sx={{
                  display: "flex",
                  mt: "1rem",
                  gap: "0.5rem",
                }}
              >
                <Box
                  width="73px"
                  height="32px"
                  borderRadius="8px"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      filterAlarm === 1
                        ? theme.palette.others.first
                        : theme.palette.background.third,
                    cursor: "pointer",
                  }}
                  onClick={() => setFilterAlarm(1)}
                >
                  <Typography color={main} fontSize={sts}>
                    تاریخ ارسال
                  </Typography>
                </Box>
                <Box
                  width="64px"
                  height="32px"
                  borderRadius="8px"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      filterAlarm === 2
                        ? theme.palette.others.first
                        : theme.palette.background.third,
                    cursor: "pointer",
                  }}
                  onClick={() => setFilterAlarm(2)}
                >
                  <Typography color={main} fontSize={sts}>
                    بحرانی ها
                  </Typography>
                </Box>
                <Box
                  width="92px"
                  height="32px"
                  borderRadius="8px"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor:
                      filterAlarm === 3
                        ? theme.palette.others.first
                        : theme.palette.background.third,
                    cursor: "pointer",
                  }}
                  onClick={() => setFilterAlarm(3)}
                >
                  <Typography color={main} fontSize={sts}>
                    خیلی بحرانی ها
                  </Typography>
                </Box>
              </Box>
              {alarms
                ? alarms.map((item, index) => (
                    <Box mt="1rem">
                      <FlexBetween>
                        <Typography>{item.deviceName}</Typography>
                        <IconButton
                        // sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                        // onClick={() => handleToggle(index)}
                        >
                          <Box
                            component="img"
                            src={ArrowIcon}
                            sx={{ rotate: "180deg" }}
                          />
                        </IconButton>
                      </FlexBetween>
                      <Box
                        mt="0.5rem"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: "1rem",
                        }}
                      >
                        <Box gap="0.5rem" sx={{ display: "flex" }}>
                          <Typography>
                            {getTimeShamsiAirSensors(item.created)}
                          </Typography>
                          -
                          <Typography>
                            {getDateShamsiAirSensors(item.created)}
                          </Typography>
                        </Box>
                        <Box
                          width="62px"
                          height="23px"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "4px",
                            backgroundColor:
                              item.severity === "MAJOR"
                                ? theme.palette.others.second
                                : theme.palette.others.third,
                          }}
                        >
                          <Typography
                            fontSize={theme.typography.smallestTextPrimary}
                            color={theme.palette.background.second}
                          >
                            {item.typeName}
                          </Typography>
                        </Box>
                      </Box>
                      {index !== alarms.length - 1 ? <Divider /> : null}
                    </Box>
                  ))
                : null}
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <Typography mb="0.5rem">آخرین داده سنسورها</Typography>
              <AirSensorsDoughnut />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <TempAir
                sensorData={
                  selectedData[0].data.temperature
                    ? selectedData[0].data.temperature
                    : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <WindAir
                sensorData={
                  selectedData[0].data ? selectedData[0].data.Wind : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <RainAir
                sensorData={
                  selectedData[0].data.rain ? selectedData[0].data.rain : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <RadiationIntensityAir
                sensorData={
                  selectedData[0].data.radiationIntensity
                    ? selectedData[0].data.radiationIntensity
                    : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <HumidityAir
                sensorData={
                  selectedData[0].data.humidity
                    ? selectedData[0].data.humidity
                    : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0">
              <DewPointAir
                sensorData={
                  selectedData[0].data.devPoint
                    ? selectedData[0].data.devPoint
                    : null
                }
              />
            </WidgetWrapper>
            <WidgetWrapper m="0.5rem 0 4rem 0">
              <PressureAir
                sensorData={
                  selectedData[0].data.pressure
                    ? selectedData[0].data.pressure
                    : null
                }
              />
            </WidgetWrapper>
          </>
        ) : (
          <AlarmNoSensor type={"هوا"} />
        )}
      </Box>
    </Box>
  );
};

export default AirSensorMob;
