import { MoreHoriz, TimerOffOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const ActivityItem = ({ type, name, task, dueTime, time }) => {
  const theme = useTheme();
  return (
    <FlexBetween
      sx={{
        m: "0.5rem 0",
      }}
    >
      <FlexBetween sx={{ alignItems: "flex-start", gap: "0.5rem" }}>
        <Avatar sx={{ width: "24px", height: "24px" }} />
        <Box dispaly="flex" flexDirection="column" justifyContent="flex-start">
          <FlexBetween gap="0.5rem">
            <Typography
              variant="subHeading"
              color={theme.palette.fontColor.main}
            >
              {name}{" "}
            </Typography>
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              {task}
            </Typography>
          </FlexBetween>
          <Box display="flex" alignItems="center" gap="0.3rem">
            <TimerOffOutlined sx={{ width: "10px", height: "10px" }} />
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              {dueTime}
            </Typography>
          </Box>
        </Box>
      </FlexBetween>
      <FlexBetween>
        <Typography
          variant="smallestTextPrimary"
          color={theme.palette.fontColor.alt}
        >
          {time}
        </Typography>

        <IconButton>
          <MoreHoriz sx={{ rotate: "90deg" }} />
        </IconButton>
      </FlexBetween>
    </FlexBetween>
  );
};

export default ActivityItem;
