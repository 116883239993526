import { Box, useTheme } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SmartIndexChart = ({
  yNumbers = false,
  fcolor = "rgba(255,116,16,0.1)",
  borderColor,
}) => {
  const theme = useTheme();
  const data = {
    labels: ["1401/10", "1401/11", "1401/12", "1402/01"],
    datasets: [
      {
        lineTension: 0.4,
        label: "First dataset",
        data: [33, 38, 31, 34],
        fill: true,
        backgroundColor: fcolor,
        borderColor: borderColor ? borderColor : theme.palette.primary.alt,
      },
      {
        lineTension: 0.4,
        label: "Second dataset",
        data: [34, 33, 38, 33],
        fill: true,
        backgroundColor: "rgba(42,128,244,0.1)",
        borderColor: theme.palette.primary.main,
      },
    ],
  };
  return (
    <Box height="100%">
      <Line
        data={data}
        options={{
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: false,
                text: "",
              },
              grid: {
                color: theme.palette.background.second,
                borderDash: [3, 3],
              },
              ticks: {
                display: yNumbers,
              },
            },
            x: {
              title: {
                display: false,
                text: "",
              },
              grid: {
                borderDash: [3, 3],
                color: theme.palette.background.second,
              },
              ticks: {
                display: true,
                color: theme.palette.fontColor.alt,
              },
            },
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],

            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default SmartIndexChart;
