import Pic1 from "../assets/StaffImg/1.jpg";
import Pic2 from "../assets/StaffImg/7.jpg";
import Pic3 from "../assets/StaffImg/8.jpg";
import Pic4 from "../assets/StaffImg/6.jpg";
export const staffData = [
    {
      id: 1,
      name: "علی عباسی",
      pic: Pic3,
      allTasks: 35,
      done: 28,
      score: 4,
      tasks: {
        title: "غذا دادن به گاو ها",
        priority: "فوری - مهم",
        sendDate: "1402/05/06",
        sendTime: "20:30",
        seenDate: "1402/05/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "در حال انجام",
        taskDescription: "",
        comment: "غذای گاو ها داده شد و مشکلی در روند انجام کار نبود",
        backupAction: "-",
        startDate: "10:00 - 1402/05/08",
        endDate: "20:00 - 1402/05/08",
      },
    },
    {
      id: 2,
      name: "حامد صمیمی",
      pic: Pic1,
      allTasks: 29,
      done: 15,
      score: 2,
      tasks: {
        title: "تمیز کردن بهاربندها",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "انجام شده",
        taskDescription: "",
        comment: "مشکلی در روند انجام کار نبود",
        backupAction: "-",
        startDate: "10:00 - 1402/05/08",
        endDate: "20:00 - 1402/05/08",
      },
    },
    {
      id: 3,
      name: "مریم احمدی",
      pic: Pic4,
      allTasks: 19,
      done: 14,
      score: 4,
      tasks: {
        title: "دوشیدن شیر",
        priority: "فوری",
        sendDate: "1402/03/05",
        sendTime: "10:30",
        seenDate: "1402/03/05",
        seenTime: "10:31",
        doTime: "08:00 - 10:00",
        status: "انجام شده",
        taskDescription: "",
        comment: "مشکلی در روند انجام کار نبود",
        backupAction: "ارسال برای آقای علیخانی",
        startDate: "10:00 - 1402/05/08",
        endDate: "20:00 - 1402/05/08",
      },
    },
    {
      id: 4,
      name: "محمد رضا علیخانی",
      pic: Pic2,
      allTasks: 35,
      done: 28,
      score: 4,
      tasks: {
        title: "رسیدگی به کارکنان ",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "در حال انجام",
        taskDescription: "",
        comment: "مشکلی در روند انجام کار نبود",
        backupAction: "-",
        startDate: "10:00 - 1402/05/08",
        endDate: "20:00 - 1402/05/08",
      },
    },
  ];