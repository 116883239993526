import { CacheProvider } from "@emotion/react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import { TextareaAutosize } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const FixedHeightTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  "&::placeholder": {
    color: theme.palette.fontColor.alt,
  },
}));
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});

const CultivationTypeSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const RecordTask = ({ setNewTask, setTasks }) => {
  const theme = useTheme();

  const [priority, setPriority] = useState("");
  const [altAction, setAltAction] = useState("");
  const handleChange = (event) => {
    setPriority(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const t =
      e.target.elements.task.value +
      "/" +
      e.target.elements.farm.value +
      "/" +
      e.target.elements.priority.value +
      "/" +
      e.target.elements.action.value +
      "/" +
      e.target.elements.desc.value;
    const newTask = {
      id: uuidv4(),
      title: t,
      date: "2023-07-12",
      backgroundColor: "#38385C",
      borderColor: "#38385C",
    };
    if (
      e.target.elements.farm.value !== "" &&
      e.target.elements.task.value !== "" &&
      e.target.elements.priority.value !== "" &&
      e.target.elements.action.value !== "" &&
      e.target.elements.desc.value !== ""
    ) {
      setTasks((prev) => prev.concat(newTask));
      setNewTask(false);
    }
  };
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="1376px"
        height="430px"
      >
        <form onSubmit={handleSubmit} validationSchema={CultivationTypeSchema}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              >
                <CssTextField
                  name="farm"
                  label="اسم کارگر"
                  variant="outlined"
                  style={{ width: "250px" }}
                />
                <CssTextField
                  name="task"
                  label="وظیفه"
                  variant="outlined"
                  style={{ width: "250px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: "1rem",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              >
                <Box display="flex" flexDirection="column" gap="1rem">
                  <FormControl
                    sx={{
                      minWidth: 250,
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                      "& .MuiOutlinedInput-input:focused": {
                        borderColor: "green",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {priority === "" ? (
                      <InputLabel
                        disableAnimation
                        shrink={false}
                        focused={false}
                        id="item_type_label"
                      >
                        اولویت
                      </InputLabel>
                    ) : null}

                    <Select
                      name="priority"
                      id="item_type"
                      labelId="item_type_label"
                      value={priority}
                      onChange={handleChange}
                    >
                      <MenuItem value={"خیلی مهم"}>خیلی مهم</MenuItem>
                      <MenuItem value={"مهم"}>مهم</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      minWidth: 250,
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                      "& .MuiOutlinedInput-input:focused": {
                        borderColor: "green",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {altAction === "" ? (
                      <InputLabel
                        disableAnimation
                        shrink={false}
                        focused={false}
                        id="item_type_label"
                      >
                        اقدام جایگزین
                      </InputLabel>
                    ) : null}

                    <Select
                      name="action"
                      id="item_type"
                      labelId="item_type_label"
                      value={altAction}
                      onChange={(e) => setAltAction(e.target.value)}
                    >
                      <MenuItem value={"هوش مزرعه"}>هوش مزرعه</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <FormControl
                  sx={{
                    minWidth: 250,

                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  <FixedHeightTextarea
                    name="desc"
                    placeholder="شرح وظیفه"
                    style={{
                      padding: "5px",
                      color: "white",
                      fontFamily: "YekanBakhFaNum-Regular",
                      fontSize: 12,
                      height: "100%",
                      borderRadius: "8px",
                      backgroundColor: theme.palette.background.first,
                      border: "1px solid white",
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: "1rem",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              ></Box>
            </Box>

            <Box display="flex" gap="1rem" mt="2.5rem">
              <Box
                sx={{
                  width: "250px",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.background.third,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => setNewTask(false)}
              >
                <Typography variant="h4">انصراف</Typography>
              </Box>
              <Button
                type="submit"
                sx={{
                  width: "250px",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,

                  cursor: "pointer",
                  ":hover": {
                    backgroundColor: theme.palette.others.first,
                  },
                }}
              >
                <Typography variant="h4" color="fontColor.main">
                  ثبت
                </Typography>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default RecordTask;
