import { createSlice } from "@reduxjs/toolkit";

    const initialState ={
        mode:"dark",
        isSidebarOpen:"false", 
        user:null,
        token:null,
        refreshToken:null,
        userId:"63701cc1f03239b7f700000e",
        activeFarm:null,
        sensorIndex:0,
        propertyId:null,
        alarmCount:0,
        farmName:"",
        selectedItemMob:1,
        farmNameMob:""
       
    };


    export const globalSlice = createSlice({
        name:"global",
        initialState,
        reducers:{
            setMode:(state)=>{
                    state.mode = state.mode ==="light"?"dark":"light";
            },
            openSidebar:(state)=>{
                state.isSidebarOpen = !state.isSidebarOpen
            },
            setLogin:(state,action)=>{
                state.user = action.payload.user;
                state.token= action.payload.token;
                state.refreshToken= action.payload.refreshToken;
            },
         
            setLogout:(state)=>{
                state.user=null;
                state.token=null;
            },
            setClearLogin:(state,action)=>{
                state.user = null;
                state.token= null;
                state.refreshToken=null;
            },
            setActiveFarm:(state,action)=>{
                state.activeFarm = action.payload.activeFarm;
               
            },
            setSensorIndex:(state,action)=>{
                state.sensorIndex = action.payload.sensorIndex
            },
            setPropertyId:(state,action)=>{
                state.propertyId= action.payload.propertyId
            },
            setAlarmCount:(state,action)=>{
                state.alarmCount=action.payload.alarmCount
            },
            setFarmName:(state,action)=>{
                state.farmName = action.payload.farmName
            },
            setSelectedItemMob:(state,action)=>{
                state.selectedItemMob = action.payload.selectedItemMob
            },
            setFarmNameMob:(state,action)=>{
                state.farmNameMob=action.payload.farmNameMob
            }
        }
    });

    export const {setMode,openSidebar,setLogin,setLogout,setClearLogin,setActiveFarm, setSensorIndex,setPropertyId,setAlarmCount,setFarmName,setSelectedItemMob,setFarmNameMob}= globalSlice.actions;
    export default globalSlice.reducer;