import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import StaffMgmt from "./StaffMgmt";
import StaffMgmtMob from "mob/scenesMob/StaffMgmtMob/StaffMgmtMob";

const StaffMgmtIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <StaffMgmtMob /> : <StaffMgmt />}</>;
};

export default StaffMgmtIndex;
