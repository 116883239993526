import { Box, Stack } from "@mui/material";
import { shamsiToGregorian } from "Utils/Utils";
import WidgetWrapper from "components/WidgetWrapper";
import { SENSOR_MANAGEMENT_DEFAULT_PERIOD, TODAY } from "constants/constant";
import React, { useState } from "react";
import CustomDatePickerCompare from "scenes/Compare/CustomDatePickerCompare";
import CustomSelectBoxCompare from "scenes/Compare/CutomSelecBoxCompare";
import CustomDatePickerCompareMob from "./CustomDatePickerCompareMob";
import CompareChart from "scenes/Compare/CompareChart";
import { CompareData } from "Mock/CompareData";
import CustomSelectBoxCompareType from "scenes/Compare/CustomSelectBoxCompareType";

const CompareMob = () => {
  const [selectedRange, setSelectedRange] = useState([
    new Date(SENSOR_MANAGEMENT_DEFAULT_PERIOD),
    new Date(TODAY),
  ]);
  const handleDateChange = (value) => {
    const gregorian = value?.map((date) =>
      shamsiToGregorian(`${date.year}-${date.month}-${date.day}`)
    );
  };

  const [itemsForSelect, setItemsForSelect] = useState(
    CompareData.map((item) => item.code)
  );
  const [selectItem1, setSelectItem1] = useState(itemsForSelect[0]);

  const [selectItem2, setSelectItem2] = useState(itemsForSelect[1]);
  const [selectItem3, setSelectItem3] = useState(itemsForSelect[2]);
  const [selectItem4, setSelectItem4] = useState(itemsForSelect[3]);

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper width="100%" height="247px" mb="12px">
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CustomSelectBoxCompare
            items={itemsForSelect}
            selectedItem={selectItem1}
            setSelectedItem={setSelectItem1}
            width="88px"
            height="33px"
          />

          <CustomSelectBoxCompareType width="88px" height="33px" />
          <Box width="250px">
            <CustomDatePickerCompareMob
              selectedRange={selectedRange}
              handleDateChange={handleDateChange}
            />
          </Box>
        </Stack>
        <CompareChart
          r={61}
          g={204}
          b={178}
          data={CompareData.filter((item) => item.code === selectItem1)}
        />
      </WidgetWrapper>
      <WidgetWrapper width="100%" height="247px" mb="12px">
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CustomSelectBoxCompare
            items={itemsForSelect}
            selectedItem={selectItem2}
            setSelectedItem={setSelectItem2}
            width="88px"
            height="33px"
          />
          <CustomSelectBoxCompareType width="88px" height="33px" />
          <Box width="250px">
            <CustomDatePickerCompareMob
              selectedRange={selectedRange}
              handleDateChange={handleDateChange}
            />
          </Box>
        </Stack>
        <CompareChart
          color={"#A52FED"}
          r={61}
          g={204}
          b={178}
          data={CompareData.filter((item) => item.code === selectItem2)}
        />
      </WidgetWrapper>
      <WidgetWrapper width="100%" height="247px" mb="12px">
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CustomSelectBoxCompare
            items={itemsForSelect}
            selectedItem={selectItem3}
            setSelectedItem={setSelectItem3}
            width="88px"
            height="33px"
          />
          <CustomSelectBoxCompareType width="88px" height="33px" />
          <Box width="250px">
            <CustomDatePickerCompareMob
              selectedRange={selectedRange}
              handleDateChange={handleDateChange}
            />
          </Box>
        </Stack>
        <CompareChart
          color={"#FF7410"}
          r={61}
          g={204}
          b={178}
          data={CompareData.filter((item) => item.code === selectItem3)}
        />
      </WidgetWrapper>
      <WidgetWrapper width="100%" height="247px" mb="12px">
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <CustomSelectBoxCompare
            items={itemsForSelect}
            selectedItem={selectItem4}
            setSelectedItem={setSelectItem4}
            width="88px"
            height="33px"
          />
          <CustomSelectBoxCompareType width="88px" height="33px" />
          <Box width="250px">
            <CustomDatePickerCompareMob
              selectedRange={selectedRange}
              handleDateChange={handleDateChange}
            />
          </Box>
        </Stack>
        <CompareChart
          color="#3DCCB2"
          r={61}
          g={204}
          b={178}
          data={CompareData.filter((item) => item.code === selectItem4)}
        />
      </WidgetWrapper>
    </Box>
  );
};

export default CompareMob;
