import { Box } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import MeMessenger from "./MeMessenger";
import HeMessenger from "./HeMessenger";

const ContentMessenger = () => {
  const containerRef = useRef();
  const selectedMember = useSelector((state) => state.messenger.selectedMember);
  return (
    <Box
      ref={containerRef}
      width="100%"
      height="100%"
      position={"relative"}
      sx={{
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        "&::-webkit-scrollbar": {
          width: 5,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      {selectedMember?.messages.map((item, index) =>
        item.me ? (
          <Box display="flex" justifyContent="flex-start">
            <MeMessenger
              me
              text={item.text}
              time={item.time}
              containerRef={containerRef}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end">
            <MeMessenger
              text={item.text}
              time={item.time}
              containerRef={containerRef}
            />
          </Box>
        )
      )}
    </Box>
  );
};

export default ContentMessenger;
