import {
  ArrowUpwardOutlined,
  Close,
  EditOutlined,
  FullscreenOutlined,
  LocationCity,
  LocationDisabled,
  LocationOnOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { setSensorIndex } from "States";
import { store } from "States/rootReducer";
import { setId } from "States/sensors";
import DrawMap from "components/RecordFarm/DrawMap/DrawMap";
import FlexBetween from "components/FlexBetween/FlexBetween";
import HTimeline from "components/HTimeline/HTimeline";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useMemo, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import CultivationType from "components/RecordFarm/CultivationType/CultivationType";
import Watering from "components/RecordFarm/Watering/Watering";
import WaterRequirement from "components/RecordFarm/WaterRequirement/WaterRequirement";
import FertilizerPoison from "components/RecordFarm/FertilizerPoison/FertilizerPoison";
import CopyRight from "widgets/CopyRight";
import ArrowIcon from "../../assets/arrow.png";

const StyledPolygon = styled("polygon")({
  fill: "none",
  stroke: "white",
  strokeWidth: "16px",
});

const mockData = [
  {
    farmName: "آقای بهرامی",
    farmType: "ماده",
    number: "1700",
    Coordinates: [51, 30],
    area: "500 هکتار",
    dateCultivation: "1402/02/03",
    productNumber: "پیشتاز الوند",
    points: "100,20 180,50 180,130 100,160 20,130 20,50",
  },
  {
    farmName: "آقای احمدی",
    farmType: "نر",
    number: "1555",
    Coordinates: [51, 32],
    area: "180 هکتار",
    dateCultivation: "1402/02/02",
    productNumber: "سیب قرمز - سیب سبز",
    points: "100,100 150,50 150,150 50,150",
  },
  {
    farmName: "آقای صالحی",
    farmType: "ماده - نر",
    number: "1300",
    Coordinates: [50, 29],
    area: "230 هکتار",
    dateCultivation: "1402/02/01",
    productNumber: "پرتغال تامسون",
    points: "100,10 150,50 150,150 50,150",
  },
];

const RecordFarm = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const mode = useSelector((state) => state.global.mode);
  const idd = useSelector((state) => state.sensors.id);
  console.log("🚀 ~ file: Map.component.jsx:220 ~ MapComponent ~ idd:", idd);
  const d = useSelector((state) => state.dashboard.remote);
  console.log("🚀 ~ file: Map.component.jsx:571 ~ MapComponent ~ d:", d);
  const [mapModal, setMapModal] = useState(false);
  const [flagMap, setFlagMap] = useState(false);
  const [step, setStep] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {}, [mode]);

  const [toggles, setToggles] = useState(
    mockData ? mockData.map(() => false) : false
  );
  const handleToggle = (index) => {
    setToggles((prevToggles) => {
      const newToggles = [...prevToggles];
      newToggles[index] = !newToggles[index];
      return newToggles;
    });
  };
  const [toggle, setToggle] = useState(false);
  const [toggleName, setToggleName] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);
  const [searchType, setSearchType] = useState("");
  const sortedData =
    searchType === "name"
      ? toggleName
        ? mockData.sort((a, b) => a.farmName.localeCompare(b.farmName))
        : mockData.sort((a, b) => b.farmName.localeCompare(a.farmName))
      : toggleDate
      ? mockData.sort((a, b) =>
          a.dateCultivation.localeCompare(b.dateCultivation)
        )
      : mockData.sort((a, b) =>
          b.dateCultivation.localeCompare(a.dateCultivation)
        );

  const Data = sortedData.filter((item) =>
    searchValue !== "" ? item.farmName.includes(searchValue) : item
  );

  return (
    <Box
      width="97.8%"
      height="92vh"
      m="16px 16px 0 16px"
      sx={{
        // backgroundColor: "red",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 15,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper
        width="100%"
        height="77.5%"
        sx={{ backgroundColor: theme.palette.background.second }}
      >
        <Typography variant="h3" color={main} mb="1rem">
          ثبت دامداری
        </Typography>
        {step === 1 && <DrawMap setFlag={setFlagMap} setStep={setStep} />}
        {step === 2 && <CultivationType setStep={setStep} />}
      </WidgetWrapper>
      <WidgetWrapper
        mt="16px"
        width="100%"
        height="367px"
        sx={{ backgroundColor: theme.palette.background.second }}
      >
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            width: "200px",
          }}
        >
          <Search sx={{ mr: "0.2rem" }} />
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
              },
              height: "27px",
              width: "200px",
              borderRadius: "8px",
              color: theme.palette.fontColor.main,
              outline: "none",
              borderBottom: "none",
            }}
          />
        </FlexBetween>
        <Box width="100%" mt="0.5rem">
          <Box display="flex" alignItems="center">
            <Box width="17%" mr="1.75rem">
              <Typography variant="subHeading" color={alt}>
                اسم دامداری
              </Typography>
              <IconButton
                sx={{
                  width: "20px",
                  height: "20px",
                  rotate: toggleName ? "0deg" : "180deg",
                }}
                onClick={() => {
                  setToggleName(!toggleName);
                  setSearchType("name");
                }}
              >
                <ArrowUpwardOutlined
                  sx={{ width: "15px", height: "15px", color: alt }}
                />
              </IconButton>
            </Box>
            <Box width="17%">
              <Typography variant="subHeading" color={alt}>
                نوع دام
              </Typography>
            </Box>
            <Box width="17%">
              <Typography variant="subHeading" color={alt}>
                تعداد
              </Typography>
            </Box>
            <Box width="17%">
              <Typography variant="subHeading" color={alt}>
                مختصات
              </Typography>
            </Box>

            <Box width="10%">
              <Typography variant="subHeading" color={alt}>
                تاریخ ثبت
              </Typography>
              <IconButton
                sx={{
                  width: "20px",
                  height: "20px",
                  rotate: toggleDate ? "0deg" : "180deg",
                }}
                onClick={() => {
                  setToggleDate(!toggleDate);
                  setSearchType("date");
                }}
              >
                <ArrowUpwardOutlined
                  sx={{ width: "15px", height: "15px", color: alt }}
                />
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{ marginTop: "10px" }} />
          {Data.map((item, index) => (
            <Box m="1rem 0">
              <Box display="flex" alignItems="center">
                <Box width="17%" mr="1.5rem">
                  <Box display="flex" alignItems="center" gap="0.5rem">
                    <Box width="16px" height="16px">
                      <svg viewBox="0 0 200 200">
                        <StyledPolygon points={item.points} />
                      </svg>
                    </Box>
                    <Typography variant="subHeading" color={main}>
                      دامداری {item.farmName}
                    </Typography>
                  </Box>
                </Box>
                <Box width="17%" mr="0.5rem">
                  <Typography variant="subHeading" color={main}>
                    {item.farmType}
                  </Typography>
                </Box>
                <Box width="17%">
                  <Typography variant="subHeading" color={main}>
                    {item.number}
                  </Typography>
                </Box>
                <Box width="17%">
                  <Typography variant="subHeading" color={main}>
                    <LocationOnOutlined />
                  </Typography>
                </Box>

                <Box width="15%">
                  <Typography variant="subHeading" color={main}>
                    {item.dateCultivation}
                  </Typography>
                </Box>

                <Box width="5%">
                  <EditOutlined
                    sx={{ width: "16px", height: "16px", mr: "0.5rem" }}
                  />
                </Box>
                <IconButton
                  sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                  onClick={() => handleToggle(index)}
                >
                  <Box
                    component="img"
                    src={ArrowIcon}
                    sx={{ rotate: toggle ? 0 : "180deg" }}
                  />
                </IconButton>
              </Box>
              <Divider sx={{ marginTop: "10px" }} />
            </Box>
          ))}
        </Box>
      </WidgetWrapper>
      <Box sx={{ m: "0 0 2rem 0.2rem" }}>
        <CopyRight />
      </Box>
    </Box>
  );
};

export default RecordFarm;
