import {
  Box,
  IconButton,
  Modal,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import BoxSensorsManagement from "components/BoxSensorsManagement/BoxSensorsManagement";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import FlexBetween from "components/FlexBetween/FlexBetween";

import MixedChart from "components/MixedChart/MixedChart";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonsSensorsManagementAll from "./ButtonsSensorsManagementAll";
import CopyRight from "widgets/CopyRight";
import { getIcon } from "./GetIcon";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import {
  Close,
  DownloadOutlined,
  PriorityHighOutlined,
  ShowChartOutlined,
  SpeedOutlined,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import SensorCards from "./SensorCards";
import { getCenterOfSensorsArray } from "Utils/mapUtils";

const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "absolute",
  width: "100% !important",
  // maxheight: "100%",
  padding: "0px",

  maxHeight: "100%",
  "& .leaflet-left": {
    left: "14px",
  },
  "& .leaflet-bar": {
    border: "none",
  },

  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const SensorsManagementAll = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const { ref: ref1, inView: inView1 } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();

  const Box1Animation = useAnimation();
  const Box2Animation = useAnimation();
  const Box3Animation = useAnimation();
  const Box4Animation = useAnimation();

  const [animationKey, setAnimationKey] = useState(0);
  const token = useSelector((state) => state.global.token);
  const [mapModal, setMapModal] = useState(false);
  const [titleDevice, setTitleDevice] = useState("");
  const lastTimeUpdate = useSelector((state) => state.sensors.lastTimeUpdate);

  const navigate = useNavigate();

  useEffect(() => {
    if (inView1) {
      if (!show) {
        Box1Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,

          transition: {
            type: "spring",
            duration: 1,
            delay: 0.1,
            // bounce: 0.1,
          },
        });
        Box2Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.2,
            // bounce: 0.1,
          },
        });

        setShow(true);
      }
    }
    if (!inView1) {
      if (!show) {
        Box1Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
        Box2Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
      }
    }
    if (inView2) {
      if (!show2) {
        Box3Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.3,
            // bounce: 0.1,
          },
        });
        Box4Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.4,
            // bounce: 0.1,
          },
        });

        setShow2(true);
      }
    }
    if (!inView2) {
      if (!show2) {
        Box3Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
        Box4Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
      }
    }
  }, [token, inView1, inView2]);
  const [typeSelect, setTypeSelect] = useState("WEATHER");
  const [zoneSelect, setZoneSelect] = useState("همه");

  const [cards, setCards] = useState(null);

  const data = useSelector((state) => state.sensors.sensorsData.devices);
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:103 ~ SensorsManagementAll ~ data:",
    data
  );
  const d = useSelector((state) => state.sensors);

  // const order = ["WEATHER", "SOIL", "PLANT", "WATER"];

  // const d = data.sort((a, b) => {
  //   const typeA = order.indexOf(a.type);
  //   const typeB = order.indexOf(b.type);
  //   return typeA - typeB;
  // });

  const flattenedData = data?.reduce((acc, obj) => {
    Object.keys(obj.data).forEach((key) => {
      acc.push({
        key,
        values: obj.data[key],
        type: obj.type,
        title: obj.title,
        id: obj.id,
      });
    });
    return acc;
  }, []);

  const tselect = flattenedData?.slice(0, 4) ? flattenedData.slice(0, 4) : [];

  useEffect(() => {
    if (token === null) {
      navigate("/login");
    } else {
    }
    // let xx = data.map((d) => d.location.x);
    // let x = xx.reduce((a, b) => a + b) / xx.length;
    // let yy = data.map((d) => d.location.y);
    // let y = yy.reduce((a, b) => a + b) / yy.length;
    // setPosition([x, y]);
  }, [data, token]);

  const allWeatherSensors = data?.filter((item) => item.type === "WEATHER");

  const allSoilSensors = data?.filter((item) => item.type === "SOIL");

  const allPlantSensors = data?.filter((item) => item.type === "PLANT");

  const filterData = (zoneSelect) => {
    if (zoneSelect === "همه") {
      if (typeSelect === "WEATHER") return allWeatherSensors;
      if (typeSelect === "PLANT") return allPlantSensors;
      if (typeSelect === "SOIL") return allSoilSensors;
    } else {
      const result = data.filter(
        (item) => item.zoneTitle === zoneSelect && item.type === typeSelect
      );
      return result;
    }
  };

  const selectedZoneSensors = filterData(zoneSelect);

  // const [position, setPosition] = useState(
  //   selectedZoneSensors?.length > 0
  //     ? getCenterOfSensorsArray(selectedZoneSensors)
  //     : [data[0]?.location?.x, data[0]?.location?.y]
  // );
  const position =
    selectedZoneSensors?.length > 0 &&
    getCenterOfSensorsArray(selectedZoneSensors);
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:264 ~ SensorsManagementAll ~ position:",
    position
  );
  useEffect(() => {}, [position]);
  const [toggle, setToggle] = useState(false);

  const theme = useTheme();
  const [selectedIcon, setselectedIcon] = useState(null);
  const mode = useSelector((state) => state.global.mode);

  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
  });

  function MultipleMarkers() {
    const theme = useTheme();

    return selectedZoneSensors?.map((item, index) => {
      console.log(
        "🚀 ~ file: SensorsManagementAll.jsx:146 ~ returnselectedZoneSensors.map ~ item:",
        item
      );
      const title = item.title;
      let { precipitation_snow, ...newObj } = item.data;

      return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <Marker
            eventHandlers={{
              click: (e) => {
                const dataArray = Object.entries(newObj).map(
                  ([key, value]) => ({
                    key,
                    data: value,
                  })
                );
                setTitleDevice(title);
                setselectedIcon(item.id);
                console.log(
                  "🚀 ~ file: SensorsManagementAll.jsx:156 ~ dataArray ~ dataArray:",
                  dataArray
                );
                setAnimationKey(animationKey + 1);
                setCards(dataArray);
              },
            }}
            key={index}
            position={[item.location.x, item.location.y]}
            icon={
              item.data && Object.keys(item.data).length > 0
                ? getIcon(item, selectedIcon)
                : GG1Icon
            }
          ></Marker>
        </motion.div>
      );
    });
  }
  return (
    <>
      {data ? (
        <Box
          position="relative"
          width="99.5%"
          height="83vh"
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <Box
            sx={{
              width: "182px",
              height: "35px",
              background: "rgba(29, 29, 65, 0.7)",
              backdropFilter: "blur(7.5px)",
              position: "absolute",
              top: "15px",
              left: "70px",
              zIndex: "1000",
              borderRadius: "8px",
              direction: "ltr",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontSize="16px">{lastTimeUpdate}</Typography>
          </Box>
          {titleDevice && (
            <Box
              sx={{
                width: "182px",
                height: "35px",
                background: "rgba(29, 29, 65, 0.7)",
                backdropFilter: "blur(7.5px)",
                position: "absolute",
                top: "15px",
                left: "260px",
                zIndex: "1000",
                borderRadius: "8px",
                direction: "ltr",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography fontSize="16px">{titleDevice}</Typography>
            </Box>
          )}
          <FlexBetween
            gap="0.5rem"
            sx={{
              position: "absolute",
              zIndex: "1000",
              top: "15px",
              right: "10px",
            }}
          >
            <CustomSelectBox
              width="157px"
              height="35px"
              type={"type"}
              background={"rgba(29, 29, 65, 0.7)"}
              backdropFilter={"blur(7.5px)"}
              typeSelect={typeSelect}
              setTypeSelect={setTypeSelect}
              zoneSelect={zoneSelect}
              setZoneSelect={setZoneSelect}
              setCards={setCards}
              setTitleDevice={setTitleDevice}
            />
            <CustomSelectBox
              width="157px"
              height="35px"
              type={"area"}
              background={"rgba(29, 29, 65, 0.7)"}
              backdropFilter={"blur(7.5px)"}
              typeSelect={typeSelect}
              setTypeSelect={setTypeSelect}
              zoneSelect={zoneSelect}
              setZoneSelect={setZoneSelect}
              setCards={setCards}
              setTitleDevice={setTitleDevice}
            />
          </FlexBetween>
          <SensorCards cards={cards} toggle={toggle} />
          <ButtonsSensorsManagementAll
            setMapModal={setMapModal}
            toggle={toggle}
            setToggle={setToggle}
            individual={false}
          />

          <Box>
            <Box
              width="99.5%"
              height="616px"
              position="relative"
              display="flex"
              flexDirection="column"
            >
              <Modal
                open={mapModal}
                onClose={() => setMapModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  zIndex: 200000,
                  "& .leaflet-left": {
                    left: "14px",
                  },
                  "& .leaflet-bar": {
                    border: "none",
                  },
                  "& .leaflet-control-zoom-in": {
                    width: "24px !important",
                    height: "24px !important",
                    backgroundColor:
                      mode === "dark"
                        ? "rgba(29, 29, 65, 0.7) !important"
                        : "rgba(250, 250, 252, 0.7) !important",
                    color: theme.palette.fontColor.alt,
                    borderRadius: "8px 8px 0 0 !important",
                  },

                  "& .leaflet-touch .leaflet-bar a": {
                    lineHeight: "24px !important",
                  },
                  "& .leaflet-control-zoom-out": {
                    width: "24px !important",
                    height: "24px !important",
                    backgroundColor:
                      mode === "dark"
                        ? "rgba(29, 29, 65, 0.7 )!important"
                        : "rgba(250, 250, 252, 0.7) !important",
                    color: theme.palette.fontColor.alt,
                    borderRadius: "0 0 8px 8px !important",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",

                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: theme.palette.background.second,
                    "& .leaflet-container": {
                      height: "100% !important",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "120px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <Close
                        onClick={() => setMapModal(false)}
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "230px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton onClick={() => setToggle(!toggle)}>
                      {toggle ? (
                        <ShowChartOutlined
                          sx={{
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      ) : (
                        <SpeedOutlined
                          sx={{
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "170px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <PriorityHighOutlined
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "200px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <DownloadOutlined
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "120px",
                      left: "3.5rem",
                      width: "24px",
                      height: "24px",
                      backgroundColor:
                        mode === "dark"
                          ? "rgba(29, 29, 65, 0.7)"
                          : "rgba(250, 250, 252, 0.7)",
                      backdropFilter: "blur(7.5px)",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <IconButton>
                      <Close
                        onClick={() => setMapModal(false)}
                        sx={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </IconButton>
                  </Box>

                  {position && (
                    <MapContainer
                      center={position}
                      zoom={17}
                      style={{
                        borderRadius: "8px",
                        height: "616px",
                        width: "100%",
                      }}
                    >
                      <ChangeView center={position} zoom={17} />
                      <TileLayer
                        attribution="PTR"
                        url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                        maxZoom={22}
                        maxNativeZoom={18}
                      />
                      <MultipleMarkers />
                    </MapContainer>
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "3rem",
                      zIndex: "1000",
                      width: "99%",
                      height: "163px",
                      p: "0 2rem",
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {cards
                      ? cards.map((item) => (
                          <BoxSensorsManagement
                            type={item.key}
                            data={item.data}
                            color={theme.palette.others.third}
                            toggle={toggle}
                            max={125}
                          />
                        ))
                      : null}
                  </Box>
                </Box>
              </Modal>
              {position && (
                <MapBox mode={mode}>
                  <MapContainer
                    center={position}
                    zoom={17}
                    style={{
                      borderRadius: "8px",
                      height: "616px",
                      width: "100%",
                    }}
                  >
                    <ChangeView center={position} zoom={16} />
                    <TileLayer
                      attribution="PTR"
                      url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                      subdomains={["mt0", "mt1", "mt2", "mt3"]}
                      maxZoom={22}
                      maxNativeZoom={18}
                    />
                    <MultipleMarkers />
                  </MapContainer>
                </MapBox>
              )}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {tselect.length !== null ? (
                <>
                  <Box
                    ref={ref1}
                    sx={{
                      width: "99.5%",
                      // height: "616px",
                      mt: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <motion.div
                      animate={Box1Animation}
                      style={{
                        width: "49.5%",
                        height: "210px",
                        backgroundColor: theme.palette.background.second,
                        borderRadius: "8px",
                        padding: "0.5rem 0.75rem",
                      }}
                    >
                      <MixedChart
                        type={
                          tselect[0] && tselect[0].type ? tselect[0].type : null
                        }
                        device={
                          tselect[0] && tselect[0].id ? tselect[0].id : null
                        }
                        sensor={
                          tselect[0] && tselect[0].key ? tselect[0].key : null
                        }
                      />
                    </motion.div>

                    <motion.div
                      animate={Box2Animation}
                      style={{
                        width: "49.5%",
                        height: "210px",
                        backgroundColor: theme.palette.background.second,
                        borderRadius: "8px",
                        padding: "0.5rem 0.75rem",
                      }}
                    >
                      <MixedChart
                        type={
                          tselect[1] && tselect[1].type ? tselect[1].type : null
                        }
                        device={
                          tselect[1] && tselect[1].id ? tselect[1].id : null
                        }
                        sensor={
                          tselect[1] && tselect[1].key ? tselect[1].key : null
                        }
                      />
                    </motion.div>
                  </Box>
                  <Box
                    ref={ref2}
                    sx={{
                      width: "99.5%",
                      // height: "616px",

                      mt: "1rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <motion.div
                      animate={Box3Animation}
                      style={{
                        width: "49.5%",
                        height: "210px",
                        backgroundColor: theme.palette.background.second,
                        borderRadius: "8px",
                        padding: "0.5rem 0.75rem",
                      }}
                    >
                      <MixedChart
                        type={
                          tselect[2] && tselect[2].type ? tselect[2].type : null
                        }
                        device={
                          tselect[2] && tselect[2].id ? tselect[2].id : null
                        }
                        sensor={
                          tselect[2] && tselect[2].key ? tselect[2].key : null
                        }
                      />
                    </motion.div>
                    <motion.div
                      animate={Box4Animation}
                      style={{
                        width: "49.5%",
                        height: "210px",
                        backgroundColor: theme.palette.background.second,
                        borderRadius: "8px",
                        padding: "0.5rem 0.75rem",
                      }}
                    >
                      <MixedChart
                        type={
                          tselect[3] && tselect[3].type ? tselect[3].type : null
                        }
                        device={
                          tselect[3] && tselect[3].id ? tselect[3].id : null
                        }
                        sensor={
                          tselect[3] && tselect[3].key ? tselect[3].key : null
                        }
                      />
                    </motion.div>
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
          <Box width="92vw">
            <CopyRight />
          </Box>
        </Box>
      ) : (
        <div>صفحه در حال بارگزاری می باشد.</div>
      )}
    </>
  );
};

export default SensorsManagementAll;
