import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import W1 from "../../../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../../../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../../../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../../../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../../../assets/WeatherIcons/static/day.svg";
import W7 from "../../../assets/WeatherIcons/static/rainy-7.svg";
import Water from "../../../assets/water.png";
import ChangeTempChart from "components/ChangeTempChart/ChangeTempChart";
import WidgetWrapper from "components/WidgetWrapper";
import ChangeTempChartMob from "../ChangeTempChartMob/ChangeTempChartMob";

const HourlyWeatherMob = ({ dataHourly, daySelected }) => {
  console.log(
    "🚀 ~ file: HourlyWeather.jsx:15 ~ HourlyWeather ~ dataHourly:",
    dataHourly
  );
  const temperatures = dataHourly.map((item) => item.values.temperature);
  console.log(
    "🚀 ~ file: HourlyWeather.jsx:20 ~ HourlyWeather ~ temperatures:",
    temperatures
  );

  const getIcon = (condition) => {
    switch (condition) {
      case "CLEAR":
        return W1;
      case "ATMOSPHERE":
        return W2;
      case "CLOUDS":
        return W3;
      case "DRIZZLE":
        return W4;
      case "RAIN":
        return W5;
      case "SNOW":
        return W6;
      case "THUNDERSTORM":
        return W7;

      default:
        break;
    }
  };
  const theme = useTheme();
  return (
    <WidgetWrapper
      position={"relative"}
      sx={{
        width: "336px",
        height: "156px",
        p: "0rem 0.5rem",
        mt: "2rem",
        // overflowX: "auto",
        direction: "rtl",
        overflowX: "scroll",
        display: "flex",
        WebkitOverflowScrolling: "touch",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <FlexBetween
        sx={{
          width: "520px",
          height: "100%",
          flexDirection: "row-reverse",
          position: "absolute",
          mr: "-10px",
          zIndex: 200,
          backgroundColor: "rgba(56, 56, 92, 0)",
        }}
      >
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
          }}
        >
          <Typography fontSize={theme.typography.smallestTextPrimary}>
            00:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[0].values.condition)}
            sx={{
              width: "32px",
              height: "32px",
            }}
          />
          <Typography fontSize={"20px"} fontFamily="YekanBakhFaNum-Regular">
            {Math.round(dataHourly[0].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontSize={"14px"} fontFamily="YekanBakhFaNum-Regular">
              {dataHourly[0].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            03:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[1].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontSize={"20px"} fontFamily="YekanBakhFaNum-Regular">
            {Math.round(dataHourly[1].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontSize={"14px"} fontFamily="YekanBakhFaNum-Regular">
              {dataHourly[1].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>

        {/* //// */}
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            backgroundColor: "rgba(29, 29, 65, 0.1)",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            06:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[2].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[2].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[2].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            backgroundColor: "rgba(29, 29, 65, 0.1)",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            9:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[3].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[3].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[3].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            backgroundColor: "rgba(29, 29, 65, 0.1)",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            12:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[4].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[4].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[4].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            backgroundColor: "rgba(29, 29, 65, 0.1)",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            15:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[5].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[5].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[5].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            backgroundColor: "rgba(29, 29, 65, 0.1)",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            18:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[6].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[6].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[6].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>

        {/* /// */}
        <FlexBetween
          sx={{
            minWidth: "65px",

            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
          }}
        >
          <Typography
            fontFamily="YekanBakhFaNum-Regular"
            fontSize={theme.typography.smallestTextPrimary}
          >
            21:00
          </Typography>
          <Box
            component="img"
            src={getIcon(dataHourly[7].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"20px"}>
            {Math.round(dataHourly[7].values.temperature)}°
          </Typography>
          <FlexBetween sx={{ alignItems: "flex-start" }}>
            <Typography fontFamily="YekanBakhFaNum-Regular" fontSize={"14px"}>
              {dataHourly[7].values.precipitation}mm
            </Typography>

            <Box
              component={"img"}
              src={Water}
              sx={{ width: "14px", height: "14px" }}
            />
          </FlexBetween>
        </FlexBetween>
      </FlexBetween>

      {/* /////day-night */}
      <FlexBetween
        sx={{
          width: "520px",
          height: "100%",
          flexDirection: "row-reverse",
          position: "absolute",
          mr: "-10px",
          zIndex: 10,
          backgroundColor: "rgba(56, 56, 92, 0.8)",
        }}
      >
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            cursor: "pointer",
          }}
        ></FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
          }}
        ></FlexBetween>

        {/* //// */}
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            background: "rgba(29, 29, 65, 0.7)",
            // backdropFilter: "blur(7.5px)",
          }}
        ></FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            background: "rgba(29, 29, 65, 0.7)",
            // backdropFilter: "blur(7.5px)",
          }}
        ></FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            background: "rgba(29, 29, 65, 0.7)",
            // backdropFilter: "blur(7.5px)",
          }}
        ></FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            background: "rgba(29, 29, 65, 0.7)",
            // backdropFilter: "blur(7.5px)",
          }}
        ></FlexBetween>
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
            background: "rgba(29, 29, 65, 0.7)",
            backdropFilter: "blur(7.5px)",
          }}
        ></FlexBetween>

        {/* /// */}
        <FlexBetween
          sx={{
            minWidth: "65px",
            height: "100%",
            flexDirection: "column",
            p: "0.75rem 0",
          }}
        ></FlexBetween>
      </FlexBetween>

      <Box
        sx={{
          mr: "-0.5rem",
          width: "520px",
          height: "100%",
          position: "absolute",
          backgroundColor: "transparent",
          overflowY: "hidden",
          top: 0,
          zIndex: 100,
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "120%",
          }}
        >
          <ChangeTempChartMob temperatures={temperatures} />
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default HourlyWeatherMob;
