import { CacheProvider } from "@emotion/react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import { TextareaAutosize } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const FixedHeightTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: "none",
  background: "transparent",
  "&::placeholder": {
    color: theme.palette.fontColor.alt,
  },
}));
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});

const CultivationTypeSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const RecordTaskMob = ({
  setNewTask,
  setTasks,
  selectedTask,
  setSelectedTask,
}) => {
  const theme = useTheme();
  console.log(selectedTask);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState("");
  const [desc, setDesc] = useState("");
  const [altAction, setAltAction] = useState("");
  const handleChange = (event) => {
    setPriority(event.target.value);
  };
  useEffect(() => {
    if (selectedTask) {
      setDesc(selectedTask.taskDescription);
      setName(selectedTask.name);
      setPriority(selectedTask.priority);
      setTitle(selectedTask.title);
      setAltAction(selectedTask.backupAction);
    } else {
      setDesc("");
      setName("");
      setPriority("");
      setTitle("");
      setAltAction("");
    }
  }, [selectedTask]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const t = {
      id: uuidv4(),
      title: e.target.elements.task.value,
      name: e.target.elements.farm.value,
      priority: e.target.elements.priority.value,
      taskDescription: e.target.elements.desc.value,
      backupAction: e.target.elements.action.value,
      status: "انجام نشده",
    };
    console.log(t);
    if (
      e.target.elements.farm.value !== "" &&
      e.target.elements.task.value !== "" &&
      e.target.elements.priority.value !== "" &&
      e.target.elements.action.value !== "" &&
      e.target.elements.desc.value !== ""
    ) {
      if (selectedTask) {
        setTasks((prev) => {
          const tmp = prev.filter((item) => item.id !== selectedTask.id);
          return [...tmp, t];
        });
      } else {
        setTasks((prev) => prev.concat(t));
      }
      setSelectedTask(null);
      setNewTask(false);
      setDesc("");
      setName("");
      setPriority("");
      setTitle("");
      setAltAction("");
    }
  };
  return (
    <CacheProvider value={cacheRtl}>
      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit}
        validationSchema={CultivationTypeSchema}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <CssTextField
            name="farm"
            label="اسم کارگر"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: "100%", margin: "0.5rem 0" }}
          />
          <CssTextField
            name="task"
            label="وظیفه"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: "100%", margin: "0.5rem 0" }}
          />

          <FormControl
            sx={{
              my: "0.5rem",
              width: "100%",
              "& .MuiFormLabel-root": {
                marginLeft: "-4px",
              },
              "& .MuiInputBase-input": {
                height: "5px",
              },
              "& .MuiOutlinedInput-input:focused": {
                borderColor: "green",
              },
              "& label.Mui-focused": {
                color: "#F7F7F7",
              },

              "& .MuiInput-underline:after": {
                borderBottomColor: "#F7F7F7",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root": {
                color: "#F7F7F7 !important",
                borderRadius: "8px",

                "& fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&:hover fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#F7F7F7",
                },
              },
            }}
          >
            {priority === "" ? (
              <InputLabel
                disableAnimation
                shrink={false}
                focused={false}
                id="item_type_label"
              >
                اولویت
              </InputLabel>
            ) : null}

            <Select
              name="priority"
              id="item_type"
              labelId="item_type_label"
              value={priority}
              onChange={handleChange}
            >
              <MenuItem value={"خیلی مهم"}>خیلی مهم</MenuItem>
              <MenuItem value={"مهم"}>مهم</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              my: "0.5rem",
              width: "100%",
              "& .MuiFormLabel-root": {
                marginLeft: "-4px",
              },
              "& .MuiInputBase-input": {
                height: "5px",
              },
              "& .MuiOutlinedInput-input:focused": {
                borderColor: "green",
              },
              "& label.Mui-focused": {
                color: "#F7F7F7",
              },

              "& .MuiInput-underline:after": {
                borderBottomColor: "#F7F7F7",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root": {
                color: "#F7F7F7 !important",
                borderRadius: "8px",

                "& fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&:hover fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#F7F7F7",
                },
              },
            }}
          >
            {altAction === "" ? (
              <InputLabel
                disableAnimation
                shrink={false}
                focused={false}
                id="item_type_label"
              >
                اقدام جایگزین
              </InputLabel>
            ) : null}

            <Select
              name="action"
              id="item_type"
              labelId="item_type_label"
              value={altAction}
              onChange={(e) => setAltAction(e.target.value)}
            >
              <MenuItem value={"هوش مزرعه"}>هوش مزرعه</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              my: "0.5rem",
              width: "100%",

              "& .MuiFormLabel-root": {
                marginLeft: "-4px",
              },
              "& .MuiInputBase-input": {
                height: "5px",
              },
              "& .MuiOutlinedInput-input:focused": {
                borderColor: "green",
              },
              "& label.Mui-focused": {
                color: "#F7F7F7",
              },

              "& .MuiInput-underline:after": {
                borderBottomColor: "#F7F7F7",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root": {
                color: "#F7F7F7 !important",
                borderRadius: "8px",

                "& fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&:hover fieldset": {
                  borderColor: "#F7F7F7",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#F7F7F7",
                },
              },
            }}
          >
            <FixedHeightTextarea
              value={desc}
              onChange={(e) => setDesc(e.target.value)}
              minRows={5}
              name="desc"
              placeholder="شرح وظیفه"
              style={{
                padding: "5px",
                color: "white",
                fontFamily: "YekanBakhFaNum-Regular",
                fontSize: 12,
                height: "100%",
                borderRadius: "8px",
                backgroundColor: theme.palette.background.first,
                border: "1px solid white",
              }}
            />
          </FormControl>

          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            mt="2.5rem"
            width={"100%"}
          >
            <Button
              type="submit"
              sx={{
                width: "100%",
                height: "44px",

                borderRadius: "8px",
                backgroundColor: theme.palette.others.first,

                cursor: "pointer",
                ":hover": {
                  backgroundColor: theme.palette.others.first,
                },
              }}
            >
              <Typography variant="h4" color="fontColor.main">
                ثبت
              </Typography>
            </Button>
            <Box
              sx={{
                width: "100%",
                height: "44px",

                borderRadius: "8px",
                backgroundColor: theme.palette.background.third,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setNewTask(false)}
            >
              <Typography variant="h4">انصراف</Typography>
            </Box>
          </Box>
        </Box>
      </form>
    </CacheProvider>
  );
};

export default RecordTaskMob;
