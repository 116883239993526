import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import TemporaryDrawer from "components/TemporaryDrawer/TemporaryDrawer";
import { useTheme } from "@emotion/react";
import NavbarMob from "mob/NavbarMob/NavbarMob";
import TabbarMob from "mob/TabbarMob/TabbarMob";
const Layout = () => {
  const { palette } = useTheme();
  const mode = useSelector((state) => state.global.mode);
  console.log("🚀 ~ file: Layout.jsx:12 ~ Layout ~ mode", mode);
  const isNonMobile = useMediaQuery("(min-width:600px");
  const isNoneMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <Box
      display={isNonMobile ? "flex" : "block"}
      width="100%"
      height="100%"
      sx={{
        // background:
        //   mode === "dark" ? palette.background.first : "rgb(235,238,242)",
        background:
          mode === "dark"
            ? palette.background.first
            : "linear-gradient(180deg, rgba(235,238,242,1) 31%, rgba(202,220,232,1) 65%)",
      }}
    >
      {isNoneMobileScreens ? (
        <>
          <Box flexGrow={1}>
            <NavbarMob />
            <Outlet />
            <TabbarMob />
          </Box>
        </>
      ) : (
        <>
          <Sidebar isNonMobile={isNonMobile} />

          <Box flexGrow={1} width="calc(100% - 80px)">
            <Navbar />
            <Outlet />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Layout;
