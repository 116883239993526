import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FahlData from "Mock/FahlData";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import CenterStack from "components/styledComponents/CenterStack";
import HStack from "components/styledComponents/HStack";
import React, { useState } from "react";
import ArrowIcon from "../../../assets/arrow.png";
import PointerIcon from "../../../assets/pointer.png";
const FahlMob = () => {
  const theme = useTheme();

  const alt = theme.palette.fontColor.alt;
  const initialToggleStates = new Array(FahlData.length).fill(false);
  const [toggleStates, setToggleStates] = useState(initialToggleStates);
  const toggleItem = (index) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };
  const colors = ["#C3FF79", "#72EB13", "#72EB13", "#FFEE00", "#FC4949"];
  const boxWidth = 107;
  const colorWidth = boxWidth / colors.length;

  const gradientBackground = `linear-gradient(to right, ${colors
    .map(
      (color, index) =>
        `${color} ${colorWidth * index}px, ${color} ${
          colorWidth * (index + 1)
        }px`
    )
    .join(", ")})`;
  return (
    <WidgetWrapper mt="16px">
      <Typography variant="h4" color="fontColor.main" mb="16px">
        فحلی ها
      </Typography>
      <HStack sx={{ gap: "16px" }}>
        <AnimatedProgressBar
          number={(750 / 1750) * 100}
          width={120}
          height={140}
          title="750"
          subTitle="1750"
          color={theme.palette.others.first}
          dot={false}
          secondColor={theme.palette.background.forth}
        />
        <Typography variant="smallestTextPrimary" color="fontColor.alt">
          تعداد فحلی ها
        </Typography>
      </HStack>
      {FahlData.map((item, index) => (
        <React.Fragment key={index}>
          <Box height="53px">
            <FlexBetween>
              <Typography variant="h5" color="fontColor.main">
                دام {item.code}
              </Typography>
              <IconButton onClick={() => toggleItem(index)}>
                <Box
                  component="img"
                  src={ArrowIcon}
                  sx={{
                    rotate: toggleStates[index] ? 0 : "180deg",
                    transition: "all 0.3s ease",
                  }}
                />
              </IconButton>
            </FlexBetween>
            {/* Rest of your item rendering code */}
            <FlexBetween>
              <Typography variant="subHeading" color="fontColor.alt">
                {item.status}
              </Typography>
              <Stack direction="column" sx={{ mt: "0.5rem" }}>
                <Box
                  sx={{
                    width: "160px",
                    height: "6px",
                    backgroundImage: gradientBackground,
                    borderRadius: "30px",
                    position: "relative",
                  }}
                >
                  <Box
                    component="img"
                    src={PointerIcon}
                    position="absolute"
                    sx={{ top: -6, left: (item.fahlTime / 32) * 100 }}
                  />
                </Box>
                <FlexBetween
                  sx={{
                    width: "160px",
                    height: "6px",
                    mt: "0.5rem",
                  }}
                >
                  <Typography variant="extraSmall" color="fontColor.main">
                    32
                  </Typography>
                  <Typography variant="extraSmall" color="fontColor.main">
                    0
                  </Typography>
                </FlexBetween>
              </Stack>
            </FlexBetween>
          </Box>
          <Collapse in={toggleStates[index]} timeout={500}>
            <FlexBetween sx={{ mt: "0.5rem" }}>
              <Typography variant="subHeading" color="fontColor.alt">
                بهترین زمان تلقیح
              </Typography>
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                {item.time} - {item.date}
              </Typography>
            </FlexBetween>
          </Collapse>

          {index !== FahlData.length - 1 && (
            <Divider variant="fullWidth" sx={{ m: "12px 0" }} />
          )}
        </React.Fragment>
      ))}
      <CenterStack sx={{ m: "1.5rem 0", cursor: "pointer" }}>
        <Typography
          variant="h5"
          color={alt}
          sx={{ borderBottom: `1px solid ${alt}` }}
        >
          نمایش بیشتر
        </Typography>
      </CenterStack>
    </WidgetWrapper>
  );
};

export default FahlMob;
