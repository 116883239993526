import { AddOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import DownloadIcon from "../../assets/Icons/download.png";
import DeleteIcon from "../../assets/Icons/delete.png";

import { HeaalthStatusData2 } from "Mock/HealthStatusData2";
import CustomSelectBoxComfort from "scenes/LivestockManagement/CustomSelectBoxComfort";
import {
  AnimalComfortData,
  AnimalComfortHistoryData,
} from "Mock/AnimalComfortData";
import LiveDualLineChart from "scenes/LivestockManagement/LiveDualLineChart";
import CenterStack from "components/styledComponents/CenterStack";
const AnimalComfortMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const items = AnimalComfortData.map((item) => item.title);
  const [selectedItem, setSelectedItem] = useState(items[[0]]);
  const [selectedBaharband, setSelectedBaharband] = useState("همه بهاربندها");
  const [searchValue, setSearchValue] = useState("");
  const filteredDate = AnimalComfortData.filter(
    (item) => item.title === selectedItem
  );
  const filteredHistory = AnimalComfortHistoryData.filter((item) =>
    searchValue !== "" ? item.title.includes(searchValue) : item
  );

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper height="248px">
        <FlexBetween>
          <Typography>وضعیت آسایش دام</Typography>
          <CustomSelectBoxComfort
            width="124px"
            height="33px"
            items={items}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
        </FlexBetween>
        <Stack direction="row" mt="0.5rem">
          <Stack direction="row" gap="16px">
            <FormControlLabel
              value="female"
              control={
                <Radio
                  sx={{
                    width: "12px",
                    height: "12px",
                    "&, &.Mui-checked": {
                      color: "others.sixth",
                    },
                  }}
                  checked={true}
                />
              }
            />
            <Typography variant="smallestTextPrimary" color="fontColor.alt">
              بهاربند انتخاب شده
            </Typography>
          </Stack>
          <Stack direction="row" gap="16px">
            <FormControlLabel
              value="female"
              control={
                <Radio
                  sx={{
                    width: "12px",
                    height: "12px",
                    "&, &.Mui-checked": {
                      color: "others.first",
                    },
                  }}
                  checked={true}
                />
              }
            />
            <Typography variant="smallestTextPrimary" color="fontColor.alt">
              آسایش کل دام
            </Typography>
          </Stack>
        </Stack>
        <LiveDualLineChart
          data1={filteredDate[0]?.baharband}
          data2={filteredDate[0].all}
          color1={theme.palette.others.first}
          colorLine="#7BFD9F"
          color2={theme.palette.primary.third}
          r2={58}
          g2={198}
          b2={173}
          r1={58}
          g1={198}
          b1={173}
        />
      </WidgetWrapper>
      <WidgetWrapper height="257px" mt="12px" sx={{ overflow: "hidden" }}>
        <Typography variant="h5" color="fontColor.main">
          آسایش دام بهاربند ها
        </Typography>
        <Box
          width="100%"
          height="95%"
          display="flex"
          gap="10px"
          sx={{
            overflowX: "auto",
            "&::-webkit-scrollbar": {
              width: 0,
              height: 0,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {AnimalComfortData.map((item, index) => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <AnimatedProgressBar
                number={item.mean}
                width={157}
                height={163}
                title={`%${item.mean}`}
                subTitle=""
                color={
                  item.mean >= 80
                    ? theme.palette.others.third
                    : item.mean < 50
                    ? theme.palette.others.second
                    : theme.palette.others.forth
                }
                dot={false}
                secondColor={theme.palette.background.forth}
              />
              <Typography>{item.title}</Typography>
            </Box>
          ))}
        </Box>
      </WidgetWrapper>
      <WidgetWrapper mt="12px" mb="70px">
        <FlexBetween>
          <Typography variant="h4" color="fontColor.main">
            آسایش بهاربندها
          </Typography>
          <Stack direction="row" gap="16px">
            <IconButton>
              <Box component="img" src={DownloadIcon} />
            </IconButton>
          </Stack>
        </FlexBetween>
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            // ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            m: "0.75rem 0 1rem 0",
          }}
        >
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "27px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
          <Search sx={{ ml: "0.2rem" }} />
        </FlexBetween>
        {filteredHistory.map((item, index) => (
          <>
            <Box
              width="100%"
              height="69px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <Typography variant="body" color="fontColor.main">
                {item.title}
              </Typography>

              <FlexBetween>
                <Typography variant="subHeading" color="fontColor.alt">
                  {item.date}
                </Typography>
                <CenterStack
                  sx={{
                    width: "47px",
                    height: "23px",
                    borderRadius: "4px",

                    backgroundColor:
                      item.comfort <= 50
                        ? theme.palette.others.second
                        : item.comfort > 70
                        ? theme.palette.others.third
                        : theme.palette.others.forth,
                  }}
                >
                  <Typography variant="subHeading" color="background.first">
                    {item.comfort}
                  </Typography>
                </CenterStack>
              </FlexBetween>
            </Box>

            {index !== AnimalComfortHistoryData.length - 1 && <Divider />}
          </>
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default AnimalComfortMob;
