import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ServiceItem = ({ data }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [show, setShow] = useState(false);

  const { ref, inView } = useInView();
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      if (!show) {
        animation.start({
          scale: 1,
          transition: {
            type: "spring",
            duration: 2000,
            bounce: 0.7,
          },
        });

        setShow(true);
      }
    }
    if (!inView) {
      if (!show) {
        animation.start({
          scale: 0.1,
        });
      }
    }
  }, [inView]);
  return (
    <Box ref={ref} width="217px" height="191px" sx={{ p: "16px" }}>
      <FlexBetween>
        <motion.div animate={animation}>{data.icon}</motion.div>
        {data.active && (
          <Box
            width="60px"
            height="23px"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: theme.palette.others.third,
            }}
          >
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.background.third}
            >
              فعال
            </Typography>
          </Box>
        )}
      </FlexBetween>
      <Typography variant="h4" color={main} mt="17px">
        {data.title}
      </Typography>
      <Typography variant="subHeading" color={alt}>
        (3 ماه، 6 ماهه، یک ساله)
      </Typography>
      <Box
        width="186px"
        height="32px"
        mt="18px"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: theme.palette.others.first, cursor: "pointer" }}
      >
        <Typography variant="subHeading" color={main}>
          {" "}
          {data.active ? "تمدید سرویس" : "خرید سرویس"}
        </Typography>
      </Box>
    </Box>
  );
};

export default ServiceItem;
