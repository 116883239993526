import { Button, useMediaQuery } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import DatePicker from "react-multi-date-picker";
import styled from "styled-components";

import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import calendar from "../../assets/calendar.svg";
import calendarLight from "../../assets/calendar-light.svg";
import { useSelector } from "react-redux";

const CustomStyledInput = styled.div`
  ${(props) => `
    position: relative;
    & img {
        position: absolute;
        height: 16px;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    & span {
        position: absolute;
        border-right: 0.5px solid ${props.divider_color};
        height: 19px;
        left: 75px;
        top: 50%;
        transform: translateY(-50%);
    }
    & input {
        cursor: pointer;
        background: transparent;
        width: ${props.isMobile ? "100%" : "216px"};
        zindex: 2000;
        border: 1px solid ${props.border_color};
        border-radius: 8px;
        height: ${props.isMobile ? "40px" : "32px"};
        outline: none;
        text-align: left;
        color: ${props.color};
        padding-left: 12px;
        font-weight: 400;
        font-size: 12px;
        font-family: YekanBakhFaNum-Regular
    }`}
`;
const CustomRangeInput = ({ openCalendar, onChange, value, ...props }) => {
  const mode = useSelector((state) => state.global.mode);
  const from = value[0];
  const to = value[1];
  value = from && to ? to + `   ` + from : from;
  return (
    <CustomStyledInput {...props} onClick={openCalendar}>
      <img
        src={mode === "dark" ? calendar : calendarLight}
        alt="calendar icon"
      />
      {from && to && <span></span>}
      <input
        disabled
        onChange={onChange}
        value={value}
        onClick={openCalendar}
      />
    </CustomStyledInput>
  );
};
const CustomRangeDatePicker = ({
  selectedRange,
  handleDateChange,
  datePickerRef,
}) => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  return (
    <Box
      sx={{
        zIndex: 2000,
        width: "100%",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#ddd",
        },
        "& .rmdp-wrapper": {
          backgroundColor: theme.palette.background.third,
          width: isMobileScreens ? "auto" : "250px",
          borderRadius: "8px",
          overflow: "hidden",
        },
        "& .rmdp-container": {
          width: "100%",
          display: "block",
        },
        "& .rmdp-day": {
          color: theme.palette.fontColor.main,
        },
        "& .rmdp-today span": {
          backgroundColor: theme.palette.background.second,
        },
        "& .rmdp-arrow": {
          borderColor: theme.palette.fontColor.main,
        },
        "& .rmdp-week-day": {
          color: theme.palette.fontColor.alt,
        },
        "& .rmdp-header-values span": {
          color: theme.palette.fontColor.main,
        },
        "& .rmdp-panel-header": {
          color: theme.palette.fontColor.main,
        },
        "& .rmdp-ep-arrow": {
          borderBottom: "none",
          borderTop: "none",
        },
        "& .ep-arrow::after": {
          backgroundColor: theme.palette.background.third,
        },
        "& .rmdp-ep-shadow:after": {
          boxShadow: "none",
        },
        "& .rmdp-month-picker": {
          backgroundColor: theme.palette.background.third,
        },
        "& .rmdp-year-picker": {
          backgroundColor: theme.palette.background.third,
        },
        "& .rmdp-input": {
          color: "white !important",
          fontFamily: "YekanBakhFaNum-Regular",
        },
        "& .rmdp-top-class": {
          backgroundColor: theme.palette.background.third,
          color: "#fff",
        },
        "& .rmdp-day.rmdp-today span": {
          backgroundColor: theme.palette.primary.alt,
        },
        "& .rmdp-range": {
          backgroundColor: "rgba(255, 116, 16, 0.4)",
          boxShadow: "none",
        },
        "& .start": {
          backgroundColor: theme.palette.primary.alt,
        },
        "& .end": {
          backgroundColor: theme.palette.primary.alt,
          "&:before": {
            content: '" "',
            backgroundColor: "rgba(255, 116, 16, 0.4)",
          },
        },
        "& .rmdp-rtl .rmdp-range": {
          "&.end": {
            borderBottomRightRadius: "50%",
            borderTopRightRadius: "50%",
            position: "relative",
            "&::before": {
              content: '" "',
              position: "absolute",
              right: 0,
              top: 0,
              width: "50%",
              height: "100%",
              backgroundColor: "rgba(255, 116, 16, 0.4)",
            },
          },
          "&.start": {
            borderBottomLeftRadius: "50%",
            borderTopLeftRadius: "50%",
            position: "relative",
            "&::before": {
              content: '" "',
              position: "absolute",
              left: 0,
              top: 0,
              width: "50%",
              height: "100%",
              backgroundColor: "rgba(255, 116, 16, 0.4)",
            },
          },
        },
        "& .rmdp-day .sd": {
          color: theme.palette.fontColor.main,
        },
        "& .rmdp-week .rmdp-week-day": {
          color: theme.palette.fontColor.alt,
        },
        "& .rmdp-header-values": {
          color: theme.palette.fontColor.main,
        },
        "& .rmdp-shadow": {
          boxShadow: "none",
        },
        "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
          backgroundColor: theme.palette.primary.alt,
        },
      }}
    >
      <DatePicker
        render={
          <CustomRangeInput
            isMobile={isMobileScreens}
            divider_color={theme.palette.fontColor.alt}
            border_color={theme.palette.fontColor.alt}
            color={theme.palette.fontColor.alt}
          />
        }
        className="bg-dark rmdp-prime"
        calendar={persian}
        locale={persian_fa}
        range
        value={selectedRange}
        onChange={handleDateChange}
        ref={datePickerRef}
        placeholder="تقویم"
        weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
      >
        <Button
          style={{
            margin: "5px",
            backgroundColor: theme.palette.background.third,
            color: theme.palette.fontColor.alt,
          }}
          onClick={() => datePickerRef.current.closeCalendar()}
        >
          بستن
        </Button>
      </DatePicker>
    </Box>
  );
};

export default CustomRangeDatePicker;
