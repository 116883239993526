import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import PowerIcon from "../../../assets/Actuator/Power.png";
import Edit from "../../../assets/icon/edit-actuator.svg";
const ActuatorItemMob = ({
  icon,
  title,
  zone,
  status,
  selected,
  index,
  setSelected,
}) => {
  const theme = useTheme();
  const [togglePower, setTogglePower] = useState(status);
  return (
    <Box
      sx={{
        width: "100%",
        paddingBottom: "0.2rem",
        marginBottom: "0.2rem",
        "&:not(:last-child)": {
          borderBottom: `1px solid ${theme.palette.background.third}`,
        },
      }}
    >
      <Box
        width="100%"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p="0.75rem"
        backgroundColor={
          selected ? theme.palette.background.third : "transparent"
        }
        sx={{
          cursor: selected ? "auto" : "pointer",
        }}
        onClick={() => setSelected(index)}
      >
        <Box display="flex" alignItems="center" gap="0.5rem">
          <Box width="24px" height="24px" component="img" src={icon} />
          <Box display="flex" alignItems="center" gap="0.5rem">
            <Typography variant="h5" color={theme.palette.fontColor.main}>
              {title}
            </Typography>
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              {zone}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ position: "relative", width: "25%" }}
        >
          <IconButton sx={{ position: "absolute", right: 0, zIndex: 2 }}>
            <Box component="img" src={Edit} />
          </IconButton>
          <Box
            width="24px"
            height="24px"
            backgroundColor={
              togglePower
                ? theme.palette.others.third
                : theme.palette.background.forth
            }
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer", position: "absolute", left: 0 }}
            onClick={() => setTogglePower(!togglePower)}
          >
            <Box component="img" src={PowerIcon} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActuatorItemMob;
