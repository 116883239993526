import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import MapComponent from "components/Map/Map.component";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 13,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 13,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Map = () => {
  const mapData = useSelector((state) => state.dashboard.remote);
  console.log("🚀 ~ file: Map.jsx:69 ~ Map ~ mapData:", mapData);
  const [check, setCheck] = useState(true);
  const navigate = useNavigate();
  return (
    <WidgetWrapper opc={mapData.enabled ? 1 : 0.1} sx={{ height: "100%" }}>
      <FlexBetween mb="0.5rem">
        <Typography variant="h5">سنجش از دور</Typography>
        <FlexBetween>
          <Typography variant="body" ml="0.5rem">
            سنسورها
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                defaultChecked={check}
                onChange={() => setCheck(!check)}
              />
            }
            sx={{ marginLeft: "0.5rem" }}
          />
          <MoreHoriz
            onClick={() => navigate("/remotesensing")}
            sx={{ ":hover": { cursor: "pointer" } }}
          />
        </FlexBetween>
      </FlexBetween>
      <Box width="100%" height="95%" position="relative" overflow="hidden">
        <MapComponent check={check} />
      </Box>
    </WidgetWrapper>
  );
};

export default Map;
