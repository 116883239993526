import { MoreHoriz, TimerOffOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import DeleteDark from "../../../assets/icon/delete-dark.svg";
import EditDark from "../../../assets/icon/edit-popover-dark.svg";
import DeleteLight from "../../../assets/icon/delete-light.svg";
import EditLight from "../../../assets/icon/edit-popover-light.svg";

const ActivityItemMob = ({ task, dueTime, time }) => {
  const mode = useSelector((state) => state.global.mode);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? task : undefined;
  const editIcon = mode === "dark" ? EditDark : EditLight;
  const deletIcon = mode === "dark" ? DeleteDark : DeleteLight;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingBottom: "0.5rem",
        m: "0.5rem 0",
        "&:not(:last-child)": {
          borderBottom: `1px solid ${theme.palette.background.third}`,
        },
      }}
    >
      <FlexBetween sx={{ alignItems: "space-between" }}>
        <Box sx={{ display: "flex", gap: "0.5rem" }}>
          <Avatar sx={{ width: "24px", height: "24px" }} />
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.alt}
          >
            {task}
          </Typography>
        </Box>
        <IconButton aria-describedby={id} onClick={handleClick}>
          <MoreHoriz sx={{ rotate: "90deg" }} />
        </IconButton>
        <Popover
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.third,
              boxShadow: "none",
              borderRadius: "8px",
            },
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box display="flex" flexDirection="column" py="0.3rem">
            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{
                minHeight: "auto",
                borderRadius: "8px",
                mx: "3px",
                display: "flex",
                gap: "0.3rem",
                px: "10px",
              }}
            >
              <img alt="edit" src={editIcon} />
              <Typography
                variant="smallestTextPrimary"
                color={theme.palette.fontColor.alt}
              >
                ویرایش
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setAnchorEl(null)}
              sx={{
                minHeight: "auto",
                borderRadius: "8px",
                mx: "3px",
                display: "flex",
                gap: "0.3rem",
                px: "10px",
              }}
            >
              <img alt="edit" src={deletIcon} />
              <Typography
                variant="smallestTextPrimary"
                color={theme.palette.fontColor.alt}
              >
                حذف
              </Typography>
            </MenuItem>
          </Box>
        </Popover>
      </FlexBetween>
      <FlexBetween alignItems="center">
        <Box display="flex" alignItems="center" gap="0.3rem">
          <TimerOffOutlined sx={{ width: "10px", height: "10px" }} />
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.alt}
          >
            {dueTime}
          </Typography>
        </Box>
        <Typography
          variant="smallestTextPrimary"
          color={theme.palette.fontColor.alt}
        >
          {time}
        </Typography>
      </FlexBetween>
    </Box>
  );
};

export default ActivityItemMob;
