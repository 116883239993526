import { MoreHoriz, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

import AlertIcon from "../../assets/AlarmDark.png";
import { useSelector } from "react-redux";
import { getDateShamsiAirSensors, getTimeShamsiAirSensors } from "Utils/Utils";
const SoilAlertsList = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const alarms = useSelector(
    (state) => state.sensors.sensorsData.alarms.SOIL
  );
  console.log(
    "🚀 ~ file: SoilAlertsList.jsx:24 ~ SoilAlertsList ~ alarms:",
    alarms
  );
  return (
    <Box>
      <FlexBetween>
        <FlexBetween gap="0.2rem">
          <Box component="img" src={AlertIcon} width="21px" height="21px" />
          <Typography variant="h5" mb="0.1rem" color={main}>
            هشدار
          </Typography>
        </FlexBetween>

        <MoreHoriz sx={{ cursor: "pointer" }} />
      </FlexBetween>

      <Box style={{ height: 160, width: "100%" }}>
        <FlexBetween mt="0.5rem">
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            سنسور
          </Typography>
          <FlexBetween gap="2rem" sx={{ width: "65%" }}>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              تاریخ ارسال
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              هشدار
            </Typography>

            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              توضیحات
            </Typography>
          </FlexBetween>
        </FlexBetween>
        <Divider sx={{ marginTop: "10px" }} />
        <Box
          sx={{
            height: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {alarms
            ? alarms.map((item, index) => (
                <FlexBetween
                  sx={{
                    m: "0.4rem 0",
                    borderRadius: "8px",
                    pr: "0.2rem",
                    "&:hover": {
                      backgroundColor: theme.palette.background.third,
                    },
                  }}
                >
                  <Typography
                    color={main}
                    fontSize={theme.typography.smallestTextPrimary}
                  >
                    {item.deviceName}
                  </Typography>
                  <FlexBetween gap="2rem" sx={{ width: "65%" }}>
                    <FlexBetween
                      flexDirection="column"
                      sx={{ alignItems: "flex-start" }}
                    >
                      <Typography
                        color={alt}
                        fontSize={theme.typography.smallestTextPrimary}
                        sx={{ direction: "ltr" }}
                      >
                        {getDateShamsiAirSensors(item.created)}
                      </Typography>
                      <Typography
                        color={alt}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {getTimeShamsiAirSensors(item.created)}
                      </Typography>
                    </FlexBetween>

                    <Box
                      width="88px"
                      height="23px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: theme.palette.others.forth,
                        borderRadius: "4px",
                      }}
                    >
                      <Typography
                        color={"#141332"}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.typeName}
                      </Typography>
                    </Box>

                    <IconButton>
                      <MoreHoriz />
                    </IconButton>
                  </FlexBetween>
                </FlexBetween>
              ))
            : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SoilAlertsList;
