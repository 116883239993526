import { Box, MenuItem, Popover, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useState } from "react";
import DeleteDark from "assets/icon/delete-dark.svg";
import EditDark from "assets/icon/edit-popover-dark.svg";
import DeleteLight from "assets/icon/delete-light.svg";
import EditLight from "assets/icon/edit-popover-light.svg";
import { useSelector } from "react-redux";

const TicketItemMob = ({ item, setSelectedTicket, handleClickEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [closeTag, setCloseTag] = useState(false);
  const mode = useSelector((state) => state.global.mode);
  const theme = useTheme();
  const dividerColor = theme.palette.background.forth;
  const badgeCount = item.messages.filter(
    (item) => !item.me && !item.seen
  ).length;
  let triggerTime;
  const handleOnTouchStart = (event) => {
    triggerTime = new Date().getTime();
  };
  const handleOnTouchEnd = (event) => {
    const now = new Date().getTime();
    const diff = now - triggerTime;
    if (diff > 500) {
      setAnchorEl(event.currentTarget);
      setCloseTag(true);
    }
  };
  const handleClick = (id) => {
    const now = new Date().getTime();
    const diff = now - triggerTime;
    if (diff < 500) {
      setSelectedTicket(id);
    }
  };
  const handleClose = () => {
    if (closeTag) {
      setAnchorEl(null);
      setCloseTag(false);
    }
  };

  const editIcon = mode === "dark" ? EditDark : EditLight;
  const deletIcon = mode === "dark" ? DeleteDark : DeleteLight;

  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        aria-describedby={item.id}
        onClick={() => handleClick(item.id)}
        onTouchStart={handleOnTouchStart}
        onTouchEnd={handleOnTouchEnd}
        p="20px 0 13px"
        sx={{
          "&:not(:last-child)": {
            borderBottom: `1px solid ${dividerColor}`,
          },
        }}
      >
        <FlexBetween mb="8px">
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography variant="body">{item.title}</Typography>
            {!!badgeCount && (
              <Box
                sx={{
                  width: "16px",
                  height: "16px",
                  borderRadius: "50%",
                  color: "#F7F7F7",
                  bgcolor: theme.palette.others.second,
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {badgeCount}
              </Box>
            )}
          </Box>
          <Typography variant="subHeading" color="fontColor.alt">
            {item.subject}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="subHeading" color="fontColor.alt">
            {item.priority === "middle"
              ? "اولویت متوسط"
              : item.priority === "high"
              ? "اولویت بالا"
              : "اولویت پایین"}
          </Typography>
          <Box
            sx={{
              p: "2px 4px",
              bgcolor:
                item.status === "pending"
                  ? theme.palette.others.first
                  : theme.palette.others.third,
              color: theme.palette.background.second,
              borderRadius: "4px",
            }}
          >
            <Typography variant="smallestTextPrimary">
              {item.status === "pending" ? "در انتظار بررسی" : "پاسخ داده شده"}
            </Typography>
          </Box>
        </FlexBetween>
      </Box>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.third,
            boxShadow: "none",
            borderRadius: "8px",
          },
        }}
        id={item.id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column" py="0.3rem">
          <MenuItem
            onClick={() => {
              handleClickEdit(item);
              setAnchorEl(null);
            }}
            sx={{
              minHeight: "auto",
              borderRadius: "8px",
              mx: "3px",
              display: "flex",
              gap: "0.3rem",
              px: "10px",
            }}
          >
            <img alt="edit" src={editIcon} />
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              ویرایش
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => setAnchorEl(null)}
            sx={{
              minHeight: "auto",
              borderRadius: "8px",
              mx: "3px",
              display: "flex",
              gap: "0.3rem",
              px: "10px",
            }}
          >
            <img alt="edit" src={deletIcon} />
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.alt}
            >
              حذف
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default TicketItemMob;
