import { Box, useMediaQuery } from "@mui/material";
import ReportMob from "mob/ReportMob/ReportMob";
import React from "react";
import Report from "./Report";

const ReportIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <Box>{isMobileScreens ? <ReportMob /> : <Report />}</Box>;
};

export default ReportIndex;
