import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useRef, useState } from "react";
import CloudyDD from "../../../assets/icon/DarkWeather/cloudy.png";
import ActuatorItemMob from "./ActuatorItemMob";
import {
  DownloadOutlined,
  FilterAltOutlined,
  FullscreenOutlined,
  Search,
} from "@mui/icons-material";
import WetIcon from "../../../assets/Actuator/Wet.png";
import PlantIcon from "../../../assets/Actuator/plant.png";
import StIcon from "../../../assets/Actuator/st.png";
import PowerIcon from "../../../assets/Actuator/Power.png";
import ActuatorMapMob from "./ActuatorMapMob";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import ActuatorSelectBox from "scenes/Actuator/ActuatorSelectBox";
import { useSelector } from "react-redux";
import calendar from "../../../assets/calendar.svg";
import calendarLight from "../../../assets/calendar-light.svg";
import DatePicker from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import ActuatorChart from "scenes/Actuator/ActuatorChart";
import { REMOTE_SENSING_DEFAULT_PERIOD, TODAY } from "constants/constant";
import { CustomInputBase } from "components/styledComponents/common";
import ActivityItemMob from "./ActivityItemMob";
const MockData = [
  {
    type: "human",
    name: "آقای محمدی",
    task: "روشن کردن روشنایی بهاربند 3",
    dueTime: "3 ساعت دیگر",
    time: "15:00 - 16:00",
  },
  {
    type: "machine",
    name: "هوش مزرعه",
    task: "روشن کردن تهویه بهاربند 1",
    dueTime: "5 ساعت دیگر",
    time: "10:00 - 11:20",
  },
  {
    type: "human",
    name: "آقای رضایی",
    task: "روشن کردن مه پاش بهاربند 3",
    dueTime: "1 ساعت دیگر",
    time: "08:00 - 09:00",
  },
];
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
}));
const ActuatorMob = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const datePickerRef = useRef();
  const [selectedRange, setSelectedRange] = useState([
    new Date(REMOTE_SENSING_DEFAULT_PERIOD),
    new Date(TODAY),
  ]);
  const [searchValue, setSearchValue] = useState("");
  const [actionSearchValue, setActionSearchValue] = useState("");
  const [selected, setSelected] = useState(null);
  const [togglePower, setTogglePower] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("همه");
  const handleDateChange = (value) => {
    setSelectedRange(value);
  };
  return (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper m="0.75rem">
        <FlexBetween>
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.main}
          >
            امروز
          </Typography>
          <Typography
            variant="smallestTextSecondary"
            color={theme.palette.fontColor.alt}
          >
            1401/10/20
          </Typography>
        </FlexBetween>
        <FlexBetween mt="1.5rem">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="Center"
            justifyContent="center"
          >
            <Typography variant="h1" color={theme.palette.fontColor.main}>
              13<sup>°</sup>
            </Typography>
            <Stack direction="row">
              <Typography variant="body" color={theme.palette.fontColor.main}>
                20°
              </Typography>
              |
              <Typography variant="body" color={theme.palette.fontColor.alt}>
                16°
              </Typography>
            </Stack>
          </Box>
          <Box component="img" src={CloudyDD} width="60px" height="60px" />
        </FlexBetween>
        <FlexBetween mt="1rem">
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="smallestTextSecondary"
              color={theme.palette.fontColor.alt}
            >
              باد
            </Typography>
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.main}
            >
              8km/h
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="smallestTextSecondary"
              color={theme.palette.fontColor.alt}
            >
              رطوبت
            </Typography>
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.main}
            >
              15%
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="smallestTextSecondary"
              color={theme.palette.fontColor.alt}
            >
              بارندگی
            </Typography>
            <Typography
              variant="smallestTextPrimary"
              color={theme.palette.fontColor.main}
            >
              6mm
            </Typography>
          </Box>
        </FlexBetween>
      </WidgetWrapper>
      <WidgetWrapper mx="0.75rem" mb="0.75rem">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">عملگرها</Typography>
          <FlexBetween
            sx={{
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              width: "35%",
            }}
          >
            <Search sx={{ mr: "0.2rem" }} />
            <CustomInputBase
              placeholder="جست و جو"
              id="inputSearchRemote"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </FlexBetween>
        </Box>
        <Box
          sx={{
            mt: "1rem",
          }}
        >
          <ActuatorItemMob
            icon={WetIcon}
            title={"روشنایی"}
            zone={"بهاربند 1"}
            selected={selected === 1 ? true : false}
            setSelected={setSelected}
            index={1}
            status={true}
          />
          <ActuatorItemMob
            icon={PlantIcon}
            title={"تهویه"}
            zone={"بهاربند 2"}
            selected={selected === 2 ? true : false}
            status={true}
            index={2}
            setSelected={setSelected}
          />

          <ActuatorItemMob
            icon={StIcon}
            title={"مه پاش"}
            zone={"بهاربند 3"}
            status={false}
            selected={selected === 3 ? true : false}
            index={3}
            setSelected={setSelected}
          />
          <ActuatorItemMob
            icon={PlantIcon}
            title={"تهویه"}
            zone={"بهاربند 4"}
            status={false}
            selected={selected === 4 ? true : false}
            index={4}
            setSelected={setSelected}
          />
        </Box>
      </WidgetWrapper>
      <ActuatorMapMob />
      <WidgetWrapper mx="0.75rem" mb="0.75rem">
        <FlexBetween>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
          >
            <Typography variant="h5">تهویه بهاربند 1</Typography>
            <Box
              sx={{
                width: "94px",
                height: "25px",
                backgroundColor: theme.palette.others.first,
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="smallestTextPrimary"
                color={theme.palette.fontColor.alt}
              >
                گزارش عملکرد
              </Typography>
            </Box>
          </Box>
          <Box
            width="24px"
            height="24px"
            backgroundColor={
              togglePower
                ? theme.palette.others.third
                : theme.palette.background.third
            }
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ cursor: "pointer" }}
            onClick={() => setTogglePower(!togglePower)}
          >
            <Box component="img" src={PowerIcon} />
          </Box>
        </FlexBetween>
        <Box
          width="100%"
          height="96%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="12px"
          flexDirection="column"
        >
          <Box my="12px">
            <AnimatedProgressBar
              number={75}
              width={157}
              height={163}
              title="2:30:50"
              subTitle="3:00:00"
              color={theme.palette.others.first}
              dot={true}
            />
          </Box>
          <Box
            display="flex"
            // flexDirection="column"
            width="100%"
            m="1rem"
            gap="12px"
            justifyContent="space-around"
          >
            <FlexBetween
              sx={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <Box width="16px" height="16px">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6663 7.99998C14.6663 11.68 11.6797 14.6666 7.99967 14.6666C4.31967 14.6666 1.33301 11.68 1.33301 7.99998C1.33301 4.31998 4.31967 1.33331 7.99967 1.33331C11.6797 1.33331 14.6663 4.31998 14.6663 7.99998Z"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4729 10.12L8.40626 8.88665C8.04626 8.67332 7.75293 8.15999 7.75293 7.73999V5.00665"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.alt}
                  whiteSpace="nowrap"
                >
                  ساعت شروع
                </Typography>
                <Typography
                  variant="subHeading"
                  color={theme.palette.fontColor.main}
                >
                  15:00
                </Typography>
              </Box>
            </FlexBetween>

            <FlexBetween
              sx={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <Box width="16px" height="16px">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.1875 8C14.1875 11.1395 11.6395 13.6875 8.5 13.6875C5.3605 13.6875 3.44381 10.5253 3.44381 10.5253M3.44381 10.5253H6.01456M3.44381 10.5253V13.369M2.8125 8C2.8125 4.8605 5.33775 2.3125 8.5 2.3125C12.2936 2.3125 14.1875 5.47475 14.1875 5.47475M14.1875 5.47475V2.631M14.1875 5.47475H11.6623"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.alt}
                  whiteSpace="nowrap"
                >
                  بازه زمانی تکرار
                </Typography>
                <Typography
                  variant="subHeading"
                  color={theme.palette.fontColor.main}
                >
                  هر روز
                </Typography>
              </Box>
            </FlexBetween>
            <FlexBetween
              sx={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <Box width="16px" height="16px">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00033 14.6667C4.78033 14.6667 2.16699 12.0533 2.16699 8.83333C2.16699 5.61333 4.78033 3 8.00033 3C11.2203 3 13.8337 5.61333 13.8337 8.83333"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 5.33331V8.66665"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6 1.33331H10"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.667 11.3333V14"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.667 11.3333V14"
                    stroke="#2A80F4"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.alt}
                  whiteSpace="nowrap"
                >
                  مدت زمان تهویه
                </Typography>
                <Typography
                  variant="subHeading"
                  color={theme.palette.fontColor.main}
                >
                  3 ساعت
                </Typography>
              </Box>
            </FlexBetween>
          </Box>
        </Box>
      </WidgetWrapper>
      <WidgetWrapper mx="0.75rem" mb="0.75rem">
        <FlexBetween>
          <ActuatorSelectBox />
          <FlexBetween>
            <Box
              sx={{
                width: "24px",
                height: "24px",
                backgroundColor:
                  mode === "dark"
                    ? "rgba(29, 29, 65, 0.7)"
                    : "rgba(250, 250, 252, 0.7)",
                backdropFilter: "blur(7.5px)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2000,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#ddd",
                },
                "& .rmdp-wrapper": {
                  backgroundColor: theme.palette.background.third,
                  width: "auto",
                  borderRadius: "8px",
                  overflow: "hidden",
                },
                "& .rmdp-container": {
                  width: "100%",
                  display: "block",
                },
                "& .rmdp-day": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-today span": {
                  backgroundColor: theme.palette.background.second,
                },
                "& .rmdp-arrow": {
                  borderColor: theme.palette.fontColor.main,
                },
                "& .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values span": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-panel-header": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-ep-arrow": {
                  borderBottom: "none",
                  borderTop: "none",
                },
                "& .ep-arrow::after": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-ep-shadow:after": {
                  boxShadow: "none",
                },
                "& .rmdp-month-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-year-picker": {
                  backgroundColor: theme.palette.background.third,
                },
                "& .rmdp-input": {
                  color: "white !important",
                  fontFamily: "YekanBakhFaNum-Regular",
                },
                "& .rmdp-top-class": {
                  backgroundColor: theme.palette.background.third,
                  color: "#fff",
                },
                "& .rmdp-day.rmdp-today span": {
                  backgroundColor: theme.palette.primary.alt,
                },
                "& .rmdp-range": {
                  backgroundColor: "rgba(255, 116, 16, 0.4)",
                  boxShadow: "none",
                },
                "& .start": {
                  backgroundColor: theme.palette.primary.alt,
                },
                "& .end": {
                  backgroundColor: theme.palette.primary.alt,
                  "&:before": {
                    content: '" "',
                    backgroundColor: "rgba(255, 116, 16, 0.4)",
                  },
                },
                "& .rmdp-rtl .rmdp-range": {
                  "&.end": {
                    borderBottomRightRadius: "50%",
                    borderTopRightRadius: "50%",
                    position: "relative",
                    "&::before": {
                      content: '" "',
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "50%",
                      height: "100%",
                      backgroundColor: "rgba(255, 116, 16, 0.4)",
                    },
                  },
                  "&.start": {
                    borderBottomLeftRadius: "50%",
                    borderTopLeftRadius: "50%",
                    position: "relative",
                    "&::before": {
                      content: '" "',
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "50%",
                      height: "100%",
                      backgroundColor: "rgba(255, 116, 16, 0.4)",
                    },
                  },
                },
                "& .rmdp-day .sd": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-week .rmdp-week-day": {
                  color: theme.palette.fontColor.alt,
                },
                "& .rmdp-header-values": {
                  color: theme.palette.fontColor.main,
                },
                "& .rmdp-shadow": {
                  boxShadow: "none",
                },
                "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover":
                  {
                    backgroundColor: theme.palette.primary.alt,
                  },
              }}
            >
              <DatePicker
                render={
                  <div
                    onClick={() => datePickerRef.current.openCalendar()}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={mode === "dark" ? calendar : calendarLight}
                      alt="calendar icon"
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </div>
                }
                className="bg-dark rmdp-prime"
                calendar={persian}
                locale={persian_fa}
                range
                weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
                value={selectedRange}
                onChange={handleDateChange}
                ref={datePickerRef}
              />
            </Box>
            <IconButton>
              <DownloadOutlined />
            </IconButton>
            <IconButton>
              <FullscreenOutlined />
            </IconButton>
          </FlexBetween>
        </FlexBetween>
        <ActuatorChart
          min={0}
          max={1500}
          stepSize={500}
          thv1={1000}
          h={"80%"}
        />
      </WidgetWrapper>
      <WidgetWrapper mx="0.75rem" mb="0.75rem">
        <FlexBetween>
          <FlexBetween
            sx={{
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              width: "48%",
              height: "36px",
            }}
          >
            <Search sx={{ mr: "0.3rem" }} />
            <CustomInputBase
              placeholder="جست و جو"
              id="inputSearchRemote"
              value={actionSearchValue}
              onChange={(e) => setActionSearchValue(e.target.value)}
            />
          </FlexBetween>
          <Box width="48%" height="36px" borderRadius="8px">
            <Select
              value={selectedFilter}
              onChange={(e) => setSelectedFilter(e.target.value)}
              IconComponent={FilterAltOutlined}
              MenuProps={{
                // Apply the custom styled MenuList component
                MenuListProps: { component: StyledMenuList },
              }}
              sx={{
                width: "100%",
                height: "36px",
                border: `1px solid ${theme.palette.fontColor.alt}`,
                borderRadius: "8px",

                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#40405e",
                },
              }}
            >
              <MenuItem
                value={"همه"}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: "0 5px",
                  borderRadius: "4px",
                  backgroundColor: `${theme.palette.background.third}!important`,
                  ":hover": {
                    backgroundColor: `${theme.palette.fontColor.third} !important`,
                  },
                }}
              >
                <Typography
                  variant="smallestTextSecondary"
                  fontColor={theme.palette.fontColor.main}
                >
                  همه
                </Typography>
              </MenuItem>
            </Select>
          </Box>
        </FlexBetween>

        {MockData.map((item) => (
          <ActivityItemMob
            type={item.type}
            name={item.name}
            task={item.task}
            dueTime={item.dueTime}
            time={item.time}
          />
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default ActuatorMob;
