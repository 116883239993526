import { Box, Typography, useTheme } from "@mui/material";
import { getSVG } from "Utils/GrowthStep";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const StepSvgTitle = ({ title, step }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="0.5rem"
    >
      {getSVG(step, 0)}
      <FlexBetween>
        <Typography variant="h1" color={main}>
          {step}
        </Typography>
        <Typography variant="smallestTextSecondary" color={main}>
          {title}
        </Typography>
      </FlexBetween>
    </Box>
  );
};

export default StepSvgTitle;
