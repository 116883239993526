import WaterIcon from '../assets/water1.png'
import DangIcon from '../assets/dang.png';
import SoilIcon from '../assets/soil.png';
import Pic1 from '../assets/StaffImg/1.jpg';
import Pic2 from '../assets/StaffImg/2.jpg';
import Pic3 from '../assets/StaffImg/3.jpg';
export const recommendData = [
    {
      type: "w",
      name: "غذای بیشتر به گاو 213",
      area: "بهاربند 1",
      date: "1402/02/13 - 20:30",
      res: "machine",
      status: "انجام شده",
      recommender: "آقای علیزاده",  
      pic:Pic1,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "10:00 - 1402/02/17",
      icon:WaterIcon,
      description:"با توجه به روند آبیاری در مزرعه بهتر است به مدت یک هفته وعده غذایی را یک وعده افزایش دهید."
    },
    {
      type: "p",
      name: "نظافت بیشتر در بهاربند 2",
      area: "بهاربند 2",
      date: "1402/02/11 - 20:30",
      res: "",
      status: "در حال انجام",
      recommender: "آقای سعیدی",
      pic:Pic2,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "10:00 - 1402/02/17",
      icon: DangIcon,
      description:"با توجه به روند آبیاری در مزرعه بهتر است به مدت یک هفته وعده غذایی را یک وعده افزایش دهید."
    },
    {
      type: "s",
      name: "آب آشامیدنی بیشتر برای دام",
      area: "بهاربند 2",
      date: "1402/02/17 - 22:30",
      res: "",
      status: "در حال انتظار",
      recommender: "آقای احمدی",
      pic:Pic3,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "10:00 - 1402/02/17",
      icon:SoilIcon,
      description:"با توجه به روند آبیاری در مزرعه بهتر است به مدت یک هفته وعده غذایی را یک وعده افزایش دهید."
    },
    
  ];