import { MoreHoriz, Search } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect } from "react";
import staff1 from "../../assets/StaffImg/1.jpg";
import { useSelector } from "react-redux";

const HeaderMember = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const selectedMember = useSelector((state) => state.messenger.selectedMember);
  console.log(
    "🚀 ~ file: HeaderMember.jsx:13 ~ HeaderMember ~ selectedMember:",
    selectedMember
  );
  useEffect(() => {}, []);
  return (
    <FlexBetween>
      <Box display="flex" alignItems="center" gap="0.5rem">
        {selectedMember && (
          <Box
            component="img"
            width="50px"
            height="50px"
            borderRadius="50%"
            src={selectedMember?.imageUrl}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          // alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="h4" color={main}>
            {selectedMember?.name}
          </Typography>
          <Typography variant="smallestTextSecondary" color={alt}>
            {selectedMember ? "آنلاین" : ""}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="0.5rem">
        <IconButton>
          <Search />
        </IconButton>
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Box>
    </FlexBetween>
  );
};

export default HeaderMember;
