import { CacheProvider } from "@emotion/react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import FlexBetween from "components/FlexBetween/FlexBetween";
import ArrowRightDark from "../../assets/icon/arrow-right-dark.svg";
import ArrowRightLight from "../../assets/icon/arrow-right-light.svg";
import { useSelector } from "react-redux";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiInputBase-root": {
    height: "48px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const handleSubmit = () => {};
const CultivationTypeSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const CultivationTypeMob = ({ handleStep, polygonArea }) => {
  const mode = useSelector((state) => state.global.mode);
  const theme = useTheme();
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <FlexBetween mb="7rem" justifyContent="space-between">
        <FlexBetween alignItems="center">
          <IconButton sx={{ padding: 0 }}>
            <Box
              component="img"
              width="32px"
              height="32px"
              borderRadius="50%"
              src={mode === "dark" ? ArrowRightDark : ArrowRightLight}
              onClick={() => handleStep("BACK")}
            />
          </IconButton>
          <Typography variant="h3" color="fontColor.main">
            اطلاعات دامداری
          </Typography>
        </FlexBetween>
      </FlexBetween>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
          validationSchema={CultivationTypeSchema}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
            gap="0.8rem"
          >
            <CssTextField
              label="اسم دامداری"
              variant="outlined"
              style={{ width: "100%" }}
            />
            <FormControl
              sx={{
                minWidth: "100%",
                "& .MuiFormLabel-root": {
                  marginLeft: "-4px",
                },
                "& .MuiInputBase-input": {
                  height: "5px",
                },
                "& .MuiOutlinedInput-input:focused": {
                  borderColor: "green",
                },
                "& label.Mui-focused": {
                  color: "#F7F7F7",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "#F7F7F7",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root": {
                  color: "#F7F7F7 !important",
                  borderRadius: "8px",

                  "& fieldset": {
                    borderColor: "#F7F7F7",
                  },
                  "&:hover fieldset": {
                    borderColor: "#F7F7F7",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F7F7F7",
                  },
                },
              }}
            >
              {age === "" ? (
                <InputLabel
                  disableAnimation
                  shrink={false}
                  focused={false}
                  id="item_type_label"
                >
                  نوع دام
                </InputLabel>
              ) : null}

              <Select
                id="item_type"
                labelId="item_type_label"
                value={age}
                onChange={handleChange}
                sx={{ height: "48px" }}
              >
                <MenuItem value={false}>Private</MenuItem>
                <MenuItem value={true}>Public</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              sx={{
                minWidth: "100%",
                "& .MuiFormLabel-root": {
                  marginLeft: "-4px",
                },
                "& .MuiInputBase-input": {
                  height: "5px",
                },
                "& .MuiOutlinedInput-input:focused": {
                  borderColor: "green",
                },
                "& label.Mui-focused": {
                  color: "#F7F7F7",
                },

                "& .MuiInput-underline:after": {
                  borderBottomColor: "#F7F7F7",
                  borderRadius: "8px",
                },
                "& .MuiOutlinedInput-root": {
                  color: "#F7F7F7 !important",
                  borderRadius: "8px",

                  "& fieldset": {
                    borderColor: "#F7F7F7",
                  },
                  "&:hover fieldset": {
                    borderColor: "#F7F7F7",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#F7F7F7",
                  },
                },
              }}
            >
              {age === "" ? (
                <InputLabel
                  disableAnimation
                  shrink={false}
                  focused={false}
                  id="item_type_label"
                >
                  تعداد دام
                </InputLabel>
              ) : null}

              <Select
                sx={{ height: "48px" }}
                id="item_type"
                labelId="item_type_label"
                value={age}
                onChange={handleChange}
              >
                <MenuItem value={false}>Private</MenuItem>
                <MenuItem value={true}>Public</MenuItem>
              </Select>
            </FormControl>

            <Box width="100%" display="flex" mt="1rem">
              <Box
                sx={{
                  width: "100%",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => handleStep("COMPLETED", "COMPLETED")}
              >
                <Typography variant="h4">ثبت</Typography>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default CultivationTypeMob;
