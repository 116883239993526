import { AddOutlined, MoreHoriz, Search } from "@mui/icons-material";
import {
  useTheme,
  Box,
  Divider,
  FormControlLabel,
  InputBase,
  Radio,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import LiveChart from "./LiveChart";
import DownloadIcon from "../../assets/Icons/download.png";
import RecordLivestock from "./RecordLivestock";
import RecordInoculated from "./RecordInoculated";
import RecordDryness from "./RecordDryness";
import RecordVaccine from "./RecordVaccine";
import LiveDualLineChart from "./LiveDualLineChart";
import RecordBirth from "./RecordBirth";
import PointerIcon from "../../assets/pointer.png";
import RecordEstrus from "./RecordEstrus";
import { EstrusData } from "Mock/EstrusData";

const Estrus = ({ selectedTitle }) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [addItem, setAddItem] = useState(false);
  const fillteredData = EstrusData.livestock.filter((item) =>
    search !== "" ? item.code.toString().includes(search) : item
  );

  const colors = ["#C3FF79", "#72EB13", "#72EB13", "#FFEE00", "#FC4949"];
  const boxWidth = 107;
  const colorWidth = boxWidth / colors.length;

  const gradientBackground = `linear-gradient(to right, ${colors
    .map(
      (color, index) =>
        `${color} ${colorWidth * index}px, ${color} ${
          colorWidth * (index + 1)
        }px`
    )
    .join(", ")})`;
  return !addItem ? (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box width="100%" dispaly="flex" flex={1}>
        <Box width="100%" height="100%" display="flex" gap="16px">
          <WidgetWrapper flex={4.65} height="100%">
            <FlexBetween>
              <Typography variant="h4" color="fontColor.main">
                عملکرد {selectedTitle}
              </Typography>

              <Stack direction="row">
                <Stack direction="row" gap="16px">
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        sx={{
                          width: "12px",
                          height: "12px",
                          "&, &.Mui-checked": {
                            color: "others.second",
                          },
                        }}
                        checked={true}
                      />
                    }
                  />
                  <Typography
                    variant="smallestTextPrimary"
                    color="fontColor.alt"
                  >
                    فحلی بدون تلقیح
                  </Typography>
                </Stack>
                <Stack direction="row" gap="16px">
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        sx={{
                          width: "12px",
                          height: "12px",
                          "&, &.Mui-checked": {
                            color: "others.sixth",
                          },
                        }}
                        checked={true}
                      />
                    }
                  />
                  <Typography
                    variant="smallestTextPrimary"
                    color="fontColor.alt"
                  >
                    فحلی با تلقیح
                  </Typography>
                </Stack>
              </Stack>
            </FlexBetween>
            <LiveDualLineChart
              data1={EstrusData.success}
              data2={EstrusData.failure}
              color1={theme.palette.others.sixth}
              color2={theme.palette.others.second}
              r1={58}
              g1={198}
              b1={173}
              r2={252}
              g2={73}
              b2={73}
            />
          </WidgetWrapper>
          <WidgetWrapper flex={1} height="100%">
            <Stack direction="row" alignItems="center" gap="8px" mb="0.75rem">
              <Typography variant="h5" color="fontColor.main">
                {EstrusData.status[1]}
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                1 ماه گذشته
              </Typography>
            </Stack>
            <AnimatedProgressBar
              number={75}
              width={157}
              height={163}
              title={EstrusData.status[0][0]}
              subTitle={EstrusData.status[0][1]}
              color={theme.palette.primary.third}
              dot={false}
            />
          </WidgetWrapper>
        </Box>
      </Box>
      <WidgetWrapper width="100%" flex={1.48}>
        <FlexBetween>
          <FlexBetween
            sx={{
              // display: open ? "inline-flex" : "none",
              transition: "all 0.2s ease",
              ml: "1.5rem",
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              // border: "1px solid black",
              borderRadius: "8px",
              mt: "0.25rem",
            }}
          >
            <InputBase
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disableUnderline
              placeholder="کد دام"
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${theme.palette.fontColor.main} !important`,
                  },
                },
                height: "27px",
                width: "150px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <Stack direction="row" alignItems="center">
            <IconButton>
              <Box component="img" src={DownloadIcon} />
            </IconButton>
            <Box
              width="158px"
              height="27px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                cursor: "pointer",
              }}
              onClick={() => setAddItem(true)}
            >
              <AddOutlined />
              <Typography>مورد جدید</Typography>
            </Box>
          </Stack>
        </FlexBetween>
        <Box style={{ height: "80%", width: "100%" }}>
          <FlexBetween
            mt="0.5rem"
            sx={{ width: "100%", justifyContent: "flex-start" }}
          >
            <Box
              width="8%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="fontColor.alt">کد دام</Typography>
            </Box>

            <Box
              width="20%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                تاریخ
              </Typography>
            </Box>

            <Box
              width="10%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                وضعیت
              </Typography>
            </Box>

            <Box
              width="10%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                علائم
              </Typography>
            </Box>

            <Box
              width="20%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                زمان های فحلی
              </Typography>
            </Box>
            <Box
              width="15%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                بهترین زمان فحلی
              </Typography>
            </Box>
            <Box
              width="15%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                فحل یاب
              </Typography>
            </Box>

            <Box
              width="15%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                شیرواری
              </Typography>
            </Box>

            <Box
              width="15%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                تلقیح
              </Typography>
            </Box>
            <Box
              width="5%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>
          </FlexBetween>

          <Divider sx={{ margin: "12px 0" }} />
          <Box
            sx={{
              height: "80%",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            {fillteredData.map((item, index) => (
              <>
                <FlexBetween
                  mt="0.5rem"
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                >
                  <Box
                    width="8%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography color="fontColor.alt">{item.code}</Typography>
                  </Box>

                  <Box
                    width="20%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.date}
                    </Typography>
                  </Box>

                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.status}
                    </Typography>
                  </Box>

                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.signs}
                    </Typography>
                  </Box>

                  <Box
                    width="20%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Stack direction="column" sx={{}}>
                      <Box
                        sx={{
                          width: "107px",
                          height: "6px",
                          backgroundImage: gradientBackground,
                          borderRadius: "30px",
                          position: "relative",
                        }}
                      >
                        <Box
                          component="img"
                          src={PointerIcon}
                          position="absolute"
                          sx={{ top: -6, left: (item.estrusTime / 32) * 100 }}
                        />
                      </Box>
                      <FlexBetween
                        sx={{
                          width: "107px",
                          height: "6px",
                          mt: "0.5rem",
                        }}
                      >
                        <Typography variant="extraSmall" color="fontColor.main">
                          32
                        </Typography>
                        <Typography variant="extraSmall" color="fontColor.main">
                          0
                        </Typography>
                      </FlexBetween>
                    </Stack>
                  </Box>
                  <Box
                    width="15%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.bestTime}
                    </Typography>
                  </Box>
                  <Box
                    width="15%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.estrusFinder}
                    </Typography>
                  </Box>

                  <Box
                    width="15%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.milk}
                    </Typography>
                  </Box>

                  <Box
                    width="15%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.estrus}
                    </Typography>
                  </Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton sx={{ rotate: "90deg" }}>
                      <MoreHoriz />
                    </IconButton>
                  </Box>
                </FlexBetween>
                <Divider sx={{ margin: "10px 0" }} />
              </>
            ))}
          </Box>
        </Box>
      </WidgetWrapper>
    </Box>
  ) : (
    <WidgetWrapper
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <RecordEstrus setAddItem={setAddItem} />
    </WidgetWrapper>
  );
};

export default Estrus;
