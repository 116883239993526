import { MoreHoriz } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  ListItem,
  Stack,
  styled,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
const profile = [
  { do: "4", done: "2", picture: require("../assets/StaffImg/1.jpg") },
  { do: "7", done: "5", picture: require("../assets/StaffImg/2.jpg") },
  { do: "2", done: "1", picture: require("../assets/StaffImg/3.jpg") },
  { do: "6", done: "5", picture: require("../assets/StaffImg/4.jpg") },
  { do: "1", done: "0", picture: require("../assets/StaffImg/5.jpg") },
  { do: "7", done: "5", picture: require("../assets/StaffImg/6.jpg") },
  { do: "2", done: "0", picture: require("../assets/StaffImg/7.jpg") },
];
const BadgeRed = styled(Typography)`
  width: 20px;
  height: 20px;
  background-color: #ff7410;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important;
`;

const BadgeGreen = styled(Typography)`
  width: 20px;
  height: 20px;
  background-color: #5fbd15;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px !important;
`;
const AvatarUsers = styled(Avatar)`
  width: 53px !important;
  height: 53px !important;
`;

const StaffMgmt = () => {
  const [value, setValue] = React.useState(0);
  const [index, setIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setIndex(activeIndex);
  };
  const staffData = useSelector((state) => state.dashboard.staffs);
  console.log(
    "🚀 ~ file: StaffMgmt.jsx:56 ~ StaffMgmt ~ staffData:",
    staffData
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box width="100%">
      {/* {!staffData.enabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,

            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="88px"
            height="27px"
            sx={{
              backgroundColor: theme.palette.others.first,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              فعال سازی
            </Typography>
          </Box>
        </Box>
      )} */}
      {/* <Box sx={{ opacity: staffData.enabled ? 1 : 0.1 }}> */}

      <FlexBetween mb="0.5rem">
        <Typography variant="h5">مدیریت کارکنان</Typography>
        <MoreHoriz
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/staffmgmt")}
        />
      </FlexBetween>

      <Box
        height="63px"
        sx={{
          "& .swiper-custom": {
            height: "63px",
            padding: "0 1rem",
            marginTop: "2rem",
          },
          "& .swiper-custom .swiper-button-prev": {
            margin: 0,
            borderRadius: " 0.25rem",
            position: "absolute",
            right: "-10px !important",
            top: "38px",
          },
          "& .swiper-custom1 .swiper-button-next": {
            margin: 0,
            borderRadius: " 0.25rem",
            position: "absolute",
            left: "10px !important",
            top: "50px",
          },
          "& .swiper-button-prev::after": {
            content:
              'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 10.7922L5.10696 6.11456L0 1.43692L1.57223 0L8.26257 6.11456L1.57223 12.2291L0 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
            fontSize: "1rem",
            marginTop: "-4.7rem",
            marginRight: "0rem",
            color: "#F7F7F7", // Set the color you want for the content
          },
          "& .swiper-button-next::after": {
            content:
              'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.26257 10.7922L3.15561 6.11456L8.26257 1.43692L6.69034 0L3.8147e-06 6.11456L6.69034 12.2291L8.26257 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
            fontSize: "1rem",
            marginTop: "-0.525rem",
            marginLeft: "-1.75rem",
            color: "#F7F7F7", // Set the color you want for the content
          },
        }}
      >
        <Swiper
          navigation={true}
          spaceBetween={20}
          slidesPerView={4}
          modules={[Navigation]}
          className="swiper-custom"
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          {profile.map((user) => (
            <SwiperSlide>
              <Stack direction="row" spacing={2}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  badgeContent={<BadgeRed>{user.do}</BadgeRed>}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={<BadgeGreen>{user.done}</BadgeGreen>}
                  >
                    <AvatarUsers alt="Travis Howard" src={user.picture} />
                  </Badge>
                </Badge>
              </Stack>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
};

export default StaffMgmt;
