import {
  Backdrop,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MenuMessenger from "./MenuMessenger";

const MeMessenger = ({ text, time, containerRef, me = false }) => {
  const messageRef = useRef(null);
  const menuRef = useRef(null);
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [anchorEl, setAnchorEl] = useState(null);
  const [top, setTop] = useState("100%");
  const handleContextMenu = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const totalHeight = containerRef?.current?.clientHeight;
    const messageOffsetTop = messageRef?.current?.offsetTop;
    const messageHeight = messageRef?.current?.clientHeight;
    const menuHeight = menuRef?.current?.clientHeight;
    if (totalHeight && messageOffsetTop && menuHeight && messageHeight) {
      const diff = totalHeight - messageOffsetTop;
      const bottomDiff = diff - messageHeight;
      if (bottomDiff < menuHeight) setTop(`${-(menuHeight - diff)}px`);
      if (bottomDiff >= menuHeight) setTop("100%");
    }
  }, [containerRef.current, messageRef.current, menuRef.current]);
  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleOnTouchStart = (event) => {
    setTimeout(() => {
      setAnchorEl(event.currentTarget);
    }, 500);
  };
  return (
    <Box
      width={"auto"}
      maxWidth={isMobileScreens ? "95%" : "459px"}
      mt="1rem"
      p="0.5rem 0.75rem"
      borderRadius={me ? "14px 14px 4px 14px" : "14px 14px 14px 4px"}
      sx={{
        backgroundColor: me
          ? theme.palette.background.third
          : theme.palette.others.first,
      }}
      onContextMenu={handleContextMenu}
      onTouchStart={handleOnTouchStart}
      position="relative"
      ref={messageRef}
    >
      <Stack
        direction="row"
        gap="0.5rem"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body" color={main}>
          {text}
        </Typography>
        <Typography
          variant="smallestTextSecondary"
          color={alt}
          alignSelf="flex-end"
          sx={{ direction: "ltr", whiteSpace: "nowrap" }}
        >
          {time}
        </Typography>
      </Stack>
      <Box
        sx={{
          position: "absolute",
          zIndex: Boolean(anchorEl) ? 1001 : -1,
          top: top,
          ...(me ? { left: 0 } : { right: 0 }),
        }}
      >
        <MenuMessenger
          menuRef={menuRef}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </Box>
      <Backdrop
        sx={{
          background: "transparent",
          zIndex: 999,
          position: "fixed",
          inset: 0,
        }}
        open={anchorEl}
        onClick={handleClose}
      ></Backdrop>
    </Box>
  );
};

export default MeMessenger;
