import { MoreHoriz } from "@mui/icons-material";
import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import CowIcon from "../assets/cow.png";
import WaterIcon from "../assets/water.png";
import W7 from "../assets/WeatherIcons/static/cloudy-day-1.svg";

const Weather = () => {
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setIndex(activeIndex);
  };
  return (
    <Box>
      <FlexBetween>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <Typography variant="h5" color="fontColor.main">
            آب و هوای مزرعه
          </Typography>
          <Typography variant="smallestTextPrimary" color="fontColor.alt">
            بهاربند {index + 1}
          </Typography>
        </Stack>
        <MoreHoriz />
      </FlexBetween>
      <Box
        sx={{
          "& .swiper-custom .swiper-button-prev": {
            margin: 0,
            borderRadius: " 0.25rem",
            position: "absolute",
            right: "-10px !important",
            top: "38px",
          },
          "& .swiper-custom1 .swiper-button-next": {
            margin: 0,
            borderRadius: " 0.25rem",
            position: "absolute",
            left: "10px !important",
            top: "50px",
          },
          "& .swiper-button-prev::after": {
            content:
              'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 10.7922L5.10696 6.11456L0 1.43692L1.57223 0L8.26257 6.11456L1.57223 12.2291L0 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
            fontSize: "1rem",
            marginTop: "-0.525rem",
            color: "#F7F7F7", // Set the color you want for the content
          },
          "& .swiper-button-next::after": {
            content:
              'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.26257 10.7922L3.15561 6.11456L8.26257 1.43692L6.69034 0L3.8147e-06 6.11456L6.69034 12.2291L8.26257 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
            fontSize: "1rem",
            marginTop: "-0.525rem",
            marginLeft: "-1.75rem",
            color: "#F7F7F7", // Set the color you want for the content
          },
        }}
      >
        <Swiper
          navigation={true}
          spaceBetween={50}
          slidesPerView={1}
          modules={[Navigation]}
          style={{ marginTop: "1rem" }}
          className="swiper-custom"
          onSlideChange={(swiper) => handleSlideChange(swiper)}
        >
          <SwiperSlide>
            <FlexBetween gap="2rem" m="0 1.5rem">
              <Stack
                direction="row"
                borderLeft={`1px solid ${theme.palette.background.third}`}
                pl="0.5rem"
              >
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      THI
                    </Typography>
                    <Box
                      component="img"
                      src={CowIcon}
                      width="32px"
                      height="32px"
                    />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      23 <sup style={{ fontSize: "14px" }}>°</sup>
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>

              <Stack
                direction="row"
                borderLeft={`1px solid ${theme.palette.background.third}`}
                pl="0.5rem"
                mr="-0.5rem"
              >
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      رطوبت
                    </Typography>
                    <Box
                      component="img"
                      src={WaterIcon}
                      width="32px"
                      height="32px"
                    />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      %16
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" mr="-0.5rem">
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      دما
                    </Typography>
                    <Box component="img" src={W7} width="32px" height="32px" />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      23 <sup style={{ fontSize: "14px" }}>°</sup>
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>
            </FlexBetween>
          </SwiperSlide>
          <SwiperSlide>
            <FlexBetween gap="2rem" m="0 1.5rem">
              <Stack
                direction="row"
                borderLeft={`1px solid ${theme.palette.background.third}`}
                pl="0.5rem"
              >
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      THI
                    </Typography>
                    <Box
                      component="img"
                      src={CowIcon}
                      width="32px"
                      height="32px"
                    />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      23 <sup style={{ fontSize: "14px" }}>°</sup>
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>

              <Stack
                direction="row"
                borderLeft={`1px solid ${theme.palette.background.third}`}
                pl="0.5rem"
                mr="-0.5rem"
              >
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      رطوبت
                    </Typography>
                    <Box
                      component="img"
                      src={WaterIcon}
                      width="32px"
                      height="32px"
                    />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      %16
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" mr="-0.5rem">
                <Box width="82px" height="112px">
                  <FlexBetween>
                    <Typography variant="subHeading" color="fontColor.alt">
                      دما
                    </Typography>
                    <Box component="img" src={W7} width="32px" height="32px" />
                  </FlexBetween>
                  <Box width="55px" height="40px">
                    <Typography variant="h2" sx={{ direction: "ltr" }}>
                      23 <sup style={{ fontSize: "14px" }}>°</sup>
                    </Typography>
                  </Box>
                  <Box width="100%">
                    <Typography
                      variant="smallestTextSecondary"
                      color="fontColor.alt"
                    >
                      دوشنبه 10/16
                    </Typography>
                  </Box>
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.alt"
                  >
                    20:00
                  </Typography>
                </Box>
              </Stack>
            </FlexBetween>
          </SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  );
};

export default Weather;
