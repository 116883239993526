import * as React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Checkbox,
  InputBase,
  ListItemText,
  MenuItem,
  MenuList,
  OutlinedInput,
  Rating,
  Select,
  Stack,
  styled,
  useTheme,
} from "@mui/material";

import {
  AddOutlined,
  CalendarMonthOutlined,
  ListOutlined,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";

import CopyRight from "widgets/CopyRight";
import WidgetWrapper from "components/WidgetWrapper";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";

import { useState } from "react";
import StaffTable from "./StaffTable";
import StaffCalendar from "./StaffCalendar";
import RecordTask from "./RecordTask";
import { useEffect } from "react";
import { useMemo } from "react";
import { staffData } from "Mock/StaffData";

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */

  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
  zIndex: 20000000,
}));
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["انجام شده", "انجام نشده", "در حال انجام"];

// const temp = recommendData.filter((item) =>
//   searchValue !== "" ? item.name.includes(searchValue) : item
// );

export default function StaffMgmt() {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [newTask, setNewTask] = useState(false);
  const [tasks, setTasks] = useState([]);
  console.log("🚀 ~ file: StaffMgmt.jsx:133 ~ StaffMgmt ~ tasks:", tasks);
  const [selectedUser, setSelectedUser] = useState();
  const [selectType, setSelectType] = useState(0);
  const [selectUser, setSelectUser] = useState(0);

  const [personName, setPersonName] = React.useState([]);
  const [search, setSearch] = useState("");

  const initData = tasks?.filter((item) => {
    const temp = item.title.split("/");
    return search !== "" ? temp[1].includes(search) : item;
  });
  useEffect(() => {
    // if (selectType === 1) {
    //   setSelectType(0);
    // }
  }, [initData, search]);

  const memoizedCalendar = React.useCallback(
    () => <StaffCalendar initialDataCalendar={initData} />,
    [initData]
  );
  console.log("🚀 ~ file: StaffMgmt.jsx:128 ~ initData ~ initData:", initData);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1235px"
      width="calc(100% - 2rem)"
      height="89vh"
      m="16px"
    >
      {newTask ? (
        <Box minWidth="100%" height="83vh">
          <Typography variant="h3" color="fontColor.main">
            ثبت وظیفه
          </Typography>
          <Box
            width="100%"
            height="95%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <RecordTask setNewTask={setNewTask} setTasks={setTasks} />
          </Box>
        </Box>
      ) : (
        <Box display="flex" gap="16px">
          <WidgetWrapper
            minWidth="18.1%"
            height="83vh"
            display="flex"
            flexDirection="column"
            gap="16px"
          >
            <Box display="flex" gap="0.2rem">
              <CalendarMonthOutlined sx={{ width: "24px", height: "24px" }} />
              <Typography variant="h4" color={main}>
                26 بهمن 1401
              </Typography>
            </Box>
            <WidgetWrapper
              width="100%"
              height="42%"
              sx={{ backgroundColor: theme.palette.background.third }}
            >
              <Box display="flex" gap="0.5rem">
                <Typography variant="h5" color={main} mb="0.5rem">
                  شاخص عملکرد
                </Typography>
                <Typography variant="subHeading" color={main}>
                  امیر محمدی
                </Typography>
              </Box>
              <AnimatedProgressBar
                number={70}
                width={157}
                height={163}
                title="%70"
                subTitle=""
                color={theme.palette.others.third}
                dot={false}
                secondColor={theme.palette.background.forth}
              />
              <FlexBetween>
                <Box display="flex" alignItems="center" gap="0.2rem">
                  <Typography
                    variant="smallestTextSecondary"
                    mt="3px"
                    color={alt}
                  >
                    کل:
                  </Typography>
                  <Typography variant="h3" color={main}>
                    35
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="0.2rem">
                  <Typography
                    variant="smallestTextSecondary"
                    mt="3px"
                    color={alt}
                  >
                    انجام شده:
                  </Typography>
                  <Typography variant="h3" color={main}>
                    28
                  </Typography>
                </Box>
              </FlexBetween>
            </WidgetWrapper>
            <WidgetWrapper
              width="100%"
              height="49%"
              sx={{
                backgroundColor: theme.palette.background.third,
                padding: "1rem 1rem 1.1rem 0",
                overflow: "hidden",
              }}
            >
              <Typography variant="h5" color={main} mb="0.75rem">
                عملکرد کارکنان
              </Typography>
              <Box
                sx={{
                  height: "93%",
                  overflow: "auto",

                  "&::-webkit-scrollbar": {
                    width: 8,
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "1px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#5A596F",
                    outline: `0px solid slategrey`,
                    borderRadius: "5px",
                  },
                }}
              >
                {staffData.map((item, index) => (
                  <Box
                    width="95%"
                    height="64px"
                    key={index}
                    mb="0.5rem"
                    sx={{
                      p: "0.5rem",
                      backgroundColor:
                        selectedUser === item.id
                          ? theme.palette.background.forth
                          : "transparent",

                      cursor: "pointer",
                      borderRadius: "4px",
                      ":hover": {
                        backgroundColor: theme.palette.background.forth,
                        borderRadius: "4px",
                      },
                    }}
                    onClick={() => {
                      if (selectedUser === item.id) {
                        setSelectedUser("");
                      } else {
                        setSelectedUser(item.id);
                      }
                    }}
                  >
                    <FlexBetween>
                      <Stack
                        direction="row"
                        gap="0.5rem"
                        sx={{ alignItems: "center" }}
                      >
                        <Box
                          width="24px"
                          height="24px"
                          component="img"
                          src={item.pic}
                          borderRadius="99px"
                        />
                        <Typography variant="smallestTextPrimary" color={main}>
                          {item.name}
                        </Typography>
                      </Stack>
                      <Rating
                        size="small"
                        name="read-only"
                        value={item.score}
                        readOnly
                        // sx={{ direction: "ltr" }}
                      />
                    </FlexBetween>
                    <Stack direction="row" alignItems="center" gap="0.5rem">
                      <Typography variant="smallestTextSecondary" color={alt}>
                        وظایف انجام شده:
                      </Typography>
                      <Typography variant="body" color={main}>
                        {item.done}/{item.allTasks}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </Box>
            </WidgetWrapper>
          </WidgetWrapper>
          <WidgetWrapper minWidth="81%" height="83vh">
            <FlexBetween>
              <Stack direction="row" alignItems="center" gap="0.5rem">
                <Typography variant="h4" color={main}>
                  وظایف کارکنان
                </Typography>
                <Box
                  width="121px"
                  height="27px"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.first,
                    p: "0 0.7rem",
                    cursor: "pointer",
                  }}
                  onClick={() => setNewTask(true)}
                >
                  <Stack direction="row" alignItems="center" gap="0.2rem">
                    <AddOutlined sx={{ width: "24px", height: "24px" }} />
                    <Typography>وظیفه جدید</Typography>
                  </Stack>
                </Box>
              </Stack>

              <Stack direction="row" gap="0.5rem" alignItems="center">
                <FlexBetween
                  sx={{
                    // display: open ? "inline-flex" : "none",
                    transition: "all 0.2s ease",

                    alignItems: "center",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    // border: "1px solid black",
                    borderRadius: "8px",
                  }}
                >
                  <InputBase
                    disableUnderline
                    placeholder="جست و جو"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    id="inputSearchRemote"
                    sx={{
                      paddingRight: "5px",
                      backgroundColor: "inherit",
                      "& .MuiInputBase-input": {
                        "::placeholder": {
                          color: `${main} !important`,
                        },
                      },
                      height: "30px",
                      width: "158px",
                      borderRadius: "8px",
                      color: theme.palette.fontColor.alt,
                      outline: "none",
                      borderBottom: "none",
                    }}
                  />
                  <Search sx={{ ml: "0.2rem" }} />
                </FlexBetween>
                <Select
                  value={selectType}
                  onChange={(e) => setSelectType(e.target.value)}
                  MenuProps={{
                    // Apply the custom styled MenuList component
                    MenuListProps: { component: StyledMenuList },
                  }}
                  sx={{
                    width: "85px",
                    height: "30px",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    borderRadius: "8px",
                    boxShadow: "none !important",
                    "& .MuiPaper-root": {
                      zIndex: 20000000,
                    },
                    "& .MuiPaper-elevation": {
                      boxShadow: "none !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#40405e",
                    },
                  }}
                >
                  <StyledMenuList
                    value={0}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third} !important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    جدول
                  </StyledMenuList>
                  <StyledMenuList
                    value={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third} !important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    تقویم
                  </StyledMenuList>
                </Select>

                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={personName}
                  onChange={handleChange}
                  input={<OutlinedInput label="" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  IconComponent={ListOutlined}
                  sx={{
                    width: "120px",
                    height: "30px",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    borderRadius: "8px",
                    boxShadow: "none !important",
                    "& .MuiList-root ": {
                      padding: "0 !important",
                      background: "#f00",
                    },
                    // "& .MuiPaper-root": {
                    //   zIndex: 20000000,
                    // },
                    // "& .MuiPaper-elevation": {
                    //   boxShadow: "red !important",
                    // },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#40405e",
                    },
                  }}
                >
                  {names.map((name) => (
                    <StyledMenuList
                      sx={{ display: "flex", alignItems: "center" }}
                      key={name}
                      value={name}
                    >
                      <Checkbox checked={personName.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </StyledMenuList>
                  ))}
                </Select>

                <Select
                  value={selectUser}
                  onChange={(e) => setSelectUser(e.target.value)}
                  MenuProps={{
                    // Apply the custom styled MenuList component
                    MenuListProps: { component: StyledMenuList },
                  }}
                  sx={{
                    width: "125px",
                    height: "30px",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    borderRadius: "8px",
                    boxShadow: "none !important",
                    "& .MuiPaper-root": {
                      zIndex: 20000000,
                    },
                    "& .MuiPaper-elevation": {
                      boxShadow: "none !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#40405e",
                    },
                  }}
                  IconComponent={PersonOutlineOutlined}
                >
                  <StyledMenuList
                    value={0}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third} !important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    همه کارکنان
                  </StyledMenuList>
                  {staffData.map((item, index) => (
                    <StyledMenuList
                      value={index + 1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: "0 5px",
                        borderRadius: "4px",
                        backgroundColor: `${theme.palette.background.third} !important`,
                        ":hover": {
                          backgroundColor: `${theme.palette.fontColor.third} !important`,
                        },
                      }}
                    >
                      {item.name}
                    </StyledMenuList>
                  ))}
                </Select>
              </Stack>
            </FlexBetween>
            {selectType === 0 ? (
              // <StaffTable data={initData} />
              <StaffTable data={staffData} />
            ) : (
              memoizedCalendar()
            )}
          </WidgetWrapper>
        </Box>
      )}
      <CopyRight />
    </Box>
  );
}
