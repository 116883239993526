import { Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import moment from "jalali-moment";
const CopyRight = () => {
  const getCurrentPersianDate = () => {
    const now = moment(); // get the current date and time
    const persianDate = now.locale("fa"); // set the locale to Persian
    const formattedDate = persianDate.format("dddd jYYYY/jM/jD - HH:mm"); // format the date and time string
    return formattedDate;
  };
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <WidgetWrapper
      sx={{
        p: "0rem 0rem 0rem 0rem",
        backgroundColor: "inherit",
      }}
    >
      <FlexBetween>
        {/* <FlexBetween gap="0.3rem">
          <Typography variant="subHeading" color={alt}>
            آخرین اتصال به ابر:
          </Typography>
          <Typography variant="subHeading" color={main}>
            دوشنبه 1401/10/05 - 20:08
          </Typography>
        </FlexBetween> */}
        <Typography variant="subHeading" color={main}>
          کلیه حقوق این سامانه متعلق به شرکت پرتوتاپ رایان است
        </Typography>

        <FlexBetween gap="0.3rem">
          <Typography variant="subHeading" color={alt}>
            امروز:
          </Typography>
          <Typography variant="subHeading" color={main}>
            {getCurrentPersianDate()}
          </Typography>
        </FlexBetween>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default CopyRight;
