import React, { useEffect, useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween/FlexBetween";

import { useDispatch } from "react-redux";
import { setLogin } from "States";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  width: "312px",
  height: "44px",
  fontSize: "20px !important",
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7FC",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7FC",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7FC !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7FC",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7FC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7FC",
    },
  },
});

const FormMob = ({ setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [showPassword, setShowPassword] = React.useState(true);
  const theme = useTheme();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  useEffect(() => {
    setUsername("");
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  const loginSchema = yup.object().shape({
    username: yup.string().required("required"),
    password: yup.string().required("required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("salama");

    const client_id = "user-panel";
    const client_secret = "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV";
    const grant_type = "password";
    const url = "https://api.rahbaan.ir/v1/authentication/token";

    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_id", client_id);
    formData.append("client_secret", client_secret);
    formData.append("grant_type", grant_type);

    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(
          setLogin({
            user: data,
            token: data.access_token,
            refreshToken: data.refresh_token,
          })
        );
        console.log("🚀 ~ file: Form.jsx:108 ~ .then ~ data:", data);

        if (!data.access_token) setOpen(true);
      })
      .catch((error) => {
        console.log("🚀 ~ file: Form.jsx:111 ~ handleSubmit ~ error", error);
      });
  };

  return (
    <CacheProvider value={cacheRtl}>
      <Box
        sx={{
          width: "336px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          direction: "ltr",
          mt: "2rem",
        }}
      >
        <form onSubmit={handleSubmit} validationSchema={loginSchema}>
          <Stack
            direction="column"
            gap="26px"
            alignItems="center"
            width="400px"
            sx={{ height: "100%" }}
          >
            <Typography color="#F7F7FC" variant="h3" textAlign="center">
              ورود
            </Typography>
            <Stack direction="column" gap="20px">
              <CssTextField
                label="نام کاربری"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onKeyPress={handleKeyPress}
                inputProps={{
                  style: {
                    height: "4px",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    // set the color of the label when not shrinked
                    color: "#F7F7FC",
                    fontSize: "16px",
                  },
                }}
              />

              <Box position={"relative"}>
                <CssTextField
                  label="رمز عبور"
                  value={password}
                  type={showPassword ? "password" : "text"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={handleKeyPress}
                  inputProps={{
                    style: {
                      height: "4px",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      // set the color of the label when not shrinked
                      color: "#F7F7FC",
                      fontSize: "16px",
                    },
                  }}
                />
                <Box sx={{ position: "absolute", top: 2, right: 0 }}>
                  <IconButton onClick={handleClickShowPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </Box>
              </Box>
            </Stack>
            <Box>
              <Link to="/resetPassword">
                <Typography
                  color="#F7F7FC"
                  fontSize={theme.typography.subHeading}
                  mt="0.5rem"
                >
                  رمز عبور خود را فراموش کرده اید؟
                </Typography>
              </Link>
              <IconButton
                variant="contained"
                sx={{
                  width: "312px",
                  height: "44px",
                  mt: "1.5rem",
                  fontSize: "24px",
                  borderRadius: "8px",
                  boxShadow: "none",
                  backgroundColor: theme.palette.others.first,
                  ":hover": {
                    backgroundColor: theme.palette.others.first,
                  },
                }}
                onClick={handleSubmit}
              >
                <Typography
                  fontFamily={"YekanBakhFaNum-Regular"}
                  fontSize="20px"
                >
                  ورود
                </Typography>
              </IconButton>
              <FlexBetween
                sx={{ justifyContent: "flex-start" }}
                gap="0.5rem"
                mb="1rem"
              >
                <Typography
                  color="#F7F7FC"
                  fontSize={theme.typography.subHeading}
                  mt="0.5rem"
                >
                  حساب کاربری ندارید؟
                </Typography>
                <Link to="/register">
                  <Typography color="#F7F7FC" mt="0.5rem">
                    <span
                      style={{ width: "40px", borderBottom: "1px solid white" }}
                    >
                      ثبت نام کنید
                    </span>
                  </Typography>
                </Link>
              </FlexBetween>

              <GoogleLogin
                id="googleOne"
                onSuccess={(credentialResponse) => {
                  console.log(credentialResponse);
                  navigate("/");
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </Box>
          </Stack>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default FormMob;
