import { useMediaQuery } from "@mui/material";
import React from "react";
import Actuator from "./Actuator";
import ActuatorMob from "mob/scenesMob/ActuatorMob/ActuatorMob";
const ActuatorIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <ActuatorMob /> : <Actuator />}</>;
};

export default ActuatorIndex;
