import { CacheProvider } from "@emotion/react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import FlexBetween from "components/FlexBetween/FlexBetween";
import SensorImage from "../../assets/sensorImage.png";
import BarcodeScannerComponent from "@grindstaff/react-qr-barcode-scanner";
import {
  CameraAltOutlined,
  CameraOutlined,
  CloseOutlined,
} from "@mui/icons-material";
const StyledTextarea = styled(TextareaAutosize)`
  resize: none;
`;
const StyledInput = styled("input")({
  display: "none",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const handleSubmit = () => {};
const RecordEventsSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const RecordSensorsForm = ({ setStep }) => {
  const theme = useTheme();

  const [age, setAge] = React.useState("");
  const [barcode, setBarcode] = useState(false);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="1376px"
        height="300px"
      >
        <form onSubmit={handleSubmit} validationSchema={RecordEventsSchema}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="1rem"
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    "& .MuiInputBase-root ": {
                      height: "45px !important",
                    },
                  }}
                >
                  <CssTextField
                    label="نام سنسور"
                    variant="outlined"
                    style={{ width: "250px" }}
                  />
                  <FormControl
                    sx={{
                      minWidth: 250,
                      "& .MuiFormLabel-root": {
                        marginLeft: "-4px",
                      },
                      "& .MuiInputBase-input": {
                        height: "5px",
                      },
                      "& .MuiOutlinedInput-input:focused": {
                        borderColor: "green",
                      },
                      "& label.Mui-focused": {
                        color: "#F7F7F7",
                      },

                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#F7F7F7",
                        borderRadius: "8px",
                      },
                      "& .MuiOutlinedInput-root": {
                        color: "#F7F7F7 !important",
                        borderRadius: "8px",

                        "& fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&:hover fieldset": {
                          borderColor: "#F7F7F7",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#F7F7F7",
                        },
                      },
                    }}
                  >
                    {age === "" ? (
                      <InputLabel
                        disableAnimation
                        shrink={false}
                        focused={false}
                        id="item_type_label"
                      >
                        نوع
                      </InputLabel>
                    ) : null}

                    <Select
                      id="item_type"
                      labelId="item_type_label"
                      value={age}
                      onChange={handleChange}
                    >
                      <MenuItem value={false}>Private</MenuItem>
                      <MenuItem value={true}>Public</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    mt: "1rem",
                    gap: "1rem",
                    "& .MuiInputBase-root ": {
                      height: "45px !important",
                    },
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Box
                      sx={{
                        position: "relative",
                      }}
                    >
                      <CssTextField
                        label="شماره سریال"
                        variant="outlined"
                        style={{ width: "250px" }}
                      ></CssTextField>
                      <IconButton
                        sx={{
                          position: "absolute",
                          zIndex: 100,
                          top: "10%",
                          right: 10,
                        }}
                        onClick={() => setBarcode(!barcode)}
                      >
                        <CameraAltOutlined />
                      </IconButton>
                    </Box>

                    <CssTextField
                      label="مختصات  [35.5,52]"
                      variant="outlined"
                      style={{ width: "250px", marginTop: "1rem" }}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <FormControl
                      sx={{
                        minWidth: 250,
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-input": {
                          height: "5px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {age === "" ? (
                        <InputLabel
                          disableAnimation
                          shrink={false}
                          focused={false}
                          id="item_type_label"
                        >
                          تاریخ ثبت
                        </InputLabel>
                      ) : null}

                      <Select
                        id="item_type"
                        labelId="item_type_label"
                        value={age}
                        onChange={handleChange}
                      >
                        <MenuItem value={false}>Private</MenuItem>
                        <MenuItem value={true}>Public</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        minWidth: 250,
                        mt: "1rem",
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-input": {
                          height: "5px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {age === "" ? (
                        <InputLabel
                          disableAnimation
                          shrink={false}
                          focused={false}
                          id="item_type_label"
                        >
                          بازه زمانی ارسال داده
                        </InputLabel>
                      ) : null}

                      <Select
                        id="item_type"
                        labelId="item_type_label"
                        value={age}
                        onChange={handleChange}
                      >
                        <MenuItem value={false}>Private</MenuItem>
                        <MenuItem value={true}>Public</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <StyledTextarea
                maxRows={3}
                placeholder="توضیحات"
                style={{
                  padding: "0.5rem",
                  width: "250px",
                  height: "167px",
                  border: "1px solid white",
                  background: theme.palette.background.second,
                  borderRadius: "8px",
                  color: theme.palette.fontColor.main,
                }}
              />
              <Box
                width="167px"
                height="167px"
                component="img"
                src={SensorImage}
              />
            </Box>

            <Box display="flex" gap="1rem" mt="2.5rem">
              <Box
                sx={{
                  width: "250px",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h4">ثبت </Typography>
              </Box>
            </Box>
          </Box>
        </form>
        {barcode ? (
          <Modal
            open={barcode}
            onClose={() => setBarcode(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              width={500}
              height={450}
              sx={{ backgroundColor: theme.palette.background.third }}
            >
              <FlexBetween p="1rem">
                <Typography>بارکد دستگاه را وارد نمایید.</Typography>
                <IconButton onClick={() => setBarcode(false)}>
                  <CloseOutlined />
                </IconButton>
              </FlexBetween>
              <BarcodeScannerComponent
                width={500}
                height={400}
                onUpdate={(err, result) => {
                  // if (result) setData(result.text);
                  // else setData("Not Found");
                }}
              />
            </Box>
          </Modal>
        ) : null}
      </Box>
    </CacheProvider>
  );
};

export default RecordSensorsForm;
