import React from "react";
import { Box, Typography, keyframes, useTheme } from "@mui/material";

const AnimatedProgressBarWallet = ({
  number,
  width,
  height,
  title,
  unit,
  subTitle,
  color,
  dot,
}) => {
  const theme = useTheme();
  const animateDot = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
        transform: rotate(calc(3.6deg * var(--num)));    }
  `;
  const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
        opacity:1
   }
`;
  return (
    <Box
      sx={{
        m: 0,
        p: 0,
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: height,
          // background: "#222",
        }}
      >
        {/* container */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "40px",
          }}
        >
          {/* card */}
          <Box
            sx={{
              position: "relative",
              width: width,
              height: height,
              // background: "#2a2a2a",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* percent */}
            <Box
              sx={{
                position: "relative",
                width: "150px",
                height: "150px",

                "--clr": color ? color : "#04fc43",
                "--num": number,
                "& svg": {
                  position: "relative",
                  width: "150px",
                  height: "150px",
                  transform: "rotate(270deg)",
                },
                "& circle": {
                  width: "100%",
                  height: "100%",
                  fill: "transparent",
                  strokeWidth: 7,
                  stroke: "#38385C",
                  transform: "translate(5px,5px)",
                },

                "& circle:nth-child(2)": {
                  stroke: "var(--clr)",
                  strokeDasharray: 440,
                  strokeDashoffset: "calc(440 - ( 440 * var(--num))/100)",
                  opacity: 0,
                  animation: `${fadeIn} 1s linear forwards`,
                  animationDelay: "2.5s",
                },
              }}
            >
              {/* dot */}
              <Box
                sx={{
                  position: "absolute",
                  inset: 5,
                  zIndex: 10,
                  animation: `${animateDot} 2s linear forwards`,
                  //   360deg/100=3.6
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: "-8px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    background: "var(--clr)",
                    boxShadow: "0 0 10px var(--clr), 0 0 30px var(--clr)",
                  },
                }}
              >
                {dot && (
                  <Box
                    sx={{
                      width: "10px",
                      position: "absolute",
                      height: "10px",
                      borderRadius: "50%",
                      background: "white",
                      top: "-3px",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></Box>
                )}
              </Box>

              <svg>
                <circle cx="70" cy="70" r="70"></circle>
                <circle cx="70" cy="70" r="70"></circle>
              </svg>
              {/* number */}
              <Box
                sx={{
                  position: "absolute",
                  inset: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  opacity: 0,
                  animation: `${fadeIn} 1s linear forwards`,
                  animationDelay: "2.5s",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {title}
                  <Typography color={theme.palette.fontColor.alt}>
                    هزار تومان
                  </Typography>
                </Typography>
                <Typography
                  variant="subHeading"
                  color={theme.palette.fontColor.alt}
                >
                  {subTitle}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AnimatedProgressBarWallet;
