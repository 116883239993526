import { DownloadOutlined, FullscreenOutlined } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import SoilChart from "./SoilChart";
const HumiditySoil = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box height="100%">
      <FlexBetween>
        <Typography
          color={main}
          fontSize={theme.typography.smallestTextSecondary}
        >
          رطوبت (درصد)
        </Typography>

        <FlexBetween gap="0.5rem">
          <DownloadOutlined sx={{ cursor: "pointer" }} />
          <FullscreenOutlined sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <FlexBetween
        gap="0.5rem"
        mt="0.5rem"
        sx={{ justifyContent: "flex-start" }}
      >
        <FormControlLabel
          sx={{}}
          value="female"
          control={
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "#2A80F4",
                },
              }}
              checked={true}
            />
          }
          label={
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              mr="0.25rem"
              color={theme.palette.fontColor.main}
            >
              عمق 30
            </Typography>
          }
        />
        <FormControlLabel
          value="female"
          control={
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "#A52FED",
                },
              }}
              checked={true}
            />
          }
          label={
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              mr="0.25rem"
              color={theme.palette.fontColor.main}
            >
              عمق 60
            </Typography>
          }
        />
        <FormControlLabel
          value="female"
          control={
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "#3DCCB2",
                },
              }}
              checked={true}
              // color={"#68EDFF"}
            />
          }
          label={
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              mr="0.25rem"
              color={theme.palette.fontColor.main}
            >
              عمق 90
            </Typography>
          }
        />
      </FlexBetween>
      <SoilChart
        th2={false}
        fillColor={"rgba(255,238,0,0.3)"}
        thv1={50}
        min={0}
        max={100}
        stepSize={50}
      />
    </Box>
  );
};

export default HumiditySoil;
