import React from "react";
import RecordSensors from "./RecordSensors";

import { useMediaQuery } from "@mui/material";
import RecordSensorsMob from "mob/RecordSensorsMob/RecordSensorsMob";

const RecordSensorsIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <RecordSensorsMob /> : <RecordSensors />}</>;
};

export default RecordSensorsIndex;
