import { Box } from "@mui/material";
import { styled } from "@mui/system";

const WidgetWrapper = styled(Box)(({ theme, opc }) => ({
  padding: "1rem 1rem 1.1rem 1.188rem",
  backgroundColor: theme.palette.background.second,
  borderRadius: "0.75rem",
  opacity: opc,
}));
export default WidgetWrapper;
