import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const CustomSelectBoxComfort = ({
  width,
  minWidth = 100,
  height,
  items,
  selectedItem,
  setSelectedItem,
  all = false,
  title,
}) => {
  const handleChange = (event) => {
    setSelectedItem(event.target.value);
  };

  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0, minWidth: minWidth }}>
        <Select
          value={selectedItem}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "8px",
            width,
            height,

            strokeWidth: 0,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          {all && <MenuItem value={title}>{title}</MenuItem>}
          {items?.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </CacheProvider>
  );
};

export default CustomSelectBoxComfort;
