import { MessageOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const DetailsStaffs = ({
  taskDescription,
  comment,
  backupAction,
  startDate,
  endDate,
  isOpen,
}) => {
  const theme = useTheme();

  const subHeading = theme.typography.subHeading;
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box
      width="100%"
      m="1rem 0"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Stack gap="0.5rem" width="25%">
        <Typography fontSize={subHeading} color={alt}>
          شرح وظیفه :
        </Typography>
        <Typography width="25rem" fontSize={subHeading} color={main}>
          {taskDescription}
        </Typography>
      </Stack>
      <Stack width="27%" ml="1rem">
        <Typography fontSize={subHeading} color={alt}>
          توضیحات کارگر :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography width="25rem" fontSize={subHeading} color={main}>
            {comment}
          </Typography>
        </Box>
      </Stack>
      <Stack width="15%">
        <Typography fontSize={subHeading} color={alt}>
          اقدام جایگزین :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography width="25rem" fontSize={subHeading} color={main}>
            {backupAction}
          </Typography>
        </Box>
      </Stack>
      <Stack width="15%">
        <Typography fontSize={subHeading} color={alt}>
          تاریخ شروع :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography
            width="25rem"
            fontSize={subHeading}
            color={main}
            sx={{ direction: "rtl" }}
          >
            {startDate}
          </Typography>
        </Box>
      </Stack>
      <Stack width="15%">
        <Typography fontSize={subHeading} color={alt}>
          تاریخ پایان :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography width="25rem" fontSize={subHeading} color={main}>
            {endDate}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default DetailsStaffs;
