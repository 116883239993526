import { DownloadOutlined, FullscreenOutlined } from "@mui/icons-material";
import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import PlantChart from "./PlantChart";
const FruitDiameterPlant = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box>
      <FlexBetween>
        <Typography
          color={main}
          fontSize={theme.typography.smallestTextSecondary}
        >
          قطر میوه (سانتی متر)
        </Typography>

        <FlexBetween gap="0.5rem">
          <DownloadOutlined sx={{ cursor: "pointer" }} />
          <FullscreenOutlined sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <FlexBetween
        gap="0.5rem"
        mt="0.5rem"
        sx={{ justifyContent: "flex-start" }}
      >
        <FormControlLabel
          sx={{}}
          value="female"
          control={
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "#2A80F4",
                },
              }}
              checked={true}
            />
          }
          label={
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              mr="0.25rem"
              color={theme.palette.fontColor.main}
            >
              روند رشد میوه
            </Typography>
          }
        />
        <FormControlLabel
          value="female"
          control={
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color: "#5FBD15",
                },
              }}
              checked={true}
            />
          }
          label={
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              mr="0.25rem"
              color={theme.palette.fontColor.main}
            >
              شاخص نرمال
            </Typography>
          }
        />
      </FlexBetween>
      <PlantChart th1={false} th2={false} min={0} max={12} stepSize={6} />
    </Box>
  );
};

export default FruitDiameterPlant;
