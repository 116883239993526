import { MoreHoriz, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import { useState } from "react";
import { setId } from "States/sensors";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/table/Table";

const getBatteryStatus = (status) => {
  switch (status) {
    case "LOWEST":
      return EmptyIcon;
    case "LOW":
      return Full3Icon;
    case "HIGH":
      return FullIcon;
    default:
      break;
  }
};
const getSignalStatus = (status) => {
  switch (status) {
    case "LOWEST":
      return WifiIcon;
    case "LOW":
      return Wifi1Icon;
    case "HIGH":
      return Wifi3Icon;
    default:
      break;
  }
};
const SoilSensorsList = ({ sensors }) => {
  const selectedSensor = useSelector((state) => state.sensors.id);
  const dispatch = useDispatch();
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const tableData = sensors?.map((row) => {
    return {
      id: row.id,
      data: {
        سنسور: row.title,
        وضعیت: (
          <Box>
            <Radio
              sx={{
                width: "12px",
                height: "12px",
                "&, &.Mui-checked": {
                  color:
                    row.status === "ACTIVE"
                      ? theme.palette.others.third
                      : theme.palette.others.second,
                },
              }}
              checked={true}
            />
          </Box>
        ),
        باتری: (
          <Box component={"img"} src={getBatteryStatus(row.batteryStatus)} />
        ),
        سیگنال: (
          <Box component={"img"} src={getSignalStatus(row.signalStatus)} />
        ),
        توضیحات: (
          <IconButton>
            <MoreHoriz />
          </IconButton>
        ),
      },
    };
  });
  return (
    <Box height={"100%"}>
      <FlexBetween>
        <Typography variant="h5" mb="0.1rem" color={main}>
          سنسورهای خاک
        </Typography>
        <FlexBetween>
          <FlexBetween
            sx={{
              // display: open ? "inline-flex" : "none",
              transition: "all 0.2s ease",
              ml: "1.5rem",
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              // border: "1px solid black",
              borderRadius: "8px",
              mt: "0.25rem",
            }}
          >
            <InputBase
              disableUnderline
              placeholder="جست و جو"
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${theme.palette.fontColor.main} !important`,
                  },
                },
                height: "27px",
                width: "80px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <MoreHoriz sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <Typography fontSize={theme.typography.smallestTextSecondary} color={alt}>
        زمانبندی ارسال داده: 6 ساعته
      </Typography>
      <Box
        style={{
          maxHeight: "calc(100% - 57px)",
          width: "100%",
          display: "flex",
          marginTop: "6px",
        }}
      >
        <Table
          handleClickRow={(row) => {
            dispatch(setId({ id: row.id }));
          }}
          selectedRow={selectedSensor}
          data={tableData}
        />
      </Box>
    </Box>
  );
};

export default SoilSensorsList;
