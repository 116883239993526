export const AllLivestockData = {
    type: "کل دام",
    performance: [
      ["05/12", 32],
      ["05/13", 27],
      ["05/14", 28],
      ["05/15", 30],
      ["05/16", 29],
      ["05/17", 31],
      ["05/18", 32],
      ["05/19", 29],
    ],
    status: [70, null],
    livestock: [
      {
        code: 900,
        birthday: "1401/08/10 - 20:30",
        fatherType: "اسپرم",
        oMother: "",
        uMother: "",
        blood: "",
        milk: 9,
        race: "هلشتاین",
        pacifier: "",
        gender: "ماده",
      },
      {
        code: 899,
        birthday: "1401/08/9 - 10:00",
        fatherType: "اسپرم",
        oMother: "",
        uMother: "",
        blood: "",
        milk: 8,
        race: "آبرشایر",
        pacifier: "",
        gender: "نر",
      },
      {
        code: 898,
        birthday: "1401/08/7 - 23:30",
        fatherType: "اسپرم",
        oMother: "",
        uMother: "",
        blood: "",
        milk: 7,
        race: "گرنزی",
        pacifier: "",
        gender: "ماده",
      },
      {
        code: 897,
        birthday: "1401/08/2 - 17:42",
        fatherType: "اسپرم",
        oMother: "",
        uMother: "",
        blood: "",
        milk: 7,
        race: "جرسی",
        pacifier: "",
        gender: "ماده",
      },
    ],
  };
  