import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const ServicesTable = ({ servicesData }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box style={{ height: "480px", width: "100%" }}>
      <Box display="flex" mt={"2rem"}>
        <Box
          width="25%"
          height="90%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography
            mr="2rem"
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            سرویس
          </Typography>
        </Box>
        <Box
          width="25%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize={theme.typography.subHeading}
            color={theme.palette.fontColor.alt}
          >
            مدت زمان
          </Typography>
        </Box>
        <Box
          width="25%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <FlexBetween>
            <Typography
              fontSize={theme.typography.subHeading}
              color={theme.palette.fontColor.alt}
            >
              وضعیت
            </Typography>
          </FlexBetween>
        </Box>
      </Box>

      <Divider sx={{ marginTop: "10px" }} />
      <Box
        sx={{
          height: "85%",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: 5,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        {servicesData.map((item) => (
          <>
            <Box display="flex" m="1rem 0">
              <Box
                width="25%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                gap="0.25rem"
              >
                <Typography
                  mr="1rem"
                  color={main}
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  {item.title}
                </Typography>
              </Box>
              <Box
                width="25%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  color={main}
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  (1ماهه، 6 ماهه ، یک ساله)
                </Typography>
              </Box>
              <Box
                width="25%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width="77px"
                  height="23px"
                  borderRadius="8px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: item.active
                      ? theme.palette.others.third
                      : "transparent",
                  }}
                >
                  <Typography
                    color={item.active ? theme.palette.background.second : main}
                    fontSize={theme.typography.smallestTextPrimary}
                  >
                    {item.active ? "فعال" : "_"}
                  </Typography>
                </Box>
              </Box>
              <Box
                width="25%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  width="135px"
                  height="32px"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ backgroundColor: theme.palette.others.first }}
                >
                  <Typography variant="subHeading" color={main}>
                    {item.active ? "تمدید سرویس" : " خرید سرویس"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};

export default ServicesTable;
