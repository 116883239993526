import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import {
  Box,
  Button,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import googleIcon from "../../assets/google.png";
import rtlPlugin from "stylis-plugin-rtl";
import jwt_decode from "jwt-decode";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween/FlexBetween";
import axios from "axios";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  width: "400px",
  "& .MuiFormLabel-root": {
    marginLeft: "-5px",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});

const FormReset = ({ emailSignInStart, googleSignInStart }) => {
  const handleLoginClick = () => {
    const username = "nima";
    const password = "nima";
    const client_id = "user-panel";
    const client_secret = "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV";
    const grant_type = "password";
    const url = "https://api.rahbaan.ir/v1/authentication/token";

    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_id", client_id);
    formData.append("client_secret", client_secret);
    formData.append("grant_type", grant_type);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error(error));
  };

  const [token, setToken] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setToken(tokenResponse);
      // navigate("/");
      // checkToken(tokenResponse);
      console.log(
        "🚀 ~ file: Form.jsx:56 ~ Form ~ tokenResponse",
        tokenResponse
      );
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  const checkToken = (token) => {
    const t = jwt_decode(token);
    console.log("🚀 ~ file: Form.jsx:55 ~ Form ~ tokenResponse", t);
  };
  useEffect(() => {
    console.log("login");
  }, [token]);

  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  // const { email, password } = userCredentials;
  const handleSubmit = async (e) => {
    e.preventDefault();

    // emailSignInStart(email, password);
  };
  const navigate = useNavigate();
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        sx={{
          width: "400px",

          display: "flex",
          flexDirection: "column",
          direction: "ltr",
          mt: "2rem",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            width="400px"
            sx={{ height: "100%" }}
          >
            <Typography color="#F7F7F7" fontSize="40px" textAlign="center">
              فراموشی رمز
            </Typography>
            <CssTextField
              label="شماره موبایل/ایمیل خود را وارد کنید"
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                },
              }}
            />
          </Stack>

          <Button
            variant="contained"
            sx={{
              width: "400px",
              height: "48px",
              mt: "1.5rem",
              fontSize: "20px",
              borderRadius: "8px",
              boxShadow: "none",
            }}
            onClick={() => handleLoginClick()}
          >
            ارسال کد
          </Button>

          <Typography color="#F7F7F7" mt="0.5rem" fontSize="13px">
            در صورت دسترسی نداشتن به شماره موبایل و یا ایمیل خود با{" "}
            <span style={{ width: "40px", borderBottom: "1px solid white" }}>
              پشتیبانی
            </span>{" "}
            تماس بگیرید
          </Typography>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default FormReset;
