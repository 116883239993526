import { useMediaQuery } from "@mui/material";
import RecordEventsMob from "mob/RecordEventsMob/RecordEventsMob";
import React from "react";
import StaffMgmt from "scenes/StaffMgmt/StaffMgmt";
import RecordEvents from "./RecordEvents";
const RecordEventsIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <RecordEventsMob /> : <RecordEvents />}</>;
};
export default RecordEventsIndex;
