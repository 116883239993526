import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const ActuatorSelectBox = () => {
  const theme = useTheme();
  const [age, setAge] = React.useState(10);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0, minWidth: 104, height: "27px" }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            border: "1px solid white",
            borderRadius: "8px",
            "& .MuiSelect-select": {
              p: "3.5px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          <MenuItem value={10}>
            <Box display="flex" m="0 0.5rem">
              <Typography
                variant="smallestTextSecondary"
                color={theme.palette.fontColor.main}
              >
                زمان تهویه
              </Typography>
            </Box>
          </MenuItem>
        </Select>
      </FormControl>
    </CacheProvider>
  );
};

export default ActuatorSelectBox;
