export const HeaalthStatusData2 = {
    type: "وضعیت سلامت",
    performance: [
      ["05/12", 710],
      ["05/13", 842],
      ["05/14", 826],
      ["05/15", 713],
      ["05/16", 619],
      ["05/17", 700],
      ["05/18", 721],
      ["05/19", 723],
    ],
    status: [[723, 1750], "دام های سالم"],
  
    livestock: [
      {
        code: 465,
        status: "آبستن",
        index: 90,
        ln: true,
        age: "3 سال و 3 ماه",
        milk: 0,
        indexT: 1.8,
      },
      {
        code: 387,
        status: "آبستن",
        index: 68,
        ln: true,
        age: "6 سال و 2 ماه",
        milk: 1,
        indexT: 3,
      },
      {
        code: 113,
        status: "آبستن",
        index: 81,
        ln: false,
        age: "2 سال و 6 ماه",
        milk: 5,
        indexT: 2.3,
      },
      {
        code: 739,
        status: "آبستن",
        index: 70,
        ln: true,
        age: "5 سال و 8 ماه",
        milk: 3,
        indexT: 3,
      },
    ],
  };