import { styled, useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import FlexBetween from "components/FlexBetween/FlexBetween";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import SensorsManagementAll from "scenes/SensorsManagementAll/SensorsManagementAll";

import { getSensorData } from "api/sensorManagement";
import CustomRangeDatePicker from "components/CustomRangeDatePicker/CustomRangeDatePicker";
import WithSpinner from "components/With-Spinner/With-Spinner.component";
import { SENSOR_MANAGEMENT_DEFAULT_PERIOD, TODAY } from "constants/constant";
import { useFetchSensorData } from "hooks/useFetchData";
import jalaali from "jalaali-js";
import AirSensorMob from "mob/scenesMob/AirSensorMob/AirSensorMob";
import PlantSensorMob from "mob/scenesMob/PlantSensorMob/PlantSensorMob";
import SensorManagementAllMob from "mob/scenesMob/SensorManagementAllMob/SensorManagementAllMob";
import SoilSensorMob from "mob/scenesMob/SoilSensorMob/SoilSensorMob";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AirSensor from "scenes/AirSensor/AirSensor";
import PlantSensor from "scenes/PlantSensor/PlantSensor";
import SoilSensor from "scenes/SoilSensor/SoilSensor";
import { setSelectedItemMob, setSensorIndex } from "States";
import {
  setDate1,
  setDate2,
  setFirstDate,
  setLastTimeUpdate,
  setSecondDate,
  setSensorsData,
} from "States/sensors";
import { strQueryParams } from "Utils/stringUtils";

const SensorsManagementAllSpinner = WithSpinner(SensorsManagementAll);
const SensorManagementAllMobSpinner = WithSpinner(SensorManagementAllMob);
const AirSensorSpinner = WithSpinner(AirSensor);
const SoilSensorSpinner = WithSpinner(SoilSensor);
const PlantSensorSpinner = WithSpinner(PlantSensor);

function shamsiToGregorian(shamsiDate) {
  const [shamsiYear, shamsiMonth, shamsiDay] = shamsiDate
    .split("-")
    .map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(
    shamsiYear,
    shamsiMonth,
    shamsiDay
  );
  return `${gy}-${gm.toString().padStart(2, "0")}-${gd
    .toString()
    .padStart(2, "0")}`;
}
const TabSmall = styled(Tab)(({ Colors, mode }) => ({
  fontSize: "12px",
  // color: `grey`,

  backgroundColor: "inherit",
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SensorManagement() {
  const datePickerRef = useRef();
  const [selectedRange, setSelectedRange] = useState([
    new Date(SENSOR_MANAGEMENT_DEFAULT_PERIOD),
    new Date(TODAY),
  ]);
  const firstDate = useSelector((state) => state.sensors.firstDate);
  const secondDate = useSelector((state) => state.sensors.secondDate);
  const dispatch = useDispatch();
  const theme = useTheme();
  const index = useSelector((state) => state.global.sensorIndex);
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  const AirSensorMobSpinner = WithSpinner(AirSensorMob);
  const SoilSensorMobSpinner = WithSpinner(SoilSensorMob);
  const PlantSensorMobSpinner = WithSpinner(PlantSensorMob);

  const [value, setValue] = React.useState(index);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setSensorIndex({ sensorIndex: newValue }));
  };
  const { response: sensors, is_loading } = useFetchSensorData(
    getSensorData,
    strQueryParams({
      start: `${firstDate}T00:00:00%2B03:30`,
      end: `${secondDate}T00:00:00%2B03:30`,
    })
  );
  console.log(
    "🚀 ~ file: SensorManagement.jsx:101 ~ SensorManagement ~ response:",
    sensors
  );
  const handleDateChange = (value) => {
    const gregorian = value?.map((date) =>
      shamsiToGregorian(`${date.year}-${date.month}-${date.day}`)
    );
    setSelectedRange(value);
    if (value.length === 2) {
      dispatch(
        setFirstDate({
          firstDate: gregorian[0],
        })
      );

      dispatch(
        setDate1({
          date1: {
            year: value[0].year,
            month: value[0].month.number,
            day: value[0].day,
          },
        })
      );
      dispatch(
        setSecondDate({
          secondDate: gregorian[1],
        })
      );
      dispatch(
        setDate2({
          date2: {
            year: value[1].year,
            month: value[1].month.number,
            day: value[1].day,
          },
        })
      );
      setTimeout(() => {
        datePickerRef.current.closeCalendar();
      }, 1000);
    }
  };
  useEffect(() => {
    const sensorsData = sensors?.data;
    if (sensorsData) {
      dispatch(
        setSensorsData({
          sensorsData,
        })
      );

      const now = new Date();
      const time = now.toLocaleTimeString("fa-IR");

      const date = now.toLocaleDateString("fa-IR");

      dispatch(setLastTimeUpdate({ lastTimeUpdate: `${time} - ${date}` }));
    }
  }, [sensors]);

  dispatch(
    setSelectedItemMob({
      selectedItemMob: 2,
    })
  );
  return (
    <>
      {isMobileScreens ? (
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CustomRangeDatePicker
              selectedRange={selectedRange}
              handleDateChange={handleDateChange}
            />
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              scrollButtons="auto"
              indicatorColor="#1c1e2a"
              sx={{
                borderBottom: "none",
                m: "0 5px",
                mt: "2.5rem",
                "& .MuiTab-textColorPrimary": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
                "& .MuiButtonBase-root": {
                  padding: "0px 0px !important",
                },
                "& 	.MuiTabs-root": {
                  // borderBottom: "red !important",
                },

                "& .Mui-selected": {
                  //   backgroundColor: "inherit !important",
                  borderBottom: `3px solid ${theme.palette.others.first}`,

                  color: "#757578",
                },
              }}
            >
              <TabSmall label="همه سنسورها" {...a11yProps(0)} />
              <TabSmall label="سنسور هوا" {...a11yProps(1)} />
              <TabSmall label="سنسور خاک" {...a11yProps(2)} />
              <TabSmall label="سنسور گیاه" {...a11yProps(3)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <SensorManagementAllMobSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AirSensorMobSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SoilSensorMobSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PlantSensorMobSpinner isLoading={is_loading} />
          </TabPanel>
        </Box>
      ) : (
        <Box
          sx={{
            width: "95vw",
            height: "6vh",
            m: "0.5rem 0.5rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: 0,
              borderColor: "divider",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              scrollButtons="auto"
              indicatorColor="#1c1e2a"
              sx={{
                borderBottom: "none",
                "& .MuiTab-textColorPrimary": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
                "& .MuiButtonBase-root": {
                  padding: "0px 0px !important",
                },
                "& 	.MuiTabs-root": {
                  // borderBottom: "red !important",
                },

                "& .Mui-selected": {
                  //   backgroundColor: "inherit !important",
                  borderBottom: `3px solid ${theme.palette.others.first}`,

                  color: "#757578",
                },
              }}
            >
              <TabSmall label="همه سنسورها" {...a11yProps(0)} />
              <TabSmall label="سنسور هوا" {...a11yProps(1)} />
              <TabSmall label="سنسور خاک" {...a11yProps(2)} />
              <TabSmall label="سنسور گیاه" {...a11yProps(3)} />
            </Tabs>
            <FlexBetween ml="2.3rem" gap="1rem">
              <CustomRangeDatePicker
                datePickerRef={datePickerRef}
                selectedRange={selectedRange}
                handleDateChange={handleDateChange}
              />
            </FlexBetween>
          </Box>
          <TabPanel value={value} index={0}>
            <SensorsManagementAllSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AirSensorSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SoilSensorSpinner isLoading={is_loading} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PlantSensorSpinner isLoading={is_loading} />
          </TabPanel>
        </Box>
      )}
    </>
  );
}
