import {
  AttachFileOutlined,
  EmojiEmotionsOutlined,
  MicNoneOutlined,
  MoreHoriz,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import ContactItem from "components/ContactItem/ContactItem";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { v4 as uuidv4 } from "uuid";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeaderMember from "./HeaderMember";
import ContentMessenger from "./ContentMessenger";
import { setMessenger } from "States/messenger";
import CopyRight from "widgets/CopyRight";

const Messenger = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [searchValue, setSearchValue] = useState("");
  const [message, setMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  console.log("🚀 ~ file: Messenger.jsx:32 ~ Messenger ~ message:", message);

  const messenger = useSelector((state) => state.messenger.messenger);
  console.log(
    "🚀 ~ file: Messenger.jsx:40 ~ Messenger ~ messenger:",
    messenger
  );
  const selectedMember = useSelector((state) => state.messenger.selectedMember);
  const filteredMessenger = messenger.filter((item) =>
    searchValue !== "" ? item.name.includes(searchValue) : item
  );
  useEffect(() => {}, [messenger]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="100%">
        <Box
          padding="1rem 1rem 1.1rem 0"
          width="30.2%"
          height="99%"
          borderRadius="8px"
          sx={{
            backgroundColor: theme.palette.background.second,
            position: "relative",
          }}
        >
          <Box
            width="48px"
            height="48px"
            borderRadius="50%"
            position="absolute"
            sx={{
              backgroundColor: theme.palette.others.first,
              bottom: "10px",
              left: "10px",
              cursor: "pointer",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.8398 2.05156H8.83984C4.83984 2.05156 2.83984 4.05156 2.83984 8.05156V21.0516C2.83984 21.6016 3.28984 22.0516 3.83984 22.0516H16.8398C20.8398 22.0516 22.8398 20.0516 22.8398 16.0516V8.05156C22.8398 4.05156 20.8398 2.05156 16.8398 2.05156Z"
                stroke="#F7F7F7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33984 12.0516H16.3398"
                stroke="#F7F7F7"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.8398 15.5516V8.55156"
                stroke="#F7F7F7"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Box>
          <FlexBetween pl="1.188rem">
            <Typography variant="h3" color={main}>
              پیام رسان
            </Typography>
            <Box
              width="119px"
              height="32px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ backgroundColor: theme.palette.others.first }}
            >
              <FlexBetween gap="0.5rem">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00065 14.6666H10.0007C13.334 14.6666 14.6673 13.3333 14.6673 9.99998V5.99998C14.6673 2.66665 13.334 1.33331 10.0007 1.33331H6.00065C2.66732 1.33331 1.33398 2.66665 1.33398 5.99998V9.99998C1.33398 13.3333 2.66732 14.6666 6.00065 14.6666Z"
                    stroke="#F7F7F7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66602 1.33331V14.6666"
                    stroke="#F7F7F7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.66602 8H14.666"
                    stroke="#F7F7F7"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Typography variant="subHeading" color={main}>
                  همسایه ها
                </Typography>
              </FlexBetween>
            </Box>
          </FlexBetween>
          <FlexBetween
            sx={{
              // display: open ? "inline-flex" : "none",
              transition: "all 0.2s ease",
              ml: "1.118rem",
              mt: "1rem",
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.main}`,
              // border: "1px solid black",
              borderRadius: "8px",
            }}
          >
            <Search sx={{ mr: "0.2rem" }} />
            <InputBase
              disableUnderline
              placeholder="جست و جو"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",

                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${theme.palette.fontColor.main} !important`,
                  },
                },
                height: "33px",
                width: "100%",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
          </FlexBetween>
          <Box
            width="100%"
            height="85%"
            mt="1rem"
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            {filteredMessenger.map((item, index) => (
              <ContactItem key={index} item={item} />
            ))}
          </Box>
        </Box>
        <WidgetWrapper
          width="69%"
          height={"99%"}
          borderRadius={"8px"}
          sx={{ backgroundColor: theme.palette.background.second }}
        >
          <Box
            width="100%"
            height="73px"
            borderBottom={`1px solid ${theme.palette.background.third}`}
          >
            <HeaderMember />
          </Box>
          <Box width="100%" height="79%">
            <ContentMessenger />
          </Box>
          <Box width="100%" height="10%" display="flex">
            <Box
              width="100%"
              height="85%"
              display="flex"
              sx={{
                backgroundColor: theme.palette.background.third,
                borderRadius: "8px",
                p: "0.25rem 0.5rem",
              }}
              alignSelf="flex-end"
            >
              <Box
                width="92%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton>
                  <AttachFileOutlined />
                </IconButton>
                <FormControl sx={{ width: "95%" }}>
                  <InputBase
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="پیام جدید"
                    sx={{ width: "100%" }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        setMessage(event.target.value);
                        setInputValue("");
                      }
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                width="5%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton>
                  <EmojiEmotionsOutlined />
                </IconButton>
                <IconButton>
                  <MicNoneOutlined />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </WidgetWrapper>
      </Box>

      <CopyRight />
    </Box>
  );
};

export default Messenger;
