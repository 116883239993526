import { Instagram, Telegram, YouTube } from "@mui/icons-material";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Form from "components/Form/Form";
import React, { useEffect, useState } from "react";
import LoginPic from "../../assets/Register.png";
import Logo from "../../assets/LogoLogin.png";
import InstagramIcon from "../../assets/instagram.svg";
import YoutubeIcon from "../../assets/youtube.svg";
import TelegramIcon from "../../assets/ph_telegram-logo-light.svg";
import FormRegisterFarmer from "components/Form/FormRegisterFarmer";
import RegisterMob from "mob/scenesMob/RegisterMob/RegisterMob";
import FormRegisterExpert from "components/Form/FormRegisterExpert";
import FormRegisterEdu from "components/Form/FormRegisterEdu";
const Register = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  const [selectedUser, setSelectedUser] = useState("");
  console.log(
    "🚀 ~ file: Register.jsx:16 ~ Register ~ selectedUser:",
    selectedUser
  );
  const theme = useTheme();
  return (
    <>
      {isMobileScreens ? (
        <Box
          sx={{
            height: "100%",
            overFlowY: "scroll",
            flexGrow: 1,
            overflowY: "auto",
          }}
        >
          <RegisterMob />
        </Box>
      ) : selectedUser === "" ? (
        <FlexBetween
          sx={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ position: "absolute", width: "100%", height: "100%" }}
            component="img"
            src={LoginPic}
          />
          <Box position="absolute" zIndex="1000" sx={{ bottom: "30px" }}>
            <Typography color="#FAFAFC" fontSize="14px" mt="5rem" ml="2.5rem">
              کلیه حقوق این سامانه متعلق به شرکت پرتوتاپ رایان است
            </Typography>
          </Box>
          <Box
            sx={{
              width: "36.375rem",
              height: "32.5rem",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography fontSize="40px" color="#F7F7F7" fontWeight="500">
              سامانه مدیریت هوشمند رهبان
            </Typography>
            <Typography fontSize="16px" color="#F7F7F7" mt="8px">
              بهتر است نبض مزرعه تان در دست خودتان باشد
            </Typography>
            <Box display="flex" gap="10px" mt="37px">
              <Box
                sx={{
                  width: "187px",
                  height: "44px",
                  background: "rgba(248, 248, 252, 0.30)",
                  backdropFilter: "blur(7.5px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                  ":hover": {
                    background: theme.palette.fontColor.main,
                    color: theme.palette.others.first,
                  },
                }}
                onClick={() => setSelectedUser("کشاورز")}
              >
                <Typography variant="regular">کشاورز</Typography>
              </Box>
              <Box
                sx={{
                  width: "187px",
                  height: "44px",
                  background: "rgba(248, 248, 252, 0.30)",
                  backdropFilter: "blur(7.5px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                  ":hover": {
                    background: theme.palette.fontColor.main,
                    color: theme.palette.others.first,
                  },
                }}
                onClick={() => setSelectedUser("کارشناس")}
              >
                <Typography variant="regular">کارشناس</Typography>
              </Box>
              <Box
                sx={{
                  width: "187px",
                  height: "44px",
                  background: "rgba(248, 248, 252, 0.30)",
                  backdropFilter: "blur(7.5px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  cursor: "pointer",
                  ":hover": {
                    background: theme.palette.fontColor.main,
                    color: theme.palette.others.first,
                  },
                }}
                onClick={() => setSelectedUser("محقق دانشگاهی")}
              >
                <Typography variant="regular">محقق دانشگاهی</Typography>
              </Box>
            </Box>
          </Box>
        </FlexBetween>
      ) : (
        <FlexBetween
          sx={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ position: "absolute", width: "100%", height: "100%" }}
            component="img"
            src={LoginPic}
          />
          <Box
            sx={{
              width: "1200px",
              height: "700px",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "36.375rem",
                height: "39.375rem",

                backgroundColor: "rgba(248, 248, 252, 0.3)",
                backdropFilter: "blur(7.5px)",
                borderRadius: "24px",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {selectedUser === "کشاورز" ? (
                <FormRegisterFarmer />
              ) : selectedUser === "کارشناس" ? (
                <FormRegisterExpert />
              ) : (
                <FormRegisterEdu />
              )}
            </Box>
            <Box
              sx={{
                width: "36.375rem",
                height: "32.5rem",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography fontSize="40px" color="#F7F7F7" fontWeight="500">
                سامانه مدیریت هوشمند رهبان
              </Typography>
              <Typography fontSize="16px" color="#F7F7F7" mt="8px">
                بهتر است نبض مزرعه تان در دست خودتان باشد
              </Typography>
              <Box display="flex" gap="10px" mt="37px">
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#697c63",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{ width: "32px", height: "32px" }}
                    component="img"
                    src={TelegramIcon}
                  />
                </Box>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#697c63",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{ width: "32px", height: "32px" }}
                    component="img"
                    src={YoutubeIcon}
                  />
                </Box>
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: "#697c63",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{ width: "32px", height: "32px" }}
                    component="img"
                    src={InstagramIcon}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box position="absolute" zIndex="1000" sx={{ bottom: "30px" }}>
            <Typography color="#FAFAFC" fontSize="14px" mt="5rem" ml="2.5rem">
              کلیه حقوق این سامانه متعلق به شرکت پرتوتاپ رایان است
            </Typography>
          </Box>
        </FlexBetween>
      )}
    </>
  );
};

export default Register;
