import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  List,
  Radio,
  Slider,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";

import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import TimerIcon from "../assets/timer-pause.png";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(Box)(({ color }) => ({
  backgroundColor: color,
  borderRadius: "50%",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
}));

function BpRadio({ color }) {
  return (
    <Radio
      checked
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon color={color} />}
      icon={<BpIcon />}
    />
  );
}
const EquipmentControl = () => {
  const marks = [
    {
      value: 75,
      label: "1:45",
    },
    {
      value: 100,
      label: "2:00",
    },
  ];
  function valuetext(value) {
    return `${value}h`;
  }
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const eqData = useSelector((state) => state.dashboard.actuators);

  const navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {/* {!eqData.enabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,

            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="88px"
            height="27px"
            sx={{
              backgroundColor: theme.palette.others.first,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              فعال سازی
            </Typography>
          </Box>
        </Box>
      )} */}
      {/* <Box sx={{ opacity: eqData.enabled ? 1 : 0.1 }}> */}
      <Box>
        <FlexBetween mb="0.5rem">
          <Typography variant="h5">کنترل تجهیزات</Typography>

          <MoreHoriz
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/actuator")}
          />
        </FlexBetween>
        <Box
          height="6.2rem"
          sx={{
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 9,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.background.third,
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <FlexBetween
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
            mb="0.5rem"
          >
            <FlexBetween>
              {open ? <ExpandLess /> : <ExpandMore />}
              <Typography variant="body" color="fontColor.main">
                روشنایی 2
              </Typography>
            </FlexBetween>

            <Box
              width="16px"
              height="16px"
              ml="0.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="4px"
              sx={{ backgroundColor: theme.palette.background.forth }}
            >
              <svg
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.44444 0.5H3.55556V4.94444H4.44444V0.5ZM6.59111 1.46444L5.96 2.09556C6.66222 2.66 7.11111 3.52667 7.11111 4.5C7.11111 6.22 5.72 7.61111 4 7.61111C2.28 7.61111 0.888889 6.22 0.888889 4.5C0.888889 3.52667 1.33778 2.66 2.03556 2.09111L1.40889 1.46444C0.546667 2.19778 0 3.28222 0 4.5C0 6.70889 1.79111 8.5 4 8.5C6.20889 8.5 8 6.70889 8 4.5C8 3.28222 7.45333 2.19778 6.59111 1.46444Z"
                  fill="#F7F7F7"
                />
              </svg>
            </Box>
          </FlexBetween>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <FlexBetween>
              <Box component="img" src={TimerIcon} mr="0.75rem" mt="-1rem" />
              <Slider
                aria-label="Custom marks"
                defaultValue={75}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay="auto"
                marks={marks}
                sx={{
                  ml: "1rem",

                  width: "150px",
                  "& 		.MuiSlider-rail": {
                    color: theme.palette.background.third,
                  },
                  "& 	.MuiSlider-track": {
                    background:
                      "linear-gradient(90deg, rgba(235,238,242,1) 0%, rgba(42,128,244,1) 69%)",
                  },
                  "& 	.MuiSlider-thumb": {
                    color: theme.palette.others.first,
                    boxShadow: "2px 2px 20px 1px #01F",
                  },
                }}
                min={10}
                max={110}
                disabled
              />
            </FlexBetween>
          </Collapse>

          <FlexBetween
            onClick={handleClick}
            sx={{ cursor: "pointer" }}
            mb="0.5rem"
          >
            <FlexBetween>
              {open ? <ExpandLess /> : <ExpandMore />}
              <Typography variant="body" color="fontColor.main">
                تهویه 1
              </Typography>
            </FlexBetween>
            <Box
              width="16px"
              height="16px"
              ml="0.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="4px"
              sx={{ backgroundColor: theme.palette.others.third }}
            >
              <svg
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.44444 0.5H3.55556V4.94444H4.44444V0.5ZM6.59111 1.46444L5.96 2.09556C6.66222 2.66 7.11111 3.52667 7.11111 4.5C7.11111 6.22 5.72 7.61111 4 7.61111C2.28 7.61111 0.888889 6.22 0.888889 4.5C0.888889 3.52667 1.33778 2.66 2.03556 2.09111L1.40889 1.46444C0.546667 2.19778 0 3.28222 0 4.5C0 6.70889 1.79111 8.5 4 8.5C6.20889 8.5 8 6.70889 8 4.5C8 3.28222 7.45333 2.19778 6.59111 1.46444Z"
                  fill="#F7F7F7"
                />
              </svg>
            </Box>
          </FlexBetween>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <FlexBetween>
              <Box component="img" src={TimerIcon} mr="0.75rem" mt="-1rem" />
              <Slider
                aria-label="Custom marks"
                defaultValue={75}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay="auto"
                marks={marks}
                sx={{
                  ml: "1rem",

                  width: "150px",
                  "& 		.MuiSlider-rail": {
                    color: theme.palette.background.third,
                  },
                  "& 	.MuiSlider-track": {
                    background:
                      "linear-gradient(90deg, rgba(235,238,242,1) 0%, rgba(42,128,244,1) 69%)",
                  },
                  "& 	.MuiSlider-thumb": {
                    color: theme.palette.others.first,
                    boxShadow: "2px 2px 20px 1px #01F",
                  },
                }}
                min={10}
                max={110}
                disabled
              />
            </FlexBetween>
          </Collapse>
          <FlexBetween onClick={handleClick} sx={{ cursor: "pointer" }}>
            <FlexBetween>
              {open ? <ExpandLess /> : <ExpandMore />}
              <Typography variant="body" color="fontColor.main">
                روشنایی 1
              </Typography>
            </FlexBetween>
            <Box
              width="16px"
              height="16px"
              ml="0.5rem"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="4px"
              sx={{ backgroundColor: theme.palette.background.forth }}
            >
              <svg
                width="8"
                height="9"
                viewBox="0 0 8 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.44444 0.5H3.55556V4.94444H4.44444V0.5ZM6.59111 1.46444L5.96 2.09556C6.66222 2.66 7.11111 3.52667 7.11111 4.5C7.11111 6.22 5.72 7.61111 4 7.61111C2.28 7.61111 0.888889 6.22 0.888889 4.5C0.888889 3.52667 1.33778 2.66 2.03556 2.09111L1.40889 1.46444C0.546667 2.19778 0 3.28222 0 4.5C0 6.70889 1.79111 8.5 4 8.5C6.20889 8.5 8 6.70889 8 4.5C8 3.28222 7.45333 2.19778 6.59111 1.46444Z"
                  fill="#F7F7F7"
                />
              </svg>
            </Box>
          </FlexBetween>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <FlexBetween>
              <Box component="img" src={TimerIcon} mr="0.75rem" mt="-1rem" />
              <Slider
                aria-label="Custom marks"
                defaultValue={75}
                getAriaValueText={valuetext}
                step={10}
                valueLabelDisplay="auto"
                marks={marks}
                sx={{
                  ml: "1rem",

                  width: "150px",
                  "& 		.MuiSlider-rail": {
                    color: theme.palette.background.third,
                  },
                  "& 	.MuiSlider-track": {
                    background:
                      "linear-gradient(90deg, rgba(235,238,242,1) 0%, rgba(42,128,244,1) 69%)",
                  },
                  "& 	.MuiSlider-thumb": {
                    color: theme.palette.others.first,
                    boxShadow: "2px 2px 20px 1px #01F",
                  },
                }}
                min={10}
                max={110}
                disabled
              />
            </FlexBetween>
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default EquipmentControl;
