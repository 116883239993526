import {
  Box,
  Collapse,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import FlexBetween from "components/FlexBetween/FlexBetween";
import { FilterAltOutlined, MoreHoriz, Search } from "@mui/icons-material";
import WidgetWrapper from "components/WidgetWrapper";
import CenterStack from "components/styledComponents/CenterStack";
import { AlarmsData, CollarData } from "Mock/AlarmsData";
import EditIcon from "../../assets/Icons/edit.png";
import ArrowIcon from "../../assets/arrow.png";
import RefreshIcon from "../../assets/Refresh.png";
import HStack from "components/styledComponents/HStack";
import AlarmsMapMob from "mob/componentsMob/AlarmsMapMob/AlarmsMapMob";
// import SpeedometerMob from "./Speedometer";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import Weather from "widgets/Weather";
import WeatherDaily from "components/WeatherDaily/WeatherDaily";
import { recommendData } from "Mock/RecommendsData";
import SpeedometerMob from "mob/scenesMob/AlarmsManagementMob/Speedometer";
const convertAlarmName = (priority) => {
  switch (priority) {
    case "High":
      return "بحرانی";
    case "Highest":
      return "خیلی بحرانی";

    default:
      break;
  }
};
const RecommendsMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const [selectValue, setSelectValue] = useState(0);
  const [selectItem, setSelectItem] = useState("تاریخ ارسال");
  const [toggle, setToggle] = useState(AlarmsData.map(() => false));
  console.log(
    "🚀 ~ file: AlarmsManagementMob.jsx:43 ~ AlarmsManagementMob ~ toggle:",
    toggle
  );
  // useEffect(() => {}, [toggle]);
  const handleToggleChange = (index) => {
    const newToggle = [...toggle];

    newToggle[index] = !newToggle[index];
    setToggle(newToggle);
  };

  const navigate = useNavigate();
  const StyledMenuList = styled(MenuList)(({ theme }) => ({
    /* Use the prop `theme` to apply dynamic styles */
    backgroundColor: theme.palette.background.third,
    boxShadow: "none !important",
  }));

  const statusOrder = {
    "درحال انجام": 1,
    "انجام نشده": 2,
  };

  function sortBasedonDate(a, b) {
    // Extract the date and time parts from the sendDate strings
    const partsA = a.date.split(" - ");
    const partsB = b.date.split(" - ");

    // Extract date components
    const dateA = partsA[0];
    const dateB = partsB[0];

    // Extract time components
    const timeA = partsA[1];
    const timeB = partsB[1];

    // Compare the dates
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    // If the dates are the same, compare the times
    if (timeA < timeB) return -1;
    if (timeA > timeB) return 1;

    // If both date and time are the same, consider them equal
    return 0;
  }

  // Sort the array based on priority
  const data =
    selectItem === "تاریخ ارسال"
      ? recommendData.sort(sortBasedonDate)
      : selectItem === "انجام شده ها"
      ? recommendData.sort((a, b) => {
          const order = ["انجام شده", "در حال انجام", "در حال انتظار"];
          const statusA = order.indexOf(a.status);
          const statusB = order.indexOf(b.status);

          return statusA - statusB;
        })
      : recommendData.sort((a, b) => {
          const order = ["انجام شده", "در حال انجام", "در حال انتظار"];
          const statusA = order.indexOf(a.status);
          const statusB = order.indexOf(b.status);

          return statusB - statusA;
        });

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <FlexBetween>
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            mt: "0.25rem",
            width: "163px",
          }}
        >
          <Search sx={{ mr: "0.2rem" }} />
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "36px",
              width: "163px",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
        </FlexBetween>
        <Select
          value={selectValue}
          IconComponent={FilterAltOutlined}
          onChange={(e) => setSelectValue(e.target.value)}
          MenuProps={{
            // Apply the custom styled MenuList component
            MenuListProps: { component: StyledMenuList },
          }}
          sx={{
            width: "163px",
            height: "36px",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            borderRadius: "8px",

            boxShadow: "none !important",
            "& .MuiPaper-elevation": {
              boxShadow: "none !important",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          <MenuItem
            value={0}
            sx={{
              display: "flex",
              justifyContent: "center",
              m: "0 5px",
              borderRadius: "4px",
              backgroundColor: `${theme.palette.background.third} !important`,
              ":hover": {
                backgroundColor: `${theme.palette.fontColor.third} !important`,
              },
            }}
          >
            همه ناحیه ها
          </MenuItem>
        </Select>
      </FlexBetween>
      <WeatherDaily />
      <WidgetWrapper mt="12px">
        <FlexBetween>
          <Typography variant="h4" color="fontColor.main">
            توصیه ها
          </Typography>
          <CenterStack
            width="114px"
            height="33px"
            borderRadius="8px"
            sx={{
              backgroundColor: theme.palette.background.third,
              cursor: "pointer",
            }}
          >
            <Typography variant="smallestTextPrimary" color="fontColor.main">
              توصیه های گذشته
            </Typography>
          </CenterStack>
        </FlexBetween>
        <Stack direction="row" alignItems="center" gap="9px" m="12px 0">
          <CenterStack
            sx={{
              padding: "4px 8px",
              width: "73px",
              height: "32px",
              borderRadius: "8px",
              background:
                selectItem === "تاریخ ارسال"
                  ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                  : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
            }}
            onClick={() => setSelectItem("تاریخ ارسال")}
          >
            <Typography variant="smallestTextSecondary" color="fontColor.main">
              تاریخ ارسال
            </Typography>
          </CenterStack>
          <CenterStack
            sx={{
              padding: "4px 8px",
              width: "101px",
              height: "32px",
              borderRadius: "8px",
              background:
                selectItem === "انجام شده ها"
                  ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                  : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
            }}
            onClick={() => setSelectItem("انجام شده ها")}
          >
            <Typography variant="smallestTextSecondary" color="fontColor.main">
              انجام شده ها
            </Typography>
          </CenterStack>
          <CenterStack
            sx={{
              padding: "4px 8px",
              width: "106px",
              height: "32px",
              borderRadius: "8px",
              background:
                selectItem === "انجام نشده ها"
                  ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                  : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
            }}
            onClick={() => setSelectItem("انجام نشده ها")}
          >
            <Typography variant="smallestTextSecondary" color="fontColor.main">
              انجام نشده ها
            </Typography>
          </CenterStack>
        </Stack>
        {data.map((item, index) => (
          <>
            <Box width="100%" height="53px">
              <FlexBetween>
                <Stack direction="row" alignItems="center" gap="8px">
                  <Box
                    component="img"
                    width="24px"
                    height="24px"
                    borderRadius="99px"
                    src={item.icon}
                    sx={{ objectFit: "cover" }}
                  />
                  <Typography variant="h5" color="fontColor.main">
                    {item.name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap="8px">
                  <IconButton>
                    <Box
                      component="img"
                      src={RefreshIcon}
                      sx={{ objectFit: "cover" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => handleToggleChange(index)}>
                    <Box
                      component="img"
                      src={ArrowIcon}
                      sx={{
                        objectFit: "cover",
                        rotate: toggle[index] ? "0" : "180deg",
                      }}
                    />
                  </IconButton>
                </Stack>
              </FlexBetween>
              <FlexBetween>
                <Typography
                  variant="subHeading"
                  color="fontColor.alt"
                  sx={{ direction: "ltr" }}
                >
                  {item.date}
                </Typography>

                <CenterStack
                  sx={{
                    borderRadius: "4px",
                    width: "77px",
                    height: "23px",
                    backgroundColor:
                      item.status === "در حال انجام"
                        ? theme.palette.others.forth
                        : item.status === "انجام شده"
                        ? theme.palette.others.third
                        : theme.palette.others.first,
                  }}
                >
                  <Typography
                    variant="smallestTextPrimary"
                    color="background.first"
                  >
                    {item.status}
                  </Typography>
                </CenterStack>
              </FlexBetween>
            </Box>
            <Collapse in={toggle[index]} timeout={500}>
              <FlexBetween sx={{ mt: "12px" }}>
                <Typography variant="subHeading" color="fontColor.alt">
                  بهاربند :
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.main"
                >
                  {item.area}
                </Typography>
              </FlexBetween>

              <FlexBetween sx={{ mt: "12px" }}>
                <Typography variant="subHeading" color="fontColor.alt">
                  انجام توسط :
                </Typography>

                <Stack direction="row" alignItems="center" gap="8px">
                  <Box
                    width="24px"
                    height="24px"
                    borderRadius="99px"
                    component="img"
                    src={item.pic}
                    sx={{ objectFit: "cover" }}
                  />
                  <Typography
                    variant="smallestTextSecondary"
                    color="fontColor.main"
                  >
                    {item.recommender}
                  </Typography>
                </Stack>
              </FlexBetween>

              <FlexBetween sx={{ mt: "12px" }}>
                <Typography variant="subHeading" color="fontColor.alt">
                  تاریخ انجام توصیه :
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.main"
                >
                  {item.dueDate}
                </Typography>
              </FlexBetween>
              <Box
                sx={{ mt: "12px", display: "flex", flexDirection: "column" }}
              >
                <Typography variant="subHeading" color="fontColor.alt">
                  توضیحات :
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.main"
                >
                  {item.description}
                </Typography>
              </Box>
            </Collapse>
            {index !== AlarmsData.length - 1 && (
              <Divider variant="fullWidth" sx={{ m: "12px 0" }} />
            )}
          </>
        ))}
      </WidgetWrapper>
      <SpeedometerMob />
      <AlarmsMapMob />
    </Box>
  );
};

export default RecommendsMob;
