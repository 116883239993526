import { Box, IconButton, Stack, useTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { MapContainer, Marker, Polygon, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import L, { map } from "leaflet";
import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import ButtonsSensorsManagementAll from "scenes/SensorsManagementAll/ButtonsSensorsManagementAll";
import FlexBetween from "components/FlexBetween/FlexBetween";
import {
  DownloadOutlined,
  FullscreenOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";
const AlarmsMap = ({}) => {
  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
  });
  // function MultipleMarkers({ data }) {
  //   console.log("🚀 ~ file: AlarmsMap.jsx:9 ~ MultipleMarkers ~ data:", data);
  //   const theme = useTheme();
  //   const mode = useSelector((state) => state.global.mode);
  //   useEffect(() => {
  //     console.log(mode);
  //   }, [mode]);

  //   return data.map((item, index) => (
  //     <Marker
  //       key={index}
  //       position={[item.location.x, item.location.y]}
  //       // eventHandlers={{
  //       //   click: (e) => {
  //       //     setSelectedSensor(item.id);
  //       //     console.log("id=======>", item.id);
  //       //   },
  //       // }}
  //       // icon={
  //       //   item.data && Object.keys(item.data).length > 0
  //       //     ? getIcon(item, selectedSensor)
  //       //     : GG1Icon
  //       // }
  //     ></Marker>
  //   ));
  // }
  const theme = useTheme();

  const mode = useSelector((state) => state.global.mode);
  const d = useSelector((state) => state.dashboard.remote);

  // const polygon = useMemo(() => d.property.boundary.coordinates[0]);

  return (
    <Box
      width="30.5vw"
      height="52%"
      sx={{
        position: "relative",
        "& .leaflet-left": {
          left: "2%",
        },
        "& .leaflet-bar": {
          border: "none",
        },
        "& .leaflet-control-zoom-in": {
          width: "24px !important",
          height: "24px !important",
          backgroundColor:
            mode === "dark"
              ? "rgba(29, 29, 65, 0.7) !important"
              : "rgba(250, 250, 252, 0.7) !important",
          color: theme.palette.fontColor.alt,
          borderRadius: "8px 8px 0 0 !important",
        },

        "& .leaflet-touch .leaflet-bar a": {
          lineHeight: "24px !important",
        },
        "& .leaflet-control-zoom-out": {
          width: "24px !important",
          height: "24px !important",
          backgroundColor:
            mode === "dark"
              ? "rgba(29, 29, 65, 0.7 )!important"
              : "rgba(250, 250, 252, 0.7) !important",
          color: theme.palette.fontColor.alt,
          borderRadius: "0 0 8px 8px !important",
        },
      }}
    >
      <FlexBetween
        position="absolute"
        top={"90px"}
        left={"1.2rem"}
        // right={individual ? "3.5%" : "none"}
        width="24px"
        height={"50px"}
        flexDirection="column"
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <FullscreenOutlined
              // onClick={() => setMapModal(true)}
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <DownloadOutlined
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>
      </FlexBetween>
      <MapContainer
        // center={[devices[0].location.x, devices[0].location.y]}
        center={[35.96, 50.83]}
        zoom={15.5}
        style={{ borderRadius: "12px", height: "100%", width: "100%" }}
      >
        {/* <ChangeView center={position} zoom={15.5} /> */}
        <TileLayer
          attribution="PTR"
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        {/* <Polygon positions={polygon} /> */}
        {/* <MultipleMarkers
          data={devices}
          selectedIcon={selectedSensor}
          setselectedIcon={setSelectedSensor}
        /> */}
      </MapContainer>
    </Box>
  );
};

export default AlarmsMap;
