import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const UvIndex = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <>
      <FlexBetween>
        <Typography color={main} variant="h5">
          شاخص UV
        </Typography>
        <Typography color={main} variant="h5">
          3 از 10
        </Typography>
      </FlexBetween>
      <Box width={isMobileScreens ? "310px" : "378px"} mt="3rem">
        <Box
          width={"100%"}
          height="8px"
          sx={{
            background: "linear-gradient(90deg, #5FBD15, #FFEE00, #FC4949)",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <Box
            width="17px"
            height="17px"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "50%",
              position: "absolute",
              top: -4,
              left: "20%",
            }}
          >
            <Typography mt="-25px" mr="-5px">
              نرمال
            </Typography>
          </Box>
        </Box>
        <FlexBetween>
          <Typography variant="h5" color={alt}>
            10
          </Typography>
          <Typography variant="h5" color={alt}>
            0
          </Typography>
        </FlexBetween>
      </Box>
    </>
  );
};

export default UvIndex;
