import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  IconButton,
  MenuItem,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import DeleteDark from "assets/icon/delete-dark.svg";
import EditDark from "assets/icon/edit-popover-dark.svg";
import DeleteLight from "assets/icon/delete-light.svg";
import EditLight from "assets/icon/edit-popover-light.svg";
import { useSelector } from "react-redux";

const CustomPopover = ({
  handleClickEdit = () => {},
  handleClickDelete = () => {},
  no_edit = false,
  no_delete = false,
  id,
}) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickEdit = (e) => {
    e.stopPropagation();
    handleClickEdit();
    setAnchorEl(null);
  };
  const clickDelete = (e) => {
    e.stopPropagation();
    handleClickDelete();
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const editIcon = mode === "dark" ? EditDark : EditLight;
  const deletIcon = mode === "dark" ? DeleteDark : DeleteLight;
  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <MoreHoriz sx={{ rotate: "90deg" }} />
      </IconButton>
      {(!no_delete || !no_edit) && (
        <Popover
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: theme.palette.background.third,
              boxShadow: "none",
              borderRadius: "8px",
            },
          }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Box display="flex" flexDirection="column" py="0.3rem">
            {!no_edit && (
              <MenuItem
                onClick={clickEdit}
                sx={{
                  minHeight: "auto",
                  borderRadius: "8px",
                  mx: "3px",
                  display: "flex",
                  gap: "0.3rem",
                  px: "10px",
                }}
              >
                <img alt="edit" src={editIcon} />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  ویرایش
                </Typography>
              </MenuItem>
            )}
            {!no_delete && (
              <MenuItem
                onClick={clickDelete}
                sx={{
                  minHeight: "auto",
                  borderRadius: "8px",
                  mx: "3px",
                  display: "flex",
                  gap: "0.3rem",
                  px: "10px",
                }}
              >
                <img alt="edit" src={deletIcon} />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  حذف
                </Typography>
              </MenuItem>
            )}
          </Box>
        </Popover>
      )}
    </>
  );
};
export default CustomPopover;
