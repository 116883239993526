export const VaccineData = {
    type: "واکسن",
    performance: [
      ["05/12", 910],
      ["05/13", 820],
      ["05/14", 900],
      ["05/15", 672],
      ["05/16", 650],
      ["05/17", 723],
      ["05/18", 831],
      ["05/19", 850],
    ],
    status: [[695, 1750], "واکسن زده ها"],
  
    livestock: [
      {
        code: 900,
        injDate: "1402/04/10",
        vaccineName: "تب برفکی",
        injectionTime: 12,
        agent: "دامپزشک",
        nextTime: "1402/08/10",
        milk: 9,
      },
      {
        code: 899,
        injDate: "1402/04/12",
        vaccineName: "تب برفکی",
        injectionTime: 8,
        agent: "دامپزشک",
        nextTime: "1402/08/12",
        milk: 8,
      },
      {
        code: 898,
        injDate: "1402/04/24",
        vaccineName: "تب برفکی",
        injectionTime: 5,
        agent: "دامپزشک",
        nextTime: "1402/08/05",
        milk: 9,
      },
      {
        code: 897,
        injDate: "1402/03/10",
        vaccineName: "تب برفکی",
        injectionTime: 5,
        agent: "دامپزشک",
        nextTime: "1402/07/10",
        milk: 6,
      },
    ],
  };