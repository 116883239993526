import { Box, styled, Typography } from "@mui/material";
import AirAlertsList from "components/AirAlertsList/AirAlertsList";
import AirSensorsList from "components/AirSensorsList/AirSensorsList";
import CustomGauge from "components/CustomGauge/CustomGauge";
import FlexBetween from "components/FlexBetween/FlexBetween";
import AirMap from "components/IndividualAirSensors/AirMap";
import DewPointAir from "components/IndividualAirSensors/DewPointAir";
import HumidityAir from "components/IndividualAirSensors/HumidityAir";
import PressureAir from "components/IndividualAirSensors/PressureAir";
import RadiationIntensityAir from "components/IndividualAirSensors/RadiationIntensityAir";
import RainAir from "components/IndividualAirSensors/RainAir";
import TempAir from "components/IndividualAirSensors/TempAir";
import WindAir from "components/IndividualAirSensors/WindAir";
import FruitDiameterPlant from "components/IndividualPlantSensors/FruitDiameterPlant";
import LeafHumidityPlant from "components/IndividualPlantSensors/LeafHumidityPlant";
import PlantAlertsList from "components/IndividualPlantSensors/PlantAlertsList";
import PlantMap from "components/IndividualPlantSensors/PlantMap";
import PlantSensorsList from "components/IndividualPlantSensors/PlantSensorsList";
import StemDiameterPlant from "components/IndividualPlantSensors/StemDiameterPlant";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import CopyRight from "widgets/CopyRight";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import jalaali from "jalaali-js";
import AlarmNoSensor from "components/AlarmNoSensor/AlarmNoSensor";
const PlantSensor = () => {
  const data = useSelector((state) => state.sensors.sensorsData.devices);
  console.log("🚀 ~ file: PlantSensor.jsx:31 ~ PlantSensor ~ data:", data);
  const allPlantSensors = data.filter((item) => item.type === "PLANT");
  const selectedSensorDashboard = useSelector((state) => state.sensors.id);

  const idExists = allPlantSensors.some(
    (item) => item.id === selectedSensorDashboard
  );

  const [selectedSensor, setSelectedSensor] = useState(
    idExists && selectedSensorDashboard
      ? selectedSensorDashboard
      : allPlantSensors[0]
      ? allPlantSensors[0].id
      : ""
  );
  const firstDate = useSelector((state) => state.sensors.firstDate);
  const secondDate = useSelector((state) => state.sensors.secondDate);

  const token = useSelector((state) => state.global.token);
  const refreshToken = useSelector((state) => state.global.refreshToken);
  const dispatch = useDispatch();

  const one = firstDate ? shamsiToGregorian(firstDate) : "2023-03-13";
  const two = secondDate ? shamsiToGregorian(secondDate) : "2023-03-15";

  function shamsiToGregorian(shamsiDate) {
    const [shamsiYear, shamsiMonth, shamsiDay] = shamsiDate
      .split("-")
      .map(Number);
    const { gy, gm, gd } = jalaali.toGregorian(
      shamsiYear,
      shamsiMonth,
      shamsiDay
    );
    return `${gy}-${gm.toString().padStart(2, "0")}-${gd
      .toString()
      .padStart(2, "0")}`;
  }

  useEffect(() => {
    console.log("Sensor Changed!");
  }, [selectedSensor, selectedSensorDashboard]);

  const selectedData = allPlantSensors.filter(
    (item) => item.id === selectedSensor
  );
  console.log(
    "🚀 ~ file: PlantSensor.jsx:37 ~ PlantSensor ~ selectedData:",
    selectedData
  );
  return (
    <Box
      width="99%"
      height="83vh"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: 15,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      {allPlantSensors.length > 0 ? (
        <>
          <FlexBetween
            sx={{ justifyContent: "flex-start", gap: "20px", width: "92vw" }}
          >
            <Box width="61vw" height="62px">
              <SpeedomotorsData />
            </Box>
            <WidgetWrapper width="29.7vw" height="62px">
              <AirSensorsDoughnut />
            </WidgetWrapper>
          </FlexBetween>
          <FlexBetween
            mt="16px"
            sx={{
              justifyContent: "flex-start",
              gap: "18px",
              width: "92vw",
              height: "33vh",
            }}
          >
            <WidgetWrapper width="30vw" height="100%">
              <PlantSensorsList
                sensors={allPlantSensors}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
              />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="100%" sx={{ padding: "0" }}>
              <PlantMap
                data={allPlantSensors}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
              />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="100%">
              <PlantAlertsList />
            </WidgetWrapper>
          </FlexBetween>

          <FlexBetween
            mt="16px"
            sx={{
              justifyContent: "flex-start",
              gap: "18px",
              width: "92vw",
              height: "32vh",
            }}
          >
            <WidgetWrapper width="30vw" height="100%">
              <FruitDiameterPlant />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="100%">
              <StemDiameterPlant />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="100%">
              <LeafHumidityPlant />
            </WidgetWrapper>
          </FlexBetween>
          <Box width="92vw">
            <CopyRight />
          </Box>
        </>
      ) : (
        <AlarmNoSensor type={"گیاه"} />
      )}
    </Box>
  );
};

export default PlantSensor;
