import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const AlarmNoSensor = ({ type }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Stack direction="column">
        <Box>
          <svg
            width="100%"
            height="145"
            viewBox="0 0 137 145"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1768_29662)">
              <path
                d="M116.936 117.448C143.686 90.5802 143.686 47.0189 116.936 20.151C90.1849 -6.71679 46.8135 -6.71679 20.0628 20.151C-6.68784 47.0189 -6.68784 90.5802 20.0628 117.448C46.8135 144.316 90.1849 144.316 116.936 117.448Z"
                fill="#E3F7F1"
              />
              <path
                d="M94.3841 33.3546C94.4124 32.3554 94.3629 31.3384 94.2248 30.3143C92.6989 18.8998 81.2031 10.5149 68.5461 11.5852C61.5963 12.1755 55.744 15.4932 52.1292 20.2581C41.9364 20.0697 34.4555 22.6406 32.9083 27.6865C30.3769 35.9433 44.6908 47.6992 64.8818 53.947C85.0728 60.1912 103.49 58.559 106.025 50.3022C107.572 45.2705 102.863 38.9445 94.3841 33.3546Z"
                fill="#CDAB5D"
              />
              <path
                d="M90.9284 31.719C90.847 31.719 90.7656 31.6977 90.6912 31.655C85.7736 28.8494 79.9709 26.3744 73.9167 24.5005C67.4165 22.4914 60.8243 21.229 54.8516 20.8485C54.5861 20.8308 54.3843 20.6032 54.402 20.3365C54.4197 20.0698 54.625 19.8564 54.9118 19.8849C60.9624 20.2689 67.6325 21.5455 74.2035 23.5795C80.3249 25.4712 86.1914 27.9746 91.1692 30.8158C91.3993 30.9474 91.4807 31.2425 91.3498 31.4736C91.2577 31.6337 91.0948 31.719 90.9284 31.719Z"
                fill="#3A222B"
              />
              <path
                d="M115.634 108.939L111.754 90.5409C110.961 86.7752 108.801 83.3473 105.615 80.7941C103.529 79.1193 98.9904 77.8711 96.3139 77.1315L83.147 73.4902H71.2371H59.3271L46.1674 77.1279C43.4908 77.8676 41.0267 79.1193 38.9378 80.7906C35.7515 83.3473 33.5918 86.7716 32.7988 90.5373L28.9185 108.936H42.981V142.059H105.915V108.936H115.634V108.939Z"
                fill="#2A80F4"
              />
              <path
                d="M74.5118 125.926H46.4787C45.9831 125.926 45.5122 125.702 45.2006 125.315L21.5542 96.0492C21.1081 95.4981 21.4551 94.6731 22.1596 94.6091L49.8067 92.0986C50.798 92.0097 51.761 92.4435 52.3558 93.2436L75.3367 124.276C75.8394 124.959 75.3579 125.926 74.5118 125.926Z"
                fill="#3A222B"
              />
              <path
                d="M26.1425 97.0129C26.1425 97.4111 25.8203 97.7347 25.4238 97.7347C25.0273 97.7347 24.7051 97.4111 24.7051 97.0129C24.7051 96.6146 25.0273 96.291 25.4238 96.291C25.8203 96.291 26.1425 96.6146 26.1425 97.0129Z"
                fill="white"
              />
              <path
                d="M35.9248 119.561C35.8044 119.97 35.8788 120.411 36.1372 120.749L42.9808 129.656V122.569L36.8311 114.956C36.7745 116.019 36.5373 117.509 35.9248 119.561Z"
                fill="#F0A76E"
              />
              <path
                d="M21.4478 104.832C21.7204 104.42 23.8093 101.835 24.4005 101.102C24.5067 100.974 24.6306 100.867 24.7758 100.786L30.6954 97.4751C31.2123 97.1835 31.8602 97.2831 32.2709 97.7134C32.8267 98.2966 32.7311 99.2389 32.0691 99.6976L28.0259 102.493C27.8949 102.585 27.7816 102.699 27.6896 102.83L25.8308 105.554L36.6716 100.441C37.2664 100.16 37.978 100.37 38.3285 100.928L38.3604 100.978C38.7498 101.6 38.5551 102.421 37.9249 102.798L28.7871 108.278L40.5484 103.104C41.1608 102.834 41.876 103.076 42.2053 103.659L42.2265 103.694C42.5629 104.292 42.3823 105.049 41.8158 105.43L38.5162 107.638L42.6301 106.123C43.0266 105.977 43.4727 106.116 43.7135 106.465C44.0144 106.891 43.9082 107.482 43.4798 107.777L37.3124 112.054C36.8805 112.353 36.6822 112.876 36.7672 113.395C36.923 114.323 36.9336 116.183 35.9246 119.565C35.8042 119.973 35.8785 120.414 36.137 120.752L43.5648 130.421C44.241 131.299 45.0164 133.685 45.5474 136.064C46.3263 139.56 44.8464 143.638 41.4653 144.79C39.5854 145.43 36.7672 144.424 36.7672 144.424C34.3172 143.798 31.584 140.032 30.8193 137.611C30.8193 137.611 29.1447 124.233 28.8012 119.785C28.8437 119.444 28.7481 119.099 28.5393 118.825L22.6551 111.141C22.5418 110.995 22.4639 110.828 22.4214 110.646C22.1559 109.519 21.1717 105.248 21.4478 104.832Z"
                fill="#F0A76E"
              />
              <path
                d="M35.9248 119.561C35.8044 119.97 35.8788 120.411 36.1372 120.749L42.9808 129.656V127.715L37.0011 120.532C36.6187 120.087 36.7603 119.323 36.948 118.768C37.3409 117.598 37.5357 116.489 37.6171 115.927L36.8311 114.956C36.7745 116.019 36.5373 117.509 35.9248 119.561Z"
                fill="#3A222B"
              />
              <path
                d="M87.2361 46.2165C87.0662 46.1881 86.8998 46.181 86.7298 46.1916C86.9741 43.7451 86.9635 42.1272 86.9635 42.1272C86.9635 41.1387 86.8502 40.1714 86.659 39.2327C82.1308 36.1924 72.7098 31.1892 60.9273 32.2737C57.6559 32.576 55.4184 32.8036 53.8217 33.1449C52.0479 35.7194 51.0353 38.7989 51.0353 42.113C51.0353 42.113 50.9822 43.7629 51.1699 46.2592C49.5484 46.6966 48.6137 49.079 49.0669 51.7175C49.5271 54.3987 51.2549 56.3474 52.9543 56.1411C54.4554 61.0092 57.1142 65.8417 61.8265 68.2277C60.8317 71.0938 59.3305 73.4905 59.3305 73.4905L71.2405 93.5708L83.1505 73.4905C83.1505 73.4905 80.2934 68.9353 79.8544 64.878C82.1662 62.332 83.7276 59.0819 84.7826 55.8389C85.0127 55.9811 85.2676 56.0807 85.5332 56.1269C87.268 56.4291 89.0524 54.452 89.5232 51.7175C89.9941 48.9795 88.9709 46.5188 87.2361 46.2165Z"
                fill="#F0A76E"
              />
              <path
                d="M62.3294 47.3507C62.3294 48.3712 61.6851 49.1962 60.892 49.1962C60.099 49.1962 59.4546 48.3712 59.4546 47.3507C59.4546 46.3301 60.099 45.5051 60.892 45.5051C61.6851 45.5051 62.3294 46.3301 62.3294 47.3507Z"
                fill="#3A222B"
              />
              <path
                d="M76.5086 47.3507C76.5086 48.3712 75.8643 49.1962 75.0712 49.1962C74.2781 49.1962 73.6338 48.3712 73.6338 47.3507C73.6338 46.3301 74.2781 45.5051 75.0712 45.5051C75.8643 45.5051 76.5086 46.3301 76.5086 47.3507Z"
                fill="#3A222B"
              />
              <path
                d="M74.6144 57.6807C74.6144 57.6807 72.483 62.659 66.4643 62.1718C62.5061 61.8518 60.9873 57.6807 60.9873 57.6807H74.6144Z"
                fill="white"
              />
              <path
                d="M85.7421 52.8163C85.6288 52.8163 85.512 52.7736 85.4235 52.6954C85.2606 52.5496 85.2146 52.3078 85.3173 52.1122L87.2291 48.3963C87.3495 48.158 87.6433 48.0691 87.877 48.19C88.1142 48.3109 88.2062 48.6025 88.0823 48.8408L87.0344 50.8748L87.877 50.3022C88.0965 50.1529 88.3974 50.2098 88.5426 50.4303C88.6913 50.6507 88.6346 50.953 88.4151 51.0988L86.0112 52.7309C85.9297 52.7878 85.8342 52.8163 85.7421 52.8163Z"
                fill="#3A222B"
              />
              <path
                d="M67.4771 53.8367C67.4594 53.8367 67.4417 53.8367 67.424 53.8332C66.3654 53.7123 65.6998 53.3389 65.4485 52.7237C65.0732 51.7992 65.845 50.8071 65.9335 50.6969C66.0999 50.4906 66.4008 50.4622 66.6097 50.6293C66.8151 50.7964 66.8469 51.1022 66.6805 51.3085C66.5141 51.5147 66.2096 52.0481 66.3406 52.361C66.4433 52.6171 66.8682 52.7984 67.5302 52.8731C67.7958 52.9015 67.9834 53.1433 67.9551 53.4065C67.9267 53.6554 67.7214 53.8367 67.4771 53.8367Z"
                fill="#3A222B"
              />
              <path
                d="M56.7282 43.8801C56.576 43.8801 56.4237 43.8055 56.3317 43.6703C56.1794 43.4499 56.2361 43.1512 56.4556 42.9983C56.5866 42.9094 59.7128 40.7972 62.6726 42.664C62.8992 42.8063 62.9664 43.105 62.8248 43.329C62.6832 43.5566 62.3858 43.6241 62.1627 43.4819C59.734 41.9493 57.0291 43.777 57.0008 43.7948C56.9158 43.8517 56.8203 43.8801 56.7282 43.8801Z"
                fill="#3A222B"
              />
              <path
                d="M77.9036 44.026C77.7938 44.026 77.6841 43.9904 77.592 43.9122C77.4894 43.8268 75.0536 41.7928 72.4903 43.0587C72.2496 43.1761 71.9628 43.0765 71.8459 42.8383C71.7291 42.6 71.8282 42.3084 72.0655 42.1911C75.2023 40.6443 78.0912 43.073 78.2152 43.1761C78.417 43.3503 78.4417 43.6526 78.2683 43.8553C78.1727 43.9691 78.0381 44.026 77.9036 44.026Z"
                fill="#3A222B"
              />
              <path
                d="M114.175 108.939H97.3686V119.255L76.5616 112.094L74.7241 109.455L71.0279 103.652L70.8721 103.371C70.4933 102.688 69.6507 102.418 68.9497 102.756L66.7582 103.89C66.6874 103.744 66.5989 103.606 66.4749 103.488L64.0745 101.22C63.7099 100.875 63.1894 100.761 62.715 100.921L61.8582 101.209C61.6671 101.273 61.4936 101.38 61.3484 101.518L58.7675 104C57.9567 104.779 58.0523 106.105 58.9657 106.76C59.6242 107.229 60.5129 107.201 61.136 106.685L61.8653 106.084L70.8084 118.16L72.784 118.768L100.449 140.16C102.407 141.675 104.896 142.326 107.342 141.96L107.962 141.867C111.364 141.359 114.189 138.966 115.262 135.687C115.435 135.161 115.556 134.624 115.63 134.08H115.634V134.069C115.797 132.896 114.175 108.939 114.175 108.939Z"
                fill="#F0A76E"
              />
              <path
                d="M62.8567 106.514C62.885 106.905 63.0089 107.293 63.232 107.641C63.848 108.602 65.0659 109.007 66.174 108.584L68.8718 107.407C68.8718 107.407 71.9662 110.813 72.2777 110.813L69.2294 106.198L65.7917 107.698C65.1438 107.944 64.4145 107.702 64.0392 107.119C63.6533 106.518 63.7666 105.753 64.2445 105.28C63.7559 104.996 63.1435 105.031 62.7009 105.394L61.8618 106.088L62.4354 106.863L62.8567 106.514Z"
                fill="#3A222B"
              />
              <path
                d="M97.3652 119.255L109.176 123.32L97.3652 116.851V119.255Z"
                fill="#3A222B"
              />
              <path
                d="M56.282 74.3296L49.4419 90.1499L60.9907 86.0784L71.2402 93.5671L59.3303 73.4868L56.282 74.3296Z"
                fill="#1E5094"
              />
              <path
                d="M86.1985 74.3296L93.0386 90.1499L81.4933 86.0784L71.2402 93.5671L83.1502 73.4868L86.1985 74.3296Z"
                fill="#1E5094"
              />
              <path
                d="M49.2615 37.3478C48.8013 39.5632 48.7234 42.9022 49.4633 47.9338C49.8528 47.0911 50.4369 46.4795 51.1733 46.2768C50.9857 43.7734 51.0388 42.1128 51.0388 42.1128C51.0388 38.8058 52.0478 35.7335 53.8145 33.159L53.7791 33.1519C52.3452 33.4612 51.4318 33.8559 50.7733 34.4818C49.8846 35.3245 49.4598 36.5371 49.2615 37.3478Z"
                fill="#3A222B"
              />
              <path
                d="M86.6694 39.2397C86.8606 40.1785 86.9633 41.1422 86.9633 42.1307C86.9633 42.1307 86.9739 43.7558 86.7261 46.2129C86.896 46.1987 87.066 46.1916 87.2359 46.22C87.9865 46.3516 88.5954 46.9028 89.0167 47.6922C89.5903 45.235 89.7107 43.169 89.5726 41.4124C89.4345 41.288 88.4184 40.4132 86.6694 39.2397Z"
                fill="#3A222B"
              />
              <path
                d="M61.8299 68.2275C61.2174 69.9877 60.4173 71.5701 59.8862 72.5302C59.8862 72.5302 65.9864 77.5085 74.4657 68.6863C72.7663 69.3726 70.8332 69.7637 68.6381 69.7637C65.9899 69.7637 63.7417 69.1948 61.8299 68.2275Z"
                fill="#3A222B"
              />
            </g>
            <defs>
              <clipPath id="clip0_1768_29662">
                <rect width="137" height="145" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Box>
        <Typography>{`سنسور  ${type} برای زمین شما ثبت نشده است.`}</Typography>
      </Stack>
    </Box>
  );
};

export default AlarmNoSensor;
