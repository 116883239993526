import { useMediaQuery } from "@mui/material";
import React from "react";
import RecommendsPage from "./RecommendsPage";
import RecommendsMob from "mob/RecommendsMob/RecommendsMob";

const RecommendsIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  return <>{isMobileScreens ? <RecommendsMob /> : <RecommendsPage />}</>;
};
export default RecommendsIndex;
