import { Box, styled, Typography, useMediaQuery } from "@mui/material";
import { setLastTimeUpdate, setSensorsData } from "States/sensors";
import { checkStatusApi } from "Utils/RefreshToken";
import axios from "axios";
import AirAlertsList from "components/AirAlertsList/AirAlertsList";
import AirSensorsList from "components/AirSensorsList/AirSensorsList";
import CustomGauge from "components/CustomGauge/CustomGauge";
import FlexBetween from "components/FlexBetween/FlexBetween";
import AirMap from "components/IndividualAirSensors/AirMap";
import DewPointAir from "components/IndividualAirSensors/DewPointAir";
import HumidityAir from "components/IndividualAirSensors/HumidityAir";
import PressureAir from "components/IndividualAirSensors/PressureAir";
import RadiationIntensityAir from "components/IndividualAirSensors/RadiationIntensityAir";
import RainAir from "components/IndividualAirSensors/RainAir";
import TempAir from "components/IndividualAirSensors/TempAir";
import WindAir from "components/IndividualAirSensors/WindAir";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import CopyRight from "widgets/CopyRight";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import jalaali from "jalaali-js";
import AlarmNoSensor from "components/AlarmNoSensor/AlarmNoSensor";
import AirSensorMob from "mob/scenesMob/AirSensorMob/AirSensorMob";
const AirSensor = () => {
  const sensorsData = useSelector((state) => state.sensors.sensorsData);
  const data = sensorsData?.devices;
  const selectedSensorDashboard = useSelector((state) => state.sensors.id);

  const allWeatherSensors = data?.filter((item) => item.type === "WEATHER");

  const idExists = allWeatherSensors?.some(
    (item) => item.id === selectedSensorDashboard
  );

  const [selectedSensor, setSelectedSensor] = useState(
    idExists && selectedSensorDashboard
      ? selectedSensorDashboard
      : allWeatherSensors?.[0]
      ? allWeatherSensors[0].id
      : ""
  );
  useEffect(() => {
    console.log("Sensor Changed!");
  }, [selectedSensor, selectedSensorDashboard]);
  const selectedData = allWeatherSensors?.filter(
    (item) => item.id === selectedSensor
  );
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <>
      {isMobileScreens ? (
        <AirSensorMob />
      ) : (
        <Box
          width="99.5%"
          height="83vh"
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {allWeatherSensors?.length > 0 ? (
            <>
              <FlexBetween
                sx={{
                  justifyContent: "flex-start",
                  gap: "20px",
                  width: "92vw",
                }}
              >
                <Box width="61.1vw" height="62px">
                  <SpeedomotorsData />
                </Box>
                <WidgetWrapper width="29.7vw" height="62px">
                  <AirSensorsDoughnut />
                </WidgetWrapper>
              </FlexBetween>
              <FlexBetween
                mt="16px"
                sx={{
                  justifyContent: "flex-start",
                  gap: "18px",
                  width: "92vw",
                }}
              >
                <WidgetWrapper width="30vw" height="241px">
                  <AirSensorsList
                    sensors={allWeatherSensors}
                    selectedSensor={selectedSensor}
                    setSelectedSensor={setSelectedSensor}
                  />
                </WidgetWrapper>
                <WidgetWrapper
                  width="30.5vw"
                  height="241px"
                  sx={{ padding: "0" }}
                >
                  <AirMap
                    data={allWeatherSensors}
                    selectedSensor={selectedSensor}
                    setSelectedSensor={setSelectedSensor}
                  />
                </WidgetWrapper>
                <WidgetWrapper width="30vw" height="241px">
                  <AirAlertsList />
                </WidgetWrapper>
              </FlexBetween>
              {selectedData?.[0] && (
                <>
                  <FlexBetween
                    mt="16px"
                    sx={{
                      justifyContent: "flex-start",
                      gap: "18px",
                      width: "92vw",
                    }}
                  >
                    <WidgetWrapper width="24vw" height="241px">
                      <WindAir
                        sensorData={
                          selectedData[0] ? selectedData[0].data.Wind : null
                        }
                      />
                    </WidgetWrapper>
                    <WidgetWrapper width="24vw" height="241px">
                      <HumidityAir
                        sensorData={
                          selectedData[0].data.humidity
                            ? selectedData[0].data.humidity
                            : null
                        }
                      />
                    </WidgetWrapper>
                    <WidgetWrapper width="24vw" height="241px">
                      <DewPointAir
                        sensorData={
                          selectedData[0].data.devPoint
                            ? selectedData[0].data.devPoint
                            : null
                        }
                      />
                    </WidgetWrapper>
                    <WidgetWrapper width="24vw" height="241px">
                      <TempAir
                        sensorData={
                          selectedData[0].data.temperature
                            ? selectedData[0].data.temperature
                            : null
                        }
                      />
                    </WidgetWrapper>
                  </FlexBetween>
                  <FlexBetween
                    mt="16px"
                    sx={{
                      justifyContent: "flex-start",
                      gap: "18px",
                      width: "92vw",
                    }}
                  >
                    <WidgetWrapper width="30vw" height="241px">
                      <RadiationIntensityAir
                        sensorData={
                          selectedData[0].data.radiationIntensity
                            ? selectedData[0].data.radiationIntensity
                            : null
                        }
                      />
                    </WidgetWrapper>
                    <WidgetWrapper width="30vw" height="241px">
                      <PressureAir
                        sensorData={
                          selectedData[0].data.pressure
                            ? selectedData[0].data.pressure
                            : null
                        }
                      />
                    </WidgetWrapper>
                    <WidgetWrapper width="30vw" height="241px">
                      <RainAir
                        sensorData={
                          selectedData[0].data.rain
                            ? selectedData[0].data.rain
                            : null
                        }
                      />
                    </WidgetWrapper>
                  </FlexBetween>
                </>
              )}
              <Box sx={{ width: "92vw" }}>
                <CopyRight />
              </Box>
            </>
          ) : (
            <AlarmNoSensor type={"هوا"} />
          )}
        </Box>
      )}
    </>
  );
};

export default AirSensor;
