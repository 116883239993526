import {
    Button,
    IconButton,
    InputBase,
    Slider,
    styled,
    Switch,
    TextField,
  } from "@mui/material";
  import rtlPlugin from "stylis-plugin-rtl";
  import createCache from "@emotion/cache";
  import { prefixer } from "stylis";
  
  export const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 30,
    height: 13,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 13,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  
  export const CustomInputBase = styled((props) => (
    <InputBase disableUnderline {...props} />
  ))(({ theme }) => ({
    height: "36px",
    width: "100%",
    borderRadius: "8px",
    color: theme.palette.fontColor.main,
    outline: "none",
    borderBottom: "none",
    paddingRight: "5px",
    "& input": {
      "&::-webkit-input-placeholder": {
        color: `${theme.palette.fontColor.main} !important`,
        opacity: 1,
      },
      "&::-moz-placeholder": {
        color: `${theme.palette.fontColor.main} !important`,
        opacity: 1,
      },
      "&::-ms-placeholder": {
        color: `${theme.palette.fontColor.main} !important`,
        opacity: 1,
      },
      "&::placeholder": {
        color: `${theme.palette.fontColor.main} !important`,
        opacity: 1,
      },
    },
  }));
  
  export const HoverIconButton = styled((props) => <IconButton {...props} />)(
    ({ theme }) => ({
      "@media (hover: hover)": {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    })
  );
  
  export const Accordion = styled("div")((props) => ({
    maxHeight: props.open ? "100vh" : 0,
    display: "flex",
    flexDirection: "column",
    gap: props.gap || "0.5rem",
    overflow: "hidden",
    marginTop: props.open ? "0.8rem" : 0,
    transition: "all 0.3s",
    ...props.sx,
  }));
  
  export const CustomButton = styled(Button)(({ theme, ...props }) => ({
    height: props.height || "40px",
    color: theme.palette.fontColor.main,
    backgroundColor: props.secondary
      ? theme.palette.background.third
      : theme.palette.primary.main,
    boxShadow: "none",
    borderRadius: "8px",
    opacity: 1,
    "&:focus": {
      backgroundColor: props.secondary
        ? theme.palette.background.third
        : theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: props.secondary
        ? theme.palette.background.third
        : theme.palette.primary.main,
      opacity: 0.9,
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(42, 128, 244, 0.3)",
    },
  }));
  
  export const CssTextField = styled(TextField)({
    "& .MuiFormLabel-root": {
      marginLeft: "-4px",
    },
    "& .MuiInputBase-input": {
      height: "5px",
    },
    "& .MuiOutlinedInput-input:focused": {
      borderColor: "green",
    },
    "& label.Mui-focused": {
      color: "#F7F7F7",
    },
  
    "& .MuiInput-underline:after": {
      borderBottomColor: "#F7F7F7",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root": {
      color: "#F7F7F7 !important",
      borderRadius: "8px",
  
      "& fieldset": {
        borderColor: "#F7F7F7",
      },
      "&:hover fieldset": {
        borderColor: "#F7F7F7",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F7F7F7",
      },
    },
  });
  
  export const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  
  export const CustomSlider = styled(Slider)(({ theme, customColor, side }) => ({
    color: customColor,
    height: 6,
    padding: "13px 0",
    "& .MuiSlider-thumb": {
      height: 12,
      width: 12,
      backgroundColor: customColor,
      "&:hover": {
        boxShadow: `0 0 8px 0 ${customColor}`,
      },
      "&:focus": {
        boxShadow: `0 0 8px 0 ${customColor}`,
      },
    },
    "& .MuiSlider-track": {
      height: 6,
      backgroundColor: customColor,
    },
    "& .MuiSlider-rail": {
      color: theme.palette.background.third,
      height: 6,
      ...(side === "left"
        ? {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          }
        : side === "right"
        ? {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }
        : {}),
    },
  }));
  