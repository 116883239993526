import {
  Box,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import moment from "jalali-moment";
import W1 from "../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../assets/WeatherIcons/static/day.svg";
import W7 from "../assets/WeatherIcons/static/rainy-7.svg";
import W8 from "../assets/WeatherIcons/static/night.svg";
import W9 from "../assets/WeatherIcons/static/snowy-5.svg";
import W10 from "../assets/WeatherIcons/static/cloudy-day-3.svg";
import { useSelector } from "react-redux";
import { getDayName, getMonthDay } from "Utils/Utils";
import { useRef } from "react";
import { useEffect } from "react";
import CircularProgressBar from "components/circularProgressBar/CircularProgressBar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
const getIcon = (condition) => {
  switch (condition) {
    case "CLEAR":
      return W1;
    case "ATMOSPHERE":
      return W2;
    case "CLOUDS":
      return W3;
    case "DRIZZLE":
      return W4;
    case "RAIN":
      return W5;
    case "SNOW":
      return W6;
    case "THUNDERSTORM":
      return W7;

    default:
      break;
  }
};
const SpeedomotorsData = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to the first box on mount
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [value, setValue] = React.useState(6);
  console.log("🚀 ~ file: Weather.jsx:66 ~ Weather ~ value:", value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [index, setIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setIndex(activeIndex);
  };
  return (
    <WidgetWrapper
      //   opc={weatherData.enabled ? 1 : 0.1}
      sx={{ padding: "0.5rem 0 0.5rem 0" }}
      maxHeight="62px"
    >
      <Box display="flex" p="0 1rem" sx={{ overflowY: "hidden" }}>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          width="13%"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            تعداد کل
          </Typography>
          <CircularProgressBar
            value={100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              1750
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          width="14%"
          mr="0.2rem"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            دوشا پرشیر
          </Typography>
          <CircularProgressBar
            value={(200 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              200
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          width="15%"
          mr="0.2rem"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            دوشا کم شیر
          </Typography>
          <CircularProgressBar
            value={(857 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              857
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="1rem"
          mr="0.2rem"
          width="13%"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            باردار
          </Typography>
          <CircularProgressBar
            value={(350 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              350
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          width="14%"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            دام خشک
          </Typography>
          <CircularProgressBar
            value={(70 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              70
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          mr="0.2rem"
          width="15%"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            تلیسه آبستن
          </Typography>
          <CircularProgressBar
            value={(57 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              57
            </Typography>
          </CircularProgressBar>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{
              m: " 0 0.5rem",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.5rem"
          width="14%"
          mr="0.2rem"
          height="50px"
        >
          <Typography variant="smallestTextSecondary" color={alt} mt="1rem">
            تلیسه غیرآبستن
          </Typography>
          <CircularProgressBar
            value={(187 / 1750) * 100}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            color={theme.palette.others.third}
          >
            <Typography
              style={{
                direction: "ltr",
                color: theme.palette.others.third,
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              187
            </Typography>
          </CircularProgressBar>
        </Stack>
      </Box>
    </WidgetWrapper>
  );
};

export default SpeedomotorsData;
