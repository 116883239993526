import {
  Close,
  CompareOutlined,
  DownloadOutlined,
  FullscreenOutlined,
  PriorityHighOutlined,
  ShowChartOutlined,
  SpeedOutlined,
  StraightenOutlined,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import { useSelector } from "react-redux";

const ButtonsSensorsManagementAll = ({
  toggle,
  setToggle,
  individual,
  setMapModal,
  modal,
}) => {
  const mode = useSelector((state) => state.global.mode);
  return (
    <>
      <FlexBetween
        position="absolute"
        top={!individual ? "11rem" : "90px"}
        left={!individual ? "2rem" : "91%"}
        // right={individual ? "3.5%" : "none"}
        width="24px"
        height={!individual ? "130px" : "80px"}
        flexDirection="column"
        sx={{ zIndex: 1000 }}
      >
        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <PriorityHighOutlined
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>
        {!modal && (
          <Box
            sx={{
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton>
              <FullscreenOutlined
                onClick={() => setMapModal(true)}
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
        )}

        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <DownloadOutlined
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>

        {!individual && (
          <Box
            sx={{
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={() => setToggle(!toggle)}>
              {toggle ? (
                <ShowChartOutlined
                  sx={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              ) : (
                <SpeedOutlined
                  sx={{
                    width: "22px",
                    height: "22px",
                  }}
                />
              )}
            </IconButton>
          </Box>
        )}
      </FlexBetween>
      {modal && (
        <Box
          sx={{
            position: "absolute",
            top: "100px",
            left: "3rem",
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <IconButton>
            <Close
              onClick={() => setMapModal(false)}
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ButtonsSensorsManagementAll;
