import { AddOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";

import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
// import RecordSensorFormMob from "./RecordSensorFormMob";
import EventIcon from "../../assets/icon/eventImg.png";
import RecordEventFormMob from "./RecordEventFormMob";
import RefreshIcon from "../../assets/Refresh.png";
const mockData = [
  {
    name: "آقای احمدی",

    doneAction: "دوشیدن شیر بهاربند 1",
    doneDate: "1402/02/03",
    doneTime: "20:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "غذا دادن به گاو ها",
    doneDate: "1402/02/02",
    doneTime: "15:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "دوشیدن شیر",
    doneDate: "1402/02/01",
    doneTime: "11:00",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "غذا دادن به گاو ها",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "دوشیدن شیر بهاربند 2",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "غذا دادن به گاو ها",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "دوشیدن شیر بهاربند 3",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
  {
    name: "آقای احمدی",

    doneAction: "غذا دادن به گاو ها",
    doneDate: "1402/02/05",
    doneTime: "12:30",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد",
    image: EventIcon,
  },
];

const sortingOptions = {
  date: { active: false, label: "تاریخ ثبت" },
  name: { active: false, label: "نام" },
};

const EventItem = ({ item }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const dividerColor = theme.palette.background.forth;

  return (
    <Box
      p="0.7rem"
      sx={{
        "&:not(:last-child)": {
          borderBottom: `1px solid ${dividerColor}`,
        },
      }}
    >
      <FlexBetween>
        <Typography variant="smallestTextPrimary" color={main}>
          {item.doneAction}
        </Typography>
        <IconButton>
          <Box component="img" src={RefreshIcon} />
        </IconButton>
      </FlexBetween>

      <FlexBetween>
        <Typography variant="smallestTextPrimary" color={alt}>
          {item.doneDate + " " + item.doneTime}
        </Typography>
        <Box
          component={"img"}
          src={item.image}
          width="32px"
          height="32px"
          borderRadius="8px"
        />
      </FlexBetween>
    </Box>
  );
};
const RecordEventsMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const [activeSorting, setActiveSorting] = useState(sortingOptions);
  const [newEvent, setNewEvent] = useState(false);
  const [sortedData, setSortedData] = useState(mockData);
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const tmp = sortedData.filter(
      (item) =>
        item.name.toUpperCase().includes(value.toUpperCase()) ||
        item.title.toUpperCase().includes(value.toUpperCase())
    );
    setSortedData(tmp);
  };
  const handleClickSorter = (key) => {
    setActiveSorting((prev) => ({
      ...prev,
      [key]: { active: !prev[key].active, label: prev[key].label },
    }));

    switch (key) {
      case "date":
        setSortedData((prev) =>
          [...prev].sort((a, b) => a.doneDate.localeCompare(b.doneDate))
        );
        break;
      case "name":
        setSortedData((prev) =>
          [...prev].sort((a, b) =>
            a.name < b.name ? -1 : a.name > b.name ? 1 : 0
          )
        );
        break;
    }
  };
  return (
    <>
      <MobileBottomSidebar
        is_open={newEvent}
        setOpen={setNewEvent}
        height={"calc(100dvh - 120px"}
        initial_height="0px"
      >
        <Box minWidth="100%" height="100%">
          <Typography mb="1rem" variant="h3" color="fontColor.main">
            ثبت اقدام
          </Typography>
          <Box
            width="100%"
            height="calc(100% - 45px)"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            sx={{
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: 0,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            <RecordEventFormMob setNewEvent={setNewEvent} />
          </Box>
        </Box>
      </MobileBottomSidebar>
      <Box
        p="1rem 12px"
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100dvh - 112px)",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        <FlexBetween
          sx={{
            transition: "all 0.2s ease",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            borderRadius: "8px",
            height: "36px",
            mb: "1rem",
            width: "auto",
          }}
        >
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            value={search}
            onChange={handleSearch}
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "36px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
          <Search sx={{ ml: "0.2rem" }} />
        </FlexBetween>
        <WidgetWrapper mb="0.5rem">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h4" color={main}>
              اقدام ها
            </Typography>
            <Box
              // width="121px"
              px="1rem"
              height="36px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                p: "0 0.7rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setNewEvent(true);
              }}
            >
              <Stack direction="row" alignItems="center" gap="0.2rem">
                <AddOutlined sx={{ width: "24px", height: "24px" }} />
                <Typography>اقدام جدید</Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack
            direction="row"
            gap="0.5rem"
            alignItems="center"
            sx={{
              overflowY: "hidden !important",
              overflowX: "auto",
              overflow: "-moz-scrollbars-none",
              "-ms-scrollbar-style": "none",
              scrollbarWidth: "none",
              my: "1rem",
              px: "0.5rem",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {Object.entries(activeSorting).map(([key, { label, active }]) => (
              <Button
                key={key}
                onClick={() => handleClickSorter(key)}
                sx={{
                  whiteSpace: "nowrap",
                  color: main,
                  background: active
                    ? "linear-gradient(to bottom, #2A80F4,#1D1D41)"
                    : "linear-gradient(to bottom, #38385C,#38385C00)",
                  padding: "8px",
                  borderRadius: "8px",
                }}
              >
                {label}
              </Button>
            ))}
          </Stack>
          {sortedData.map((item) => (
            <EventItem item={item} />
          ))}
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default RecordEventsMob;
