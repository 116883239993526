import { CacheProvider } from "@emotion/react";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const handleSubmit = () => {};
const CultivationTypeSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const RecordInoculated = ({ setAddItem }) => {
  const theme = useTheme();
  const [farmName, setFarmName] = useState("");
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <form onSubmit={handleSubmit} validationSchema={CultivationTypeSchema}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              >
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      کد دام
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      تاریخ تلقیح
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      ساعت تلقیح
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: "1rem",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              >
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      نوع تلقیح
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      نوبت تلقیح
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>نر</MenuItem>
                    <MenuItem value={true}>ماده</MenuItem> */}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    minWidth: 250,
                    "& .MuiFormLabel-root": {
                      marginLeft: "-4px",
                    },
                    "& .MuiInputBase-input": {
                      height: "5px",
                    },
                    "& .MuiOutlinedInput-input:focused": {
                      borderColor: "green",
                    },
                    "& label.Mui-focused": {
                      color: "#F7F7F7",
                    },

                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#F7F7F7",
                      borderRadius: "8px",
                    },
                    "& .MuiOutlinedInput-root": {
                      color: "#F7F7F7 !important",
                      borderRadius: "8px",

                      "& fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&:hover fieldset": {
                        borderColor: "#F7F7F7",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#F7F7F7",
                      },
                    },
                  }}
                >
                  {age === "" ? (
                    <InputLabel
                      disableAnimation
                      shrink={false}
                      focused={false}
                      id="item_type_label"
                    >
                      شیرواری
                    </InputLabel>
                  ) : null}

                  <Select
                    id="item_type"
                    labelId="item_type_label"
                    value={age}
                    onChange={handleChange}
                  >
                    {/* <MenuItem value={false}>نر</MenuItem>
                    <MenuItem value={true}>ماده</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  mt: "1rem",
                  gap: "1rem",
                  "& .MuiInputBase-root ": {
                    height: "45px !important",
                  },
                }}
              >
                <CssTextField
                  label="شماره اسپرم"
                  variant="outlined"
                  style={{ width: "250px" }}
                />
              </Box>
            </Box>

            <Box display="flex" gap="1rem" mt="2.5rem">
              <Box
                sx={{
                  width: "250px",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.background.third,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setAddItem(false);
                }}
              >
                <Typography variant="h4">انصراف</Typography>
              </Box>
              <Box
                sx={{
                  width: "250px",
                  height: "44px",
                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h4">ثبت</Typography>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default RecordInoculated;
