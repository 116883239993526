import { DownloadOutlined, FullscreenOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import AirChartFill from "./AirChartFill";
import LineFillupY from "components/Charts/LineFillupY";
import LineChart from "components/lineChart/LineChart";
import Temprature from "../../assets/icon/temprature.svg";

const TempAir = ({ sensorData }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const max = Math.ceil(Math.max(...sensorData.map(({ v }) => v)));
  const min = Math.floor(Math.min(...sensorData.map(({ v }) => v)));
  const chartData = {
    wind: {
      data: sensorData,
      name: "باد",
    },
  };
  return (
    <Box>
      <FlexBetween>
        <Typography
          color={main}
          fontSize={theme.typography.smallestTextSecondary}
        >
          دما (سانتی گراد)
        </Typography>

        <FlexBetween gap="0.5rem">
          <DownloadOutlined sx={{ cursor: "pointer" }} />
          <FullscreenOutlined sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <Box
        sx={{
          height: "167.4px",
          transition: "height 0.2s ease",
          position: "relative",
          margin: "0.7rem",
        }}
      >
        {chartData && (
          <LineChart
            data={chartData}
            min={min}
            max={max}
            thresholds={[20, 25, 30, 35]}
            toolTipIcon={Temprature}
          />
        )}
      </Box>
    </Box>
  );
};

export default TempAir;
