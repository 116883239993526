import { Box, useTheme } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const SmartIndexexChart = ({
  yNumbers = false,
  fcolor = "rgba(255,116,16,0.1)",
  borderColor,
  data1,
  data2,
}) => {
  const theme = useTheme();
  const data = {
    labels: [
      "1401/10",
      "1401/11",
      "1401/12",
      "1402/01",
      "1402/02",
      "1402/03",
      "1402/04",
      "1402/05",
      "1402/06",
    ],
    datasets: [
      {
        lineTension: 0.4,
        label: "First dataset",
        data: data1 ? data1 : [0, 0, 0, 0, 0, 0, 0, 0, 0],
        fill: true,
        backgroundColor: fcolor,
        borderColor: borderColor ? borderColor : theme.palette.primary.alt,
      },
      {
        lineTension: 0.4,
        label: "Second dataset",
        data: data2 ? data2 : [0, 0, 0, 0, 0, 0, 0, 0, 0],
        fill: true,
        backgroundColor: "rgba(42,128,244,0.1)",
        borderColor: theme.palette.primary.main,
      },
    ],
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Line
        data={data}
        options={{
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              min: 0,
              max: 100,
              title: {
                display: false,
                text: "",
              },
              grid: {
                color: theme.palette.background.second,
                borderDash: [3, 3],
              },
              ticks: {
                stepSize: 50,
                display: true,
                color: theme.palette.fontColor.alt,
                callback: function (val, index) {
                  // Hide every 2nd tick label
                  const temp = val;
                  if (val === 0) {
                    return 0;
                  } else return `%${temp}`;
                },
              },
            },
            x: {
              title: {
                display: false,
                text: "",
              },
              grid: {
                borderDash: [3, 3],
                color: theme.palette.background.second,
              },
              ticks: {
                display: true,
                color: theme.palette.fontColor.alt,
              },
            },
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],

            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default SmartIndexexChart;
