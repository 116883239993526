import { Box, Typography, useMediaQuery } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Form from "components/Form/Form";
import React, { useEffect } from "react";
import LoginPic from "../../assets/Register.png";

import FormReset from "components/Form/FormReset";
import ResetPaswordMob from "mob/scenesMob/ResetPaswordMob/ResetPaswordMob";
const ResetPassword = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <>
      {isMobileScreens ? (
        <ResetPaswordMob />
      ) : (
        <FlexBetween
          sx={{
            width: "100vw",
            height: "100vh",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{ position: "absolute", width: "100%", height: "100%" }}
            component="img"
            src={LoginPic}
          />
          <Box
            sx={{
              width: "1200px",
              height: "600px",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                width: "36.375rem",
                height: "33.5rem",

                backgroundColor: "rgba(248, 248, 252, 0.3)",
                backdropFilter: "blur(7.5px)",
                borderRadius: "24px",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormReset />
            </Box>
          </Box>
          <Box position="absolute" zIndex="1000" sx={{ bottom: "30px" }}>
            <Typography color="#FAFAFC" fontSize="14px" mt="5rem" ml="2.5rem">
              کلیه حقوق این سامانه متعلق به شرکت پرتوتاپ رایان است
            </Typography>
          </Box>
        </FlexBetween>
      )}
    </>
  );
};

export default ResetPassword;
