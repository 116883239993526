import { createSlice } from '@reduxjs/toolkit';
import { SENSOR_MANAGEMENT_DEFAULT_PERIOD, TODAY } from 'constants/constant';

const initialState = {
    sensorsData: {},
    id: null,
    firstDate: SENSOR_MANAGEMENT_DEFAULT_PERIOD,
    secondDate: TODAY,
    date1: { year: null, month: null, day: null },
    date2: { year: null, month: null, day: null },
    lastTimeUpdate: '',
    alarms: null,
    devicesAlarm: null,
};

export const sensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        setSensorsData: (state, action) => {
            state.sensorsData = action.payload.sensorsData;
        },
        setId: (state, action) => {
            state.id = action.payload.id;
        },
        setFirstDate: (state, action) => {
            state.firstDate = action.payload.firstDate;
        },
        setSecondDate: (state, action) => {
            state.secondDate = action.payload.secondDate;
        },
        setDate1: (state, action) => {
            state.date1 = action.payload.date1;
        },
        setDate2: (state, action) => {
            state.date2 = action.payload.date2;
        },
        setLastTimeUpdate: (state, action) => {
            state.lastTimeUpdate = action.payload.lastTimeUpdate;
        },
        setAlarms: (state, action) => {
            state.alarms = action.payload.alarms;
        },
        setDevicesAlarm: (state, action) => {
            state.devicesAlarm = action.payload.devicesAlarm;
        },
    },
});

export const {
    setSensorsData,
    setId,
    setFirstDate,
    setSecondDate,
    setDate1,
    setDate2,
    setLastTimeUpdate,
    setAlarms,
    setDevicesAlarm,
} = sensorsSlice.actions;
export default sensorsSlice.reducer;
