import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";

import { useRef } from "react";
import { useEffect } from "react";

import "swiper/css";
import "swiper/css/navigation";
import W1 from "../../assets/WeatherIcons/static/cloudy-day-3.svg";
import W2 from "../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W3 from "../../assets/WeatherIcons/static/cloudy-day-1.svg";
const WeatherDaily = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    // Scroll to the first box on mount
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollWidth,
        behavior: "smooth",
      });
    }
  }, []);

  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [value, setValue] = React.useState(6);
  console.log("🚀 ~ file: Weather.jsx:66 ~ Weather ~ value:", value);

  return (
    <WidgetWrapper
      //   opc={weatherData.enabled ? 1 : 0.1}
      mt="12px"
      sx={{ padding: "0.5rem 0 0.5rem 0" }}
      maxHeight="56px"
    >
      <Box
        display="flex"
        p="0 1rem"
        sx={{
          overflowY: "hidden",
          overflowX: "auto",
          "&::-webkit-scrollbar": {
            height: 0, // Change this to adjust the width of the horizontal scrollbar
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        <Box
          whiteSpace="nowrap"
          display="flex"
          alignItems="center"
          width="75%"
          height="100%"
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
          >
            <Typography variant="smallestTextPrimary" color="fontColor.main">
              امروز
            </Typography>
            <Typography variant="smallestTextSecondary" color="fontColor.alt">
              06/05
            </Typography>
          </Stack>

          <Box component="img" src={W1} width="32px" height="32px" />
          <Stack direction="row" sx={{ direction: "ltr" }}>
            <Typography variant="body" color="fontColor.alt">
              26 <sup>°</sup> |
            </Typography>
            <Typography variant="body" color="fontColor.main">
              {" "}
              32 <sup>°</sup>
            </Typography>
          </Stack>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ m: "0 1rem" }} />
        <Box
          whiteSpace="nowrap"
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
          >
            <Typography variant="smallestTextPrimary" color="fontColor.main">
              دوشنبه
            </Typography>
            <Typography variant="smallestTextSecondary" color="fontColor.alt">
              06/06
            </Typography>
          </Stack>

          <Box component="img" src={W1} width="32px" height="32px" />
          <Stack direction="row" sx={{ direction: "ltr" }}>
            <Typography variant="body" color="fontColor.alt">
              21 <sup>°</sup> |
            </Typography>
            <Typography variant="body" color="fontColor.main">
              {" "}
              30 <sup>°</sup>
            </Typography>
          </Stack>
        </Box>
        <Divider orientation="vertical" flexItem sx={{ m: "0 1rem" }} />
        <Box
          whiteSpace="nowrap"
          display="flex"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
          >
            <Typography variant="smallestTextPrimary" color="fontColor.main">
              سه شنبه
            </Typography>
            <Typography variant="smallestTextSecondary" color="fontColor.alt">
              06/07
            </Typography>
          </Stack>

          <Box component="img" src={W1} width="32px" height="32px" />
          <Stack direction="row" sx={{ direction: "ltr" }}>
            <Typography variant="body" color="fontColor.alt">
              16 <sup>°</sup> |
            </Typography>
            <Typography variant="body" color="fontColor.main">
              {" "}
              22 <sup>°</sup>
            </Typography>
          </Stack>
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default WeatherDaily;
