import { MessageOutlined } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const DetailStaffGrid = ({ item }) => {
  console.log(
    "🚀 ~ file: DetailStaffGrid.jsx:7 ~ DetailStaffGrid ~ item:",
    item
  );
  const theme = useTheme();

  const subHeading = theme.typography.subHeading;
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box
      width="100%"
      m="1rem 0"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <Stack gap="0.5rem" width="50%">
        <Typography fontSize={subHeading} color={alt}>
          توضیحات :
        </Typography>
        <Typography width="25rem" fontSize={subHeading} color={main}>
          {item.description}
        </Typography>
      </Stack>
      <Stack width="30%">
        <Typography fontSize={subHeading} color={alt}>
          اعلان از طریق :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography width="25rem" fontSize={subHeading} color={main}>
            {item.notification}
          </Typography>
        </Box>
      </Stack>
      <Stack width="20%">
        <Typography fontSize={subHeading} color={alt}>
          اقدام جایگزین :
        </Typography>
        <Box display="flex" alignItems="flex-end">
          <Typography width="25rem" fontSize={subHeading} color={main}>
            {item.alt !== "" ? item.alt : "-"}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default DetailStaffGrid;
