import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";

Chart.register(...registerables);
Chart.register(annotationPlugin);
const AirChart = ({ th2, fillColor, sensorData }) => {
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  const thresholdValue = 30;
  const firstDate = useSelector((state) => state.sensors.date1);
  const secondDate = useSelector((state) => state.sensors.date2);

  function areDatesEqual(date1, date2) {
    return (
      date1.year === date2.year &&
      date1.month === date2.month &&
      date1.day === date2.day
    );
  }
  const dataLabel = [];
  const dataSensor = [];
  const chartBarData = [];

  if (sensorData) {
    sensorData.map((item) => {
      const date = new Date(item.ts);

      const persianDate = date.toLocaleDateString("fa-IR-u-ca-persian", {
        // year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      const persianTime = date.toLocaleTimeString("fa-IR-u-ca-persian", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });

      dataLabel.push(
        areDatesEqual(firstDate, secondDate) ? persianTime : persianDate
      );
      dataSensor.push(item.v);
      chartBarData.push(50);
    });
  }
  const chartData = sensorData ? dataSensor : [0, 0, 0, 0, 0, 0, 0];
  const dataLabels = sensorData ? dataLabel : [1, 2, 3, 4, 5, 6, 7];
  const backgroundcolor = [];
  for (let i = 0; i < chartData.length; i++) {
    if (chartData[i] < 30) {
      backgroundcolor.push("rgba(47,128,237,0.3");
    }
    if (chartData[i] >= 30) {
      backgroundcolor.push("rgba(252,73,73,0.3");
    }
  }
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p1.parsed.y > 30 || ctx.p0.parsed.y > 30 ? value : undefined;
  const data = {
    labels: dataLabels,
    datasets: [
      {
        type: "bar",
        label: "First dataset",
        data: chartBarData,
        barThickness: 25,
        backgroundColor: "rgba(42,128,244,0.1)",
        options: {
          tooltips: { enabled: false },
        },
      },
      {
        lineTension: 0,
        label: "Second dataset",
        data: chartData,
        showLine: true,
        fill: true,
        //  {
        //   target: {
        //     value: 30,
        //   },
        //   above: "red",
        //   below: "green",
        // },
        backgroundColor: backgroundcolor,
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          console.log("val", value);
          return value > 30 ? "blue" : theme.palette.others.first;
        },
        segment: {
          backgroundColor: (ctx) =>
            skipped(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3") ||
            down(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3"),
          borderColor: (ctx) =>
            skipped(ctx, fillColor ? fillColor : theme.palette.others.second) ||
            down(ctx, fillColor ? fillColor : theme.palette.others.second),
          // borderDash: (ctx) => skipped(ctx, [6, 6]),
          // fill: {
          //   target: {
          //     value: 30,
          //   },
          //   above: "red",
          //   below: "green",
          // },
        },
        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > 30 ? "rgba(255,0,0,0.3)" : "green";
        },
        pointBackgroundColor: "red",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };
  return (
    <Box
      mt="1.5rem"
      sx={{
        width: "100%",
        height: "160px",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "rgba(255,255,255,0.8)",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },
              annotation: {
                annotations: {
                  line1: {
                    type: "line",
                    yMin: thresholdValue,
                    yMax: thresholdValue,
                    borderColor: theme.palette.others.second,
                    borderWidth: 1,
                  },
                  line2: th2
                    ? {
                        type: "line",
                        yMin: 15,
                        yMax: 15,
                        borderColor: theme.palette.others.second,
                        borderWidth: 1,
                      }
                    : null,
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.second,
                  borderDash: [3, 3],
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  borderDash: [3, 3],
                  color: theme.palette.background.second,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default AirChart;
