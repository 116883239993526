import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "jalali-moment";

Chart.register(...registerables);
Chart.register(annotationPlugin);
const ActuatorChart = ({
  th2,
  fillColor,
  sensorData,
  min,
  max,
  stepSize,
  thv1,
  thv2,
  h,
}) => {
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  // const thresholdValue = 30;

  const chartBarData = [0, 0, 0, 1250, 600, 1000, 1500, 920];

  const dataLabels = [
    "00:00",
    "02:00",
    "04:00",
    "06:00",
    "08:00",
    "10:00",
    "12:00",
    "14:00",
  ];

  const data = {
    labels: dataLabels,
    datasets: [
      {
        type: "bar",
        label: "First dataset",
        data: chartBarData,
        barThickness: 25,
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(54,162,256,1)",
            "rgba(54,162,256,0.6)",
            "rgba(54,162,256,0.05)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);

          return gradientBg;
        },
        options: {
          tooltips: { enabled: false },
        },
      },
    ],
  };
  return (
    <Box
      mt="1.5rem"
      sx={{
        opacity: 1,
        width: "100%",
        height: h ? h : "160px",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "rgba(255,255,255,0.8)",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },
              annotation: {
                annotations: {
                  line1: {
                    type: "line",
                    yMin: thv1,
                    yMax: thv1,
                    borderColor: theme.palette.others.second,
                    borderWidth: 1,
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                min: min,
                max: max,
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.third,
                  borderDash: [3, 3],
                },
                ticks: {
                  stepSize: stepSize,
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  borderDash: [3, 3],
                  color: theme.palette.background.third,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: true,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: true,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default ActuatorChart;
