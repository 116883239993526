import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";

// import CloudyDD from "../../../assets/icon/DarkWeather/sunny - cloudy.png";
// import CloudyDN from "../../../assets/icon/DarkWeather/night - cloudy.png";
// import ClearDD from "../../../assets/icon/DarkWeather/Sunny.png";
// import ClearDN from "../../../assets/icon/DarkWeather/night.png";
// import RainyDD from "../../../assets/icon/DarkWeather/sunny - rainy.png";
// import RainyDN from "../../../assets/icon/DarkWeather/Night - Rainy.png";
// import SnowyDD from "../../../assets/icon/DarkWeather/snowy.png";
// import ThunderDD from "../../../assets/icon/DarkWeather/firelight.png";

// import CloudyLD from "../../../assets/icon/LightWeather//sunny - cloudy.png";
// import CloudyLN from "../../../assets/icon/LightWeather/night - cloudy.png";
// import ClearLD from "../../../assets/icon/LightWeather/Sunny.png";
// import ClearLN from "../../../assets/icon/LightWeather/night.png";
// import RainyLD from "../../../assets/icon/LightWeather/sunny - rainy.png";
// import RainyLN from "../../../assets/icon/LightWeather/Night - Rainy.png";
// import SnowyLD from "../../../assets/icon/LightWeather/snowy.png";
// import ThunderLD from "../../../assets/icon/LightWeather/firelight.png";
import W1 from "../../../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../../../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../../../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../../../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../../../assets/WeatherIcons/static/day.svg";
import W7 from "../../../assets/WeatherIcons/static/rainy-7.svg";
import DailyWeatherFluctuations from "components/ToleranceDailyWeather/DailyWeatherFluctuations";
import { useSelector } from "react-redux";

const getIcon = (condition) => {
  switch (condition) {
    case "CLEAR":
      return W1;
    case "ATMOSPHERE":
      return W2;
    case "CLOUDS":
      return W3;
    case "DRIZZLE":
      return W4;
    case "RAIN":
      return W5;
    case "SNOW":
      return W6;
    case "THUNDERSTORM":
      return W7;

    default:
      break;
  }
};
const DailyMob = ({
  min,
  max,
  minMinTemperature,
  maxMaxTemperature,
  dayName,
  monthDay,
  condition,
  day,
  index,
  mob,
}) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="60px"
    >
      <Box
        display="flex"
        width="50%"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Typography variant="h5" color={theme.palette.fontColor.main}>
            {mob ? dayName : null}
          </Typography>
          <Typography
            variant="smallestTextSecondary"
            color={theme.palette.fontColor.alt}
          >
            {mob ? monthDay : null}
          </Typography>
        </Stack>
        {mob ? (
          <Box
            component="img"
            src={getIcon(condition)}
            width="40px"
            height="40px"
          />
        ) : null}
      </Box>
      <DailyWeatherFluctuations
        minMinTemperature={minMinTemperature}
        maxMaxTemperature={maxMaxTemperature}
        min={min}
        max={max}
      />
    </Box>
  );
};

export default DailyMob;
