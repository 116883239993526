import { Box, useTheme } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

const ChartSensorsManagement = ({ dataChart }) => {
  console.log(
    "🚀 ~ file: ChartSensorsManagement.jsx:8 ~ ChartSensorsManagement ~ dataChart:",
    dataChart
  );

  const dataLabels = [];
  const chartData = [];
  dataChart.map((item, index) => {
    dataLabels.push(item.ts);
    chartData.push(item.v);
  });
  console.log(
    "🚀 ~ file: ChartSensorsManagement.jsx:15 ~ ChartSensorsManagement ~ chartData:",
    chartData
  );

  const theme = useTheme();
  const data = {
    labels: dataLabels,
    datasets: [
      {
        lineTension: 0.2,
        label: "First dataset",
        data: chartData,

        backgroundColor: "rgba(255,116,16,0.1)",
        borderColor: theme.palette.others.first,
      },
    ],
  };
  return (
    <Box
      sx={{
        width: "11.125rem",
        height: "5.188rem",
      }}
    >
      <Line
        data={data}
        options={{
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: false,
                text: "",
              },

              grid: {
                // display: false,
                lineWidth: ({ tick }) => (tick.value === 0 ? 2 : 0),
              },
              ticks: {
                display: false,
              },
            },
            x: {
              title: {
                display: false,
                text: "",
              },
              grid: {
                lineWidth: ({ tick }) => (tick.value === 0 ? 0 : 0),
              },
              ticks: {
                display: false,
              },
            },
            yAxes: [
              {
                gridLines: {},
              },
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],

            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default ChartSensorsManagement;
