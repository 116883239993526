import { Circle, MoreHoriz } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import ProgressBar from "components/progressbar/ProgressBar";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";
import SmartIndexChart from "../../components/SmartIndexChart/SmartIndexChart";

const SmartIndexGrowth = () => {
  const smartIndexData = useSelector((state) => state.dashboard.index);

  const theme = useTheme();
  const alt = theme.palette.fontColor.alt;
  return (
    <WidgetWrapper sx={{ position: "relative", height: "100%" }}>
      {/* {!smartIndexData.enabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,

            width: "100%",
            height: "100%",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            width="88px"
            height="27px"
            sx={{
              backgroundColor: theme.palette.others.first,
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              فعال سازی
            </Typography>
          </Box>
        </Box>
      )} */}
      {/* <Box
        width="100%"
        height="95%"
        sx={{ opacity: smartIndexData.enabled ? 1 : 0.1 }}
      > */}
      <Box width="100%" height="95%">
        <FlexBetween>
          <Typography variant="h5" mb="0.1rem">
            شاخص هوشمند گیاه
          </Typography>
          <MoreHoriz sx={{ cursor: "pointer" }} />
        </FlexBetween>
        <FlexBetween>
          <FlexBetween gap="0.5rem" sx={{ justifyContent: "flex-start" }}>
            <FlexBetween>
              <Circle
                sx={{
                  width: "8px",
                  height: "8px",
                  color: theme.palette.primary.alt,
                  ml: "2px",
                }}
              />
              <Typography variant="smallestTextPrimary" color={alt}>
                وضعیت گیاه
              </Typography>
            </FlexBetween>
            <FlexBetween>
              <Circle
                sx={{
                  width: "8px",
                  height: "8px",
                  color: theme.palette.primary.main,
                  ml: "2px",
                }}
              />

              <Typography variant="smallestTextPrimary" color={alt}>
                شاخص نرمال
              </Typography>
            </FlexBetween>
          </FlexBetween>
          <FlexBetween sx={{ flexDirection: "column" }}>
            <FlexBetween gap="1rem">
              <Typography variant="smallestTextPrimary" color={alt}>
                وضعیت گیاه
              </Typography>
              <Typography variant="h4" color={theme.palette.others.third}>
                88%
              </Typography>
            </FlexBetween>
            <Box
              width="8.313rem"
              height="0.25rem"
              sx={{
                backgroundColor: theme.palette.background.forth,
                position: "relative",
                borderRadius: "1rem",
              }}
            >
              <ProgressBar
                bgcolor={theme.palette.others.third}
                completed={78}
              />
            </Box>
          </FlexBetween>
        </FlexBetween>
        <Box mt="1.55rem" sx={{ width: "100%", height: "80%" }}>
          <SmartIndexChart />
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default SmartIndexGrowth;
