import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import profileImage from "../../assets/profile.jpg";
import { GiCow, GiPencilBrush } from "react-icons/gi";
import DashIcon from "../../assets/dash.svg";
import AirIcon from "../../assets/airdrop.svg";
import {
  Badge,
  Box,
  Drawer,
  Fade,
  IconButton,
  List,
  ListItemText,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";

import {
  QueryStats,
  QueryStatsOutlined,
  DashboardOutlined,
  HeadsetMicOutlined,
  ShoppingCartOutlined,
  CalculateOutlined,
  AirplaneTicketOutlined,
  CloudOutlined,
  Add,
  RecommendOutlined,
  ReportProblemOutlined,
  Dashboard,
  Recommend,
  ReportProblem,
  Cloud,
  AirplaneTicket,
  HeadsetMic,
  ShoppingCart,
  SatelliteAlt,
  SatelliteAltOutlined,
  ForumOutlined,
  Forum,
  LeaderboardOutlined,
  Leaderboard,
  ToggleOffOutlined,
  ToggleOff,
  PeopleAltOutlined,
  PeopleAlt,
  AccountBalanceOutlined,
  AccountBalance,
  Inventory2Outlined,
  Inventory2,
  Calculate,
  SettingsRemoteOutlined,
  SettingsRemote,
  SchoolOutlined,
  School,
  MessageOutlined,
  Message,
  GrassOutlined,
  Grass,
  SettingsOutlined,
  Settings,
  PriorityHighOutlined,
  PriorityHigh,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import FlexBetween from "components/FlexBetween/FlexBetween";
import HealtMgmtOutLinedIcon from "../../assets/Icons/healthicons_animal-cow-outline.png";
import HealthMgmtIcon from "../../assets/Icons/healthicons_animal-cow.png";
import CompareOutlinedIcon from "../../assets/Icons/Comparison.png";
import CompareIcon from "../../assets/Icons/check.png";
const navItems = [
  {
    text: "مدیریت جامع",
    t: "management",
    icon: <DashboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Dashboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "مدیریت دام",
    t: "livestockmanagement",
    icon: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={HealtMgmtOutLinedIcon}
      />
    ),
    iconFilled: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={HealthMgmtIcon}
      />
    ),
  },
];
const navItemsI = [
  {
    text: "مدیریت پرسنل",
    t: "staffmgmt",
    icon: <PeopleAltOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <PeopleAlt sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "هشدارها",
    t: "alarms",
    iconOpenDrawer: (
      <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: (
      <ReportProblem sx={{ width: "24px", height: "24px" }} />
    ),
    icon: <ReportProblemOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ReportProblem sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "عملگر ها",
    t: "actuator",
    icon: <ToggleOffOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ToggleOff sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "مقایسه",
    t: "compare",
    icon: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={CompareOutlinedIcon}
      />
    ),
    iconFilled: (
      <Box
        sx={{ width: "24px", height: "24px" }}
        component="img"
        src={CompareIcon}
      />
    ),
  },
  {
    text: "توصیه ها",
    t: "recommends",
    iconOpenDrawer: (
      <RecommendOutlined sx={{ width: "24px", height: "24px" }} />
    ),
    iconFilledOpenDrawer: <Recommend sx={{ width: "24px", height: "24px" }} />,
    icon: <RecommendOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Recommend sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "پیام رسان",
    t: "messenger",
    icon: <ForumOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Forum sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "گزارشات",
    t: "report",
    icon: <LeaderboardOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Leaderboard sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "کیف پول",
    t: "wallet",
    icon: <AccountBalanceOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <AccountBalance sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "شاخص هوشمند",
    t: "smartindexes",
    icon: <QueryStatsOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <QueryStats sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "هواشناسی",
    t: "weather",
    icon: <CloudOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Cloud sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "تیکت",
    t: "ticket",
    icon: <MessageOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Message sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "تنظیمات",
    t: "setting",
    icon: <SettingsOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <Settings sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "راهنما",
    t: "help",
    icon: <PriorityHighOutlined style={{ width: "24px", height: "24px" }} />,
    iconFilled: <PriorityHigh sx={{ width: "24px", height: "24px" }} />,
  },
];
const navItemsEq = [
  {
    text: "آموزش",
    t: "education",
    icon: <SchoolOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <School sx={{ width: "24px", height: "24px" }} />,
  },

  {
    text: "فروشگاه",
    t: "shopping",
    icon: <ShoppingCartOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <ShoppingCart sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "حسابداری",
    t: "shoppingg",
    icon: <CalculateOutlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Calculate sx={{ width: "24px", height: "24px" }} />,
  },
  {
    text: "انبارداری",
    t: "shoppinggg",
    icon: <Inventory2Outlined sx={{ width: "24px", height: "24px" }} />,
    iconFilled: <Inventory2 sx={{ width: "24px", height: "24px" }} />,
  },
];
const Sidebar = ({ isNonMobile }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const mode = useSelector((state) => state.global.mode);
  const alarmCount = useSelector((state) => state.global.alarmCount);
  const [value, setValue] = React.useState(0);
  const [becomeHover, setBecomeHover] = useState();
  const [record, setRecord] = useState(false);
  const handleMouseOut = (e) => {
    setRecord(false);
    if (true) {
      const menu = document.getElementById("openD");

      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };

      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }

    setIsSidebarOpen(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);
  return (
    <Box component="nav">
      <div>
        <Drawer
          open={true}
          onClose={() => setIsSidebarOpen(true)}
          variant="persistent"
          anchor="right"
          id="openD"
          sx={{
            width: "80px",
            display: "flex",
            flexDirection: "column",

            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.second,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: isSidebarOpen ? "80px" : "230px",
              transition: "all ease-in-out 0.3s",
              boxShadow: !isSidebarOpen
                ? "0 0 30px rgba(0, 0, 0, 0.4)"
                : "none",
            },
          }}
          onMouseEnter={() => setIsSidebarOpen(false)}
          onMouseLeave={handleMouseOut}
        >
          <Box
            width="90%"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              mr: "0.5rem",
              height: "100%",

              justifyContent: "flex-start",
              overflowX: "hidden",
              marginTop: "1rem",
            }}
          >
            <List>
              {/* LOGO */}
              <Box
                sx={{
                  mr: isSidebarOpen ? 0 : "35%",
                  transition: "all ease 0.3s",
                }}
              >
                {mode === "dark" ? (
                  <img
                    alt="img1"
                    src={require("../../assets/LogoColor.png")}
                    style={{
                      width: "52px",
                      height: "40px",
                      objectFit: "cover",
                      marginBottom: "1rem",
                    }}
                  ></img>
                ) : (
                  <img
                    alt="img1"
                    src={require("../../assets/LogoColor.png")}
                    style={{
                      width: "52px",
                      height: "40px",
                      objectFit: "cover",
                    }}
                  ></img>
                )}
              </Box>

              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="none"
                sx={{
                  mt: "1rem",
                  direction: "rtl",
                  height: "104px",
                  width: isSidebarOpen ? "60px" : "209px",
                  transition: "all ease-in-out 0.3s",
                  display: "flex",
                  p: "0 0.6rem",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  backgroundColor: theme.palette.background.third,
                  borderRadius: "12px",
                  "&	.MuiTabs-scrollButtons": {
                    height: "25px",
                  },
                  "& .MuiTabs-indicator": {
                    width: 0,
                  },
                }}
              >
                {navItems.map(({ text, icon, t, iconFilled }) => {
                  const lcText = t.toLocaleLowerCase();

                  return (
                    <Box
                      width={isSidebarOpen ? "40px" : "190px"}
                      height="40px"
                      key={t}
                      mt="0.3rem"
                      mb="0.5rem"
                      sx={{
                        transition: "all ease-in-out 0.3s",
                      }}
                    >
                      <IconButton
                        sx={{
                          width: isSidebarOpen ? "40px" : "190px",
                          transition: "all ease-in-out 0.3s",

                          height: "40px",
                          backgroundColor:
                            active === lcText
                              ? theme.palette.primary.main
                              : "transparent",
                          color:
                            mode === "dark"
                              ? active === lcText
                                ? "white"
                                : "white"
                              : active === lcText
                              ? "white"
                              : theme.palette.fontColor.alt,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "12px",
                        }}
                        onMouseEnter={() => {
                          setBecomeHover(lcText);
                        }}
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          // setIsSidebarOpen(false);
                        }}
                      >
                        <FlexBetween gap="1rem">
                          {active === lcText
                            ? iconFilled
                            : becomeHover === lcText
                            ? iconFilled
                            : icon}
                          {isSidebarOpen ? null : (
                            <ListItemText primary={text} />
                          )}
                        </FlexBetween>
                      </IconButton>
                    </Box>
                  );
                })}
              </Tabs>
            </List>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                mt: "0.5rem",
                direction: "rtl",
                height: "218px",
                width: isSidebarOpen ? "60px" : "209px",
                transition: "all ease-in-out 0.3s",
                p: "0 0.6rem",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                backgroundColor: theme.palette.background.third,
                borderRadius: "12px",
                "&	.MuiTabs-scrollButtons": {
                  height: "25px",
                },
                "& .MuiTabs-indicator": {
                  width: 0,
                },
              }}
            >
              {navItemsI.map(({ text, icon, t, iconFilled }) => {
                const lcText = t.toLocaleLowerCase();

                return (
                  <Box
                    width={isSidebarOpen ? "40px" : "190px"}
                    transition="all ease-in-out 0.3s"
                    height="40px"
                    key={t}
                    mb="0.3rem"
                    sx={{
                      transition: "all ease-in-out 0.3s",
                    }}
                  >
                    <IconButton
                      sx={{
                        width: isSidebarOpen ? "40px" : "190px",
                        transition: "all ease-in-out 0.3s",

                        height: "40px",
                        backgroundColor:
                          active === lcText
                            ? theme.palette.primary.main
                            : "transparent",
                        color:
                          mode === "dark"
                            ? active === lcText
                              ? "white"
                              : "white"
                            : active === lcText
                            ? "white"
                            : theme.palette.fontColor.alt,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderRadius: "12px",
                      }}
                      onMouseEnter={() => {
                        setBecomeHover(lcText);
                      }}
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                        // setIsSidebarOpen(false);
                      }}
                    >
                      <FlexBetween gap="1rem">
                        {active === lcText
                          ? iconFilled
                          : becomeHover === lcText
                          ? iconFilled
                          : icon}
                        {isSidebarOpen ? null : <ListItemText primary={text} />}
                      </FlexBetween>
                    </IconButton>
                  </Box>
                );
              })}
            </Tabs>
            <Box
              mt="1rem"
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Typography
                mr="0.2rem"
                variant="body"
                color={theme.palette.fontColor.alt}
              >
                امکانات
              </Typography>
              <Tabs
                orientation="vertical"
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  direction: "rtl",
                  height: "142px",
                  width: isSidebarOpen ? "60px" : "209px",
                  transition: "all ease-in-out 0.3s",
                  p: "0 0.6rem",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  backgroundColor: theme.palette.background.third,
                  borderRadius: "12px",
                  "&	.MuiTabs-scrollButtons": {
                    height: "25px",
                  },
                  "& .MuiTabs-indicator": {
                    width: 0,
                  },
                }}
              >
                {navItemsEq.map(({ text, icon, t, iconFilled }) => {
                  const lcText = t.toLocaleLowerCase();

                  return (
                    <Box
                      width={isSidebarOpen ? "40px" : "190px"}
                      height="40px"
                      key={t}
                      mb="0.3rem"
                    >
                      <IconButton
                        sx={{
                          width: isSidebarOpen ? "40px" : "190px",
                          transition: "all ease-in-out 0.3s",
                          height: "40px",
                          backgroundColor:
                            active === lcText
                              ? theme.palette.primary.main
                              : "transparent",
                          color:
                            mode === "dark"
                              ? active === lcText
                                ? "white"
                                : "white"
                              : active === lcText
                              ? "white"
                              : theme.palette.fontColor.alt,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: "12px",
                        }}
                        onMouseEnter={() => {
                          setBecomeHover(lcText);
                        }}
                        onClick={() => {
                          navigate(`/${lcText}`);
                          setActive(lcText);
                          // setIsSidebarOpen(false);
                        }}
                      >
                        <FlexBetween gap="1rem">
                          {active === lcText
                            ? iconFilled
                            : becomeHover === lcText
                            ? iconFilled
                            : icon}
                          {isSidebarOpen ? null : (
                            <ListItemText primary={text} />
                          )}
                        </FlexBetween>
                      </IconButton>
                    </Box>
                  );
                })}
              </Tabs>
              <Box
                height="72px"
                sx={{
                  width: isSidebarOpen ? "60px" : "209px",

                  mt: "1rem",
                  backgroundColor: isSidebarOpen
                    ? "inherit"
                    : theme.palette.background.third,
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  p: isSidebarOpen ? 0 : "0 2rem",
                  transition: "all ease 0.3s",
                  borderRadius: "12px",
                  position: "relative",
                }}
              >
                <IconButton
                  sx={{
                    width: "60px",
                    height: "60px",
                    transition: "all ease 0.3s",
                    backgroundColor: isSidebarOpen
                      ? "inherit"
                      : theme.palette.others.first,
                    borderRadius: "12px",
                    // mt: "1.5rem",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setRecord(!record)}
                >
                  <Add sx={{ fontSize: "40px" }} />
                </IconButton>
                <ListItemText
                  sx={{
                    width: isNonMobile ? "0" : "100%",
                    opacity: isNonMobile ? 1 : 0,
                    transition: "all ease 0.3s",
                  }}
                  primary={"ثبت"}
                />
                {record && (
                  <Box
                    sx={{
                      width: "209px",
                      height: "220px",
                      position: "absolute",
                      top: -200,
                      right: 0,
                    }}
                  >
                    <Fade in={record} timeout={2000}>
                      <Box
                        width="209px"
                        height="45px !important"
                        borderRadius="12px"
                        sx={{
                          mb: "0.5rem",
                          cursor: "pointer",
                          backgroundColor: theme.palette.others.first,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => navigate("/recordSensors")}
                      >
                        <Typography variant="body" position="absolute" top="10">
                          ثبت کاربر
                        </Typography>
                      </Box>
                    </Fade>
                    <Fade in={record} timeout={1500}>
                      <Box
                        width="209px"
                        height="45px !important"
                        borderRadius="12px"
                        sx={{
                          mb: "0.5rem",
                          cursor: "pointer",
                          backgroundColor: theme.palette.others.first,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => navigate("/recordSensors")}
                      >
                        <Typography variant="body" position="absolute" top="10">
                          ثبت سنسور
                        </Typography>
                      </Box>
                    </Fade>
                    <Fade in={record} timeout={1000}>
                      <Box
                        width="209px"
                        height="45px !important"
                        borderRadius="12px"
                        sx={{
                          mb: "0.5rem",
                          cursor: "pointer",
                          backgroundColor: theme.palette.others.first,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => navigate("/recordEvents")}
                      >
                        <Typography variant="body" position="absolute" top="10">
                          ثبت وقایع
                        </Typography>
                      </Box>
                    </Fade>

                    <Fade in={record} timeout={500}>
                      <Box
                        width="209px"
                        height="45px !important"
                        borderRadius="12px"
                        sx={{
                          mb: "0.5rem",
                          cursor: "pointer",
                          backgroundColor: theme.palette.others.first,

                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => navigate("/recordFarm")}
                      >
                        <Typography variant="body" position="absolute" top="10">
                          ثبت دامداری
                        </Typography>
                      </Box>
                    </Fade>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Drawer>
      </div>
    </Box>
  );
};

export default Sidebar;
