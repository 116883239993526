import L from "leaflet";
import { Box, IconButton, Modal, styled, useTheme } from "@mui/material";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import GG2Icon1 from "../../assets/Sensor Icon/G-G 2.png";
import { useState } from "react";
import { Close, FullscreenOutlined } from "@mui/icons-material";
const MapBox = styled(Box)(({ theme, mode }) => ({
  zIndex: 100,
  position: "relative",
  width: "95% !important",
  padding: "0px",
  maxHeight: "80%",
  "& .leaflet-left": {
    display: "none",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));
var GG1Icon = L.icon({
  iconUrl: GG1Icon1,
  iconSize: [52, 54],
  iconAnchor: [10, 54],
  popupAnchor: [2, -10],
});
var GG2Icon = L.icon({
  iconUrl: GG2Icon1,
  iconSize: [52, 54],
  iconAnchor: [10, 54],
  popupAnchor: [2, -10],
});

function MultipleMarkers({ data, selectedSensor, setSelectedSensor }) {
  return data.map((item, index) => (
    <Marker
      position={[item.position[0], item.position[1]]}
      eventHandlers={{
        click: (e) => {
          setSelectedSensor(item.id);
        },
      }}
      key={index}
      icon={item.id === selectedSensor ? GG2Icon : GG1Icon}
    ></Marker>
  ));
}

const ReportSensorDataMap = ({ data, selectedSensor, setSelectedSensor }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [mapModal, setMapModal] = useState(false);
  return (
    <MapBox mode={mode}>
      <Box
        sx={{
          position: "absolute",
          top: "12px",
          right: "12px",
          zIndex: 500,
          width: "24px",
          height: "24px",
          backgroundColor:
            mode === "dark"
              ? "rgba(29, 29, 65, 0.7)"
              : "rgba(250, 250, 252, 0.7)",
          backdropFilter: "blur(7.5px)",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton sx={{ p: "4px" }} onClick={() => setMapModal(true)}>
          <FullscreenOutlined
            sx={{
              width: "22px",
              height: "22px",
            }}
          />
        </IconButton>
      </Box>
      <Modal
        open={mapModal}
        onClose={() => setMapModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 2000,
          "& .leaflet-left": {
            left: "14px",
          },
          "& .leaflet-bar": {
            border: "none",
          },
          "& .leaflet-control-zoom-in": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7) !important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "8px 8px 0 0 !important",
          },

          "& .leaflet-touch .leaflet-bar a": {
            lineHeight: "24px !important",
          },
          "& .leaflet-control-zoom-out": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7 )!important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "0 0 8px 8px !important",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.second,
            "& .leaflet-container": {
              height: "100% !important",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "120px",
              right: "42px",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <Close
                onClick={() => setMapModal(false)}
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <MapContainer
            center={[35.646029, 52.223528]}
            zoom={15.5}
            style={{ borderRadius: "8px", height: "241px", width: "100%" }}
          >
            <TileLayer
              attribution="PTR"
              url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              maxZoom={22}
              maxNativeZoom={18}
            />
            {/* <MultipleMarkers
              data={data}
              selectedSensor={selectedSensor}
              setSelectedSensor={setSelectedSensor}
            /> */}
          </MapContainer>
        </Box>
      </Modal>

      <MapContainer
        center={[35.646029, 52.223528]}
        zoom={16}
        style={{ borderRadius: "8px", height: "160px", width: "100%" }}
      >
        <TileLayer
          attribution="PTR"
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        {/* <ChangeView center={[35.646029, 52.223528]} zoom={16} /> */}
        {/* <MultipleMarkers
          data={data}
          selectedSensor={selectedSensor}
          setSelectedSensor={setSelectedSensor}
        /> */}
      </MapContainer>
    </MapBox>
  );
};

export default ReportSensorDataMap;
