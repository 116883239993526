import WalletMob from "mob/WalletMob/WalletMob";
import React from "react";
import Wallet from "./Wallet";
import { useMediaQuery } from "@mui/material";

const WalletIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <WalletMob /> : <Wallet />}</>;
};

export default WalletIndex;
