import React from "react";
import { useMediaQuery } from "@mui/material";

import SmartIndexes from "./SmartIndexes";
import SmartIndexesMob from "mob/scenesMob/SmartIndexesMob/SmartIndexesMob";

const SmartIndexesIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <SmartIndexesMob /> : <SmartIndexes />}</>;
};

export default SmartIndexesIndex;
