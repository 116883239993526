import { Box, Divider, Stack, Tabs, Typography, useTheme } from '@mui/material';
import CircularProgressBar from 'components/circularProgressBar/CircularProgressBar';
import CustomGaugeWeather from 'components/CustomGaugeWeather/CustomGaugeWeather';
import FlexBetween from 'components/FlexBetween/FlexBetween';
//

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetColor } from 'Utils/weather';
const AirSensorsDoughnut = () => {
    const theme = useTheme();
    const data = useSelector((state) => state.sensors.sensorsData?.devices);
    console.log('🚀 ~ file: AirSensorsDoughnut.jsx:13 ~ AirSensorsDoughnut ~ data:', data);
    let lTemp = 0;
    let cTemp = 0;
    let lHumidity = 0;
    let cHumidity = 0;
    let lSoil = 0;
    let cSoil = 0;
    let lPlant = 0;
    let cPlant = 0;
    const allWeatherSensors = data?.filter((item) => item.type === 'WEATHER');
    console.log('🚀 ~ file: AirSensorsDoughnut.jsx:22 ~ AirSensorsDoughnut ~ allWeatherSensors:', allWeatherSensors);

    // const allSoilSensors = data.filter((item) => item.type === 'SOIL');
    // const allPlantSensors = data.filter((item) => item.type === 'PLANT');

    const getLastValues = (id) => {
        const filteredData = data ? data.filter((item) => item.id === id)[0].data : null;

        const lastTemperature =
            filteredData.length > 0 ? filteredData.temperature[filteredData.temperature.length - 1].v : '';

        const lastHumidity = filteredData.length > 0 ? filteredData.humidity[filteredData.humidity.length - 1].v : '';
        return { lastTemperature, lastHumidity };
    };

    allWeatherSensors?.length > 0 &&
        allWeatherSensors.map((item) => {
            lTemp += getLastValues(item.id).lastTemperature;
            cTemp += 1;
        });
    const meanTemp = lTemp / cTemp;
    const tempColor = useGetColor('temperature', meanTemp);
    allWeatherSensors?.length > 0 &&
        allWeatherSensors.map((item) => {
            lHumidity += getLastValues(item.id).lastHumidity;
            cHumidity += 1;
        });
    const meanHumidity = lHumidity / cHumidity;
    const humidityColor = useGetColor('humidity', meanHumidity);

    const main = theme.palette.fontColor.main;
    const alt = theme.palette.fontColor.alt;
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Box sx={{ mt: '-5px' }}>
            <Tabs
                orientation='horizontal'
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                sx={{
                    direction: 'ltr',
                    height: '20px',
                    width: '100%',
                    //   backgroundColor: "red",
                    display: 'flex',
                    alignItems: 'center',
                    '& .MuiTabs-scrollButtons': {
                        width: '20px',
                        height: '100%',
                    },
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                }}
            >
                <Stack direction='row' alignItems='center' justifyContent='center' gap='24px' width='140px'>
                    <CircularProgressBar
                        value={meanTemp}
                        diameter={35}
                        start_from='middle'
                        gaugeMinMax={40}
                        color={tempColor}
                    >
                        <Typography
                            style={{ direction: 'ltr', color: tempColor }}
                            fontSize={theme.typography.smallestTextPrimary}
                        >
                            {`${meanTemp}°`}
                        </Typography>
                    </CircularProgressBar>
                    <Typography fontSize={theme.typography.smallestTextSecondary} color={alt}>
                        دما
                    </Typography>
                </Stack>

                <Divider orientation='vertical' flexItem />
                <Stack direction='row' alignItems='center' justifyContent='center' gap='24px' width='140px'>
                    <CircularProgressBar value={meanHumidity} diameter={35} start_from='start' color={humidityColor}>
                        <Typography
                            style={{ direction: 'ltr', color: humidityColor }}
                            fontSize={theme.typography.smallestTextPrimary}
                        >
                            {`${meanHumidity}°`}
                        </Typography>
                    </CircularProgressBar>
                    <Typography fontSize={theme.typography.smallestTextSecondary} color={alt}>
                        رطوبت
                    </Typography>
                </Stack>

                {/* <Divider orientation="vertical" flexItem /> */}
                {/* <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"km/h"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            باد
          </Typography>
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"°"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            نقطه شبنم
          </Typography>
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"mm"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            بارندگی
          </Typography>
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"Lux"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            شدت تابش
          </Typography>
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"bar"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            فشار
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"cm"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            قطر میوه
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"cm"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            قطر ساقه
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="160px">
          <CustomGaugeWeather units={"%"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            رطوبت برگ
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"°"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            دما خاک عمق 30
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"°"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            دما خاک عمق 60
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"°"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            دما خاک عمق 90
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"%"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            رطوبت خاک عمق 30
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"%"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            رطوبت خاک عمق 60
          </Typography>
        </FlexBetween>
        <Divider orientation="vertical" flexItem />
        <FlexBetween gap="2.2rem" m="0 0.5rem" width="180px">
          <CustomGaugeWeather units={"%"} />
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            رطوبت خاک عمق 90
          </Typography>
        </FlexBetween> */}
            </Tabs>
        </Box>
    );
};

export default AirSensorsDoughnut;
