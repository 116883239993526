import { useMediaQuery } from "@mui/material";
import React from "react";

import RecordFarmMob from "mob/RecordFarmMob/RecordFarmMob";
import RecordFarm from "./RecordFarm";

const RecordFarmIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  return <>{isMobileScreens ? <RecordFarmMob /> : <RecordFarm />}</>;
};

export default RecordFarmIndex;
