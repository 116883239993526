import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setClearLogin, setLogin } from 'States';

export const deleteEmptyValues = (obj) =>
    Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== '' && v !== null));

export const useFetchFarmData = (getter, farmId = null, indexLayerName = null, params = '') => {
    const [response, setResponse] = useState(null);
    const token = useSelector((state) => state.global.token);
    const refreshToken = useSelector((state) => state.global.refreshToken);
    const args = deleteEmptyValues({ token, farmId, indexLayerName });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getNewAccessToken = async (refreshToken) => {
        const formData = new URLSearchParams();
        formData.append('refresh_token', refreshToken);
        formData.append('client_id', 'user-panel');
        formData.append('client_secret', 'mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV');
        formData.append('grant_type', 'refresh_token');
        await fetch('https://api.rahbaan.ir/v1/authentication/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        })
            .then((response) => {
                console.log('🚀 ~ file: SensorManagement.jsx:143 ~ .then ~ response:', response.status);
            })
            .then((data) => {
                dispatch(
                    setLogin({
                        user: data,
                        token: data.access_token,
                        refreshToken: data.refresh_token,
                    }),
                );
            })
            .catch((error) => {
                console.log('🚀 ~ file: SensorManagement.jsx:113 ~ .then ~ error:', error);
                dispatch(setClearLogin());
                navigate('/login');
            });
    };

    useEffect(() => {
        const fetch = async ({ getter, args = {}, params = '' }) => {
            const res = await getter({
                ...args,
                ...(params && { query_string: params }),
            });

            if (res) {
                switch (res.status) {
                    case 200:
                        const res_json = await res.json();
                        setResponse({ ...res_json });
                        break;
                    case 401:
                        // getNewAccessToken(refreshToken);
                        break;
                    default:
                        break;
                }
            }
        };
        fetch({ getter, args, params });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, farmId, indexLayerName, params]);
    return { response };
};

export const useFetchSensorData = (getter, params) => {
    const [response, setResponse] = useState(null);
    const [is_loading, setLoading] = useState(true)
    const token = useSelector((state) => state.global.token);
    const refreshToken = useSelector((state) => state.global.refreshToken);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getNewAccessToken = async (refreshToken) => {
        const formData = new URLSearchParams();
        formData.append('refresh_token', refreshToken);
        formData.append('client_id', 'user-panel');
        formData.append('client_secret', 'mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV');
        formData.append('grant_type', 'refresh_token');
        await fetch('https://api.rahbaan.ir/v1/authentication/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        })
            .then((data) => {
                dispatch(
                    setLogin({
                        user: data,
                        token: data.access_token,
                        refreshToken: data.refresh_token,
                    }),
                );
            })
            .catch((error) => {
                dispatch(setClearLogin());
                navigate('/login');
            });
    };

    useEffect(() => {
        const fetch = async ({ getter, params = '' }) => {
            const res = await getter({
                token,
                ...(params && { query_string: params }),
            });

            if (res) {
                switch (res.status) {
                    case 200:
                        const res_json = await res.json();
                        setResponse({ ...res_json });
                        setLoading(false)
                        break;
                    case 401:
                        setLoading(true)
                        getNewAccessToken(refreshToken);
                        break;
                    default:
                        break;
                }
            }
        };
        fetch({ getter, params });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, params]);
    return { response, is_loading };
};
