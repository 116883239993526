import {
  ArrowUpwardOutlined,
  Filter,
  FilterAltOutlined,
  MoreHoriz,
  NavigateBeforeRounded,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Radio,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect, useMemo, useState } from "react";

import EditIcon from "../../assets/edit.png";
import ArrowIcon from "../../assets/arrow.png";
import DetailStaffGrid from "components/DetailStaffGrid/DetailStaffGrid";
import WidgetWrapper from "components/WidgetWrapper";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import { MapContainer, TileLayer } from "react-leaflet";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import { useSelector } from "react-redux";
import CopyRight from "widgets/CopyRight";
import { store } from "States/rootReducer";
import { setAlarms } from "States/sensors";
import { getDateShamsi } from "Utils/Utils";
import W1 from "../../assets/WeatherIcons/static/cloudy-day-3.svg";
import W2 from "../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W3 from "../../assets/WeatherIcons/static/cloudy-day-1.svg";
// import AlarmsMap from "components/AlarmsMap/AlarmsMap";
import { useNavigate } from "react-router-dom";
import { setSensorIndex } from "States";
import AlarmsMap from "components/AlarmsMap/AlarmsMap";
import { AlarmsData, CollarData } from "Mock/AlarmsData";

const convertAlarmName = (priority) => {
  switch (priority) {
    case "High":
      return "بحرانی";
    case "Highest":
      return "خیلی بحرانی";

    default:
      break;
  }
};
const getAlarmColor = (severity, theme) => {
  switch (severity) {
    case "High":
      return theme.palette.others.forth;
    case "Highest":
      return theme.palette.others.second;

    default:
      break;
  }
};
const AlarmsManagement = () => {
  const [toggle, setToggle] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const StyledMenuList = styled(MenuList)(({ theme }) => ({
    /* Use the prop `theme` to apply dynamic styles */
    backgroundColor: theme.palette.background.third,
    boxShadow: "none !important",
  }));
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const mode = useSelector((state) => state.global.mode);
  const [selectValue, setSelectValue] = useState(0);
  const [selectSensorValue, setSelectSensorValue] = useState(0);

  const [selectedSensor, setSelectedSensor] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [toggleDate, setToggleDate] = useState(false);
  const [sensorSearch, setSensorSearch] = useState("");

  // const data = useSelector((state) => state.sensors.sensorsData.data);
  // console.log(
  //   "🚀 ~ file: AlarmsManagement.jsx:92 ~ AlarmsManagement ~ data:",
  //   data
  // );

  // const uniqueSensorZones = data
  //   ? [...new Set(data.map((zone) => zone.zoneTitle))]
  //   : null;

  // const sensorsSelectedZone =
  //   data && data.length > 0
  //     ? data.filter(
  //         (item) =>
  //           item.title === uniqueSensorZones[selectValue - 1] || item.title
  //       )
  //     : [];

  // const sensors = sensorsSelectedZone.filter((item) =>
  //   sensorSearch !== "" ? item.title.includes(sensorSearch) : item
  // );

  // const alarmsData = useSelector((state) => state.sensors.alarms);
  // console.log(
  //   "🚀 ~ file: AlarmsManagement.jsx:77 ~ AlarmsManagement ~ alarmsData:",
  //   alarmsData
  // );
  // const uniqueZones = alarmsData
  //   ? [...new Set(alarmsData.map((zone) => zone.zoneTitle))]
  //   : null;

  // const alarmsDataSelectedZone =
  //   alarmsData && alarmsData.length > 0
  //     ? alarmsData.filter(
  //         (item) =>
  //           item.zoneTitle === uniqueZones[selectValue - 1] || item.zoneTitle
  //       )
  //     : [];

  // const temp = alarmsDataSelectedZone.filter((item) =>
  //   searchValue !== "" ? item.typeName.includes(searchValue) : item
  // );
  // const alarms = toggleDate
  //   ? temp.sort((a, b) => new Date(a.created) - new Date(b.created))
  //   : temp.sort((a, b) => new Date(b.created) - new Date(a.created));
  const [toggles, setToggles] = useState(
    AlarmsData ? AlarmsData.map(() => false) : false
  );

  const handleToggle = (index) => {
    setToggles((prevToggles) => {
      const newToggles = [...prevToggles];
      newToggles[index] = !newToggles[index];
      return newToggles;
    });
  };
  const getBatteryStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return EmptyIcon;
      case "LOW":
        return Full3Icon;
      case "HIGH":
        return FullIcon;
      default:
        break;
    }
  };
  const getSignalStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return WifiIcon;
      case "LOW":
        return Wifi1Icon;
      case "HIGH":
        return Wifi3Icon;
      default:
        break;
    }
  };
  return (
    <Box width="99.5%" height="83vh" m="1rem 1rem 0 0">
      <FlexBetween
        sx={{ justifyContent: "flex-start", gap: "20px", width: "94vw" }}
      >
        <Box width="61.1vw" height="62px">
          <SpeedomotorsData />
        </Box>
        <WidgetWrapper
          width="30.5vw"
          height="62px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="1rem"
        >
          <Box
            borderLeft={`1px solid ${alt}`}
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
            >
              <Typography variant="smallestTextPrimary" color="fontColor.main">
                امروز
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                10/18
              </Typography>
            </Stack>

            <Box component="img" src={W1} width="32px" height="32px" />
            <Stack direction="row" sx={{ direction: "ltr" }}>
              <Typography variant="body" color="fontColor.alt">
                11 <sup>°</sup> |
              </Typography>
              <Typography variant="body" color="fontColor.main">
                {" "}
                16 <sup>°</sup>
              </Typography>
            </Stack>
          </Box>
          <Box
            borderLeft={`1px solid ${alt}`}
            display="flex"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
            >
              <Typography variant="smallestTextPrimary" color="fontColor.main">
                یکشنبه
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                10/18
              </Typography>
            </Stack>

            <Box component="img" src={W1} width="32px" height="32px" />
            <Stack direction="row" sx={{ direction: "ltr" }}>
              <Typography variant="body" color="fontColor.alt">
                11 <sup>°</sup> |
              </Typography>
              <Typography variant="body" color="fontColor.main">
                {" "}
                16 <sup>°</sup>
              </Typography>
            </Stack>
          </Box>
          <Box display="flex" alignItems="center" width="100%" height="100%">
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              gap="0.5rem"
            >
              <Typography variant="smallestTextPrimary" color="fontColor.main">
                دوشنبه
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                10/18
              </Typography>
            </Stack>

            <Box component="img" src={W1} width="32px" height="32px" />
            <Stack direction="row" sx={{ direction: "ltr" }}>
              <Typography variant="body" color="fontColor.alt">
                11 <sup>°</sup> |
              </Typography>
              <Typography variant="body" color="fontColor.main">
                {" "}
                16 <sup>°</sup>
              </Typography>
            </Stack>
          </Box>
        </WidgetWrapper>
      </FlexBetween>
      <Stack direction="row" gap="1.2rem" mt="1rem" height="72.5vh">
        <WidgetWrapper width="61.2vw" height="100%" mr="0rem">
          <Stack direction="row" justifyContent="space-between" mb="30px">
            <Stack direction="row" gap="20px" alignItems="center">
              <Typography color={main} variant="h3" sx={{ userSelect: "none" }}>
                هشدارها
              </Typography>
              <Box
                width="138px"
                height="32px"
                bgcolor={theme.palette.others.first}
                borderRadius={"8px"}
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
              >
                <Typography fontSize={theme.typography.subHeading} color={main}>
                  هشدارهای گذشته
                </Typography>
              </Box>
            </Stack>
            <Stack direction="row">
              <FlexBetween
                sx={{
                  // display: open ? "inline-flex" : "none",
                  transition: "all 0.2s ease",
                  ml: "1.5rem",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.fontColor.alt}`,
                  // border: "1px solid black",
                  borderRadius: "8px",
                }}
              >
                <Search sx={{ mr: "0.2rem" }} />
                <InputBase
                  disableUnderline
                  placeholder="جست و جو"
                  id="inputSearchRemote"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  sx={{
                    paddingRight: "5px",
                    backgroundColor: "inherit",
                    "& .MuiInputBase-input": {
                      "::placeholder": {
                        color: `${theme.palette.fontColor.main} !important`,
                      },
                    },
                    height: "27px",
                    width: "200px",
                    borderRadius: "8px",
                    color: theme.palette.fontColor.main,
                    outline: "none",
                    borderBottom: "none",
                  }}
                />
              </FlexBetween>
              <Box width="125px" height="33px" borderRadius="8px">
                <Select
                  value={selectValue}
                  IconComponent={FilterAltOutlined}
                  onChange={(e) => setSelectValue(e.target.value)}
                  MenuProps={{
                    // Apply the custom styled MenuList component
                    MenuListProps: { component: StyledMenuList },
                  }}
                  sx={{
                    width: "125px",
                    height: "33px",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    borderRadius: "8px",
                    boxShadow: "none !important",
                    "& .MuiPaper-elevation": {
                      boxShadow: "none !important",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#40405e",
                    },
                  }}
                >
                  <MenuItem
                    value={0}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third} !important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    همه ناحیه ها
                  </MenuItem>
                  <MenuItem
                    value={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third}!important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    {}
                  </MenuItem>
                  {/* ///List Areas */}
                </Select>
              </Box>
            </Stack>
          </Stack>
          <Box style={{ height: "90%", width: "100%" }}>
            <Box display="flex">
              <Box
                width="10%"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Typography
                  fontSize={theme.typography.subHeading}
                  color={theme.palette.fontColor.alt}
                >
                  بهاربند ها
                </Typography>
              </Box>
              <Box
                width="25%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={theme.typography.subHeading}
                  color={theme.palette.fontColor.alt}
                >
                  هشدار
                </Typography>
              </Box>
              <Box
                width="15%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FlexBetween>
                  <Typography
                    fontSize={theme.typography.subHeading}
                    color={theme.palette.fontColor.alt}
                  >
                    اولویت
                  </Typography>
                </FlexBetween>
              </Box>
              <Box
                width="15%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <FlexBetween>
                  <Typography
                    fontSize={theme.typography.subHeading}
                    color={theme.palette.fontColor.alt}
                  >
                    تاریخ ارسال
                  </Typography>
                  <IconButton
                    sx={{
                      width: "20px",
                      height: "20px",
                      rotate: toggleDate ? "0deg" : "180deg",
                    }}
                    onClick={() => {
                      setToggleDate(!toggleDate);
                    }}
                  >
                    <ArrowUpwardOutlined
                      sx={{ width: "15px", height: "15px", color: alt }}
                    />
                  </IconButton>
                </FlexBetween>
              </Box>
              <Box
                width="20%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={theme.typography.subHeading}
                  color={theme.palette.fontColor.alt}
                >
                  اقدام توسط
                </Typography>
              </Box>
              <Box
                width="10%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  fontSize={theme.typography.subHeading}
                  color={theme.palette.fontColor.alt}
                >
                  ویرایش
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ marginTop: "10px" }} />
            <Box
              sx={{
                height: "90%",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-button": {
                  height: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5A596F",
                  outline: `0px solid slategrey`,
                  borderRadius: "5px",
                },
              }}
            >
              {AlarmsData.map((item, index) => (
                <>
                  <Box display="flex" m="1rem 0">
                    <Box
                      width="10%"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      gap="0.25rem"
                    >
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <Box
                      width="25%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.alarm}
                      </Typography>
                    </Box>
                    <Box
                      width="15%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        width="77px"
                        height="23px"
                        borderRadius="8px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: getAlarmColor(item.priority, theme),
                        }}
                      >
                        <Typography
                          color={"#000"}
                          fontSize={theme.typography.smallestTextPrimary}
                        >
                          {convertAlarmName(item.priority)}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width="15%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextPrimary}
                        sx={{ direction: "ltr" }}
                      >
                        {item.sendDate}
                      </Typography>
                    </Box>
                    <Box
                      width="20%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography
                        color={main}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {item.dueTo}
                      </Typography>
                    </Box>
                    <Box
                      width="10%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton>
                        <Box component="img" src={EditIcon} />
                      </IconButton>
                    </Box>

                    <Box
                      width="5%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <IconButton
                        sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                        onClick={() => handleToggle(index)}
                      >
                        <Box
                          component="img"
                          src={ArrowIcon}
                          sx={{ rotate: toggle ? 0 : "180deg" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                  {toggles[index] && <DetailStaffGrid item={item} />}
                  <Divider />
                </>
              ))}
            </Box>
          </Box>
        </WidgetWrapper>

        <Stack direction="column" gap="1rem" width="30.5vw" height="100%">
          <AlarmsMap />
          <WidgetWrapper width="30.5vw" height="45.5%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">قلاده ها</Typography>

              <Stack direction="row" gap="0.2rem" alignItems="center">
                <FlexBetween
                  sx={{
                    // display: open ? "inline-flex" : "none",
                    transition: "all 0.2s ease",
                    ml: "0.5rem",
                    alignItems: "center",
                    border: `1px solid ${theme.palette.fontColor.main}`,
                    // border: "1px solid black",
                    borderRadius: "8px",
                  }}
                >
                  <Search sx={{ mr: "0.2rem" }} />
                  <InputBase
                    disableUnderline
                    placeholder="جست و جو"
                    value={sensorSearch}
                    onChange={(e) => setSensorSearch(e.target.value)}
                    id="inputSearchRemote"
                    sx={{
                      paddingRight: "5px",
                      backgroundColor: "inherit",
                      "& .MuiInputBase-input": {
                        "::placeholder": {
                          color: `${theme.palette.fontColor.main} !important`,
                        },
                      },
                      height: "33px",
                      width: "120px",
                      borderRadius: "8px",
                      color: theme.palette.fontColor.alt,
                      outline: "none",
                      borderBottom: "none",
                    }}
                  />
                </FlexBetween>
                <Box width="90px" height="33px" borderRadius="8px">
                  <Select
                    value={selectSensorValue}
                    onChange={(e) => setSelectSensorValue(e.target.value)}
                    IconComponent={FilterAltOutlined}
                    MenuProps={{
                      // Apply the custom styled MenuList component
                      MenuListProps: { component: StyledMenuList },
                    }}
                    sx={{
                      width: "130px",
                      height: "33px",
                      border: `1px solid ${theme.palette.fontColor.alt}`,
                      borderRadius: "8px",

                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#40405e",
                      },
                    }}
                  >
                    <MenuItem
                      value={selectSensorValue}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: "0 5px",
                        borderRadius: "4px",
                        backgroundColor: `${theme.palette.background.third} !important`,
                        ":hover": {
                          backgroundColor: `${theme.palette.fontColor.third} !important`,
                        },
                      }}
                    >
                      همه ناحیه ها
                    </MenuItem>
                    <MenuItem
                      value={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: "0 5px",
                        borderRadius: "4px",
                        backgroundColor: `${theme.palette.background.third}!important`,
                        ":hover": {
                          backgroundColor: `${theme.palette.fontColor.third} !important`,
                        },
                      }}
                    >
                      {/* {item} */}
                    </MenuItem>
                  </Select>
                </Box>
                <MoreHoriz
                  onClick={() => {
                    store.dispatch(setSensorIndex({ sensorIndex: 0 }));
                    navigate("/sensormanagement");
                  }}
                  sx={{
                    mr: "3rem",
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                />
              </Stack>
            </Box>
            {/* ///////////////////// */}

            <Box style={{ height: 160, width: "100%" }}>
              <FlexBetween mt="0.5rem">
                <Typography color={alt}>قلاده</Typography>
                <FlexBetween gap="2rem" sx={{ width: "65%" }}>
                  <Typography
                    color={alt}
                    fontSize={theme.typography.smallestTextSecondary}
                  >
                    وضعیت
                  </Typography>
                  <Typography
                    color={alt}
                    fontSize={theme.typography.smallestTextSecondary}
                  >
                    باتری
                  </Typography>
                  <Typography
                    color={alt}
                    fontSize={theme.typography.smallestTextSecondary}
                  >
                    سیگنال
                  </Typography>
                </FlexBetween>
              </FlexBetween>
              <Divider sx={{ marginTop: "10px" }} />
              <Box
                sx={{
                  height: "75%",
                  overflow: "auto",

                  "&::-webkit-scrollbar": {
                    width: 5,
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                    borderRadius: "5px",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "1px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#5A596F",
                    outline: `0px solid slategrey`,
                    borderRadius: "5px",
                  },
                }}
              >
                {CollarData.map((item) => (
                  <Box
                    onClick={() => {
                      // setSelected(index);
                      // setSelectedSensor(item.id);
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    height="30px"
                    sx={{
                      mt: "3px",
                      p: "0.2rem",
                      borderRadius: "8px",
                      // cursor: item.id === selectedSensor ? "auto" : "pointer",
                      // backgroundColor:
                      //   item.id === selectedSensor
                      //     ? theme.palette.background.third
                      //     : null,
                      // p: "0 2px",
                      "&:hover": {
                        backgroundColor: theme.palette.background.third,
                      },
                    }}
                  >
                    <Typography
                      color={main}
                      fontSize={theme.typography.smallestTextPrimary}
                    >
                      {item.title}
                    </Typography>
                    <FlexBetween gap="2rem" sx={{ width: "65%" }}>
                      <Box>
                        <FormControlLabel
                          value="female"
                          control={
                            <Radio
                              sx={{
                                width: "12px",
                                height: "12px",
                                "&, &.Mui-checked": {
                                  color:
                                    item.status === true
                                      ? theme.palette.others.third
                                      : theme.palette.others.second,
                                },
                              }}
                              checked={true}
                            />
                          }
                        />
                      </Box>
                      <Box
                        component={"img"}
                        src={getBatteryStatus(item.battery)}
                      />
                      <Box
                        component={"img"}
                        src={getSignalStatus(item.signal)}
                      />
                    </FlexBetween>
                  </Box>
                ))}
              </Box>
            </Box>

            {/* /////////////////////////// */}
          </WidgetWrapper>
        </Stack>
      </Stack>
      <Box width="93vw">
        <CopyRight />
      </Box>
    </Box>
  );
};

export default AlarmsManagement;
