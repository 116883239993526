import {
  CloudDownloadOutlined,
  Menu,
  NotificationsOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import ListSidebarMob from "./ListSidebarMob";
import profileImage from "../../assets/StaffImg/9.jpg";

const NavbarMob = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  console.log("🚀 ~ file: NavbarMob.jsx:31 ~ NavbarMob ~ open:", open);
  return (
    <Stack
      width="100%"
      height="56px"
      direction="row"
      justifyContent="space-between"
      sx={{ backgroundColor: theme.palette.background.second, zIndex: 1000 }}
    >
      <IconButton onClick={toggleDrawer("right", true)}>
        <Menu />
      </IconButton>
      <Stack direction="row">
        <IconButton>
          <SettingsOutlined />
        </IconButton>
        <IconButton>
          <CloudDownloadOutlined />
        </IconButton>
        <IconButton>
          <NotificationsOutlined />
        </IconButton>
        <IconButton width="32px" height="32px">
          <Box
            component="img"
            src={profileImage}
            width="32px"
            height="32px"
            borderRadius="50%"
            mb="0.2rem"
          />
        </IconButton>
      </Stack>

      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={open}
          onClose={toggleDrawer("right", false)}
          sx={{
            zIndex: 1999999,
            backgroundColor: "transparent",
            "& .MuiPaper-root": {
              background: theme.palette.background.second,
              borderRadius: "16px 0 0 16px",
            },
          }}
        >
          <ListSidebarMob open={open} />
        </Drawer>
      </React.Fragment>
    </Stack>
  );
};

export default NavbarMob;
