import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "jalali-moment";
import { MOBILE_WIDTH } from "constants/constant";

Chart.register(...registerables);
Chart.register(annotationPlugin);
const WalletChart = ({
  th2,
  fillColor,
  sensorData,
  min,
  max,
  stepSize,
  thv1,
  thv2,
  h,
}) => {
  const theme = useTheme();
  const isMobileScreens = useMediaQuery(`(max-width:600px)`);

  const [flag, setFlag] = useState(true);
  // const thresholdValue = 30;

  const chartBarData = [
    7000000, 4000000, 6000000, 8000000, 5000000, 6500000, 4100000, 5500000,
    7800000, 3800000, 6100000, 7900000,
  ];

  const dataLabels = [
    "1402/01",
    "1402/02",
    "1402/03",
    "1402/04",
    "1402/05",
    "1402/06",
    "1402/07",
    "1402/08",
    "1402/09",
    "1402/10",
    "1402/11",
    "1402/12",
  ];

  const data = {
    labels: dataLabels,
    datasets: [
      {
        type: "bar",
        label: "First dataset",
        data: chartBarData,
        ...(isMobileScreens ? {} : { barThickness: 25 }),
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(54,162,256,1)",
            "rgba(54,162,256,0.6)",
            "rgba(54,162,256,0.05)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);

          return gradientBg;
        },
        options: {
          tooltips: { enabled: false },
        },
      },
    ],
  };
  return (
    <Box
      mt="1.5rem"
      sx={{
        opacity: 1,
        width: "100%",
        height: h ? h : "160px",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            animation: {
              duration: 2000, //set your desired time in ms here
              easing: "easeInOutQuad", //this is optional and can be set by default as well.
            },
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "rgba(255,255,255,0.8)",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },
              annotation: {
                annotations: {},
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                min: min,
                // max: max,
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.third,
                  borderDash: [3, 3],
                },
                ticks: {
                  stepSize: stepSize,
                  display: true,
                  color: theme.palette.fontColor.main,
                  callback: function (val, index) {
                    // Hide every 2nd tick label
                    const temp = val / 1000000;
                    if (val === 0) {
                      return 0;
                    } else
                      return `${temp} ${isMobileScreens ? "م" : "  میلیون  "}`;
                  },
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  //   display: false,
                  borderDash: [3, 3],
                  color: theme.palette.background.third,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: false,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: true,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default WalletChart;
