import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import CopyRight from "widgets/CopyRight";
import CustomSelectBoxCompare from "./CutomSelecBoxCompare";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { SENSOR_MANAGEMENT_DEFAULT_PERIOD, TODAY } from "constants/constant";
import { shamsiToGregorian } from "Utils/Utils";
import CustomDatePickerCompare from "./CustomDatePickerCompare";
import CompareChart from "./CompareChart";
import CustomSelectBoxCompareType from "./CustomSelectBoxCompareType";
import { CompareData } from "Mock/CompareData";

const Compare = () => {
  const [selectedRange, setSelectedRange] = useState([
    new Date(SENSOR_MANAGEMENT_DEFAULT_PERIOD),
    new Date(TODAY),
  ]);
  const handleDateChange = (value) => {
    const gregorian = value?.map((date) =>
      shamsiToGregorian(`${date.year}-${date.month}-${date.day}`)
    );
  };

  const [itemsForSelect, setItemsForSelect] = useState(
    CompareData.map((item) => item.code)
  );
  const [selectItem1, setSelectItem1] = useState(itemsForSelect[0]);

  const [selectItem2, setSelectItem2] = useState(itemsForSelect[2]);
  const [selectItem3, setSelectItem3] = useState(itemsForSelect[1]);
  const [selectItem4, setSelectItem4] = useState(itemsForSelect[3]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1235px"
      width="calc(100% - 2rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="89vh">
        <Box
          width="49.5%"
          height="99%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <WidgetWrapper width="100%" height="49%">
            <FlexBetween>
              <Stack direction="row">
                <CustomSelectBoxCompare
                  items={itemsForSelect}
                  selectedItem={selectItem1}
                  setSelectedItem={setSelectItem1}
                  width="88px"
                  height="33px"
                />

                <CustomSelectBoxCompareType width="88px" height="33px" />
                <CustomDatePickerCompare
                  selectedRange={selectedRange}
                  handleDateChange={handleDateChange}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  عملکرد کل دام
                </Typography>
              </Stack>
            </FlexBetween>
            <CompareChart
              r={61}
              g={204}
              b={178}
              data={CompareData.filter((item) => item.code === selectItem1)}
            />
          </WidgetWrapper>
          <WidgetWrapper width="100%" height="49%">
            <FlexBetween>
              <Stack direction="row">
                <CustomSelectBoxCompare
                  items={itemsForSelect}
                  selectedItem={selectItem2}
                  setSelectedItem={setSelectItem2}
                  width="88px"
                  height="33px"
                />
                <CustomSelectBoxCompareType width="88px" height="33px" />
                <CustomDatePickerCompare
                  selectedRange={selectedRange}
                  handleDateChange={handleDateChange}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  عملکرد کل دام
                </Typography>
              </Stack>
            </FlexBetween>
            <CompareChart
              color={"#A52FED"}
              r={61}
              g={204}
              b={178}
              data={CompareData.filter((item) => item.code === selectItem2)}
            />
          </WidgetWrapper>
        </Box>
        <Box
          width="49.5%"
          height="99%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <WidgetWrapper width="100%" height="49%">
            <FlexBetween>
              <Stack direction="row">
                <CustomSelectBoxCompare
                  items={itemsForSelect}
                  selectedItem={selectItem3}
                  setSelectedItem={setSelectItem3}
                  width="88px"
                  height="33px"
                />
                <CustomSelectBoxCompareType width="88px" height="33px" />
                <CustomDatePickerCompare
                  selectedRange={selectedRange}
                  handleDateChange={handleDateChange}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  عملکرد کل دام
                </Typography>
              </Stack>
            </FlexBetween>
            <CompareChart
              color={"#FF7410"}
              r={61}
              g={204}
              b={178}
              data={CompareData.filter((item) => item.code === selectItem3)}
            />
          </WidgetWrapper>
          <WidgetWrapper width="100%" height="49%">
            <FlexBetween>
              <Stack direction="row">
                <CustomSelectBoxCompare
                  items={itemsForSelect}
                  selectedItem={selectItem4}
                  setSelectedItem={setSelectItem4}
                  width="88px"
                  height="33px"
                />
                <CustomSelectBoxCompareType width="88px" height="33px" />
                <CustomDatePickerCompare
                  selectedRange={selectedRange}
                  handleDateChange={handleDateChange}
                />
              </Stack>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  عملکرد کل دام
                </Typography>
              </Stack>
            </FlexBetween>
            <CompareChart
              color="#3DCCB2"
              r={61}
              g={204}
              b={178}
              data={CompareData.filter((item) => item.code === selectItem4)}
            />
          </WidgetWrapper>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default Compare;
