import {
  AssignmentTurnedInOutlined,
  CheckCircleOutline,
  ContentCopyOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  FormatListBulletedOutlined,
  ShortcutOutlined,
} from "@mui/icons-material";
import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";

const MenuMessenger = ({ anchorEl, setAnchorEl, menuRef }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div ref={menuRef}>
      <Box
        sx={{
          background: "rgba(56, 56, 92, 0.5)",
          backdropFilter: "blur(30px)",
          width: "auto",
          borderRadius: "8px",
          opacity: Boolean(anchorEl) ? 1 : 0,
          visibility: Boolean(anchorEl) ? "visible" : "hidden",
          transition: "opacity 0.3s",
          zIndex: Boolean(anchorEl) ? 1001 : -1,
          "& .MuiButtonBase-root:hover": {
            "@media(max-width: 600px)": {
              backgroundColor: "transparent",
            },
          },
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <EditOutlined />
            <Typography variant="subHeading" color={main}>
              ویرایش
            </Typography>
          </FlexBetween>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <ContentCopyOutlined />

            <Typography variant="subHeading" color={main}>
              کپی متن
            </Typography>
          </FlexBetween>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <ShortcutOutlined />

            <Typography variant="subHeading" color={main}>
              ارسال به
            </Typography>
          </FlexBetween>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <DeleteOutlineOutlined />

            <Typography variant="subHeading" color={main}>
              حذف
            </Typography>
          </FlexBetween>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <CheckCircleOutline />

            <Typography variant="subHeading" color={main}>
              انتخاب
            </Typography>
          </FlexBetween>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <AssignmentTurnedInOutlined />

            <Typography variant="subHeading" color={main}>
              تبدیل به وظیفه
            </Typography>
          </FlexBetween>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <FlexBetween gap="0.5rem">
            <FormatListBulletedOutlined />

            <Typography variant="subHeading" color={main}>
              ثبت اقدام
            </Typography>
          </FlexBetween>
        </MenuItem>
      </Box>
    </div>
  );
};

export default MenuMessenger;
