import React, { useState } from "react";
import L, { map } from "leaflet";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import {
  Box,
  Fade,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import ButtonsSensorsManagementAll from "scenes/SensorsManagementAll/ButtonsSensorsManagementAll";
// import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
// import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import {
  Close,
  DownloadOutlined,
  FullscreenOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween/FlexBetween";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 13,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 13,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "relative",
  width: "100%",
  padding: "0 0.75rem 0.75rem",
  height: "63%",
  maxHeight: "80%",
  "& .leaflet-control-zoom": {
    display: "none",
  },
}));

const ActuatorMapMob = () => {
  const mode = useSelector((state) => state.global.mode);
  const [checkMeh, setCheckMeh] = useState(true);
  const [checkWater, setCheckWater] = useState(true);
  const [checkSensors, setCheckSensors] = useState(false);
  const position = [35.643029, 52.224928];
  const [mapModal, setMapModal] = useState(false);
  const theme = useTheme();
  return (
    <MapBox mode={mode}>
      <FlexBetween
        position="absolute"
        top="50%"
        right="24px"
        width="24px"
        flexDirection="column"
        sx={{ zIndex: 1000, gap: "12px", transform: "translateY(-50%)" }}
      >
        {!mapModal && (
          <Box
            sx={{
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton>
              <FullscreenOutlined
                onClick={() => setMapModal(true)}
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
        )}
        <Box
          sx={{
            width: "24px",
            height: "24px",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7)"
                : "rgba(250, 250, 252, 0.7)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton>
            <DownloadOutlined
              sx={{
                width: "22px",
                height: "22px",
              }}
            />
          </IconButton>
        </Box>
      </FlexBetween>
      <Modal
        open={mapModal}
        onClose={() => setMapModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 200000,
          "& .leaflet-left": {
            left: "14px",
          },
          "& .leaflet-bar": {
            border: "none",
          },
          "& .leaflet-control-zoom-in": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7) !important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "8px 8px 0 0 !important",
          },

          "& .leaflet-touch .leaflet-bar a": {
            lineHeight: "24px !important",
          },
          "& .leaflet-control-zoom-out": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7 )!important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "0 0 8px 8px !important",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.second,
            "& .leaflet-container": {
              height: "100% !important",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "120px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <Close
                onClick={() => setMapModal(false)}
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "170px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <PriorityHighOutlined
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "200px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <DownloadOutlined
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <MapContainer
            center={position}
            zoom={15.5}
            style={{ borderRadius: "8px", height: "241px", width: "100%" }}
          >
            {/* <ChangeView center={position} zoom={15.5} /> */}
            <TileLayer
              attribution="PTR"
              url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              maxZoom={22}
              maxNativeZoom={18}
            />
          </MapContainer>
        </Box>
      </Modal>

      <MapContainer
        center={position}
        zoom={15.5}
        style={{ borderRadius: "8px", height: "100%" }}
      >
        <TileLayer
          attribution="PTR"
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
      </MapContainer>
      <Box
        sx={{
          position: "absolute",
          top: "24px",
          right: "24px",
          zIndex: 1000,
          display: "flex",
          gap: "1rem",
        }}
      >
        <Box>
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.main}
            ml="0.5rem"
          >
            مه پاش ها
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                defaultChecked={checkMeh}
                onChange={() => setCheckMeh(!checkMeh)}
              />
            }
            sx={{ marginLeft: "0.5rem" }}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <IOSSwitch
                defaultChecked={checkWater}
                onChange={() => setCheckWater(!checkWater)}
              />
            }
            sx={{ marginLeft: "0.5rem" }}
          />
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.main}
            ml="0.5rem"
          >
            پمپ های آب
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="smallestTextPrimary"
            color={theme.palette.fontColor.main}
            ml="0.5rem"
          >
            سنسورها
          </Typography>
          <FormControlLabel
            control={
              <IOSSwitch
                defaultChecked={checkSensors}
                onChange={() => setCheckSensors(!checkSensors)}
              />
            }
            sx={{ marginLeft: "0.5rem" }}
          />
        </Box>
      </Box>
    </MapBox>
  );
};

export default ActuatorMapMob;
