import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import CopyRight from "widgets/CopyRight";
import WeatherDayImage from "../../assets/weatherDayImage.png";
import W1 from "../../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../../assets/WeatherIcons/static/day.svg";
import W7 from "../../assets/WeatherIcons/static/rainy-7.svg";
import Water from "../../assets/water.png";
import UvIndex from "components/UvIndex/UvIndex";
import CustomGauge from "components/CustomGauge/CustomGauge";
import Gauge from "components/Gauge/Gauge";
import GaugeLetter from "components/GaugeLetter/GaugeLetter";
import CustomGaugeLetter from "components/CustomGaugeLetter/CustomGaugeLetter";
import ArrowIcon from "../../assets/direction.png";
import CustomPressureDoughnut from "components/CustomPressureDoughnut/CustomPressureDoughnut";
import AirSensor from "scenes/AirSensor/AirSensor";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import AirSensorJ from "components/AirSensorJ/AirSensorJ";
import Sunset from "components/Sunset/Sunset";
import HourlyWeather from "components/HourlyWeather/HourlyWeather";
import { useDispatch, useSelector } from "react-redux";
import { setWeather } from "States/weather";
import {
  getDateShamsi,
  getDateShamsiAirSensors,
  getDayName,
  getMonthDayT,
} from "Utils/Utils";
import {
  Donut,
  DonutElement,
  DonutLabel,
  DonutMultiple,
} from "react-donut-component";
import WeatherSMob from "mob/scenesMob/WeatherSMob/WeatherSMob";
import DailyMob from "mob/componentsMob/DailyMob/DailyMob";
import CircularProgressBar from "components/circularProgressBar/CircularProgressBar";
const Weather = () => {
  const getIcon = (condition) => {
    switch (condition) {
      case "CLEAR":
        return W1;
      case "ATMOSPHERE":
        return W2;
      case "CLOUDS":
        return W3;
      case "DRIZZLE":
        return W4;
      case "RAIN":
        return W5;
      case "SNOW":
        return W6;
      case "THUNDERSTORM":
        return W7;

      default:
        break;
    }
  };
  const getDegree = (direction) => {
    switch (direction) {
      case "WEST":
        return "270deg";
      case "EAST":
        return "90deg";

      case "NORTH":
        return "0deg";
      case "SOUTH":
        return "180deg";

      case "NORTHWEST":
        return "315deg";
      case "NORTHEAST":
        return "45deg";
      case "SOUTHEAST":
        return "135deg";
      case "SOUTHWEST":
        return "225deg";

      default:
        break;
    }
  };
  const theme = useTheme();

  const [daySelected, setDaySelected] = useState(0);

  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  // const weatherData = useSelector((state) => state.weather.weather.weatherData);
  const weatherData = [
    {
      day: "شنبه",
      status: "CLEAR",
      temp: 35,
      min: 33,
      max: 37,
      humidity: 40,
      precipitation: 0.1,
      windSpeed: 0.2,
      windDirection: "NORTHEAST",
      atmosphericPressure: 1,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "یک شنبه",
      status: "CLEAR",
      temp: 33,
      min: 31,
      max: 35,
      humidity: 35,
      precipitation: 0.15,
      windSpeed: 0.3,
      windDirection: "NORTH",
      atmosphericPressure: 1.5,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "دو شنبه",
      status: "CLEAR",
      temp: 31,
      min: 30,
      max: 32,
      humidity: 46,
      precipitation: 0.1,
      windSpeed: 0.25,
      windDirection: "SOUTH",
      atmosphericPressure: 1.7,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 30,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 32,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 32,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 32,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "سه شنبه",
      status: "CLEAR",
      temp: 37,
      min: 33,
      max: 40,
      humidity: 50,
      precipitation: 0.17,
      windSpeed: 0.41,
      windDirection: "SOUTHWEST",
      atmosphericPressure: 1.3,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 38,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 40,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "چهارشنبه",
      status: "CLEAR",
      temp: 40,
      min: 36,
      max: 43,
      humidity: 60,
      precipitation: 0.07,
      windSpeed: 0.43,
      windDirection: "SOUTHWEST",
      atmosphericPressure: 1.1,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 41,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 42,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 43,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 40,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 39,
            precipitation: 0.1,
          },
        },
      ],
    },
  ];
  const isMobileScreens = useMediaQuery("(max-width:600px)");
  function getCurrentTime() {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  const currentTime = getCurrentTime();

  // const result = weatherData.reduce(
  //   (acc, obj) => {
  //     // Update minimum minTemperature
  //     if (obj.values.minTemperature < acc.minMinTemperature) {
  //       acc.minMinTemperature = obj.values.minTemperature;
  //     }

  //     // Update maximum maxTemperature
  //     if (obj.values.maxTemperature > acc.maxMaxTemperature) {
  //       acc.maxMaxTemperature = obj.values.maxTemperature;
  //     }

  //     return acc;
  //   },
  //   { minMinTemperature: Infinity, maxMaxTemperature: -Infinity }
  // );

  // const minMinTemperature = result.minMinTemperature;
  // const maxMaxTemperature = result.maxMaxTemperature;
  // const day =
  //   currentTime < weatherData[0].values.sunset &&
  //   currentTime > weatherData[0].values.sunrise
  //     ? true
  //     : false;
  return (
    <>
      {isMobileScreens ? (
        <WeatherSMob />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          minWidth="1255px"
          width="calc(100% - 2.75rem)"
          height="89vh"
          m="16px"
        >
          <FlexBetween width="100%" height="99%" gap="16px">
            <Box
              sx={{
                width: "59.2%",
                height: "99%",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <FlexBetween
                sx={{ width: "100%", height: "29%" }}
                flexDirection="row"
              >
                {weatherData.map((item, index) => (
                  <WidgetWrapper
                    sx={{
                      width: "18.7%",
                      height: "100%",
                      backdropFilter: "blur(7.5px)",

                      backgroundColor:
                        index === daySelected
                          ? theme.palette.others.first
                          : theme.palette.background.second,

                      cursor: index === daySelected ? "auto" : "pointer",
                      ":hover": {
                        backgroundColor:
                          index !== daySelected
                            ? theme.palette.background.third
                            : theme.palette.others.first,
                      },
                    }}
                    onClick={() => setDaySelected(index)}
                  >
                    <FlexBetween
                      sx={{
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Typography variant="h5" fontWeight={600}>
                        {/* {index === 0 ? "امروز" : getDayName(item.epochDateTime)} */}
                        {item.day}
                      </Typography>
                      <Box component={"img"} src={getIcon(item.status)} />
                      <Typography variant="h2" fontWeight={400}>
                        {Math.round(item.temp)}°
                      </Typography>
                      <Typography variant="h2" fontWeight={400} mt="-0.5rem">
                        <DailyMob
                          mob={false}
                          min={item.min}
                          max={item.max}
                          minMinTemperature={30}
                          maxMaxTemperature={43}
                          // dayName={getDayName(item.epochDateTime)}
                          // monthDay={getMonthDayT(item.epochDateTime)}
                          // condition={item.values.condition}
                          // day={day}
                          index={index}
                        />
                      </Typography>
                    </FlexBetween>
                  </WidgetWrapper>
                ))}
              </FlexBetween>

              <WidgetWrapper
                position={"relative"}
                sx={{
                  width: "100%",
                  height: "26%",
                  p: "0rem 0.5rem",
                  overflow: "hidden",
                }}
              >
                <HourlyWeather
                  dataHourly={weatherData[daySelected].children}
                  daySelected={daySelected}
                />
              </WidgetWrapper>

              <Box sx={{ width: "100%", height: "21%" }}>
                <FlexBetween gap="16px" height="100%">
                  <WidgetWrapper sx={{ flex: 1, height: "100%" }}>
                    <UvIndex />
                  </WidgetWrapper>
                  <WidgetWrapper sx={{ flex: 1, height: "100%" }}>
                    <Sunset sunset={"19:20"} sunrise={"5:12"} />
                  </WidgetWrapper>
                </FlexBetween>
              </Box>
              <WidgetWrapper sx={{ width: "100%", height: "20%" }}>
                <AirSensorJ />
              </WidgetWrapper>
            </Box>
            <Box
              sx={{
                width: "40%",
                height: "99%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component={"img"}
                src={WeatherDayImage}
                sx={{ borderRadius: "8px", width: "100%" }}
              />
              <Box sx={{ position: "absolute", top: "110px" }}>
                <FlexBetween sx={{ flexDirection: "column" }}>
                  <Stack direction="row-reverse">
                    <Typography
                      fontSize="80px"
                      fontFamily="YekanBakhFaNum-Regular"
                      color={main}
                    >
                      {Math.round(weatherData[daySelected].temp)}
                    </Typography>
                    <Typography fontSize={64}>°</Typography>
                  </Stack>
                  <FlexBetween
                    sx={{
                      alignItems: "center",
                      mt: "-32px",
                    }}
                  >
                    <Stack direction="row-reverse">
                      <Typography
                        fontSize="20px"
                        fontFamily="YekanBakhFaNum-Regular"
                        color={main}
                      >
                        {Math.round(weatherData[daySelected].min)}
                      </Typography>
                      <Typography>°</Typography>
                    </Stack>
                    <Stack direction="row-reverse">
                      <Typography
                        fontSize="32px"
                        fontFamily="YekanBakhFaNum-Regular"
                        color={main}
                      >
                        {Math.round(weatherData[daySelected].max)}
                      </Typography>
                      <Typography
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        °
                      </Typography>
                    </Stack>
                  </FlexBetween>
                  <Typography
                    variant="h4"
                    color={main}
                    sx={{ direction: "rtl", mt: "-4px" }}
                  >
                    20:30 - 1402/01/09
                    {/* {getDateShamsiAirSensors(
                      weatherData[daySelected].epochDateTime
                    )} */}
                  </Typography>
                </FlexBetween>
              </Box>
              <FlexBetween
                sx={{ position: "absolute", bottom: "16px", gap: "0.5rem" }}
              >
                <Box
                  p="9.5px 11.5px"
                  width="125px"
                  height="91px"
                  sx={{
                    backgroundColor: "rgba(29, 29, 65, 0.7)",
                    backdropFilter: "blur(7.5px)",
                    borderRadius: "8px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={400}
                      color={main}
                    >
                      رطوبت
                    </Typography>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={600}
                      color={main}
                    >
                      {Math.round(weatherData[daySelected].humidity)}%
                    </Typography>
                  </FlexBetween>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <DonutMultiple size={"75px"}>
                        <DonutElement color={theme.palette.background.second}>
                          {Math.round(weatherData[daySelected].humidity)}
                        </DonutElement>
                        <DonutElement
                          color={theme.palette.others.third}
                          style={{ borderRadius: "20px" }}
                        >
                          {100 - Math.round(weatherData[daySelected].humidity)}
                        </DonutElement>
                        <DonutLabel>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography variant="h4">
                              {Math.round(weatherData[daySelected].humidity)}
                            </Typography>
                            <Typography
                              fontSize={theme.typography.smallestTextPrimary}
                            >
                              %
                            </Typography>
                          </Box>
                        </DonutLabel>
                      </DonutMultiple>
                    </Box>
                  </Box>
                </Box>
                <Box
                  p="9.5px 11.5px"
                  width="125px"
                  height="91px"
                  sx={{
                    backgroundColor: "rgba(29, 29, 65, 0.7)",
                    backdropFilter: "blur(7.5px)",
                    borderRadius: "8px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={400}
                      color={main}
                    >
                      بارندگی
                    </Typography>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={600}
                      color={main}
                    >
                      {Math.floor(
                        weatherData[daySelected].precipitation
                      ).toFixed(2)}
                      mm
                    </Typography>
                  </FlexBetween>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgressBar
                      value={Math.floor(
                        weatherData[daySelected].precipitation
                      ).toFixed(2)}
                      diameter={50}
                      start_from="start"
                      // gaugeMinMax={40}
                      color={theme.palette.others.third}
                    >
                      <Typography
                        style={{
                          direction: "ltr",
                          color: theme.palette.others.third,
                        }}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {`${Math.floor(
                          weatherData[daySelected].precipitation
                        ).toFixed(2)}mm`}
                      </Typography>
                    </CircularProgressBar>
                  </Box>
                </Box>
                <Box
                  p="9.5px 11.5px"
                  width="125px"
                  height="91px"
                  sx={{
                    backgroundColor: "rgba(29, 29, 65, 0.7)",
                    backdropFilter: "blur(7.5px)",
                    borderRadius: "8px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={400}
                      color={main}
                    >
                      باد
                    </Typography>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={600}
                      color={main}
                    >
                      {(
                        Math.round(weatherData[daySelected].windSpeed * 100) /
                        100
                      ).toFixed(2)}
                      km
                    </Typography>
                  </FlexBetween>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      width="50px"
                      height="50px"
                      sx={{
                        borderRadius: "50%",
                        border: `4px solid #1d1d41`,
                        position: "relative",
                        mt: "-0.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{ position: "absolute", top: "0%", right: "43%" }}
                        fontSize="8px"
                      >
                        N
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "0%",
                          right: "43%",
                        }}
                        fontSize="8px"
                      >
                        S
                      </Typography>

                      <Box
                        component="img"
                        src={ArrowIcon}
                        sx={{
                          rotate: getDegree(
                            weatherData[daySelected].windDirection
                          ),
                          zIndex: 1000,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  p="9.5px 11.5px"
                  width="125px"
                  height="91px"
                  sx={{
                    backgroundColor: "rgba(29, 29, 65, 0.7)",
                    backdropFilter: "blur(7.5px)",
                    borderRadius: "8px",
                  }}
                >
                  <FlexBetween>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={400}
                      color={main}
                    >
                      فشار
                    </Typography>
                    <Typography
                      fontSize={theme.typography.smallestTextPrimary}
                      fontWeight={600}
                      color={main}
                    >
                      {(
                        weatherData[daySelected].atmosphericPressure / 1000
                      ).toFixed(3)}
                      bar
                    </Typography>
                  </FlexBetween>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgressBar
                      value={
                        Math.round(
                          weatherData[daySelected].atmosphericPressure
                        ) / 1000
                      }
                      diameter={50}
                      start_from="start"
                      // gaugeMinMax={40}
                      color={theme.palette.others.third}
                    >
                      <Typography
                        style={{
                          direction: "ltr",
                          color: theme.palette.others.third,
                        }}
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        {`${
                          Math.round(
                            weatherData[daySelected].atmosphericPressure
                          ) / 1000
                        }bar`}
                      </Typography>
                    </CircularProgressBar>
                  </Box>
                </Box>
              </FlexBetween>
            </Box>
          </FlexBetween>
          <CopyRight />
        </Box>
      )}
    </>
  );
};

export default Weather;
