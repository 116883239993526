import { useMediaQuery } from "@mui/material";
import React from "react";
import Compare from "./Compare";
import CompareMob from "mob/CompareMob/CompareMob";

const CompareIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <CompareMob /> : <Compare />}</>;
};

export default CompareIndex;
