import { Box, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";

const PackageItemMob = ({
  title,
  price,
  unit,
  enable = false,
  count,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      sx={{
        backgroundColor: theme.palette.background.third,
        borderRadius: "8px",
        flex: "1 1 40%",
        padding: "8px",
        gap: "12px",
      }}
    >
      <FlexBetween>
        <Typography variant="h5" color="fontColor.main">
          {title}
        </Typography>
        {enable && (
          <Box
            width="60px"
            height="23px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
            sx={{ backgroundColor: "others.third" }}
          >
            <Typography>فعال</Typography>
          </Box>
        )}
      </FlexBetween>
      <Stack direction="row" alignItems="center">
        <Typography variant="h2" color="fontColor.main">
          {price}
        </Typography>
        <Typography variant="subHeading" color="fontColor.main">
          {unit}
        </Typography>
      </Stack>
      {count && (
        <Typography variant="subHeading" color="fontColor.main">
          {count} توصیه
        </Typography>
      )}
      <Box
        height="36px"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "others.first",
          cursor: "pointer",
        }}
      >
        <Typography>تمدید سرویس</Typography>
      </Box>
    </Box>
  );
};

export default PackageItemMob;
