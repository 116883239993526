import React, { useState } from "react";
import BottomDrawer from "./ButtomDrawer";
import AllLivestockMob from "./AllLivestockMob";
import DrynessMob from "./DrynessMob";
import EstrusMob from "./EstrusMob";
import BirthMob from "./BirthMob";
import InoculatedMob from "./InoculatedMob";
import VaccineMob from "./VaccineMob";
import PickingCowHoofsMob from "./PickingCowHoofsMob";
import TreatmentMob from "./TreatmentMob";
import MediceneMob from "./MediceneMob";
import HealthStatusMob from "./HealthStatusMob";
import AnimalComfortMob from "./AnimalComfortMob";

const LivestockManagementMob = () => {
  const [selectedTitle, setSelectedTitle] = useState("کل دام");
  return (
    <>
      {selectedTitle === "کل دام" && <AllLivestockMob />}
      {selectedTitle === "خشکی" && <DrynessMob />}
      {selectedTitle === "فحلی" && <EstrusMob />}
      {selectedTitle === "زایش" && <BirthMob />}
      {selectedTitle === "تلقیح" && <InoculatedMob />}
      {selectedTitle === "واکسن" && <VaccineMob />}
      {selectedTitle === "سم چینی" && <PickingCowHoofsMob />}
      {selectedTitle === "درمان" && <TreatmentMob />}
      {selectedTitle === "دارو" && <MediceneMob />}
      {selectedTitle === "وضعیت سلامت" && <HealthStatusMob />}
      {selectedTitle === "آسایش دام" && <AnimalComfortMob />}
      <BottomDrawer
        selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle}
      />
    </>
  );
};

export default LivestockManagementMob;
