import { apiBaseUrl } from './common';

const propertyPath = 'properties';
const observationPath = 'earth-observations/property';

export const getFarmData = ({ token }) =>
    fetch(`${apiBaseUrl}/${propertyPath}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
export const getIndexData = ({ farmId, token }) =>
    farmId &&
    fetch(`${apiBaseUrl}/${observationPath}/${farmId}/indices`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
export const getLayerData = ({ token, farmId, indexLayerName, query_string }) =>
    farmId &&
    indexLayerName &&
    query_string &&
    fetch(`${apiBaseUrl}/${observationPath}/${farmId}/indices/${indexLayerName}${query_string}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
export const getChartData = ({ token, farmId, indexLayerName, query_string }) =>
    farmId &&
    indexLayerName &&
    query_string &&
    fetch(`${apiBaseUrl}/${observationPath}/${farmId}/indices/${indexLayerName}/data${query_string}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
