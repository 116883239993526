import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import CopyRight from "widgets/CopyRight";
import {
  AddOutlined,
  KeyboardArrowDownOutlined,
  Search,
} from "@mui/icons-material";
import LiveChart from "./LiveChart";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import DownloadIcon from "../../assets/Icons/download.png";
import AllLivestock from "./AllLivestock";
import Inoculated from "./Inoculated";
import Dryness from "./Dryness";
import Vaccine from "./Vaccine";
import Birth from "./Birth";
import Estrus from "./Estrus";
import PickingCowHoofs from "./PickingCowHoofs";
import Treatment from "./Treatment";
import Medicine from "./Medicene";
import HealthStatusM from "./HealthStatus";
import FamilyTree from "./FamilyTree";
import AnimalComfort from "./AnimalComfort";

const LivestockManagement = () => {
  const theme = useTheme();
  const [liveGroup, setLiveGroup] = useState(false);
  const [actions, setActions] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("کل دام");
  const LiveItem = ({ id, title }) => {
    return (
      <Box
        width="168px"
        height="32px"
        display="flex"
        alignItems="center"
        mb="0.2rem"
        p="0.5rem"
        borderRadius="4px"
        sx={{
          backgroundColor:
            selectedTitle === title
              ? theme.palette.background.third
              : "transparent",
          ":hover": {
            backgroundColor: "background.third",
            cursor: "pointer",
          },
        }}
        onClick={() => setSelectedTitle(title)}
      >
        <Typography variant="subHeading" color="fontColor.main">
          {title}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="89vh">
        <WidgetWrapper flex={1} height="98%">
          <FlexBetween>
            <Typography variant="h5" color="fontColor.main">
              دسته بندی دام
            </Typography>
            <IconButton onClick={() => setLiveGroup(!liveGroup)}>
              <KeyboardArrowDownOutlined
                sx={{ rotate: !liveGroup ? "-180deg" : "" }}
              />
            </IconButton>
          </FlexBetween>
          <Collapse in={!liveGroup} timeout={500}>
            <Box overflow="hidden">
              <LiveItem id={1} title="خشکی" />
              <LiveItem id={2} title="فحلی" />
              <LiveItem id={3} title="کل دام" />
            </Box>
          </Collapse>

          <FlexBetween mt="1rem">
            <Typography variant="h5" color="fontColor.main">
              اقدامات
            </Typography>
            <IconButton onClick={() => setActions(!actions)}>
              <KeyboardArrowDownOutlined
                sx={{ rotate: !actions ? "-180deg" : "" }}
              />
            </IconButton>
          </FlexBetween>
          <Collapse in={!actions} timeout={500}>
            <Box overflow="hidden">
              <LiveItem id={4} title="زایش" />
              <LiveItem id={5} title="تلقیح" />
              <LiveItem id={6} title="واکسن" />
              <LiveItem id={7} title="سم چینی" />
              <LiveItem id={8} title="درمان" />
              <LiveItem id={9} title="دارو" />
              <LiveItem id={10} title="وضعیت سلامت" />
              <LiveItem id={11} title="آسایش دام" />
            </Box>
          </Collapse>

          <Box
            mt="1rem"
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => setSelectedTitle("شجره دام")}
          >
            <Typography variant="h5" color="fontColor.main">
              شجره دام
            </Typography>
          </Box>
        </WidgetWrapper>
        <Box flex={7} height="98%">
          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {selectedTitle === "کل دام" && (
              <AllLivestock selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "تلقیح" && (
              <Inoculated selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "خشکی" && (
              <Dryness selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "واکسن" && (
              <Vaccine selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "زایش" && (
              <Birth selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "فحلی" && (
              <Estrus selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "سم چینی" && (
              <PickingCowHoofs selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "درمان" && (
              <Treatment selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "دارو" && (
              <Medicine selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "وضعیت سلامت" && (
              <HealthStatusM selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "شجره دام" && (
              <FamilyTree selectedTitle={selectedTitle} />
            )}
            {selectedTitle === "آسایش دام" && (
              <AnimalComfort selectedTitle={selectedTitle} />
            )}
          </Box>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default LivestockManagement;
