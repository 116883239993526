import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect, useState } from "react";
import ChartSensorsManagement from "./ChartSensorsManagement";
import MeanIcon from "../../assets/chart.png";
import DirectionIcon from "../../assets/direction.png";
import Gauge from "components/Gauge/Gauge";
import GaugeChart from "react-gauge-chart";
import CustomGauge from "components/CustomGauge/CustomGauge";
const BoxSensorsManagement = ({ data, type, color, toggle, max }) => {
  let minNumber = 99999;
  let maxNumber = -99999;
  let mean = 0;
  let mainNumber = data[data.length - 1].v;
  data.map((item) => {
    if (item.v < minNumber) {
      minNumber = item.v;
    }
    if (item.v > maxNumber) {
      maxNumber = item.v;
    }
    mean = mean + item.v;
  });

  mean = mean / data.length;
  mean = Math.floor(mean);
  const [value, setValue] = useState(" ");
  const theme = useTheme();
  const convertName = (type) => {
    switch (type) {
      case "wind_speed":
        return "باد";
      case "solar_radiation":
        return "شدت تابش";

      case "atmospheric_pressure":
        return "فشار";

      case "humidity":
        return "رطوبت";

      case "temperature":
        return "دما";

      case "precipitation_rain":
        return "بارندگی";
      case "wind_direction":
        return "wind_direction";

      default:
        break;
    }
  };
  const convertUnit = (type) => {
    switch (type) {
      case "wind_speed":
        return "km/h";
      case "solar_radiation":
        return "lux";

      case "atmospheric_pressure":
        return "bar";

      case "humidity":
        return "%";

      case "temperature":
        return "°";

      case "precipitation_rain":
        return "%";

      default:
        break;
    }
  };
  const str = (type) => {
    switch (type) {
      case "temperature":
        return (
          <sup>
            <sup>&deg;</sup>
          </sup>
        );
      case "humidity":
        return <span>%</span>;
      case "precipitation_rain":
        return <span>mm</span>;
      case "wind_speed":
        return <span>km/h</span>;
      case "solar_radiation":
        return <span>Lux</span>;
      case "atmospheric_pressure":
        return <span>bar</span>;
      default:
        break;
    }
  };

  const getColor = (type) => {
    const temperatureThreshold1 = 10;
    const temperatureThreshold2 = 30;
    const humidityThreshold1 = 30;
    const humidityThreshold2 = 60;
    switch (type) {
      case "temperature":
        if (
          mainNumber > temperatureThreshold1 &&
          mainNumber < temperatureThreshold2
        )
          return theme.palette.others.third;
        if (mainNumber > temperatureThreshold2)
          return theme.palette.others.second;
        if (mainNumber < temperatureThreshold1)
          return theme.palette.others.forth;
        break;
      case "humidity":
        if (mainNumber > humidityThreshold1 && mainNumber < humidityThreshold2)
          return theme.palette.others.third;
        if (mainNumber > humidityThreshold2) return theme.palette.others.forth;
        if (mainNumber < humidityThreshold1) return theme.palette.others.second;
        break;
      case "precipitation_rain":
        return theme.palette.others.third;
      case "wind_speed":
        return theme.palette.others.third;
      case "solar_radiation":
        return theme.palette.others.third;
      case "atmospheric_pressure":
        return theme.palette.others.third;
      default:
        break;
    }
  };
  useEffect(() => {
    setValue(str());
  }, []);
  return (
    <Box
      sx={{
        minWidth: "190px",
        minHeight: "163px",
        backgroundColor: "rgba(29, 29, 65, 0.7)",
        backdropFilter: "blur(7.5px)",
        borderRadius: "8px",
        p: "0.5rem 1rem",
      }}
    >
      <FlexBetween sx={{ height: "50px" }}>
        <Typography fontSize="16px" color={theme.palette.fontColor.main}>
          {convertName(type)}
        </Typography>
        <FlexBetween>
          {type === "wind_speed" ? (
            <Box
              component="img"
              src={DirectionIcon}
              ml="0.5rem"
              sx={{ rotate: "45deg" }}
            />
          ) : null}
          {type !== "humidity" ? (
            <>
              <Typography fontSize="16px" color={getColor(type)}>
                {str(type)}
              </Typography>
              <Typography
                variant="h1"
                color={getColor(type)}
                sx={{ direction: "ltr" }}
              >
                {Math.floor(mainNumber)}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h1"
                color={getColor(type)}
                sx={{ direction: "ltr" }}
              >
                {Math.floor(mainNumber)}
              </Typography>
              <Typography fontSize="16px" color={getColor(type)}>
                {str(type)}
              </Typography>
            </>
          )}
        </FlexBetween>
      </FlexBetween>
      <FlexBetween sx={{ height: "25px" }}>
        <FlexBetween gap="0.3rem">
          <Typography fontSize="10px" color={theme.palette.fontColor.alt}>
            <Box component="img" src={MeanIcon} />
          </Typography>
          <FlexBetween>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.main}
            >
              {str(type)}
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.fontColor.main}
              sx={{ direction: "ltr" }}
            >
              {mean}
            </Typography>
          </FlexBetween>
        </FlexBetween>
        <FlexBetween gap="0.5rem">
          <FlexBetween>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {str(type)}
            </Typography>
            <Typography
              variant="h4"
              color={theme.palette.fontColor.alt}
              sx={{ direction: "ltr" }}
            >
              {Math.floor(minNumber)}
            </Typography>
          </FlexBetween>
          <FlexBetween>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.main}
            >
              {str(type)}
            </Typography>

            <Typography
              variant="h4"
              color={theme.palette.fontColor.main}
              sx={{ direction: "ltr" }}
            >
              {Math.floor(maxNumber)}
            </Typography>
          </FlexBetween>
        </FlexBetween>
      </FlexBetween>
      <Box
        mr="0.25rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {toggle ? (
          <ChartSensorsManagement dataChart={data} />
        ) : (
          // <Gauge
          //   persent={mainNumber}
          //   type={type}
          //   color={"#3D8361"}
          //   fontS="12px"
          //   // type={convertName(type)}
          //   max={max}
          // />
          <CustomGauge
            units={convertUnit(type)}
            fs="16px"
            selectColor={getColor(type)}
            value={mainNumber}
            max={max}
            size={"80px"}
          />
        )}
      </Box>
    </Box>
  );
};

export default BoxSensorsManagement;
