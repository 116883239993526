import { AddOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import CenterStack from "components/styledComponents/CenterStack";
import React, { useState } from "react";
import DownloadIcon from "../../assets/Icons/download.png";
import DeleteIcon from "../../assets/Icons/delete.png";
import LiveChart from "scenes/LivestockManagement/LiveChart";
import VaccineFormMob from "./VaccineFormMob";
import { PickingCowHoofsData } from "Mock/PickingCowHoofsData";
import PickingCowHoofsFormMob from "./PickingCowHoofsFormMob";
const PickingCowHoofsMob = () => {
  const theme = useTheme();
  const [isAddItem, setIsAddItem] = useState(false);
  const main = theme.palette.fontColor.main;
  return isAddItem ? (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <PickingCowHoofsFormMob setIsAddItem={setIsAddItem} />
    </Box>
  ) : (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <WidgetWrapper height="248px">
        <Stack direction="row" alignItems="center" gap="8px">
          <Typography variant="h5" color="fontColor.Main">
            سم چینی شده ها
          </Typography>
          <Typography variant="smallestTextSecondary" color="fontColor.alt">
            1 ماه گذشته
          </Typography>
        </Stack>
        <Box mt="1rem">
          <AnimatedProgressBar
            number={75}
            width={157}
            height={163}
            title={PickingCowHoofsData.status[0][0]}
            subTitle={PickingCowHoofsData.status[0][1]}
            color={theme.palette.others.first}
            dot={false}
          />
        </Box>
      </WidgetWrapper>
      <WidgetWrapper height="257px" mt="12px">
        <Typography variant="h4" color="fontColor.main">
          سم چینی
        </Typography>
        <LiveChart
          data={PickingCowHoofsData.performance}
          color={theme.palette.others.first}
          r={255}
          g={255}
          b={255}
        />
      </WidgetWrapper>
      <WidgetWrapper mt="12px" mb="70px">
        <FlexBetween>
          <Typography variant="h4" color="fontColor.main">
            سم چینی شده ها
          </Typography>
          <Stack direction="row" gap="16px">
            <IconButton>
              <Box component="img" src={DownloadIcon} />
            </IconButton>
            <IconButton onClick={() => setIsAddItem(true)}>
              <CenterStack
                sx={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                }}
              >
                <AddOutlined />
              </CenterStack>
            </IconButton>
          </Stack>
        </FlexBetween>
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            // ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            m: "0.75rem 0 1rem 0",
          }}
        >
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "27px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
          <Search sx={{ ml: "0.2rem" }} />
        </FlexBetween>
        {PickingCowHoofsData.livestock.map((item, index) => (
          <>
            <Box
              width="100%"
              height="69px"
              display="flex"
              flexDirection="column"
              justifyContent="space-evenly"
            >
              <FlexBetween>
                <Typography variant="body" color="fontColor.main">
                  دام {item.code}
                </Typography>
                <Box component="img" src={DeleteIcon} />
              </FlexBetween>
              <FlexBetween>
                <Typography variant="subHeading" color="fontColor.alt">
                  {item.nextTime}
                </Typography>
                <Typography
                  variant="subHeading"
                  color="fontColor.alt"
                ></Typography>
              </FlexBetween>
            </Box>

            {index !== PickingCowHoofsData.livestock.length - 1 && <Divider />}
          </>
        ))}
      </WidgetWrapper>
    </Box>
  );
};

export default PickingCowHoofsMob;
