import {
  ArrowUpwardOutlined,
  DownloadOutlined,
  EditOutlined,
  LocationOnOutlined,
  Refresh,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import RefreshIcon from "../../assets/Refresh.png";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import ArrowIcon from "../../assets/arrow.png";
import DetailsSensors from "./DetailsSensors";

const mockData = [
  {
    deviceName: "شماره یک",
    status: "ACTIVE",
    deviceType: "سنسور",
    coordinates: "",
    serialNumber: "PTR-30505",
    lastSendData: "1402/02/01 - 20:30",
    weight: "20",
    battery: "LOW",
    anntena: "LOWEST",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره دو",
    status: "ACTIVE",
    deviceType: "گیت وی",
    coordinates: "",
    serialNumber: "PTR-31525",
    lastSendData: "1402/02/02 - 20:30",
    weight: "15",
    battery: "LOWEST",
    anntena: "HIGH",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره سه",
    status: "INACTIVE",
    deviceType: "سنسور",
    coordinates: "",
    serialNumber: "PTR-30525",
    lastSendData: "1402/02/05 - 11:30",
    weight: "20",
    battery: "HIGH",
    anntena: "LOW",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره یک",
    status: "INACTIVE",
    deviceType: "عملگر",
    coordinates: "",
    serialNumber: "PTR-35227",
    lastSendData: "1402/02/09 - 17:30",
    weight: "51",
    battery: "HIGH",
    anntena: "HIGH",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
];
const RecordSensorsTable = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [searchValue, setSearchValue] = useState("");
  const [toggleName, setToggleName] = useState(false);
  const [toggleDate, setToggleDate] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [toggle, setToggle] = useState(false);

  const [toggles, setToggles] = useState(
    mockData ? mockData.map(() => false) : false
  );

  const handleToggle = (index) => {
    setToggles((prevToggles) => {
      const newToggles = [...prevToggles];
      newToggles[index] = !newToggles[index];
      return newToggles;
    });
  };
  const sortedData =
    searchType === "name"
      ? toggleName
        ? mockData.sort((a, b) => a.deviceName.localeCompare(b.deviceName))
        : mockData.sort((a, b) => b.deviceName.localeCompare(a.deviceName))
      : toggleDate
      ? mockData.sort((a, b) => a.lastSendData.localeCompare(b.lastSendData))
      : mockData.sort((a, b) => b.lastSendData.localeCompare(a.lastSendData));

  const Data = sortedData.filter((item) =>
    searchValue !== "" ? item.deviceName.includes(searchValue) : item
  );
  const getBatteryStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return EmptyIcon;
      case "LOW":
        return Full3Icon;
      case "HIGH":
        return FullIcon;
      default:
        break;
    }
  };
  const getSignalStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return WifiIcon;
      case "LOW":
        return Wifi1Icon;
      case "HIGH":
        return Wifi3Icon;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <FlexBetween>
        <FlexBetween
          sx={{
            // display: open ? "inline-flex" : "none",
            transition: "all 0.2s ease",
            ml: "1.5rem",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            // border: "1px solid black",
            borderRadius: "8px",
            width: "200px",
          }}
        >
          <Search sx={{ mr: "0.2rem" }} />
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            id="inputSearchRemote"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
              },
              height: "27px",
              width: "200px",
              borderRadius: "8px",
              color: theme.palette.fontColor.main,
              outline: "none",
              borderBottom: "none",
            }}
          />
        </FlexBetween>
        <IconButton>
          <DownloadOutlined />
        </IconButton>
      </FlexBetween>
      <Box width="100%" mt="1rem" height="90%">
        <Box display="flex" alignItems="center">
          <Box width="10%" mr="1.75rem">
            <Typography variant="subHeading" color={alt}>
              نام دستگاه
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleName ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleName(!toggleName);
                setSearchType("name");
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </Box>
          <Box
            width="5%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              وضعیت
            </Typography>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              نوع دستگاه
            </Typography>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              مختصات
            </Typography>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              شماره سریال
            </Typography>
          </Box>
          <Box
            width="18%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              آخرین داده ارسالی
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleName ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleName(!toggleName);
                setSearchType("name");
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </Box>
          <Box
            width="10%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              وزن دستگاه
            </Typography>
          </Box>
          <Box
            width="7%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              باتری
            </Typography>
            <IconButton
              sx={{
                width: "20px",
                height: "20px",
                rotate: toggleName ? "0deg" : "180deg",
              }}
              onClick={() => {
                setToggleName(!toggleName);
                setSearchType("name");
              }}
            >
              <ArrowUpwardOutlined
                sx={{ width: "15px", height: "15px", color: alt }}
              />
            </IconButton>
          </Box>
          <Box
            width="7%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              آنتن
            </Typography>
          </Box>
          <Box
            width="7%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="subHeading" color={alt}>
              ویرایش
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "10px" }} />
        <Box
          sx={{
            width: "100%",
            height: "97%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {Data.map((item, index) => (
            <Box m="1rem 0">
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Box width="10.45%" mr="1.5rem">
                    <Box display="flex" alignItems="center" gap="0.5rem">
                      <Typography variant="subHeading" color={main}>
                        {item.deviceName}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color:
                            item.status === "ACTIVE"
                              ? theme.palette.others.third
                              : theme.palette.others.second,
                        },
                      }}
                      checked={true}
                    />
                  </Box>
                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="subHeading" color={main}>
                      {item.deviceType}
                    </Typography>
                  </Box>
                  <Box
                    width="10.5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="subHeading" color={main}>
                      <LocationOnOutlined />
                    </Typography>
                  </Box>
                  <Box
                    width="10.2%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography variant="subHeading" color={main}>
                      {item.serialNumber}
                    </Typography>
                  </Box>
                  <Box
                    width="18.5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="subHeading"
                      color={main}
                      textAlign="center"
                      // sx={{ direction: "ltr" }}
                    >
                      {item.lastSendData}
                    </Typography>
                  </Box>
                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="subHeading"
                      color={main}
                      textAlign="center"
                    >
                      {item.weight}
                    </Typography>
                  </Box>

                  <Box
                    width="7%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box component="img" src={getBatteryStatus(item.battery)} />
                  </Box>
                  <Box
                    width="7%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box component="img" src={getSignalStatus(item.anntena)} />
                  </Box>
                  <Box
                    width="7%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <EditOutlined
                      sx={{ width: "16px", height: "16px", mr: "0.5rem" }}
                    />
                  </Box>

                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton
                      sx={{ rotate: toggles[index] ? "180deg" : "0deg" }}
                      onClick={() => handleToggle(index)}
                    >
                      <Box
                        component="img"
                        src={ArrowIcon}
                        sx={{ rotate: toggle ? 0 : "180deg" }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                {toggles[index] && (
                  <DetailsSensors
                    description={item.description}
                    period={item.periodSendData}
                    recordDate={item.recordDate}
                  />
                )}
              </Box>
              <Divider sx={{ marginTop: "10px" }} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RecordSensorsTable;
