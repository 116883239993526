import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import rtlPlugin from "stylis-plugin-rtl";
import jwt_decode from "jwt-decode";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween/FlexBetween";
import axios from "axios";
import { Close, Done, Visibility, VisibilityOff } from "@mui/icons-material";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  width: "400px",
  height: "44px",
  "& .MuiInputBase-root": {
    height: "44px",
  },
  "& .MuiFormLabel-root": {
    marginLeft: "-3px",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const CssOutlinedInput = styled(OutlinedInput)({
  width: "400px",
  height: "44px",
  "& .MuiFormLabel-root": {
    color: "white",
  },
  // "& .MuiFormLabel-root": {
  //   marginLeft: "-3px",
  // },
  // "& label.Mui-focused": {
  //   color: "#F7F7F7",
  // },

  // "& .MuiInput-underline:after": {
  //   borderBottomColor: "#F7F7F7",
  //   borderRadius: "8px",
  // },
  // "& .MuiOutlinedInput-root": {
  //   color: "#F7F7F7 !important",
  //   borderRadius: "8px",

  //   "& fieldset": {
  //     borderColor: "#F7F7F7",
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "#F7F7F7",
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "#F7F7F7",
  //   },
  // },
});

const FormRegisterFarmer = ({ emailSignInStart, googleSignInStart }) => {
  const AtEightCh = /(?:[a-zA-Z][^a-zA-Z]*){8}/;
  const AtOneNum = /.*\d+.*/;
  const AtOneUpper = /(?=.*[A-Z])/;
  const [checkPassword, setCheckPassword] = useState("");
  const [eightCh, setEightCh] = useState("");
  const [oneNum, setoneNum] = useState("");
  const [oneUpper, setOneUpper] = useState("");
  const theme = useTheme();
  const handleLoginClick = async () => {
    const token =
      "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICItd1FsTlRPaDlENFZWM092RTlLTTlKRnRwcjJSekYtdGVka1NPVzQ4YWprIn0.eyJleHAiOjE2ODgyODExMzksImlhdCI6MTY4ODI3OTMzOSwianRpIjoiNmU1OWMzNGMtNDhmNS00ZjMwLWJhNzUtZWQ2YzU2ZjQ4NWQzIiwiaXNzIjoiaHR0cHM6Ly9hY2NvdW50cy5yYWhiYWFuLmlyL3JlYWxtcy9yYWhiYWFuIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6IjFjYTY3OWVmLTMxMzAtNGZkYi04ZmRlLTlkMDg0ZTUxNjg0MyIsInR5cCI6IkJlYXJlciIsImF6cCI6InVzZXItcGFuZWwiLCJzZXNzaW9uX3N0YXRlIjoiNzI0MDM5YmEtZTVhNi00ZjFhLTgxMGItY2UzMTk2YjRiYzYzIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm93bmVyIiwib2ZmbGluZV9hY2Nlc3MiLCJkZWZhdWx0LXJvbGVzLXJhaGJhYW4iLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsInNpZCI6IjcyNDAzOWJhLWU1YTYtNGYxYS04MTBiLWNlMzE5NmI0YmM2MyIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ1c2VyMSIsImdpdmVuX25hbWUiOiJBbGkiLCJ1c2VySWQiOiJjMGYyNDIyMC1iZGY2LTExZWQtOGU5Ni0yOTVkYzE1Y2NhOTgiLCJlbmFibGVkIjp0cnVlLCJjdXN0b21lck5hbWUiOiJlNWM5YTE1NC1iYjhjLTRmYmYtYWVmMS0zMWNiOTU3ZTZkNTMiLCJ0ZW5hbnRJZCI6ImE1NjJhYTEwLWJkZjQtMTFlZC04ZTk2LTI5NWRjMTVjY2E5OCIsImN1c3RvbWVySWQiOiJjMGVmODMwMC1iZGY2LTExZWQtOGU5Ni0yOTVkYzE1Y2NhOTgiLCJuYW1lIjoiQWxpIEJhYmFlaSIsImlzUHVibGljIjpmYWxzZSwiZmFtaWx5X25hbWUiOiJCYWJhZWkiLCJwcm9wZXJ0eUlkIjoiMzVmODM3MjAtYzE3NC0xMWVkLWJjMTctNmIwODA4ZGIzZWVmIiwiZW1haWwiOiJ1c2VyMUBkb21haW4xLmNvbSJ9.OOGu5ED0e1n-VF35w37s49gYGuM4r2t929NecHtzR8XOqrZ8ABLwr8F10Q-sJF29OOLzCjWaAwowOG4yybiiYBVVPAjJ0LSAOTu-l0Lj53iofAMnH-pbOApnzB8Qzf5pzuQyY78P5_u4aLcy_HQAayyU6uWdG_ZaT3ykq7FdRxulzeZmtAyQKvtQp8rm9xn4DDFwYlumwNIBrtT545oavMO34O4qq5-ezlOYnxQr_U09JNhs3oUvNkqfVFg8qW_Yx2NcZ_FrPEr9uzIqoFL0DCB1GoWPEe4x4x_qRn3708oAD9lIYWUAy_-T0aA8Hy-O40vEFNP8idH6AfD0JlTFNQ";

    const headers = {
      Authorization: `Bearer ${token}`,
    };
    console.log("peyman");

    try {
      const response = await axios.post(
        "https://api.rahbaan.ir/v1/registration/register",
        formValues,
        { headers }
      );

      console.log(response.data);

      setFormValues({
        username: "",
        mobile: "",
        email: "",
        password: "",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [token, setToken] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setToken(tokenResponse);
      // navigate("/");
      // checkToken(tokenResponse);
      console.log(
        "🚀 ~ file: Form.jsx:56 ~ Form ~ tokenResponse",
        tokenResponse
      );
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  const checkToken = (token) => {
    const t = jwt_decode(token);
    console.log("🚀 ~ file: Form.jsx:55 ~ Form ~ tokenResponse", t);
  };
  useEffect(() => {
    console.log("login");
  }, [token]);

  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  const { email, password } = userCredentials;

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [formValues, setFormValues] = useState({
    username: "",
    mobile: "",
    email: "",
    password: "",
  });
  const [selectedUser, setSelectedUser] = useState("");
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        sx={{
          width: "400px",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          direction: "ltr",

          mt: "1rem",
        }}
      >
        <form onSubmit={handleLoginClick}>
          <Typography
            color="#F7F7F7"
            fontSize="40px"
            textAlign="center"
            mt="-1rem"
            mb="1rem"
          >
            ثبت نام
          </Typography>
          <Stack
            direction="column"
            spacing={4}
            alignItems="center"
            width="400px"
            // sx={{ height: "100%" }}
          >
            <CssTextField
              label="نام کاربری"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                },
              }}
            />
            <CssTextField
              label="شماره همراه"
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                },
              }}
            />
            <CssTextField
              label="ایمیل"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                },
              }}
            />
            <Box position={"relative"}>
              <CssTextField
                label="رمز عبور"
                autoComplete="new-password"
                type={showPassword ? "password" : "text"}
                onChange={(e) => {
                  setEightCh(AtEightCh.test(e.target.value));
                  setOneUpper(AtOneUpper.test(e.target.value));
                  setoneNum(AtOneNum.test(e.target.value));
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    // set the color of the label when not shrinked
                    color: "#F7F7F7",
                    fontSize: "16px",
                  },
                }}
              />
              <Box sx={{ position: "absolute", top: 5, right: 0 }}>
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box>
            </Box>

            <FlexBetween
              sx={{ width: "100%" }}
              style={{ marginTop: "0.75rem" }}
            >
              <FlexBetween gap="0.1rem">
                {eightCh ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 8 کاراکتر
                </Typography>
              </FlexBetween>

              <FlexBetween gap="0.1rem">
                {oneNum ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 1 عدد
                </Typography>
              </FlexBetween>

              <FlexBetween gap="0.1rem">
                {oneUpper ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 1 حرف بزرگ
                </Typography>
              </FlexBetween>
            </FlexBetween>
          </Stack>
          <FlexBetween sx={{ justifyContent: "flex-start" }} mt="0.5rem">
            <Checkbox sx={{ ml: "-0.5rem" }} />
            <FlexBetween gap="0.3rem">
              <Link>
                <Typography color="#F7F7F7">
                  <span
                    style={{ width: "40px", borderBottom: "1px solid white" }}
                  >
                    قوانین و مقررات
                  </span>
                </Typography>
              </Link>
              <Typography>را مطالعه کردم و با آن موافقم</Typography>
            </FlexBetween>
          </FlexBetween>
          <Button
            variant="contained"
            sx={{
              width: "400px",
              height: "48px",
              mt: "1rem",
              fontSize: "20px",
              borderRadius: "8px",
              boxShadow: "none",
            }}
            onClick={() => handleLoginClick()}
          >
            ثبت نام
          </Button>
          <FlexBetween
            sx={{ justifyContent: "flex-start" }}
            gap="0.5rem"
            mb="1.5rem"
          >
            <Typography color="#F7F7F7" mt="0.5rem">
              حساب کاربری دارید؟
            </Typography>
            <Link to="/login">
              <Typography color="#F7F7F7" mt="0.5rem">
                <span
                  style={{ width: "40px", borderBottom: "1px solid white" }}
                >
                  وارد شوید
                </span>
              </Typography>
            </Link>
          </FlexBetween>

          <GoogleLogin
            id="googleOne"
            onSuccess={(credentialResponse) => {
              console.log(credentialResponse);
              navigate("/");
            }}
            onError={() => {
              console.log("Login Failed");
            }}
          />
        </form>
      </Box>
    </CacheProvider>
  );
};

export default FormRegisterFarmer;
