import React, { useEffect, useState } from "react";

import {
  LightModeOutlined,
  DarkModeOutlined,
  Search,
  SettingsOutlined,
  MessageOutlined,
  NotificationsNoneOutlined,
  EditOutlined,
  LogoutOutlined,
  RecommendOutlined,
  AccessTimeOutlined,
  UpdateOutlined,
  DisplaySettingsOutlined,
  PeopleOutlineOutlined,
  RoomPreferencesOutlined,
  ListOutlined,
  AddOutlined,
  DarkMode,
  LightMode,
  Settings,
  Notifications,
  CloudUploadOutlined,
  CloudUpload,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import {
  setAlarmCount,
  setFarmName,
  setLogin,
  setLogout,
  setMode,
  setPropertyId,
} from "States";
import profileImage from "../../assets/StaffImg/9.jpg";
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";

import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../assets/arrow.png";

import { styled } from "@mui/material/styles";
import { setProperty } from "States/dashboard";
import { store } from "States/rootReducer";
import axios from "axios";
import { useFetchFarmData } from "hooks/useFetchData";
import { getFarmData } from "api/remoteSensing";

const StyledPolygon = styled("polygon")({
  fill: "none",
  stroke: "white",
  strokeWidth: "16px",
});
const points = "100,20 180,50 180,130 100,160 20,130 20,50";
// const points1 = "100,100 150,50 150,150 50,150";
// const points2 = "100,10 150,50 150,150 50,150";
// const points3 = "100,10 150,10 150,150 10,150";
// const points4 = "100,0 150,50 150,150 50,150";
const Navbar = () => {
  const [alarm, setAlarm] = useState(false);
  const [download, setDownload] = useState(false);
  const [setting, setSetting] = useState(false);
  const [ld, setLD] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { response: farms } = useFetchFarmData(getFarmData);
  const farmData = farms?.data;
  useEffect(() => {
    if (farmData) {
      const active = farmData.find((farm) => farm.active);
      dispatch(
        setProperty({
          property: farmData,
        })
      );
      active &&
        dispatch(
          setFarmName({
            farmName: active.title,
          })
        );
    }
  }, [farmData]);

  const fillteredFarm =
    farmData && farmData.length > 0
      ? farmData.filter((item) =>
          searchValue !== "" ? item.title.includes(searchValue) : item
        )
      : null;
  const proId = useSelector((state) => state.global.propertyId);
  const fName = useSelector((state) => state.global.farmName);
  const selectedFarm = farmData?.find((item) => item.id === proId);
  const isNonMobile = useMediaQuery("(min-width:1000px");
  const dispatch = useDispatch();
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [anchorEL, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEL);
  const navigate = useNavigate();
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const token = useSelector((state) => state.global.token);
  const alarmCount = useSelector((state) => state.global.alarmCount);
  const refreshToken = useSelector((state) => state.global.refreshToken);

  const getNewAccessToken = async (refreshToken) => {
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", "user-panel");
    formData.append("client_secret", "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV");
    formData.append("grant_type", "refresh_token");
    await fetch("https://api.rahbaan.ir/v1/authentication/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxBodyLength: Infinity,

      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        store.dispatch(
          setLogin({
            user: data,
            token: data.access_token,
            refreshToken: data.refresh_token,
          })
        );
      })
      .catch((error) => {
        store.dispatch(
          setLogin({
            user: null,
            token: null,
            refreshToken: null,
          })
        );
        navigate("/login");
      });
  };
  const getDataAlarms = async () => {
    try {
      const response = await fetch(
        "https://api.rahbaan.ir/v1/alarms/overview?searchStatus=ACTIVE",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = await response.json();

      store.dispatch(setAlarmCount({ alarmCount: data.length }));
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    setAnchorEl(null);
    setAnchorElAlarm(null);
    getDataAlarms();
  }, [alarmCount, token]);
  const handleClose = () => {
    setAnchorEl(null);
    // googleLogout();
  };

  const handleSignOut = () => {
    dispatch(setLogout());

    console.log("login", token);
    navigate("/login");
  };
  ///////////////////////
  const [anchorElAlarm, setAnchorElAlarm] = React.useState(null);
  const isOpenAlarm = Boolean(anchorElAlarm);
  const handleClickAlarm = (event) => {
    setAnchorElAlarm(event.currentTarget);
  };
  const handleCloseAlarm = () => {
    setAnchorElAlarm(null);
    // navigate("/alarms");
  };

  /////////////////////////

  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const isOpenUpdate = Boolean(anchorElUpdate);
  const handleClickUpdate = (event) => {
    setAnchorElUpdate(event.currentTarget);
  };
  const handleCloseUpdate = () => {
    setAnchorElUpdate(null);
  };
  ////////////////////////////////////

  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const isOpenSetting = Boolean(anchorElSetting);
  const handleClickSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };
  ///////////////////////////////////////
  const [anchorElDrop, setAnchorElDrop] = React.useState(null);
  const [toggleDrop, setToggleDrop] = useState(false);
  // const [farmName, setFarmName] = useState();
  const isOpenDrop = Boolean(anchorElDrop);
  const handleClickDrop = (event) => {
    setToggleDrop(!toggleDrop);
    if (toggleDrop) setAnchorElDrop(null);
    else setAnchorElDrop(event.currentTarget);
  };
  const handleCloseDrop = () => {
    setAnchorElDrop(null);
    setToggleDrop(false);
  };

  const setFarm = async (id, title) => {
    getNewAccessToken(refreshToken);

    axios
      .put(`https://api.rahbaan.ir/v1/properties/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        dispatch(setPropertyId({ propertyId: id }));
        setFarmName(title);
        getNewAccessToken(refreshToken);
        setAnchorElDrop(null);
        setToggleDrop(false);
      });
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: theme.palette.background.second,
        boxShadow: "none",
      }}
    >
      <Box width="100%">
        <Toolbar
          sx={{
            width: "100%",

            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h3"
              ml={1}
              display={isNonMobile ? undefined : "none"}
              color={main}
            >
              سامانه هوشمند مدیریت رهبان
            </Typography>
            <div style={{ cursor: "pointer" }} onClick={handleClickDrop}>
              <Typography
                variant="body"
                color={alt}
                display={isNonMobile ? undefined : "none"}
              >
                <Typography variant="body" color={alt}>
                  {selectedFarm ? selectedFarm.title : fName}
                </Typography>
              </Typography>
              <IconButton>
                {toggleDrop ? (
                  <Box
                    component={"img"}
                    src={ArrowIcon}
                    sx={{
                      width: "14px",
                      height: "10px",
                      // rotate: "180deg",
                    }}
                  />
                ) : (
                  <Box
                    component={"img"}
                    src={ArrowIcon}
                    sx={{
                      width: "14px",
                      height: "10px",
                      rotate: "180deg",
                    }}
                  />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorElDrop}
                open={isOpenDrop}
                onClose={handleCloseDrop}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                sx={{
                  mt: "15px",
                  "& .MuiList-root": {
                    color: "white",
                    backgroundColor: theme.palette.background.third,
                  },
                }}
              >
                <Box
                  sx={{
                    width: "246px",
                    height: "284px",
                    p: "0.25rem 0.75rem",
                  }}
                >
                  <FlexBetween>
                    <FlexBetween
                      sx={{
                        transition: "all 0.2s ease",
                        width: "70%",

                        alignItems: "center",
                        border: `1px solid ${theme.palette.fontColor.alt}`,
                        // border: "1px solid black",
                        borderRadius: "8px",
                        mt: "0.25rem",
                      }}
                    >
                      <InputBase
                        disableUnderline
                        placeholder="جست و جو"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        id="inputSearchRemote"
                        sx={{
                          paddingRight: "5px",
                          backgroundColor: "inherit",
                          "& .MuiInputBase-input": {
                            "::placeholder": {
                              color: `${theme.palette.fontColor.main} !important`,
                            },
                          },
                          height: "27px",
                          width: "80px",
                          borderRadius: "8px",
                          color: theme.palette.fontColor.alt,
                          outline: "none",
                          borderBottom: "none",
                        }}
                      />
                      <Search sx={{ ml: "0.2rem" }} />
                    </FlexBetween>
                    <ListOutlined />
                  </FlexBetween>
                  <Box
                    sx={{
                      width: "100%",
                      height: "70%",
                      overflowY: "auto",
                      mt: "1rem",
                      "&::-webkit-scrollbar": {
                        width: 5,
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                        borderRadius: "5px",
                      },
                      "&::-webkit-scrollbar-button": {
                        height: "1px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#5A596F",
                        outline: `0px solid slategrey`,
                        borderRadius: "5px",
                      },
                    }}
                  >
                    {fillteredFarm?.map((item) => (
                      <FlexBetween
                        sx={{
                          justifyContent: "flex-start",
                          gap: "0.5rem",
                          mb: "0.5rem",
                          cursor: "pointer",
                          p: "0.25rem 0.5rem",
                          borderRadius: "8px",
                          "&:hover": {
                            backgroundColor: theme.palette.background.second,
                          },
                        }}
                        onClick={() => setFarm(item.id, item.title)}
                      >
                        <Box width="16px" height="16px">
                          <svg viewBox="0 0 200 200">
                            <StyledPolygon points={points} />
                          </svg>
                        </Box>
                        <Typography
                          fontSize={theme.typography.body}
                          color={theme.palette.fontColor.main}
                        ></Typography>
                        <Typography
                          fontSize={theme.typography.smallestTextSecondary}
                          color={theme.palette.fontColor.alt}
                        >
                          {item.title}
                        </Typography>
                      </FlexBetween>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      mt: "0.25rem",
                      width: "215px",
                      height: "32px",
                      backgroundColor: theme.palette.others.first,
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FlexBetween>
                      <AddOutlined
                        color="white"
                        sx={{ width: "30px", height: "30px" }}
                      />
                      <Typography
                        fontSize={theme.typography.smallestTextPrimary}
                        color={theme.palette.fontColor.main}
                      >
                        زمین جدید
                      </Typography>
                    </FlexBetween>
                  </Box>
                </Box>
              </Menu>
            </div>
          </Box>
          {/* // */}
          <Box sx={{ display: "flex" }}>
            <IconButton
              onClick={() => {
                dispatch(setMode());
                navigate("/");
              }}
              onMouseEnter={() => setLD(true)}
              onMouseLeave={() => setLD(false)}
              sx={{
                "&:hover": {
                  bgcolor: theme.palette.others.first,
                },
                width: "50px",
              }}
            >
              {ld ? (
                theme.palette.mode === "dark" ? (
                  <DarkMode sx={{ fontSize: "25px" }} />
                ) : (
                  <LightMode sx={{ fontSize: "25px" }} />
                )
              ) : theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{ fontSize: "25px" }} />
              ) : (
                <LightModeOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <IconButton
              onClick={handleClickSetting}
              sx={{
                "&:hover": {
                  bgcolor: theme.palette.others.first,
                },
                width: "50px",
              }}
              onMouseEnter={() => setSetting(true)}
              onMouseLeave={() => setSetting(false)}
            >
              {setting ? (
                <Settings sx={{ fontSize: "25px" }} />
              ) : (
                <SettingsOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorElSetting}
              open={isOpenSetting}
              onClose={handleCloseSetting}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              sx={{
                zIndex: 3000,
                "& .MuiList-root": {
                  color: "white",
                  backgroundColor: theme.palette.background.third,
                },
              }}
            >
              <FlexBetween sx={{ justifyContent: "flex-start" }}>
                <DisplaySettingsOutlined
                  sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                />
                <MenuItem onClick={handleClose}>تنظیمات سامانه</MenuItem>
              </FlexBetween>

              <FlexBetween sx={{ justifyContent: "flex-start" }}>
                <PeopleOutlineOutlined
                  sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                />
                <MenuItem onClick={handleClose}>تنظیمات کاربران</MenuItem>
              </FlexBetween>

              <FlexBetween sx={{ justifyContent: "flex-start" }}>
                <RoomPreferencesOutlined
                  sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                />
                <MenuItem onClick={handleClose}>تنظیمات تجهیزات</MenuItem>
              </FlexBetween>
            </Menu>

            <IconButton
              onClick={handleClickUpdate}
              sx={{
                "&:hover": {
                  bgcolor: theme.palette.others.first,
                },
                width: "50px",
              }}
              onMouseEnter={() => setDownload(true)}
              onMouseLeave={() => setDownload(false)}
            >
              {download ? (
                <CloudUpload sx={{ fontSize: "25px" }} />
              ) : (
                <CloudUploadOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorElUpdate}
              open={isOpenUpdate}
              onClose={handleCloseUpdate}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              sx={{
                zIndex: 3000,
                "& .MuiList-root": {
                  color: "white",
                  backgroundColor: theme.palette.background.third,
                },
              }}
            >
              <Box
                flexDirection="column"
                justifyContent="flex-start"
                p="0.5rem 1rem 0.5rem 1rem"
              >
                <Typography variant="body" color={theme.palette.fontColor.main}>
                  بروز رسانی سنسورها
                </Typography>
                <FlexBetween>
                  <AccessTimeOutlined
                    sx={{
                      width: "13px",
                      height: "13px",
                      color: theme.palette.fontColor.alt,
                    }}
                  />
                  <Typography
                    variant="smallestTextPrimary"
                    color={theme.palette.fontColor.alt}
                  >
                    سه شنبه 1401/10/06 - 20:08
                  </Typography>
                </FlexBetween>
                <IconButton
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    width: "172px",
                    height: "1.75rem",
                    backgroundColor: theme.palette.others.third,
                    mt: "0.5rem",
                    borderRadius: "8px",
                  }}
                >
                  <UpdateOutlined
                    sx={{ width: "13px", height: "13px", ml: "0.25rem" }}
                  />
                  <Typography
                    variant="smallestTextPrimary"
                    color={theme.palette.fontColor.main}
                  >
                    شروع بروز رسانی
                  </Typography>
                </IconButton>
              </Box>
            </Menu>

            <IconButton
              onClick={handleClickAlarm}
              sx={{
                "&:hover": {
                  bgcolor: theme.palette.others.first,
                },
                width: "50px",
              }}
              onMouseEnter={() => setAlarm(true)}
              onMouseLeave={() => setAlarm(false)}
            >
              {alarm ? (
                <Notifications sx={{ fontSize: "25px" }} />
              ) : (
                <NotificationsNoneOutlined sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorElAlarm}
              open={isOpenAlarm}
              onClose={handleCloseAlarm}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              sx={{
                zIndex: 3000,
                "& .MuiList-root": {
                  color: "white",
                  backgroundColor: theme.palette.background.third,
                },
              }}
            >
              <FlexBetween>
                <FlexBetween sx={{ justifyContent: "flex-start" }}>
                  <EditOutlined
                    sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                  />
                  <MenuItem
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                      navigate("/alarms");
                      setAnchorElAlarm(null);
                    }}
                  >
                    هشدار ها
                  </MenuItem>
                </FlexBetween>
                <Box
                  width="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.second,
                    ml: "1rem",
                    borderRadius: "6px",
                  }}
                >
                  {alarmCount}
                </Box>
              </FlexBetween>
              <FlexBetween>
                <FlexBetween sx={{ justifyContent: "flex-start" }}>
                  <MessageOutlined
                    sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                  />
                  {/* <MenuItem onClick={handleCloseAlarm} sx={{ ml: "0.5rem" }}> */}
                  <MenuItem sx={{ ml: "0.5rem" }}>پیام ها</MenuItem>
                </FlexBetween>
                <Box
                  width="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.second,
                    ml: "1rem",
                    borderRadius: "6px",
                  }}
                >
                  5
                </Box>
              </FlexBetween>
              <FlexBetween>
                <FlexBetween sx={{ justifyContent: "flex-start" }}>
                  <RecommendOutlined
                    sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                  />
                  <MenuItem sx={{ ml: "0.5rem" }}>توصیه ها</MenuItem>
                </FlexBetween>
                <Box
                  width="24px"
                  height="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.second,
                    ml: "1rem",
                    borderRadius: "6px",
                  }}
                >
                  2
                </Box>
              </FlexBetween>
            </Menu>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={handleClick}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                  width: "50px",
                  // "&:hover": {
                  //   bgcolor: theme.palette.others.first,
                  // },
                }}
              >
                <Box
                  component={"img"}
                  alt="profile"
                  src={profileImage}
                  height="32px"
                  width="32px"
                  borderRadius={"50%"}
                  sx={{ objectFit: "cover" }}
                />
              </IconButton>

              <Menu
                anchorEl={anchorEL}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                sx={{
                  zIndex: 3000,

                  "& .MuiList-root": {
                    color: "white",
                    backgroundColor: theme.palette.background.third,
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  p="10px 20px"
                  mb="15px"
                >
                  <Box
                    component="img"
                    src={profileImage}
                    width="48px"
                    height="48px"
                    borderRadius="50%"
                    mb="0.2rem"
                  />
                  <Typography
                    variant="subHeading"
                    color={theme.palette.fontColor.main}
                  >
                    صادق توسلی زاده
                  </Typography>
                  <Typography
                    variant="smallestTextSecondary"
                    color={theme.palette.fontColor.alt}
                  >
                    Dr.Tavasolizadeh@gmail.com
                  </Typography>
                </Box>

                <FlexBetween sx={{ justifyContent: "flex-start" }}>
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      gap: "1rem",
                    }}
                  >
                    <EditOutlined
                      sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                    />
                    <Typography>ویرایش پروفایل</Typography>
                  </MenuItem>
                </FlexBetween>

                <FlexBetween sx={{ justifyContent: "flex-start" }}>
                  <MenuItem
                    onClick={handleSignOut}
                    display="flex"
                    sx={{ width: "100%", gap: "1rem" }}
                  >
                    <LogoutOutlined
                      sx={{ width: "24px", height: "24px", mr: "0.5rem" }}
                    />
                    <Typography>خروج</Typography>
                  </MenuItem>
                </FlexBetween>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default Navbar;
