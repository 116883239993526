import { Box, Fade, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Alarms from "widgets/Alarms";
import CopyRight from "widgets/CopyRight";
import CultivationStatus from "widgets/CultivationStatus";
import EquipmentControl from "widgets/EquipmentControl";
import GrowthStage from "widgets/GrowthStage";
import Map from "widgets/Map";
import Recommends from "widgets/Recommends";
import SensorsM from "widgets/SensorsM";
import SmartIndex from "widgets/SmartIndex";
import StaffMgmt from "widgets/StaffMgmt";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import WidgetWrapper from "components/WidgetWrapper";
import Weather from "widgets/Weather";
import DailyWeather from "widgets/DailyWeather";
import Fahl from "widgets/Fahl";
import HealthStatus from "widgets/HealthStatus";

const Dashboard = () => {
  const index = useSelector((state) => state.global.sensorIndex);
  console.log("🚀 ~ file: Test.jsx:18 ~ Test ~ index:", index);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="89vh">
        <Box
          width="27%"
          height="99%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <Alarms />
          <WidgetWrapper sx={{ width: "100%", flex: 1.2 }}>
            <Weather />
          </WidgetWrapper>
          <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
            <EquipmentControl />
          </WidgetWrapper>
          <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
            <DailyWeather />
          </WidgetWrapper>
        </Box>
        <Box
          width="72%"
          height="99%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <SpeedomotorsData />
          <Box width="100%" height="98%" display="flex" gap="16px">
            <Box
              flex={1.55}
              width="60%"
              height="100%"
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
                <Fahl />
              </WidgetWrapper>
              <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
                <HealthStatus />
              </WidgetWrapper>
            </Box>
            <Box
              flex={1}
              height="100%"
              width="35%"
              display="flex"
              flexDirection="column"
              gap="16px"
            >
              <WidgetWrapper sx={{ width: "100%", flex: 1.2 }}>
                <SmartIndex />
              </WidgetWrapper>
              <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
                <Recommends />
              </WidgetWrapper>
              <WidgetWrapper sx={{ width: "100%", flex: 1 }}>
                <StaffMgmt />
              </WidgetWrapper>
            </Box>
          </Box>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default Dashboard;
