import FullCalendar from "@fullcalendar/react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { formatDate } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FlexBetween from "components/FlexBetween/FlexBetween";
import userPic from "../../assets/StaffImg/1.jpg";
import { useEffect } from "react";
const StaffCalendar = ({ initialDataCalendar }) => {
  const [currentEvents, setCurrentEvents] = useState([]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: StaffCalendar.jsx:29 ~ StaffCalendar ~ initialDataCalendar:",
      initialDataCalendar
    );
  }, [initialDataCalendar]);
  const [open, setOpen] = React.useState(false);
  console.log("🚀 ~ file: StaffCalendar.jsx:30 ~ StaffCalendar ~ open:", open);
  const [cellSelected, setCellSelected] = useState(null);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [pri, setPri] = useState("");
  const [openDeleteEvent, setOpenDeleteEvent] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDeleteEvent(false);
  };
  const handleRecord = () => {
    const calendarApi = cellSelected.view.calendar;
    calendarApi.unselect();

    if (pri === "بالا") {
      if (title) {
        calendarApi.addEvent({
          id: `${cellSelected.dateStr} - ${title}`,
          title: `${title} - ${name}`,
          backgroundColor: "red",

          start: cellSelected.startStr,
          end: cellSelected.endStr,
          allDay: cellSelected.allDay,
        });
      }
    } else if (pri === "متوسط") {
      if (title) {
        calendarApi.addEvent({
          id: `${cellSelected.dateStr} - ${title}`,
          title: `${title} - ${name}`,
          backgroundColor: "blue",

          start: cellSelected.startStr,
          end: cellSelected.endStr,
          allDay: cellSelected.allDay,
        });
      }
    } else {
      if (title) {
        calendarApi.addEvent({
          id: `${cellSelected.dateStr} - ${title}`,
          title: `${title} - ${name}`,
          backgroundColor: "green",

          start: cellSelected.startStr,
          end: cellSelected.endStr,
          allDay: cellSelected.allDay,
        });
      }
    }
    setTitle("");
    setName("");
    setPri("");
    setOpen(false);
  };
  const handleDateClick = (selected) => {
    handleClickOpen();
    setCellSelected(selected);
  };
  const handleDelete = () => {
    cellSelected.event.remove();
    setOpenDeleteEvent(false);
  };
  const handleEventClick = (selected) => {
    setCellSelected(selected);
    setOpenDeleteEvent(true);
  };
  const eventContent = ({ event }) => {
    console.log(
      "🚀 ~ file: StaffCalendar.jsx:136 ~ eventContent ~ event:",
      event.title
    );

    const arr = event.title.split("/");
    console.log("🚀 ~ file: StaffCalendar.jsx:112 ~ eventContent ~ arr:", arr);

    // Modify the HTML and apply CSS for each event
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        p="10px"
      >
        <FlexBetween>
          <Box
            width="8px"
            height="8px"
            borderRadius="99px"
            sx={{
              backgroundColor:
                arr[2] === "خیلی مهم" ? "others.first" : "others.third",
            }}
          />
          <Stack direction="row" alignItems="center" gap="0.5rem">
            {/* <Typography
              variant="smallestTextSecondary"
              fontSize="8px"
              color="fontColor.main"
              sx={{
                width: "18ch",
                textOverflow: "ellipsis",
                whiteSpace: "initial",
                textAlign: "end",
              }}
            >
              {`${arr[1]} - ${arr[0]}`}
            </Typography> */}
            <Stack direction="column" alignItems="flex-end">
              <Typography
                variant="smallestTextSecondary"
                fontSize="8px"
                color="fontColor.main"
                sx={{
                  width: "18ch",
                  direction: "rtl",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  textAlign: "start",
                  overflow: "hidden",
                }}
              >
                {`${arr[1]}`}
              </Typography>
              <Typography
                variant="smallestTextSecondary"
                fontSize="8px"
                color="fontColor.main"
                sx={{
                  width: "18ch",
                  textOverflow: "ellipsis",
                  whiteSpace: "initial",
                  textAlign: "end",
                }}
              >
                {` ${arr[0]}`}
              </Typography>
            </Stack>

            <Box
              component="img"
              src={userPic}
              borderRadius="99px"
              width="1rem"
              height="1rem"
            />
          </Stack>
        </FlexBetween>
        <Typography>{event.description}</Typography>
      </Box>
    );
  };
  return (
    <Box
      flex="1 1 100%"
      mt="0.5rem"
      height="95%"
      sx={{
        "& .fc-daygrid-day": {
          backgroundColor: "transparent",
        },
      }}
    >
      <FullCalendar
        direction="ltr"
        locale="fa"
        height="100%"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        // headerToolbar={{
        //   left: "prev,next today",
        //   center: "title",
        //   right: "dayGridMonth,timeGridWeek,timeGridDay",
        // }}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        initialView="timeGrid"
        visibleRange={{
          start: "2023-07-11",
          end: "2023-07-18",
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        // select={handleDateClick}
        eventClick={handleEventClick}
        eventsSet={(events) => setCurrentEvents(events)}
        scrollTime={true}
        // nowIndicator={true}
        eventBorderColor="#453"
        // eventColor="blue"
        // eventBackgroundColor="#38385C"
        initialEvents={initialDataCalendar}
        eventContent={eventContent}
      />

      <Dialog open={openDeleteEvent} onClose={handleClose}>
        <DialogTitle>حذف وظیفه</DialogTitle>
        <DialogContent>
          <DialogContentText>
            آیا از حذف وظیفه انتخاب شده اطمینان دارید ؟
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant={"contained"}>
            لغو
          </Button>
          <Button onClick={handleDelete} variant={"contained"}>
            حذف وظیفه
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StaffCalendar;
