import { AddOutlined, MoreHoriz, Search } from "@mui/icons-material";
import {
  useTheme,
  Box,
  Divider,
  FormControlLabel,
  InputBase,
  Radio,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";

import FamilyTreePic from "../../assets/familytree.png";

const FamilyTree = ({ selectedTitle }) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [addItem, setAddItem] = useState(false);

  return (
    <WidgetWrapper
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box width="100%" dispaly="flex" flex={1}>
        <Box component="img" src={FamilyTreePic} width="100%" height="100%" />
      </Box>
    </WidgetWrapper>
  );
};

export default FamilyTree;
