import { useMediaQuery } from "@mui/material";
import { MOBILE_WIDTH } from "constants/constant";
import Ticket from "./Ticket";
import TicketMob from "mob/TicketMob/TicketMob";

const TicketIndex = () => {
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);

  return <>{isMobileScreens ? <TicketMob /> : <Ticket />}</>;
};

export default TicketIndex;
