import { Box, Divider, Tab, Tabs, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import moment from "jalali-moment";
import W1 from "../../../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../../../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../../../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../../../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../../../assets/WeatherIcons/static/day.svg";
import W7 from "../../../assets/WeatherIcons/static/rainy-7.svg";
import W8 from "../../../assets/WeatherIcons/static/night.svg";
import W9 from "../../../assets/WeatherIcons/static/snowy-5.svg";
import W10 from "../../../assets/WeatherIcons/static/cloudy-day-3.svg";
import { useSelector } from "react-redux";
import { getDayName, getMonthDay } from "Utils/Utils";
import { useRef } from "react";
import { useEffect } from "react";

const getIcon = (condition) => {
  switch (condition) {
    case "CLEAR":
      return W1;
    case "ATMOSPHERE":
      return W2;
    case "CLOUDS":
      return W3;
    case "DRIZZLE":
      return W4;
    case "RAIN":
      return W5;
    case "SNOW":
      return W6;
    case "THUNDERSTORM":
      return W7;

    default:
      break;
  }
};
const WeatherWidgetMob = () => {
  const weatherData = useSelector((state) => state.dashboard.weather);

  console.log(
    "🚀 ~ file: Weather.jsx:70 ~ Weather ~ weatherData:",
    weatherData.days
  );

  const today = Date.now();
  console.log("🚀 ~ file: Weather.jsx:64 ~ Weather ~ today:", today);

  const todayFa = {
    day: getDateFormat(today, { day: "2-digit" }),
    month: getDateFormat(today, { month: "numeric" }),
    monthTitle: getDateFormat(today, { month: "long" }),
    year: getDateFormat(today, { year: "numeric" }),
    dayWeek: getDateFormat(today, { weekday: "long" }),
  };

  function getDateFormat(uDate, option) {
    let date = new Intl.DateTimeFormat("fa-IR", option).format(uDate);
    return date;
  }

  console.log("shamsi:", todayFa);

  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [value, setValue] = React.useState(10);
  console.log("🚀 ~ file: Weather.jsx:66 ~ Weather ~ value:", value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <WidgetWrapper
      height="62px"
      opc={weatherData.enabled ? 1 : 0.1}
      sx={{ padding: "0rem 0 0rem 0" }}
    >
      <Box
        width="100%"
        height="62px"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          pr: "0rem",
          overflowX: "scroll",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[0].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[0].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[0].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[0].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[0].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>

        <Box>
          <Divider orientation="vertical" flexItem sx={{ height: "50px" }} />
        </Box>

        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[1].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[1].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[1].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[1].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[1].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>

        <Box>
          <Divider orientation="vertical" flexItem sx={{ height: "50px" }} />
        </Box>

        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[2].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[2].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[2].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[2].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[2].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>

        <Box>
          <Divider orientation="vertical" flexItem sx={{ height: "50px" }} />
        </Box>

        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[3].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[3].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[3].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[3].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[3].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>

        <Box>
          <Divider orientation="vertical" flexItem sx={{ height: "50px" }} />
        </Box>

        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[4].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[4].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[4].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[4].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[4].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>

        <Box>
          <Divider orientation="vertical" flexItem sx={{ height: "50px" }} />
        </Box>

        <Box display="flex" width="151px" m="0 25px">
          <FlexBetween
            sx={{
              flexDirection: "column",
              //   mr: "20px",
              alignItems: "flex-start",
            }}
          >
            <Typography
              fontSize={theme.typography.smallestTextPrimary}
              color={theme.palette.fontColor.main}
            >
              {getDayName(weatherData.days[5].epochDateTime)}
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={theme.palette.fontColor.alt}
            >
              {getMonthDay(weatherData.days[5].dateTime)}
            </Typography>
          </FlexBetween>
          <Box
            component={"img"}
            src={getIcon(weatherData.days[5].values.condition)}
            sx={{ width: "32px", height: "32px" }}
          />
          <FlexBetween>
            <Typography variant="body" color={theme.palette.fontColor.alt}>
              {Math.round(weatherData.days[5].values.maxTemperature)}&deg;
            </Typography>
            <Typography
              variant="body"
              color={theme.palette.fontColor.alt}
              p="0 3px"
            >
              |
            </Typography>
            <Typography
              fontSize={theme.typography.body}
              color={theme.palette.fontColor.main}
            >
              {Math.round(weatherData.days[5].values.minTemperature)}&deg;
            </Typography>
          </FlexBetween>
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default WeatherWidgetMob;
