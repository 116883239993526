import {  Box, Stack, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMember } from "States/messenger";
const ContactItemMob = ({ item, handleClick }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;

  const selectedMember = useSelector((state) => state.messenger.selectedMember);
  const dispatch = useDispatch();

  return (
    <Box
      width="98%"
      height="70px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderRadius="4px"
      sx={{
        mb: "0.2rem",
        p: "0 0.5rem",
        cursor: "pointer",
        backgroundColor:
          item.id === selectedMember?.id
            ? theme.palette.background.third
            : "transparent",
        transition: "background-color 0.3s",
        ":hover": {
          backgroundColor: theme.palette.background.third,
        },
        "-webkit-tap-highlight-color": "transparent",
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        userSelect: "none",
      }}
      onClick={() => {
        handleClick();
        dispatch(
          setSelectedMember({
            selectedMember: item,
          })
        );
      }}
    >
      <FlexBetween sx={{ width: "100%", alignItems: "flex-start" }}>
        <FlexBetween>
          <Box
            component="img"
            src={item.imageUrl}
            sx={{ width: "50px", height: "50px", borderRadius: "50%" }}
          />
          <Stack direction="column" mr="0.5rem" gap="0.3rem">
            <Typography variant="h4" color={main}>
              {item.type === "system" ? (
                <>
                  <Stack direction="row" alignItems="center" gap="0.5rem">
                    <svg
                      width="13"
                      height="11"
                      viewBox="0 0 13 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.7092 0.0614982C0.947379 -0.141653 0.2 0.432543 0.2 1.22098V9.46975C0.2 10.2888 1.00243 10.8672 1.77947 10.6082L5.58331 9.34022C5.96541 10.3007 6.9031 10.9797 8 10.9797C9.43594 10.9797 10.6 9.81564 10.6 8.3797V7.66799L12.1795 7.1415C12.6695 6.97817 13 6.5196 13 6.00308V3.99431C13 3.45065 12.6345 2.97491 12.1092 2.83483L1.7092 0.0614982ZM9.8 7.93466V8.3797C9.8 9.37381 8.99411 10.1797 8 10.1797C7.25707 10.1797 6.61874 9.72933 6.34401 9.08666L9.8 7.93466Z"
                        fill="white"
                      />
                    </svg>
                    <Typography variant="h4" color={main}>
                      {item.name}
                    </Typography>
                  </Stack>
                </>
              ) : (
                item.name
              )}
            </Typography>
            <Typography variant="subHeading" color={alt} whiteSpace={"nowrap"}>
              {item.type === "system" ? (
                <>
                  <FlexBetween gap="0.5rem">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.39961 8.49998C2.39961 5.40718 4.90682 2.89998 7.99961 2.89998C11.0924 2.89998 13.5996 5.40718 13.5996 8.49998V9.29998H10.7996C10.5787 9.29998 10.3996 9.47906 10.3996 9.69998V14.5C10.3996 14.7209 10.5787 14.9 10.7996 14.9H12.7996C13.6833 14.9 14.3996 14.1836 14.3996 13.3V8.49998C14.3996 4.96535 11.5342 2.09998 7.99961 2.09998C4.46499 2.09998 1.59961 4.96535 1.59961 8.49998V13.3C1.59961 14.1836 2.31595 14.9 3.19961 14.9H5.19961C5.42052 14.9 5.59961 14.7209 5.59961 14.5V9.69998C5.59961 9.47906 5.42052 9.29998 5.19961 9.29998H2.39961V8.49998Z"
                        fill="#2A80F4"
                      />
                    </svg>
                    <Typography
                      variant="subHeading"
                      color={theme.palette.fontColor.alt}
                    >
                      راهنمای جامع
                    </Typography>
                  </FlexBetween>
                </>
              ) : (
                item.messages[item.messages.length - 1].text
              )}
            </Typography>
          </Stack>
        </FlexBetween>
        <Typography variant="subHeading" color={alt} sx={{ direction: "ltr" }}>
          {item.lastTime}
        </Typography>
      </FlexBetween>
    </Box>
  );
};

export default ContactItemMob;
