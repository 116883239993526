import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FahlData from "Mock/FahlData";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import CenterStack from "components/styledComponents/CenterStack";
import HStack from "components/styledComponents/HStack";
import React, { useState } from "react";
import ArrowIcon from "../../../assets/arrow.png";
import PointerIcon from "../../../assets/pointer.png";
import HealthStatusData from "Mock/HealthStatusData";
import AlarmPNG from "../../../assets/AlarmDark.png";
const HealthStatusMob = () => {
  const theme = useTheme();

  const alt = theme.palette.fontColor.alt;
  const initialToggleStates = new Array(FahlData.length).fill(false);
  const [toggleStates, setToggleStates] = useState(initialToggleStates);
  const toggleItem = (index) => {
    const newToggleStates = [...toggleStates];
    newToggleStates[index] = !newToggleStates[index];
    setToggleStates(newToggleStates);
  };
  const colors = ["#C3FF79", "#72EB13", "#72EB13", "#FFEE00", "#FC4949"];
  const boxWidth = 107;
  const colorWidth = boxWidth / colors.length;

  const gradientBackground = `linear-gradient(to right, ${colors
    .map(
      (color, index) =>
        `${color} ${colorWidth * index}px, ${color} ${
          colorWidth * (index + 1)
        }px`
    )
    .join(", ")})`;
  return (
    <WidgetWrapper mt="16px">
      <Typography variant="h4" color="fontColor.main" mb="16px">
        وضعیت سلامت
      </Typography>
      <HStack sx={{ gap: "16px" }}>
        <AnimatedProgressBar
          number={(850 / 1750) * 100}
          width={120}
          height={140}
          title="850"
          subTitle="1750"
          color={theme.palette.others.first}
          dot={false}
          secondColor={theme.palette.background.forth}
        />
        <Typography variant="smallestTextPrimary" color="fontColor.alt">
          دام های سالم
        </Typography>
      </HStack>
      {HealthStatusData.map((item, index) => (
        <React.Fragment key={index}>
          <Box height="53px">
            <FlexBetween>
              <Typography variant="h5" color="fontColor.main">
                دام {item.code}
              </Typography>
              <Typography variant="h5" color="fontColor.alt">
                {item.status}
              </Typography>
            </FlexBetween>
            <FlexBetween sx={{ mt: "0.5rem" }}>
              {item.l ? (
                <Stack direction="row" alignItems="center" gap="8px">
                  <Box
                    component="img"
                    src={AlarmPNG}
                    width="16px"
                    height="16px"
                  />
                  <Typography variant="subHeading" color="fontColor.alt">
                    لنگش دارد
                  </Typography>
                </Stack>
              ) : (
                <Typography variant="subHeading" color="fontColor.alt">
                  لنگش ندارد
                </Typography>
              )}

              <CenterStack
                width="39px"
                height="23px"
                borderRadius="4px"
                sx={{
                  backgroundColor:
                    item.index < 2
                      ? "others.second"
                      : item.index >= 3
                      ? "others.forth"
                      : "others.third",
                }}
              >
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.background.first}
                >
                  {item.index}
                </Typography>
              </CenterStack>
            </FlexBetween>
          </Box>
          <Collapse in={toggleStates[index]} timeout={500}>
            <FlexBetween sx={{ mt: "0.5rem" }}>
              <Typography variant="subHeading" color="fontColor.alt">
                بهترین زمان تلقیح
              </Typography>
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                {item.time} - {item.date}
              </Typography>
            </FlexBetween>
          </Collapse>

          {index !== FahlData.length - 1 && (
            <Divider variant="fullWidth" sx={{ m: "12px 0" }} />
          )}
        </React.Fragment>
      ))}
      <CenterStack sx={{ m: "1.5rem 0", cursor: "pointer" }}>
        <Typography
          variant="h5"
          color={alt}
          sx={{ borderBottom: `1px solid ${alt}` }}
        >
          نمایش بیشتر
        </Typography>
      </CenterStack>
    </WidgetWrapper>
  );
};

export default HealthStatusMob;
