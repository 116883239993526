import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
import globalReducer from 'States';
import dashboardReducer from 'States/dashboard';
import sensorsReducer from 'States/sensors';
import { Provider } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './States/rootReducer';
// import {api} from 'States/api';

setupListeners(store.dispatch);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

    <App />
    </PersistGate>
    </Provider>
  </React.StrictMode>
);

