import {
  AddOutlined,
  ArrowUpwardOutlined,
  FilterAltOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import styled from "styled-components";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import RecommendsTable from "./RecommendsTable";
import RecommendMap from "./RecommendsMap";
import CopyRight from "widgets/CopyRight";
import ArrowIcon from "../../assets/arrow.png";
import TaskIcon from "../../assets/task.png";
import MachineIcon from "../../assets/machine.png";
import WaterIcon from "../../assets/waterRec.png";
import PoisonIcon from "../../assets/poisonRec.png";
import SoilIcon from "../../assets/soilRec.png";
import Table from "components/table/Table";
import W1 from "../../assets/WeatherIcons/static/cloudy-day-3.svg";
import { recommendData } from "Mock/RecommendsData";

const RecommendsPage = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [searchValue, setSearchValue] = useState("");

  const [selectValue, setSelectValue] = useState(0);

  const temp = recommendData.filter((item) =>
    searchValue !== "" ? item.name.includes(searchValue) : item
  );

  const tableData = temp?.map((row) => {
    return {
      data: {
        "توصیه ها": (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="1rem"
          >
            <Box
              width="32px"
              height="32px"
              component="img"
              src={
                row.type === "w"
                  ? WaterIcon
                  : row.type === "p"
                  ? PoisonIcon
                  : SoilIcon
              }
            />
            <Typography
              color={main}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.name}
            </Typography>
          </Box>
        ),
        بهاربند: (
          <Typography
            color={main}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {row.area}
          </Typography>
        ),
        "تاریخ ارسال توصیه": (
          <Box
            width="90%"
            height="23px"
            borderRadius="8px"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              direction: "ltr",
            }}
          >
            <Typography
              color={main}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.date}
            </Typography>
          </Box>
        ),
        "انجام توسط":
          row.res === "machine" ? (
            <IconButton>
              <Box component="img" src={MachineIcon} />
            </IconButton>
          ) : row.res === "" ? (
            <IconButton>
              <Box
                width="24px"
                height="24px"
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ border: `1px solid ${main}` }}
              >
                <AddOutlined />
              </Box>
            </IconButton>
          ) : null,
        وضعیت: (
          <Box
            sx={{
              width: "77px",
              height: "23px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                row.status === "انجام شده"
                  ? theme.palette.others.third
                  : row.status === "درحال انجام"
                  ? theme.palette.others.forth
                  : theme.palette.others.first,
            }}
          >
            <Typography
              color={"#000"}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.status}
            </Typography>
          </Box>
        ),
        "تبدیل به تسک": (
          <IconButton>
            <Box component="img" src={TaskIcon} />
          </IconButton>
        ),
      },
      expanded: {
        "توصیه کننده": row.recommender,
        توضیحات: row.desc,
        "تاریخ انجام توصیه": row.dueDate,
      },
    };
  });

  const StyledMenuList = styled(MenuList)(({ theme }) => ({
    /* Use the prop `theme` to apply dynamic styles */
    backgroundColor: theme.palette.background.third,
    boxShadow: "none !important",
  }));
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box
        width="100%"
        height="89vh"
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <Box
          width="66.5%"
          height="99%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
        >
          <Box width="100%" height="62px">
            <SpeedomotorsData />
          </Box>
          <WidgetWrapper width="100%" height="90%">
            <FlexBetween mb={"1rem"}>
              <Box display="flex" alignItems="center" gap="1.5rem">
                <Typography color={main} variant="h3">
                  توصیه ها
                </Typography>
                <Box
                  width="136px"
                  height="32px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="8px"
                  sx={{
                    cursor: "pointer",
                    backgroundColor: theme.palette.others.first,
                  }}
                >
                  <Typography variant="subHeading" color={main}>
                    توصیه های گذشته
                  </Typography>
                </Box>
              </Box>
              <Stack direction="row">
                <FlexBetween
                  sx={{
                    // display: open ? "inline-flex" : "none",
                    transition: "all 0.2s ease",
                    ml: "1.5rem",
                    alignItems: "center",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    // border: "1px solid black",
                    borderRadius: "8px",
                  }}
                >
                  <Search sx={{ mr: "0.2rem" }} />
                  <InputBase
                    disableUnderline
                    placeholder="جست و جو"
                    id="inputSearchRemote"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    sx={{
                      paddingRight: "5px",
                      backgroundColor: "inherit",
                      "& .MuiInputBase-input": {
                        "::placeholder": {
                          color: `${theme.palette.fontColor.main} !important`,
                        },
                      },
                      height: "27px",
                      width: "200px",
                      borderRadius: "8px",
                      color: theme.palette.fontColor.main,
                      outline: "none",
                      borderBottom: "none",
                    }}
                  />
                </FlexBetween>
                <Box width="125px" height="33px" borderRadius="8px">
                  <Select
                    value={selectValue}
                    IconComponent={FilterAltOutlined}
                    onChange={(e) => setSelectValue(e.target.value)}
                    MenuProps={{
                      // Apply the custom styled MenuList component
                      MenuListProps: { component: StyledMenuList },
                    }}
                    sx={{
                      width: "125px",
                      height: "33px",
                      border: `1px solid ${theme.palette.fontColor.alt}`,
                      borderRadius: "8px",
                      boxShadow: "none !important",
                      "& .MuiPaper-elevation": {
                        boxShadow: "none !important",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#40405e",
                      },
                    }}
                  >
                    <MenuItem
                      value={0}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: "0 5px",
                        borderRadius: "4px",
                        backgroundColor: `${theme.palette.background.third} !important`,
                        ":hover": {
                          backgroundColor: `${theme.palette.fontColor.third} !important`,
                        },
                      }}
                    >
                      همه ناحیه ها
                    </MenuItem>
                  </Select>
                </Box>
              </Stack>
            </FlexBetween>
            <Box
              style={{
                maxHeight: "calc(100% - 57px)",
                width: "100%",
                display: "flex",
                marginTop: "6px",
              }}
            >
              <Table data={tableData} expandable />
            </Box>
          </WidgetWrapper>
        </Box>
        <Box
          width="32.5%"
          height="99%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
        >
          <WidgetWrapper
            width="100%"
            height="62px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="1rem"
          >
            <Box
              borderLeft={`1px solid ${alt}`}
              display="flex"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <Typography
                  variant="smallestTextPrimary"
                  color="fontColor.main"
                >
                  امروز
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  10/18
                </Typography>
              </Stack>

              <Box component="img" src={W1} width="32px" height="32px" />
              <Stack direction="row" sx={{ direction: "ltr" }}>
                <Typography variant="body" color="fontColor.alt">
                  11 <sup>°</sup> |
                </Typography>
                <Typography variant="body" color="fontColor.main">
                  {" "}
                  16 <sup>°</sup>
                </Typography>
              </Stack>
            </Box>
            <Box
              borderLeft={`1px solid ${alt}`}
              display="flex"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <Typography
                  variant="smallestTextPrimary"
                  color="fontColor.main"
                >
                  یکشنبه
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  10/18
                </Typography>
              </Stack>

              <Box component="img" src={W1} width="32px" height="32px" />
              <Stack direction="row" sx={{ direction: "ltr" }}>
                <Typography variant="body" color="fontColor.alt">
                  11 <sup>°</sup> |
                </Typography>
                <Typography variant="body" color="fontColor.main">
                  {" "}
                  16 <sup>°</sup>
                </Typography>
              </Stack>
            </Box>
            <Box display="flex" alignItems="center" width="100%" height="100%">
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <Typography
                  variant="smallestTextPrimary"
                  color="fontColor.main"
                >
                  دوشنبه
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  10/18
                </Typography>
              </Stack>

              <Box component="img" src={W1} width="32px" height="32px" />
              <Stack direction="row" sx={{ direction: "ltr" }}>
                <Typography variant="body" color="fontColor.alt">
                  11 <sup>°</sup> |
                </Typography>
                <Typography variant="body" color="fontColor.main">
                  {" "}
                  16 <sup>°</sup>
                </Typography>
              </Stack>
            </Box>
          </WidgetWrapper>
          <Box width="100%" height="90%" sx={{ borderRadius: "12px" }}>
            <RecommendMap />
          </Box>
        </Box>
      </Box>

      <CopyRight />
    </Box>
  );
};

export default RecommendsPage;
