import { useMediaQuery } from "@mui/material";
import GrowthStagesMob from "mob/GrowthStagesMob/GrowthStagesMob";
import React from "react";
import GrowthStages from "./GrowthStages";

const GrowthStagesIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <>{isMobileScreens ? <GrowthStagesMob /> : <GrowthStages />}</>;
};

export default GrowthStagesIndex;
