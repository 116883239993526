import { Box, Rating, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import { useEffect } from "react";

const ExpertItem = ({ name, pic, rate }) => {
  const theme = useTheme();

  const [value, setValue] = React.useState(rate);
  const [hoverBox, setHoverBox] = useState(false);
  useEffect(() => {}, [value]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      width="220px"
      height="194px"
      borderRadius="4px"
      m="0 1rem"
      position="realtive"
    >
      <Box
        width="100%"
        height="100%"
        component="img"
        src={pic}
        borderRadius="4px"
      />
      <Box
        position="absolute"
        width="194px"
        height={hoverBox ? "80px" : "40px"}
        borderRadius="4px"
        sx={{
          bottom: "0.5rem",
          left: "0.5rem",
          transform: "translateX(5px)",
          transition: "all ease 0.3s",
          background: "rgba(29, 29, 65, 0.7)",
          backdropDrop: "blur(7.5px)",
          ml: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover": {
            background: theme.palette.background.second,
          },
        }}
        onMouseEnter={() => setHoverBox(true)}
        onMouseLeave={() => setHoverBox(false)}
      >
        <Box display="flex" flexDirection="column" gap="0.5rem">
          <FlexBetween gap="0.5rem">
            <Rating
              size="small"
              readOnly
              name="size-small"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <Typography variant="h5">{name}</Typography>
          </FlexBetween>
          {hoverBox && (
            <Box
              width="178px"
              height="27px"
              borderRadius="4px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                cursor: "pointer",
              }}
            >
              <Typography
                variant="smallestTextPrimary"
                color={theme.palette.fontColor.main}
              >
                درخواست مشاوره
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExpertItem;
