import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, FeatureGroup, useMap } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import L, { map } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw";
import { useSelector } from "react-redux";
import { Box, Input, Typography, styled, useTheme } from "@mui/material";
import * as turf from "@turf/turf";
import FlexBetween from "components/FlexBetween/FlexBetween";
import UploadIcon from "../../../assets/uploadeCoordinates.png";
const StyledInput = styled("input")({
  display: "none",
});

const FileUploader = () => {
  const theme = useTheme();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Do something with the uploaded file
    console.log("fileeeeeeeeee =====>");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <label htmlFor="file-upload">
        <StyledInput id="file-upload" type="file" onChange={handleFileUpload} />
        <Box
          component="div"
          sx={{
            borderRadius: "3px",
            color: "white",
            cursor: "pointer",
          }}
        >
          <FlexBetween gap="0.5rem">
            <Box component="img" src={UploadIcon} />
            <Typography
              variant="body"
              color={theme.palette.fontColor.main}
              align="center"
            >
              بارگذاری مختصات زمین
            </Typography>
          </FlexBetween>
        </Box>
      </label>
    </Box>
  );
};

const Search = (props) => {
  const map = useMap(); // access to leaflet map
  const { provider } = props;

  useEffect(() => {
    const searchControl = new GeoSearchControl({
      provider,
      style: "bar",
      showMarker: false,
      showPopup: false,
      autoClose: true,
      retainZoomLevel: true,
      animateZoom: false,
      keepResult: false,
      searchLabel: "جستجو",
    });

    map.addControl(searchControl); // this is how you add a control in vanilla leaflet
    return () => map.removeControl(searchControl);
  }, [props]);

  return null; // don't want anything to show up from this comp
};

const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "relative",
  width: "100% !important",
  // maxheight: "100%",
  padding: "0px",
  maxHeight: "100%",
  "& .leaflet-left": {
    left: "0%",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-draw-draw-polyline ": {
    // width: "24px !important",
    // height: "24px !important",
    // backgroundColor:
    //   mode === "dark"
    //     ? "rgba(29, 29, 65, 0.7) !important"
    //     : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
  "& .leaflet-control-geosearch": {
    // marginLeft: "60.5rem",
    position: "absolute",
    top: -12,
    right: 21,
    height: "33px !important",
  },
  "& .leaflet-control-geosearch form": {
    background: "#38385C !important",
    border: "none",
    marginTop: "22px !important",
  },

  "& .leaflet-control-geosearch input": {
    height: "33px !important",
    background: "#38385C !important",
    border: "none",
    color: "white",
    padding: "0 important",
    fontSize: "12px",
    fontFamily: "YekanBakhFaNum-Regular",
  },
  "& .leaflet-control-geosearch .reset": {
    // background: "#1D1D41 !important",
    // color: "white",
    display: "none !important",
    padding: "0 important",
  },
  "&.leaflet-geosearch-button a": {},
  "& .leaflet-geosearch-button .active form div": {
    // background: "#1D1D41 !important",
    color: "black",
    padding: "0 important",
  },
  "& .leaflet-control-geosearch .results": {
    padding: "0",
  },
  "& .leaflet-control-geosearch .results div": {
    padding: "0 important",
    background: "#38385C !important",
    color: "white !important",
  },
  "& .leaflet-geosearch-button": {},

  "& .leaflet-pane .leaflet-marker-pane img": {
    display: "none",
  },

  "& .leaflet-draw-tooltip-single": {
    display: "none !important",
  },

  "& .leaflet-draw-draw-polygon": {
    // backgroundColor: "#5A596F !important",
  },

  "& .leaflet-draw-draw-rectangle": {
    // backgroundColor: "#5A596F !important",
  },
  "& .leaflet-draw-edit-edit": {
    // backgroundColor: "#5A596F !important",
  },

  "& .leaflet-draw-edit-remove": {
    // backgroundColor: "#5A596F !important",
  },
  "& .leaflet-draw-draw-polygon a span": {
    color: "white !important",
  },
  "& .leaflet-draw-tooltip": {
    display: "none",
  },
}));
const DrawMap = ({ setFlag, setStep }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [editableFG, setEditableFG] = useState(null);
  const [polygonArea, setPolygonArea] = useState(0);
  console.log(
    "🚀 ~ file: DrawMap.jsx:63 ~ DrawMap ~ polygonArea:",
    polygonArea
  );

  const [polygonPoints, setPolygonPoints] = useState([]); // State variable to store polygon points
  console.log(
    "🚀 ~ file: DrawMap.jsx:12 ~ DrawMap ~ polygonPoints:",
    polygonPoints
  );

  useEffect(() => {
    if (polygonPoints.length >= 3) {
      const polygon = turf.polygon([polygonPoints]);
      const area = turf.area(polygon);
      const areaInHectares = (area / 10000).toFixed(2);
      setPolygonArea(areaInHectares);
    } else {
      setPolygonArea(0);
    }
  }, [polygonPoints]);
  const onCreated = (e) => {
    const { layer } = e; // Get the created layer from the event
    const polygon = layer.toGeoJSON(); // Convert the layer to GeoJSON format
    const points = polygon.geometry.coordinates[0]; // Extract the points from the GeoJSON

    setPolygonPoints(points); // Update the state with the polygon points
    setFlag(true);
  };
  const onDeleted = () => {
    setPolygonPoints([]);
    setFlag(false);
  };

  const onFeatureGroupReady = (reactFGref) => {
    setEditableFG(reactFGref);
  };

  return (
    <MapBox theme={theme} mode={mode}>
      <MapContainer
        center={[35.96, 50.83]}
        zoom={17}
        style={{ borderRadius: "12px", height: "59vh" }}
      >
        <TileLayer
          attribution="PTR"
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        <FeatureGroup
          ref={(featureGroupRef) => {
            onFeatureGroupReady(featureGroupRef);
          }}
        >
          <EditControl
            position="bottomleft"
            onCreated={onCreated}
            onDeleted={onDeleted}
            draw={{
              rectangle: true,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
              polygon: true,
            }}
          />
        </FeatureGroup>
        <Search provider={new OpenStreetMapProvider()} />
      </MapContainer>
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          zIndex: 1000,
          width: "155px",
          height: "33px",
          backgroundColor: theme.palette.background.third,
          borderRadius: "8px",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="smallestTextSecondary"
          color={theme.palette.fontColor.alt}
        >
          مساحت زمین
        </Typography>
        <Typography
          variant="smallestTextSecondary"
          color={theme.palette.fontColor.main}
        >
          {polygonArea}
        </Typography>
        <Typography
          variant="smallestTextSecondary"
          color={theme.palette.fontColor.main}
        >
          هکتار
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "20px",
          left: "50px",
          zIndex: 1000,
          width: "201px",
          height: "33px",
          backgroundColor: theme.palette.background.third,
          borderRadius: "8px",
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FileUploader />
      </Box>

      <Box
        sx={{
          position: "absolute",
          width: "250px",
          height: "44px",
          bottom: "00px",

          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 10000000,
          borderRadius: "8px",
          backgroundColor: theme.palette.others.first,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setStep(2)}
      >
        <Typography variant="h4">ثبت و بعدی</Typography>
      </Box>
    </MapBox>
  );
};

export default DrawMap;
