import { Box, useMediaQuery } from "@mui/material";
import MessengerMob from "mob/MessengerMob/MessengerMob";
import React from "react";
import Messenger from "./Messenger";

const MessengerIndex = () => {
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return <Box>{isMobileScreens ? <MessengerMob /> : <Messenger />}</Box>;
};

export default MessengerIndex;
