import { Box, Modal, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import SidebarRemoteSensing from './SidebarRemoteSensing';

import ButtonsRemoteSensing from 'components/ButtonsRemoteSensing/ButtonsRemoteSensing';
import MapCompare from 'components/Map/MapCompare';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyRight from 'widgets/CopyRight';
import Indicator from 'widgets/Indicator';
import ChartRemoteSensing from './ChartRemoteSensing';
import RemoteSensingLayerBox from './RemoteSensingLayersBox';
import RemoteSensingMap from './RemoteSensingMap';
import SelectRemoteSensing from './SelectRemoteSensing';
import {
    setActiveFarmIndex,
    setBoundary,
    setDate1R,
    setDate2R,
    setFirstDateR,
    setIdFarm,
    setPosition,
    setSecondDateR,
} from 'States/remoteSensing';
import calendar from '../../assets/calendar.svg';
import calendarLight from '../../assets/calendar-light.svg';
import DatePicker from 'react-multi-date-picker';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import { REMOTE_SENSING_DEFAULT_PERIOD, TODAY } from 'constants/constant';
import { shamsiToGregorian } from 'Utils/Utils';

const MapBox = styled(Box)(({ theme, mode }) => ({
    position: 'absolute',
    width: '100% !important',
    zIndex: 99,
    height: '100%',
    padding: '0px',
    '& .leaflet-pane': {
        zIndex: -1,
    },
    '& .leaflet-left': {
        left: '5px',
    },
    '& .leaflet-bar': {
        border: 'none',
    },
    '& .leaflet-control-zoom-in': {
        width: '24px !important',
        height: '24px !important',
        backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7) !important' : 'rgba(250, 250, 252, 0.7) !important',
        color: theme.palette.fontColor.alt,
        borderRadius: '8px 8px 0 0 !important',
    },

    '& .leaflet-touch .leaflet-bar a': {
        lineHeight: '24px !important',
    },
    '& .leaflet-control-zoom-out': {
        width: '24px !important',
        height: '24px !important',
        backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7 )!important' : 'rgba(250, 250, 252, 0.7) !important',
        color: theme.palette.fontColor.alt,
        borderRadius: '0 0 8px 8px !important',
    },
}));

const RemoteSensing = () => {
    const isMobileScreens = useMediaQuery('(max-width:600px)');
    const theme = useTheme();
    const [openCompare, setOpenCompare] = useState(false);
    const mode = useSelector((state) => state.global.mode);
    const farms = useSelector((state) => state.dashboard.property);
    const position = useSelector((state) => state.remoteSensing.position);
    const datePickerRef = useRef();
    const [selectedRange, setSelectedRange] = useState([new Date(REMOTE_SENSING_DEFAULT_PERIOD), new Date(TODAY)]);
    const dispatch = useDispatch();
    const farmDataCalculation = (data) => {
        const active = data?.find((obj) => obj.active === true);
        const activeIndex = data?.findIndex((obj) => obj.id === active.id);
        if (active && activeIndex) {
            let temp = active.boundary.coordinates[0];
            let count = 0;
            let positionX = 0;
            let positionY = 0;

            temp.forEach((item) => {
                count++;
                positionX = positionX + item[0];
                positionY = positionY + item[1];
            });
            dispatch(
                setPosition({
                    position: [positionX / count, positionY / count],
                }),
            );
            dispatch(
                setBoundary({
                    boundary: active.boundary.coordinates[0],
                }),
            );

            dispatch(
                setIdFarm({
                    idFarm: active.id,
                }),
            );

            dispatch(
                setActiveFarmIndex({
                    activeFarmIndex: activeIndex,
                }),
            );
        }
    };
    const handleDateChange = (value) => {
        const gregorian = value?.map((date) => shamsiToGregorian(`${date.year}-${date.month}-${date.day}`));
        setSelectedRange(value);
        if (value.length === 2) {
            dispatch(
                setFirstDateR({
                    firstDate: gregorian[0],
                }),
            );

            dispatch(
                setDate1R({
                    date1: {
                        year: value[0].year,
                        month: value[0].month.number,
                        day: value[0].day,
                    },
                }),
            );
            dispatch(
                setSecondDateR({
                    secondDate: gregorian[1],
                }),
            );
            dispatch(
                setDate2R({
                    date2: {
                        year: value[1].year,
                        month: value[1].month.number,
                        day: value[1].day,
                    },
                }),
            );
            setTimeout(() => {
                datePickerRef.current.closeCalendar();
            }, 1000);
        }
    };
    useEffect(() => {
        farmDataCalculation(farms);
    }, [farms]);

    const [mapModal, setMapModal] = useState(false);

    return (
        <>
            {openCompare ? (
                <Box width='100%' height='100%' position='relative'>
                    <MapBox mode={mode}>
                        <MapCompare setOpenCompare={setOpenCompare} />
                    </MapBox>
                </Box>
            ) : (
                <Box
                    minWidth='1024px'
                    width='calc(100% - 2rem)'
                    height='83vh'
                    m='1rem auto 0'
                    position='relative'
                    display='flex'
                    flexDirection='column'
                >
                    <Modal
                        open={mapModal}
                        onClose={() => setMapModal(false)}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box>
                            <Typography id='modal-modal-title' variant='h6' component='h2'>
                                Text in a modal
                            </Typography>
                            <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                            </Typography>
                        </Box>
                    </Modal>
                    <MapBox mode={mode}>
                        <RemoteSensingMap position={position} />
                    </MapBox>
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'stretch',
                            padding: '1rem 1rem 1rem 4rem',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <SidebarRemoteSensing />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                width: 'calc(100% - 270px)',
                            }}
                        >
                            <RemoteSensingLayerBox />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end',
                                    width: '100%',
                                }}
                            >
                                <SelectRemoteSensing />

                                <ChartRemoteSensing />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '30px',
                            height: '188px',
                            borderRadius: '8px',
                            position: 'absolute',
                            bottom: '1rem',
                            left: '0.75rem',
                            zIndex: 1000,
                            backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                            backdropFilter: 'blur(7.5px)',
                            boxShadow: 'none',
                        }}
                    >
                        <Indicator />
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '5rem',
                            left: '15px',
                            width: '24px',
                            height: '24px',
                            backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                            backdropFilter: 'blur(7.5px)',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2000,
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#ddd',
                            },
                            '& .rmdp-wrapper': {
                                backgroundColor: theme.palette.background.third,
                                width: isMobileScreens ? 'auto' : '250px',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            },
                            '& .rmdp-container': {
                                width: '100%',
                                display: 'block',
                            },
                            '& .rmdp-day': {
                                color: theme.palette.fontColor.main,
                            },
                            '& .rmdp-today span': {
                                backgroundColor: theme.palette.background.second,
                            },
                            '& .rmdp-arrow': {
                                borderColor: theme.palette.fontColor.main,
                            },
                            '& .rmdp-week-day': {
                                color: theme.palette.fontColor.alt,
                            },
                            '& .rmdp-header-values span': {
                                color: theme.palette.fontColor.main,
                            },
                            '& .rmdp-panel-header': {
                                color: theme.palette.fontColor.main,
                            },
                            '& .rmdp-ep-arrow': {
                                borderBottom: 'none',
                                borderTop: 'none',
                            },
                            '& .ep-arrow::after': {
                                backgroundColor: theme.palette.background.third,
                            },
                            '& .rmdp-ep-shadow:after': {
                                boxShadow: 'none',
                            },
                            '& .rmdp-month-picker': {
                                backgroundColor: theme.palette.background.third,
                            },
                            '& .rmdp-year-picker': {
                                backgroundColor: theme.palette.background.third,
                            },
                            '& .rmdp-input': {
                                color: 'white !important',
                                fontFamily: 'YekanBakhFaNum-Regular',
                            },
                            '& .rmdp-top-class': {
                                backgroundColor: theme.palette.background.third,
                                color: '#fff',
                            },
                            '& .rmdp-day.rmdp-today span': {
                                backgroundColor: theme.palette.primary.alt,
                            },
                            '& .rmdp-range': {
                                backgroundColor: 'rgba(255, 116, 16, 0.4)',
                                boxShadow: 'none',
                            },
                            '& .start': {
                                backgroundColor: theme.palette.primary.alt,
                            },
                            '& .end': {
                                backgroundColor: theme.palette.primary.alt,
                                '&:before': {
                                    content: '" "',
                                    backgroundColor: 'rgba(255, 116, 16, 0.4)',
                                },
                            },
                            '& .rmdp-rtl .rmdp-range': {
                                '&.end': {
                                    borderBottomRightRadius: '50%',
                                    borderTopRightRadius: '50%',
                                    position: 'relative',
                                    '&::before': {
                                        content: '" "',
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        width: '50%',
                                        height: '100%',
                                        backgroundColor: 'rgba(255, 116, 16, 0.4)',
                                    },
                                },
                                '&.start': {
                                    borderBottomLeftRadius: '50%',
                                    borderTopLeftRadius: '50%',
                                    position: 'relative',
                                    '&::before': {
                                        content: '" "',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        width: '50%',
                                        height: '100%',
                                        backgroundColor: 'rgba(255, 116, 16, 0.4)',
                                    },
                                },
                            },
                            '& .rmdp-day .sd': {
                                color: theme.palette.fontColor.main,
                            },
                            '& .rmdp-week .rmdp-week-day': {
                                color: theme.palette.fontColor.alt,
                            },
                            '& .rmdp-header-values': {
                                color: theme.palette.fontColor.main,
                            },
                            '& .rmdp-shadow': {
                                boxShadow: 'none',
                            },
                            '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
                                backgroundColor: theme.palette.primary.alt,
                            },
                        }}
                    >
                        <DatePicker
                            render={
                                <div
                                    onClick={() => datePickerRef.current.openCalendar()}
                                    style={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <img
                                        src={mode === 'dark' ? calendar : calendarLight}
                                        alt='calendar icon'
                                        sx={{
                                            width: '16px',
                                            height: '16px',
                                        }}
                                    />
                                </div>
                            }
                            className='bg-dark rmdp-prime'
                            calendar={persian}
                            locale={persian_fa}
                            range
                            weekDays={['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج']}
                            value={selectedRange}
                            onChange={handleDateChange}
                            ref={datePickerRef}
                        />
                    </Box>
                    <ButtonsRemoteSensing
                        openCompare={openCompare}
                        setOpenCompare={setOpenCompare}
                        setMapModal={setMapModal}
                    />
                </Box>
            )}
            <Box width='93vw' mr='1rem'>
                <CopyRight />
            </Box>
        </>
    );
};

export default RemoteSensing;
