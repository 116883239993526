import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import AnimatedProgressBarWalletSmall from "scenes/Wallet/AnimatedProgressbarWalletSmall";
import { useNavigate } from "react-router-dom";

const WalletFeatureItemMob = ({
  title,
  totalDays,
  remainedDays,
  route,
  color,
  titleRemained,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      width="300px"
      height="160px"
      display="flex"
      aligItems="center"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          gap="0.5rem"
        >
          <Typography variant="h4" color={theme.palette.fontColor.main}>
            {title}
          </Typography>
          <Box
            sx={{
              direction: "rtl",
              display: "flex",
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subHeading"
              color={theme.palette.fontColor.alt}
            >
              کل روز:
            </Typography>
            <Typography variant="h4" color={theme.palette.fontColor.alt}>
              {totalDays}
            </Typography>
            <Typography
              variant="subHeading"
              color={theme.palette.fontColor.alt}
            >
              روز
            </Typography>
          </Box>
        </Box>
        <Typography
          onClick={() => (route ? navigate(route) : {})}
          sx={{
            borderBottom: `1px solid ${theme.palette.others.first}`,
            cursor: "pointer",
          }}
          variant="body"
          color={theme.palette.others.first}
        >
          مشاهده
        </Typography>
      </Box>
      <AnimatedProgressBarWalletSmall
        number={remainedDays}
        width={157}
        height={163}
        title={remainedDays}
        subTitle="روز مانده"
        titleRemained={titleRemained}
        color={color}
        dot={false}
      />
    </Box>
  );
};

export default WalletFeatureItemMob;
