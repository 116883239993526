export const BirthData = {
    type: "زایش",
    success: [
      ["05/12", 910],
      ["05/13", 820],
      ["05/14", 900],
      ["05/15", 672],
      ["05/16", 650],
      ["05/17", 723],
      ["05/18", 831],
      ["05/19", 850],
    ],
    failure: [
      ["05/12", 810],
      ["05/13", 820],
      ["05/14", 750],
      ["05/15", 672],
      ["05/16", 620],
      ["05/17", 623],
      ["05/18", 731],
      ["05/19", 550],
    ],
    status: [[850, 973], "تعداد زایمان ها"],
  
    livestock: [
      {
        code: 900,
        birthday: "1402/04/10",
        dueBirthdy: "1402/02/29",
        gender: "نر",
        noSperm: "2255514261",
        status: "طبیعی",
      },
      {
        code: 899,
        birthday: "1402/04/20",
        dueBirthdy: "1402/01/29",
        gender: "نر",
        noSperm: "2255514262",
        status: "طبیعی",
      },
      {
        code: 898,
        birthday: "1402/04/18",
        dueBirthdy: "1401/12/29",
        gender: "ماده",
        noSperm: "2255514263",
        status: "طبیعی",
      },
      {
        code: 897,
        birthday: "1402/04/10",
        dueBirthdy: "1402/01/29",
        gender: "نر",
        noSperm: "2255514264",
        status: "طبیعی",
      },
    ],
  };