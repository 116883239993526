import {
  Box,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import FlexBetween from "components/FlexBetween/FlexBetween";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";

import ChartZoom from "chartjs-plugin-zoom";
import LineNormal from "components/Charts/LineNormal";
import LineFillupY from "components/Charts/LineFillupY";
import { useEffect } from "react";
import SelectDevice from "./SelectDevice";
import SelectSensor from "./SelectSensor";
Chart.register(...registerables);
Chart.register(annotationPlugin);
// --------------------------

// -----------------------
const MixedChart = ({ device, sensor, type }) => {
  console.log("🚀 ~ file: MixedChart.jsx:31 ~ data1:", type);
  const data3 = useSelector(
    (state) => state.sensors.sensorsData.devices
  );

  const [selectedSensor, setSelectedSensor] = useState(sensor);
  const [selectedDevice, setSelectedDevice] = useState(device);
  const [selectedType, setSelectedType] = useState(type);
  console.log(
    "🚀 ~ file: MixedChart.jsx:24 ~ MixedChart ~ selectedSensor:",
    selectedSensor
  );

  useEffect(() => {}, [selectedDevice, selectedSensor]);
  const getGradient = (ctx, chartArea, scales) => {
    const gradientBg = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradientBg.addColorStop(0, theme.palette.others.first);
    gradientBg.addColorStop(1, theme.palette.others.second);
    return gradientBg;
  };
  const theme = useTheme();

  const [flag, setFlag] = useState(true);

  const detailOfDevice =
    selectedDevice !== null
      ? data3.filter((item) => {
          return item.id === selectedDevice;
        })
      : "";

  let temporary = "";
  if (detailOfDevice)
    detailOfDevice.forEach((element) => {
      console.log("temporary", element.data);
      temporary = element.data;
    });

  const keys = Object.keys(temporary);

  const dataChart = temporary[selectedSensor];

  const handleChange = (event) => {
    console.log(
      "🚀 ~ file: MixedChart.jsx:131 ~ handleChange ~ event.target.value:",
      event.target.value
    );
    setSelectedDevice(event.target.value);
  };

  const handleSensorChange = (event) => {
    console.log(
      "🚀 ~ file: MixedChart.jsx:139 ~ handleSensorChange1 ~ event.target.value:",
      event.target.value
    );
    setSelectedSensor(event.target.value);
  };

  const convertName = (item) => {
    switch (item) {
      case "precipitation_rain":
        return "بارندگی";
      case "temperature":
        return "دما";
      case "humidity":
        return "رطوبت";
      case "atmospheric_pressure":
        return "فشار";
      case "wind_speed":
        return "باد";
      case "solar_radiation":
        return "شدت تابش";

      default:
        return "";
    }
  };
  const getUnit = (item) => {
    switch (item) {
      case "precipitation_rain":
        return "میلی متر";
      case "temperature":
        return "سانتی گراد";
      case "humidity":
        return "درصد";
      case "atmospheric_pressure":
        return "بار";
      case "wind_speed":
        return "کیلومتر بر ساعت";
      case "solar_radiation":
        return "لوکس";

      default:
        return " ";
    }
  };

  const getChart = (sensor, type) => {
    switch (true) {
      case selectedSensor === "humidity" && selectedType === "WEATHER":
        return (
          <LineNormal
            th2={true}
            sensorData={dataChart}
            min={0}
            max={100}
            stepSize={50}
            thv1={75}
            thv2={20}
            h={135}
          />
        );
      case selectedSensor === "temperature" && selectedType === "WEATHER":
        return (
          <LineFillupY
            th2={true}
            sensorData={dataChart}
            min={-50}
            max={50}
            stepSize={50}
            thv1={25}
            thv2={-25}
            h={135}
          />
        );
      default:
        break;
    }
  };
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return (
    <>
      {sensor ? (
        <Box
          mt="0.5rem"
          sx={{
            width: "100%",
            height: "85%",
          }}
        >
          <FlexBetween
            flexDirection={isMobileScreens ? "column" : "row"}
            sx={{
              alignItems: isMobileScreens ? "flex-start" : "center",
              // justifyContent: isMobileScreens ? "flex-end" : "center",
              gap: "0.5rem",
            }}
          >
            <FlexBetween
              width="200px"
              gap="0.5rem"
              sx={{ justifyContent: "flex-start" }}
            >
              <Typography fontSize={theme.typography.smallestTextSecondary}>
                {convertName(selectedSensor)}
              </Typography>
              <Typography fontSize={theme.typography.smallestTextSecondary}>
                {convertName(selectedSensor)
                  ? `(${getUnit(selectedSensor)})`
                  : ""}
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <SelectDevice
                setSelectedDevice={setSelectedDevice}
                selectedDevice={selectedDevice}
                data3={data3}
              />
              <SelectSensor
                keys={keys}
                selectedSensor={selectedSensor}
                setSelectedSensor={setSelectedSensor}
              />
            </Box>
          </FlexBetween>
          {flag ? getChart(sensor, type) : null}
        </Box>
      ) : (
        <Box>
          <Typography></Typography>
        </Box>
      )}
    </>
  );
};

export default MixedChart;
