import {
  AddOutlined,
  AttachFileOutlined,
  EmojiEmotionsOutlined,
  MicNoneOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputBase,
  Stack,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { setSelectedMember } from "States/messenger";
import ContactItem from "components/ContactItem/ContactItem";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentMessenger from "scenes/Messenger/ContentMessenger";
import HeaderMob from "./HeaderMob";
import ContactItemMob from "./ContactItemMob";
import SendMessageDark from "../../assets/icon/send-message-dark.svg";
import SendMessageLight from "../../assets/icon/send-message-light.svg";
const MessengerMob = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [message_open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const messenger = useSelector((state) => state.messenger.messenger);
  const selectedMember = useSelector((state) => state.messenger.selectedMember);
  const [searchValue, setSearchValue] = useState("");
  const filteredMessenger = messenger.filter((item) =>
    searchValue !== "" ? item.name.includes(searchValue) : item
  );
  const handleWriteMessage = (e) => {
    setMessage(e.target.value);
  };
  useEffect(() => {
    dispatch(setSelectedMember({ selectedMember: null }));
  }, []);
  return (
    <>
      <WidgetWrapper
        width={message_open ? "calc(100% - 1rem)" : 0}
        height={"calc(100dvh - 140px)"}
        sx={{
          position: "absolute",
          left: "0",
          overflow: "hidden",
          padding: message_open ? "1rem" : "1rem 0",
          transition: "all 0.3s 0.3s",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        m="0.5rem"
      >
        <Box
          width="100%"
          pb={"0.5rem"}
          borderBottom={`1px solid ${theme.palette.background.third}`}
        >
          <HeaderMob
            handleClickBack={() => {
              setOpen(false);
              setTimeout(() => {
                dispatch(setSelectedMember({ selectedMember: null }));
              }, 600);
            }}
            selectedMember={selectedMember}
          />
        </Box>
        <Box
          width="calc(100vw - 3rem)"
          flexGrow={1}
          sx={{ overflowY: "auto", mb: "0.2rem" }}
        >
          <ContentMessenger />
        </Box>
        <Box width="100%" display="flex" alignItems="flex-end">
          <Box
            width="100%"
            height="100%"
            display="flex"
            sx={{
              backgroundColor: theme.palette.background.third,
              borderRadius: "8px",
              p: "0.25rem 0.5rem",
            }}
            alignItems="flex-end"
            gap="0.3rem"
          >
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              flexGrow={1}
            >
              <IconButton>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    position: "relative",
                  }}
                >
                  <img
                    src={mode === "dark" ? SendMessageDark : SendMessageLight}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: message ? 20 : 0,
                      height: message ? 20 : 0,
                      transition: "all 0.3s",
                    }}
                  />
                  <AttachFileOutlined
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: message ? 0 : 20,
                      height: message ? 0 : 20,
                      transition: "all 0.1s",
                      transform: "rotate(45deg)",
                    }}
                  />
                </div>
              </IconButton>
              <FormControl
                sx={{
                  width: "95%",
                  py: "8px",
                  "& textarea::placeholder": {
                    color: alt,
                  },
                }}
              >
                <TextareaAutosize
                  value={message}
                  onChange={handleWriteMessage}
                  placeholder="پیام جدید"
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    color: main,
                    resize: "none",
                    border: "none",
                    outline: "none",
                    fontFamily: "YekanBakhFaNum-Regular",
                  }}
                />
              </FormControl>
            </Box>
            {/* <Box
              gap="0.5rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton sx={{ px: 0 }}>
                <EmojiEmotionsOutlined />
              </IconButton>
              <IconButton sx={{ px: 0 }}>
                <MicNoneOutlined />
              </IconButton>
            </Box> */}
          </Box>
        </Box>
      </WidgetWrapper>
      <WidgetWrapper
        m="0.5rem"
        width={message_open ? 0 : "calc(100% - 1rem)"}
        sx={{
          position: "absolute",
          right: "0",
          overflow: "hidden",
          padding: message_open ? "1rem 0" : "1rem",
          transition: "all 0.3s 0.3s",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={"space-between"}
          overflowX="hidden"
        >
          <Typography variant="h4" color={main} whiteSpace="nowrap">
            پیام رسان
          </Typography>
          <Box
            // width="121px"
            px="1rem"
            height="36px"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: theme.palette.others.first,
              p: "0 0.7rem",
              cursor: "pointer",
            }}
          >
            <Stack direction="row" alignItems="center" gap="0.2rem">
              <AddOutlined sx={{ width: "24px", height: "24px" }} />
              <Typography>چت جدید</Typography>
            </Stack>
          </Box>
        </Stack>
        <FlexBetween
          sx={{
            transition: "all 0.2s ease",
            mt: "1.5em",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.main}`,
            borderRadius: "8px",
          }}
        >
          <Search sx={{ mr: "0.2rem" }} />
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",

              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
              },
              height: "33px",
              width: "100%",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
        </FlexBetween>
        <Box
          mt="1rem"
          width="100%"
          maxHeight="calc(100dvh - 265px)"
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {filteredMessenger.map((item, index) => (
            <ContactItemMob
              key={index}
              item={item}
              handleClick={() => setOpen(true)}
            />
          ))}
        </Box>
      </WidgetWrapper>
    </>
  );
};

export default MessengerMob;
