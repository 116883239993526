import {
  Box,
  Button,
  Divider,
  IconButton,
  Rating,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import FarmIcon from "../../assets/Modules/weather1.png";
import FarmIcon2 from "../../assets/Modules/Weather2.png";
import FarmIcon3 from "../../assets/Modules/Weather3.png";
import FarmIcon4 from "../../assets/Modules/Weather4.png";
import PackageItemMob from "./PackageItemMob";
import { useSelector } from "react-redux";
import ArrowRightDark from "../../assets/icon/arrow-right-dark.svg";
import ArrowRightLight from "../../assets/icon/arrow-right-light.svg";
import ShoppingCardDark from "../../assets/icon/shopping-cart-dark.svg";
import ShoppingCardLight from "../../assets/icon/shopping-cart-light.svg";
import MessageDark from "../../assets/icon/message-dark.svg";
import MessageLight from "../../assets/icon/message-light.svg";
import StarDark from "../../assets/icon/star-dark.svg";
import StarLight from "../../assets/icon/star-light.svg";
import CommentItem from "modules/WeatherModule/commentItem";
const CommentsButton = styled(Button)(({ theme }) => ({
  color: theme.palette.fontColor.main,
  backgroundColor: theme.palette.background.third,
  boxShadow: "none",
  borderRadius: "8px",
  width: "100%",
  height: "36px",
  "&:focus": {
    backgroundColor: theme.palette.background.third,
  },
}));
const recommededServices = [
  "شاخص هوشمند",
  "مدیریت سنسورها",
  "توصیه ها",
  "مدیریت کارکنان",
  "هشدارها",
];
const WeatherModuleMob = ({ setWeatherModule }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  return (
    <>
      <Box
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "calc(100dvh - 112px)",
          padding: "12px",
        }}
      >
        <WidgetWrapper mt="12px">
          <IconButton sx={{ padding: "8px 0" }}>
            <Box
              component="img"
              width="32px"
              height="32px"
              borderRadius="50%"
              src={mode === "dark" ? ArrowRightDark : ArrowRightLight}
              onClick={() => setWeatherModule(false)}
            />
          </IconButton>
          <Stack
            direction="row"
            alignItems="stretch"
            width="100%"
            gap="16px"
            mb="12px"
          >
            <Box component="img" src={FarmIcon} width="64px" height="64px" />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              width="90%"
            >
              <Typography variant="h4" color="fontColor.main">
                هواشناسی
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                ورژن 1.0.0
              </Typography>
            </Box>
            <Box
              component="img"
              src={mode === "dark" ? ShoppingCardDark : ShoppingCardLight}
              width="24px"
              height="24px"
            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            gap="24px"
            justifyContent="flex-start"
            mb="16px"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Rating
                size="medium"
                name="read-only"
                value={4}
                readOnly
                sx={{ direction: "ltr" }}
              />
              <Typography variant="subHeading" color="fontColor.alt">
                83 نفر
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <Typography variant="h5" color="fontColor.main">
                150
              </Typography>
              <Typography variant="subHeading" color="fontColor.alt">
                خرید
              </Typography>
            </Box>
          </Stack>
          <Typography>
            سرویس هواشناسی یکی از مهم سرویس هایی هست که در روند رسیدگی به مزارع
            به کشاورزان کمک می کند تا از وقوع حوادث طبیعی جلوگیری کنند و زیان
            کمتری به محصولات وارد شود
          </Typography>
          <Box
            mt="2rem"
            width="100%"
            //   height="50%"
            display="flex"
            alignItems="stretch"
            // p="0 1rem"
            justifyContent="space-between"
            sx={{ flexWrap: "wrap", gap: "12px" }}
          >
            <PackageItemMob
              title={"سه ماهه"}
              price={"750"}
              unit={"هزار تومان"}
            />
            <PackageItemMob
              title={"شش ماهه"}
              price={"1.3"}
              unit={"میلیون تومان"}
              enable={true}
            />
            <PackageItemMob
              title={"یک ساله"}
              price={"2.2"}
              unit={"میلیون تومان"}
            />
            <PackageItemMob
              title={"سه ساله"}
              price={"4"}
              unit={"میلیون تومان"}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            mt="3rem"
            gap="12px"
            sx={{
              overflowY: "hidden !important",
              overflowX: "auto",
              overflow: "-moz-scrollbars-none",
              "-ms-scrollbar-style": "none",
              scrollbarWidth: "none",
              mb: "1rem",
              px: "0.5rem",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Box height="122px" component="img" src={FarmIcon2} />
            <Box height="122px" component="img" src={FarmIcon3} />
            <Box height="122px" component="img" src={FarmIcon4} />
          </Box>
          <Box width="100%" mt="3rem">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography variant="h4" color="fontColor.main">
                تغییرات نسخه آخر
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                ورژن 1.0.0
              </Typography>
            </Box>
            <Box m="0.5rem 1.5rem 0 0">
              <ul>
                <li style={{ margin: "1.5rem 0 0.5rem 0" }}>
                  <Typography variant="body" color="fontColor.main">
                    مشاهده تغییرات آب و هوای 7 روز آینده
                  </Typography>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <Typography variant="body" color="fontColor.main">
                    زمان غروب و طلوع خورشید
                  </Typography>
                </li>
                <li style={{ marginBottom: "0.5rem" }}>
                  <Typography variant="body" color="fontColor.main">
                    مقایسه داده های آب و هوا با داده های سنسورهای مزرعه
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>
        </WidgetWrapper>
        <WidgetWrapper mt="12px">
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography variant="h4" color="fontColor.main">
              نظرات شما
            </Typography>
            <Typography variant="subHeading" color="fontColor.alt">
              یک دیدگاه
            </Typography>
          </Stack>

          <CommentItem />
          <Divider />
          <CommentItem />
          <Divider />
          <CommentItem />

          <Box
            sx={{
              width: "136px",
              m: "1rem auto",
            }}
          >
            <CommentsButton>همه نظرات</CommentsButton>
          </Box>
          <Typography
            variant="smallestTextSecondary"
            color="fontColor.alt"
            sx={{
              borderBottom: `1px solid ${theme.palette.fontColor.alt}`,
              display: "flex",
              alignItems: "center",
              gap: "4px",
              width: "fit-content",
            }}
          >
            <Box
              component="img"
              src={mode === "dark" ? MessageDark : MessageLight}
              width="16px"
              height="16px"
            />
            نظر خود را ثبت کنید
          </Typography>
        </WidgetWrapper>
        <WidgetWrapper mt="12px">
          <Typography variant="h4">سرویس های پیشنهادی</Typography>
          <Box
            width="100%"
            display="flex"
            alignItems="stretch"
            justifyContent="flex-start"
            mt="1rem"
            gap="12px"
            sx={{
              overflowY: "hidden !important",
              overflowX: "auto",
              overflow: "-moz-scrollbars-none",
              "-ms-scrollbar-style": "none",
              scrollbarWidth: "none",
              mb: "1rem",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {recommededServices.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  component="img"
                  src={FarmIcon}
                  width="82px"
                  height="82px"
                />
                <Typography
                  variant="smallestTextSecondary"
                  sx={{ alignSelf: "flex-start" }}
                >
                  {item}
                </Typography>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    width: "fit-content",
                  }}
                  variant="smallestTextSecondary"
                >
                  <Box
                    component="img"
                    src={mode === "dark" ? StarDark : StarLight}
                    width="6px"
                    height="6px"
                  />
                  4.5
                </Typography>
              </Box>
            ))}
          </Box>
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default WeatherModuleMob;
