export const CompareData = [
    {
      code: 1,
      data: [
        ["05/12", 32],
        ["05/13", 27],
        ["05/14", 28],
        ["05/15", 30],
        ["05/16", 28],
        ["05/17", 30],
      ],
    },
    {
      code: 2,
      data: [
        ["05/12", 35],
        ["05/13", 30],
        ["05/14", 23],
        ["05/15", 28],
        ["05/16", 28],
        ["05/17", 28],
      ],
    },
    {
      code: 3,
      data: [
        ["05/12", 29],
        ["05/13", 22],
        ["05/14", 21],
        ["05/15", 26],
        ["05/16", 26],
        ["05/17", 25],
      ],
    },
    {
      code: 4,
      data: [
        ["05/12", 34],
        ["05/13", 34],
        ["05/14", 30],
        ["05/15", 26],
        ["05/16", 26],
        ["05/17", 26],
      ],
    },
    {
      code: 5,
      data: [
        ["05/12", 27],
        ["05/13", 27],
        ["05/14", 28],
        ["05/15", 33],
        ["05/16", 32],
        ["05/17", 34],
      ],
    },
  ];