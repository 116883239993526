import { CloseOutlined, DangerousOutlined } from "@mui/icons-material";
import { Box, Snackbar, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Form from "components/Form/Form";
import React, { useEffect } from "react";
import LoginPic from "../../../assets/responsive-login.png";
import InstagramIcon from "../../../assets/instagram.svg";
import YoutubeIcon from "../../../assets/youtube.svg";
import LinkdinIcon from "../../../assets/linkedin.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormMob from "./FormMob";

const LoginMob = () => {
  const theme = useTheme();
  const token = useSelector((state) => state.global.token);
  const [open, setOpen] = React.useState(false);
  const mode = useSelector((state) => state.global.mode);
  const vertical = "top";
  const horizontal = "center";
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);
  return (
    <FlexBetween
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ position: "absolute", width: "100%", height: "100%" }}
        component="img"
        src={LoginPic}
      />
      <Box
        sx={{
          width: "1400px",
          height: "600px",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
          {mode === "dark" ? (
            <img
              alt="img1"
              src={require("../../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
              }}
            ></img>
          ) : (
            <img
              alt="img1"
              src={require("../../../assets/LogoColor.png")}
              style={{
                width: "52px",
                height: "40px",
                objectFit: "cover",
              }}
            ></img>
          )}
        </Box>
        <Box
          sx={{
            width: "336px",
            height: "461px",

            backgroundColor: "rgba(248, 248, 252, 0.3)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "24px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormMob setOpen={setOpen} />
        </Box>
      </Box>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        dir="rtl"
      >
        <FlexBetween
          gap="1rem"
          sx={{
            backgroundColor: theme.palette.others.second,
            p: "0.5rem 0.5rem",
            borderRadius: "8px",
          }}
        >
          <FlexBetween gap="0.5rem">
            <DangerousOutlined sx={{ width: "24px", height: "24px" }} />
            <Typography>ورود ناموفق بود لطفا مجددا تلاش کنید</Typography>
          </FlexBetween>
          <CloseOutlined
            onClick={handleClose}
            sx={{ width: "24px", height: "24px" }}
          />
        </FlexBetween>
      </Snackbar>
    </FlexBetween>
  );
};

export default LoginMob;
