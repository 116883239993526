export const DrynessData = {
    type: "تلقیح",
    performance: [
      ["05/12", 870],
      ["05/13", 850],
      ["05/14", 870],
      ["05/15", 420],
      ["05/16", 806],
      ["05/17", 810],
      ["05/18", 850],
      ["05/19", 870],
    ],
    status: [[870, 1750], "دام های خشک"],
  
    livestock: [
      {
        code: 900,
        startDate: "1401/07/10",
        endDate: "1401/08/10",
        resean: "آبستن سنگین",
        wdry: "کاهش وعده های دوشش",
        milk: "9",
      },
      {
        code: 899,
        startDate: "1401/07/13",
        endDate: "1401/08/12",
        resean: "آبستن سنگین",
        wdry: "کاهش وعده های دوشش",
        milk: "8",
      },
      {
        code: 898,
        startDate: "1401/07/15",
        endDate: "1401/08/18",
        resean: "آبستن سنگین",
        wdry: "قطع دوشش",
        milk: "9",
      },
      {
        code: 897,
        startDate: "1401/07/21",
        endDate: "1401/08/12",
        resean: "آبستن سنگین",
        wdry: " قطع دوشش",
        milk: "6",
      },
    ],
  };