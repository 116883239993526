import { useParams } from "react-router-dom";
import {
  AddOutlined,
  ListOutlined,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  InputBase,
  ListItemText,
  MenuList,
  OutlinedInput,
  Rating,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useRef, useState } from "react";

import Pic1 from "../../../assets/StaffImg/1.jpg";
import Pic2 from "../../../assets/StaffImg/2.jpg";
import SendLight from "../../../assets/icon/send-light.svg";
import SendDark from "../../../assets/icon/send-dark.svg";
import { getDateShamsiAirSensors } from "Utils/Utils";
import DatePicker from "react-multi-date-picker";
import calendar from "../../../assets/calendar.svg";
import calendarLight from "../../../assets/calendar-light.svg";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useSelector } from "react-redux";
import WidgetWrapper from "components/WidgetWrapper";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
import RecordTask from "scenes/StaffMgmt/RecordTask";
import { Link } from "react-router-dom";
import TaskItem from "components/TaskItem/TaskItem";
import RecordTaskMob from "../StaffMgmtMob/RecordTaskMob";

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */

  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
  zIndex: 20000000,
}));
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["انجام شده", "انجام نشده", "در حال انجام"];

const staffData = [
  {
    id: "1",
    name: "امیر محمدی",
    pic: Pic1,
    allTasks: 35,
    done: 28,
    score: 4,
    tasks: [
      {
        id: "a;slkfha",
        name: "امیر محمدی",
        title: "آبیاری ناحیه 12",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "در حال انجام",
        taskDescription: "",
        comment: "آبیاری منطقه 12 انجام شد و مشکلی در روند انجام کار نبود.",
        backupAction: "ماشین آبیاری شماره 3",
      },
    ],
  },
  {
    id: "2",
    name: "سعید صمیمی",
    pic: Pic2,
    allTasks: 29,
    done: 15,
    score: 2,
    tasks: [
      {
        id: "afgujfmfki",
        name: "سعید صمیمی",
        title: "سم پاشی  ",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "انجام شده",
        taskDescription: "",
        comment: "سمپاشی منطقه 3 انجام شد و مشکلی در روند انجام کار نبود.",
        backupAction: "ماشین سم پاشی شماره 3",
      },
      {
        id: "foncgiokl",
        name: "سعید صمیمی",
        title: "سم  ناحیه 3",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "انجام شده",
        taskDescription: "",
        comment: "سمپاشی منطقه 3 انجام شد و مشکلی در روند انجام کار نبود.",
        backupAction: "ماشین سم پاشی شماره 3",
      },
      {
        id: "asdcin",
        name: "سعید صمیمی",
        title: "ناحیه 3",
        priority: "فوری - مهم",
        sendDate: "1402/03/06",
        sendTime: "20:30",
        seenDate: "1402/03/07",
        seenTime: "08:00",
        doTime: "12:00 - 13:30",
        status: "انجام شده",
        taskDescription: "",
        comment: "سمپاشی منطقه 3 انجام شد و مشکلی در روند انجام کار نبود.",
        backupAction: "ماشین سم پاشی شماره 3",
      },
    ],
  },
];
const StaffMgmtIndividual = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const datePickerRef = useRef();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [date, setDate] = useState(getDateShamsiAirSensors(new Date()));
  const handleDateChange = (value) => {
    setDate(getDateShamsiAirSensors(value));
    const tmp = tasks.filter(
      (item) => item.sendDate === getDateShamsiAirSensors(value)
    );
    setFiltered(tmp);
  };
  const [personName, setPersonName] = useState([]);
  const [search, setSearch] = useState("");
  const [newTask, setNewTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const { id } = useParams();
  const selectedStaff = staffData.find((item) => item.id === id);
  const [tasks, setTasks] = useState([...selectedStaff.tasks]);
  const [filtered, setFiltered] = useState(tasks);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const tmp = tasks.filter((item) => item.title.includes(value));
    setFiltered(tmp);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const tmp = tasks.filter((item) => item.status === value);
    setFiltered(tmp);
  };
  return (
    <>
      <MobileBottomSidebar
        is_open={newTask}
        setOpen={setNewTask}
        height={"80vh"}
        initial_height="0px"
      >
        <Box minWidth="100%" height="83vh">
          <Typography variant="h3" color="fontColor.main">
            ثبت وظیفه
          </Typography>
          <Box
            width="100%"
            height="95%"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
          >
            <RecordTaskMob
              setNewTask={setNewTask}
              setTasks={setTasks}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          </Box>
        </Box>
      </MobileBottomSidebar>
      <Box
        pt="1rem"
        sx={{ overflowY: "auto", maxHeight: "calc(100dvh - 112px)" }}
      >
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <FlexBetween
            sx={{
              transition: "all 0.2s ease",

              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              height: "30px",
            }}
          >
            <InputBase
              disableUnderline
              placeholder="جست و جو"
              value={search}
              onChange={handleSearch}
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${main} !important`,
                  },
                },
                height: "30px",
                width: "158px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <Box
            sx={{
              padding: "0 1rem",
              bottom: 20,
              width: "163px",
              height: "30px",
              background: "transparent",
              // zIndex: 1000,
              borderRadius: "8px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
              },
              "& .rmdp-wrapper": {
                backgroundColor: theme.palette.background.third,
                // width: "250px",
                borderRadius: "8px",
                overflow: "hidden",
              },
              "& .rmdp-container": {
                width: "100%",
                display: "block",
              },
              "& .rmdp-day": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-arrow": {
                borderColor: theme.palette.fontColor.main,
              },
              "& .rmdp-week-day": {
                color: theme.palette.fontColor.alt,
              },
              "& .rmdp-header-values span": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-panel-header": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-ep-arrow": {
                borderBottom: "none",
                borderTop: "none",
              },
              "& .ep-arrow::after": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-ep-shadow:after": {
                boxShadow: "none",
              },
              "& .rmdp-month-picker": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-year-picker": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-input": {
                color: "white !important",
                fontFamily: "YekanBakhFaNum-Regular",
              },
              "& .rmdp-top-class": {
                backgroundColor: theme.palette.background.third,
                color: "#fff",
              },
              "& .rmdp-day.rmdp-today span": {
                backgroundColor: "rgba(42,128,244,0.5)",
              },
              "& .rmdp-day.rmdp-selected span:not(.highlight)": {
                backgroundColor: theme.palette.primary.alt,
              },
              "& .rmdp-day .sd": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-week .rmdp-week-day": {
                color: theme.palette.fontColor.alt,
              },
              "& .rmdp-header-values": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-shadow": {
                boxShadow: "none",
              },
              "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover":
                {
                  backgroundColor: theme.palette.primary.alt,
                },
            }}
          >
            <DatePicker
              render={
                <div
                  onClick={() => datePickerRef.current.openCalendar()}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    gap: "0.5rem",
                  }}
                >
                  <img
                    src={mode === "dark" ? calendar : calendarLight}
                    alt="calendar icon"
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  <div>{date}</div>
                </div>
              }
              calendarPosition={`bottom-center`}
              className="bg-dark rmdp-prime"
              calendar={persian}
              locale={persian_fa}
              weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
              value={date}
              onChange={handleDateChange}
              ref={datePickerRef}
            />
          </Box>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            IconComponent={ListOutlined}
            sx={{
              width: "120px",
              height: "30px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              boxShadow: "none !important",
              "& .MuiList-root ": {
                padding: "0 !important",
                background: "#f00",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
          >
            {names.map((name) => (
              <StyledMenuList
                sx={{ display: "flex", alignItems: "center" }}
                key={name}
                value={name}
              >
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuList>
            ))}
          </Select>
        </Stack>
        <WidgetWrapper mb="0.5rem" mx={"0.5rem"}>
          <FlexBetween>
            <Stack direction="row" gap="0.5rem" sx={{ alignItems: "center" }}>
              <Box
                width="56px"
                height="56px"
                component="img"
                src={Pic1}
                borderRadius="50%"
              />
              <Typography variant="smallestTextPrimary" color={main}>
                {selectedStaff.name}
              </Typography>
            </Stack>
            <Rating
              size="small"
              name="read-only"
              value={selectedStaff.score}
              readOnly
              // sx={{ direction: "ltr" }}
            />
          </FlexBetween>

          <AnimatedProgressBar
            number={70}
            width={157}
            height={163}
            title="%70"
            subTitle=""
            color={theme.palette.others.third}
            dot={false}
            secondColor={theme.palette.background.forth}
          />
          {/* <Box>
            </Box> */}
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <Box display="flex" alignItems="center" gap="0.2rem">
              <Typography variant="smallestTextSecondary" mt="3px" color={alt}>
                کل:
              </Typography>
              <Typography variant="h3" color={main}>
                35
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="0.2rem">
              <Typography variant="smallestTextSecondary" mt="3px" color={alt}>
                انجام شده:
              </Typography>
              <Typography variant="h3" color={main}>
                28
              </Typography>
            </Box>
          </Box>
        </WidgetWrapper>
        <WidgetWrapper mb="0.5rem" mx={"0.5rem"}>
          {filtered.map((item) => {
            return (
              <TaskItem
                item={item}
                handleClickTask={() => {
                  setSelectedTask(item);
                  setNewTask(true);
                }}
              />
            );
          })}
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default StaffMgmtIndividual;
