import { CacheProvider } from "@emotion/react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import * as yup from "yup";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import FlexBetween from "components/FlexBetween/FlexBetween";
const StyledTextarea = styled(TextareaAutosize)`
  resize: none;
`;
const StyledInput = styled("input")({
  display: "none",
});

const FileUploader = () => {
  const theme = useTheme();
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // Do something with the uploaded file
    console.log("fileeeeeeeeee =====>");
  };

  return (
    <Box
      sx={{
        width: "167px",
        height: "167px",
        backgroundColor: theme.palette.background.third,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <label htmlFor="file-upload">
        <StyledInput id="file-upload" type="file" onChange={handleFileUpload} />
        <Box
          component="div"
          sx={{
            borderRadius: "3px",
            color: "white",
            cursor: "pointer",
          }}
        >
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M24.0003 26.6667C26.9458 26.6667 29.3337 24.2789 29.3337 21.3333C29.3337 18.3878 26.9458 16 24.0003 16C21.0548 16 18.667 18.3878 18.667 21.3333C18.667 24.2789 21.0548 26.6667 24.0003 26.6667Z"
                stroke="#C4C3D9"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M34.6663 5.33331H23.9997C10.6663 5.33331 5.33301 10.6666 5.33301 24V40C5.33301 53.3333 10.6663 58.6666 23.9997 58.6666H39.9997C53.333 58.6666 58.6663 53.3333 58.6663 40V26.6666"
                stroke="#C4C3D9"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M42 13.3333H56.6667"
                stroke="#C4C3D9"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M49.333 20.6667V6"
                stroke="#C4C3D9"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M7.12012 50.5333L20.2668 41.7067C22.3734 40.2933 25.4134 40.4533 27.3068 42.08L28.1868 42.8533C30.2668 44.64 33.6268 44.64 35.7068 42.8533L46.8001 33.3333C48.8801 31.5467 52.2401 31.5467 54.3201 33.3333L58.6668 37.0667"
                stroke="#C4C3D9"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        </Box>
      </label>
    </Box>
  );
};
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "5px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const handleSubmit = () => {};
const RecordEventsSchema = yup.object().shape({
  // username: yup.string().required("required"),
  // password: yup.string().required("required"),
});
const RecordEventsForm = ({ setStep }) => {
  const theme = useTheme();

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="1376px"
        height="300px"
      >
        <form onSubmit={handleSubmit} validationSchema={RecordEventsSchema}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="1rem"
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    "& .MuiInputBase-root ": {
                      height: "45px !important",
                    },
                  }}
                >
                  <CssTextField
                    label="داشت"
                    variant="outlined"
                    style={{ width: "250px" }}
                  />
                  <CssTextField
                    label="اقدام انجام شده"
                    variant="outlined"
                    style={{ width: "250px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",

                    mt: "1rem",
                    gap: "1rem",
                    "& .MuiInputBase-root ": {
                      height: "45px !important",
                    },
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <FormControl
                      sx={{
                        minWidth: 250,
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-input": {
                          height: "5px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {age === "" ? (
                        <InputLabel
                          disableAnimation
                          shrink={false}
                          focused={false}
                          id="item_type_label"
                        >
                          تاریخ انجام کار
                        </InputLabel>
                      ) : null}

                      <Select
                        id="item_type"
                        labelId="item_type_label"
                        value={age}
                        onChange={handleChange}
                      >
                        <MenuItem value={false}>Private</MenuItem>
                        <MenuItem value={true}>Public</MenuItem>
                      </Select>
                    </FormControl>
                    <CssTextField
                      sx={{
                        mt: "1rem",
                      }}
                      label="ساعت انجام کار"
                      variant="outlined"
                      style={{ width: "250px" }}
                    />
                  </Box>
                  <StyledTextarea
                    maxRows={3}
                    placeholder="توضیحات"
                    style={{
                      padding: "0.5rem",
                      width: "250px",
                      height: "106px",
                      border: "1px solid white",
                      background: theme.palette.background.second,
                      borderRadius: "8px",
                      color: theme.palette.fontColor.main,
                    }}
                  />
                </Box>
              </Box>
              <FileUploader />
            </Box>

            <Box display="flex" gap="1rem" mt="2.5rem">
              <Box
                sx={{
                  width: "250px",
                  height: "44px",

                  borderRadius: "8px",
                  backgroundColor: theme.palette.others.first,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h4">ثبت </Typography>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default RecordEventsForm;
