import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";

Chart.register(...registerables);
Chart.register(annotationPlugin);
const PlantChart = ({
  th1,
  th2,
  fillColor,
  min,
  max,
  stepSize,
  thv1,
  thv2,
  sensorData1,
  sensorData2,
}) => {
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  const thresholdValue = 30;
  const firstDate = useSelector((state) => state.sensors.date1);
  const secondDate = useSelector((state) => state.sensors.date2);

  function areDatesEqual(date1, date2) {
    return (
      date1.year === date2.year &&
      date1.month === date2.month &&
      date1.day === date2.day
    );
  }
  const chartData = sensorData1 ? sensorData1 : [0, 0, 0, 0, 0, 0, 0];
  const chartData2 = sensorData2 ? sensorData2 : [0, 0, 0, 0, 0, 0, 0];
  const chartBarData = [20, 20, 20, 20, 20, 20, 20];
  const dataLabels = [
    "01/01",
    "01/02",
    "01/03",
    "01/04",
    "01/05",
    "01/06",
    "01/07",
  ];
  const backgroundcolor = [];
  for (let i = 0; i < chartData.length; i++) {
    if (chartData[i] < 30) {
      backgroundcolor.push("rgba(47,128,237,0.3");
    }
    if (chartData[i] >= 30) {
      backgroundcolor.push("rgba(252,73,73,0.3");
    }
  }
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p1.parsed.y > 30 || ctx.p0.parsed.y > 30 ? value : undefined;
  const data = {
    labels: dataLabels,
    datasets: [
      // {
      //   type: "bar",
      //   label: "First dataset",
      //   data: chartBarData,
      //   barThickness: 30,
      //   backgroundColor: "rgba(42,128,244,0.1)",
      //   options: {
      //     tooltips: { enabled: false },
      //   },
      // },
      {
        lineTension: 0.4,
        label: "Second dataset",
        data: chartData,
        showLine: true,
        fill: true,
        //  {
        //   target: {
        //     value: 30,
        //   },
        //   above: "red",
        //   below: "green",
        // },
        backgroundColor: backgroundcolor,
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          console.log("val", value);
          return value > 30 ? "blue" : theme.palette.others.first;
        },
        // segment: {
        //   backgroundColor: (ctx) =>
        //     skipped(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3") ||
        //     down(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3"),
        //   borderColor: (ctx) =>
        //     skipped(ctx, fillColor ? fillColor : theme.palette.others.second) ||
        //     down(ctx, fillColor ? fillColor : theme.palette.others.second),
        //   // borderDash: (ctx) => skipped(ctx, [6, 6]),
        //   // fill: {
        //   //   target: {
        //   //     value: 30,
        //   //   },
        //   //   above: "red",
        //   //   below: "green",
        //   // },
        // },
        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > 30 ? "rgba(255,0,0,0.3)" : "green";
        },
        pointBackgroundColor: "red",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
      {
        lineTension: 0.4,
        label: "Second dataset",
        data: chartData2,
        showLine: true,
        fill: true,
        //  {
        //   target: {
        //     value: 30,
        //   },
        //   above: "red",
        //   below: "green",
        // },
        backgroundColor: backgroundcolor,
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          console.log("val", value);
          return value > 30 ? "blue" : "#3DCCB2";
        },
        // segment: {
        //   backgroundColor: (ctx) =>
        //     skipped(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3") ||
        //     down(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3"),
        //   borderColor: (ctx) =>
        //     skipped(ctx, fillColor ? fillColor : theme.palette.others.second) ||
        //     down(ctx, fillColor ? fillColor : theme.palette.others.second),
        //   // borderDash: (ctx) => skipped(ctx, [6, 6]),
        //   // fill: {
        //   //   target: {
        //   //     value: 30,
        //   //   },
        //   //   above: "red",
        //   //   below: "green",
        //   // },
        // },
        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > 30 ? "rgba(255,0,0,0.3)" : "green";
        },
        pointBackgroundColor: "red",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };
  return (
    <Box
      mt="0.5rem"
      sx={{
        opacity: sensorData1 ? 1 : 0.2,
        width: "100%",
        height: "80%",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "rgba(255,255,255,0.8)",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },
              annotation: {
                annotations: {
                  line1: th1
                    ? {
                        type: "line",
                        yMin: thresholdValue,
                        yMax: thresholdValue,
                        borderColor: theme.palette.others.second,
                        borderWidth: 1,
                      }
                    : null,
                  line2: th2
                    ? {
                        type: "line",
                        yMin: 15,
                        yMax: 15,
                        borderColor: theme.palette.others.second,
                        borderWidth: 1,
                      }
                    : null,
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                min: min,
                max: max,
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.second,
                  borderDash: [3, 3],
                },
                ticks: {
                  stepSize: stepSize,
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  borderDash: [3, 3],
                  color: theme.palette.background.second,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default PlantChart;
