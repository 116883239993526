export const tokenDark = {

 
    primary:{
        100:"#2A80F4",
        200:"#FF7410",
        300:"#3AC6AD",
      
    },
   
    font:{
        50:"#F7F7F7",
        100:"#C4C3D9",
        200:"#1D1D41",
        300:"#5A596F"
    },
    backgroundColor:{
        100:"#141332",
        200:"#1D1D41",
        300:"#38385C",
        400:"#5A596F",
        500:"#CADCE8",
        600:"#EBEEF2",
        700:"#F7F7FC",
        800:"#EDEFF1",
        900:"#FFFFFF"
    },
    others:{
        100:"#2F80ED",
        200:"#FC4949",
        300:"#5FBD15",
        400:"#FFEE00",
        500:"rgba(250,250,252,0.7",
        600:"rgba(29, 29, 65, 0.7)",
        700:"#3DCCB2",
        800:"#A52FED"
    }
};


//function that reverses the color palette

function reverseTokens(tokenDark){
    const reversedTokens = {};
    Object.entries(tokenDark).forEach(([key,val])=>{
        const keys= Object.keys(val);
        const values = Object.values(val);
        const length = keys.length;
        const reversedObj = {};
        for(let i=0;i<length;i++){
            reversedObj[key[i]] = values[length - i - 1];
        }
        reversedTokens[key]= reversedObj;
    });
    return reversedTokens;
}
export const tokensLight = reverseTokens(tokenDark);

//mui theme settings

export const themeSettings = (mode)=>{
    return{
        components: {
            MuiTypography: {
                styleOverrides: {
                    root: {
                        userSelect: "none"
                    }
                }
            }
        },
        
        palette:{
            mode:mode,
            ...(mode ==="dark")?{
                //palette values for dark mode
                primary:{
                    ...tokenDark.primary,
                  
                    main:tokenDark.primary[100],
                    alt:tokenDark.primary[200],
                    third:tokenDark.primary[300]
                   
                },
             
              
                background:{
                    first:tokenDark.backgroundColor[100],
                    second:tokenDark.backgroundColor[200],
                   third:tokenDark.backgroundColor[300],
                   forth:tokenDark.backgroundColor[400],
                },
                fontColor:{
                    main:tokenDark.font[50],
                    alt:tokenDark.font[100],
                    third:tokenDark.font[300]
                },
                others:{
                    first: tokenDark.others[100],
                    second:tokenDark.others[200],
                    third:tokenDark.others[300],
                    forth:tokenDark.others[400],
                    fifth:tokenDark.others[600],
                    sixth:tokenDark.others[700],
                    seventh:tokenDark.others[800],
                }
            }:{
                //palette values for light mode
                primary:{
                    ...tokensLight.primary,
                    main:tokenDark.primary[100],
                    alt:tokenDark.primary[200],
                    third:tokenDark.primary[300]
                },
               
              
                background:{
                    first:tokenDark.backgroundColor[500],
                    second:tokenDark.backgroundColor[900],
                   third:tokenDark.backgroundColor[800],
                   forth:tokenDark.backgroundColor[700],
                  
                },
                fontColor:{
                    main:tokenDark.font[200],
                    alt:tokenDark.font[300],
                    third:tokenDark.font[300]
                },  
                 others:{
                    first: tokenDark.others[100],
                    second:tokenDark.others[200],
                    third:tokenDark.others[300],
                    forth:tokenDark.others[400],
                    fifth:tokenDark.others[500],
                    sixth:tokenDark.others[700],
                    seventh:tokenDark.others[800],
                }
            }
        },
        typography:{
            fontFamily: ["vazir","sans-serif"].join(","),
            fontSize :12,
            h1:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:40,
            },
            h2:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:32,
            },
            h3:{
                fontFamily: ["YekanBakhFaNum-SemiBold","sans-serif"].join(","),
                fontSize:24,
            },
            h4:{
                fontFamily: ["YekanBakhFaNum-SemiBold","sans-serif"].join(","),
                fontSize:18,
            },
            h5:{
                fontFamily: ["YekanBakhFaNum-SemiBold","sans-serif"].join(","),
                fontSize:16,
            },
            h6:{
                fontFamily: ["YekanBakhFaNum-SemiBold","sans-serif"].join(","),
                fontSize:12,
            }, 
            body:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:16,
            },
            subHeading:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:14,
            },
            smallestTextPrimary:{
                fontFamily: ["YekanBakhFaNum-SemiBold","sans-serif"].join(","),
                fontSize:12,
            },
            smallestTextSecondary:{ 
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:12,
            },
            extraSmall:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:10,
            },
            regular:{
                fontFamily: ["YekanBakhFaNum-Regular","sans-serif"].join(","),
                fontSize:20,
            }

        }
    }
}
