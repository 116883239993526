import React, { useEffect } from "react";
import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useSelector } from "react-redux";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const CustomSelectBoxCompare = ({
  items,
  width,
  height,
  setSelectedItem, // Make sure this prop is correctly set
  selectedItem, // Make sure this prop is correctly set
}) => {
  useEffect(() => {
    // You can add any necessary side effects here
  }, [selectedItem]);
  console.log(
    "🚀 ~ file: CutomSelecBoxCompare.jsx:24 ~ selectedItem:",
    selectedItem
  );

  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0, minWidth: 100 }}>
        <Select
          value={selectedItem} // Ensure this matches the selectedItem prop
          onChange={(e) => setSelectedItem(e.target.value)} // Check the event handler
          // displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "8px",
            width,
            height,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          {items.map((item, index) => (
            <MenuItem key={index} value={index + 1}>
              گاو {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CacheProvider>
  );
};

export default CustomSelectBoxCompare;
