import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import WeatherImage from "../../../assets/cloudyWeather.png";
import { useSelector } from "react-redux";
import W1 from "../../../assets/WeatherIcons/static/cloudy-day-1.svg";
import W2 from "../../../assets/WeatherIcons/static/cloudy-night-2.svg";
import W3 from "../../../assets/WeatherIcons/static/cloudy-day-2.svg";
import W4 from "../../../assets/WeatherIcons/static/rainy-1.svg";
import W5 from "../../../assets/WeatherIcons/static/snowy-2.svg";
import W6 from "../../../assets/WeatherIcons/static/day.svg";
import W7 from "../../../assets/WeatherIcons/static/rainy-7.svg";
import { getDateShamsiAirSensors, getDayName, getMonthDayT } from "Utils/Utils";
import FlexBetween from "components/FlexBetween/FlexBetween";
import HourlyWeather from "components/HourlyWeather/HourlyWeather";
import WidgetWrapper from "components/WidgetWrapper";
import HourlyWeatherMob from "mob/componentsMob/HourlyWeatherMob/HourlyWeatherMob";
import DailyMob from "mob/componentsMob/DailyMob/DailyMob";
import ArrowIcon from "../../../assets/direction.png";
import CustomGaugeLetter from "components/CustomGaugeLetter/CustomGaugeLetter";
import { DonutElement, DonutLabel, DonutMultiple } from "react-donut-component";
import Sunset from "components/Sunset/Sunset";
import AirSensorJ from "components/AirSensorJ/AirSensorJ";
import UvIndex from "components/UvIndex/UvIndex";
import { weatherData } from "Mock/WeatherData";

const WeatherSMob = () => {
  const getDegree = (direction) => {
    switch (direction) {
      case "WEST":
        return "270deg";
      case "EAST":
        return "90deg";

      case "NORTH":
        return "0deg";
      case "SOUTH":
        return "180deg";

      case "NORTHWEST":
        return "315deg";
      case "NORTHEAST":
        return "45deg";
      case "SOUTHEAST":
        return "135deg";
      case "SOUTHWEST":
        return "225deg";

      default:
        break;
    }
  };
  const theme = useTheme();

  const [daySelected, setDaySelected] = useState(0);

  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  // const weatherData = useSelector((state) => state.weather.weather.weatherData);

  console.log(
    "🚀 ~ file: Weather.jsx:49 ~ Weather ~ weatherData:",
    weatherData
  );

  // const result = weatherData.reduce(
  //   (acc, obj) => {
  //     // Update minimum minTemperature
  //     if (obj.values.minTemperature < acc.minMinTemperature) {
  //       acc.minMinTemperature = obj.values.minTemperature;
  //     }

  //     // Update maximum maxTemperature
  //     if (obj.values.maxTemperature > acc.maxMaxTemperature) {
  //       acc.maxMaxTemperature = obj.values.maxTemperature;
  //     }

  //     return acc;
  //   },
  //   { minMinTemperature: Infinity, maxMaxTemperature: -Infinity }
  // );

  // const minMinTemperature = result.minMinTemperature;
  // const maxMaxTemperature = result.maxMaxTemperature;

  function getCurrentTime() {
    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, "0");
    const minutes = String(currentTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentTime.getSeconds()).padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  }
  const currentTime = getCurrentTime();
  // const day =
  //   currentTime < weatherData[0].values.sunset &&
  //   currentTime > weatherData[0].values.sunrise
  //     ? true
  //     : false;
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        // overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box component="img" src={WeatherImage} width="100vw" height={"90vh"} />
      <Box
        sx={{
          position: "absolute",
          overflowY: "auto",
          height: "85%",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <FlexBetween sx={{ flexDirection: "column", top: "22px" }}>
          <Stack direction="row-reverse">
            <Typography
              fontSize="80px"
              fontFamily="YekanBakhFaNum-Regular"
              color={main}
            >
              {Math.round(weatherData[daySelected].temp)}
            </Typography>
            <Typography fontSize={32}>°</Typography>
          </Stack>
          <FlexBetween
            sx={{
              alignItems: "center",

              mt: "-32px",
            }}
          >
            <Stack direction="row-reverse">
              <Typography
                fontSize="20px"
                fontFamily="YekanBakhFaNum-Regular"
                color={main}
              >
                {Math.round(weatherData[daySelected].min)}
              </Typography>
              <Typography>°</Typography>
            </Stack>
            <Stack direction="row-reverse">
              <Typography
                fontSize="32px"
                fontFamily="YekanBakhFaNum-Regular"
                color={main}
              >
                {Math.round(weatherData[daySelected].max)}
              </Typography>
              <Typography fontSize={theme.typography.smallestTextPrimary}>
                °
              </Typography>
            </Stack>
          </FlexBetween>
          <Typography
            variant="h4"
            color={main}
            sx={{ direction: "rtl", mt: "-4px" }}
          >
            20:30 - 1402/01/09
            {/* {getDateShamsiAirSensors(weatherData[daySelected].epochDateTime)} */}
          </Typography>
        </FlexBetween>

        <HourlyWeatherMob
          dataHourly={weatherData[daySelected].children}
          daySelected={daySelected}
        />
        <WidgetWrapper
          sx={{
            mt: "12px",
            background: " rgba(29, 29, 65, 0.7)",
            backdropFilter: "blur(7.5px)",
            width: "336px",
          }}
        >
          {weatherData.map((item, index) => (
            <DailyMob
              min={item.min}
              max={item.max}
              minMinTemperature={30}
              maxMaxTemperature={43}
              // min={item.values.minTemperature}
              // max={item.values.maxTemperature}
              // minMinTemperature={minMinTemperature}
              // maxMaxTemperature={maxMaxTemperature}
              dayName={item.day}
              // monthDay={getMonthDayT(item.epochDateTime)}
              condition={item.status}
              // day={day}
              index={index}
              mob
            />
          ))}
        </WidgetWrapper>
        <Box
          mt="12px"
          width="336px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            p="9.5px 11.5px"
            width="162px"
            height="116px"
            sx={{
              backgroundColor: "rgba(29, 29, 65, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={400}
                color={main}
              >
                باد
              </Typography>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={600}
                color={main}
              >
                {(
                  Math.round(weatherData[daySelected].windSpeed * 100) / 100
                ).toFixed(2)}
                km
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                width="50px"
                height="50px"
                sx={{
                  borderRadius: "50%",
                  border: `4px solid #1d1d41`,
                  position: "relative",
                  mt: "-0.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ position: "absolute", top: "0%", right: "43%" }}
                  fontSize="8px"
                >
                  N
                </Typography>
                <Typography
                  sx={{
                    position: "absolute",
                    bottom: "0%",
                    right: "43%",
                  }}
                  fontSize="8px"
                >
                  S
                </Typography>

                <Box
                  component="img"
                  src={ArrowIcon}
                  sx={{
                    rotate: getDegree(weatherData[daySelected].windDirection),
                    zIndex: 1000,
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            p="9.5px 11.5px"
            width="162px"
            height="116px"
            sx={{
              backgroundColor: "rgba(29, 29, 65, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={400}
                color={main}
              >
                فشار
              </Typography>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={600}
                color={main}
              >
                {(weatherData[daySelected].atmosphericPressure / 1000).toFixed(
                  3
                )}
                bar
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomGaugeLetter
                cutoff={"90%"}
                value={
                  Math.round(weatherData[daySelected].atmosphericPressure) /
                  1000
                }
                units={"bar"}
                max={20}
                color={theme.palette.others.third}
              />
            </Box>
          </Box>
        </Box>
        <Box
          mt="12px"
          width="336px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            p="9.5px 11.5px"
            width="162px"
            height="116px"
            sx={{
              backgroundColor: "rgba(29, 29, 65, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={400}
                color={main}
              >
                رطوبت
              </Typography>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={600}
                color={main}
              >
                {Math.round(weatherData[daySelected].humidity)}%
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                <DonutMultiple size={"75px"}>
                  <DonutElement color={theme.palette.background.second}>
                    {Math.round(weatherData[daySelected].humidity)}
                  </DonutElement>
                  <DonutElement
                    color={theme.palette.others.third}
                    style={{ borderRadius: "20px" }}
                  >
                    {100 - Math.round(weatherData[daySelected].humidity)}
                  </DonutElement>
                  <DonutLabel>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="h4">
                        {Math.round(weatherData[daySelected].humidity)}
                      </Typography>
                      <Typography
                        fontSize={theme.typography.smallestTextPrimary}
                      >
                        %
                      </Typography>
                    </Box>
                  </DonutLabel>
                </DonutMultiple>
              </Box>
            </Box>
          </Box>
          <Box
            p="9.5px 11.5px"
            width="162px"
            height="116px"
            sx={{
              backgroundColor: "rgba(29, 29, 65, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
            }}
          >
            <FlexBetween>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={400}
                color={main}
              >
                بارندگی
              </Typography>
              <Typography
                fontSize={theme.typography.smallestTextPrimary}
                fontWeight={600}
                color={main}
              >
                {Math.floor(weatherData[daySelected].precipitation).toFixed(2)}
                mm
              </Typography>
            </FlexBetween>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomGaugeLetter
                cutoff={"90%"}
                value={Math.floor(
                  weatherData[daySelected].precipitation
                ).toFixed(2)}
                units={"mm"}
                max={20}
                color={theme.palette.others.third}
              />
            </Box>
          </Box>
        </Box>
        <WidgetWrapper
          sx={{
            mt: "12px",
            background: " rgba(29, 29, 65, 0.7)",
            backdropFilter: "blur(7.5px)",
            width: "336px",
          }}
        >
          <Sunset sunset={"19:20"} sunrise={"5:12"} />
        </WidgetWrapper>
        <WidgetWrapper
          sx={{
            mt: "12px",
            background: " rgba(29, 29, 65, 0.7)",
            backdropFilter: "blur(7.5px)",
            width: "336px",
          }}
        >
          <AirSensorJ />
        </WidgetWrapper>
        <WidgetWrapper
          sx={{
            mt: "12px",
            mb: "50px",
            background: " rgba(29, 29, 65, 0.7)",
            backdropFilter: "blur(7.5px)",
            width: "336px",
          }}
        >
          <UvIndex />
        </WidgetWrapper>
      </Box>
    </Box>
  );
};

export default WeatherSMob;
