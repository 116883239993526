import { AddOutlined, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  InputBase,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import ArrowIcon from "../../assets/arrow.png";
import { useSelector } from "react-redux";
import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
import RecordSensorFormMob from "./RecordSensorFormMob";
import CenterStack from "components/styledComponents/CenterStack";

const mockData = [
  {
    deviceName: "شماره یک",
    status: "ACTIVE",
    deviceType: "سنسور",
    coordinates: "",
    serialNumber: "PTR-30505",
    lastSendData: "1402/02/01 - 20:30",
    weight: "20",
    battery: "LOW",
    anntena: "LOWEST",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره دو",
    status: "ACTIVE",
    deviceType: "گردنبند",
    coordinates: "",
    serialNumber: "PTR-31525",
    lastSendData: "1402/02/02 - 20:30",
    weight: "15",
    battery: "LOWEST",
    anntena: "HIGH",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره سه",
    status: "INACTIVE",
    deviceType: "سنسور",
    coordinates: "",
    serialNumber: "PTR-30525",
    lastSendData: "1402/02/05 - 11:30",
    weight: "20",
    battery: "HIGH",
    anntena: "LOW",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
  {
    deviceName: "شماره یک",
    status: "INACTIVE",
    deviceType: "عملگر",
    coordinates: "",
    serialNumber: "PTR-35227",
    lastSendData: "1402/02/09 - 17:30",
    weight: "51",
    battery: "HIGH",
    anntena: "HIGH",
    description: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
    periodSendData: "12 ساعته",
    recordDate: "1402/02/29",
  },
];
const sortingOptions = {
  date: { active: false, label: "تاریخ ثبت" },
  actives: { active: false, label: "فعال ها" },
  lowestBattery: { active: false, label: "کمترین میزان باتری" },
  inactives: { active: false, label: "غیرفعال ها" },
};

const SensorItem = ({ item }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const dividerColor = theme.palette.background.forth;
  const getBatteryStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return EmptyIcon;
      case "LOW":
        return Full3Icon;
      case "HIGH":
        return FullIcon;
      default:
        break;
    }
  };
  const getSignalStatus = (status) => {
    switch (status) {
      case "LOWEST":
        return WifiIcon;
      case "LOW":
        return Wifi1Icon;
      case "HIGH":
        return Wifi3Icon;
      default:
        break;
    }
  };
  return (
    <Box
      p="0.7rem"
      sx={{
        "&:not(:last-child)": {
          borderBottom: `1px solid ${dividerColor}`,
        },
      }}
    >
      <Typography variant="smallestTextPrimary" color={main}>
        {item.deviceType + " " + item.deviceName}
      </Typography>

      <FlexBetween>
        <FlexBetween alignItems={"center"} gap={"0.3rem"}>
          <Box component={"img"} src={getBatteryStatus(item.battery)} />
          <Box component={"img"} src={getSignalStatus(item.anntena)} />
        </FlexBetween>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="4.8124rem"
          height="1.4375rem"
          borderRadius="4px"
          sx={{
            backgroundColor:
              item?.status === "INACTIVE"
                ? theme.palette.others.third
                : theme.palette.others.second,
          }}
        >
          <Typography
            color={theme.palette.background.second}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {item.status === "INACTIVE" ? "غیرفعال" : "فعال"}
          </Typography>
        </Box>
      </FlexBetween>
    </Box>
  );
};
const RecordSensorsMob = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [activeSorting, setActiveSorting] = useState(sortingOptions);
  const [newSensor, setNewSensor] = useState(false);
  const [sortedData, setSortedData] = useState(mockData);
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const tmp = sortedData.filter(
      (item) =>
        item.name.toUpperCase().includes(value.toUpperCase()) ||
        item.title.toUpperCase().includes(value.toUpperCase())
    );
    setSortedData(tmp);
  };
  const [selectItem, setSelectItem] = useState("تاریخ ثبت");

  return (
    <>
      <MobileBottomSidebar
        is_open={newSensor}
        setOpen={setNewSensor}
        height={"calc(100dvh - 120px"}
        initial_height="0px"
      >
        <Box minWidth="100%" height="100%">
          <Typography mb="1rem" variant="h3" color="fontColor.main">
            ثبت سنسور
          </Typography>
          <Box
            width="100%"
            height="calc(100% - 45px)"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            sx={{
              overflowY: "auto",
            }}
          >
            <RecordSensorFormMob setNewSensor={setNewSensor} />
          </Box>
        </Box>
      </MobileBottomSidebar>
      <Box
        p="1rem 0.5rem"
        sx={{ overflowY: "auto", maxHeight: "calc(100dvh - 112px)" }}
      >
        <FlexBetween
          sx={{
            transition: "all 0.2s ease",
            alignItems: "center",
            border: `1px solid ${theme.palette.fontColor.alt}`,
            borderRadius: "8px",
            width: "100%",
            height: "30px",
            mb: "1rem",
          }}
        >
          <InputBase
            disableUnderline
            placeholder="جست و جو"
            value={search}
            onChange={handleSearch}
            id="inputSearchRemote"
            sx={{
              paddingRight: "5px",
              backgroundColor: "inherit",
              "& .MuiInputBase-input": {
                "::placeholder": {
                  color: `${main} !important`,
                },
              },
              height: "30px",
              width: "158px",
              borderRadius: "8px",
              color: theme.palette.fontColor.alt,
              outline: "none",
              borderBottom: "none",
            }}
          />
          <Search sx={{ ml: "0.2rem" }} />
        </FlexBetween>
        <WidgetWrapper mb="0.5rem" mx={"0.5rem"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h4" color={main}>
              سنسورها
            </Typography>
            <Box
              // width="121px"
              px="1rem"
              height="36px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                p: "0 0.7rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setNewSensor(true);
              }}
            >
              <Stack direction="row" alignItems="center" gap="0.2rem">
                <AddOutlined sx={{ width: "24px", height: "24px" }} />
                <Typography>سنسور جدید</Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" gap="9px" m="12px 0">
            <CenterStack
              sx={{
                padding: "4px 4px",
                width: "60px",
                height: "32px",
                borderRadius: "8px",
                whiteSpace: "nowrap",
                background:
                  selectItem === "تاریخ ثبت"
                    ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                    : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
              }}
              onClick={() => setSelectItem("تاریخ ثبت")}
            >
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                تاریخ ثبت
              </Typography>
            </CenterStack>
            <CenterStack
              sx={{
                padding: "4px 4px",
                width: "60px",
                height: "32px",
                borderRadius: "8px",
                whiteSpace: "nowrap",
                background:
                  selectItem === "فعال ها"
                    ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                    : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
              }}
              onClick={() => setSelectItem("فعال ها")}
            >
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                فعال ها
              </Typography>
            </CenterStack>
            <CenterStack
              sx={{
                padding: "4px 8px",
                width: "106px",
                height: "32px",
                borderRadius: "8px",
                whiteSpace: "nowrap",
                background:
                  selectItem === "کمترین میزان باتری"
                    ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                    : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
              }}
              onClick={() => setSelectItem("کمترین میزان باتری")}
            >
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                کمترین میزان باتری
              </Typography>
            </CenterStack>
            <CenterStack
              sx={{
                padding: "4px 4px",
                width: "70px",
                height: "32px",
                borderRadius: "8px",
                whiteSpace: "nowrap",
                background:
                  selectItem === "غیر فعال ها"
                    ? "linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%), linear-gradient(180deg, #2A80F4 0%, #1D1D41 100%)"
                    : "linear-gradient(180deg, #38385C 0%, rgba(56, 56, 92, 0.00) 100%)",
              }}
              onClick={() => setSelectItem("غیر فعال ها")}
            >
              <Typography
                variant="smallestTextSecondary"
                color="fontColor.main"
              >
                غیر فعال ها
              </Typography>
            </CenterStack>
          </Stack>
          {sortedData.map((item) => (
            <SensorItem item={item} />
          ))}
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default RecordSensorsMob;
