import { Box, styled, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
import GG1Icon1 from "../../../assets/Sensor Icon/G-G 1.png";
import CustomSelectBox from "components/CustomSelectBox/CustomSelectBox";
import FlexBetween from "components/FlexBetween/FlexBetween";
import BoxSensorsManagement from "components/BoxSensorsManagement/BoxSensorsManagement";
import MixedChart from "components/MixedChart/MixedChart";
import WidgetWrapper from "components/WidgetWrapper";

const MapBox = styled(Box)(({ theme, mode }) => ({
  //   position: "absolute",
  width: "100% !important",

  // maxheight: "100%",
  padding: "0px",
  maxHeight: "400px",
  "& .leaflet-left": {
    left: "14px",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-control-zoom": {
    display: "none",
  },
  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
  zIndex: 0,
}));

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const SensorManagementAllMob = () => {
  const token = useSelector((state) => state.global.token);
  const [mapModal, setMapModal] = useState(false);
  const [titleDevice, setTitleDevice] = useState("");

  const lastTimeUpdate = useSelector((state) => state.sensors.lastTimeUpdate);
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:43 ~ SensorsManagementAll ~ lastTimeUpdate:",
    lastTimeUpdate
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (token === null) {
      navigate("/login");
    }
  }, [token]);
  const [typeSelect, setTypeSelect] = useState("WEATHER");
  const [zoneSelect, setZoneSelect] = useState("همه");

  const [cards, setCards] = useState(null);
  console.log(
    "🚀 ~ file: SensorManagementAllMob.jsx:79 ~ SensorManagementAllMob ~ cards:",
    cards
  );

  const data = useSelector(
    (state) => state.sensors.sensorsData.devices
  );
  const [position, setPosition] = useState(
    data.length > 0 ? [data[0].location.x, data[0].location.y] : [31, 53]
  );
  const d = useSelector((state) => state.sensors);
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:57 ~ SensorsManagementAll ~ data:",
    d
  );

  const flattenedData = data.reduce((acc, obj) => {
    Object.keys(obj.data).forEach((key) => {
      acc.push({
        key,
        values: obj.data[key],
        type: obj.type,
        title: obj.title,
        id: obj.id,
      });
    });
    return acc;
  }, []);
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:83 ~ flattenedData ~ flattenedData:",
    flattenedData
  );

  const tselect = flattenedData.slice(0, 4) ? flattenedData.slice(0, 4) : [];
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:80 ~ SensorsManagementAll ~ tselect:",
    tselect
  );

  useEffect(() => {
    if (token === null) {
      navigate("/login");
    } else {
    }
    let xx = data.map((d) => d.location.x);
    let x = xx.reduce((a, b) => a + b) / xx.length;
    let yy = data.map((d) => d.location.y);
    let y = yy.reduce((a, b) => a + b) / yy.length;
    setPosition([x, y]);
  }, [data, token]);

  const allWeatherSensors = data.filter((item) => item.type === "WEATHER");

  const allSoilSensors = data.filter((item) => item.type === "SOIL");

  const allPlantSensors = data.filter((item) => item.type === "PLANT");

  const filterData = (zoneSelect) => {
    if (zoneSelect === "همه") {
      if (typeSelect === "WEATHER") return allWeatherSensors;
      if (typeSelect === "PLANT") return allPlantSensors;
      if (typeSelect === "SOIL") return allSoilSensors;
    } else {
      const result = data.filter(
        (item) => item.zoneTitle === zoneSelect && item.type === typeSelect
      );
      return result;
    }
  };
  const selectedZoneSensors = filterData(zoneSelect);

  const [toggle, setToggle] = useState(false);

  const theme = useTheme();
  const [selectedIcon, setselectedIcon] = useState(null);
  const mode = useSelector((state) => state.global.mode);

  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
  });

  function MultipleMarkers() {
    const theme = useTheme();

    return selectedZoneSensors.map((item, index) => {
      console.log(
        "🚀 ~ file: SensorsManagementAll.jsx:146 ~ returnselectedZoneSensors.map ~ item:",
        item
      );
      const title = item.title;
      let { precipitation_snow, ...newObj } = item.data;

      return (
        <Marker
          eventHandlers={{
            click: (e) => {
              const dataArray = Object.entries(newObj).map(([key, value]) => ({
                key,
                data: value,
              }));
              setTitleDevice(title);
              setselectedIcon(item.id);
              console.log(
                "🚀 ~ file: SensorsManagementAll.jsx:156 ~ dataArray ~ dataArray:",
                dataArray
              );

              setCards(dataArray);
            },
          }}
          key={index}
          position={[item.location.x, item.location.y]}
          icon={
            item.data && Object.keys(item.data).length > 0
              ? getIcon(item, selectedIcon)
              : GG1Icon
          }
        ></Marker>
      );
    });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "70vh",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          position: "relative",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <FlexBetween
          gap="0.5rem"
          sx={{
            position: "absolute",
            zIndex: "1000",
            top: "15px",
            right: "10px",
          }}
        >
          <CustomSelectBox
            width="97px"
            height="40px"
            type={"type"}
            background={"rgba(29, 29, 65, 0.7)"}
            backdropFilter={"blur(7.5px)"}
            typeSelect={typeSelect}
            setTypeSelect={setTypeSelect}
            zoneSelect={zoneSelect}
            setZoneSelect={setZoneSelect}
            setCards={setCards}
            setTitleDevice={setTitleDevice}
          />
          <CustomSelectBox
            width="97px"
            height="40px"
            type={"area"}
            background={"rgba(29, 29, 65, 0.7)"}
            backdropFilter={"blur(7.5px)"}
            typeSelect={typeSelect}
            setTypeSelect={setTypeSelect}
            zoneSelect={zoneSelect}
            setZoneSelect={setZoneSelect}
            setCards={setCards}
            setTitleDevice={setTitleDevice}
          />
        </FlexBetween>
        <Box
          sx={{
            position: "absolute",
            bottom: "5rem",
            zIndex: "1000",
            width: "96vw",
            height: "163px",
            p: "0 2rem",
            display: "flex",
            alignItem: "center",
            justifyContent: "center",
            gap: "0.5rem",
            backgroundColor: "transparent",
            right: "0vw",
            alignItems: "center",
            overflowX: "auto",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {cards
            ? cards.map((item, index) => (
                <Box m={index === 0 ? "0 4rem 0 0" : "0"}>
                  <BoxSensorsManagement
                    type={item.key}
                    data={item.data}
                    color={theme.palette.others.third}
                    toggle={toggle}
                    max={125}
                  />
                </Box>
              ))
            : null}
        </Box>
        <MapBox mode={mode}>
          <MapContainer
            center={position}
            zoom={15}
            style={{
              borderRadius: "8px",
              height: "400px",
              width: "100%",
            }}
          >
            <ChangeView center={position} zoom={16} />
            <TileLayer
              attribution="PTR"
              url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              maxZoom={22}
              maxNativeZoom={16}
            />
            <MultipleMarkers />
          </MapContainer>
        </MapBox>
        {tselect.length !== null ? (
          <>
            <WidgetWrapper mt="1rem">
              <MixedChart
                type={tselect[0] && tselect[0].type ? tselect[0].type : null}
                device={tselect[0] && tselect[0].id ? tselect[0].id : null}
                sensor={tselect[0] && tselect[0].key ? tselect[0].key : null}
              />
            </WidgetWrapper>
            <WidgetWrapper mt="1rem">
              <MixedChart
                type={tselect[1] && tselect[1].type ? tselect[1].type : null}
                device={tselect[1] && tselect[1].id ? tselect[1].id : null}
                sensor={tselect[1] && tselect[1].key ? tselect[1].key : null}
              />
            </WidgetWrapper>
            <WidgetWrapper mt="1rem">
              <MixedChart
                type={tselect[2] && tselect[2].type ? tselect[2].type : null}
                device={tselect[2] && tselect[2].id ? tselect[2].id : null}
                sensor={tselect[2] && tselect[2].key ? tselect[2].key : null}
              />
            </WidgetWrapper>
            <WidgetWrapper m="1rem 0 3rem 0">
              <MixedChart
                type={tselect[3] && tselect[3].type ? tselect[3].type : null}
                device={tselect[3] && tselect[3].id ? tselect[3].id : null}
                sensor={tselect[3] && tselect[3].key ? tselect[3].key : null}
              />
            </WidgetWrapper>
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default SensorManagementAllMob;
