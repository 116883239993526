const HealthStatusData = [
    {
      type: "p",
      code: 420,
      date: "1402/02/16",
      time: "22:30",
      index: 1.8,
      res: "",
      status: "تلیسه",
      l: true,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "s",
      code: 56,
      date: "1402/02/16",
      time: "22:30",
      index: 2,
      res: "",
      status: "آبستن",
      l: true,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "s",
      code: 16,
      date: "1402/02/16",
      time: "22:30",
      index: 3,
      res: "",
      status: "تلیسه",
      l: false,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
    {
      type: "s",
      code: 16,
      date: "1402/02/16",
      time: "22:30",
      index: 3,
      res: "",
      status: "تلیسه",
      l: false,
      desc: "این دستگاه به تازگی نصب شده و نیاز به مراقبت دارد.",
      dueDate: "1402/02/17 - 10:00",
    },
  ];

  export default HealthStatusData