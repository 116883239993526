export const weatherData = [
    {
      day: "شنبه",
      status: "CLEAR",
      temp: 35,
      min: 33,
      max: 37,
      humidity: 40,
      precipitation: 0.1,
      windSpeed: 0.2,
      windDirection: "NORTHEAST",
      atmosphericPressure: 1,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "یک شنبه",
      status: "CLEAR",
      temp: 33,
      min: 31,
      max: 35,
      humidity: 35,
      precipitation: 0.15,
      windSpeed: 0.3,
      windDirection: "NORTH",
      atmosphericPressure: 1.5,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 35,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 35,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "دو شنبه",
      status: "CLEAR",
      temp: 31,
      min: 30,
      max: 32,
      humidity: 46,
      precipitation: 0.1,
      windSpeed: 0.25,
      windDirection: "SOUTH",
      atmosphericPressure: 1.7,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 30,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 32,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 32,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 31,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 32,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "سه شنبه",
      status: "CLEAR",
      temp: 37,
      min: 33,
      max: 40,
      humidity: 50,
      precipitation: 0.17,
      windSpeed: 0.41,
      windDirection: "SOUTHWEST",
      atmosphericPressure: 1.3,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 34,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 33,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 38,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 40,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
      ],
    },
    {
      day: "چهارشنبه",
      status: "CLEAR",
      temp: 40,
      min: 36,
      max: 43,
      humidity: 60,
      precipitation: 0.07,
      windSpeed: 0.43,
      windDirection: "SOUTHWEST",
      atmosphericPressure: 1.1,
      children: [
        {
          values: {
            condition: "CLEAR",
            temperature: 36,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 37,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 41,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 42,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 43,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "ATMOSPHERE",
            temperature: 40,
            precipitation: 0.1,
          },
        },
        {
          values: {
            condition: "CLEAR",
            temperature: 39,
            precipitation: 0.1,
          },
        },
      ],
    },
  ];
  