import { DownloadOutlined, FullscreenOutlined } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import AirChart from "./AirChart";
import LineNormal from "components/Charts/LineNormal";
const RainAir = ({ sensorData }) => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box sx={{ opacity: sensorData ? 1 : 0.2 }}>
      <FlexBetween>
        <Typography
          color={main}
          fontSize={theme.typography.smallestTextSecondary}
        >
          بارندگی (میلی متر)
        </Typography>

        <FlexBetween gap="0.5rem">
          <DownloadOutlined sx={{ cursor: "pointer" }} />
          <FullscreenOutlined sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <LineNormal
        th2={false}
        sensorData={sensorData}
        min={0}
        max={60}
        stepSize={30}
        thv1={30}
      />
    </Box>
  );
};

export default RainAir;
