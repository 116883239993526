import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const CustomSelectBoxCompareType = ({ width, height }) => {
  const [selectedItem, setSelectedItem] = useState("عملکرد");

  useEffect(() => {}, [selectedItem]);
  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0, minWidth: 100 }}>
        <Select
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "8px",
            width,
            height,
            // background,
            // backdropFilter,
            strokeWidth: 0,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          <MenuItem value="عملکرد">عملکرد</MenuItem>
        </Select>
      </FormControl>
    </CacheProvider>
  );
};

export default CustomSelectBoxCompareType;
