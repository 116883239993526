import { Box, FormControl, MenuItem, Select, Typography, styled, useTheme } from '@mui/material';
import { useState } from 'react';

import rtlPlugin from 'stylis-plugin-rtl';
import { useDispatch, useSelector } from 'react-redux';

import { prefixer } from 'stylis';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import FlexBetween from 'components/FlexBetween/FlexBetween';
import { setActiveIndex, setNameIndex } from 'States/remoteSensing';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

const StyledMenuList = styled(MenuItem)(({ theme }) => ({
    /* Use the prop `theme` to apply dynamic styles */
    backgroundColor: theme.palette.background.third,
    boxShadow: 'none !important',
    '& .MuiPaper-root': { background: 'red' },
}));
const SelectRemoteSensing = () => {
    const [openSelect, setOpenSelect] = useState(true);
    const indexes = useSelector((state) => state.remoteSensing.indexes);
    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });
    const [age, setAge] = useState(0);

    const dispatch = useDispatch();
    const handleChange = (event) => {
        setAge(event.target.value);
        dispatch(
            setNameIndex({
                nameIndex: indexes[event.target.value].index,
            }),
        );
        dispatch(
            setActiveIndex({
                activeIndex: event.target.value,
            }),
        );
    };
    const theme = useTheme();
    const mode = useSelector((state) => state.global.mode);
    const activeIndexL = useSelector((state) => state.remoteSensing.activeIndex);

    return (
        <Box
            sx={{
                left: '58.5rem',
                zIndex: 1000,
                minWidth: '200px',
                height: openSelect ? '208px' : '70px',
                borderRadius: '12px',
                transition: 'all 0.5s ease',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                onClick={() => setOpenSelect(!openSelect)}
                sx={{
                    width: '100px',
                    height: '18px',
                    backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                    boxShadow: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '0.5rem 0.5rem 0 0',
                    cursor: 'pointer',
                    transition: 'all 0.5s ease',
                }}
            >
                {openSelect ? <KeyboardArrowDownOutlined /> : <KeyboardArrowUpOutlined />}
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '190px',
                    backgroundColor: mode === 'dark' ? 'rgba(29, 29, 65, 0.7)' : 'rgba(250, 250, 252, 0.7)',
                    backdropFilter: 'blur(7.5px)',
                    boxShadow: 'none',
                    borderRadius: '12px',
                }}
            >
                <CacheProvider value={cacheRtl}>
                    <FormControl variant='standard' sx={{ m: 1, mt: '0.75rem', minWidth: '90%' }}>
                        <Select
                            IconComponent={() => <KeyboardArrowDownOutlined />}
                            disableUnderline={openSelect ? false : true}
                            value={activeIndexL}
                            onChange={handleChange}
                            sx={{
                                ml: '0.5rem',
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#40405e',
                                },
                            }}
                        >
                            {indexes?.map((item, idx) => (
                                <StyledMenuList
                                    value={idx}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        m: '0 5px',

                                        backgroundColor: `${theme.palette.background.third}!important`,
                                        ':hover': {
                                            backgroundColor: `${theme.palette.fontColor.third} !important`,
                                            borderRadius: '4px',
                                        },
                                        '& .muirtl-6hp17o-MuiList-root-MuiMenu-list ': {
                                            background: 'red !important',
                                        },
                                    }}
                                >
                                    <Typography color={theme.palette.fontColor.main} fontSize={theme.typography.body}>
                                        {item.name}
                                    </Typography>
                                </StyledMenuList>
                            ))}
                        </Select>
                    </FormControl>
                    <FlexBetween flexDirection='column' sx={{ opacity: openSelect ? 1 : 0 }}>
                        <FlexBetween sx={{ width: '80%', mb: '8px' }}>
                            <Typography fontSize={theme.typography.smallestTextSecondary}>یکنواختی</Typography>
                            <Typography
                                fontSize={theme.typography.smallestTextSecondary}
                                color={theme.palette.others.third}
                            >
                                -
                            </Typography>
                        </FlexBetween>
                        <FlexBetween sx={{ width: '80%', mb: '8px' }}>
                            <Typography fontSize={theme.typography.smallestTextSecondary}>حداکثر زمین</Typography>
                            <Typography
                                fontSize={theme.typography.smallestTextSecondary}
                                color={theme.palette.others.forth}
                            >
                                {indexes?.[age]?.lastMax}
                            </Typography>
                        </FlexBetween>
                        <FlexBetween sx={{ width: '80%', mb: '8px' }}>
                            <Typography fontSize={theme.typography.smallestTextSecondary}>حداقل زمین</Typography>
                            <Typography
                                fontSize={theme.typography.smallestTextSecondary}
                                color={theme.palette.others.second}
                            >
                                {indexes?.[age]?.lastMin}
                            </Typography>
                        </FlexBetween>
                        <FlexBetween sx={{ width: '80%', mb: '8px' }}>
                            <Typography fontSize={theme.typography.smallestTextSecondary}>میانگین زمین</Typography>
                            <Typography
                                fontSize={theme.typography.smallestTextSecondary}
                                color={theme.palette.others.third}
                            >
                                {indexes?.[age]?.lastMean}
                            </Typography>
                        </FlexBetween>
                        <Box width='80%' mb='8px'>
                            <Typography
                                fontSize={theme.typography.smallestTextPrimary}
                                color={theme.palette.others.first}
                            >
                                <span
                                    style={{
                                        width: '40px',
                                        borderBottom: `1px solid ${theme.palette.others.first}`,
                                    }}
                                >
                                    توضیحات شاخص
                                </span>
                            </Typography>
                        </Box>
                    </FlexBetween>
                </CacheProvider>
            </Box>
        </Box>
    );
};

export default SelectRemoteSensing;
