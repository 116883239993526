import Pic1 from "../assets/StaffImg/1.jpg";
import Pic2 from "../assets/StaffImg/2.jpg";
import Pic3 from "../assets/StaffImg/3.jpg";
import Pic4 from "../assets/StaffImg/4.jpg";
export const TreatmentData = {
  type: "درمان",
  performance: [
    ["05/12", 350],
    ["05/13", 341],
    ["05/14", 312],
    ["05/15", 265],
    ["05/16", 285],
    ["05/17", 221],
    ["05/18", 190],
    ["05/19", 231],
  ],
  status: [[231, 220], "درمان شده ها"],

  livestock: [
    {
      code: 900,
      visitDate: "08:10 - 1402/03/15",
      reason: "تست آبستنی",
      type: "تولید مثل",
      group: "تولید مثل",
      nextTime: "1402/06/15",
      action: Pic1,
      milk: 9,
    },

    {
      code: 899,
      visitDate: "12:17 - 1402/03/26",
      reason: "پلوروپنومونی",
      type: "درمانی",
      group: "تنفسی",
      nextTime: "1402/06/26",
      action: Pic2,
      milk: 8,
    },
    {
      code: 898,
      visitDate: "14:17 - 1402/05/22",
      reason: "پلوروپنومونی",
      type: "تولید مثل",
      group: "تولید مثل",
      nextTime: "1402/08/22",
      action: Pic3,
      milk: 9,
    },
    {
      code: 897,
      visitDate: "18:17 - 1402/05/12",
      reason: "تست آبستنی",
      type: "تولید مثل",
      group: "تنفسی",
      nextTime: "1402/08/12",
      action: Pic4,
      milk: 6,
    },
  ],
};