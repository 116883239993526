import Pic1 from "../assets/StaffImg/8.jpg";
import Pic2 from "../assets/StaffImg/7.jpg";
import Pic3 from "../assets/StaffImg/6.jpg";
import Pic4 from "../assets/StaffImg/5.jpg";
export const MediceneData = {
  type: "دارو",
  performance: [
    ["05/12", 125],
    ["05/13", 132],
    ["05/14", 111],
    ["05/15", 118],
    ["05/16", 99],
    ["05/17", 98],
    ["05/18", 105],
    ["05/19", 121],
  ],
  status: [[121, 125], "تجویز دارو"],

  livestock: [
    {
      code: 900,
      injDate: "1402/03/29",
      row: "-",
      medName: "-",
      dose: "-",
      unitDose: "-",
      consumeWay: "-",
      action: Pic1,
      threatmentResult: "بهبود دام",
    },
    {
      code: 870,
      injDate: "1402/05/14",
      row: "-",
      medName: "-",
      dose: "-",
      unitDose: "-",
      consumeWay: "-",
      action: Pic2,
      threatmentResult: "فوت",
    },
    {
      code: 729,
      injDate: "1402/05/21",
      row: "-",
      medName: "-",
      dose: "-",
      unitDose: "-",
      consumeWay: "-",
      action: Pic3,
      threatmentResult: "بهبود دام",
    },
    {
      code: 211,
      injDate: "1402/04/29",
      row: "-",
      medName: "-",
      dose: "-",
      unitDose: "-",
      consumeWay: "-",
      action: Pic4,
      threatmentResult: "وخیم شدن بیماری",
    },
  ],
};