import { MoreHoriz } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Header from "components/header/Header";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import { useSelector } from "react-redux";
import AlarmPNG from "../assets/AlarmDark.png";
import { useNavigate } from "react-router-dom";
const Alarms = () => {
  const theme = useTheme();
  const recAlerts = useSelector((state) => state.dashboard.alerts);
  console.log("🚀 ~ file: Alarms.jsx:12 ~ Alarms ~ recAlerts:", recAlerts);
  const navigate = useNavigate();
  return (
    <WidgetWrapper
      opc={recAlerts.enabled ? 1 : 1}
      sx={{ position: "relative" }}
    >
      {!recAlerts.enabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,

            width: "100%",
            height: "100%",
            zIndex: 1000,
          }}
        ></Box>
      )}
      <FlexBetween mt="0.4rem">
        <FlexBetween gap="0.5rem">
          <Box component="img" src={AlarmPNG} />
          <Typography variant="body" color={theme.palette.fontColor.alt}>
            {/* {recAlerts.typeName ? recAlerts.typeName : "هشداری نیست"} */}
            لنگش گاو 213
          </Typography>
        </FlexBetween>
        <MoreHoriz
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/alarms")}
        />
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default Alarms;
