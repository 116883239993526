import { Circle, Wallet } from "@mui/icons-material";
import {
  Box,
  Divider,
  Rating,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import CopyRight from "widgets/CopyRight";
import UserImage from "../../assets/StaffImg/4.jpg";
import FarmIcon from "../../assets/Modules/weather1.png";
import FarmIcon2 from "../../assets/Modules/Weather2.png";
import FarmIcon3 from "../../assets/Modules/Weather3.png";
import FarmIcon4 from "../../assets/Modules/Weather4.png";
import PackageItem from "./PackageItem";
import CommentItem from "./commentItem";
import ServiceItem from "./ServiceItem";

const WeatherModules = ({ setWeatherModule }) => {
  const theme = useTheme();
  const blue = theme.palette.others.first;
  const alt = theme.palette.fontColor.alt;

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1235px"
      width="calc(100% - 2rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px">
        <Box
          width="80%"
          height="83vh"
          display="flex"
          flexDirection="column"
          gap="16px"
          sx={{
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <WidgetWrapper width="100%" height="887px">
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              height="139px"
              gap="16px"
            >
              <Box
                component="img"
                src={FarmIcon}
                width="139px"
                height="139px"
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="90%"
                height="100%"
              >
                <FlexBetween>
                  <Stack direction="row" gap="20px" alignItems="center">
                    <Typography variant="h2" color="fontColor.main">
                      هواشناسی
                    </Typography>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <Rating
                        size="medium"
                        name="read-only"
                        value={4}
                        readOnly
                        sx={{ direction: "ltr" }}
                      />
                      <Typography variant="subHeading" color="fontColor.alt">
                        83 نفر
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap="8px">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.99935 8.00016C9.8403 8.00016 11.3327 6.50778 11.3327 4.66683C11.3327 2.82588 9.8403 1.3335 7.99935 1.3335C6.1584 1.3335 4.66602 2.82588 4.66602 4.66683C4.66602 6.50778 6.1584 8.00016 7.99935 8.00016Z"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.7268 14.6667C13.7268 12.0867 11.1601 10 8.0001 10C4.8401 10 2.27344 12.0867 2.27344 14.6667"
                          stroke="#C4C3D9"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <Typography variant="subHeading" color="fontColor.alt">
                        خرید
                      </Typography>
                      <Typography variant="h5" color="fontColor.main">
                        150
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap="8px">
                    <Typography
                      borderBottom={`1px solid ${alt}`}
                      color="fontColor.alt"
                      variant="subHeading"
                      sx={{ cursor: "pointer" }}
                      onClick={() => setWeatherModule((prev) => !prev)}
                    >
                      بازگشت به کیف پول
                    </Typography>
                    <Wallet />
                  </Stack>
                </FlexBetween>
                <Typography>
                  سرویس هواشناسی یکی از مهم سرویس هایی هست که در روند رسیدگی به
                  مزارع به کشاورزان کمک می کند تا از وقوع حوادث طبیعی جلوگیری
                  کنند و زیان کمتری به محصولات وارد شود
                </Typography>
              </Box>
            </Stack>
            <Box
              mt="2rem"
              width="100%"
              //   height="50%"
              display="flex"
              alignItems="center"
              p="0 1rem"
              justifyContent="space-between"
            >
              <PackageItem
                title={"سه ماهه"}
                price={"750"}
                unit={"هزار تومان"}
              />
              <PackageItem
                title={"شش ماهه"}
                price={"1.3"}
                unit={"میلیون تومان"}
                enable={true}
              />
              <PackageItem
                title={"یک ساله"}
                price={"2.2"}
                unit={"میلیون تومان"}
              />
              <PackageItem
                title={"سه ساله"}
                price={"4"}
                unit={"میلیون تومان"}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="3rem"
              gap="16px"
            >
              <Box component="img" src={FarmIcon2} />
              <Box component="img" src={FarmIcon3} />
              <Box component="img" src={FarmIcon4} />
            </Box>
            <Box width="100%" mt="3rem">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <Typography variant="h4" color="fontColor.main">
                  تغییرات نسخه آخر
                </Typography>
                <Typography
                  variant="smallestTextSecondary"
                  color="fontColor.alt"
                >
                  ورژن 1.0.0
                </Typography>
              </Box>
              <Box m="0.5rem 1.5rem 0 0">
                <ul>
                  <li style={{ margin: "1.5rem 0 0.5rem 0" }}>
                    <Typography variant="body" color="fontColor.main">
                      مشاهده تغییرات آب و هوای 7 روز آینده
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      زمان غروب و طلوع خورشید
                    </Typography>
                  </li>
                  <li style={{ marginBottom: "0.5rem" }}>
                    <Typography variant="body" color="fontColor.main">
                      مقایسه داده های آب و هوا با داده های سنسورهای مزرعه
                    </Typography>
                  </li>
                </ul>
              </Box>
            </Box>
          </WidgetWrapper>
          <WidgetWrapper width="100%">
            <Stack direction="row" alignItems="center" gap="8px">
              <Typography variant="h4" color="fontColor.main">
                نظرات شما
              </Typography>
              <Typography variant="subHeading" color="fontColor.alt">
                یک دیدگاه
              </Typography>
            </Stack>

            <CommentItem />
            <Divider />
            <CommentItem />
            <Divider />
            <CommentItem />
          </WidgetWrapper>
        </Box>
        <WidgetWrapper width="19.3%" height="83vh">
          <Typography variant="h4">سرویس های پیشنهادی</Typography>
          <ServiceItem title={"شاخص هوشمند"} />
          <Divider />
          <ServiceItem title={"مدیریت سنسورها"} />

          <Divider />
          <ServiceItem title={"توصیه ها"} />

          <Divider />
          <ServiceItem title={"مدیریت کارکنان"} />
          <Divider />
          <ServiceItem title={"هشدارها"} />
        </WidgetWrapper>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default WeatherModules;
