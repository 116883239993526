import {
  Box,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setData, setProperty } from "States/dashboard";
import axios from "axios";
import { setFirstDate, setSecondDate, setSensorsData } from "States/sensors";
import WithSpinner from "components/With-Spinner/With-Spinner.component";
import { useState } from "react";
import { store } from "States/rootReducer";
import { setFarmName, setLogin } from "States";
import DashboardMobIndex from "mob/scenesMob/DashboardMobIndex";
import Dashboard from "components/Dashboard/Dashboard";
import DashboardMob from "mob/scenesMob/DashboardMob/DashboardMob";
const DashboardSpinner = WithSpinner(Dashboard);
const DashboardMobSpinner = WithSpinner(DashboardMob);
const DashboardIndex = () => {
  const [loading, setLoading] = useState(true);
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  const token = useSelector((state) => state.global.token);
  const refreshToken = useSelector((state) => state.global.refreshToken);

  const dispatch = useDispatch();
  console.log("🚀 ~ file: dashboard.jsx:11 ~ Dashboard ~ token:", token);
  // const theme = useTheme();
  const navigate = useNavigate();
  const propertyID = useSelector((state) => state.global.propertyId);

  const checkStatusApi = async (error, refreshToken) => {
    console.log(
      "🚀 ~ file: dashboard.jsx:32 ~ checkStatusApi ~ error:",
      error.response
    );

    if (error.response && error.response.status === 401) {
      try {
        console.log("checkStatusApi ===========>", error.response.status);
        getNewAccessToken(refreshToken);
      } catch (error) {
        console.log("n===>", error);
      }
    } else {
    }
  };

  const getNewAccessToken = async (refreshToken) => {
    const formData = new URLSearchParams();
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", "user-panel");
    formData.append("client_secret", "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV");
    formData.append("grant_type", "refresh_token");
    await fetch("https://api.rahbaan.ir/v1/authentication/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxBodyLength: Infinity,

      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("🚀 ~ file: dashboard.jsx:61 ~ .then ~ data:", data);

        store.dispatch(
          setLogin({
            user: data,
            token: data.access_token,
            refreshToken: data.refresh_token,
          })
        );
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SensorManagement.jsx:113 ~ .then ~ error:",
          error
        );
        store.dispatch(
          setLogin({
            user: null,
            token: null,
            refreshToken: null,
          })
        );
        navigate("/login");
      });
  };

  const getDashboardData = async () => {
    axios
      .get(`https://353ry.wiremockapi.cloud/dashboard`, {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      })
      .then((response) => {
        console.log(
          "🚀 ~ file: dashboard.jsx:102 ~ .then ~ response:",
          response.data.dashboard
        );
        dispatch(
          setData({
            growth: response.data.dashboard.growth,
            recommendations: response.data.dashboard.recommendations,
            remote: response.data.dashboard.remote,
            staffs: response.data.dashboard.staffs,
            weather: response.data.dashboard.weather,
            index: response.data.dashboard.index,
            actuators: response.data.dashboard.actuators,
            alerts: response.data.dashboard.alerts,
            sensors: response.data.dashboard.sensors,
            cultivation: response.data.dashboard.cultivation,
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: SensorManagement.jsx:299 ~ useEffect ~ error:",
          error
        );
        checkStatusApi(error, refreshToken);
      });
  };
  const getAlarms = async () => {
    try {
      const response = await fetch(
        `https://api.rahbaan.ir/v1/alarms/overview?searchStatus=ACTIVE`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = await response.json();
      console.log("🚀 ~ file: dashboard.jsx:82 ~ getAlarms ~ data:", data);
    } catch (error) {
      console.log("🚀 ~ file: dashboard.jsx:88 ~ getAlarms ~ error:", error);
    }
  };

  useEffect(() => {
    // if (!token) {
    //     navigate('/login');
    // } else {
    getDashboardData();
    // getFarms();
    getAlarms();
    setFirstDate({
      firstDate: null,
    });
    setSecondDate({
      secondDate: null,
    });
    // }

    // console.log('🚀 ~ file: dashboard.jsx:110 ~ useEffect ~ propertyID:', propertyID);
  }, [propertyID, token]);

  return (
    <>
      {isMobileScreens ? (
        <DashboardMobSpinner isLoading={false} />
      ) : (
        <DashboardSpinner isLoading={false} />
      )}
    </>
  );
};

export default DashboardIndex;
