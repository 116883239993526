import { CloseOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import CenterStack from "components/styledComponents/CenterStack";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    marginLeft: "-4px",
  },
  "& .MuiInputBase-input": {
    height: "20px",
  },
  "& .MuiOutlinedInput-input:focused": {
    borderColor: "green",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
  },
});
const AllLivestockForm = ({ setIsAddItem }) => {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <WidgetWrapper mb="80px">
      <FlexBetween mb="1rem">
        <Typography>ثبت دام جدید</Typography>
        <IconButton onClick={() => setIsAddItem(false)}>
          <CloseOutlined />
        </IconButton>
      </FlexBetween>
      <CacheProvider value={cacheRtl}>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              تاریخ تولد
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              ساعت تولد
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              خلوص خونی نژاد
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <Box mb="12px">
          <CssTextField
            label="پستانک اضافه"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>
        <Box mb="12px">
          <CssTextField
            label="پدر"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              نوع پدر
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              جنسیت
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            <MenuItem value={false}>نر</MenuItem>
            <MenuItem value={true}>ماده</MenuItem>
          </Select>
        </FormControl>
        <Box mb="12px">
          <CssTextField
            label="تاریخ ورود به گله"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>

        <Box mb="12px">
          <CssTextField
            label="مادر تنی"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>
        <Box mb="12px">
          <CssTextField
            label="مادر ناتنی"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>
        <Box mb="12px">
          <CssTextField
            label="نژاد"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>

        <FormControl
          sx={{
            minWidth: "100%",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              شیرواری
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <Divider sx={{ m: "20px 0" }} />
        <Box mb="12px">
          <CssTextField
            label="کد دام"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>

        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              کد بهاربند
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            minWidth: "100%",
            mb: "12px",
            "& .MuiFormLabel-root": {
              marginLeft: "-4px",
            },
            "& .MuiInputBase-input": {
              height: "5px",
            },
            "& .MuiOutlinedInput-input:focused": {
              borderColor: "green",
            },
            "& label.Mui-focused": {
              color: "#F7F7F7",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#F7F7F7",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              color: "#F7F7F7 !important",
              borderRadius: "8px",

              "& fieldset": {
                borderColor: "#F7F7F7",
              },
              "&:hover fieldset": {
                borderColor: "#F7F7F7",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#F7F7F7",
              },
            },
          }}
        >
          {age === "" ? (
            <InputLabel
              disableAnimation
              shrink={false}
              focused={false}
              id="item_type_label"
            >
              نام بهاربند
            </InputLabel>
          ) : null}

          <Select
            id="item_type"
            labelId="item_type_label"
            value={age}
            onChange={handleChange}
          >
            {/* <MenuItem value={false}>Private</MenuItem>
                    <MenuItem value={true}>Public</MenuItem> */}
          </Select>
        </FormControl>
        <Box mb="12px">
          <CssTextField
            label="نوع بهاربند"
            variant="outlined"
            style={{ width: "100%" }}
          />
        </Box>

        <CenterStack
          sx={{
            m: "20px 0",
            width: "100%",
            height: "36px",
            borderRadius: "8px",
            backgroundColor: "others.first",
            cursor: "pointer",
          }}
        >
          <Typography variant="h4" color="fontColor.main">
            ثبت
          </Typography>
        </CenterStack>
        <CenterStack
          sx={{
            m: "20px 0",
            width: "100%",
            height: "36px",
            borderRadius: "8px",
            backgroundColor: "background.third",
            cursor: "pointer",
          }}
          onClick={() => setIsAddItem(false)}
        >
          <Typography variant="h4" color="fontColor.main">
            انصراف
          </Typography>
        </CenterStack>
      </CacheProvider>
    </WidgetWrapper>
  );
};

export default AllLivestockForm;
