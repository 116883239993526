import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import React from "react";
import { useState } from "react";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const CustomSelectBox = ({
  width,
  height,
  type,
  backdropFilter,
  background,
  typeSelect,
  setTypeSelect,
  zoneSelect,
  setZoneSelect,
  setCards,
  setTitleDevice,
}) => {
  const data = useSelector((state) => state.sensors.sensorsData.devices);
  const weatherZones = data.filter((item) => item.type === "WEATHER");

  const soilZones = data.filter((item) => item.type === "SOIL");
  const allPlantSensors = data.filter((item) => item.type === "PLANT");

  const handleChange = (event) => {
    setTypeSelect(event.target.value);
    setZoneSelect("همه");
    setTitleDevice(" ");
    setCards(null);
  };
  const handleZoneChange = (event) => {
    setZoneSelect(event.target.value);
    setTitleDevice(" ");
    setCards(null);
  };

  const uniqueSoilZones = [...new Set(soilZones.map((item) => item.zoneTitle))];
  const uniqueWeatherZones = [
    ...new Set(weatherZones.map((item) => item.zoneTitle)),
  ];
  console.log(
    "🚀 ~ file: CustomSelectBox.jsx:46 ~ uniqueWeatherZones:",
    uniqueWeatherZones
  );
  const uniquePlantZones = [
    ...new Set(soilZones.map((item) => item.zoneTitle)),
  ];
  useEffect(() => {}, [typeSelect, zoneSelect]);
  const theme = useTheme();
  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0, minWidth: 100 }}>
        {type === "type" ? (
          <Select
            value={typeSelect}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              borderRadius: "8px",
              width,
              height,
              background,
              backdropFilter,
              strokeWidth: 0,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
          >
            <MenuItem value={"WEATHER"}>هوا</MenuItem>
            <MenuItem value={"SOIL"}>خاک</MenuItem>
            <MenuItem value={"PLANT"}>گیاه</MenuItem>
          </Select>
        ) : (
          <Select
            value={zoneSelect}
            onChange={handleZoneChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              borderRadius: "8px",
              width,
              height,
              background,
              backdropFilter,
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
          >
            <MenuItem value={"همه"}>همه</MenuItem>
            {typeSelect === "SOIL"
              ? uniqueSoilZones.map((item) => (
                  <MenuItem value={item} key={item.zoneId}>
                    {item}
                  </MenuItem>
                ))
              : typeSelect === "WEATHER"
              ? uniqueWeatherZones.map((item, index) => (
                  <MenuItem value={item} key={item.zoneId}>
                    {item}
                  </MenuItem>
                ))
              : typeSelect === "PLANT"
              ? uniquePlantZones.map((item, index) => (
                  <MenuItem value={item} key={item.zoneId}>
                    {item}
                  </MenuItem>
                ))
              : null}
          </Select>
        )}
      </FormControl>
    </CacheProvider>
  );
};

export default CustomSelectBox;
