export const getCenterOfSensors = ({d})=>{
    const sumX = d?.devices.reduce((sum, item) => sum + item.location.x, 0);
    const sumY = d?.devices.reduce((sum, item) => sum + item.location.y, 0);
  
    const meanX = sumX / d?.devices.length;
    const meanY = sumY / d?.devices.length;
    return [meanX,meanY]
}


export const getCenterOfSensorsArray = (selectedZoneSensors)=>{
console.log('hi===========================>',selectedZoneSensors)
    const totalItems = selectedZoneSensors?.length;
    let sumX = 0;
    let sumY = 0;
    
    selectedZoneSensors?.forEach((item) => {
      sumX += item.location.x;
      sumY += item.location.y;
    });
    
    const meanX = sumX / totalItems;
    const meanY = sumY / totalItems;
    return [meanX,meanY]
}
