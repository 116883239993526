import { Box, Divider, Tabs, Typography, useTheme } from "@mui/material";
import CircularProgressBar from "components/circularProgressBar/CircularProgressBar";
import CustomGauge from "components/CustomGauge/CustomGauge";
import CustomGaugeLetter from "components/CustomGaugeLetter/CustomGaugeLetter";
import DoughnutChart from "components/DoughnutChart/DoughnutChart";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Gauge from "components/Gauge/Gauge";
// import Gauge from "components/Gauge/Gauge";
//

import React, { useState } from "react";
import { useSelector } from "react-redux";
const AirSensorJ = () => {
  const theme = useTheme();

  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const textSize = theme.typography.smallestTextSecondary;
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getColor = (type, value) => {
    const temperatureThreshold1 = 10;
    const temperatureThreshold2 = 30;
    const humidityThreshold1 = 30;
    const humidityThreshold2 = 60;
    switch (type) {
      case "temperature":
        if (value > temperatureThreshold1 && value < temperatureThreshold2)
          return theme.palette.others.third;
        if (value > temperatureThreshold2) return theme.palette.others.second;
        if (value < temperatureThreshold1) return theme.palette.others.forth;
        break;
      case "humidity":
        if (value > humidityThreshold1 && value < humidityThreshold2)
          return theme.palette.others.third;
        if (value > humidityThreshold2) return theme.palette.others.forth;
        if (value < humidityThreshold1) return theme.palette.others.second;
        break;
      case "precipitation_rain":
        return theme.palette.others.third;
      case "wind_speed":
        return theme.palette.others.third;
      case "solar_radiation":
        return theme.palette.others.third;
      case "atmospheric_pressure":
        return theme.palette.others.third;
      default:
        break;
    }
  };

  // const sensorData = useSelector((state) => state.weather.weather.devices);
  // console.log('🚀 ~ file: Weather.jsx:99 ~ Weather ~ sensorData:', sensorData);

  let temperatureSum = 0;
  let humiditySum = 0;
  let temperatureCount = 0;
  let humidityCount = 0;

  // sensorData.forEach((item) => {
  //     const temperature = item.lastData.temperature.v;
  //     const humidity = item.lastData.humidity.v;

  //     temperatureSum += temperature;
  //     humiditySum += humidity;
  //     temperatureCount++;
  //     humidityCount++;
  // });
  const temperatureMean = temperatureSum / temperatureCount;

  const humidityMean = humiditySum / humidityCount;

  return (
    <FlexBetween sx={{ alignItems: "flex-start", height: "100%" }}>
      <Typography variant="h5">سنسور هوا</Typography>
      <Box sx={{ mt: "15px", display: "flex" }}>
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
          height="60px"
        >
          <Typography color={alt} fontSize={textSize} mb="0.5rem">
            دما
          </Typography>
          <CircularProgressBar
            value={37}
            diameter={45}
            start_from="middle"
            gaugeMinMax={40}
            // color={getColor("temperature", temperatureMean)}
            color={theme.palette.others.forth}
          >
            <Typography
              style={{
                direction: "ltr",
                color: getColor("temperature", temperatureMean),
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {`37°`}
              {/* {`${temperatureMean}°`} */}
            </Typography>
          </CircularProgressBar>
        </FlexBetween>

        <Divider orientation="vertical" flexItem height="60px" />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
          height="60px"
        >
          <Typography color={alt} fontSize={textSize}>
            رطوبت
          </Typography>
          <CircularProgressBar
            value={70}
            diameter={45}
            start_from="start"
            // gaugeMinMax={40}
            // color={getColor("humidity", humidityMean)}
            color={theme.palette.others.second}
          >
            <Typography
              style={{
                direction: "ltr",
                color: getColor("humidity", humidityMean),
              }}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {`70°`}
              {/* {`${humidityMean}°`} */}
            </Typography>
          </CircularProgressBar>
        </FlexBetween>

        {/* <Divider orientation="vertical" flexItem />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
        >
          <Typography color={alt} fontSize={textSize}>
            باد
          </Typography>
          <CustomGauge units={"km/h"} fs="8px" />
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
        >
          <Typography color={alt} fontSize={textSize}>
            نقطه شبنم
          </Typography>
          <CustomGauge units={"°"} fs="8px" />
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
        >
          <Typography color={alt} fontSize={textSize}>
            بارندگی
          </Typography>
          <CustomGauge units={"mm"} fs="8px" />
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
        >
          <Typography color={alt} fontSize={textSize}>
            شدت تابش
          </Typography>
          <CustomGauge units={"Lux"} fs="8px" />
        </FlexBetween>

        <Divider orientation="vertical" flexItem />
        <FlexBetween
          gap="0.2rem"
          m="0 0.75rem"
          sx={{ flexDirection: "column" }}
          width="80px"
        >
          <Typography color={alt} fontSize={textSize}>
            فشار
          </Typography>
          <CustomGauge units={"bar"} fs="8px" />
        </FlexBetween> */}
      </Box>
    </FlexBetween>
  );
};

export default AirSensorJ;
