import {
  CalendarMonthOutlined,
  DownloadOutlined,
  FilterAltOutlined,
  FullscreenOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import ActuatorItem from "components/ActuatorItem/ActuatorItem";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import CopyRight from "widgets/CopyRight";
import WetIcon from "../../assets/Actuator/Wet.png";
import PlantIcon from "../../assets/Actuator/plant.png";
import StIcon from "../../assets/Actuator/st.png";
import CloudyDD from "../../assets/icon/DarkWeather/cloudy.png";
import PowerIcon from "../../assets/Actuator/Power.png";
import SunIcon from "../../assets/Icons/sun.png";
import WindIcon from "../../assets/Icons/wind.png";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import ProgressbarV2 from "components/ProgressbarV2/ProgressbarV2";
import ActuatorChart from "./ActuatorChart";
import SelectBox from "components/SelectBox/SelectBox";
import ActuatorSelectBox from "./ActuatorSelectBox";
import ActuatorMap from "./ActuatorMap";
import ActivityItem from "./ActivityItem";

const MockData = [
  {
    type: "human",
    name: "آقای محمدی",
    task: "روشن کردن پمپ آب ناحیه 3",
    dueTime: "3 ساعت دیگر",
    time: "15:00 - 16:00",
  },
  {
    type: "machine",
    name: "هوش مزرعه",
    task: "روشن کردن پمپ آب ناحیه 1",
    dueTime: "5 ساعت دیگر",
    time: "10:00 - 11:20",
  },
  {
    type: "human",
    name: "آقای رضایی",
    task: "روشن کردن پمپ آب ناحیه 2",
    dueTime: "1 ساعت دیگر",
    time: "08:00 - 09:00",
  },
];
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
}));
const Actuator = () => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState(null);
  const [togglePower, setTogglePower] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("همه");
  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1255px"
      width="calc(100% - 2.75rem)"
      height="89vh"
      m="16px"
    >
      <Box display="flex" gap="16px" width="100%" height="96%">
        <Box
          width="16%"
          height="100%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <WidgetWrapper sx={{ width: "100%", height: "62%" }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">عملگرها</Typography>
              <FlexBetween
                sx={{
                  // display: open ? "inline-flex" : "none",
                  transition: "all 0.2s ease",

                  alignItems: "center",
                  border: `1px solid ${theme.palette.fontColor.alt}`,
                  // border: "1px solid black",
                  borderRadius: "8px",
                }}
              >
                <Search sx={{ mr: "0.2rem" }} />
                <InputBase
                  disableUnderline
                  placeholder="جست و جو"
                  id="inputSearchRemote"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  sx={{
                    paddingRight: "5px",
                    backgroundColor: "inherit",
                    "& .MuiInputBase-input": {
                      "::placeholder": {
                        color: `${theme.palette.fontColor.main} !important`,
                      },
                    },
                    height: "27px",
                    width: "100px",
                    borderRadius: "8px",
                    color: theme.palette.fontColor.main,
                    outline: "none",
                    borderBottom: "none",
                  }}
                />
              </FlexBetween>
            </Box>
            <Box
              width="100%"
              height="88%"
              mt="1rem"
              sx={{
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-button": {
                  height: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5A596F",
                  outline: `0px solid slategrey`,
                  borderRadius: "5px",
                },
              }}
            >
              <ActuatorItem
                icon={SunIcon}
                title={"روشنایی"}
                zone={"بهاربند 1"}
                selected={selected === 1 ? true : false}
                setSelected={setSelected}
                index={1}
                status={true}
              />
              <ActuatorItem
                icon={WindIcon}
                title={"تهویه 1"}
                zone={"بهاربند 2"}
                selected={selected === 2 ? true : false}
                status={true}
                index={2}
                setSelected={setSelected}
              />

              <ActuatorItem
                icon={StIcon}
                title={"مه پاش"}
                zone={"بهاربند 1"}
                status={false}
                selected={selected === 3 ? true : false}
                index={3}
                setSelected={setSelected}
              />
              <ActuatorItem
                icon={WindIcon}
                title={"تهویه 2"}
                zone={"بهاربند 1"}
                status={false}
                selected={selected === 4 ? true : false}
                index={4}
                setSelected={setSelected}
              />
            </Box>
          </WidgetWrapper>
          <WidgetWrapper sx={{ width: "100%", height: "32.5%" }}>
            <FlexBetween>
              <Typography
                variant="smallestTextPrimary"
                color={theme.palette.fontColor.main}
              >
                امروز
              </Typography>
              <Typography
                variant="smallestTextSecondary"
                color={theme.palette.fontColor.alt}
              >
                1401/10/20
              </Typography>
            </FlexBetween>
            <FlexBetween mt="1.5rem">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="Center"
                justifyContent="center"
              >
                <Typography variant="h1" color={theme.palette.fontColor.main}>
                  13<sup>°</sup>
                </Typography>
                <Stack direction="row">
                  <Typography
                    variant="body"
                    color={theme.palette.fontColor.main}
                  >
                    20°
                  </Typography>
                  |
                  <Typography
                    variant="body"
                    color={theme.palette.fontColor.alt}
                  >
                    16°
                  </Typography>
                </Stack>
              </Box>
              <Box component="img" src={CloudyDD} width="60px" height="60px" />
            </FlexBetween>
            <FlexBetween mt="1rem">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="smallestTextSecondary"
                  color={theme.palette.fontColor.alt}
                >
                  باد
                </Typography>
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.main}
                >
                  8km/h
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="smallestTextSecondary"
                  color={theme.palette.fontColor.alt}
                >
                  رطوبت
                </Typography>
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.main}
                >
                  15%
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Typography
                  variant="smallestTextSecondary"
                  color={theme.palette.fontColor.alt}
                >
                  بارندگی
                </Typography>
                <Typography
                  variant="smallestTextPrimary"
                  color={theme.palette.fontColor.main}
                >
                  6mm
                </Typography>
              </Box>
            </FlexBetween>
          </WidgetWrapper>
        </Box>
        <Box
          width="41.6%"
          height="100%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <WidgetWrapper sx={{ width: "100%", height: "50%" }}>
            <FlexBetween>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="0.5rem"
              >
                <Typography variant="h5">پمپ آب ناحیه 1</Typography>
                <Box
                  sx={{
                    width: "94px",
                    height: "25px",
                    backgroundColor: theme.palette.others.first,
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="smallestTextPrimary"
                    color={theme.palette.fontColor.alt}
                  >
                    گزارش عملکرد
                  </Typography>
                </Box>
              </Box>
              <Box
                width="24px"
                height="24px"
                backgroundColor={
                  togglePower
                    ? theme.palette.others.third
                    : theme.palette.background.third
                }
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: "pointer" }}
                onClick={() => setTogglePower(!togglePower)}
              >
                <Box component="img" src={PowerIcon} />
              </Box>
            </FlexBetween>
            <Box
              width="100%"
              height="96%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="5rem"
            >
              <AnimatedProgressBar
                number={75}
                width={157}
                height={163}
                title="2:30:50"
                subTitle="3:00:00"
                color={theme.palette.others.first}
                dot={true}
              />
              <Box
                display="flex"
                flexDirection="column"
                width={157}
                height={163}
                justifyContent="space-around"
              >
                <FlexBetween
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <Box width="16px" height="16px">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00033 14.6667C4.78033 14.6667 2.16699 12.0533 2.16699 8.83333C2.16699 5.61333 4.78033 3 8.00033 3C11.2203 3 13.8337 5.61333 13.8337 8.83333"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 5.33331V8.66665"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 1.33331H10"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.667 11.3333V14"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.667 11.3333V14"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="smallestTextPrimary"
                      color={theme.palette.fontColor.alt}
                    >
                      مدت زمان آبیاری
                    </Typography>
                    <Typography
                      variant="subHeading"
                      color={theme.palette.fontColor.main}
                    >
                      3 ساعت
                    </Typography>
                  </Box>
                </FlexBetween>

                <FlexBetween
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <Box width="16px" height="16px">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.1875 8C14.1875 11.1395 11.6395 13.6875 8.5 13.6875C5.3605 13.6875 3.44381 10.5253 3.44381 10.5253M3.44381 10.5253H6.01456M3.44381 10.5253V13.369M2.8125 8C2.8125 4.8605 5.33775 2.3125 8.5 2.3125C12.2936 2.3125 14.1875 5.47475 14.1875 5.47475M14.1875 5.47475V2.631M14.1875 5.47475H11.6623"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="smallestTextPrimary"
                      color={theme.palette.fontColor.alt}
                    >
                      بازه زمانی تکرار
                    </Typography>
                    <Typography
                      variant="subHeading"
                      color={theme.palette.fontColor.main}
                    >
                      هر روز
                    </Typography>
                  </Box>
                </FlexBetween>
                <FlexBetween
                  sx={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <Box width="16px" height="16px">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.6663 7.99998C14.6663 11.68 11.6797 14.6666 7.99967 14.6666C4.31967 14.6666 1.33301 11.68 1.33301 7.99998C1.33301 4.31998 4.31967 1.33331 7.99967 1.33331C11.6797 1.33331 14.6663 4.31998 14.6663 7.99998Z"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.4729 10.12L8.40626 8.88665C8.04626 8.67332 7.75293 8.15999 7.75293 7.73999V5.00665"
                        stroke="#2A80F4"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="smallestTextPrimary"
                      color={theme.palette.fontColor.alt}
                    >
                      ساعت شروع
                    </Typography>
                    <Typography
                      variant="subHeading"
                      color={theme.palette.fontColor.main}
                    >
                      15:00
                    </Typography>
                  </Box>
                </FlexBetween>
              </Box>
            </Box>
          </WidgetWrapper>
          <WidgetWrapper sx={{ width: "100%", height: "44.5%" }}>
            <FlexBetween>
              <ActuatorSelectBox />
              <FlexBetween>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  width="132px"
                  height="27px"
                  borderRadius="8px"
                  border="1px solid white"
                >
                  <IconButton>
                    <CalendarMonthOutlined
                      sx={{ width: "18px", height: "18px" }}
                    />
                  </IconButton>
                  <Typography
                    variant="smallestTextPrimary"
                    color={theme.palette.fontColor.main}
                  >
                    1402/02/03
                  </Typography>
                </Box>
                <IconButton>
                  <DownloadOutlined />
                </IconButton>
                <IconButton>
                  <FullscreenOutlined />
                </IconButton>
              </FlexBetween>
            </FlexBetween>
            <ActuatorChart
              min={0}
              max={1500}
              stepSize={500}
              thv1={1000}
              h={"80%"}
            />
          </WidgetWrapper>
        </Box>
        <Box
          width="40.5%"
          height="100%"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <ActuatorMap />
          <WidgetWrapper sx={{ width: "100%", height: "31.5%" }}>
            <FlexBetween>
              <Typography>فعالیت ها</Typography>
              <Box width="77px" height="27px" borderRadius="8px">
                <Select
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                  IconComponent={FilterAltOutlined}
                  MenuProps={{
                    // Apply the custom styled MenuList component
                    MenuListProps: { component: StyledMenuList },
                  }}
                  sx={{
                    width: "77px",
                    height: "27px",
                    border: `1px solid ${theme.palette.fontColor.alt}`,
                    borderRadius: "8px",

                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#40405e",
                    },
                  }}
                >
                  <MenuItem
                    value={"همه"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: "0 5px",
                      borderRadius: "4px",
                      backgroundColor: `${theme.palette.background.third}!important`,
                      ":hover": {
                        backgroundColor: `${theme.palette.fontColor.third} !important`,
                      },
                    }}
                  >
                    <Typography
                      variant="smallestTextSecondary"
                      fontColor={theme.palette.fontColor.main}
                    >
                      همه
                    </Typography>
                  </MenuItem>
                </Select>
              </Box>
            </FlexBetween>
            <Box
              sx={{
                overflowY: "auto",
                width: "100%",
                height: "90%",
                mt: "0.5rem",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-button": {
                  height: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5A596F",
                  outline: `0px solid slategrey`,
                  borderRadius: "5px",
                },
              }}
            >
              {MockData.map((item, index) => (
                <ActivityItem
                  type={item.type}
                  name={item.name}
                  task={item.task}
                  dueTime={item.dueTime}
                  time={item.time}
                />
              ))}
            </Box>
          </WidgetWrapper>
        </Box>
      </Box>
      <CopyRight />
    </Box>
  );
};

export default Actuator;
