import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { graphic } from "echarts";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDateShamsi, getMonthDayP1 } from "Utils/Utils";
import DewPoint from "../../assets/icon/dewPoint.svg";

const LineChart = ({
  data,
  min,
  max,
  thresholds,
  showLegend = false,
  toolTipIcon,
}) => {
  const theme = useTheme();
  const [legendState, setLegendState] = useState({});
  useEffect(() => {
    if (data) {
      const tmp = Object.keys(data).reduce((acc, curr) => {
        acc[data[curr].name] = true;
        return acc;
      }, {});
      setLegendState(tmp);
    }
  }, [data]);
  const sortedThreshold = thresholds?.sort((a, b) => b - a);

  const chartData = Object.values(data).map((value) => {
    const data = value.data?.map((point) => [point.ts, point.v]);
    return {
      name: value.name,
      data: data,
      type: "line",
      emphasis: {
        focus: "none",
      },
      showSymbol: false,
      smooth: true,
      ...(sortedThreshold
        ? {
            markLine: {
              silent: true,
              symbol: ["", ""],
              data: sortedThreshold.map((threshold) => ({ yAxis: threshold })),
              lineStyle: {
                type: "solid",
                color: "red",
              },
              label: {
                show: false,
              },
            },
          }
        : {}),
      // areaStyle: {
      // origin: 0,
      //   color: new graphic.LinearGradient(0, 0, 0, 1, [
      //     {
      //       offset: 0,
      //       color: value.color,
      //     },
      //     {
      //       offset: 1,
      //       color: `rgba(255, 255, 255, 0)`,
      //     },
      //   ]),
      // },
    };
  });
  const isMobileScreens = useMediaQuery("(max-width: 600px)");
  const mode = useSelector((state) => state.global.mode);
  const option = {
    grid: {
      top: 45,
      left: 5,
      right: 20,
      bottom: 10,
      width: "auto",
      containLabel: true,
    },
    backgroundColor: "transparent",
    tooltip: {
      formatter: (params) => {
        return `<div style='background-color: ${
          params[0].color
        }; padding: 1rem; border-radius: 10px; position: relative'>
        
        <img style='width: 30px; height: 30px; position: absolute; top: 1rem; left: 1rem;' src=${toolTipIcon} />
        ${params[0].value[1]}<br/>20    50
        <br/>${getDateShamsi(params[0].value[0])}</div>`;
      },
      trigger: "axis",
      position: (point) => [point[0], "10%"],
      axisPointer: {
        type: "line",
        animation: false,
      },
      textStyle: {
        color: `${mode === "dark" ? "#CED4DA" : "#868E96"}`,
        fontSize: "1rem",
      },
      padding: 0,
      borderColor: `transparent`,
      backgroundColor: `transparent`,
      extraCssText: "box-shadow: none; font-family: YekanBakhFaNum-Regular",
    },
    ...(sortedThreshold
      ? {
          visualMap: {
            left: 0,
            right: 0,
            width: 0,
            height: 0,
            show: false,
            orient: "vertical",
            pieces: [
              ...sortedThreshold.map((threshold, idx) => {
                switch (idx) {
                  case 0:
                    return { max: threshold, color: "rgb(196,182,24)" };
                  case 1:
                    return {
                      max: threshold,
                      color: theme.palette.others.first,
                    };
                  case 2:
                    return { max: threshold, color: "rgb(196,182,24)" };
                  case 3:
                    return { max: threshold, color: "rgba(250, 0,0,0.3)" };
                  default:
                    break;
                }
              }),
              { min: sortedThreshold[0], color: "rgba(250, 0,0,0.3)" },
            ],
          },
        }
      : {}),
    xAxis: {
      type: "time",
      axisLabel: {
        formatter: (value) => getMonthDayP1(value),
        margin: 10,
        color: theme.palette.fontColor.main,
        fontSize: 10,
      },
      axisPointer: { label: { show: false } },
      axisTick: { show: false },
    },
    yAxis: {
      type: "value",
      min: min,
      max: max,
      interval: (max - min) / 2,
      splitLine: {
        lineStyle: {
          color: `${mode === "dark" ? "#495057" : "#E9ECEF"}`,
          type: "dashed",
        },
      },
      axisPointer: { label: { show: false } },
      scale: true,
      axisLabel: {
        fontSize: "10",
        color: theme.palette.fontColor.main,
      },
    },
    textStyle: {
      fontFamily: "YekanBakhFaNum-Regular",
    },
    dataZoom: [{ type: "inside" }],
    series: chartData,
    color: [
      theme.palette.others.first,
      "#FEC37B",
      "#FF7B7B",
      "#7B9EFD",
      "#7BFD9F",
    ],
    legend: {
      show: false,
      ...(showLegend ? { selected: legendState } : {}),
    },
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: isMobileScreens ? "70%" : "100%",
        position: "relative",
      }}
    >
      {showLegend && (
        <Box
          sx={{
            position: "absolute",
            top: "0.5rem  ",
            right: 0,
          }}
        >
          {Object.entries(legendState).length > 0 &&
            Object.entries(legendState).map(([name, checked]) => (
              <FormControlLabel
                value={checked}
                control={
                  <Radio
                    sx={{
                      width: "12px",
                      height: "12px",
                      "&, &.Mui-checked": {
                        color: theme.palette.others.first,
                      },
                    }}
                    checked={checked}
                    onClick={() =>
                      setLegendState((prev) => ({
                        ...prev,
                        [name]: !prev[name],
                      }))
                    }
                  />
                }
                label={
                  <Typography
                    fontSize={theme.typography.smallestTextPrimary}
                    mr="0.25rem"
                    color={theme.palette.fontColor.main}
                  >
                    {name}
                  </Typography>
                }
              />
            ))}
        </Box>
      )}
      {option && (
        <ReactEcharts
          theme={mode}
          option={option}
          notMerge={true}
          style={{ height: "100%", width: "100%" }}
        />
      )}
    </Box>
  );
};

export default LineChart;
