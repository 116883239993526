import { Box, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import FormReset from "components/Form/FormReset";
import React from "react";
import LoginPic from "../../../assets/responsive-login.png";
import FormResetMob from "./FormResetMob";
import { useSelector } from "react-redux";

const ResetPaswordMob = () => {
  const mode = useSelector((state) => state.global.mode);

  return (
    <FlexBetween
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ position: "absolute", width: "100%", height: "100%" }}
        component="img"
        src={LoginPic}
      />
      <Box sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
        {mode === "dark" ? (
          <img
            alt="img1"
            src={require("../../../assets/LogoColor.png")}
            style={{
              width: "52px",
              height: "40px",
              objectFit: "cover",
            }}
          ></img>
        ) : (
          <img
            alt="img1"
            src={require("../../../assets/LogoColor.png")}
            style={{
              width: "52px",
              height: "40px",
              objectFit: "cover",
            }}
          ></img>
        )}
      </Box>
      <Box
        sx={{
          width: "336px",
          height: "461px",
          zIndex: 1000,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            width: "336px",
            height: "461px",

            backgroundColor: "rgba(248, 248, 252, 0.3)",
            backdropFilter: "blur(7.5px)",
            borderRadius: "24px",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FormResetMob />
        </Box>
      </Box>
    </FlexBetween>
  );
};

export default ResetPaswordMob;
