import {
  Box,
  FormControlLabel,
  Radio,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MOBILE_WIDTH } from "constants/constant";
import { graphic } from "echarts";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMonthDayP1 } from "Utils/Utils";
const LiveChart = ({ data, color, r, g, b }) => {
  const theme = useTheme();
  const nameIndex = "";
  const [legendState, setLegendState] = useState(() => {
    if (nameIndex) return { [nameIndex]: true };
    return {};
  });
  useEffect(() => {
    if (nameIndex) {
      setLegendState({ [nameIndex]: true });
    }
  }, [nameIndex]);
  const isMobileScreens = useMediaQuery(`(max-width:600px)`);
  const mode = useSelector((state) => state.global.mode);
  const chartData = {
    name: nameIndex,
    data: data,

    type: "line",
    emphasis: {
      focus: "none",
    },
    showSymbol: false,
    smooth: false,
    areaStyle: {
      color: new graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: color ? color : theme.palette.others.first,
        },
        {
          offset: 1,
          color: `rgba(${r}, ${g}, ${b}, 0.1)`,
        },
      ]),
    },
  };
  const option = {
    grid: {
      top: 45,
      left: 5,
      right: 20,
      bottom: 10,
      width: "auto",
      containLabel: true,
    },
    backgroundColor: "transparent",
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        animation: false,
      },
      textStyle: {
        color: `${mode === "dark" ? "#CED4DA" : "#868E96"}`,
        fontSize: "1rem",
      },
      borderColor: `${mode === "dark" ? "#111018" : "#F7F9FB"}`,
      backgroundColor: `${mode === "dark" ? "#111018" : "#F7F9FB"}`,
    },
    xAxis: {
      type: "category",
      splitLine: {
        show: false,
      },
      boundaryGap: false,
      axisPointer: { label: { show: false } },
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: { margin: 20, color: theme.palette.fontColor.main },
    },
    yAxis: {
      type: "value",
      splitLine: {
        lineStyle: {
          color: `${mode === "dark" ? "transparent" : "transparent"}`,
          type: "dashed",
        },
      },
      axisPointer: { label: { show: false } },
      scale: true,
      axisLabel: {
        fontSize: "10",
        color: theme.palette.fontColor.main,
      },
    },
    textStyle: {
      fontFamily: "YekanBakhFaNum-Regular",
    },
    dataZoom: [{ type: "inside" }],
    series: chartData,
    color: [
      color ? color : theme.palette.others.first,
      "#FEC37B",
      "#FF7B7B",
      "#7B9EFD",
      "#7BFD9F",
    ],
    legend: {
      show: false,
      top: 10,
      right: 5,
      align: "right",
      padding: [0, 0, 250, 0],
      icon: "circle",
      width: "80%",
      itemGap: 15,
      itemWidth: 12,
      textStyle: { fontSize: 12, color: theme.palette.fontColor.main },
      itemStyle: {
        borderWidth: 2,
        borderJoin: "round",
      },
      selected: legendState,
    },
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: isMobileScreens ? "85%" : "90%",
        position: "relative",
      }}
    >
      {option && (
        <ReactEcharts
          theme={mode}
          option={option}
          notMerge={true}
          style={{ height: "100%", width: "100%" }}
        />
      )}
    </Box>
  );
};

export default LiveChart;
