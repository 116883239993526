export const EstrusData = {
    type: "فحلی",
    success: [
      ["05/12", 910],
      ["05/13", 820],
      ["05/14", 900],
      ["05/15", 672],
      ["05/16", 650],
      ["05/17", 723],
      ["05/18", 831],
      ["05/19", 850],
    ],
    failure: [
      ["05/12", 810],
      ["05/13", 820],
      ["05/14", 750],
      ["05/15", 672],
      ["05/16", 620],
      ["05/17", 623],
      ["05/18", 731],
      ["05/19", 550],
    ],
    status: [[810, 873], "فحلی ها"],
  
    livestock: [
      {
        code: 900,
        date: "1402/03/12 - 12:10",
        status: "تلیسه",
        signs: "ایستای فحلی",
        estrusTime: 12,
        bestTime: "1402/04/10",
        estrusFinder: "سنسور",
        milk: 9,
        estrus: "انجام شده",
      },
      {
        code: 899,
        date: "1402/03/11 - 22:10",
        status: "قابل تلقیح",
        signs: "ایستای فحلی",
        estrusTime: 5,
        bestTime: "1402/04/14",
        estrusFinder: "سنسور",
        milk: 8,
        estrus: "انجام شده",
      },
      {
        code: 898,
        date: "1402/03/12 - 05:10",
        status: "تلیسه",
        signs: "ایستای فحلی",
        estrusTime: 30,
        bestTime: "1402/05/01",
        estrusFinder: "سنسور",
        milk: 9,
        estrus: "انجام نشده",
      },
      {
        code: 897,
        date: "1402/03/14 - 19:45",
        status: "تلیسه",
        signs: "ایستای فحلی",
        estrusTime: 15,
        bestTime: "1402/04/24",
        estrusFinder: "سنسور",
        milk: 6,
        estrus: "انجام شده",
      },
    ],
  };