import { GoogleOAuthProvider } from "@react-oauth/google";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Dashboard from "scenes/dashboard/DashboardIndex";
import Layout from "scenes/Layout/Layout";
import Login from "scenes/Login/Login";
import RemoteSensing from "scenes/RemoteSensing/RemoteSensing";
import Register from "scenes/Register/Register";
import ResetPassword from "scenes/ResetPassword/ResetPassword";
import SensorManagement from "scenes/SensorManagement/SensorManagement";
import StaffMgmt from "scenes/StaffMgmt/StaffMgmt";
import AlarmManagementIndex from "scenes/AlarmsManagement/AlarmManagementIndex";
import WeatherIndex from "scenes/Weather/WeatherIndex";
import ActuatorIndex from "scenes/Actuator/ActuatorIndex";
import RecordFarmIndex from "scenes/RecordFarm/RecordFarmIndex";
import RecordEventsIndex from "scenes/RecordEvents/RecordEventsIndex";
import RecordSensorsIndex from "scenes/RecordSensors/RecordSensorsIndex";
import WalletIndex from "scenes/Wallet/WalletIndex";
import MessengerIndex from "scenes/Messenger/MessengerIndex";
import RecommendsIndex from "scenes/Recommends/RecommendsIndex";
import GrowthStagesIndex from "scenes/GrowthStages/GrowthStagesIndex";
import SmartIndexesIndex from "scenes/SmartIndexes/SmartIndexesIndex";
import StaffMgmtIndex from "scenes/StaffMgmt/StaffMgmtIndex";
import StaffMgmtIndividual from "mob/scenesMob/StaffMgmtIndividual/StaffMgmtIndividual";
import CompareIndex from "scenes/Compare/CompareIndex";
import LivestockManagementIndex from "scenes/LivestockManagement/LivestockManagementIndex";
import ReportIndex from "scenes/Report/ReportIndex";
import TicketIndex from "scenes/Ticket/TicketIndex";
function App() {
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GoogleOAuthProvider clientId="141838505432-bn0de50o8d1u25dkpp0fsl4h88qqvkh2.apps.googleusercontent.com">
            {/* zeroLineColor: "transparent" */}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route element={<Layout />}>
                <Route
                  path="/"
                  element={<Navigate to="/management" replace />}
                />
                <Route path="/management" element={<Dashboard />} />
                <Route path="/livestockmanagement" element={<LivestockManagementIndex />} />

                <Route path="/staffmgmt" element={<StaffMgmtIndex />} />
                <Route
                  path="/staffmgmt/:id"
                  element={<StaffMgmtIndividual />}
                />
                <Route path="/alarms" element={<AlarmManagementIndex />} />
                <Route path="/weather" element={<WeatherIndex />} />
                <Route path="/actuator" element={<ActuatorIndex />} />
                <Route path="/recordfarm" element={<RecordFarmIndex />} />
                <Route path="/recordEvents" element={<RecordEventsIndex />} />
                <Route path="/recordSensors" element={<RecordSensorsIndex />} />
                <Route path="/wallet" element={<WalletIndex />} />
                <Route path="/messenger" element={<MessengerIndex />} />
                <Route path="/recommends" element={<RecommendsIndex />} />
                <Route path="/growthstage" element={<GrowthStagesIndex />} />
                <Route path="/smartindexes" element={<SmartIndexesIndex />} />
                <Route path="/compare" element={<CompareIndex />} />
                <Route path="/report" element={<ReportIndex />} />
                <Route path="/ticket" element={<TicketIndex />} />

              </Route>
            </Routes>
          </GoogleOAuthProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
