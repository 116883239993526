import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import sensorsReducer from './sensors';
import dashboardReducer from './dashboard';
import globalReducer from './index';
import weatherReducer from './weather';
import storage from 'redux-persist/lib/storage';
import remoteSensing from './remoteSensing';
import messenger from './messenger';
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['global', 'dashboard', 'sensors', 'weather'], // add the slice(s) you want to persist
};
const notPersistedSensor = {
    key: 'sensors',
    storage,
    blacklist: ['firstDate', 'secondDate'],
};
const rootReducer = combineReducers({
    sensors: persistReducer(notPersistedSensor, sensorsReducer),
    dashboard: dashboardReducer,
    global: globalReducer,
    weather: weatherReducer,
    remoteSensing: remoteSensing,
    messenger: messenger,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);
