import React, { useEffect, useState } from "react";
import WithSpinner from "components/With-Spinner/With-Spinner.component";
import AlarmsManagement from "./AlarmsManagement";
import { store } from "States/rootReducer";
import { setAlarms, setDevicesAlarm, setSensorsData } from "States/sensors";
import { useSelector } from "react-redux";
import { setLogin } from "States";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlarmsManagementMob from "mob/scenesMob/AlarmsManagementMob/AlarmsManagementMob";
import { useMediaQuery } from "@mui/material";
const AlarmsManagementWithSpinner = WithSpinner(AlarmsManagement);
const AlarmsManagementMobWithSpinner = WithSpinner(AlarmsManagementMob);
const AlarmManagementIndex = () => {
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.global.token);
  const refreshToken = useSelector((state) => state.global.refreshToken);
  const navigate = useNavigate();

  // const checkStatusApi = async (error, refreshToken) => {

  //   if (error.response && error.response.status === 401) {
  //     try {
  //       console.log("checkStatusApi ===========>", error.response.status);
  //       getNewAccessToken(refreshToken);
  //     } catch (error) {
  //       console.log("n===>", error);
  //     }
  //   } else {
  //   }
  // };
  // const getNewAccessToken = async (refreshToken) => {
  //   const formData = new URLSearchParams();
  //   formData.append("refresh_token", refreshToken);
  //   formData.append("client_id", "user-panel");
  //   formData.append("client_secret", "mcTnPhIUDMdCq06n2wJR59LNVmmv4ahV");
  //   formData.append("grant_type", "refresh_token");
  //   await fetch("https://api.rahbaan.ir/v1/authentication/token", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     maxBodyLength: Infinity,

  //     body: formData,
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("🚀 ~ file: dashboard.jsx:61 ~ .then ~ data:", data);

  //       store.dispatch(
  //         setLogin({
  //           user: data,
  //           token: data.access_token,
  //           refreshToken: data.refresh_token,
  //         })
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "🚀 ~ file: SensorManagement.jsx:113 ~ .then ~ error:",
  //         error
  //       );
  //       store.dispatch(
  //         setLogin({
  //           user: null,
  //           token: null,
  //           refreshToken: null,
  //         })
  //       );
  //       navigate("/login");
  //     });
  // };
  // const getDataAlarms = async () => {
  //   axios
  //     .get(`https://api.rahbaan.ir/v1/alarms/overview?searchStatus=ACTIVE`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.log(
  //         "🚀 ~ file: AlarmManagementIndex.jsx:89 ~ .then ~ response:",
  //         response
  //       );

  //       store.dispatch(setAlarms({ alarms: response.data.data }));
  //     })
  //     .catch((error) => {
  //       console.log(
  //         "🚀 ~ file: SensorManagement.jsx:299 ~ useEffect ~ error:",
  //         error
  //       );
  //       checkStatusApi(error, refreshToken);
  //     });
  // };

  // const getDataOverView = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://api.rahbaan.ir/v1/devices/overview",
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const { data } = await response.json();
  //     console.log(
  //       "🚀 ~ file: AlarmsManagement.jsx:113 ~ getDataOverView ~ data:",
  //       data
  //     );
  //     store.dispatch(
  //       setSensorsData({
  //         sensorsData: data,
  //       })
  //     );

  //     store.dispatch(setDevicesAlarm({ devicesAlarm: data }));
  //     if (response.status === 200) {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(
  //       "🚀 ~ file: AlarmsManagement.jsx:95 ~ getDataAlarms ~ error:",
  //       error
  //     );
  //   }
  // };
  useEffect(() => {
    // getDataAlarms();
    // getDataOverView();
  }, [token]);
  const isMobileScreens = useMediaQuery("(max-width:600px)");

  return isMobileScreens ? (
    <AlarmsManagementMobWithSpinner isLoading={false} />
  ) : (
    <AlarmsManagementWithSpinner isLoading={false} />
  );
};

export default AlarmManagementIndex;
