import { AddOutlined, MoreHoriz, Search } from "@mui/icons-material";
import {
  useTheme,
  Box,
  Divider,
  FormControlLabel,
  InputBase,
  Radio,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";
import LiveChart from "./LiveChart";
import DownloadIcon from "../../assets/Icons/download.png";
import RecordLivestock from "./RecordLivestock";
import RecordInoculated from "./RecordInoculated";
import { InoculatedData } from "Mock/InoculatedData";

const Inoculated = ({ selectedTitle }) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [addItem, setAddItem] = useState(false);
  const fillteredData = InoculatedData.livestock.filter((item) =>
    search !== "" ? item.code.toString().includes(search) : item
  );
  return !addItem ? (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <Box width="100%" dispaly="flex" flex={1}>
        <Box width="100%" height="100%" display="flex" gap="16px">
          <WidgetWrapper flex={4.65} height="100%">
            <FlexBetween>
              <Typography variant="h4" color="fontColor.main">
                عملکرد {selectedTitle}
              </Typography>

              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  وضعیت {selectedTitle}
                </Typography>
              </Stack>
            </FlexBetween>
            <LiveChart
              data={InoculatedData.performance}
              r={255}
              g={255}
              b={255}
            />
          </WidgetWrapper>
          <WidgetWrapper flex={1} height="100%">
            <Stack direction="row" alignItems="center" gap="8px" mb="0.75rem">
              <Typography variant="h5" color="fontColor.main">
                {InoculatedData.status[1]}
              </Typography>
              <Typography variant="smallestTextSecondary" color="fontColor.alt">
                1 ماه گذشته
              </Typography>
            </Stack>
            <AnimatedProgressBar
              number={75}
              width={157}
              height={163}
              title={InoculatedData.status[0][0]}
              subTitle={InoculatedData.status[0][1]}
              color={theme.palette.others.first}
              dot={false}
            />
          </WidgetWrapper>
        </Box>
      </Box>
      <WidgetWrapper width="100%" flex={1.48}>
        <FlexBetween>
          <FlexBetween
            sx={{
              // display: open ? "inline-flex" : "none",
              transition: "all 0.2s ease",
              ml: "1.5rem",
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              // border: "1px solid black",
              borderRadius: "8px",
              mt: "0.25rem",
            }}
          >
            <InputBase
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              disableUnderline
              placeholder="کد دام"
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${theme.palette.fontColor.main} !important`,
                  },
                },
                height: "27px",
                width: "150px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <Stack direction="row" alignItems="center">
            <IconButton>
              <Box component="img" src={DownloadIcon} />
            </IconButton>
            <Box
              width="158px"
              height="27px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                cursor: "pointer",
              }}
              onClick={() => setAddItem(true)}
            >
              <AddOutlined />
              <Typography>تلقیح جدید</Typography>
            </Box>
          </Stack>
        </FlexBetween>
        <Box style={{ height: "80%", width: "100%" }}>
          <FlexBetween
            mt="0.5rem"
            sx={{ width: "100%", justifyContent: "flex-start" }}
          >
            <Box
              width="8%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="fontColor.alt">کد دام</Typography>
            </Box>

            <Box
              width="30%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                تاریخ تلقیح
              </Typography>
            </Box>

            <Box
              width="10%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                نوع تلقیح
              </Typography>
            </Box>

            <Box
              width="10%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                نوبت
              </Typography>
            </Box>

            <Box
              width="15%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                شماره اسپرم
              </Typography>
            </Box>

            <Box
              width="10%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                شیرواری
              </Typography>
            </Box>

            <Box
              width="5%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            ></Box>
          </FlexBetween>

          <Divider sx={{ margin: "12px 0" }} />
          <Box
            sx={{
              height: "80%",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            {fillteredData.map((item, index) => (
              <>
                <FlexBetween
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                >
                  <Box
                    width="8%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography color="fontColor.alt">{item.code}</Typography>
                  </Box>

                  <Box
                    width="30%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.inDate}
                    </Typography>
                  </Box>

                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.inType}
                    </Typography>
                  </Box>

                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.order}
                    </Typography>
                  </Box>

                  <Box
                    width="15%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.noSperm}
                    </Typography>
                  </Box>

                  <Box
                    width="10%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.milk}
                    </Typography>
                  </Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  ></Box>
                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  ></Box>

                  <Box
                    width="5%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IconButton sx={{ rotate: "90deg" }}>
                      <MoreHoriz />
                    </IconButton>
                  </Box>
                </FlexBetween>
                <Divider sx={{ margin: "10px 0" }} />
              </>
            ))}
          </Box>
        </Box>
      </WidgetWrapper>
    </Box>
  ) : (
    <WidgetWrapper
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap="16px"
    >
      <RecordInoculated setAddItem={setAddItem} />
    </WidgetWrapper>
  );
};

export default Inoculated;
