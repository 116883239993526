import {
  AddOutlined,
  ListOutlined,
  PersonOutlineOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  InputBase,
  ListItemText,
  MenuList,
  OutlinedInput,
  Rating,
  Select,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useRef, useState } from "react";

import Pic1 from "../../../assets/StaffImg/1.jpg";
import Pic2 from "../../../assets/StaffImg/2.jpg";
import { getDateShamsiAirSensors } from "Utils/Utils";
import DatePicker from "react-multi-date-picker";
import calendar from "../../../assets/calendar.svg";
import calendarLight from "../../../assets/calendar-light.svg";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useSelector } from "react-redux";
import WidgetWrapper from "components/WidgetWrapper";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import MobileBottomSidebar from "components/MobileBottomSidebar/MobileBottomSidebar";
import RecordTaskMob from "./RecordTaskMob";
import { Link } from "react-router-dom";
import TaskItem from "components/TaskItem/TaskItem";
import { staffData } from "Mock/StaffData";

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */

  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
  zIndex: 20000000,
}));
const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["انجام شده", "انجام نشده", "در حال انجام"];

// const mockTasks = [
//   {
//     id: "a61a720d-da1a-418e-a3d5-a17afd854d3b",
//     name: "سعید صمیمی",
//     title: "سم پاشی ناحیه 3",
//     priority: "فوری - مهم",
//     taskDescription: "",
//     backupAction: "ماشین سم پاشی شماره 3",
//     status: "در حال انجام",
//   },
//   {
//     id: "5f404a44-0942-4e42-b527-b6ad78603a94",
//     name: "امیر محمدی",
//     title: "آبیاری ناحیه 12",
//     priority: "فوری - مهم",
//     taskDescription: "",
//     backupAction: "ماشین آبیاری شماره 3",
//     status: "انجام شده",
//   },
// ];
const StaffMgmtMob = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const datePickerRef = useRef();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [newTask, setNewTask] = useState(false);
  const [tasks, setTasks] = useState(staffData);
  const [filtered, setFiltered] = useState(tasks);
  const [selectedUser, setSelectedUser] = useState();
  const [selectUser, setSelectUser] = useState(0);
  const [date, setDate] = useState(getDateShamsiAirSensors(new Date()));
  const handleDateChange = (value) => {
    setDate(getDateShamsiAirSensors(value));
  };
  const [personName, setPersonName] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const tmp = tasks.filter(
      (item) => item.name.includes(value) || item.title.includes(value)
    );
    setFiltered(tmp);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const tmp = tasks.filter((item) => item.status === value);
    setFiltered(tmp);
  };
  return (
    <>
      <MobileBottomSidebar
        is_open={newTask}
        setOpen={setNewTask}
        height={"80vh"}
        initial_height="0px"
      >
        <Box minWidth="100%" height="83vh">
          <Typography variant="h3" color="fontColor.main">
            ثبت وظیفه
          </Typography>
          <Box
            width="100%"
            height="95%"
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
          >
            <RecordTaskMob
              setNewTask={setNewTask}
              setTasks={setTasks}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          </Box>
        </Box>
      </MobileBottomSidebar>
      <Box
        pt="1rem"
        sx={{
          overflowY: "auto",
          maxHeight: "calc(100dvh - 112px)",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-button": {
            height: "1px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#5A596F",
            outline: `0px solid slategrey`,
            borderRadius: "5px",
          },
        }}
      >
        <Stack
          direction="row"
          gap="0.5rem"
          alignItems="center"
          sx={{
            overflowY: "hidden !important",
            overflowX: "auto",
            overflow: "-moz-scrollbars-none",
            "-ms-scrollbar-style": "none",
            scrollbarWidth: "none",
            mb: "1rem",
            px: "0.5rem",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <FlexBetween
            sx={{
              transition: "all 0.2s ease",

              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              height: "30px",
            }}
          >
            <InputBase
              disableUnderline
              placeholder="جست و جو"
              value={search}
              onChange={handleSearch}
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${main} !important`,
                  },
                },
                height: "30px",
                width: "158px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <Box
            sx={{
              padding: "0 1rem",
              bottom: 20,
              width: "163px",
              height: "30px",
              background: "transparent",
              // zIndex: 1000,
              borderRadius: "8px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ddd",
              },
              "& .rmdp-wrapper": {
                backgroundColor: theme.palette.background.third,
                // width: "250px",
                borderRadius: "8px",
                overflow: "hidden",
              },
              "& .rmdp-container": {
                width: "100%",
                display: "block",
              },
              "& .rmdp-day": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-arrow": {
                borderColor: theme.palette.fontColor.main,
              },
              "& .rmdp-week-day": {
                color: theme.palette.fontColor.alt,
              },
              "& .rmdp-header-values span": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-panel-header": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-ep-arrow": {
                borderBottom: "none",
                borderTop: "none",
              },
              "& .ep-arrow::after": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-ep-shadow:after": {
                boxShadow: "none",
              },
              "& .rmdp-month-picker": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-year-picker": {
                backgroundColor: theme.palette.background.third,
              },
              "& .rmdp-input": {
                color: "white !important",
                fontFamily: "YekanBakhFaNum-Regular",
              },
              "& .rmdp-top-class": {
                backgroundColor: theme.palette.background.third,
                color: "#fff",
              },
              "& .rmdp-day.rmdp-today span": {
                backgroundColor: "rgba(42,128,244,0.5)",
              },
              "& .rmdp-day.rmdp-selected span:not(.highlight)": {
                backgroundColor: theme.palette.primary.alt,
              },
              "& .rmdp-day .sd": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-week .rmdp-week-day": {
                color: theme.palette.fontColor.alt,
              },
              "& .rmdp-header-values": {
                color: theme.palette.fontColor.main,
              },
              "& .rmdp-shadow": {
                boxShadow: "none",
              },
              "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover":
                {
                  backgroundColor: theme.palette.primary.alt,
                },
            }}
          >
            <DatePicker
              render={
                <div
                  onClick={() => datePickerRef.current.openCalendar()}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    gap: "0.5rem",
                  }}
                >
                  <img
                    src={mode === "dark" ? calendar : calendarLight}
                    alt="calendar icon"
                    sx={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                  <div>{date}</div>
                </div>
              }
              calendarPosition={`bottom-center`}
              className="bg-dark rmdp-prime"
              calendar={persian}
              locale={persian_fa}
              weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
              value={date}
              onChange={handleDateChange}
              ref={datePickerRef}
            />
          </Box>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={personName}
            onChange={handleChange}
            input={<OutlinedInput label="" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
            IconComponent={ListOutlined}
            sx={{
              width: "120px",
              height: "30px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              boxShadow: "none !important",
              "& .MuiList-root ": {
                padding: "0 !important",
                background: "#f00",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
          >
            {names.map((name) => (
              <StyledMenuList
                sx={{ display: "flex", alignItems: "center" }}
                key={name}
                value={name}
              >
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuList>
            ))}
          </Select>

          <Select
            value={selectUser}
            onChange={(e) => setSelectUser(e.target.value)}
            MenuProps={{
              // Apply the custom styled MenuList component
              MenuListProps: { component: StyledMenuList },
            }}
            sx={{
              width: "125px",
              height: "30px",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              borderRadius: "8px",
              boxShadow: "none !important",
              "& .MuiPaper-root": {
                zIndex: 20000000,
              },
              "& .MuiPaper-elevation": {
                boxShadow: "none !important",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#40405e",
              },
            }}
            IconComponent={PersonOutlineOutlined}
          >
            <StyledMenuList
              value={0}
              sx={{
                display: "flex",
                justifyContent: "center",
                m: "0 5px",
                borderRadius: "4px",
                backgroundColor: `${theme.palette.background.third} !important`,
                ":hover": {
                  backgroundColor: `${theme.palette.fontColor.third} !important`,
                },
              }}
            >
              همه کارکنان
            </StyledMenuList>
            {staffData.map((item, index) => (
              <StyledMenuList
                value={index + 1}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  m: "0 5px",
                  borderRadius: "4px",
                  backgroundColor: `${theme.palette.background.third} !important`,
                  ":hover": {
                    backgroundColor: `${theme.palette.fontColor.third} !important`,
                  },
                }}
              >
                {item.name}
              </StyledMenuList>
            ))}
          </Select>
        </Stack>
        <WidgetWrapper mb="0.5rem" mx={"0.5rem"}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="h4" color={main}>
              مدیریت پرسنل
            </Typography>
            <Box
              // width="121px"
              px="1rem"
              height="36px"
              borderRadius="8px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: theme.palette.others.first,
                p: "0 0.7rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setNewTask(true);
                setSelectedTask(null);
              }}
            >
              <Stack direction="row" alignItems="center" gap="0.2rem">
                <AddOutlined sx={{ width: "24px", height: "24px" }} />
                <Typography>وظیفه جدید</Typography>
              </Stack>
            </Box>
          </Stack>
          <Box my="1rem">
            {filtered.map((item) => {
              return (
                <TaskItem
                  item={item}
                  handleClickTask={() => {
                    setSelectedTask(item);
                    setNewTask(true);
                  }}
                />
              );
            })}
          </Box>
        </WidgetWrapper>
        <WidgetWrapper
          p="0.5rem"
          // width="100%"
          mx={"0.5rem"}
          // height="42%"
        >
          <WidgetWrapper
            mb="0.5rem"
            sx={{
              backgroundColor: theme.palette.background.third,
              width: "100%",
            }}
          >
            <Box display="flex" gap="0.5rem">
              <Typography variant="h5" color={main} mb="0.5rem">
                شاخص عملکرد کارکنان
              </Typography>
            </Box>
            <AnimatedProgressBar
              number={70}
              width={157}
              height={163}
              title="%70"
              subTitle=""
              color={theme.palette.others.third}
              dot={false}
              secondColor={theme.palette.background.forth}
            />
            <FlexBetween>
              <Box display="flex" alignItems="center" gap="0.2rem">
                <Typography
                  variant="smallestTextSecondary"
                  mt="3px"
                  color={alt}
                >
                  کل:
                </Typography>
                <Typography variant="h3" color={main}>
                  35
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="0.2rem">
                <Typography
                  variant="smallestTextSecondary"
                  mt="3px"
                  color={alt}
                >
                  انجام شده:
                </Typography>
                <Typography variant="h3" color={main}>
                  28
                </Typography>
              </Box>
            </FlexBetween>
          </WidgetWrapper>
          <WidgetWrapper
            mb="0.5rem"
            width="100%"
            height="49%"
            sx={{
              backgroundColor: theme.palette.background.third,
              padding: "1rem 1rem 1.1rem 0",
              overflow: "hidden",
            }}
          >
            <Typography variant="h5" color={main} mb="0.75rem">
              عملکرد کارکنان
            </Typography>
            <Box
              sx={{
                height: "93%",
                overflow: "auto",

                "&::-webkit-scrollbar": {
                  width: 8,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-button": {
                  height: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5A596F",
                  outline: `0px solid slategrey`,
                  borderRadius: "5px",
                },
              }}
            >
              {staffData.map((item, index) => (
                <Link to={`/staffmgmt/${item.id}`}>
                  <Box
                    width="95%"
                    height="64px"
                    key={index}
                    mb="0.5rem"
                    sx={{
                      p: "0.5rem",
                      backgroundColor:
                        selectedUser === item.id
                          ? theme.palette.background.forth
                          : "transparent",

                      cursor: "pointer",
                      borderRadius: "4px",
                      ":hover": {
                        backgroundColor: theme.palette.background.forth,
                        borderRadius: "4px",
                      },
                    }}
                  >
                    <FlexBetween>
                      <Stack
                        direction="row"
                        gap="0.5rem"
                        sx={{ alignItems: "center" }}
                      >
                        <Box
                          width="24px"
                          height="24px"
                          component="img"
                          src={item.pic}
                          borderRadius="99px"
                        />
                        <Typography variant="smallestTextPrimary" color={main}>
                          {item.name}
                        </Typography>
                      </Stack>
                      <Rating
                        size="small"
                        name="read-only"
                        value={item.score}
                        readOnly
                        // sx={{ direction: "ltr" }}
                      />
                    </FlexBetween>
                    <Stack direction="row" alignItems="center" gap="0.5rem">
                      <Typography variant="smallestTextSecondary" color={alt}>
                        وظایف انجام شده:
                      </Typography>
                      <Typography variant="body" color={main}>
                        {item.done}/{item.allTasks}
                      </Typography>
                    </Stack>
                  </Box>
                </Link>
              ))}
            </Box>
          </WidgetWrapper>
        </WidgetWrapper>
      </Box>
    </>
  );
};

export default StaffMgmtMob;
