import {
  AddBoxOutlined,
  AddOutlined,
  Dashboard,
  DashboardOutlined,
  PeopleAlt,
  PeopleAltOutlined,
  SatelliteAlt,
  SatelliteAltOutlined,
  SettingsRemote,
  SettingsRemoteOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { setSelectedItemMob } from "States";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EventIcon from "../../assets/icon/event.svg";
import SensorIcon from "../../assets/icon/sensor.svg";
import FarmIcon from "../../assets/icon/farm.svg";
import HealtMgmtOutLinedIcon from "../../assets/Icons/healthicons_animal-cow-outline.png";
import HealthMgmtIcon from "../../assets/Icons/healthicons_animal-cow.png";
import CompareOutlinedIcon from "../../assets/Icons/Comparison.png";
import CompareIcon from "../../assets/Icons/check.png";
const TabbarMob = () => {
  const theme = useTheme();
  const alt = theme.palette.fontColor.alt;
  const dispatch = useDispatch();
  const selectedItemMob = useSelector((state) => state.global.selectedItemMob);
  const [open_record, setOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <FlexBetween
      sx={{
        width: "100%",
        height: "56px",
        position: "fixed",
        bottom: 0,
        borderRadius: "12px 12px 0 0",
        backgroundColor: theme.palette.background.second,
        p: "0 0.5rem",
        zIndex: "1000",
        mt: "0.9rem",
        boxShadow: "0 -4px 15px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          dispatch(setSelectedItemMob({ selectedItemMob: 1 }));
          navigate("/");
        }}
      >
        {selectedItemMob === 1 ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Dashboard />
            {/* <Dashboard sx={{ color: theme.palette.others.first }} /> */}
            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت جامع
            </Typography>
          </Box>
        ) : (
          <DashboardOutlined />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          dispatch(setSelectedItemMob({ selectedItemMob: 2 }));
          navigate("/livestockmanagement");
        }}
      >
        {selectedItemMob === 2 ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                color: theme.palette.others.first,
              }}
              component="img"
              src={HealtMgmtOutLinedIcon}
            />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت دام
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{ width: "24px", height: "24px" }}
            component="img"
            src={HealthMgmtIcon}
          />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          dispatch(setSelectedItemMob({ selectedItemMob: 4 }));
          navigate("/compare");
        }}
      >
        {selectedItemMob === 4 ? (
          <Box
            width="70px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
                color: theme.palette.others.first,
              }}
              component="img"
              src={CompareOutlinedIcon}
            />

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مقایسه
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{ width: "24px", height: "24px" }}
            component="img"
            src={CompareIcon}
          />
        )}
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        width="20%"
        onClick={() => {
          dispatch(setSelectedItemMob({ selectedItemMob: 8 }));
          navigate("/staffmgmt");
        }}
      >
        {selectedItemMob === 8 ? (
          <Box
            width="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <PeopleAltOutlined />
            {/* <PeopleAltOutlined sx={{ color: theme.palette.others.first }} /> */}

            <Typography
              color={alt}
              fontSize={theme.typography.smallestTextSecondary}
            >
              مدیریت کارکنان
            </Typography>
          </Box>
        ) : (
          <PeopleAlt />
        )}
      </Stack>
      <Box
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          position: "absolute",
          width: "38px",
          height: "38px",
          top: 5,
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "38px",
            height: "38px",
            backgroundColor: theme.palette.background.second,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:before": {
              content: '""',
              position: "absolute",
              width: "40px",
              height: "40px",
              top: "50%",
              right: "50%",
              transform: "translate(50%, -50%)",
              zIndex: -2,
              borderRadius: "inherit" /* !importanté */,
              background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
            },
          }}
        >
          <AddOutlined
            sx={{
              width: "75%",
              height: "75%",
              color: alt,
              rotate: open_record ? "135deg" : "0deg",
              transition: "rotate 0.3s",
            }}
          />
          <Link to="/recordSensors">
            <Box
              sx={{
                content: '""',
                position: "absolute",
                width: "40px",
                height: "40px",
                top: open_record ? "-35%" : "50%",
                right: open_record ? "180%" : "50%",
                transition: "all 0.3s",
                transform: "translate(50%, -50%)",
                zIndex: -2,
                borderRadius: "50%",
                background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="record sensor" src={SensorIcon} />
            </Box>
          </Link>
          <Link to="/recordfarm">
            <Box
              sx={{
                content: '""',
                position: "absolute",
                width: "40px",
                height: "40px",
                top: open_record ? "-80%" : "50%",
                right: "50%",
                transition: "all 0.3s",
                transform: "translate(50%, -50%)",
                zIndex: -2,
                borderRadius: "50%",
                background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="record farm" src={FarmIcon} />
            </Box>
          </Link>
          <Link to="/recordEvents">
            <Box
              sx={{
                content: '""',
                position: "absolute",
                width: "40px",
                height: "40px",
                top: open_record ? "-35%" : "50%",
                right: open_record ? "-80%" : "50%",
                transition: "all 0.3s",
                transform: "translate(50%, -50%)",
                zIndex: -2,
                borderRadius: "50%",
                background: "linear-gradient(45deg,  #2D2D51,#2A80F4)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img alt="record event" src={EventIcon} />
            </Box>
          </Link>
        </Box>
      </Box>
    </FlexBetween>
  );
};

export default TabbarMob;
