import { Radio } from "@mui/material";
import {
  Box,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
  useThemeProps,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import ProgressBar from "components/progressbar/ProgressBar";
import React, { useEffect, useState } from "react";

import SmartIndexChart from "components/SmartIndexChart/SmartIndexChart";
import AnimatedProgressBarWallet from "scenes/Wallet/AnimatedProgressbarWallet";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import CopyRight from "widgets/CopyRight";
import SmartIndexexChart from "./SmartIndexesChart";
const smartIndexData = [
  { id: 1, title: "عملکرد کارکنان", value: 30 },
  { id: 2, title: "زایش ها", value: 83 },
  { id: 3, title: "فحلی ها", value: 48 },
  { id: 4, title: "کل دام", value: 78 },
  { id: 5, title: "واکسن", value: 95 },
  { id: 6, title: "سم چینی", value: 65 },
  { id: 7, title: "خشکی", value: 43 },
];
const SmartIndexes = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const stp = theme.typography.smallestTextPrimary;
  const sts = theme.typography.smallestTextSecondary;
  const [selectedIndex, setSelectedIndex] = useState(smartIndexData[0]);
  console.log(
    "🚀 ~ file: SmartIndexes.jsx:37 ~ SmartIndexes ~ selectedIndex:",
    selectedIndex
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth="1235px"
      width="calc(100% - 2rem)"
      height="89vh"
      m="16px"
      // sx={{ backgroundColor: "red" }}
    >
      <Stack direction="row" gap="16px">
        <WidgetWrapper width="25.5vw" height="84vh">
          <Typography variant="h3" color={main} mb="1rem">
            شاخص هوشمند
          </Typography>
          {smartIndexData.map((item, index) => (
            <FlexBetween
              key={index}
              width="100%"
              height="47px"
              p="0 0.5rem"
              mb="5px"
              sx={{
                backgroundColor:
                  index === selectedIndex.id - 1
                    ? theme.palette.background.third
                    : "transparent",
                borderRadius: index === selectedIndex.id - 1 ? "4px" : 0,
                ":hover": {
                  backgroundColor: theme.palette.background.third,
                  borderRadius: "4px",
                  cursor: "pointer",
                },
              }}
              onClick={() => setSelectedIndex(item)}
            >
              <Typography variant="h5" color={main}>
                {item.title}
              </Typography>

              <Box display="flex" flexDirection="column">
                <Typography
                  alignSelf="flex-end"
                  variant="h4"
                  color={
                    item.value <= 30
                      ? theme.palette.others.second
                      : (item.value > 30 && item.value) < 50
                      ? theme.palette.others.forth
                      : theme.palette.others.third
                  }
                >
                  %{item.value}
                </Typography>

                <Box
                  width="9.063rem"
                  height="0.25rem"
                  sx={{
                    backgroundColor: theme.palette.background.forth,
                    position: "relative",
                    borderRadius: "1rem",
                  }}
                >
                  <ProgressBar
                    bgcolor={
                      item.value <= 30
                        ? theme.palette.others.second
                        : (item.value > 30 && item.value) < 50
                        ? theme.palette.others.forth
                        : theme.palette.others.third
                    }
                    completed={item.value}
                  />
                </Box>
              </Box>
            </FlexBetween>
          ))}
        </WidgetWrapper>
        <Box
          width="calc(100% - 25.5vw)"
          height="84vh"
          display="flex"
          flexDirection="column"
          gap="16px"
        >
          <Box width="100%" height="49%" display="flex" gap="16px">
            <WidgetWrapper width="75.5%" height="100%">
              <FlexBetween>
                <Box display="flex" gap="0.5rem">
                  <Typography variant="h4" color={main}>
                    شاخص هوشمند کل دامداری
                  </Typography>
                </Box>
                <Box display="flex" gap="0.5rem" ml="1rem">
                  <FormControlLabel
                    value="وضعیت دامداری"
                    control={
                      <Radio
                        checked
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    }
                    label="وضعیت دامداری"
                  />
                  <FormControlLabel
                    value="شاخص نرمال"
                    control={
                      <Radio
                        checked
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: theme.palette.primary.alt,
                          },
                        }}
                      />
                    }
                    label="شاخص نرمال"
                  />
                </Box>
              </FlexBetween>
              <Box mt="2rem" width="100%" height="70%">
                <SmartIndexexChart
                  yNumbers={true}
                  data1={[50, 25, 15, 20, 50, 65, 50, 15, 40]}
                  data2={[30, 25, 30, 50, 30, 45, 35, 32, 32]}
                />
              </Box>
            </WidgetWrapper>
            <WidgetWrapper width="23%" height="100%">
              <Typography variant="h4" color={main}>
                وضعیت کل دامداری
              </Typography>
              <Box
                width="100%"
                height="85%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AnimatedProgressBar
                  number={70}
                  width={157}
                  height={163}
                  title="%70"
                  subTitle=""
                  color={theme.palette.others.third}
                  dot={false}
                  bigTitle={true}
                />
              </Box>
            </WidgetWrapper>
          </Box>

          <Box width="100%" height="49%" display="flex" gap="16px">
            <WidgetWrapper width="75.5%" height="100%">
              <FlexBetween>
                <Box display="flex" gap="0.5rem">
                  <Typography variant="h4" color={main}>
                    شاخص هوشمند
                  </Typography>
                  <Typography variant="h4" color={main}>
                    {selectedIndex.title}
                  </Typography>
                  <Typography variant="subHeading" color={main}>
                    (درصد)
                  </Typography>
                </Box>
                <Box display="flex" gap="0.5rem" ml="1rem">
                  <FormControlLabel
                    value="عملکرد کارگر"
                    control={
                      <Radio
                        checked
                        sx={{
                          color: "red",
                          "&.Mui-checked": {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    }
                    label="عملکرد کارگر"
                  />
                  <FormControlLabel
                    value="شاخص نرمال"
                    control={
                      <Radio
                        checked
                        sx={{
                          "&.Mui-checked": {
                            color: "#3DCCB2",
                          },
                        }}
                      />
                    }
                    label="شاخص نرمال"
                  />
                </Box>
              </FlexBetween>
              <Box mt="2rem" width="100%" height="70%">
                <SmartIndexexChart
                  fcolor="rgba(61, 204, 178, 0.4)"
                  yNumbers={true}
                  borderColor="#3DCCB2"
                  data1={[60, 25, 20, 40, 80, 65, 50, 15, 40]}
                  data2={[30, 25, 30, 50, 30, 45, 35, 32, 32]}
                />
              </Box>
            </WidgetWrapper>
            <WidgetWrapper width="23%" height="100%">
              <Box display="flex" gap="0.5rem">
                <Typography variant="h4" color={main}>
                  وضعیت
                </Typography>
                <Typography variant="h4" color={main}>
                  {selectedIndex.title}
                </Typography>
              </Box>
              <Box
                width="100%"
                height="85%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AnimatedProgressBar
                  number={selectedIndex.value}
                  width={157}
                  height={163}
                  title={`%${selectedIndex.value}`}
                  subTitle=""
                  color={
                    selectedIndex.value <= 30
                      ? theme.palette.others.second
                      : (selectedIndex.value > 30 && selectedIndex.value) < 50
                      ? theme.palette.others.forth
                      : theme.palette.others.third
                  }
                  dot={false}
                  bigTitle={true}
                />
              </Box>
            </WidgetWrapper>
          </Box>
        </Box>
      </Stack>
      <CopyRight />
    </Box>
  );
};

export default SmartIndexes;
