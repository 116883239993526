import { AddOutlined, MoreHoriz, Search } from "@mui/icons-material";
import {
  useTheme,
  Box,
  Divider,
  FormControlLabel,
  InputBase,
  Radio,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useState } from "react";

import CustomSelectBoxComfort from "./CustomSelectBoxComfort";
import LiveDualLineChart from "./LiveDualLineChart";
import DownloadIcon from "../../assets/Icons/download.png";
import ArrowIcon from "../../assets/Icons/arrow.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import {
  AnimalComfortData,
  AnimalComfortHistoryData,
} from "Mock/AnimalComfortData";

const AnimalComfort = ({ selectedTitle }) => {
  const theme = useTheme();

  const items = AnimalComfortData.map((item) => item.title);
  const [selectedItem, setSelectedItem] = useState(items[[0]]);
  const [selectedBaharband, setSelectedBaharband] = useState("همه بهاربندها");
  const [toggle, setToggle] = useState(false);

  const [index, setIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setIndex(activeIndex);
  };

  const filteredDate = AnimalComfortData.filter(
    (item) => item.title === selectedItem
  );

  const filteredHistory =
    selectedBaharband === "همه بهاربندها"
      ? AnimalComfortHistoryData
      : AnimalComfortHistoryData.filter(
          (item) => item.title === selectedBaharband
        );

  let filteredAndSortedData = [...filteredHistory];

  if (toggle) {
    // Filter the data based on date in ascending order
    filteredAndSortedData = filteredAndSortedData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
  } else {
    // Sort the data based on date in descending order
    filteredAndSortedData = filteredAndSortedData.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  }
  return (
    <Box width="100%" height="100%" display="flex" gap="16px">
      <Box
        width="62.4%"
        height="100%"
        display="flex"
        flexDirection="column"
        gap="16px"
      >
        <WidgetWrapper width="100%" flex={1}>
          <FlexBetween>
            <FlexBetween gap="8px">
              <Typography variant="h4" color="fontColor.main">
                وضعیت آسایش دام
              </Typography>
              <CustomSelectBoxComfort
                width="124px"
                height="33px"
                items={items}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
              />
            </FlexBetween>
            <FlexBetween>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.sixth",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  بهاربند انتخاب شده
                </Typography>
              </Stack>
              <Stack direction="row" gap="16px">
                <FormControlLabel
                  value="female"
                  control={
                    <Radio
                      sx={{
                        width: "12px",
                        height: "12px",
                        "&, &.Mui-checked": {
                          color: "others.first",
                        },
                      }}
                      checked={true}
                    />
                  }
                />
                <Typography variant="smallestTextPrimary" color="fontColor.alt">
                  آسایش کل دام
                </Typography>
              </Stack>
            </FlexBetween>
          </FlexBetween>
          <LiveDualLineChart
            data1={filteredDate[0]?.baharband}
            data2={filteredDate[0].all}
            color1={theme.palette.others.first}
            colorLine="#7BFD9F"
            color2={theme.palette.primary.third}
            r2={58}
            g2={198}
            b2={173}
            r1={58}
            g1={198}
            b1={173}
          />
        </WidgetWrapper>
        <WidgetWrapper width="100%" flex={1}>
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography variant="h4" color="fontColor.main">
              آسایش دام بهاربند ها
            </Typography>
            <Typography variant="smallestTextSecondary" color="fontColor.alt">
              1 ماه گذشته
            </Typography>
          </Stack>

          <Box width="100%" overflow="hidden" mt="1.5rem">
            <Box
              // width="700px"
              sx={{
                "& .swiper-custom .swiper-button-prev": {
                  margin: 0,
                  borderRadius: " 0.25rem",
                  position: "absolute",
                  right: "-10px !important",
                  top: "60px",
                },
                "& .swiper-custom1 .swiper-button-next": {
                  margin: 0,
                  borderRadius: " 0.25rem",
                  position: "absolute",
                  left: "10px !important",
                  top: "50px",
                },
                "& .swiper-button-prev::after": {
                  content:
                    'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M0 10.7922L5.10696 6.11456L0 1.43692L1.57223 0L8.26257 6.11456L1.57223 12.2291L0 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
                  fontSize: "1rem",
                  marginTop: "-0.525rem",
                  color: "#F7F7F7", // Set the color you want for the content
                },
                "& .swiper-button-next::after": {
                  content:
                    'url(\'data:image/svg+xml,%0A%3Csvg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.26257 10.7922L3.15561 6.11456L8.26257 1.43692L6.69034 0L3.8147e-06 6.11456L6.69034 12.2291L8.26257 10.7922Z" fill="%23F7F7F7"/%3E%3C/svg%3E%0A\') !important',
                  fontSize: "1rem",
                  marginTop: "-0.525rem",
                  marginLeft: "-1.75rem",
                  color: "#F7F7F7", // Set the color you want for the content
                },
              }}
            >
              <Swiper
                navigation={true}
                // spaceBetween={50}
                slidesPerView={3}
                modules={[Navigation]}
                style={{ marginTop: "1rem" }}
                className="swiper-custom"
                onSlideChange={(swiper) => handleSlideChange(swiper)}
              >
                {AnimalComfortData.map((item, index) => (
                  <Box display="flex" alignItems="center">
                    <SwiperSlide key={index}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <AnimatedProgressBar
                          number={item.mean}
                          width={157}
                          height={163}
                          title={`%${item.mean}`}
                          subTitle=""
                          color={
                            item.mean >= 80
                              ? theme.palette.others.third
                              : item.mean < 50
                              ? theme.palette.others.second
                              : theme.palette.others.forth
                          }
                          dot={false}
                          secondColor={theme.palette.background.forth}
                        />
                        <Typography>{item.title}</Typography>
                      </Box>
                    </SwiperSlide>
                  </Box>
                ))}
              </Swiper>
            </Box>
          </Box>
        </WidgetWrapper>
      </Box>
      <WidgetWrapper width="36.5%" height="100%">
        <FlexBetween>
          <CustomSelectBoxComfort
            width="124px"
            height="33px"
            items={items}
            setSelectedItem={setSelectedBaharband}
            selectedItem={selectedBaharband}
            title="همه بهاربندها"
            all={true}
          />
          <Box component="img" src={DownloadIcon} />
        </FlexBetween>

        <Box style={{ height: "80%", width: "100%" }}>
          <FlexBetween
            mt="0.5rem"
            sx={{ width: "100%", justifyContent: "flex-start" }}
          >
            <Box
              width="33%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="fontColor.alt">بهاربند</Typography>
            </Box>

            <Box
              width="33%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                تاریخ
              </Typography>
              <IconButton onClick={() => setToggle(!toggle)}>
                <Box
                  component="img"
                  src={ArrowIcon}
                  sx={{ rotate: toggle ? 0 : "180deg" }}
                />
              </IconButton>
            </Box>

            <Box
              width="33%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                color="fontColor.alt"
                fontSize={theme.typography.smallestTextSecondary}
              >
                آسایش
              </Typography>
            </Box>
          </FlexBetween>

          <Divider sx={{ margin: "12px 0" }} />
          <Box
            sx={{
              height: "100%",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                borderRadius: "5px",
              },
              "&::-webkit-scrollbar-button": {
                height: "1px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#5A596F",
                outline: `0px solid slategrey`,
                borderRadius: "5px",
              },
            }}
          >
            {filteredAndSortedData.map((item, index) => (
              <>
                <FlexBetween
                  mt="0.5rem"
                  sx={{ width: "100%", justifyContent: "flex-start" }}
                >
                  <Box
                    width="33%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography color="fontColor.alt">{item.title}</Typography>
                  </Box>

                  <Box
                    width="33%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      color="fontColor.alt"
                      fontSize={theme.typography.smallestTextSecondary}
                    >
                      {item.date}
                    </Typography>
                  </Box>

                  <Box
                    width="33%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      width="47px"
                      height="23px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="4px"
                      sx={{
                        backgroundColor:
                          item.comfort <= 50
                            ? theme.palette.others.second
                            : item.comfort > 70
                            ? theme.palette.others.third
                            : theme.palette.others.forth,
                      }}
                    >
                      <Typography
                        color={theme.palette.background.first}
                        fontSize={theme.typography.smallestTextSecondary}
                      >
                        {item.comfort}
                      </Typography>
                    </Box>
                  </Box>
                </FlexBetween>
                <Divider sx={{ margin: "12px 0" }} />
              </>
            ))}
          </Box>
        </Box>
      </WidgetWrapper>
    </Box>
  );
};

export default AnimalComfort;
