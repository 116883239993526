import { MoreHoriz } from "@mui/icons-material";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Table from "components/table/Table";
import React from "react";
import TickIcon from "../assets/tick.png";
import DropIcon from "../assets/notick.png";
import HealthStatusData from "Mock/HealthStatusData";

const HealthStatus = () => {
  const theme = useTheme();
  const tableData = HealthStatusData?.map((row) => {
    return {
      data: {
        "کد دام": (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="1rem"
          >
            <Typography
              color="fontColor.main"
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.code}
            </Typography>
          </Box>
        ),
        وضعیت: (
          <Typography
            color="fontColor.main"
            fontSize={theme.typography.smallestTextPrimary}
          >
            {row.status}
          </Typography>
        ),
        "شاخص سلامت": (
          <Typography
            color={
              row.index < 2
                ? "others.second"
                : row.index >= 3
                ? "others.forth"
                : "others.third"
            }
            fontSize={theme.typography.smallestTextPrimary}
          >
            {row.index}
          </Typography>
        ),
        لنگش: (
          <Box component="img" src={row.l === true ? TickIcon : DropIcon} />
        ),
      },
    };
  });
  return (
    <Box width="100%" height="100%">
      <FlexBetween>
        <Typography variant="h5" color="fontColor.main">
          وضعیت سلامت
        </Typography>
        <MoreHoriz />
      </FlexBetween>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%", height: "90%" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <AnimatedProgressBar
            number={(1730 / 1750) * 100}
            width={157}
            height={163}
            title="1730"
            subTitle="1750"
            color={theme.palette.others.first}
            dot={false}
            secondColor={theme.palette.background.forth}
          />
          <Typography variant="smallestTextPrimary" color="fontColor.alt">
            دام های سالم
          </Typography>
        </Box>
        <Box width="70%" maxHeight="150px" display="flex">
          <Table expandable={false} data={tableData} />
        </Box>
      </Box>
    </Box>
  );
};

export default HealthStatus;
