import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { setSelectedItemMob } from "States";
import { store } from "States/rootReducer";
import WidgetWrapper from "components/WidgetWrapper";
import AlarmPNG from "../../../assets/AlarmDark.png";
import React, { useState } from "react";
import FahlMob from "./FahlMob";
import HealthStatusMob from "./HealthStatusMob";

const DashboardMob = () => {
  const theme = useTheme();

  store.dispatch(
    setSelectedItemMob({
      selectedItemMob: 1,
    })
  );

  return (
    <Box
      sx={{
        overflow: "auto",
        height: "calc(100dvh - 112px)",
        p: "12px",
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",

          position: "relative",
        }}
      >
        <WidgetWrapper
          sx={{
            height: "36px",
            padding: "0rem 1rem 0rem 1.188rem",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Box component="img" src={AlarmPNG} />
          <Typography variant="body" color={theme.palette.fontColor.alt}>
            {/* {recAlerts.typeName ? recAlerts.typeName : "هشداری نیست"} */}
            لنگش گاو 213
          </Typography>
        </WidgetWrapper>
        <FahlMob />
        <HealthStatusMob />
      </Box>
    </Box>
  );
};

export default DashboardMob;
