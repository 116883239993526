import { Box, Stack, Typography } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import WeatherIcon from "../assets/weather-b.png";
import WaterIcon from "../assets/water.png";
const DailyWeather = () => {
  return (
    <Box>
      <FlexBetween>
        <Typography variant="smallestTextPrimary" color="fontColor.main">
          امروز
        </Typography>
        <Typography variant="smallestTextSecondary" color="fontColor.alt">
          1402/04/31
        </Typography>
      </FlexBetween>
      <FlexBetween>
        <Stack direction="row" alignItems="center" gap="0.5rem">
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ direction: "ltr" }}
          >
            <Typography variant="h1" color="fontColor.main">
              13 <sup>°</sup>
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography variant="body" color="fontColor.alt">
                16 <sup>°</sup> |
              </Typography>

              <Typography variant="body" color="fontColor.main">
                20 <sup>°</sup>
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="subHeading" color="fontColor.main">
              %75
            </Typography>
            <Box component="img" src={WaterIcon} width="12px" height="12px" />
          </Stack>
        </Stack>
        <Box component="img" src={WeatherIcon} width="154px" height="110px" />
      </FlexBetween>
    </Box>
  );
};

export default DailyWeather;
