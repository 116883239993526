const planData = {
  "economic1": {
    "title": "بسته اقتصادی 1",
    "active": true,
    "period": "یک ساله",
    "price": 2.5,
    "unit": "میلیون تومان",
    "livestockManagement": true,
    "smartIndex": true,
    "weather": true,
    "staffManagement": false,
    "alarms": false,
    "recommends": false,
    "actuaturs": false,
    "inventory": false,
    "accountant": false
  },
  "economic2": {
    "title": "بسته اقتصادی 2",
    "active": true,
    "period": "یک ساله",
    "price": 3.5,
    "unit": "میلیون تومان",
    "livestockManagement": true,
    "smartIndex": true,
    "weather": true,
    "staffManagement": false,
    "alarms": false,
    "recommends": false,
    "actuaturs": false,
    "inventory": false,
    "accountant": false
  },
  "economic3": {
    "title": "بسته اقتصادی 3",
    "active": false,
    "period": "یک ساله",
    "price": "5.5",
    "unit": "میلیون تومان",
    "livestockManagement": true,
    "smartIndex": true,
    "weather": true,
    "staffManagement": true,
    "alarms": true,
    "recommends": false,
    "actuaturs": false,
    "inventory": false,
    "accountant": false
  },
  "economic4": {
    "title": "بسته اقتصادی 4",
    "active": false,
    "period": "یک ساله",
    "price": "6.5",
    "unit": "میلیون تومان",
    "livestockManagement": true,
    "smartIndex": true,
    "weather": true,
    "staffManagement": true,
    "alarms": true,
    "recommends": true,
    "actuaturs": false,
    "inventory": false,
    "accountant": false
  },
  "fullPack": {
    "title": "بسته کامل",
    "active": false,
    "period": "یک ساله",
    "price": "7.5",
    "unit": "میلیون تومان",
    "livestockManagement": true,
    "smartIndex": true,
    "weather": true,
    "staffManagement": true,
    "alarms": true,
    "recommends": true,
    "actuaturs": true,
    "inventory": true,
    "accountant": true
  }
};

module.exports = planData;
