import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
Chart.register(...registerables);
Chart.register(annotationPlugin);
const ChangeTempChartMob = ({ th1, th2, fillColor, temperatures }) => {
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  const chartData = temperatures;
  const m = Math.max.apply(null, chartData);
  const max = m * 1.5;
  const chartBarData = [max, max, max, max, max, max, max, max];
  const dataLabels = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p1.parsed.y > 30 || ctx.p0.parsed.y > 30 ? value : undefined;
  const data = {
    labels: dataLabels,
    datasets: [
      {
        type: "bar",
        label: "First dataset",
        data: chartBarData,
        barThickness: 0,
        backgroundColor: "transparent",
        options: {
          tooltips: { enabled: false },
        },
      },
      {
        lineTension: 0.3,
        label: "Second dataset",
        data: chartData,
        showLine: true,
        fill: true,
        //  {
        //   target: {
        //     value: 30,
        //   },
        //   above: "red",
        //   below: "green",
        // },
        // backgroundColor: "rgba(252,73,73,0.4)",
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(252,73,73,1)",
            "rgba(252,73,73,0.9)",
            "rgba(252,73,73,0)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);

          return gradientBg;
        },
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          console.log("val", value);
          return value > 30 ? "red" : "rgba(252,73,73,0.3)";
        },

        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > 30 ? "rgba(255,0,0,0.3)" : "green";
        },
        pointBackgroundColor: "red",
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 0,
      },
    ],
  };
  return (
    <Box
      mt="0.5rem"
      mr="-8rem"
      sx={{
        width: "140%",
        height: "100%",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "rgba(255,255,255,0.2)",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },

              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  display: false,
                  color: theme.palette.background.second,
                  borderDash: [3, 3],
                },
                ticks: {
                  display: false,
                  color: theme.palette.fontColor.main,
                  max: 100,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  display: false,
                  borderDash: [3, 3],
                  color: theme.palette.background.second,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: false,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default ChangeTempChartMob;
