import React, { useEffect, useState } from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import rtlPlugin from "stylis-plugin-rtl";
import jwt_decode from "jwt-decode";
import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Link, useNavigate } from "react-router-dom";
import FlexBetween from "components/FlexBetween/FlexBetween";
import axios from "axios";
import { Close, Done, Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const CssTextField = styled(TextField)({
  width: "312px",
  height: "44px",
  "& .MuiFormLabel-root": {
    marginLeft: "-3px",
  },
  "& label.Mui-focused": {
    color: "#F7F7F7",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#F7F7F7",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    color: "#F7F7F7 !important",
    borderRadius: "8px",

    "& fieldset": {
      borderColor: "#F7F7F7",
    },
    "&:hover fieldset": {
      borderColor: "#F7F7F7",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#F7F7F7",
    },
    "& .MuiOutlinedInput-root": {
      height: "44px !important",
    },
  },
});
// const CssOutlinedInput = styled(OutlinedInput)({
//   width: "400px",
//   height: "44px",
//   "& .MuiFormLabel-root": {
//     color: "white",
//   },
// });

const FormRegisterMob = ({ emailSignInStart, googleSignInStart }) => {
  const AtEightCh = /(?:[a-zA-Z][^a-zA-Z]*){8}/;
  const AtOneNum = /.*\d+.*/;
  const AtOneUpper = /(?=.*[A-Z])/;
  const [checkPassword, setCheckPassword] = useState("");
  const [eightCh, setEightCh] = useState("");
  const [oneNum, setoneNum] = useState("");
  const [oneUpper, setOneUpper] = useState("");
  const theme = useTheme();
  const handleLoginClick = () => {
    const username = "nima";
    const password = "nima";
    axios
      .post("https://api.rahbaan.ir/v1/authentication/token", {
        username: username,
        password: password,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [token, setToken] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setToken(tokenResponse);
      // navigate("/");
      // checkToken(tokenResponse);
      console.log(
        "🚀 ~ file: Form.jsx:56 ~ Form ~ tokenResponse",
        tokenResponse
      );
    },
    onError: () => {
      console.log("Login Failed");
    },
  });
  const checkToken = (token) => {
    const t = jwt_decode(token);
    console.log("🚀 ~ file: Form.jsx:55 ~ Form ~ tokenResponse", t);
  };
  useEffect(() => {
    console.log("login");
  }, [token]);

  const [userCredentials, setCredentials] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  const { email, password } = userCredentials;
  const handleSubmit = async (e) => {
    e.preventDefault();

    emailSignInStart(email, password);
  };
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <CacheProvider value={cacheRtl}>
      <Box
        sx={{
          width: "336px",
          height: "90%",
          display: "flex",
          flexDirection: "column",
          direction: "ltr",
          alignItems: "center",

          mt: "2.5rem",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography
            color="#F7F7FC"
            variant="h3"
            textAlign="center"
            mt="-1rem"
            mb="2rem"
          >
            ثبت نام
          </Typography>
          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            width="336px"
          >
            <CssTextField
              label="نام کاربری"
              autoComplete="off"
              inputProps={{
                style: {
                  height: "4px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                  autocomplete: "new-password",
                  form: {
                    autocomplete: "off",
                  },
                },
              }}
            />
            <CssTextField
              label="شماره همراه"
              inputProps={{
                style: {
                  height: "4px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                },
              }}
            />
            <CssTextField
              label="ایمیل"
              inputProps={{
                style: {
                  height: "4px",
                },
              }}
              InputLabelProps={{
                shrink: true,
                sx: {
                  // set the color of the label when not shrinked
                  color: "#F7F7F7",
                  fontSize: "16px",
                },
              }}
            />
            <Box position={"relative"}>
              <CssTextField
                label="رمز عبور"
                inputProps={{
                  style: {
                    height: "4px",
                  },
                }}
                type={showPassword ? "password" : "text"}
                onChange={(e) => {
                  setEightCh(AtEightCh.test(e.target.value));
                  setOneUpper(AtOneUpper.test(e.target.value));
                  setoneNum(AtOneNum.test(e.target.value));
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    // set the color of the label when not shrinked
                    color: "#F7F7F7",
                    fontSize: "16px",
                  },
                }}
              />
              <Box sx={{ position: "absolute", top: 2, right: 0 }}>
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Box>
            </Box>

            <FlexBetween
              sx={{
                width: "336px",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FlexBetween gap="0.1rem" sx={{ ml: "1rem" }}>
                {eightCh ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 8 کاراکتر
                </Typography>
              </FlexBetween>

              <FlexBetween gap="0.1rem" sx={{ ml: "1rem" }}>
                {oneNum ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 1 عدد
                </Typography>
              </FlexBetween>

              <FlexBetween gap="0.1rem" sx={{ ml: "1rem" }}>
                {oneUpper ? (
                  <Done
                    sx={{ color: "#72EB13", width: "10px", height: "10px" }}
                  />
                ) : (
                  <Close
                    sx={{ color: "#F7F7F7", width: "10px", height: "10px" }}
                  />
                )}
                <Typography
                  color="#F7F7F7"
                  fontSize={theme.typography.smallestTextPrimary}
                >
                  حداقل 1 حرف بزرگ
                </Typography>
              </FlexBetween>
            </FlexBetween>
            <FlexBetween sx={{ justifyContent: "flex-start" }}>
              <Checkbox sx={{ ml: "-1.7rem" }} />
              <FlexBetween gap="0.3rem">
                <Link>
                  <Typography color="#F7F7F7">
                    <span
                      style={{ width: "40px", borderBottom: "1px solid white" }}
                    >
                      قوانین و مقررات
                    </span>
                  </Typography>
                </Link>
                <Typography>را مطالعه کردم و با آن موافقم</Typography>
              </FlexBetween>
            </FlexBetween>
            <Button
              variant="contained"
              sx={{
                width: "312px",
                height: "44px",
                fontSize: "20px",
                borderRadius: "8px",
                boxShadow: "none",
              }}
              onClick={() => handleLoginClick()}
            >
              <Typography fontFamily={"YekanBakhFaNum-Regular"} fontSize="20px">
                ثبت نام
              </Typography>
            </Button>
          </Stack>
          <FlexBetween
            sx={{ justifyContent: "flex-start", m: "1.5rem 0rem 1rem 1rem" }}
            gap="0.5rem"
          >
            <Typography color="#F7F7F7">حساب کاربری دارید؟</Typography>
            <Link to="/login">
              <Typography color="#F7F7F7">
                <span
                  style={{ width: "40px", borderBottom: "1px solid white" }}
                >
                  وارد شوید
                </span>
              </Typography>
            </Link>
          </FlexBetween>
          <Box sx={{ m: "1.5rem 0rem 1rem 1rem" }}>
            <GoogleLogin
              id="googleOne"
              onSuccess={(credentialResponse) => {
                console.log(credentialResponse);
                navigate("/");
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </Box>
        </form>
      </Box>
    </CacheProvider>
  );
};

export default FormRegisterMob;
