import { Box, Stack, styled, Typography } from "@mui/material";
import AirAlertsList from "components/AirAlertsList/AirAlertsList";
import AirSensorsList from "components/AirSensorsList/AirSensorsList";
import CustomGauge from "components/CustomGauge/CustomGauge";
import FlexBetween from "components/FlexBetween/FlexBetween";
import AirMap from "components/IndividualAirSensors/AirMap";
import DewPointAir from "components/IndividualAirSensors/DewPointAir";
import HumidityAir from "components/IndividualAirSensors/HumidityAir";
import PressureAir from "components/IndividualAirSensors/PressureAir";
import RadiationIntensityAir from "components/IndividualAirSensors/RadiationIntensityAir";
import RainAir from "components/IndividualAirSensors/RainAir";
import TempAir from "components/IndividualAirSensors/TempAir";
import WindAir from "components/IndividualAirSensors/WindAir";
import HumiditySoil from "components/IndividualSoilSensors/HumiditySoil";
import SoilAlertsList from "components/IndividualSoilSensors/SoilAlertsList";
import SoilMap from "components/IndividualSoilSensors/SoilMap";
import SoilSensorsList from "components/IndividualSoilSensors/SoilSensorList";
import TempSoil from "components/IndividualSoilSensors/TempSoil";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AirSensorsDoughnut from "widgets/AirSensorsDoughnut";
import CopyRight from "widgets/CopyRight";
import SpeedomotorsData from "widgets/SpeedomotorsData";
import jalaali from "jalaali-js";
import AlarmNoSensor from "components/AlarmNoSensor/AlarmNoSensor";
import { setId } from "States/sensors";
const SoilSensor = () => {
  const sensorsData = useSelector((state) => state.sensors.sensorsData);
  const data = sensorsData?.devices;
  const selectedSensorDashboard = useSelector((state) => state.sensors.id);
  const dispatch = useDispatch();
  const allSoilSensors = data?.filter((item) => item.type === "SOIL");
  useEffect(() => {
    const idExists = allSoilSensors?.some(
      (item) => item.id === selectedSensorDashboard
    );
    if (data) {
      if (!idExists) {
        dispatch(setId({ id: data[0]?.id }));
      }
    }
  }, [data]);

  return (
    <Box
      width="99%"
      height="83vh"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",

        "&::-webkit-scrollbar": {
          width: 15,
        },
        "&::-webkit-scrollbar-track": {
          boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
          borderRadius: "5px",
        },
        "&::-webkit-scrollbar-button": {
          height: "1px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#5A596F",
          outline: `0px solid slategrey`,
          borderRadius: "5px",
        },
      }}
    >
      {allSoilSensors?.length > 0 ? (
        <>
          <FlexBetween
            sx={{
              justifyContent: "flex-start",
              gap: "20px",
              width: "92vw",
            }}
          >
            <Box width="61vw" height="62px">
              <SpeedomotorsData />
            </Box>
            <WidgetWrapper width="29.7vw" height="62px">
              <AirSensorsDoughnut />
            </WidgetWrapper>
          </FlexBetween>
          <FlexBetween
            mt="16px"
            sx={{
              justifyContent: "flex-start",
              gap: "18px",
              width: "92vw",
              height: "33vh",
            }}
          >
            <WidgetWrapper width="30vw" height="33vh">
              <SoilSensorsList sensors={allSoilSensors} />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="33vh" sx={{ padding: "0" }}>
              <SoilMap data={allSoilSensors} />
            </WidgetWrapper>
            <WidgetWrapper width="30vw" height="33vh">
              <SoilAlertsList />
            </WidgetWrapper>
          </FlexBetween>

          <FlexBetween
            mt="16px"
            sx={{
              justifyContent: "flex-start",
              gap: "18px",
              width: "92vw",
              height: "32vh",
            }}
          >
            <WidgetWrapper width="45.5vw" height="32vh">
              <TempSoil />
            </WidgetWrapper>
            <WidgetWrapper width="45.5vw" height="32vh">
              <HumiditySoil />
            </WidgetWrapper>
          </FlexBetween>
          <Box width="92vw">
            <CopyRight />
          </Box>
        </>
      ) : (
        <AlarmNoSensor type={"خاک"} />
      )}
    </Box>
  );
};

export default SoilSensor;
