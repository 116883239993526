import { AddOutlined, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Radio,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import Table from "components/table/Table";
import React from "react";
import PointerIcon from "../assets/pointer.png";
import FahlData from "Mock/FahlData";
const Fahl = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const colors = ["#C3FF79", "#72EB13", "#72EB13", "#FFEE00", "#FC4949"];
  const boxWidth = 107;
  const colorWidth = boxWidth / colors.length;

  const gradientBackground = `linear-gradient(to right, ${colors
    .map(
      (color, index) =>
        `${color} ${colorWidth * index}px, ${color} ${
          colorWidth * (index + 1)
        }px`
    )
    .join(", ")})`;

  const tableData = FahlData?.map((row) => {
    return {
      data: {
        "کد دام": (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="1rem"
          >
            {/* <Box
              width="32px"
              height="32px"
              component="img"
              src={
                row.type === "w"
                  ? WaterIcon
                  : row.type === "p"
                  ? PoisonIcon
                  : SoilIcon
              }
            /> */}
            <Typography
              color={main}
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.code}
            </Typography>
          </Box>
        ),
        وضعیت: (
          <Typography
            color={main}
            fontSize={theme.typography.smallestTextPrimary}
          >
            {row.status}
          </Typography>
        ),
        "زمان های فحلی": (
          <Stack direction="column" sx={{}}>
            <Box
              sx={{
                width: "107px",
                height: "6px",
                backgroundImage: gradientBackground,
                borderRadius: "30px",
                position: "relative",
              }}
            >
              <Box
                component="img"
                src={PointerIcon}
                position="absolute"
                sx={{ top: -6, left: (row.fahlTime / 32) * 100 }}
              />
            </Box>
            <FlexBetween
              sx={{
                width: "107px",
                height: "6px",
                mt: "0.5rem",
              }}
            >
              <Typography variant="extraSmall" color="fontColor.main">
                32
              </Typography>
              <Typography variant="extraSmall" color="fontColor.main">
                0
              </Typography>
            </FlexBetween>
          </Stack>
        ),
        "بهترین زمان فحلی": (
          <Stack direction="column" alignItems="flex-end">
            <Typography
              color="fontColor.alt"
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.date}
            </Typography>
            <Typography
              color="fontColor.alt"
              fontSize={theme.typography.smallestTextPrimary}
            >
              {row.time}
            </Typography>
          </Stack>
        ),
      },
    };
  });

  return (
    <Box width="100%" height="100%">
      <FlexBetween>
        <Typography variant="h5" color="fontColor.main">
          فحلی بودن
        </Typography>
        <MoreHoriz />
      </FlexBetween>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="0.5rem"
        sx={{ width: "100%", height: "90%" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-between"
          gap="0.5rem"
          mr="1rem"
        >
          <AnimatedProgressBar
            number={(750 / 1750) * 100}
            width={120}
            height={140}
            title="750"
            subTitle="1750"
            color={theme.palette.others.first}
            dot={false}
            secondColor={theme.palette.background.forth}
          />
          <Typography variant="smallestTextPrimary" color="fontColor.alt">
            تعداد فحلی ها
          </Typography>
        </Box>
        <Box width="70%" maxHeight="80%" display="flex">
          <Table expandable={false} data={tableData} />
        </Box>
      </Box>
    </Box>
  );
};
export default Fahl;
