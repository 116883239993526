import { MoreHoriz, Search } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import Full3Icon from "../../assets/battery/3full.png";
import FullIcon from "../../assets/battery/battery-full.png";
import EmptyIcon from "../../assets/battery/battery-empty.png";
import WifiIcon from "../../assets/wifi/wifi.png";
import Wifi1Icon from "../../assets/wifi/wifi-1.png";
import Wifi2Icon from "../../assets/wifi/wifi-2.png";
import Wifi3Icon from "../../assets/wifi/wifi-3.png";
import { useState } from "react";

const getBatteryStatus = (status) => {
  switch (status) {
    case "LOWEST":
      return EmptyIcon;
    case "LOW":
      return Full3Icon;
    case "HIGH":
      return FullIcon;
    default:
      break;
  }
};
const getSignalStatus = (status) => {
  switch (status) {
    case "LOWEST":
      return WifiIcon;
    case "LOW":
      return Wifi1Icon;
    case "HIGH":
      return Wifi3Icon;
    default:
      break;
  }
};
const PlantSensorsList = ({ sensors, setSelectedSensor, selectedSensor }) => {
  // const [selected, setSelected] = useState(0);
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <Box>
      <FlexBetween>
        <Typography variant="h5" mb="0.1rem" color={main}>
          سنسورهای گیاه
        </Typography>
        <FlexBetween>
          <FlexBetween
            sx={{
              // display: open ? "inline-flex" : "none",
              transition: "all 0.2s ease",
              ml: "1.5rem",
              alignItems: "center",
              border: `1px solid ${theme.palette.fontColor.alt}`,
              // border: "1px solid black",
              borderRadius: "8px",
              mt: "0.25rem",
            }}
          >
            <InputBase
              disableUnderline
              placeholder="جست و جو"
              id="inputSearchRemote"
              sx={{
                paddingRight: "5px",
                backgroundColor: "inherit",
                "& .MuiInputBase-input": {
                  "::placeholder": {
                    color: `${theme.palette.fontColor.main} !important`,
                  },
                },
                height: "27px",
                width: "80px",
                borderRadius: "8px",
                color: theme.palette.fontColor.alt,
                outline: "none",
                borderBottom: "none",
              }}
            />
            <Search sx={{ ml: "0.2rem" }} />
          </FlexBetween>
          <MoreHoriz sx={{ cursor: "pointer" }} />
        </FlexBetween>
      </FlexBetween>
      <Typography fontSize={theme.typography.smallestTextSecondary} color={alt}>
        زمانبندی ارسال داده: 6 ساعته
      </Typography>
      <Box style={{ height: 160, width: "100%" }}>
        {/* <DataGrid
          columns={columns}
          rows={rows}
          direction="rtl"
          //   checkboxSelection
          
        /> */}
        <FlexBetween mt="0.5rem">
          <Typography
            fontSize={theme.typography.smallestTextSecondary}
            color={alt}
          >
            سنسور
          </Typography>
          <FlexBetween gap="2rem" sx={{ width: "65%" }}>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              وضعیت
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              باتری
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              سیگنال
            </Typography>
            <Typography
              fontSize={theme.typography.smallestTextSecondary}
              color={alt}
            >
              توضیحات
            </Typography>
          </FlexBetween>
        </FlexBetween>
        <Divider sx={{ marginTop: "10px" }} />
        <Box
          sx={{
            height: "115px",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 5,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          {sensors.map((item, index) => (
            <FlexBetween
              key={index}
              onClick={() => {
                // setSelected(index);
                setSelectedSensor(item.id);
              }}
              sx={{
                borderRadius: "8px",
                cursor: item.id === selectedSensor ? "auto" : "pointer",
                mb: "0.25rem",
                backgroundColor:
                  item.id === selectedSensor
                    ? theme.palette.background.third
                    : null,
                pr: "0.2rem",
                "&:hover": { backgroundColor: theme.palette.background.third },
              }}
            >
              <Typography
                color={main}
                fontSize={theme.typography.smallestTextPrimary}
              >
                {item.title}
              </Typography>
              <FlexBetween gap="2rem" sx={{ width: "65%" }}>
                <Box>
                  <FormControlLabel
                    value="female"
                    control={
                      <Radio
                        sx={{
                          width: "12px",
                          height: "12px",
                          "&, &.Mui-checked": {
                            color:
                              item.status === "ACTIVE"
                                ? theme.palette.others.third
                                : theme.palette.others.second,
                          },
                        }}
                        checked={true}
                      />
                    }
                  />
                </Box>
                <Box
                  component={"img"}
                  src={getBatteryStatus(item.batteryStatus)}
                />
                <Box
                  component={"img"}
                  src={getSignalStatus(item.signalStatus)}
                />

                <IconButton>
                  <MoreHoriz />
                </IconButton>
              </FlexBetween>
            </FlexBetween>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default PlantSensorsList;
