import {
  Box,
  Button,
  Divider,
  Fade,
  MenuItem,
  MenuList,
  Select,
  Slide,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import FlexBetween from "components/FlexBetween/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import AnimatedProgressBarWallet from "./AnimatedProgressbarWallet";
import {
  CloseOutlined,
  DoneOutline,
  DownloadOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import ActuatorSelectBox from "scenes/Actuator/ActuatorSelectBox";
import WalletSelectBox from "./WalletSelectedBox";
import WalletChart from "./WalletChart";
import AnimatedProgressbarWalletSmall from "./AnimatedProgressbarWalletSmall";
import WalletFeatureItem from "./WalletFeatureItem";
import planData from "./plandata";
import Plan from "./Plan";
import Services from "./Services";
import BannerImage from "../../assets/livestock.png";
import Experts from "./Experts";
import WalletMap from "./WalletMap";
import CopyRight from "widgets/CopyRight";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import Neighbor from "./Neighbor";
import WeatherModules from "modules/WeatherModule/WeatherModules";
// import "./Wallet";
const StyledMenuList = styled(MenuList)(({ theme }) => ({
  /* Use the prop `theme` to apply dynamic styles */
  backgroundColor: theme.palette.background.third,
  boxShadow: "none !important",
}));

const Wallet = () => {
  const theme = useTheme();
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  const [selectedFilter, setSelectedFilter] = useState("ماهانه");
  const [value, setValue] = React.useState(10);
  const [show, setShow] = useState(false);
  const { ref, inView } = useInView();
  const Box1Animation = useAnimation();
  const Box2Animation = useAnimation();
  const Box3Animation = useAnimation();
  const Box4Animation = useAnimation();
  const Box5Animation = useAnimation();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (inView) {
      if (!show) {
        Box1Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box2Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box3Animation.start({
          opacity: 1,
          transition: {
            type: "spring",
            duration: 200,
            bounce: 0.1,
          },
        });
        Box4Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        Box5Animation.start({
          x: 0,
          transition: {
            type: "spring",
            duration: 2,
            bounce: 0.1,
          },
        });
        setShow(true);
      }
    }
    if (!inView) {
      if (!show) {
        Box1Animation.start({
          x: "60vw",
        });
        Box2Animation.start({
          x: "30vw",
        });
        Box3Animation.start({
          opacity: 0,
        });
        Box4Animation.start({
          x: "-30vw",
        });
        Box5Animation.start({
          x: "-60vw",
        });
      }
    }
  }, [inView]);
  const [neighbor, setNeighbor] = useState(false);
  const [WeatherModule, setWeatherModule] = useState(false);
  return (
    <>
      {WeatherModule ? (
        <WeatherModules setWeatherModule={setWeatherModule} />
      ) : (
        <Box
          width="97.8%"
          height="92vh"
          m="16px 16px 0 16px"
          sx={{
            // backgroundColor: "red",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 15,
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
              borderRadius: "5px",
            },
            "&::-webkit-scrollbar-button": {
              height: "1px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#5A596F",
              outline: `0px solid slategrey`,
              borderRadius: "5px",
            },
          }}
        >
          <Fade in={true} timeout={4000}>
            <Box
              width="99.5%"
              height="315px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              position="relative"
            >
              <Box
                width="100%"
                height="100%"
                component="img"
                src={BannerImage}
                borderRadius="12px"
                position="relative"
              ></Box>
              <Box
                position="absolute"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box width="420px" height="117px" textAlign={"center"}>
                  <Typography color={main} variant="h2">
                    پیش بینی خطرات احتمالی برای
                  </Typography>
                  <Typography
                    color={main}
                    variant="h2"
                    fontWeight={600}
                    mt="0.5rem"
                  >
                    سلامت دام
                  </Typography>
                </Box>
                <Box
                  width="228px"
                  height="32px"
                  borderRadius="8px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.others.first,
                    cursor: "pointer",
                  }}
                  onClick={() => setWeatherModule((prev) => !prev)}
                >
                  <Typography>بیشتر</Typography>
                </Box>
              </Box>
            </Box>
          </Fade>

          <FlexBetween
            sx={{
              width: "99.5%",
              justifyContent: "flex-start",
              gap: "16px",
              mt: "16px",
            }}
          >
            <Fade in={true} timeout={6000}>
              <WidgetWrapper width="24%" height="315px">
                <FlexBetween>
                  <Typography variant="h4" color={main}>
                    کیف پول
                  </Typography>
                  <Box
                    width="108px"
                    height="32px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="8px"
                    sx={{
                      backgroundColor: theme.palette.others.first,
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="subHeading" color={main}>
                      شارژ کیف پول
                    </Typography>
                  </Box>
                </FlexBetween>
                <Box
                  width="100%"
                  height="90%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <AnimatedProgressBarWallet
                    number={75}
                    width={157}
                    height={163}
                    title="200"
                    subTitle="350 هزار تومان"
                    color={theme.palette.others.first}
                    dot={false}
                  />
                </Box>
              </WidgetWrapper>
            </Fade>
            <Fade in={true} timeout={6000}>
              <WidgetWrapper width="75.5%" height="315px">
                <FlexBetween>
                  <Stack direction={"row"} gap="0.5rem">
                    <Typography>واریزی</Typography>
                    <Typography>(تومان)</Typography>
                  </Stack>
                  <FlexBetween gap="0.5rem">
                    <Box
                      width="122px"
                      height="27px"
                      borderRadius="8px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: theme.palette.others.first,
                        cursor: "pointer",
                      }}
                    >
                      <Stack direction="row" gap="0.2rem">
                        <DownloadOutlined />
                        <Typography>گزارش</Typography>
                      </Stack>
                    </Box>
                    <WalletSelectBox items={["ماهانه"]} />
                  </FlexBetween>
                </FlexBetween>

                <WalletChart
                  min={0}
                  max={11000000}
                  stepSize={5000000}
                  h={"85%"}
                />
              </WidgetWrapper>
            </Fade>
          </FlexBetween>

          <WidgetWrapper width="92.5vw" height="160px" mt="16px">
            <Tabs
              //   ref={scrollRef}
              orientation="horizontal"
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { transition: "none" },
              }}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                direction: "ltr",
                height: "100%",
                width: "100%",
                //   backgroundColor: "red",
                display: "flex",
                alignItems: "center",

                "& .MuiTabs-scrollButtons": {
                  width: "40px",
                  height: "100%",
                  borderRadius: "8px",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              
              <WalletFeatureItem
                title={"عملگرها"}
                totalDays="180"
                route={"/actuator"}
                remainedDays={(61 / 180) * 100}
                titleRemained={61}
                color={theme.palette.others.forth}
              />
              <Divider orientation="vertical" flexItem sx={{ m: "0 30px" }} />
              <WalletFeatureItem
                title={"بسته اقتصادی 1"}
                totalDays="365"
                remainedDays={Math.round((90 / 365) * 100)}
                titleRemained={90}
                color={theme.palette.others.second}
              />
              <Divider orientation="vertical" flexItem sx={{ m: "0 30px" }} />
              <WalletFeatureItem
                title={"مدیریت کارکنان"}
                totalDays={180}
                remainedDays={Math.round((88 / 180) * 100)}
                titleRemained={88}
                color={theme.palette.others.forth}
                route={"/staffmgmt"}
              />
              <Divider orientation="vertical" flexItem sx={{ m: "0 30px" }} />
              <WalletFeatureItem
                title={"هواشناسی"}
                totalDays={90}
                remainedDays={Math.round((68 / 90) * 100)}
                titleRemained={68}
                color={theme.palette.others.third}
                route={"/weather"}
              />
            </Tabs>
          </WidgetWrapper>
          <Box ref={ref}>
            <motion.div
              style={{
                width: "92.5vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <motion.div animate={Box1Animation} style={{ width: "19%" }}>
                <Plan plan={planData.economic1} />
              </motion.div>
              <motion.div animate={Box2Animation} style={{ width: "19%" }}>
                <Plan plan={planData.economic2} />
              </motion.div>
              <motion.div animate={Box3Animation} style={{ width: "19%" }}>
                <Plan plan={planData.economic3} />
              </motion.div>
              <motion.div animate={Box4Animation} style={{ width: "19%" }}>
                <Plan plan={planData.economic4} />
              </motion.div>
              <motion.div animate={Box5Animation} style={{ width: "19%" }}>
                <Plan plan={planData.fullPack} />
              </motion.div>
            </motion.div>
          </Box>
          <WidgetWrapper width="99.5%" height="556px" mt="16px">
            <Services />
          </WidgetWrapper>
          <WidgetWrapper width="92.5vw" height="284px" mt="16px">
            <Experts />
          </WidgetWrapper>

          <Box mb="2rem" width="91.5vw">
            <CopyRight />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Wallet;
