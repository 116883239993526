import React, { useState } from "react";
import L, { map } from "leaflet";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import { Box, IconButton, Modal, styled, useTheme } from "@mui/material";

import { useSelector } from "react-redux";
import { useEffect } from "react";
import ButtonsSensorsManagementAll from "scenes/SensorsManagementAll/ButtonsSensorsManagementAll";
import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
import GG1Icon1 from "../../../assets/Sensor Icon/G-G 1.png";
import {
  Close,
  DownloadOutlined,
  PriorityHighOutlined,
} from "@mui/icons-material";

var GG1Icon = L.icon({
  iconUrl: GG1Icon1,
  iconSize: [52, 54],
  iconAnchor: [10, 54],
  popupAnchor: [2, -10],
});

const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "relative",
  width: "100% !important",

  // maxheight: "100%",
  padding: "0px",
  maxHeight: "80%",
  "& .leaflet-left": {
    left: "89.2%",
  },
  "& .leaflet-bar": {
    border: "none",
  },
  "& .leaflet-control-zoom": { display: "none" },
  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const AirMapMob = ({ data, selectedSensor, setSelectedSensor }) => {
  function MultipleMarkers({ data }) {
    const theme = useTheme();
    const mode = useSelector((state) => state.global.mode);
    useEffect(() => {
      console.log(mode);
    }, [mode]);

    return data.map((item, index) => (
      <Marker
        position={item.position}
        eventHandlers={{
          click: (e) => {
            setSelectedSensor(item.id);
            console.log("id=======>", item.id);
          },
        }}
        key={index}
        icon={
          item.data && Object.keys(item.data).length > 0
            ? getIcon(item, selectedSensor)
            : GG1Icon
        }
      ></Marker>
    ));
  }
  const mode = useSelector((state) => state.global.mode);

  useEffect(() => {}, [mode]);
  const position = data[0].position;
  const [mapModal, setMapModal] = useState(false);
  const theme = useTheme();
  return (
    <MapBox mode={mode}>
      <ButtonsSensorsManagementAll
        individual={true}
        setMapModal={setMapModal}
      />
      <Modal
        open={mapModal}
        onClose={() => setMapModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 200000,
          "& .leaflet-left": {
            left: "14px",
          },
          "& .leaflet-bar": {
            border: "none",
          },
          "& .leaflet-control-zoom-in": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7) !important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "8px 8px 0 0 !important",
          },

          "& .leaflet-touch .leaflet-bar a": {
            lineHeight: "24px !important",
          },
          "& .leaflet-control-zoom-out": {
            width: "24px !important",
            height: "24px !important",
            backgroundColor:
              mode === "dark"
                ? "rgba(29, 29, 65, 0.7 )!important"
                : "rgba(250, 250, 252, 0.7) !important",
            color: theme.palette.fontColor.alt,
            borderRadius: "0 0 8px 8px !important",
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.second,
            "& .leaflet-container": {
              height: "100% !important",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "120px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <Close
                onClick={() => setMapModal(false)}
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "170px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <PriorityHighOutlined
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: "200px",
              left: "3.5rem",
              width: "24px",
              height: "24px",
              backgroundColor:
                mode === "dark"
                  ? "rgba(29, 29, 65, 0.7)"
                  : "rgba(250, 250, 252, 0.7)",
              backdropFilter: "blur(7.5px)",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <IconButton>
              <DownloadOutlined
                sx={{
                  width: "22px",
                  height: "22px",
                }}
              />
            </IconButton>
          </Box>
          <MapContainer
            center={position}
            zoom={15.5}
            style={{ borderRadius: "8px", height: "241px", width: "100%" }}
          >
            {/* <ChangeView center={position} zoom={15.5} /> */}
            <TileLayer
              attribution="PTR"
              url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
              maxZoom={22}
              maxNativeZoom={18}
            />
            <MultipleMarkers
              data={data}
              selectedIcon={selectedSensor}
              setselectedIcon={setSelectedSensor}
            />
          </MapContainer>
        </Box>
      </Modal>

      <MapContainer
        center={position}
        zoom={15.5}
        style={{ borderRadius: "8px", height: "287px", width: "100%" }}
      >
        {/* <ChangeView center={position} zoom={15.5} /> */}
        <TileLayer
          attribution="PTR"
          url="http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        <MultipleMarkers
          data={data}
          selectedIcon={selectedSensor}
          setselectedIcon={setSelectedSensor}
        />
      </MapContainer>
    </MapBox>
  );
};

export default AirMapMob;
