import { useTheme } from "@mui/material";

const BACKGROUND_STROKE_DASH_OFFSET = 120;

const CircularProgressBar = ({
  children,
  diameter,
  value,
  start_from = "start",
  color,
  gaugeMinMax = 100,
}) => {
  const theme = useTheme();
  const wholeStrok = 360 - BACKGROUND_STROKE_DASH_OFFSET;
  const halfStrok = wholeStrok / 2;
  const transformStroke = 90 + BACKGROUND_STROKE_DASH_OFFSET / 2;
  const startStroke = 270 - BACKGROUND_STROKE_DASH_OFFSET / 2;
  const degree =
    (value * (start_from === "start" ? wholeStrok : halfStrok)) / gaugeMinMax;
  const transformDegree = (start) => {
    switch (start) {
      case "start":
        return transformStroke;
      case "middle":
        return -90;
      default:
        break;
    }
  };
  const startDegree = (start) => {
    switch (start) {
      case "start":
        return startStroke;
      case "middle":
        return 90;
      default:
        break;
    }
  };

  const cx =
    (diameter / 2) *
    (1 + Math.cos(Math.PI * ((startDegree(start_from) - degree) / 180)));
  const cy =
    (diameter / 2) *
    (1 - Math.sin(Math.PI * ((startDegree(start_from) - degree) / 180)));
  return (
    <div
      style={{
        position: "relative",
        height: `${diameter + 7}px`,
        width: `${diameter}px`,
        overflow: "visible",
        display: "flex",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <svg
        style={{
          position: "absolute",
          overflow: "visible",
          width: `${diameter}px`,
          height: `${diameter}px`,
          transform: `rotate(${transformStroke}deg)`,
        }}
        viewBox={`0 0 ${diameter} ${diameter}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          pathLength={360}
          style={{
            stroke: theme.palette.fontColor.third,
            strokeWidth: "2px",
            fill: "transparent",
            strokeDashoffset: BACKGROUND_STROKE_DASH_OFFSET,
            strokeLinecap: "round",
            strokeDasharray: "360 360",
          }}
          r={diameter / 2}
          cx="50%"
          cy="50%"
        />
      </svg>
      <svg
        style={{
          position: "absolute",
          overflow: "visible",
          width: `${diameter}px`,
          height: `${diameter}px`,
          transform: `rotate(${transformDegree(start_from)}deg)`,
        }}
        viewBox={`0 0 ${diameter} ${diameter}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r={diameter / 2}
          cx="50%"
          cy="50%"
          pathLength={360}
          style={{
            strokeDashoffset: 360 - degree,
            fill: "none",
            strokeLinecap: "round",
            strokeDasharray: "360 360",
            strokeWidth: "4px",
            stroke: color,
          }}
        />
      </svg>
      <svg
        style={{
          position: "absolute",
          overflow: "visible",
          width: `${diameter}px`,
          height: `${diameter}px`,
        }}
        viewBox={`0 0 ${diameter} ${diameter}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx={cx} cy={cy} r={diameter / 10} fill={color} />
      </svg>
      <div
        style={{
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CircularProgressBar;
