import { CacheProvider } from "@emotion/react";
import {
  AttachFileOutlined,
  EmojiEmotionsOutlined,
  KeyboardArrowDownOutlined,
  MessageOutlined,
  MicNoneOutlined,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import CustomPopover from "components/CustomPopover/CustomPopover";
import WidgetWrapper from "components/WidgetWrapper";
import {
  CssTextField,
  CustomButton,
  cacheRtl,
} from "components/styledComponents/common";
import Table from "components/table/Table";
import { useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import FlexBetween from "components/FlexBetween/FlexBetween";
import TicketMessage from "./TicketMessage";
import SendMessageDark from "assets/icon/send-message-dark.svg";
import SendMessageLight from "assets/icon/send-message-light.svg";
import AdminImg from "assets/admin_img.svg";
import CopyRight from "widgets/CopyRight";
export const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  flex: 1,
  padding: "0.5rem",
  width: "100%",
  height: "106px !important",
  border: "1px solid white",
  background: `${theme.palette.background.second}`,
  borderRadius: "8px",
  color: `${theme.palette.fontColor.main}`,
  resize: "none",
  overflow: "auto !important",
  "&::placeholder": {
    color: `${theme.palette.fontColor.alt}`,
  },
}));
const mockData = [
  {
    id: uuidv4(),
    title: "شارژ نشدن کیف پول",
    subject: "مالی",
    service: "کیف پول",
    priority: "middle",
    date: "1401/02/03  - 20:30",
    status: "pending",
    messages: [],
  },
  {
    id: uuidv4(),
    imageUrl: "https://s28.picofile.com/file/8464512900/5.jpg",
    title: "خرابی دستگاه",
    subject: "فنی",
    service: "سنسور گیاه",
    priority: "high",
    date: "1401/02/03  - 20:30",
    status: "answered",
    messages: [
      {
        me: true,
        text: "سلام خسته نباشید از امروز صبح دستگاه شماره 1 از کار افتاده دیتایی ارسال نمی کنه",
        date: "1402/02/01",
        time: "7:18 PM",
        seen: true,
      },
      {
        me: false,
        text: "اینترنت مزرعه وصله؟!",
        date: "1402/02/01",
        time: "7:19 PM",
        seen: true,
      },
      {
        me: true,
        text: "بله وصله بقیه دستگاه ها دارم کار می کنن فقط همین از کار افتاده",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: true,
      },
      {
        me: false,
        text: "باهاتون تماس گرفته میشه برای بررسی بیشتر اگر لازم باشه کارشناسمون برای بررسی و رفع خرابی میان مزرعه",
        date: "1402/02/01",
        time: "7:21 PM",
        seen: true,
      },
      {
        me: true,
        text: "ممنون منتظر تماستون هستم",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: false,
      },
    ],
  },
  {
    id: uuidv4(),
    imageUrl: "https://s28.picofile.com/file/8464512918/6.jpg",
    title: "از کار افتادن عملگر",
    subject: "فنی",
    service: "عملگر آبیاری",
    priority: "middle",
    date: "1401/02/03  - 20:30",
    status: "answered",
    messages: [
      {
        me: true,
        text: "سلام خسته نباشید از امروز صبح دستگاه شماره 1 از کار افتاده دیتایی ارسال نمی کنه",
        date: "1402/02/01",
        time: "7:18 PM",
        seen: true,
      },
      {
        me: false,
        text: "اینترنت مزرعه وصله؟!",
        date: "1402/02/01",
        time: "7:19 PM",
        seen: true,
      },
      {
        me: true,
        text: "بله وصله بقیه دستگاه ها دارم کار می کنن فقط همین از کار افتاده",
        date: "1402/02/01",
        time: "7:20 PM",
        seen: true,
      },
      {
        me: false,
        text: "باهاتون تماس گرفته میشه برای بررسی بیشتر اگر لازم باشه کارشناسمون برای بررسی و رفع خرابی میان مزرعه",
        date: "1402/02/01",
        time: "7:21 PM",
        seen: false,
      },
    ],
  },
  {
    id: uuidv4(),
    title: "خرابی دستگاه",
    subject: "فنی",
    service: "سنسور هوا",
    priority: "low",
    date: "1401/02/03  - 20:30",
    status: "pending",
    messages: [],
  },
];
const Ticket = () => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const [subject, setSubject] = useState("");
  const [service, setService] = useState("");
  const [priority, setPriority] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedTicket, setSelectedTicket] = useState("");
  const [message, setMessage] = useState("");
  const handleClickRow = (row) => {
    setSelectedTicket(row.id);
  };
  const handleClickEdit = (e) => {
    e.stopPropagation();
  };
  const tableData = mockData
    .filter((item) => item.title.includes(searchValue))
    .map((item) => {
      const badgeCount = item.messages.filter(
        (item) => !item.me && !item.seen
      ).length;
      return {
        id: item.id,
        data: {
          عنوان: (
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {item.title}
              {!!badgeCount && (
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    color: "#F7F7F7",
                    bgcolor: theme.palette.others.second,
                    fontSize: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {badgeCount}
                </Box>
              )}
            </Box>
          ),
          موضوع: item.subject,
          "سرویس مربوطه": item.service,
          اولویت: (
            <Typography
              variant="subHeading"
              color={
                item.priority === "middle"
                  ? "others.first"
                  : item.priority === "high"
                  ? "others.third"
                  : "others.forth"
              }
            >
              {item.priority === "middle"
                ? "متوسط"
                : item.priority === "high"
                ? "بالا"
                : "پایین"}
            </Typography>
          ),
          "تاریخ ارسال": item.date,
          وضعیت: (
            <Box
              sx={{
                p: "2px 4px",
                bgcolor:
                  item.status === "pending"
                    ? theme.palette.others.first
                    : theme.palette.others.third,
                color: theme.palette.background.second,
                borderRadius: "4px",
              }}
            >
              <Typography variant="smallestTextPrimary">
                {item.status === "pending"
                  ? "در انتظار بررسی"
                  : "پاسخ داده شده"}
              </Typography>
            </Box>
          ),
          "": (
            <Box display="flex" position="relative">
              <CustomPopover handleClickEdit={handleClickEdit} id={item.id} />
            </Box>
          ),
        },
      };
    });
  const selectedTicketData = mockData.find(
    (item) => item.id === selectedTicket
  );
  return (
    <Box width="100%" height="calc(100vh - 100px)" p="16px 16px 0 16px">
      {!!selectedTicket ? (
        <WidgetWrapper height="100%" display="flex" flexDirection="column">
          <FlexBetween>
            <Typography variant="h3">{selectedTicketData?.title}</Typography>
            <CustomButton onClick={() => setSelectedTicket("")}>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MessageOutlined style={{ width: "16px", height: "16px" }} />
                <Typography variant="subHeading">تیکت جدید</Typography>
              </Box>
            </CustomButton>
          </FlexBetween>
          <Box
            sx={{
              width: "70%",
              flex: 1,
              m: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              gap: "4px",
            }}
          >
            <Box
              width="100%"
              height="100%"
              position={"relative"}
              sx={{
                overflowY: "auto",
                overflowX: "hidden",
                display: "flex",
                gap: "12px",
                flex: 1,
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                  width: 5,
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.1)`,
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-button": {
                  height: "1px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#5A596F",
                  outline: `0px solid slategrey`,
                  borderRadius: "5px",
                },
              }}
            >
              {selectedTicketData?.messages.map((item) => (
                <Box
                  display="flex"
                  // justifyContent={item.me ? "flex-start" : "flex-end"}
                  flexDirection={item.me ? "row-reverse" : "row"}
                  gap="12px"
                >
                  <Box
                    component="img"
                    sx={{ width: "32px", height: "32px", borderRadius: "50%" }}
                    src={item.me ? selectedTicketData?.imageUrl : AdminImg}
                  />
                  <TicketMessage
                    seen={item.seen}
                    me={item.me}
                    text={item.text}
                    time={item.time}
                  />
                </Box>
              ))}
            </Box>
            <Box width="100%" display="flex" alignItems="flex-end">
              <Box
                width="100%"
                height="100%"
                display="flex"
                sx={{
                  backgroundColor: theme.palette.background.third,
                  borderRadius: "8px",
                  p: "0.25rem 0.5rem",
                }}
                alignItems="flex-end"
                gap="0.3rem"
              >
                <Box
                  display="flex"
                  alignItems="flex-end"
                  // justifyContent="space-between"
                  gap="8px"
                  flexGrow={1}
                >
                  <IconButton>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        position: "relative",
                      }}
                    >
                      <img
                        src={
                          mode === "dark" ? SendMessageDark : SendMessageLight
                        }
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: message ? 20 : 0,
                          height: message ? 20 : 0,
                          transition: "all 0.3s",
                        }}
                      />
                      <AttachFileOutlined
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: message ? 0 : 20,
                          height: message ? 0 : 20,
                          transition: "all 0.1s",
                          transform: "rotate(45deg)",
                        }}
                      />
                    </div>
                  </IconButton>
                  <FormControl
                    sx={{
                      width: "95%",
                      py: "8px",
                      "& textarea::placeholder": {
                        color: theme.palette.fontColor.alt,
                      },
                    }}
                  >
                    <TextareaAutosize
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder="پیام جدید"
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        color: theme.palette.fontColor.main,
                        resize: "none",
                        border: "none",
                        outline: "none",
                        fontFamily: "YekanBakhFaNum-Regular",
                      }}
                    />
                  </FormControl>
                </Box>
                <Box
                  gap="0.5rem"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <IconButton sx={{ px: 0 }}>
                    <EmojiEmotionsOutlined />
                  </IconButton>
                  <IconButton sx={{ px: 0 }}>
                    <MicNoneOutlined />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </WidgetWrapper>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "1rem",
          }}
        >
          <WidgetWrapper>
            <Typography variant="h3" color="fontColor.main">
              تیکت جدید
            </Typography>
            <CacheProvider value={cacheRtl}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="60%"
                m="2.5rem auto"
                flexDirection="column"
                gap="2rem"
                // height="300px"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "stretch",
                    gap: "12px",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      width: "30%",
                      flex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        // height: "48px",
                        "& .MuiInputBase-root": {
                          height: "45px",
                        },
                      }}
                    >
                      <CssTextField
                        label="عنوان"
                        variant="outlined"
                        style={{ width: "100%" }}
                      />
                    </Box>
                    <FormControl
                      sx={{
                        // minWidth: 250,
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-root": {
                          height: "45px",
                        },
                        "& .MuiInputBase-input": {
                          //   height: "5px",
                          padding: "8px 14px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          //   borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {!subject && (
                        <InputLabel
                          // disableAnimation
                          shrink={false}
                          // focused={false}
                          id="item_type_label"
                        >
                          موضوع
                        </InputLabel>
                      )}

                      <Select
                        IconComponent={KeyboardArrowDownOutlined}
                        id="item_type"
                        labelId="item_type_label"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      >
                        <MenuItem value={"finance"}>مالی</MenuItem>
                        <MenuItem value={"tech"}>فنی</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      width: "30%",
                      flex: 1,
                    }}
                  >
                    <FormControl
                      sx={{
                        // minWidth: 250,
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-root": {
                          height: "45px",
                        },
                        "& .MuiInputBase-input": {
                          //   height: "5px",
                          padding: "8px 14px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          //   borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {!service && (
                        <InputLabel
                          // disableAnimation
                          shrink={false}
                          // focused={false}
                          id="item_type_label"
                        >
                          سرویس مربوطه
                        </InputLabel>
                      )}

                      <Select
                        IconComponent={KeyboardArrowDownOutlined}
                        id="item_type"
                        labelId="item_type_label"
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                      >
                        <MenuItem value={"wallet"}>کیف پول</MenuItem>
                        <MenuItem value={"sensor-weather"}>سنسور هوا</MenuItem>
                        <MenuItem value={"sensor-soil"}>سنسور خاک</MenuItem>
                        <MenuItem value={"sensor-plant"}>سنسور گیاه</MenuItem>
                        <MenuItem value={"actuator-water"}>
                          عملگر آبپاش
                        </MenuItem>
                        <MenuItem value={"actuator-mist"}>
                          عملگر مه پاش
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{
                        // minWidth: 250,
                        "& .MuiFormLabel-root": {
                          marginLeft: "-4px",
                        },
                        "& .MuiInputBase-root": {
                          height: "45px",
                        },
                        "& .MuiInputBase-input": {
                          //   height: "5px",
                          padding: "8px 14px",
                        },
                        "& .MuiOutlinedInput-input:focused": {
                          //   borderColor: "green",
                        },
                        "& label.Mui-focused": {
                          color: "#F7F7F7",
                        },

                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#F7F7F7",
                          borderRadius: "8px",
                        },
                        "& .MuiOutlinedInput-root": {
                          color: "#F7F7F7 !important",
                          borderRadius: "8px",

                          "& fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&:hover fieldset": {
                            borderColor: "#F7F7F7",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#F7F7F7",
                          },
                        },
                      }}
                    >
                      {!priority && (
                        <InputLabel
                          // disableAnimation
                          shrink={false}
                          // focused={false}
                          id="item_type_label"
                        >
                          اولویت
                        </InputLabel>
                      )}

                      <Select
                        IconComponent={KeyboardArrowDownOutlined}
                        id="item_type"
                        labelId="item_type_label"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      >
                        <MenuItem value={"high"}>بالا</MenuItem>
                        <MenuItem value={"middle"}>متوسط</MenuItem>
                        <MenuItem value={"low"}>پایین</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box flex={1}>
                    <StyledTextarea maxRows={3} placeholder="توضیحات" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "70%",
                  }}
                >
                  <CustomButton
                    //   onClick={() => setNewActuator(false)}
                    secondary
                    sx={{ flex: "1 1" }}
                  >
                    انصراف
                  </CustomButton>
                  <CustomButton sx={{ flex: "1 1" }}>ثبت</CustomButton>
                </Box>
              </Box>
            </CacheProvider>
          </WidgetWrapper>
          <WidgetWrapper flex={1} overflow="hidden">
            <Box
              sx={{
                display: "flex",
                width: "fit-content",
                justifyContent: "flex-start",
                gap: "8px",
                alignItems: "center",
                border: `1px solid ${theme.palette.fontColor.alt}`,
                borderRadius: "8px",
                mt: "0.25rem",
                "& input::placeholder": {
                  color: `${theme.palette.fontColor.main} !important`,
                },
              }}
            >
              <Search />
              <InputBase
                disableUnderline
                placeholder="جست و جو"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                id="inputSearchRemote"
                sx={{
                  paddingRight: "5px",
                  backgroundColor: "inherit",
                  height: "27px",
                  width: "137px",
                  borderRadius: "8px",
                  color: theme.palette.fontColor.alt,
                  outline: "none",
                  borderBottom: "none",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                maxHeight: "calc(100% - 60px)",
                mt: "1rem",
              }}
            >
              <Table data={tableData} handleClickRow={handleClickRow} />
            </Box>
          </WidgetWrapper>
          <CopyRight />
        </Box>
      )}
    </Box>
  );
};

export default Ticket;
