import { MoreHoriz, Search } from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import ArrowRightDark from "../../assets/icon/arrow-right-dark.svg";
import ArrowRightLight from "../../assets/icon/arrow-right-light.svg";
import { useSelector } from "react-redux";

const HeaderMob = ({ handleClickBack, selectedMember }) => {
  const theme = useTheme();
  const mode = useSelector((state) => state.global.mode);
  const main = theme.palette.fontColor.main;
  const alt = theme.palette.fontColor.alt;
  return (
    <FlexBetween>
      <Box display="flex" alignItems="center" gap="0.5rem">
        <IconButton sx={{ padding: 0 }}>
          <Box
            component="img"
            width="32px"
            height="32px"
            borderRadius="50%"
            src={mode === "dark" ? ArrowRightDark : ArrowRightLight}
            onClick={handleClickBack}
          />
        </IconButton>
        <Box
          component="img"
          width="48px"
          height="48px"
          borderRadius="50%"
          src={selectedMember?.imageUrl}
        />
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Typography variant="h4" color={main}>
            {selectedMember?.name}
          </Typography>
          <Typography variant="smallestTextSecondary" color={alt}>
            {selectedMember ? "آنلاین" : ""}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap="0.5rem">
        <IconButton>
          <MoreHoriz />
        </IconButton>
      </Box>
    </FlexBetween>
  );
};

export default HeaderMob;
